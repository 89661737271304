import React, { useState, useEffect } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ThousandsSeparator from '../../../../Helpers/ThousandsSeparator';

const SueldoCheckbox = withStyles({
    root: {
      color: '#1572E8',
      '&$checked': {
        color: '#1572E8',
      },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CheckIndicadoresSueldo = ({ elementSelect, monto, setMonto, indicador, montoIndicador, setIndicadoresSelected, indicadoresSelected, disabledPuesto }) => {
    
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        if(elementSelect && disabledPuesto && indicador.indicadorId)  {
            const indicadorFound = indicadoresSelected.find(i => i === indicador.indicadorId);
            if(!indicadorFound) {
                setChecked(false);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = () => {
        
        if(checked === true) {
            setMonto(monto - parseFloat(montoIndicador));
            const newIndicadores = indicadoresSelected.filter(i => i !== indicador.indicadorId);
            setIndicadoresSelected(newIndicadores);
        } else {
            setMonto(monto + parseFloat(montoIndicador));
            const newIndicadores = indicadoresSelected.concat([indicador.indicadorId]);
            setIndicadoresSelected(newIndicadores);
        }
        setChecked(!checked);
    };

    return (
        <div className="row">
            <div className="col-1 col-md-1 col-sm-1">
                <SueldoCheckbox checked={checked} onChange={handleChange} name="checked"/>
            </div>
            <div className="col-8 col-md-8 col-sm-8">
                <div><label><strong>Clave: </strong>{ indicador ? indicador.indicadorClave : '' }</label></div>
                <div><label><strong>Nombre: </strong>{ indicador ? indicador.indicadorNom : '' }</label></div>
            </div>
            <div className="col-3 col-md-3 col-sm-3" style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ width: '100%', textAlign: 'right' }}>
                    <label style={{ marginRight: '22%' }}>
                        <strong>{ montoIndicador ? `$ ${ThousandsSeparator(parseFloat(montoIndicador).toFixed(2))}` : '' }</strong>
                    </label>
                </div>
            </div>
        </div>
    );
}
 
export default CheckIndicadoresSueldo;
import React, { Fragment } from 'react';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import TableSelectRows from '../../../../../../Services/Tables/TableSelectRows';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const TableEmpleados = ({ empleados, enviaEmpleados }) => {
    return (
        <Fragment>
            {
                (empleados)
                ?   <TableSelectRows
                        title=''
                        columns={[
                            { field: 'empleadoClave', title: 'NUP' },
                            { field: 'nombreCompleto', title: 'Nombre' },
                            { field: 'empleadoRfc', title: 'RFC' },
                            { field: 'puestoNom', title: 'Puesto' },
                            { field: 'nominaNombre', title: 'Nómina' },
                            { field: 'cpresupuestalClave', title: 'Clave Presupuestal / Proyecto' },
                        ]}
                        data={empleados}
                        icon={ChromeReaderModeIcon}
                        tooltip="Generar"
                        action={enviaEmpleados}
                    />
                :   <SpinnerOval />
            }
        </Fragment>
    );
}
 
export default TableEmpleados;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService';
import { servicios_grupogack_url, servicios_grupogack_catalogo } from '../../../../../Constants/Constants'; 
import { informacionModulos } from '../../Helpers/HelperNavegacion';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { iconosDrawer } from '../../MenuDrawer/Drawer/IconosDrawer';
import TextField from '@material-ui/core/TextField';
import SpinnerOval from '../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../Services/AlertForm';
import ModalService from '../../../../Usuarios/Usuario/ModalService/ModalService';
import { Tooltip } from 'reactstrap';
import { InfoRounded } from '@material-ui/icons';

const Manual = ({ id, permisos }) => {

    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
    const [ modulos, setModulos ]= useState(null); 
    const [ openPDF, setOpenPDF ] = useState( false );
    const [ titlePDF, setTitlePDF ] = useState( '' );
    const [ moduleName, setModuleName ] = useState( '' );
    const [ fileNotFound, setFileNotFound ] = useState(null);
    const [ pdfServer, setPdfServer ] =useState([]);
    const [ tooltipOpenModulo, setTooltipOpenModulo ] = useState(false);

    useEffect(() => {
        axios.get( `${servicios_grupogack_url}/menu`, headersAuth() )
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        setModulos(informacionModulos(res.data.dataResponse.menu));
                        break;
                    default:
                        break;
                }
            } )
            .catch( error => { console.log( error ) });
            axios.get(`${servicios_grupogack_catalogo}/documentos/ayuda/`, headersAuth())
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        setPdfServer( res.data.dataResponse[1] );
                        break;
                    default:
                        break;
                }
            } )
            .catch( error => { console.log( error ) });
    }, []);
 
    const validar = (e) => {
        if (e === undefined || e === null) {
            setFileNotFound(null);
        } else {
            let result = modulos.filter(mod => e.nombre === mod.nombre);
            if(result.length !== 0 ){   
                  
                if(pdfServer.includes(`${result[0].id}.pdf`)){
                    setTitlePDF(result[0].id);
                    setFileNotFound(null);  
                    setModuleName(result[0].nombre);
                    setOpenPDF( true ); 
                }else{
                    setFileNotFound( <AlertForm message="No se ha encontrado manual para el módulo seleccionado." /> );
                } 
            }
        }
    }

    const toggle = () => { 
        setTooltipOpenModulo(!tooltipOpenModulo); 
    }

    return (
        <div id={ id }>
            <div className="row justify-content-center">
                {
                    modulos ?
                    <div className="col-md-6 col-sm-12">
                    <div className="card py-2 px-3 mb-2">
                        <h4 className="mt-2"> PDF Ayuda </h4> 
                        <hr/>
                        <label>{'Módulo *'} 
                            <span id={'tooltipModulo'} className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRounded color="disabled"/></span> 
                        </label>
                        <Tooltip placement="right" isOpen={tooltipOpenModulo} target="tooltipModulo" toggle={toggle}>
                            { 'Seleccione un Módulo' }
                        </Tooltip>
                        <Autocomplete 
                        id="free-solo-demo" 
                        autoHighlight={true}
                        onChange={(e,v) => validar(v)}
                        getOptionLabel={option => option.nombre}
                        options={modulos.map(modu => ({'nombre':modu.nombre,'icono':modu.icon}))}
                        size="small" 
                        noOptionsText="No existen coincidencias" 
                        renderOption={ (options,{inputValue}) => {
                            const matches = match(options.nombre, inputValue)
                            const parts = parse(options.nombre, matches);
                            return (
                                <React.Fragment>
                                    <table >
                                        <thead>
                                            <tr>
                                                <th className="Icono">
                                                    { iconosDrawer[ options.icono ] }
                                                </th>
                                                <th >
                                                    { parts.map((part, index) => (
                                                        <span key={index} style={{ color: part.highlight ? '#4C638E': 'black' }}>
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </React.Fragment>
                            );
                        }}      
                        renderInput={ params => (
                                <TextField
                                    id="mui-theme-provider-outlined-input"
                                    {...params} 
                                    margin="normal"
                                    variant="outlined"
                                    fullWidth
                                />
                        )}
                        />
                        {fileNotFound}
                    </div>
                    </div>
                    : <SpinnerOval/>
                }
                
            </div>
            {
                openPDF &&
                <ModalService title={ moduleName } parentNode={ id } className="modal-style"
                    isOpen={ openPDF } onClose={ () => setOpenPDF   (false) } minWidth='80%' >
                    <div className='embed-responsive' style={{ paddingBottom: '60%'}}>
                        <iframe src={`https://sao.grupogack.net/ayuda/pdf/${titlePDF}.pdf`} title=' ' width="100%"
                            onError={()=>{setOpenPDF(false); setFileNotFound( <AlertForm message="No se ha encontrado tutorial para el módulo seleccionado." /> ); }}
                        />
                    </div>
                </ModalService> 
            }
        </div>
    );
}
 
export default Manual;
import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; 
import { Tooltip} from '@material-ui/core';
import { RecentActors, LibraryBooks, Add, InfoRounded, AssignmentInd, Delete} from '@material-ui/icons';
import CardActions from '@material-ui/core/CardActions';
import axios from 'axios';
import { servicios_grupogack } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import CircularProgress from '@material-ui/core/CircularProgress';

const ReportesAccesoDirecto = ({ reporte, setOpenDelete, setElementSelect, setOpenInfoReport, setTitle, openReport, icon, permisos, informacionAdicional, setOpenNewReportNomina, downloadReport, setDownloadReportDefinido, setInfoReport }) => {
    
    const dividos =permisos? permisos.filter(element=> element!==1):null;
    const [ downloading, setDownloading ] = useState(false);
    const downloadXls = () =>{
        setDownloading(true);
        axios.get( `${servicios_grupogack+reporte.reportesReporteJson}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                    const downloadLink = document.createElement("a");
                    const fileName =`${reporte.reportesReporteNom}.xls`; 
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                    setDownloading(false);
                    break; 
                case 404:
                    alert("No existen datos");
                    setDownloading(false);
                    break; 
                case 500:
                    setDownloading(false);
                    break; 
                default:
                    setDownloading(false);
                    break; 
            }
        })
        .catch( error => { console.log( error ) });
    }
    
    return (
        <React.Fragment>
            <Card className="cardBoard" > 
                <CardContent onClick={ () => {
                        if(setOpenNewReportNomina && !setDownloadReportDefinido){ 
                            setOpenNewReportNomina(true);
                        }else if( downloadReport && !setDownloadReportDefinido && reporte.reportesId<7 ){
                            openReport( reporte );
                            setElementSelect( { isStatic: true});
                        }else if(reporte.reportesId>6){
                            downloadXls();
                        }else if(setDownloadReportDefinido){ 
                            setInfoReport(reporte.reportesReporteNom);
                            setDownloadReportDefinido(true);
                            setOpenNewReportNomina(true);
                        }else{ 
                            setElementSelect( reporte ); openReport( reporte ) 
                        }}}
                > 
                    <div className="icon">{icon==='Add' ? <Add/> : icon==='RecentActors' ? <RecentActors/> : <LibraryBooks/> }</div>
                    <p className="name">{ reporte ? reporte.reportesReporteNom : reporte.reportesId || informacionAdicional ? reporte.reportesReporteNom :  reporte.reportesReporteNom }</p>
                    {
                        downloading &&
                        <div className="row" >
                            <div className={`col-md-12 col-sm-1 my-2`} style={{textAlign:'center'}}>
                                <Tooltip title="Descargando..."> 
                                    <CircularProgress size={20} />
                                </Tooltip> 
                            </div>
                        </div>
                    }
                </CardContent>
                {
                        icon!=='Add' && icon !=='RecentActors' &&
                <CardActions >
                        <div className="row" > 
                            <div className={`col-md-${12/dividos.length} col-sm-1 my-2`} style={{textAlign:'center'}}>
                                <Tooltip title="Mostrar información"> 
                                        <InfoRounded color="disabled" className={"info"} style={{ color: '#bdbdbd'}}
                                             onClick={() => { setElementSelect( informacionAdicional ? informacionAdicional :reporte  ); setTitle('Información del Reporte'); setOpenInfoReport( true ); }}/> 
                                </Tooltip>
                            </div> 
                            <div className={`col-md-${12/dividos.length} col-sm-1 my-2`} style={{textAlign:'center'}}>
                                <Tooltip title="Asignar Reporte a..."> 
                                        <AssignmentInd className={"info"} style={{ color: '#4C638E'}}
                                            onClick={() => { setElementSelect( reporte  ); setTitle('Asignar Reporte'); setOpenInfoReport( true ); }}/> 
                                </Tooltip>
                            </div>
                            {
                                permisos.includes(3) &&
                                <div className={`col-md-${12/dividos.length} col-sm-1 my-2`} style={{textAlign:'center'}}>
                                    <Tooltip title="Eliminar Reporte"> 
                                            <Delete color="disabled" className={"info"} onClick={() => { setElementSelect( reporte ); setOpenDelete( true ); }} /> 
                                    </Tooltip>
                                </div>
                            }
                            
                        </div>
                </CardActions>
                }
            </Card>
        </React.Fragment>
    );
}; 
export default ReportesAccesoDirecto;
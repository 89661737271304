import React from 'react';
import './styles.css';

const AlertForm = ({message}) =>{
    return(
        <div className="alert alert-danger alert_form col-12" role="alert">
            <span>{message}</span>
        </div>
    );
}

export default AlertForm;
import React, { useState, useEffect } from 'react';

import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import ChatIcon from '@material-ui/icons/Chat';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import Tooltip from '@material-ui/core/Tooltip';
/* ------------------------------------ Estilos y componentes Header -----------------------------------  */
import RenderMobileMenu from './RenderMobileMenu';
/*import SearchComponent from './SearchComponent';*/
import UsuariosChat from '../Drawer/UsuariosChat';
import useStylesHeader from './UseStylesHeader';
/* ---------------------------------- Ultimo que debe de ser importado ---------------------------------- */
import useStylesMenu from '../UseStylesMenu';
import './style.css';
/* -----------------------------------------------------------------------------------------------------  */
import ShowEmails from './ShowEmails';

function HeaderAppBar({ openMenu, setOpenMenu, socket, modulosApi, usuario, steps, run, tour, relacionLaboral, toggleRelacionLaboral, companias }) {
    const classes = useStylesMenu();
    /* ---------------------------- Estilos y variables Header-Mobile ---------------------------- */
    const classesHeader = useStylesHeader();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [mensajeNuevo, setMensajeNuevo] = useState([]);

    const mobileMenuId = 'primary-search-account-menu-mobile';

    useEffect( () => {
        if(socket !==null){
          socket.on('chat:messagedesc', data => {
              if(data.length) setMensajeNuevo(data);
          });
          
          return( () => {
            socket.off('chat:messagedesc');
          })
        }
    },[socket, mensajeNuevo]);
    
    const styles = theme => ({
        margin: {
          margin: theme.spacing.unit * 2
        },
        customBadge: {
          backgroundColor: "#4C638E",
          color: "white"
        }
    });

    return (
        
        <div className={classesHeader.grow}>
            <AppBar position="fixed" className={clsx(classes.appBar, {[classes.appBarShift]: openMenu,})} >
                <Toolbar className="">
                    <Tooltip title="Menú" >
                        <IconButton aria-label="open drawer" edge="start" className={classes.menuButton} onClick={ () => setOpenMenu(!openMenu) } >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    {/* ----------------------------------------------- Buscador ----------------------------------------------- 
                    <SearchComponent modulosApi={modulosApi} steps={steps}  run={run}/>*/}
                    {
                        (relacionLaboral)
                        ?   <div className="contenedor-textoHeader">
                                {
                                    (companias.length > 1)
                                    ?   <Tooltip title="Cambiar Empresa" >  
                                            <label className="form-control contenidoRelLab-hover " onClick={toggleRelacionLaboral} > 
                                                <span className="bold"> Empresa:&nbsp;</span><span>{ relacionLaboral.nombre }</span>
                                            </label>
                                        </Tooltip>
                                    :   <label className="form-control contenidoRelLab"> 
                                            <span className={"bold"}> Empresa:&nbsp;</span><span>{ relacionLaboral.nombre }</span>
                                        </label>
                                }
                            </div>
                        :   null
                    }
                    {/* ----------------------------------- Seccion Notificaciones y mensajes ----------------------------------- 
                    <div className={classesHeader.grow} />
                    <div className={classesHeader.sectionDesktop}>
                        <div className="my-fourth-step">
                            <ShowEmails />
                            <Tooltip title="Notificaciones" >
                                <IconButton aria-label="show 17 new notifications">
                                    <Badge  classes={{ badge: classes.notificationColor }}
                                            className={styles.margin}
                                            badgeContent={17}
                                    >
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="my-five-step">
                            <Tooltip title="Chat" >
                                <IconButton aria-label="open drawer" edge="end" aria-haspopup="true" onClick={ () => setOpen(true) }>
                                    {
                                        (mensajeNuevo.length > 0)
                                        ? <Badge badgeContent={mensajeNuevo.length} classes={{ badge: classes.notificationColor }}><ChatIcon /></Badge>
                                        : <ChatIcon />
                                    }
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>*/}
                    {/* -------------------------------- Icono de apertura menu - (Solo Movil) --------------------------------- 
                    <div className={classesHeader.sectionMobile}>
                        <IconButton aria-label="show more" aria-controls={mobileMenuId} aria-haspopup="true" onClick={ (event) => setMobileMoreAnchorEl(event.currentTarget) } color="inherit" >
                            <MoreIcon/>
                        </IconButton>
                    </div>*/}
                </Toolbar>
                {/* --- Componente que solo se muestra cuando presionamos el icono de chat
                <UsuariosChat open={open} setOpen={setOpen} socket={socket} usuario={usuario} mensajeNuevo={mensajeNuevo} setMensajeNuevo={setMensajeNuevo} tour={tour}/> */}
            </AppBar>
            {/* Componentes externos que renderizan menus en la vista movil
            <RenderMobileMenu steps={steps} run={run} mobileMoreAnchorEl={mobileMoreAnchorEl} setMobileMoreAnchorEl={setMobileMoreAnchorEl} mobileMenuId={mobileMenuId} mensajeNuevo={mensajeNuevo} setOpen={setOpen}/>
             */}
        </div>
    );
}

export default HeaderAppBar;
import React from 'react';
import ModalService from '../../../ModalService/ModalService';
import PrestamosEmpleado from './PrestamosEmpleado/PrestamosEmpleado';

const ModalConsultaPrestamos = ({ id, openModalForm, setOpenModalForm, elementSelect, informacionAdicional }) => {
    const cerrarModal = () => {
        setOpenModalForm( false );
    }

    return (
        <ModalService title="Consultar Préstamos" parentNode={id} minWidth="80%" maxWidth="80%"
            isOpen={ openModalForm } onClose={ () => cerrarModal() } >
                <div className="row mb-2">
                    <div className="col-md-6 col-sm-12">
                        <div> <b> Empleado: </b> {elementSelect.nombreCompleto} </div>
                        <div> <b> Número Empleado: </b> {elementSelect.empleadoClave} </div>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div> <b> Nómina: </b> {elementSelect.nominaNombre} </div>
                    </div>
                </div>

                <PrestamosEmpleado id={id} empleado={elementSelect}/>
        </ModalService>
    );
};

export default ModalConsultaPrestamos;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_rh, servicios_grupogack_nomina, movimientos_relacionados } from '../../../../../../../Constants/Constants';
import DataTableMovimientos from '../../Variables/DataTableMovimientos/DataTableMovimientos';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonMovimientosRelacionadosEmpleado } from '../../../../Helpers/JsonToOneLevel';

const RelacionadosEmpleado = ({ empleado, setEmpleado, tiposAcumulado, idModulo }) => {
    const [ relacionados, setRelacionados ] = useState( null );
    const [ indicadoresNomina, setIndicadoresNomina ] = useState( null );
    useEffect(() => {
        axiosRelacionadosEmpleado();
        axiosIndicadoresNomina();
        // eslint-disable-next-line
    }, []);

    const axiosRelacionadosEmpleado = () => {
        axios.get( `${servicios_grupogack_rh}/movimientos/relacionado/empleado/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setRelacionados( ArrayJsonMovimientosRelacionadosEmpleado( respuesta.data.dataResponse ));
                        break;
                    case 404: //No hay datos disponibles
                        setRelacionados( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener los indicadores por nomina (Nomina a la cual esta relacionada el empleado) */
    const axiosIndicadoresNomina = () => {
        axios.get( `${servicios_grupogack_nomina}/indicadores/getbynomina/${empleado.nominaId}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setIndicadoresNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setIndicadoresNomina( [] );
            })
    }

    return (
        <React.Fragment>
            { relacionados && indicadoresNomina ?
                <DataTableMovimientos
                    idModulo={idModulo}
                    title={movimientos_relacionados}
                    empleado={empleado}
                    setEmpleado={setEmpleado}
                    columns={[
                        { field: "nomina.nomClave", title: "Clave Indicador" },
                        { field: "nomina.nomIndicador", title: "Nombre Indicador" },
                        { field: "acumulado.acumuladoNombre", title: "Tipo de Acumulado" },
                        { field: "fechaInicioTab", title: "Fecha Inicio" },
                        { field: "fechaFinalTab", title: "Fecha Final" },
                    ]}
                    data={ relacionados }
                    informacionAdicional={{ empleado, tiposAcumulado, indicadoresNomina }}/>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default RelacionadosEmpleado;
import React from 'react';

const TerminosyCondiciones = () => {
    return (
        <div className="informacion-legal">
            <p>Versión 1.0 Fecha de última actualización 01/Julio/2013</p>
            <p>
                CONTRATO DE ADHESIÓN RESPECTO DE LA PÁGINA DE INTERNET <b>www.GRUPO GACK.net</b> QUE CELEBRAN POR UNA PARTE GACK DE MÉXICO, S. DE R.L. DE C.V., EN ADELANTE "GRUPO GACK" Y,
                POR LA OTRA, EL USUARIO, SUJETÁNDOSE AMBAS PARTES, A LO ESTABLECIDO EN EL TEXTO DEL PRESENTE CONTRATO.
            </p>
            <p> <b>[www.GRUPO GACK.net]</b> </p>

            <h4>TÉRMINOS Y CONDICIONES DE USO.</h4>
            <h4>PROEMIO.</h4>
            <p>
                1.- AL INGRESAR Y UTILIZAR ESTE PORTAL DE INTERNET, CUYO NOMBRE DE DOMINIO ES: <b>www.GRUPO GACK.net</b> PROPIEDAD DE "GRUPO GACK",
                USTED (EL USUARIO) ESTÁ ACEPTANDO LOS TÉRMINOS Y LAS CONDICIONES CONTENIDOS EN ESTE CONVENIO Y DECLARA EXPRESAMENTE SU ACEPTACIÓN UTILIZANDO PARA TAL EFECTO MEDIOS ELECTRÓNICOS,
                EN TÉRMINOS DE LO DISPUESTO POR EL ARTÍCULO 1803 Y 1834BIS DEL CÓDIGO CIVIL FEDERAL, 80, 81, 89 Y DEMÁS RELATIVOS Y APLICABLES DEL CÓDIGO DE COMERCIO Y DEMÁS RELATIVOS Y APLICABLES DE LA LEGISLACIÓN APLICABLE.
                <br/>
                <br/>
                2.- SI EL USUARIO NO ACEPTA EN FORMA ABSOLUTA Y COMPLETA LOS TÉRMINOS Y CONDICIONES DE ESTE CONVENIO, DEBERÁ ABSTENERSE DE ACCEDER, USAR Y VER <b>www.GRUPO GACK.net.</b>
                <br/>
                <br/>
                3.- PARA EL CASO QUE EL USUARIO CONTINÚE EN EL USO DE <b>www.GRUPO GACK.net</b> DICHA ACCIÓN SE CONSIDERARÁ COMO SU ABSOLUTA Y EXPRESA ACEPTACIÓN A LOS TÉRMINOS Y CONDICIONES AQUÍ ESTIPULADOS.
                <br/>
                <br/>
                4.- LA SOLA UTILIZACIÓN DE LA PÁGINA DE INTERNET LE OTORGA AL PÚBLICO USUARIO (EN ADELANTE REFERIDO COMO EL "USUARIO" O LOS "USUARIOS") E IMPLICA LA ACEPTACIÓN, PLENA E INCONDICIONAL,
                DE TODAS Y CADA UNA DE LAS CONDICIONES GENERALES Y PARTICULARES INCLUIDAS EN ESTOS TÉRMINOS Y CONDICIONES DE USO EN LA VERSIÓN PUBLICADA Y ADMINSITRADA POR "GRUPO GACK", EN EL MOMENTO MISMO EN QUE EL USUARIO ACCEDA A LA PÁGINA.
                CUALQUIER MODIFICACIÓN A LOS PRESENTES TÉRMINOS Y CONDICIONES SERÁ REALIZADA CUANDO "GRUPO GACK", LO CONSIDERE APROPIADO, SIENDO EXCLUSIVA RESPONSABILIDAD DEL USUARIO ASEGURARSE DE TOMAR CONOCIMIENTO DE TALES MODIFICACIONES.
                <br/>
                <br/>
                LAS PARTES ACUERDAN QUE AL NO EXISTIR ERROR, DOLO, MALA FE O CUALQUIER OTRO VICIO DE LA VOLUNTAD QUE PUDIERA NULIFICAR LA VALIDEZ DEL PRESENTE INTRUCMENTO, AMBAS ACUERDAN EN SUJERTARSE AL TENERO DE LOS ESTIPULADO EN LAS SIGUIENTES:
                <br/>
            </p>
            <h4>CLÁUSULAS</h4>

            <h6>1.- LICENCIA.</h6>
            <p>
                <b>1.1.-</b> POR VIRTUD DE LA CELEBRACIÓN DE ESTE CONVENIO, "GRUPO GACK" OTORGA Y CONCEDE AL USUARIO EL DERECHO NO EXCLUSIVO,
                REVOCABLE Y NO TRANSFERIBLE DE VER Y USAR <b>www.GRUPO GACK.net</b> DE CONFORMIDAD CON LOS TÉRMINOS Y CONDICIONES QUE SE ESTIPULAN EN ESTE CONVENIO.
                <br/>
                <br/>
                <b>1.2.-</b> PARA LOS EFECTOS DEL PRESENTE CONVENIO, LAS PARTES ACUERDAN QUE POR USUARIO SE ENTENDERÁ A CUALQUIER PERSONA DE CUALQUIER NATURALEZA QUE INGRESE A <b>www.GRUPO GACK.net</b>
                Y/O A CUALQUIERA DE LAS SUBPÁGINAS QUE DESPLIEGAN SU CONTENIDO Y/O A LA PERSONA DE CUALQUIER NATURALEZA QUE SE DÉ DE ALTA Y/O USE, CUALQUIERA DE LOS SERVICIOS QUE SE OFRECEN A TRAVÉS DE DICHO SITIO DE INTERNET.
                <br/>
                <br/>
                <b>1.3.-</b> EL USUARIO SÓLO PODRÁ IMPRIMIR Y/O COPIAR CUALQUIER INFORMACIÓN CONTENIDA O PUBLICADA EN <b>www.GRUPO GACK.net</b> EXCLUSIVAMENTE PARA SU USO PERSONAL, NO COMERCIAL.
                EN CASO DE SER PERSONA MORAL SE ESTARÁ A LO DISPUESTO POR EL ARTÍCULO 148, FRACCIÓN IV DE LA LEY FEDERAL DEL DERECHO DE AUTOR. SE LE RECUERDA AL USUARIO QUE TODAS LAS FOTOGRAFÍAS, OBRAS ARTÍSTICAS,
                MARCAS Y/O CATÁLOGOS DE LAS MISMAS, SE ENCUENTRAN DEBIDAMENTE PROTEGIDAS EN TÉRMINOS DE LA LEGISLACIÓN EN MATERIA DE PROPIEDAD INTELECTUAL Y SON PROPIEDAD DE SUS RESPECTIVOS TITULARES Y QUE "GRUPO GACK" PUEDE HABER UTILIZADO IMÁGENES,
                PROPIEDAD DE TERCEROS, POR LO QUE LA PROPIEDAD DE ELLAS LES CORRESPONDE A SUS RESPECTIVOS TITULARES Y NO SE USAN EN TÉRMINOS DE LO DISPUESTO POR EL ARTÍCULO 62 DEL REGLAMENTO DE LA LEY DE LA PROPIEDAD INDUSTRIAL.
                <br/>
                <br/>
                <b>1.4.-</b> LA REIMPRESIÓN, RE-PUBLICACIÓN, DISTRIBUCIÓN, ASIGNACIÓN, SUBLICENCIA, VENTA, REPRODUCCIÓN ELECTRÓNICA O POR CUALQUIER OTRO MEDIO DE CUALQUIER INFORMACIÓN, DOCUMENTO O GRÁFICO DE O QUE APAREZCA EN <b>www.GRUPO GACK.net</b>
                EN TODO O EN PARTE, PARA CUALQUIER USO DISTINTO AL PERSONAL NO COMERCIAL LE ESTÁ EXPRESAMENTE PROHIBIDO AL USUARIO, A MENOS QUE "GRUPO GACK" LE HAYA OTORGADO SU AUTORIZACIÓN PREVIAMENTE Y POR ESCRITO.
                <br/>
            </p>

            <h6>2.- REGLAS PARA EL USO DE www.GRUPO GACK.net.</h6>
            <p> INDEPENDIENTEMENTE DE LAS DEMÁS OBLIGACIONES QUE SE CONTENGAN EN EL TEXTO ÍNTEGRO DEL PRESENTE INSTRUMENTO, EL USUARIO Y "GRUPO GACK", ESTÁN DE ACUERDO EN QUE EL USO DE <b>www.GRUPO GACK.net</b> SE SUJETARÁ A LAS SIGUIENTES REGLAS: </p>
            <p>
                <b>2.1.-</b> INFORMACIÓN CONTENIDA EN <b>www.GRUPO GACK.net:</b> EL USUARIO RECONOCE Y ACEPTA QUE LA INFORMACIÓN PUBLICADA O CONTENIDA EN <b>www.GRUPO GACK.net</b> GENERADA POR "GRUPO GACK" O POR LOS PROVEEDORES, USUARIOS Y/O PROFESIONALES,
                SERÁ CLARAMENTE IDENTIFICADA DE FORMA TAL QUE SE RECONOZCA QUE LA MISMA PROVIENE DE "GRUPO GACK" O DE LOS PROVEEDORES.
                <br/>
                <br/>
                <b>2.2.-</b> LA INFORMACIÓN, CONSEJOS, CONCEPTOS Y OPINIONES PUBLICADAS EN <b>www.GRUPO GACK.net,</b> NO NECESARIAMENTE REFLEJAN LA POSICIÓN DE "GRUPO GACK" NI DE SUS EMPLEADOS, OFICIALES, DIRECTORES, ACCIONISTAS,
                LICENCIATARIOS Y CONCESIONARIOS. POR ESTA RAZÓN, "GRUPO GACK" NO SE HACE RESPONSABLE POR NINGUNA INFORMACIÓN, CONSEJOS, OPINIONES Y CONCEPTOS QUE SE EMITAN EN <b>www.GRUPO GACK.net.</b> ASIMISMO, <b>www.GRUPO GACK.net</b> NO SE HACE RESPONSABLE
                DE LA INFORMACIÓN CONTENIDA EN LA PÁGINA DE INTERNET, INCLUIDAS LAS SUBPÁGINAS, EN EL ENTENDIDO DE QUE ES BAJO EL PROPIO RIESGO Y RESPONSABILIDAD DEL USUARIO EL USO Y SEGUIMIENTO DE LA MISMA. SIN EMBARGO,
                SI UNA PARTE DEL CONTENIDO LE AFECTA DIRECTAMENTE A USUARIOS O TERCEROS, PODRÁN SOLICITAR QUE ÉSTE SEA DADO DE BAJA DEL SITIO DE WEB, SEGÚN EL PROCEDIMIENTO ESTABLECIDO EN EL PUNTO 4.8.
                <br/>
                <br/>
                <b>2.3.-</b> "GRUPO GACK" SE RESERVA EL DERECHO DE BLOQUEAR EL ACCESO O REMOVER EN FORMA PARCIAL O TOTAL, TODA INFORMACIÓN, COMUNICACIÓN O MATERIAL QUE A SU EXCLUSIVO JUICIO PUEDA RESULTAR:
                (I) ABUSIVO, DIFAMATORIO U OBSCENO, (II) FRAUDULENTO, ARTIFICIOSO O ENGAÑOSO, (III) VIOLATORIO DE DERECHOS DE AUTOR, MARCAS, CONFIDENCIALIDAD, SECRETOS INDUSTRIALES O CUALQUIER DERECHO DE PROPIEDAD INTELECTUAL DE UN TERCERO,
                (IV) OFENSIVO O (V) DE CUALQUIER FORMA CONTRAVENGA LO ESTABLECIDO EN ESTE CONVENIO. SI EL USUARIO DESEA OBTENER MAYOR INFORMACIÓN DE UN TEMA EN ESPECÍFICO PROVEÍDO POR "GRUPO GACK" O LOS PROVEEDORES,
                EL USUARIO DEBERÁ CONSULTARLO DIRECTAMENTE CON CADA UNO DE ELLOS, SEGÚN CORRESPONDA Y/O CON UN ESPECIALISTA EN LA MATERIA.
                <br/>
                <br/>
                <b>2.4.-</b> EL USUARIO RECONOCE QUE "GRUPO GACK" NO CONTROLA O CENSURA PREVIAMENTE EL CONTENIDO DISPONIBLE EN <b>www.GRUPO GACK.net</b>, QUE SEA PROVEÍDO AL MISMO POR PROVEEDORES INDEPENDIENTES AJENOS A "GRUPO GACK" Y/O OTROS USUARIOS,
                ENTIÉNDASE POR ESTO, LOS TIPS, CONSEJOS, IMÁGENES, RECOMENDACIONES Y OPINIONES. "GRUPO GACK" NO TIENE CONTROL EDITORIAL SOBRE EL CONTENIDO Y QUEDARÁ EXENTO DE CUALQUIER RESPONSABILIDAD POR INFORMACIÓN O MATERIAL GENERADO Y/O PROVISTO
                POR TERCEROS. POR TAL MOTIVO, "GRUPO GACK" NO ASUME NINGUNA RESPONSABILIDAD POR EL CONTENIDO PROVISTO A LA PÁGINA DE INTERNET POR PROVEEDORES INDEPENDIENTES AJENOS A "GRUPO GACK".
                <br/>
                <br/>
                BAJO NINGUNA CIRCUNSTANCIA "GRUPO GACK" SERÁ RESPONSABLE DE CUALQUIER DAÑO Y/O PERJUICIO, DIRECTO O INDIRECTO, CAUSADO EN VIRTUD DE LA CONFIANZA DEL USUARIO EN INFORMACIÓN OBTENIDA A TRAVÉS DE ESTA PÁGINA DE INTERNET.
                <br/>
                <br/>
                <b>2.5.-</b> "GRUPO GACK" SE RESERVA EL DERECHO DE SUPRIMIR O MODIFICAR EL CONTENIDO DE LA PÁGINA DE INTERNET QUE, A EXCLUSIVO JUICIO DE "GRUPO GACK" NO CUMPLA CON LOS ESTÁNDARES DE "GRUPO GACK" O QUE PUDIERA RESULTAR CONTRARIO
                AL ORDENAMIENTO JURÍDICO VIGENTE. SIN EMBARGO, "GRUPO GACK", NO SERÁ RESPONSABLE POR CUALQUIER FALLA O TARDANZA QUE SE GENERE AL ELIMINAR TAL MATERIAL.
                <br/>
            </p>

            <h6>3.- AVISO DE PRIVACIDAD SOBRE EL USO DE INFORMACIÓN Y BASE DE DATOS.</h6>
            <p>
                <b>3.1.-</b> A TRAVÉS DE <b>www.GRUPO GACK.net</b> PUEDE OBTENER DIVERSA INFORMACIÓN DEL USUARIO QUE PUEDE COMPILARSE Y FIJARSE EN UNA BASE DE DATOS PARA LO CUAL "GRUPO GACK" PUBLICA SU "AVISO DE PRIVACIDAD" EN EL SITIO DE <b>www.GRUPO GACK.net</b>,
                EL CUAL SE RECOMIENDA CONSULTAR EN CASO DE DUDAS ACERCA DEL TRATAMIENTO DE LOS DATOS PERSONALES POR PARTE "GRUPO GACK".
                <br/>
                <br/>
                <b>3.2.-</b> SI "GRUPO GACK" DECIDE CAMBIAR SU AVISO DE PRIVACIDAD, ANUNCIARÁ DICHOS CAMBIOS EN <b>www.GRUPO GACK.net</b>, DE MANERA QUE EL USUARIO SIEMPRE PUEDA SABER QUÉ INFORMACIÓN SE RECOPILA, CÓMO PODRÍA SER UTILIZADA Y SI SERÁ REVELADA A ALGUIEN.
                <br/>
            </p>

            <h6>4.- DERECHOS DE AUTOR Y PROPIEDAD INDUSTRIAL.</h6>
            <p>
                <b>4.1.-</b> "GRUPO GACK", <b>www.GRUPO GACK.net</b> Y SUS LOGOTIPOS Y TODO EL MATERIAL QUE APARECE EN LA PÁGINA DE INTERNET REFERIDA, SON MARCAS, NOMBRES DE DOMINIO Y/O NOMBRES COMERCIALES PROPIEDAD DE SUS RESPECTIVOS TITULARES
                PROTEGIDOS POR LOS TRATADOS INTERNACIONALES Y LEYES APLICABLES EN MATERIA DE PROPIEDAD INTELECTUAL Y DERECHOS DE AUTOR.
                <br/>
                <br/>
                <b>4.2.-</b> LOS DERECHOS DE AUTOR SOBRE EL CONTENIDO, ORGANIZACIÓN, RECOPILACIÓN, COMPILACIÓN, INFORMACIÓN, LOGOTIPOS, FOTOGRAFÍAS, IMÁGENES, PROGRAMAS, APLICACIONES, O EN GENERAL CUALQUIER INFORMACIÓN CONTENIDA O PUBLICADA
                EN <b>www.GRUPO GACK.net</b> SE ENCUENTRAN DEBIDAMENTE PROTEGIDOS A FAVOR DE "GRUPO GACK" SUS AFILIADOS, PROVEEDORES Y/O DE SUS RESPECTIVOS PROPIETARIOS, DE CONFORMIDAD CON LA LEGISLACIÓN APLICABLE EN MATERIA DE PROPIEDAD INTELECTUAL.
                <br/>
                <br/>
                <b>4.3.-</b> SE PROHÍBE EXPRESAMENTE AL USUARIO MODIFICAR, ALTERAR O SUPRIMIR, YA SEA EN FORMA TOTAL O PARCIAL, LOS AVISOS, MARCAS, NOMBRES COMERCIALES, SEÑAS, ANUNCIOS, LOGOTIPOS O EN GENERAL CUALQUIER INDICACIÓN QUE SE REFIERA A
                LA PROPIEDAD DE INFORMACIÓN CONTENIDA EN <b>www.GRUPO GACK.net</b>.
                <br/>
                <br/>
                <b>4.4.-</b> EN CASO DE QUE EL USUARIO TRANSMITA Y/O PUBLIQUE EN <b>www.GRUPO GACK.net</b> CUALQUIER INFORMACIÓN, PROGRAMAS, APLICACIONES, SOFTWARE O, EN GENERAL, CUALQUIER MATERIAL QUE REQUIERA SER LICENCIADO A TRAVÉS DE <b>www.GRUPO GACK.net</b>,
                EL USUARIO LE OTORGA A "GRUPO GACK" EN ESTE ACTO, UNA LICENCIA PERPETUA, UNIVERSAL, GRATUITA, NO EXCLUSIVA, MUNDIAL Y LIBRE DE REGALÍAS, INCLUYENDO ENTRE LOS DERECHOS OTORGADOS, EL DERECHO DE SUBLICENCIAR, VENDER, REPRODUCIR, DISTRIBUIR,
                TRANSMITIR, CREAR TRABAJOS DERIVADOS, EXHIBIRLOS Y EJECUTARLOS PÚBLICAMENTE. NO SE INTERPRETARÁ EN ESTE SENTIDO LA INFORMACIÓN PROVENIENTE DE OTROS SITIOS WEB A LOS QUE "GRUPO GACK" HAGA REFERENCIA CON OBJETIVOS MERAMENTE INFORMATIVOS.
                <br/>
                <br/>
                <b>4.5.-</b> LO ESTABLECIDO EN EL INCISO ANTERIOR SE APLICARÁ IGUALMENTE A CUALQUIER OTRA INFORMACIÓN QUE EL USUARIO ENVÍE, TRASMITA O PUBLIQUE EN <b>www.GRUPO GACK.net</b> (INCLUYENDO, SIN LIMITACIÓN ALGUNA, IDEAS PARA RENOVARO MEJORAR
                EL SITIO <b>www.GRUPO GACK.net</b>, SEA QUE ÉSTAS HAYAN SIDO INCLUIDAS EN CUALQUIER ESPACIO DE <b>www.GRUPO GACK.net</b>, EN VIRTUD DE OTROS MEDIOS O MODOS DE TRANSMISIÓN YA CONOCIDOS O EN EL FUTURO DESARROLLADOS).
                <br/>
                <br/>
                <b>4.6.-</b> POR LO ANTERIOR, EL USUARIO RENUNCIA EXPRESAMENTE EN ESTE ACTO A INTENTAR CUALQUIER ACCIÓN, DEMANDA O RECLAMACIÓN EN CONTRA DE "GRUPO GACK", SUS AFILIADOS O PROVEEDORES POR CUALQUIER ACTUAL O EVENTUAL VIOLACIÓN
                DE CUALQUIER DERECHO DE AUTOR O PROPIEDAD INTELECTUAL DERIVADO DE LA INFORMACIÓN, PROGRAMAS, APLICACIONES, SOFTWARE, IDEAS Y DEMÁS MATERIAL QUE EL PROPIO USUARIO ENVÍE A <b>www.GRUPO GACK.net</b>.
                <br/>
                <br/>
                <b>4.7.-</b> ASIMISMO, EL USUARIO SERÁ EL ÚNICO RESPONSABLE DEL CONTENIDO QUE PUBLIQUE DENTRO DE <b>www.GRUPO GACK.net</b>, YA QUE DECLARA SER EL TITULAR DE LOS DERECHOS PATRIMONIALES DE AUTOR SOBRE EL MISMO O CONTAR CON LA AUTORIZACIÓN
                DE SU LEGÍTIMO TITULAR PARA INCLUIRLO DENTRO DE <b>www.GRUPO GACK.net</b>. POR LO ANTERIOR, SE COMPROMETE A SACAR EN PAZ Y A SALVO A "GRUPO GACK" DE CUALQUIER RECLAMACIÓN, QUEJA, DEMANDA O DENUNCIA QUE CUALQUIER TERCERO INICIE
                EN VIRTUD DE LA ORIGINALIDAD O TITULARIDAD DE DICHO CONTENIDO.
                <br/>
                <br/>
                <b>4.8.-</b> EN EL CASO DE QUE ALGÚN USUARIO O UN TERCERO CONSIDEREN QUE CUALQUIERA DE LOS CONTENIDOS QUE SE ENCUENTREN O SEAN INTRODUCIDOS EN <b>www.GRUPO GACK.net</b>, Y/O CUALQUIERA DE SUS SERVICIOS, VIOLEN SUS DERECHOS DE PROPIEDAD
                INTELECTUAL, DEBERÁN ENVIAR UNA NOTIFICACIÓN A <b>www.GRUPO GACK.net</b>, A LA DIRECCIÓN QUE ARRIBA APARECE, EN LA QUE INDIQUEN, CUANDO MENOS:
                (I) LOS DATOS PERSONALES VERÍDICOS (NOMBRE, DIRECCIÓN, NÚMERO DE TELÉFONO Y DIRECCIÓN DE CORREO ELECTRÓNICO DEL RECLAMANTE); (II) LA FIRMA AUTÓGRAFA CON LOS DATOS PERSONALES DEL TITULAR DE LOS DERECHOS DE PROPIEDAD INTELECTUAL;
                (III) LA INDICACIÓN PRECISA Y COMPLETA DEL (LOS) CONTENIDO(S) PROTEGIDO(S) MEDIANTE LOS DERECHOS DE PROPIEDAD INTELECTUAL SUPUESTAMENTE INFRINGIDOS, ASÍ COMO DE SU LOCALIZACIÓN EN <b>www.GRUPO GACK.net</b>;
                (IV) UNA DECLARACIÓN EXPRESA Y CLARA DE QUE LA INTRODUCCIÓN DEL (LOS) CONTENIDO(S) INDICADO(S) SE HA REALIZADO SIN EL CONSENTIMIENTO DEL TITULAR DE LOS DERECHOS DE PROPIEDAD INTELECTUAL SUPUESTAMENTE INFRINGIDOS;
                (V) UNA DECLARACIÓN EXPRESA, CLARA Y BAJO LA RESPONSABILIDAD DEL RECLAMANTE DE QUE LA INFORMACIÓN PROPORCIONADA EN LA NOTIFICACIÓN ES EXACTA Y DE QUE LA INTRODUCCIÓN DEL (LOS) CONTENIDO(S) CONSTITUYE UNA VIOLACIÓN
                DE SUS DERECHOS DE PROPIEDAD INTELECTUAL.
                <br/>
                <br/>
                LIC. AGUSTÍN RÍOS AGUILAR ES LA PERSONA DESIGNADA POR "GRUPO GACK" PARA RESOLVER ESTE TIPO DE ANOMALÍAS. PARA REPORTAR CUALQUIER INFRACCIÓN SOBRE LOS CONTENIDOS DE LA PÁGINA WEB, POR FAVOR CONTACTE A:
                <br/>
                <br/>NOMBRE: AGUSTÍN RÍOS AGUILAR
                <br/>CORREO ELECTRÓNICO: arios@riosabogados.com
                <br/>DIRECCIÓN: GOLDSMITH 38, DESP. 103-105, COLONIA POLANCO CHAPULTEPEC, C.P. 11560, MÉXICO DISTRITO FEDERAL.
                <br/>TELÉFONO Y FAX: 52802789
                <br/>
            </p>

            <h6>5.- MATERIAL PUBLICITARIO.</h6>
            <p>
                <b>5.1.-</b> EL USUARIO RECONOCE Y ACEPTA "GRUPO GACK", ES UNA ORGANIZACIÓN INDEPENDIENTE DE TERCEROS PATROCINADORES Y ANUNCIANTES CUYA INFORMACIÓN, IMÁGENES, ANUNCIOS Y DEMÁS MATERIAL PUBLICITARIO O PROMOCIONAL
                (EL "MATERIAL PUBLICITARIO") PUDIERA ESTAR PUBLICADO EN <b>www.GRUPO GACK.net</b>.
                <br/>
                <br/>
                <b>5.2.-</b> EL USUARIO RECONOCE Y ACEPTA QUE EL MATERIAL PUBLICITARIO NO FORMA PARTE DEL CONTENIDO PRINCIPAL QUE, SE PUBLICA EN <b>www.GRUPO GACK.net</b>. ASIMISMO, EL USUARIO RECONOCE Y ACEPTA EN ESTE ACTO, QUE EL MATERIAL PUBLICITARIO
                SE ENCUENTRA PROTEGIDO POR LAS LEYES QUE EN MATERIA DE PROPIEDAD INTELECTUAL E INDUSTRIAL RESULTEN APLICABLES.
                <br/>
            </p>

            <h6>6.- NEGACIÓN DE GARANTÍAS.</h6>
            <p>
                <b>6.1.-</b> EL USUARIO ESTA DE ACUERDO QUE EL USO DE <b>www.GRUPO GACK.net</b>, SE REALIZA BAJO SU PROPIO RIESGO, QUE LOS SERVICIOS QUE SE PRESTAN Y OFRECEN EN <b>www.GRUPO GACK.net</b>, SE PREVÉN SOBRE UNA BASE "TAL CUAL" Y "SEGÚN SEAN DISPONIBLES".
                "GRUPO GACK" NO GARANTIZA QUE <b>www.GRUPO GACK.net</b>, SATISFAGA AL CIEN POR CIENTO LOS REQUERIMIENTOS DEL USUARIO, O QUE LOS SERVICIOS DE <b>www.GRUPO GACK.net</b>, SE MANTENGAN SIEMPRE ININTERRUMPIDOS, EN TIEMPO, SEGUROS O LIBRES DE ERRORES.
                <br/>
                <br/>
                <b>6.2.-</b> "GRUPO GACK" NO GARANTIZA O AVALA EN NINGUNA FORMA LA VERACIDAD, PRECISIÓN, LEGALIDAD, MORALIDAD O NINGUNA OTRA CARACTERÍSTICA DEL CONTENIDO DEL MATERIAL QUE SE PUBLIQUE EN <b>www.GRUPO GACK.net</b>.
                "GRUPO GACK" SE LIBERA DE CUALQUIER RESPONSABILIDAD Y CONDICIONES, TANTO EXPRESAS COMO IMPLÍCITAS, EN RELACIÓN CON LOS SERVICIOS E INFORMACIÓN CONTENIDA O DISPONIBLE EN O A TRAVÉS DE <b>www.GRUPO GACK.net</b>, INCLUYENDO, SIN LIMITACIÓN ALGUNA:
                <br/>
                <br/>
                <b>6.2.1.-</b> LA DISPONIBILIDAD DE USO DE <b>www.GRUPO GACK.net</b>, POR MOTIVO DE PROBLEMAS TÉCNICOS IMPUTABLES A LOS SISTEMAS DE COMUNICACIÓN Y TRANSMISIÓN DE DATOS.
                <br/>
                <br/>
                <b>6.2.2.-</b> LA AUSENCIA DE VIRUS, ERRORES, DESACTIVADORES O CUALESQUIER OTRO MATERIAL CONTAMINANTE O CON FUNCIONES DESTRUCTIVAS EN LA INFORMACIÓN O PROGRAMAS DISPONIBLES EN O A TRAVÉS DE <b>www.GRUPO GACK.net</b>,
                O EN GENERAL, CUALQUIER FALLA EN <b>www.GRUPO GACK.net</b>.
                <br/>
                <br/>
                <b>6.2.3.-</b> NO OBSTANTE LO ANTERIOR, "GRUPO GACK" O SUS PROVEEDORES PODRÁN ACTUALIZAR EL CONTENIDO DE <b>www.GRUPO GACK.net</b>, CONSTANTEMENTE, POR LO QUE SE PIDE AL USUARIO TOMAR EN CUENTA QUE, ALGUNA INFORMACIÓN PUBLICITADA
                O CONTENIDA EN O A TRAVÉS DE <b>www.GRUPO GACK.net</b>, PUEDE HABER QUEDADO OBSOLETA Y/O CONTENER IMPRECISIONES O ERRORES TIPOGRÁFICOS U ORTOGRÁFICOS.
                <br/>
            </p>
            
            <h6>7.- LIMITACIONES A LA RESPONSABILIDAD.</h6>
            <p>
                <b>7.1.-</b> HASTA EL MÁXIMO PERMITIDO POR LAS LEYES APLICABLES, "GRUPO GACK" NO SERÁ RESPONSABLE, EN NINGÚN CASO, POR DAÑOS DIRECTOS, ESPECIALES, INCIDENTALES, INDIRECTOS, O CONSECUENCIALES QUE EN CUALQUIER FORMA SE DERIVEN
                O SE RELACIONEN CON, DE MANERA ENUNCIATIVA MÁS NO LIMITATIVA CON:
                <br/>
                <br/>
                <b>7.1.1.-</b> EL USO O EJECUCIÓN DE <b>www.GRUPO GACK.net</b> CON EL RETRASO O FALTA DE DISPONIBILIDAD DE USO "GRUPO GACK".
                <br/>
                <br/>
                <b>7.1.2.-</b> LA PROVEEDURÍA DE O FALTA DE PROVEEDURÍA DE SERVICIOS DE CUALESQUIER INFORMACIÓN O GRÁFICOS CONTENIDOS O PUBLICADOS EN O A TRAVÉS DE <b>www.GRUPO GACK.net</b>.
                <br/>
                <br/>
                <b>7.1.3.-</b> DE LA ACTUALIZACIÓN O FALTA DE ACTUALIZACIÓN DE LA INFORMACIÓN.
                <br/>
                <br/>
                <b>7.1.4.-</b> QUE LA INFORMACIÓN HAYA SIDO ALTERADA O MODIFICADA, EN TODO O EN PARTE, LUEGO DE HABER SIDO INCLUIDA EN <b>www.GRUPO GACK.net</b>.
                <br/>
                <br/>
                <b>7.1.5.-</b> CUALQUIER OTRO ASPECTO O CARACTERÍSTICA DE LA INFORMACIÓN CONTENIDA O PUBLICADA EN <b>www.GRUPO GACK.net</b> O A TRAVÉS DE LAS LIGAS QUE EVENTUALMENTE SE INCLUYAN EN EL MISMO.
                <br/>
                <br/>
                <b>7.1.6.-</b> LA PROVEEDURÍA O FALTA DE PROVEEDURÍA QUE LOS DEMÁS SERVICIOS, TODOS LOS SUPUESTOS ANTERIORES SERÁN VIGENTES, AÚN EN LOS CASOS EN QUE SE LE HUBIERE NOTIFICADO O AVISADO A "GRUPO GACK" ACERCA DE LA POSIBILIDAD
                DE QUE SE OCASIONARAN DICHOS DAÑOS.
                <br/>
            </p>

            <h6>8.- MODIFICACIONES <b>www.GRUPO GACK.net</b>.</h6>
            <p>
                <b>8.1.-</b> "GRUPO GACK" PODRÁ EN CUALQUIER MOMENTO Y CUANDO LO CONSIDERE CONVENIENTE, SIN NECESIDAD DE AVISO AL USUARIO, REALIZAR CORRECCIONES, ADICIONES, MEJORAS O MODIFICACIONES AL CONTENIDO, PRESENTACIÓN, INFORMACIÓN, SERVICIOS,
                ÁREAS, BASES DE DATOS Y DEMÁS ELEMENTOS DE <b>www.GRUPO GACK.net</b> SIN QUE ELLO DE LUGAR NI DERECHO A NINGUNA RECLAMACIÓN O INDEMNIZACIÓN, NI QUE LO MISMO IMPLIQUE RECONOCIMIENTO DE RESPONSABILIDAD ALGUNA A FAVOR DEL USUARIO.
                <br/>
            </p>

            <h6>9.- MODIFICACIONES AL CONVENIO.</h6>
            <p>
                <b>9.1.-</b> "GRUPO GACK" SE RESERVA EL DERECHO DE MODIFICAR LOS TÉRMINOS Y CONDICIONES DE ÉSTE CONVENIO EN CUALQUIER TIEMPO, SIENDO EFECTIVAS DICHAS MODIFICACIONES DE FORMA INMEDIATA:
                <br/>
                <br/>
                <b>9.1.1.-</b> POR LA PUBLICACIÓN EN <b>www.GRUPO GACK.net</b> DEL CONVENIO MODIFICADO, O;
                <br/>
                <br/>
                <b>9.1.2.-</b> POR LA NOTIFICACIÓN AL USUARIO SOBRE DICHAS MODIFICACIONES. EL USUARIO ESTÁ DE ACUERDO EN REVISAR ESTE CONVENIO PERIÓDICAMENTE A FIN DE ESTAR AL TANTO DE DICHAS MODIFICACIONES. NO OBSTANTE LO ANTERIOR,
                CADA OCASIÓN QUE EL USUARIO TENGA ACCESO A <b>www.GRUPO GACK.net</b> SE CONSIDERARÁ COMO ACEPTACIÓN ABSOLUTA A LAS MODIFICACIONES DEL CONVENIO.
                <br/>
            </p>

            <h6>10.- EXCLUYENTE DE RESPONSABILIDAD.</h6>
            <p>
                <b>10.1.-</b> OCASIONALMENTE, "GRUPO GACK" PODRÁ ADICIONAR LOS TÉRMINOS Y CONDICIONES DEL PRESENTE CONVENIO CON PROVISIONES ADICIONALES RELATIVAS A ÁREAS ESPECÍFICAS O NUEVOS SERVICIOS QUE SE PROPORCIONEN
                EN O A TRAVÉS DE <b>www.GRUPO GACK.net</b> ("TÉRMINOS ADICIONALES"), LOS CUALES SERÁN PUBLICADOS EN LAS ÁREAS ESPECÍFICAS O NUEVOS SERVICIOS DE <b>www.GRUPO GACK.net</b> PARA SU LECTURA Y ACEPTACIÓN. EL USUARIO RECONOCE Y ACEPTA QUE DICHOS TÉRMINOS
                ADICIONALES FORMAN PARTE INTEGRANTE DEL PRESENTE CONVENIO PARA TODOS LOS EFECTOS LEGALES A QUE HAYA LUGAR.
                <br/>
                <br/>
                <b>10.2.-</b> "GRUPO GACK" NO TIENE OBLIGACIÓN DE CONTROLAR Y NO CONTROLA LA UTILIZACIÓN QUE LOS USUARIOS HACEN DEL PORTAL, DE LOS SERVICIOS Y DE LOS CONTENIDOS.
                <br/>
                <br/>
                <b>10.3.-</b> EN PARTICULAR, <b>www.GRUPO GACK.net</b> NO GARANTIZA QUE LOS USUARIOS UTILICEN EL PORTAL, LOS SERVICIOS Y LOS CONTENIDOS DE CONFORMIDAD CON ESTAS CONDICIONES GENERALES Y, EN SU CASO, LAS CONDICIONES PARTICULARES APLICABLES,
                NI QUE LO HAGAN DE FORMA DILIGENTE Y PRUDENTE. <b>www.GRUPO GACK.net</b> TAMPOCO TIENE LA OBLIGACIÓN DE VERIFICAR Y NO VERIFICA LA IDENTIDAD DE LOS USUARIOS, NI LA VERACIDAD, VIGENCIA, EXHAUSTIVIDAD Y/O AUTENTICIDAD DE LOS DATOS QUE LOS USUARIOS
                PROPORCIONAN SOBRE SÍ MISMOS A OTROS USUARIOS.
                <br/>
                <br/>
                <b>10.4.-</b> "GRUPO GACK" NO ASUME RESPONSABILIDAD ALGUNA POR LOS DAÑOS Y PERJUICIOS DE TODA NATURALEZA QUE PUDIERAN DERIVARSE DE LA UTILIZACIÓN DE LOS SERVICIOS Y DE LOS CONTENIDOS POR PARTE DE LOS USUARIOS O QUE PUEDAN DERIVARSE
                DE LA FALTA DE VERACIDAD, VIGENCIA, EXHAUSTIVIDAD Y/O AUTENTICIDAD DE LA INFORMACIÓN QUE LOS USUARIOS PROPORCIONAN A OTROS USUARIOS ACERCA DE SÍ MISMOS Y, EN PARTICULAR, AUNQUE NO DE FORMA EXCLUSIVA, POR LOS DAÑOS Y PERJUICIOS DE
                TODA NATURALEZA QUE PUEDAN DERIVARSE DE LA SUPLANTACIÓN DE LA PERSONALIDAD DE UN TERCERO EFECTUADA POR UN USUARIO EN CUALQUIER CLASE DE COMUNICACIÓN REALIZADA A TRAVÉS DEL PORTAL.
                <br/>
            </p>

            <h6>11.- CESIÓN DE DERECHOS.</h6>
            <p>
                "GRUPO GACK" PODRÁ, EN CUALQUIER TIEMPO Y CUANDO ASÍ LO ESTIME CONVENIENTE, CEDER TOTAL O PARCIALMENTE SUS DERECHOS Y OBLIGACIONES DERIVADOS DEL PRESENTE CONVENIO. EN VIRTUD DE DICHA CESIÓN, "GRUPO GACK" QUEDARÁ LIBERADO DE CUALQUIER
                OBLIGACIÓN A FAVOR DEL USUARIO ESTABLECIDA EN EL PRESENTE CONVENIO.
                <br/>
            </p>

            <h6>12.- INDEMNIZACIÓN.</h6>
            <p>
                EL USUARIO ESTÁ DE ACUERDO EN INDEMNIZAR A "GRUPO GACK" SUS AFILIADOS, PROVEEDORES, VENDEDORES Y ASESORES DE CUALQUIER ACCIÓN, DEMANDA O RECLAMACIÓN (INCLUSO DE HONORARIOS DE ABOGADOS Y DE COSTAS JUDICIALES) DERIVADAS DE CUALESQUIER
                INCUMPLIMIENTO POR PARTE DEL USUARIO AL PRESENTE CONVENIO, INCLUYENDO, SIN LIMITACIÓN DE ALGUNA DE LAS DERIVADAS:
                <br/>
                <br/>
                <b>12.1.-</b> DE CUALQUIER ASPECTO RELATIVO AL USO DE <b>www.GRUPO GACK.net</b>
                <br/>
                <br/>
                <b>12.2.-</b> DE LA INFORMACIÓN CONTENIDA O DISPONIBLE EN O A TRAVÉS DE <b>www.GRUPO GACK.net</b>;
                <br/>
                <br/>
                <b>12.3.-</b> DE INJURIAS, DIFAMACIÓN O CUALESQUIER OTRA CONDUCTA VIOLATORIA DEL PRESENTE CONVENIO POR PARTE DEL USUARIO EN EL USO DE <b>www.GRUPO GACK.net</b>;
                <br/>
                <br/>
                <b>12.4.-</b> DE VIOLACIÓN A LAS LEYES APLICABLES O TRATADOS INTERNACIONALES RELATIVOS A LOS DERECHOS DE AUTOR O PROPIEDAD INTELECTUAL, CONTENIDOS O DISPONIBLES EN, O A TRAVÉS DE <b>www.GRUPO GACK.net</b>.
                <br/>
            </p>

            <h6>13.- TERMINACIÓN.</h6>
            <p>
                "GRUPO GACK" SE RESERVA EL DERECHO, A SU EXCLUSIVA DISCRECIÓN, Y SIN NECESIDAD DE AVISO O NOTIFICACIÓN AL USUARIO, PARA:
                <br/>
                <br/>
                <b>13.1.-</b> TERMINAR DEFINITIVAMENTE EL PRESENTE CONVENIO;
                <br/>
                <br/>
                <b>13.2.-</b> DESCONTINUAR O DEJAR DE PUBLICAR DEFINITIVAMENTE <b>www.GRUPO GACK.net</b>, SIN RESPONSABILIDAD ALGUNA PARA "GRUPO GACK", SUS AFILIADOS O PROVEEDORES.
                <br/>
            </p>
            
            <h6>14.- SUBSISTENCIA.</h6>
            <p>
                <b>14.1.-</b> ESTOS TÉRMINOS Y CONDICIONES, ASÍ COMO LOS TÉRMINOS ADICIONALES CONSTITUYEN EL ACUERDO ÍNTEGRO ENTRE LAS PARTES, Y SUSTITUYE CUALQUIER OTRO ACUERDO O CONVENIO CELEBRADO CON ANTERIORIDAD. CUALESQUIER CLÁUSULA O PROVISIÓN
                DEL PRESENTE CONVENIO ASÍ COMO DE LOS TÉRMINOS ADICIONALES, LEGALMENTE DECLARADA INVÁLIDA, SERÁ ELIMINADA O MODIFICADA, A ELECCIÓN DE "GRUPO GACK", A FIN DE CORREGIR SU VICIO O DEFECTO. SIN EMBARGO, EL RESTO DE LAS CLÁUSULAS O PROVISIONES
                MANTENDRÁN SU FUERZA, OBLIGATORIEDAD Y VALIDEZ.
                <br/>
            </p>

            <h6>15.- NO RENUNCIA DE DERECHOS.</h6>
            <p>
                <b>15.1.-</b> LA INACTIVIDAD POR PARTE DE "GRUPO GACK", SUS AFILIADOS O PROVEEDORES AL EJERCICIO DE CUALQUIER DERECHO O ACCIÓN DERIVADOS DEL PRESENTE CONVENIO, EN NINGÚN MOMENTO DEBERÁ INTERPRETARSE COMO RENUNCIA A DICHOS DERECHOS O ACCIONES.
                <br/>
            </p>

            <h6>16.- LEGISLACIÓN APLICABLE Y JURISDICCIÓN.</h6>
            <p>
                <b>16.1.-</b> EN CASO DE QUE SE PRESENTE UNA CONTROVERSIA QUE SE DERIVE DEL PRESENTE CONTRATO O SE RELACIONE CON ÉL, LAS PARTES SE OBLIGAN A TRATAR DE LLEGAR A UNA TRANSACCIÓN AMISTOSA DE ESA CONTROVERSIA MEDIANTE LA MEDIACIÓN;
                ÉSTA TENDRÁ LUGAR DE CONFORMIDAD CON EL REGLAMENTO DE MEDIACIÓN DE LA CÁMARA NACIONAL DE COMERCIO DE LA CIUDAD DE MÉXICO, VIGENTE AL MOMENTO DEL INICIO DE LA MEDIACIÓN. DE NO SER ASÍ, LAS PARTES PODRÁN ACUDIR ANTE LOS TRIBUNALES
                DE LA CIUDAD DE MÉXICO, DISTRITO FEDERAL, MÉXICO, RENUNCIANDO A CUALQUIER OTRO FUERO QUE PUDIERA CORRESPONDERLES, QUEDANDO ESTE CONVENIO A LA INTERPRETACIÓN DE ACUERDO CON LAS LEYES DE LA REPÚBLICA MEXICANA.
                <br/>
            </p>
        </div>
    );
};

export default TerminosyCondiciones;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_rh, expediente_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Expediente = ({ id }) => {
    const [ empleados, setEmpleados ] = useState( null );
    const [ tiposDocumentos, setTiposDocumentos ] = useState( null );
    useEffect(() => {
        axiosTiposDocumentos();
        axiosEmpleados();
    }, []);

    const axiosEmpleados = () => {
        axios.get( `${servicios_grupogack_rh}/empleado/activos/`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setEmpleados( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setEmpleados( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio TiposDocumentos */
    const axiosTiposDocumentos = () => {
        axios.get( `${servicios_grupogack_rh}/documentos/catalogo/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposDocumentos( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposDocumentos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={ id }>
            { empleados && tiposDocumentos ?
                <DataTableService id={id} title={ expediente_ }
                    columns={[
                        { field: "nombreCompleto", title: "Nombre" },
                        { field: "empleadoClave", title: "Núm. Empleado" },
                        { field: "puestoNom", title: "Plaza / Puesto" },
                        { field: "nominaNombre", title: "Nómina" },
                        { field: "empleadoRfc", title: "RFC" },
                        { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                    ]}
                    hideIconAdd hideIconDelete
                    data={ empleados }
                    informacionAdicional={{ tiposDocumentos }} /> : <SpinnerOval />
            }
        </div>
    );
}
 
export default Expediente;
import React, { useState, useEffect, createElement, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupogack_org } from '../../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../../Services/AuthService';
import { Spinner } from 'reactstrap';
import PlazasModal from '../OrganigramaModal';
import ScrollContainer from 'react-indiana-drag-scroll';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AlertForm from '../../../../../../../../Services/AlertForm';
import AlertSyE from '../../../../../../../../Services/Alerts/AlertSyE';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../../../Media/Img/profile.jpg';
import { Tooltip } from '@material-ui/core';

const OrganigramaPlazas = ({ data, permisos }) => {

    const [html, setHTML] = useState([]);
    const [toDoOrg, setToDoOrg] = useState(false);    
    const [organigrama, setOrganigrama] = useState([]);
    const [openAlertSyEError, setOpenAlertSyEError] = useState(false);
    const [openAlertSyEEError, setOpenAlertSyEEError] = useState(false);
    const [openAlertSyESuccess, setOpenAlertSyESuccess] = useState(false);
    const [openAlertSyEESuccess, setOpenAlertSyEESuccess] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenEdit, setModalIsOpenEdit] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenInfo, setModalIsOpenInfo] = useState({ state: false, type: null, data: {} });
    const [modalIsOpenNuevoOrg, setModalIsOpenNuevoOrg] = useState({ state: false, type: null, data: {} });
    
    let nuevoHtml = [];

    const servicioEnviar = (info, type) => {
        axios.post(`${servicios_grupogack_org}/plaza/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(type === 1 ) setOrganigrama([res.data.dataResponse]);
                    if(type === 2 ) setOrganigrama([...organigrama, res.data.dataResponse]);
                    setOpenAlertSyEESuccess(true);
                    break;
                default:
                    setOpenAlertSyEError(true);
                    break;
            }
        })
        .catch(e => {
            setOpenAlertSyEError(true);
        });
    };

    const enviarNuevoOrg = dataR => {
        const info = {
                        areaid: data.id,
                        plazaclave: dataR.clave,
                        plazanivel: dataR.nivel,
                        plazaidsup: dataR.id,
                        plazadisponible: false,
                        plazasecuencia: 1,
                    };

        servicioEnviar(info, 1); 
    };

    const enviar = dataR => {
        const itemshermanos = (organigrama.filter(hijo => hijo.plazaidsup === dataR.id)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia)
        });

        let orden = 1;
        if(itemshermanos.length > 0) {
            orden = (itemshermanos[itemshermanos.length - 1].plazasecuencia) + 1;
        }

        const info = {
                        areaid: data.id,
                        plazaclave: dataR.clave,
                        plazanivel: dataR.nivel + 1,
                        plazaidsup: dataR.id,
                        plazadisponible: false,
                        plazasecuencia: orden
                    };
                    
        servicioEnviar(info, 2);
    };

    const actualizar = item => {
        if(permisos.find(permiso => permiso.id === 2)) {
            const info = { 
                areaid: data.id,
                plazaclave: item.plazaclave,
                plazanivel: item.plazanivel,
                plazasecuencia: item.plazasecuencia,
                plazadisponible: item.plazadisponible,
                plazaidsup: item.plazaidsup
            };

            axios.put(`${servicios_grupogack_org}/plaza/${item.id}`, info, headersAuth())
            .then(response => {
                if(response.data.status === 200) {
                    let itemOrg = organigrama.find(element => element.id === item.id );
                    
                    itemOrg.plazaclave = response.data.dataResponse.plazaclave;
                    itemOrg.plazanivel = response.data.dataResponse.plazanivel;
                    itemOrg.plazasecuencia = response.data.dataResponse.plazasecuencia;
                    itemOrg.plazadisponible = response.data.dataResponse.plazadisponible;
                    itemOrg.plazaidsup = response.data.dataResponse.plazaidsup;

                    setOpenAlertSyEESuccess(true);
                    return true;
                } else {
                    setOrganigrama(null);
                    setOpenAlertSyEError(true);
                    return false;
                }
            })
            .catch(e => {
                setOrganigrama(null);
                setOpenAlertSyEError(true);
                return false;
            });
        }
    };

    const agregar = (id, nivel) => {
        if(permisos.find(permiso => permiso.id === 1)) {
            setModalIsOpen({ state: true, type: 'agregarPlaza', data: { id, nivel } });
        }
    };

    const editar = item => {
        if(permisos.find(permiso => permiso.id === 2)) {
            setModalIsOpenEdit({ state: true, type: 'editarPlaza', data: item });
        }
    };

    const eliminar = id => {
        if(permisos.find(permiso => permiso.id === 3)) {
            const numNodosHijos = (organigrama.filter(item => item.plazaidsup === id)).length;

            axios.delete(`${servicios_grupogack_org}/plaza/${id}`, headersAuth())
            .then(response => {
                if(response.data.status === 200) {
                    if(numNodosHijos === 0) {
                        setOpenAlertSyEESuccess(true);
                        if(organigrama.length > 1) {
                            const nuevoOrganigrama = organigrama.filter(item => item.id !== id);
                            setOrganigrama(nuevoOrganigrama);
                        } else {
                            setOrganigrama(null);
                        }
                    }
                } else {
                    setOpenAlertSyEEError(true);
                }
            })
            .catch((error) => {
                setOpenAlertSyEEError(true);
            });
        }
    };

    var idDrag = 0;
    var nivelDrag = 0;

    const allowDrop = e => {
        e.preventDefault();
    };
      
    const drag = e => {
        if(permisos.find(permiso => permiso.id === 2)) {
            e.dataTransfer.setData("div", e.target.id);
            idDrag = parseInt(e.target.id, 10);
            nivelDrag = (organigrama.find(item => item.id === idDrag)).plazanivel;
        }
    };
      
    const drop = e => {
        if(permisos.find(permiso => permiso.id === 2)) {
            const idDrop = parseInt(e.target.id, 10);
            //se obtiene informacion del elemento que se mueve, para editar 
            let itemDrag = organigrama.find(item => item.id === idDrag);
            //se obtiene informacion del nuevo padre
            const datosNuevoPadre = organigrama.find(item => item.id === idDrop);

            const buscaRelaciones = (padre, nivel) => {
                if(itemDrag.plazanivel < nivel) {
                    if(padre === itemDrag.id) {
                        idDrag = 0;
                        return false;
                    } else {
                        const datosSigItem = organigrama.find(item => item.id === padre); 
                        buscaRelaciones(datosSigItem.plazaidsup, datosSigItem.plazanivel);   
                    }
                }
                return true;
            };

            if(idDrag > 0 & idDrop > 0 & idDrag !== idDrop) var acceder = buscaRelaciones(datosNuevoPadre.plazaidsup, datosNuevoPadre.plazanivel);

            //aquí se ejecutan los cambios 
            if(acceder & idDrag > 0 & idDrop > 0 & idDrag !== idDrop) {
                let nuevoOrganigrama = organigrama.filter(item => item.id !== idDrag);
                
                //nuevos valores de item que se cambiará de nodo padre
                const newHermanos = (organigrama.filter(item => item.plazaidsup === datosNuevoPadre.id)).sort((a, b) => {
                    return (a.plazasecuencia - b.plazasecuencia)
                });

                // se asigna nuevo valor de orgen
                let plazasecuencia 
                if(newHermanos.length > 0) {
                    plazasecuencia = newHermanos[newHermanos.length - 1].plazasecuencia + 1;
                } else {
                    plazasecuencia = 1;
                }

                const nuevosValores = {
                    plazaclave: itemDrag.plazaclave,
                    plazanivel: datosNuevoPadre.plazanivel + 1,
                    plazasecuencia: plazasecuencia,
                    plazadisponible: itemDrag.plazadisponible,
                    plazaidsup: idDrop,
                    id: itemDrag.id
                };

                actualizar(nuevosValores);

                //ajustar nivel de los hijos (-1);
                const ajustarNivelHijos = (padre) => {
                    //diferencia entre nodo y nuevo padre antes del cambio
                    var hijos = nuevoOrganigrama.filter(item => item.plazaidsup === padre.id);
                    const copiaNivelDrag = nivelDrag;
                    if(hijos.length > 0) {
                        for(let nh = 0; nh < hijos.length; nh++) {
                            let hijo = nuevoOrganigrama.find(item => item.id === hijos[nh].id);
                            nivelDrag = hijo.plazanivel
                            hijo.plazanivel = padre.plazanivel + (hijo.plazanivel - copiaNivelDrag);
                            actualizar(hijo);
                            ajustarNivelHijos(hijos[nh]);
                        }
                        return;
                    }
                    return;
                }//termina ajuste de nivel
                ajustarNivelHijos(itemDrag);

                setToDoOrg(false);
                setOrganigrama([...nuevoOrganigrama, itemDrag]);
            }
        }
    };

    const moverIzquierda = (id, padre, orden) => {
        let item = organigrama.find(item => item.id === id);  
        const itemshermanos = (organigrama.filter(item => item.plazaidsup === padre & item.plazasecuencia < orden)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia)
        });
        let itemAnterior = itemshermanos[itemshermanos.length - 1];
        const nuevoOrganigrama = organigrama.filter(item => item.id !== id & item.id !== itemAnterior.id );
        item.plazasecuencia = itemAnterior.plazasecuencia;
        actualizar(item);
        itemAnterior.plazasecuencia = itemAnterior.plazasecuencia + 1;
        actualizar(itemAnterior);
        setOrganigrama([...nuevoOrganigrama, item, itemAnterior]);
        setToDoOrg(false);
    };
    
    const moverDerecha = (id, padre, orden) => {
        let item = organigrama.find(item => item.id === id);
        const itemshermanos = (organigrama.filter(item => item.plazaidsup === padre & item.plazasecuencia > orden)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia);
        });
        let itemSiguiente = itemshermanos[0];
        const nuevoOrganigrama = organigrama.filter(item => item.id !== id & item.id !== itemSiguiente.id);

        itemSiguiente.plazasecuencia = item.plazasecuencia;
        actualizar(itemSiguiente);
        item.plazasecuencia = item.plazasecuencia + 1;
        actualizar(item);
        
        setOrganigrama([...nuevoOrganigrama, item, itemSiguiente]);
        setToDoOrg(false);
    };

    const moverArriba = nodo => {
        const padreInfo = organigrama.find(item => item.id === nodo.plazaidsup);
        let copiaNodo = nodo;
        
        if((copiaNodo.plazanivel - padreInfo.plazanivel) === 1) {
            const hijos = (organigrama.filter(hijo => hijo.plazaidsup === padreInfo.plazaidsup)).sort((a, b) => {
                return (a.plazasecuencia - b.plazasecuencia);
            });
            copiaNodo.plazaidsup = padreInfo.plazaidsup;
            copiaNodo.plazasecuencia = hijos[hijos.length - 1].plazasecuencia + 1;
        }
        
        copiaNodo.plazanivel = copiaNodo.plazanivel - 1;
        actualizar(copiaNodo);
        //ajustar nivel de los hijos (-1);
        const ajustarNivelHijos = (id) => {
            const hijos = organigrama.filter(item => item.plazaidsup === id);
            
            if(hijos.length > 0){
                for(let nh = 0; nh < hijos.length; nh++) {
                    hijos[nh].plazanivel = hijos[nh].plazanivel - 1;
                    actualizar(hijos[nh]);
                    ajustarNivelHijos(hijos[nh].id);
                }
            }

        }//termina ajuste de nivel
        ajustarNivelHijos(nodo.id);
        setToDoOrg(false);
    };

    const moverAbajo = nodo => {
        //nuevo orden
        let copiaNodo = nodo;
        copiaNodo.plazanivel = copiaNodo.plazanivel + 1;
        actualizar(copiaNodo)
        //ajustar nivel de los hijos (+1);
        const ajustarNivelHijos = (id) => {
            const hijos = organigrama.filter(item => item.plazaidsup === id);
            
            if(hijos.length > 0){
                for(let nh = 0; nh < hijos.length; nh++) {
                    hijos[nh].plazanivel = hijos[nh].plazanivel + 1;
                    actualizar(hijos[nh]);
                    ajustarNivelHijos(hijos[nh].id);

                }
            }
        }//termina ajuste de nivel
        ajustarNivelHijos(nodo.id);
        setToDoOrg(false);
    };

    const generaOrganigrama = padre => {    
        const hijos = (organigrama.filter(hijo => hijo.plazaidsup === padre.id)).sort((a, b) => {
            return (a.plazasecuencia - b.plazasecuencia);
        });

        if(hijos.length > 0) {
            let padreshijos = [];
            for(let x = 0; x < hijos.length; x++) {
                const respuesta = generaOrganigrama(hijos[x]);
                const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                    ?   <Tooltip title="Agregar nueva plaza" key={`btnAgregar${hijos[x].id}`}><div className="btnAgregar" onClick={() => agregar(hijos[x].id, hijos[x].plazanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                let btnEliminar = (permisos.find(permiso => permiso.id === 3))
                                    ?   <Tooltip title="Eliminar plaza" key={`btnEliminar${hijos[x].id}`}><div className="btnEliminar" onClick={() => eliminar(hijos[x].id, hijos[x].plazaidsup, hijos[x].plazasecuencia)}><DeleteForeverOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                
                if(respuesta) btnEliminar = '';

                const btnInfo = (permisos.find(permiso => permiso.id === 4))
                                ?   <Tooltip title="Mostrar información" key={`btnInfo${hijos[x].id}`}><div className="btnInfo" onClick={() => setModalIsOpenInfo({ state: true, type: "infoPlaza", data: hijos[x] })}><InfoOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
                const btnArriba = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Subir nivel" key={`btnArriba${hijos[x].id}`}><div className="btnArriba" onClick={() => moverArriba(hijos[x])}><ArrowUpwardIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                const btnAbajo = (permisos.find(permiso => permiso.id === 2))
                                ?   <Tooltip title="Bajar nivel" key={`btnAbajo${hijos[x].id}`}><div className="btnAbajo" onClick={() => moverAbajo(hijos[x])}><ArrowDownwardIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                :   null;
                const btnIzquierda = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Mover a la izquierda" key={`btnIzquierda${hijos[x].id}`}><div className="btnIzquierda" onClick={() => moverIzquierda(hijos[x].id, hijos[x].plazaidsup, hijos[x].plazasecuencia)}><ArrowBackOutlinedIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                    :   null;
                const btnDerecha = (permisos.find(permiso => permiso.id === 2))
                                    ?   <Tooltip title="Mover a la derecha" key={`btnDerecha${hijos[x].id}`}><div className="btnDerecha" onClick={() => moverDerecha(hijos[x].id, hijos[x].plazaidsup, hijos[x].plazasecuencia)}><ArrowForwardOutlinedIcon style={{ fontSize: '1.3em' }} /></div></Tooltip>
                                    :   null;

                const h5 = createElement('h5', {key: `h5${hijos[x].id}`, className: "name"}, (hijos[x].plazadisponible === false) ? (hijos[x].empleadoNom) ? [<strong key={`str${hijos[x].id}`}>Nombre: </strong>, `${hijos[x].empleadoNom} ${hijos[x].empleadoPat} ${hijos[x].empleadoMat}`] : <strong key={`str${hijos[x].id}`}>Plaza No disponible</strong> : <strong key={`str${hijos[x].id}`}>Plaza Disponible</strong>);
                const h6 = createElement('h6', {id: hijos[x].id, key: `h6${hijos[x].id}`, className: "num_empleado"}, [<strong key={`str1${hijos[x].id}`}>NUP: </strong>, hijos[x].plazaclave]);
                const h61 = createElement('h6', {key: `h61${hijos[x].id}`, className: "puesto"}, (hijos[x].plazadisponible === false && hijos[x].empleadoNom) ? [<strong key={`str2${hijos[x].id}`}>Clave del Puesto: </strong>, hijos[x].puestoCve] : '');
                const h62 = createElement('h6', {key: `h62${hijos[x].id}`, className: "sueldo"}, (hijos[x].plazadisponible === false && hijos[x].empleadoNom) ? [<strong key={`str3${hijos[x].id}`}>Nivel del Puesto: </strong>, hijos[x].nivLaboralClave] : '');
                const h63 = createElement('h6', {key: `h63${hijos[x].id}`, className: "identificacion"}, [<strong key={`str4${hijos[x].id}`}>Identificación de la plaza: </strong>]);
                
                const div1 = createElement('div', {key: `div1${hijos[x].id}`, className: "info-wrap"}, [h5, h6, h61, h62, h63]);
                let divBtn = null;
                
                /*  Diferancia de niveles entre padre e hijo    */
                const dif = hijos[x].plazanivel - padre.plazanivel; 
                /*  Valores de Secuencia de los primer y ultimo nodo */
                const ultimoNodo = hijos[hijos.length - 1].plazasecuencia;
                const primerNodo = hijos[0].plazasecuencia;

                if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba]);

                } else if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha]);

                } else if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda]);

                } else if(hijos[x].plazanivel === 2 & dif === 1 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda, btnDerecha]);

                } else if(hijos[x].plazanivel === 3 & dif === 2 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda, btnDerecha]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length === 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnIzquierda]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === primerNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length > 1 & hijos[x].plazasecuencia === ultimoNodo) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnIzquierda]);

                } else if(hijos[x].plazanivel >= 3 & dif === 1 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnAbajo, btnDerecha,  btnIzquierda]);

                } else if(hijos[x].plazanivel >= 3 & dif === 2 & hijos.length > 1) {
                    divBtn = createElement('div', {key: `divBtn${hijos[x].id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo, btnArriba, btnDerecha,  btnIzquierda]);

                }
                
                const img = createElement('div', {key: `img${hijos[x].id}` , className: "profile-image"}, <Avatar alt="avatar" src={(hijos[x].foto) ? (hijos[x].foto !== "" && hijos[x].foto !== null) ? `data:image/jpeg;base64, ${atob(hijos[x].foto)}`: profileIMG : profileIMG} />);
                const div2 = createElement('div', {id: hijos[x].id, key: `div2${hijos[x].id}`, className: "personal-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, [img, div1, divBtn]);
                const div3 = createElement('div', {key: `div3${hijos[x].id}`, className: "info-block"}, div2);
                let elemento1 = createElement('label', {id: hijos[x].id, key: `label${hijos[x].id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, hijos[x].puestoNom);
                let elemento2 = <Tooltip title="Editar" key={`btnEditar${hijos[x].id}`}><div className="btnEditar" onClick={() => editar(hijos[x])}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;
                const div4 = createElement('div', {key: `div4${hijos[x].id}`, className: "department-info"}, [elemento1, elemento2]);
                const div5 = createElement('div', {id: hijos[x].id, key: `div5${hijos[x].id}`, className: "employee-card", draggable: (permisos.find(permiso => permiso.id === 2)) ? true : false, onDragStart: (e) => drag(e)}, [div4, div3]);
                let div6 = null;

                if(respuesta) {
                    if(respuesta.length === 1) {
                        div6 = createElement('div', {key: `div6${hijos[x].id}`, className: `node${dif} sub-list`}, div5);
                        const ul = createElement('ul', {key: `ul${hijos[x].id}`, className: "sub-list2"}, respuesta);
                        const li = createElement('li', {key: `li${hijos[x].id}`}, [div6, ul]);
                        padreshijos.push(li);
              
                    } else {
                        div6 = createElement('div', {key: `div6${hijos[x].id}`, className: `node${dif} sub-list`}, div5);
                        const ul = createElement('ul', {key: `ul${hijos[x].id}`, className: "sub-list"}, respuesta);
                        const li = createElement('li', {key: `li${hijos[x].id}`}, [div6, ul]);
                        padreshijos.push(li);
                    }

                } else {
                    div6 = createElement('div', {key: `div6${hijos[x].id}`, className: `node${dif} no-child sub-list`}, div5);
                    const li = createElement('li', {key: `li${hijos[x].id}`}, div6);
                    padreshijos.push(li);
                }
            }

            if(padre.plazaidsup === 0) {
                const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                    ?   <Tooltip title="Agregar nueva plaza" key={`btnAgregar${padre.id}`}><div className="btnAgregar" onClick={() => agregar(padre.id, padre.plazanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                    :   null;
                const btnInfo = (permisos.find(permiso => permiso.id === 4))
                                ?   <Tooltip title="Mostrar información" key={`btnInfo${padre.id}`}><div className="btnInfo" onClick={() => setModalIsOpenInfo({ state: true, type: "infoPlaza", data: padre })}><InfoOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
                
                const h5 = createElement('h5', {key: `h5${padre.id}`, className: "name"}, (padre.plazadisponible === false) ? (padre.empleadoNom) ? [<strong key={`str${padre.id}`}>Nombre: </strong>, `${padre.empleadoNom} ${padre.empleadoPat} ${padre.empleadoMat}`] : <strong key={`str${padre.id}`}>Plaza No disponible</strong> : <strong key={`str${padre.id}`}>Plaza Disponible</strong>);
                const h6 = createElement('h6', {key: `h6${padre.id}`, className: "num_empleado"}, [<strong key={`str1${padre.id}`}>NUP: </strong>, padre.plazaclave]);
                const h61 = createElement('h6', {key: `h61${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str2${padre.id}`}>Clave del Puesto: </strong>, padre.puestoCve] : '');
                const h62 = createElement('h6', {key: `h62${padre.id}`, className: "sueldo"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str3${padre.id}`}>Nivel del Puesto: </strong>, padre.nivLaboralClave] : '');
                const h63 = createElement('h6', {key: `h63${padre.id}`, className: "identificacion"}, [<strong key={`str4${padre.id}`}>Identificación de la plaza: </strong>]);
                
                const img = createElement('div', {key: `img${padre.id}` , className: "profile-image"}, <Avatar alt="avatar" src={(padre.foto) ? (padre.foto !== "" && padre.foto !== null) ? `data:image/jpeg;base64, ${atob(padre.foto)}` : profileIMG : profileIMG} />);
                const div1 = createElement('div', {key: `div1${padre.id}`, className: "info-wrap"}, [h5, h6, h61, h62, h63]);

                const divBtn = createElement('div', {key: `divBtn${padre.id}`, className: "btnsPosition"}, [btnAgregar, btnInfo]);
                const div2 = createElement('div', {id: padre.id, key: `div2${padre.id}`, className: "personal-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, [img, div1, divBtn]);
                const div3 = createElement('div', {key: `div3${padre.id}`, className: "info-block"}, div2);
                
                let elemento1 = createElement('label', {id: padre.id, key: `label${padre.id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, padre.puestoNom);
                let elemento2 = <Tooltip title="Editar" key={`btnEditar${padre.id}`}><div className="btnEditar" onClick={() => editar(padre)}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;

                const div4 = createElement('div', {key: `div4${padre.id}`, className: "master-info"}, [elemento1, elemento2]);
                const div5 = createElement('div', {key: `div5${padre.id}`, className: "employee-card"}, [div4, div3]);
                let div6 = null;
                
                if(padreshijos.length === 1) {
                    div6 = createElement('div', {key: `div6${padre.id}`, className: "node1"}, div5);
                    const ul2 = createElement('ul', {key: `ul2${padre.id}`}, padreshijos);
                    const div = createElement('div', {key: `ul2${padre.id}`, className: "sub-list"}, ul2);
                    const li = createElement('li', {key: `li${padre.id}`}, [div6, div]);
                    return (li);
          
                } else {
                    div6 = createElement('div', {key: `div6${padre.id}`, className: "node1"}, div5);
                    const ul2 = createElement('ul', {key: `ul2${padre.id}`, className: "sub-list"}, padreshijos);
                    const li = createElement('li', {key: `li${padre.id}`}, [div6, ul2]);
                    return (li);
                }
                    
            } else {
                return (padreshijos);
            }
        }

        if(hijos.length === 0 & padre.plazanivel === 1) {
            const btnAgregar = (permisos.find(permiso => permiso.id === 1))
                                ?   <Tooltip title="Agregar nueva plaza" key={`btnAgregar${padre.id}`}><div className="btnAgregar" onClick={() => agregar(padre.id, padre.plazanivel)}><AddOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
            const btnEliminar = (permisos.find(permiso => permiso.id === 3))
                                ?   <Tooltip title="Eliminar plaza" key={`btnEliminar${padre.id}`}><div className="btnEliminar" onClick={() => eliminar(padre.id, padre.plazaidsup, padre.plazasecuencia)}><DeleteForeverOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                                :   null;
            const btnInfo = (permisos.find(permiso => permiso.id === 4))
                            ?   <Tooltip title="Mostrar información" key={`btnInfo${padre.id}`}><div className="btnInfo" onClick={() => setModalIsOpenInfo({ state: true, type: "infoPlaza", data: padre })}><InfoOutlinedIcon style={{ fontSize: '1.3em' }}/></div></Tooltip>
                            :   null;

            const h5 = createElement('h5', {key: `h5${padre.id}`, className: "name"}, (padre.plazadisponible === false) ? (padre.empleadoNom) ? [<strong key={`str${padre.id}`}>Nombre: </strong>, `${padre.empleadoNom} ${padre.empleadoPat} ${padre.empleadoMat}`] : <strong key={`str${padre.id}`}>Plaza No disponible</strong> : <strong key={`str${padre.id}`}>Plaza Disponible</strong>);
            const h6 = createElement('h6', {key: `h6${padre.id}`, className: "num_empleado"}, [<strong key={`str1${padre.id}`}>NUP: </strong>, padre.plazaclave]);
            const h61 = createElement('h6', {key: `h61${padre.id}`, className: "puesto"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str2${padre.id}`}>Clave del Puesto: </strong>, padre.puestoCve] : '');
            const h62 = createElement('h6', {key: `h62${padre.id}`, className: "sueldo"}, (padre.plazadisponible === false && padre.empleadoNom) ? [<strong key={`str3${padre.id}`}>Nivel del Puesto: </strong>, padre.nivLaboralClave] : '');
            const h63 = createElement('h6', {key: `h63${padre.id}`, className: "identificacion"}, [<strong key={`str4${padre.id}`}>Identificación de la plaza: </strong>]);

            const img = createElement('div', {key: `img${padre.id}` , className: "profile-image"}, <Avatar alt="avatar" src={(padre.foto) ? (padre.foto !== "" && padre.foto !== null) ? `data:image/jpeg;base64, ${atob(padre.foto)}` : profileIMG : profileIMG} />);
            const div1 = createElement('div', {key: `div1${padre.id}`, className: "info-wrap"}, [h5, h6, h61, h62, h63]);
            const divBtn = createElement('div', {key: `divBtn${padre.id}`, className: "btnsPosition"}, [btnAgregar, btnEliminar, btnInfo]);
            const div2 = createElement('div', {id: padre.id, key: `div2${padre.id}`, className: "personal-info", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, [img, div1, divBtn]);
            const div3 = createElement('div', {key: `div3${padre.id}`, className: "info-block"}, div2);
            
            let elemento1 = createElement('label', {id: padre.id, key: `label${padre.id}`, className: "name", onDrop: (e) => drop(e), onDragOver: (e) => allowDrop(e)}, padre.puestoNom);
            let elemento2 = <Tooltip title="Editar" key={`btnEditar${padre.id}`}><div className="btnEditar" onClick={() => editar(padre)}><EditOutlinedIcon style={{ fontSize: '2em' }}/></div></Tooltip>;

            const div4 = createElement('div', {key: `div4${padre.id}`, className: "master-info"}, [elemento1, elemento2]);
            const div5 = createElement('div', {key: `div5${padre.id}`, className: "employee-card"}, [div4, div3]);
            const div6 = createElement('div', {key: `div6${padre.id}`, className: "node1 no-child"}, div5);
            
            return (div6);
        }
    };

    useEffect(() => {
        setToDoOrg(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organigrama]);

    useEffect(() => {
        axios.get(`${servicios_grupogack_org}/plaza/empleado/${data.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setOrganigrama(res.data.dataResponse);
                    break;
                case 404:
                    setOrganigrama(null);
                    break;
                default:
                    setOrganigrama(null);
                    setOpenAlertSyEError(true);
                    break;
            }
        })
        .catch((error) => {
            setOpenAlertSyEError(true);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!toDoOrg) {
            if(organigrama) {
                const padres = organigrama.filter(Obj => Obj.plazaidsup === 0);
                if(padres.length > 0) {
                    for(let nf = 0; nf < padres.length; nf++) {
                        nuevoHtml.push(<Fragment key={`rfr${nf}`}><br/><ScrollContainer className="scroll-container orgchart" hideScrollbars={false} ignoreElements=".employee-card" key={`divoc${nf}`}>{createElement('ul', {key:`ulpr${nf}`, className:"prime-list"}, generaOrganigrama(padres[nf]))}</ScrollContainer><br/></Fragment>);
                        setHTML(nuevoHtml);
                    }
                }
            } else {
                setHTML([]);
            }

            setToDoOrg(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDoOrg]);

    let contenido = null; 
    (!toDoOrg)
    ?   contenido =     <Spinner color="primary" />
    :   contenido =     <Fragment>
                            { createElement('div', { key: 'div1', className: 'contenedorOrganigramaModal' }, html) }
                        </Fragment>;

    return (
        <Fragment>
            {
                (organigrama === null)
                ?   <Fragment>
                        <AlertForm message="No hay elementos" />
                        <button className="btn btn-color-form" onClick={() => setModalIsOpenNuevoOrg({ state: true, type: 'agregarPlazaNuevoOrg', data: { id: 0, nivel: 1 } }) }>Comenzar nuevo organigrama</button>
                    </Fragment>   
                :   <Fragment>{ contenido }</Fragment>
            }
            <AlertSyE show={openAlertSyEESuccess} setOpenAlertSyE={setOpenAlertSyEESuccess} title="Plaza" text="La tarea se realizó con éxito" textbtnconfirm="Aceptar" type="success"
                action={() => { setModalIsOpen({ state: false, type: null, data: {} }); setToDoOrg(false); }}/>
            <AlertSyE show={openAlertSyEEError} setOpenAlertSyE={setOpenAlertSyEEError} title="Plaza" text="La tarea no se ha podido realizar" textbtnconfirm="Aceptar" type="error" />
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpenNuevoOrg} setModalIsOpen={setModalIsOpenNuevoOrg} enviar={enviarNuevoOrg}
                openAlertSyESuccess={openAlertSyESuccess} setOpenAlertSyESuccess={setOpenAlertSyESuccess} openAlertSyEError={openAlertSyEError} setOpenAlertSyEError={setOpenAlertSyEError} setToDoOrg={setToDoOrg} />
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} enviar={enviar}
                openAlertSyESuccess={openAlertSyESuccess} setOpenAlertSyESuccess={setOpenAlertSyESuccess} openAlertSyEError={openAlertSyEError} setOpenAlertSyEError={setOpenAlertSyEError} setToDoOrg={setToDoOrg} />
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpenEdit} setModalIsOpen={setModalIsOpenEdit} actualizar={actualizar}
                openAlertSyESuccess={openAlertSyESuccess} setOpenAlertSyESuccess={setOpenAlertSyESuccess} openAlertSyEError={openAlertSyEError} setOpenAlertSyEError={setOpenAlertSyEError} setToDoOrg={setToDoOrg} />
            <PlazasModal minWidth="50%" modalIsOpen={modalIsOpenInfo} setModalIsOpen={setModalIsOpenInfo}
                openAlertSyESuccess={openAlertSyESuccess} setOpenAlertSyESuccess={setOpenAlertSyESuccess} openAlertSyEError={openAlertSyEError} setOpenAlertSyEError={setOpenAlertSyEError} setToDoOrg={setToDoOrg} />
        </Fragment>
    );
}

export default OrganigramaPlazas;

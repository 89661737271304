import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioSeguroSocial from './FormularioSeguroSocial';

const ModalEditSeguroSocial = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarSeguroSocial = ( seguroSocial, seguroSocialId ) => {
        axios.put( `${servicios_grupogack_catalogo}/tiposegsoc/${seguroSocialId}`, seguroSocial, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        editar( respuesta.data.dataResponse );
                        setOpenEdit( false );
                        break;
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Tipo Seguro Social" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioSeguroSocial
                    error={error}
                    elementSelect={elementSelect}
                    actualizarSeguroSocial={actualizarSeguroSocial}
                />
        </ModalService>
    );
};

export default ModalEditSeguroSocial;
/****** URLS ******/
export const servicios_grupogack = 'https://nomipago.grupogack.com.mx/api';
//export const servicios_grupogack = 'http://localhost:9000/api';
export const servicios_grupogack_url = `${servicios_grupogack}/user`;
export const servicios_grupogack_token = `${servicios_grupogack}/token`;
export const servicios_grupogack_catalogo = `${servicios_grupogack}/catalogo`;
export const servicios_grupogack_admon = `${servicios_grupogack}/admon`;
export const servicios_grupogack_org = `${servicios_grupogack}/org`;
export const servicios_grupogack_rh = `${servicios_grupogack}/rh`;
export const servicios_grupogack_nomina = `${servicios_grupogack}/nomina`;
export const servicios_grupogack_mensajes = `${servicios_grupogack}/usuarios/mensajes`;
export const servicios_grupogack_plantillas = `${servicios_grupogack}/generar/plantillas`;

/****** Text Alerts ******/
export const registro_sera_eliminado = '¡El registro será eliminado!';
export const registro_sera_actualizado = '¡El registro será actualizado!';
export const registro_sera_guardado = '¡El registro será guardado!';
export const registro_actualizado = '¡El registro fue actualizado!';
export const registro_guardado = '¡El registro fue guardado!';
export const registro_eliminado = '¡El registro fue eliminado!';
export const cambios_no_guardados = 'No se han podido guardar los cambios';
export const registro_no_guardado = 'No se ha podido guardar el registro';
export const registro_no_eliminado = 'No se ha podido eliminar el registro';

export const registro_sera_inactivado = '¡El registro será desactivado!';
export const registro_sera_activado = '¡El registro será activado!';
export const registro_no_inactivado = 'No se ha podido desactivar el registro';
export const registro_no_activado = 'No se ha podido activar el registro';

/****** Mensajes Formularios ******/
export const campos_vacios = "Complete los campos";
export const ocurrio_un_error = "Ocurrió un error";
export const terminosYcondiciones = "Debe aceptar los términos y condiciones";

/****** Tamaños Inputs ******/
export const lengthNombre = 30;
export const lengthEmail = 50;
export const lengthPassword = 30;

/****** CHAT ******/
export const mensajesVentana = 7

/****** Constantes para titulo de tabla, id y parentNode en Modales ******/
export const tablero_ = 'Tablero';
/* Generales */
export const tipo_relacion_laboral = 'Tipo de Relación Laboral';
export const registro_patronal = 'Registro Patronal';
export const giros_ = 'Giros';
export const tipos_secretaria_o_dependencia = 'Tipos de Secretaría o Dependencia';
export const motivos_baja = 'Motivos de Baja';
export const parentesco_ = 'Parentesco';
export const colonias_ = 'Colonias';
export const bancos_ = 'Bancos';
export const bases_ = 'Bases';
export const tipos_ausentismos = 'Tipos de Ausentismos';
export const tipos_jornada = 'Tipos de Jornada';
export const tipo_domicilio = 'Tipo de Domicilio';
export const indicadores_generales = 'Conceptos Generales';
export const nivel_escolaridad = 'Nivel de Escolaridad';
export const tipo_estado_civil = 'Tipo de Estado Civil';
export const tipo_seguro_social = 'Tipo de Seguridad Social';
export const tipo_documentos = 'Tipo de Documentos';
export const regimen_fiscal = 'Régimen Fiscal';
export const origen_recurso = 'Origen de Recurso';
export const tipo_contrato = 'Tipo de Contrato';
export const tipo_regimen = 'Régimen de Contratación';
export const tipo_riesgo = 'Tipo de Riesgo';
export const tipo_acumulado = 'Tipo de Acumulado';
export const tipo_pasatiempo_ = 'Tipo de Pasatiempo';
export const contratos_relacion_laboral = 'Contratos por Relación Laboral';
export const salario_minimo_uma = 'Salario Mínimo / UMA';
export const impuesto_sobre_nomina = 'Impuesto Sobre Nómina';
export const tablas_impuestos = 'Tablas de Impuestos';
export const tablas_subsidios = 'Tablas de Subsidio';
export const sindicatos_ = 'Sindicatos';
export const departamentos_generales = 'Departamentos Generales';
/* Administración */
export const usuarios_ = 'Usuarios';
export const dependencias_ = 'Dependencias';
export const proyectos_ = 'Proyectos';
export const carga_logo = 'Carga Logo';
/* Organización */
export const niveles_ = 'Niveles';
export const puestos_ = 'Puestos';
export const tabuladores_ = 'Tabulador de Sueldos';
export const tabuladores_quinquenios = 'Tabulador de Quinquenios';
export const departamentos_ = 'Departamentos';
export const plazas_ = 'Plazas';
export const nominas_organizacion = 'Nóminas';
export const turnos_= 'Turnos';
/* Recursos Humanos */
export const empleados_ = 'Empleados';
export const ausentismos_ = 'Ausentismos';
export const reingresos_ = 'Reingresos';
export const expediente_ = 'Expediente';
export const historico_ = 'Históricos';
export const especiales_ = 'Especiales';
export const plantillas_ = 'Plantillas';
/* Nómina */
export const nominas_nominas = 'Nóminas';
export const periodos_nomina = 'Periodos de Nómina';
export const indicadores_ = 'Indicadores';
export const politicas_ = 'Políticas';
export const calculo_ = 'Cálculo';
export const movimientos_ = 'Variables';
export const movimientos_fijos = 'Movimientos Fijos';
export const movimientos_relacionados = 'Movimientos Relacionados';
export const movimientos_pendientes = 'Movimientos Pendientes';
export const prestamos_ = 'Préstamos';
export const prestamos_empleado = 'Préstamos Empleado';
export const imss_ = 'IMSS';
export const pensiones_ = 'Pensiones';
export const acumulados_ = 'Acumulados';
/* Reportes */
export const dispersiones_ = 'Dispersiones';
export const auditoria_ = 'Auditoría';
import React, { Fragment, useEffect, useState } from 'react';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { servicios_grupogack_rh, servicios_grupogack_plantillas } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import TitleCard from './components/TitleCard';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import TableEmpleados from './TableEmpleados';
import './styles/styles.css';

const AssignPlantilla = ({ plantilla, onShowTable }) => {

    const [empleados, setEmpleados] = useState(null)

    useEffect(() => {
        axios.get(`${servicios_grupogack_rh}/empleado/activos/`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEmpleados(res.data.dataResponse);
                    break;
                case 404:
                    setEmpleados([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    const crearZip = data => {
        var zip = new JSZip();

        data.forEach((value,i) => {
            console.log(`data:application/pdf;base64,${value.pdf}`)
            zip.file(`${value.empleadoClave} - ${value.nombreCompleto}.pdf`,value.pdf, { base64: true });
        });
        
        zip.generateAsync({ type:"blob" }).then(function(content) {
            saveAs(content, `${plantilla.nombre}.zip`);
        });

    };
 
    const enviaEmpleados = (data) => {
        const empleados = data.map(item => item.id)
        const request = { plantillaId: plantilla.id, empleados };
        axios.post(`${servicios_grupogack_plantillas}/pdf/`, request, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    crearZip(res.data.dataResponse);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    };

    return (
        <Fragment>
            <BtnBack tooltipText="Regresar a yabla de plantillas" action={onShowTable}/>
            <CardComponent
                title={<TitleCard title="Asignar Plantilla a Empleados"/>}
                table={<TableEmpleados empleados={empleados} enviaEmpleados={enviaEmpleados}/>}
            />
        </Fragment>
    );
}
 
export default AssignPlantilla;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIMSS } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioIMSS from './FormularioIMSS';

const ModalAddIMSS = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarIMSS = ( porcentajeIMSS ) => {
        axios.post( `${servicios_grupogack_nomina}/imss/`, porcentajeIMSS, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonIMSS(respuesta.data.dataResponse) );
                        setOpenAdd( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Nuevo % IMSS" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioIMSS
                    error={error}
                    informacion={informacionAdicional}
                    enviarIMSS={enviarIMSS}
                />
        </ModalService>
    );
};

export default ModalAddIMSS;
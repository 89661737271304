import React from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_eliminado, registro_sera_eliminado, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';

const ModalDeleteTablasSubsidios = ({ alertWarning, setAlertWarning, elementSelect, eliminar, errorTable, informacionAdicional }) => {
    
    const axiosEliminar = () => {
         
        if(informacionAdicional){
            Axios.delete(`${servicios_grupogack_catalogo}s/subsidio/${elementSelect}`,headersAuth()) 
            .then(res =>  {
                switch(res.data.status) {
                    case 200:  
                        eliminar();
                        break;
                    case 404:
                        errorTable(res.data.msg);
                        break;
                    default:
                        errorTable(registro_no_eliminado);
                        break;
                }
            })
            .catch(error => errorTable( registro_no_guardado ) );
            
        }else{
            Axios.delete(`${servicios_grupogack_catalogo}s/subsidio/rango/${elementSelect.id}`,headersAuth()) 
            .then(res =>  {
                switch(res.data.status) {
                    case 200:
                        eliminar(elementSelect);
                        break;
                    case 404:
                        errorTable(res.data.msg);
                        break;
                    default:
                        errorTable(registro_no_eliminado);
                        break;
                }
            })
            .catch(error => errorTable( registro_no_guardado ) );
        }
         
    }

    return(
        <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
    );
}

export default ModalDeleteTablasSubsidios;
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const EditorHTML = ({ text, setText }) => {

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'link', 'underline', 'strike'],
            [{ 'align': [] }],
            [{ 'direction': 'rtl' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['clean']
        ],
        clipboard: {
            matchVisual: false,
        }
    };
     
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'link', 'underline', 'strike', 'align', 'direction',
        'list', 'bullet', 'indent'
    ];
    
    const handleChange = value => {
        setText(value);
    };

    return (
        <ReactQuill
            theme="snow"
            value={text}
            onChange={handleChange}
            modules={modules}
            formats={formats}
        />
    );
}
 
export default EditorHTML;
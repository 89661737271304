import React, { useState } from 'react';
import ModalService from '../../../ModalService/ModalService';
import FormEmpleados from './FormEmpleados';

const ModalEditEmpleados = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, successTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    return (
        <ModalService
            title="Actualizar Empleado"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormEmpleados
                        modulo={nombreModulo}
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        editar={editar}
                        errorTable={errorTable}
                        successTable={successTable}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditEmpleados;
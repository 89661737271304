import React, { useState } from 'react';

import ModalService from '../../../ModalService/ModalService';
import CargasMasivas from './CargasMasivas/CargasMasivas';

/* Importacion de elementos para uso de Alertas */
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const ModalAddMasivos = ({ moduloID, openAdd, setOpenAdd, modulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }
    /* ---------------------------------- Funciones para el manejo de alertas ---------------------------------- */
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );

    const successAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }
    /* --------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            { modulo &&
                <ModalService title={`Carga Masiva de ${modulo.nombre}`} parentNode={moduloID} minWidth="33%" maxWidth="35%"
                    isOpen={ openAdd } onClose={ () => cerrarModal() } >
                        <b>Descarga</b>
                        <div>1. Baje el formato</div>
                        <div>2. Capture la información solicitada</div>
                        <br/>
                        <b>Carga</b>
                        <div>1. Busque el archivo</div>
                        <div>2. Una vez que el sistema lo analice:</div>
                        <div>Si no se presentan incidencias haga la carga, de lo contrario modifique el archivo y repita el procedimiento</div>
                        <hr/>

                        <CargasMasivas modulo={modulo} error={error} errorAlert={errorAlert} successAlert={successAlert}/>
                </ModalService>
            }
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </React.Fragment>
    );
};

export default ModalAddMasivos;
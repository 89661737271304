import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_actualizado, cambios_no_guardados, registro_sera_actualizado } from '../../../../../../Constants/Constants';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import InfoExpLaboral from './InfoExpLaboral';
import Button from '@material-ui/core/Button';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';

const FormExperienciaLaboral = ({ empresaExpLabRef, puestoExpLabRef, desdeMesExpLabRef, desdeAnioExpLabRef, hastaMesExpLabRef, hastaAnioExpLabRef, nombreJefeExpLabRef, telefonoExpLabRef, elementSelect, elementoSeleccionado, setElementoSeleccionado, irInfo, actualizarExpLaboral, agregarExpLaboralEdit, enviar, mostrarFormAddEdit, setMostrarFormAddEdit, expLaboralSaved, setExpLaboralSaved, expLaboralSavedEdit, setExpLaboralSavedEdit, setError, successTable, errorTable }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const meses = [
        { id: 1, mes: 'Enero' },
        { id: 2, mes: 'Febrero' },
        { id: 3, mes: 'Marzo' },
        { id: 4, mes: 'Abril' },
        { id: 5, mes: 'Mayo' },
        { id: 6, mes: 'Junio' },
        { id: 7, mes: 'Julio' },
        { id: 8, mes: 'Agosto' },
        { id: 9, mes: 'Septiembre' },
        { id: 10, mes: 'Octubre' },
        { id: 11, mes: 'Noviembre' },
        { id: 12, mes: 'Diciembre' }
    ];

    const obtieneNombreMes = mesInt => {
        const nombreMes = meses.find(mes => mes.id === mesInt);

        return nombreMes.mes;
    };

    const obtieneAnios = () => {
        let arrayAnios = [];
        const today = new Date();
        const year = today.getFullYear();
        for(let a = 0; a < 30; a ++) {
            arrayAnios.push({ id: year-a, anio: year-a })
        }
        return arrayAnios;
    };

    const anios = obtieneAnios();

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const guardar = e => {
        e.preventDefault();
        enviar();
    };

    const abrirFormAddEdit = e => {
        e.preventDefault();
        setMostrarFormAddEdit(true);
    };

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupogack_rh}/empleado/experiencia/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        let info = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                empId: res.empId.id,
                                empNom: res.empNom,
                                puestoExp: res.puestoExp,
                                dmes: res.dmes,
                                danio: res.danio,
                                hmes: res.hmes,
                                hanio: res.hanio,
                                jefe: res.jefe,
                                tel: res.tel
                            }
                        ));
                        setExpLaboralSaved(info);
                        break;
                    case 404:
                        setExpLaboralSaved([]);
                        break;
                    default:
                        alert('No se pudo obtener la información de experiencia laboral. Intente de nuevo.');
                        break;
                }
            })
            .catch(error => { alert('No se pudo obtener la información de experiencia laboral. Intente de nuevo.'); console.log(error) });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const guardaExpLaboral = (e, type) => {
        e.preventDefault();

        let lengthDepEcon = 0;
        let lengthDepEconS = 0;

        if(expLaboralSaved.length) lengthDepEcon = expLaboralSaved.length;
        if(expLaboralSavedEdit.length) lengthDepEconS = expLaboralSavedEdit.length;

        const total = lengthDepEcon + lengthDepEconS;

        if(total < 10) {
            setError(null);
            if(validarSubmit("FormEmpleadoExpLaboral")) {
                if(parseInt(hastaAnioExpLabRef.current.value) < parseInt(desdeAnioExpLabRef.current.value)) {
                    setError(<AlertForm message="La fecha final no puede ser menor a la fecha de inicio"/>);
                    return;
                } else if((parseInt(hastaAnioExpLabRef.current.value) === parseInt(desdeAnioExpLabRef.current.value)) && (parseInt(hastaMesExpLabRef.current.value) < parseInt(desdeMesExpLabRef.current.value))) {
                    setError(<AlertForm message="La fecha final no puede ser menor a la fecha de inicio"/>);
                    return;
                }
                
                let info = {
                    id: `${expLaboralSaved.length}${empresaExpLabRef.current.value}${puestoExpLabRef.current.value}`,
                    empNom: empresaExpLabRef.current.value,
                    puestoExp: puestoExpLabRef.current.value,
                    dmes: parseInt(desdeMesExpLabRef.current.value),
                    danio: parseInt(desdeAnioExpLabRef.current.value),
                    hmes: parseInt(hastaMesExpLabRef.current.value),
                    hanio: parseInt(hastaAnioExpLabRef.current.value),
                    jefe: nombreJefeExpLabRef.current.value,
                    tel: telefonoExpLabRef.current.value
                };
    
                if(type === 'FormAgregar') setExpLaboralSaved([ ...expLaboralSaved, info ]);
                if(type === 'FormEditar') {
                    info = { ...info, empId: elementSelect.id }
                    setExpLaboralSavedEdit([ ...expLaboralSavedEdit, info ]);
                }

                empresaExpLabRef.current.value = '';
                puestoExpLabRef.current.value = '';
                desdeMesExpLabRef.current.value = '';
                desdeAnioExpLabRef.current.value = '';
                hastaMesExpLabRef.current.value = '';
                hastaAnioExpLabRef.current.value = '';
                nombreJefeExpLabRef.current.value = '';
                telefonoExpLabRef.current.value = '';
            }
        } else {
            setError(<AlertForm message="Has llegado al límite de puestos de Experiencia Laboral"/>)
        }

    };

    const limpiaSelected = e => {
        e.preventDefault();
        if(expLaboralSavedEdit.length) setExpLaboralSavedEdit([]);
        else setElementoSeleccionado(null);
        setMostrarFormAddEdit(false);
    };

    const eliminarExpLaboral = item => {
        axios.delete(`${servicios_grupogack_rh}/empleado/experiencia/${item.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setExpLaboralSaved(expLaboralSaved.filter(expLaborale => expLaborale.id !== item.id));
                    successTable(registro_actualizado);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => { errorTable(cambios_no_guardados); console.log(error) });
    };

    const enviaTnfo = () => {
        setError(null);
        if(!expLaboralSavedEdit.length && !mostrarFormAddEdit) {
            actualizarExpLaboral();
        } else {
            agregarExpLaboralEdit();
        }
    };
    
    return (
        <Fragment>
            <h5>Experiencia Laboral</h5>
            {
                (!elementSelect || (elementoSeleccionado === null && expLaboralSaved.length > 0 && !mostrarFormAddEdit))
                ?  expLaboralSaved.map((expLaboral, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            {
                                (elementSelect)
                                ?   <Fragment>
                                        <BtnEditItem id={expLaboral.id} idArray="id" array={expLaboralSaved} action={setElementoSeleccionado} remove/>
                                        <BtnRemoveItem id={expLaboral.id} idArray="id" array={expLaboralSaved} action={eliminarExpLaboral} remove/>
                                    </Fragment>
                                :   <BtnRemoveItem id={expLaboral.id} idArray="id" array={expLaboralSaved} action={setExpLaboralSaved}/>
                            }
                            <InfoExpLaboral expLaboral={expLaboral} obtieneNombreMes={obtieneNombreMes}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (expLaboralSavedEdit.length)
                ?  expLaboralSavedEdit.map((expLaboral, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            <BtnRemoveItem id={expLaboral.id} idArray="id" array={expLaboralSavedEdit} action={setExpLaboralSavedEdit}/>
                            <InfoExpLaboral expLaboral={expLaboral} obtieneNombreMes={obtieneNombreMes}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (!elementSelect || elementoSeleccionado !== null || expLaboralSaved.length === 0 || (expLaboralSaved.length > 0 && mostrarFormAddEdit))
                ?   <form id="FormEmpleadoExpLaboral" onSubmit={(elementSelect) ? mostrarAlert : guardar}>
                        <InputValidation title="Empresa" type="empresa" placeholder="Escriba el nombre de la empresa" refValue={empresaExpLabRef}
                            defaultValue={elementoSeleccionado ? elementoSeleccionado.empNom : ''}
                            tooltipText="64 letras y/o espacio" tooltipName="tooltipEmpresaExpLab" maxLength={64} onlyLetters=" "/>
                        <InputValidation title="Puesto" type="puesto" placeholder="Escriba el nombre del puesto" refValue={puestoExpLabRef}
                            defaultValue={elementoSeleccionado ? elementoSeleccionado.puestoExp : ''}
                            tooltipText="64 letras y/o espacio" tooltipName="tooltipPuestoExpLab" maxLength={64} onlyLetters=" "/>
                        <div className="row">
                            <div className="col-3">
                                <SelectValidation title="Desde" arrayOpciones={meses} keyOpcion="mes" refValue={desdeMesExpLabRef}
                                    defaultValue={elementoSeleccionado ? elementoSeleccionado.dmes : ''} required/>
                            </div>
                            <div className="col-3">
                                <SelectValidation title=" " arrayOpciones={anios} keyOpcion="anio" refValue={desdeAnioExpLabRef}
                                    defaultValue={elementoSeleccionado ? elementoSeleccionado.danio : ''}/>
                            </div>
                            <div className="col-3">
                                <SelectValidation title="Hasta" arrayOpciones={meses} keyOpcion="mes" refValue={hastaMesExpLabRef}
                                    defaultValue={elementoSeleccionado ? elementoSeleccionado.hmes : ''} required/>
                            </div>
                            <div className="col-3">
                                <SelectValidation title=" " arrayOpciones={anios} keyOpcion="anio" refValue={hastaAnioExpLabRef}
                                    defaultValue={elementoSeleccionado ? elementoSeleccionado.hanio : ''}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <InputValidation title="Jefe Inmediato" type="nombre" placeholder="Escriba el nombre del jefe inmediato" refValue={nombreJefeExpLabRef}
                                    defaultValue={elementoSeleccionado ? elementoSeleccionado.jefe : ''}
                                    tooltipText="60 letras y/o espacio" tooltipName="tooltipJefeExpLab" maxLength={60} onlyLetters=" "/>
                            </div>
                            <div className="col-6">
                                <InputValidation title="Teléfono" type="telefono" placeholder="Escriba el teléfono" refValue={telefonoExpLabRef}
                                    defaultValue={elementoSeleccionado ? elementoSeleccionado.tel : ''}
                                    tooltipText="10 números" tooltipName="tooltipTelefonoExpLab" maxLength={10} onlyNumbers/>
                            </div>
                        </div>
                        <BtnsInfoAdicional numForm={7} irInfo={irInfo}/>
                        {
                            (!elementSelect || expLaboralSaved.length === 0 || (expLaboralSaved.length > 0 && mostrarFormAddEdit))
                            ?   (!elementSelect || expLaboralSaved.length === 0)
                                ?   <div className="formRelLab">
                                        <div className="form-left">
                                            <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                                        </div>
                                        <div className="form-right">
                                            <Button variant="outlined" className="btn-outline" onClick={e => (!elementSelect) ? guardaExpLaboral(e, 'FormAgregar') : guardaExpLaboral(e, 'FormEditar')}>Agregar</Button>
                                        </div>
                                    </div>
                                :   <div className="formRelLab">
                                        <div className="form-left">
                                            <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                        </div>
                                        <div className="form-right">
                                            <Button variant="outlined" className="btn-outline" onClick={e => guardaExpLaboral(e, 'FormEditar')}>Agregar</Button>
                                        </div>
                                    </div>
                            :   null
                        }
                        {
                            (elementSelect && expLaboralSaved.length > 0 && !mostrarFormAddEdit)
                            ?   <div className="formRelLab">
                                    <div className="form-left">
                                        <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                    </div>
                                    <div className="form-right">
                                        <Button variant="contained" className="btn-color" type="submit">{(!elementSelect || expLaboralSaved.length === 0 || (expLaboralSaved.length > 0 && mostrarFormAddEdit)) ? 'Guardar' : 'Actualizar' }</Button>
                                    </div>
                                </div>
                            :   <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
                        }
                    </form>
                :   <Fragment>
                        <br/>
                        <BtnsInfoAdicional numForm={7} irInfo={irInfo}/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                            </div>
                            <div className="form-right">
                                <Button variant="outlined" className="btn-outline" onClick={e => abrirFormAddEdit(e)}>Agregar</Button>
                            </div>
                        </div>
                    </Fragment>
            }
            {   elementSelect &&
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaTnfo}/>
            }
        </Fragment>
    );
}
 
export default FormExperienciaLaboral;
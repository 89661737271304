import React from 'react';
import ModalService from './../../../Usuarios/Usuario/ModalService/ModalService';
import Button from '@material-ui/core/Button';

function ModalPDF({ idModulo, title, openPDF, setOpenPDF, pdfSelect }) {
    return(
        <ModalService title="Visualizar" parentNode={ idModulo } className="modal-style"
            isOpen={ openPDF } onClose={ () => setOpenPDF(false) } minWidth='80%' >
                
            <div className='embed-responsive' style={{ paddingBottom: '100%'}}>
                <object className='embed-responsive-item' aria-labelledby="Descargar"
                    data={ pdfSelect } type='application/pdf' width='100%' height='100%'/>
            </div>

            <Button color="primary">
                <a href={pdfSelect} download={ `${title}.pdf` }>Descargar</a>
            </Button>
        </ModalService>
    );
}

export default ModalPDF;
import React from 'react';
import csvDownload from 'json-to-csv-export';

const AlertErrorDescarga = ({ data, fileName }) => {
    const decargarErroresExcel = () => {
        console.log( data.dataResponse );
        csvDownload( data.dataResponse, `${fileName}.csv` );
    }

    return (
        <React.Fragment>
            { Array.isArray(data.dataResponse) ?
                <div className="alert alert-danger alert_form col-12" role="alert">
                    La carga no pudo completarse, <span className="subrayado link" onClick={() => decargarErroresExcel()}>revisar errores</span>
                </div>
                :
                <div className="alert alert-danger alert_form col-12" role="alert">
                    { data.msg }
                </div>
            }
        </React.Fragment>
    );
};

export default AlertErrorDescarga;
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, indicadores_generales } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonIndicadoresGenerales } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const IndicadoresGenerales = ({ id, permisos }) => {

    const [indicadoresGenerales, setIndicadoresGenerales] = useState(null);

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}/indicador`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresGenerales(res.data.dataResponse);
                    setIndicadoresGenerales(indicadores);
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]);

    return (
        <div id={ id }>
            {   
                indicadoresGenerales
                ?   <DataTableService id={id} title={ indicadores_generales }
                        permisos={permisos} 
                        columns={[
                            { field: "indicadorClave", title: "Clave" },
                            { field: "indicadorNom", title: "Nombre" },
                            { field: "relacionLabNombre", title: "Relación Laboral" },
                            { field: "tipoNatuNom", title: "Naturaleza" },
                            { field: "indicadorSat", title: "Indicador SAT" },
                            { field: "clasificaNom", title: "Clasificación SAT" },
                            { field: "partida", title: "Partida" },
                            { field: "cuenta", title: "Cuenta" },
                        ]}
                        data={indicadoresGenerales}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default IndicadoresGenerales;

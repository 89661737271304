import React, { Fragment } from 'react';
import ModalReprocesar from './ModalReprocesar';
import ConvertDate from '../../../Helpers/ConvertDate';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const AcumuladosSelecionados = ({ nombreModulo, acumuladosSelected, modalIsOpen, setModalIsOpen, openAlertCerrar, setOpenAlertCerrar, openAlertEliminar, setOpenAlertEliminar, value, setValue, reprocesar, cerrarAcumulado, eliminarAcumulado, permisos, openLoading }) => {

    const abrirModalReprocesar = (e, item) => {
        e.preventDefault();
        setValue(item);
        setModalIsOpen(true);
    };

    const abrirModalCerrar = (e, item) => {
        e.preventDefault();
        setValue(item);
        setOpenAlertCerrar({ state: true, value: item });
    };

    const abrirModalEliminar = (e, item) => {
        e.preventDefault();
        setValue(item);
        setOpenAlertEliminar(true);
    };

    return (
        <div className="formRelLab">
            {   
            acumuladosSelected !== undefined
            ?   acumuladosSelected.map(acum => (
                    <Fragment key={acum.tipoAcumuladoId}>
                        <div style={{ float: 'right', width: '30%' }}>
                            <div className="label-cell">{ acum.tipoAcumuladoNombre }</div>
                        </div>
                        <div style={{ width: '30%', marginLeft: '5%', marginRight: '5%' }}>
                            <div className="label-cell">{ ConvertDate(acum.fechaPago) }</div>
                        </div>
                        {
                            (!acum.cerrado)
                            ?   <div className="contenedor-botones-calculo">
                                    {   (permisos.find(permiso => permiso.id === 2)) &&
                                        <Fragment>
                                            <Tooltip title="Reprocesar Acumulado" placement="top">
                                                <Button variant="contained" className="btn1-3 btn1-3-right" onClick={e => abrirModalReprocesar(e, acum)} disabled={openLoading}>
                                                    <SyncIcon/>
                                                </Button>
                                            </Tooltip>
                                            <Tooltip title="Cerrar Acumulado" placement="top">
                                                <Button variant="outlined" className="btn1-3 btn1-3-center" onClick={e => abrirModalCerrar(e, acum)} disabled={openLoading}>
                                                    <EventBusyIcon/>
                                                </Button>
                                            </Tooltip>
                                        </Fragment>
                                    }
                                    {   (permisos.find(permiso => permiso.id === 3)) &&
                                        <Tooltip title="Eliminar Acumulado" placement="top">
                                            <Button variant="outlined" className="btn1-3 btn1-3-left" onClick={e => abrirModalEliminar(e, acum)} disabled={openLoading}>
                                                <DeleteIcon/>
                                            </Button>
                                        </Tooltip>
                                    }
                                </div>
                            :   <div style={{ float: 'left', width: '30%' }}>
                                   <div className="label-cell label-block">Periodo Cerrado</div>
                                </div>
                        }
                        <br/>
                    </Fragment>
                ))
            :   <SpinnerOval />
            }
            {
                (value !== null) &&
                <Fragment>
                    <ModalReprocesar nombreModulo={nombreModulo} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}
                        reprocesar={reprocesar} item={value}/>
                    <AlertWarning show={openAlertCerrar} SetopenALert={setOpenAlertCerrar} text="¡Se cerrará el Acumulado!" textButtonAceptar="Aceptar"
                        textButtonCancelar="Cancelar" action={cerrarAcumulado} value={value}/>
                    <AlertWarning show={openAlertEliminar} SetopenALert={setOpenAlertEliminar} text="¡Se eliminará el Acumulado!" textButtonAceptar="Aceptar"
                        textButtonCancelar="Cancelar" action={eliminarAcumulado} value={value}/>
                </Fragment>
            }
        </div>
    );
}
 
export default AcumuladosSelecionados;
import React, { createRef, useState, useEffect, Fragment } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_sera_actualizado, registro_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core'; 
import { ArrayJsonSalarioMinUMAIndicadores, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel'; 
import { obtenerModalAdd, obtenerModalEdit } from '../../../DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; 
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import AlertForm from '../../../../../../Services/AlertForm'; 

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#1572E8' },
    },
});

const FormIndicadoresGenerales = ({ error, setError, enviarIndicadorGeneral, elementSelect, actualizarIndicadorGeneral, vista, idModulo, informacionAdicional, indicadoresGeneral }) => {

    const [ errorAuto, setErrorAuto ] = useState( null );
    const [relacionLaboral, setCompania] = useState([]);
    const [naturaleza, setNaturaleza] = useState([]);
    const [clasificacionSAT, setClasificacionSAT] = useState([]);
    const [editRelacionLaboral, setEditRelacionLaboral] = useState('');
    const [editNaturaleza, setEditNaturaleza] = useState('');
    const [editClasificacionSAT, setEditClasificacionSAT] = useState('');
    const [partida, setPartida] = useState({ partidagen: '', partidaesp: '' });
    const [cuenta, setCuenta] = useState({ ctacontable: '', subcta: '' });
    const [openAlert, setOpenAlert] = useState(false);

    const [ openAdd, setOpenAdd ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState(''); 
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    

    const claveRef = createRef('');
    const nombreRef = createRef('');
    const relLaboralRef = createRef('');
    const naturalezaRef = createRef('');
    const indicadorSATRef = createRef('');
    const clasificacionSATRef = createRef('');
    const partGenericaRef = createRef('');
    const partEspecifRef = createRef('');
    const ctaContableRef = createRef('');
    const subCtaContableRef = createRef('');
    const importeRef = createRef(0);

    const [ dataConfigBase, setDataConfigBase ] = useState(null);
    const [ updateIndicadorParametros, setUpdateIndicadorParametros ] = useState(false);
    const [ defValTopVec, setDefValTopVec ] = useState(null);
    const [ defValCalcSob, setDefCalcSob ] = useState(null);
    const [ defValMonto, setDefValMonto ] = useState(null);

    const [ defValIndicador, setDefIndicador ] = useState(null);
    const [ idIndicadorParametro, setIdIndicadorParametro ] = useState(null);
    
    const [active, setActive] = useState(1);
 
    const topeVecesRef = createRef(0);  
    const prioridadRef = createRef(''); 
    const rutinaCalcRef = createRef(''); 
    const calcSobreRef = createRef('');
    const [ indicadorAuto, setIndicadorAuto ] = useState( null ); 

    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null); 
    const [ exentoReq , setExentoReq ]= useState(false);
    const [ montoZona , setMontoZona ]= useState(0);
    const [ rutinas , setRutinas ]= useState(null);
    const [ apareceRecib , setApareceRecib ]= useState(elementSelect ? elementSelect.recibo:  false);
    const [salarioMinimos, setSalarioMinimos] = useState(null); 

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMAIndicadores(res.data.dataResponse)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    alert('error');
                    break;
            } 
        })
        .catch(error => alert(error));

        Axios.get(`${servicios_grupogack_catalogo}/indicador/getall/relab`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse);
                    setIndicadoresGenerales(indicadores);
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        Axios.get(`${servicios_grupogack_catalogo}/rutina/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    setRutinas( res.data.dataResponse );
                    break;
                case 404:
                    setRutinas([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error')); 
        // eslint-disable-next-line
    },[]);  

    
    
    const deleteOptionals =( objectRequest )=>{
        var temporal = objectRequest;  
        Object.keys( temporal ).forEach( key => { 
            if( temporal[key] === " " ){
                delete temporal[key];
            }
        });
        return temporal;
    }

    const enviarForm = (evt) => {
        evt.preventDefault();
        if(indicadorAuto === null && exentoReq) {
            validarSubmit( 'AgregarIndicadorConfig' )
            setErrorAuto(<AlertForm message="Complete los campos" />); 
        }else{
            if( validarSubmit( 'AgregarIndicadorConfig' ) ){ 
                setErrorAuto('');
                const indicadorClave = claveRef.current.value;
                const indicadorNom = nombreRef.current.value;
                const relacion = parseInt(relLaboralRef.current.value);
                const naturaleza = parseInt(naturalezaRef.current.value);
                const indicadorSat = indicadorSATRef.current.value;
                const clasificaSat = parseInt(clasificacionSATRef.current.value);
                const partidagen = partGenericaRef.current.value;
                const partidaesp = partEspecifRef.current.value;
                const ctacontable = ctaContableRef.current.value;
                const subcta = subCtaContableRef.current.value;

                const rutinaId = parseInt(rutinaCalcRef.current.value);
                const prioridad = parseInt(prioridadRef.current.value);
                const recibo = apareceRecib; 

                var indicadorParametros=null;
                if(exentoReq){ 
                    const indicador = indicadorAuto.id;
                    const zonaId = parseInt(calcSobreRef.current.value);
                    const topVeces = parseInt( topeVecesRef.current.value );
                    indicadorParametros={ indicador, zonaId, topVeces }; 
                }

                const dataConf=[];
                for (const key in dataConfigBase) {
                    dataConf.push(deleteOptionals(dataConfigBase[key]));
                } 

                let cuentas = {};
                if(partidagen.length === 3) cuentas = { ...cuentas, partidagen };
                if(partidaesp.length === 3) cuentas = { ...cuentas, partidaesp };
                if(ctacontable.length === 3) cuentas = { ...cuentas, ctacontable };
                if(subcta.length === 6) cuentas = { ...cuentas, subcta }; 
                
                
                enviarIndicadorGeneral({ indicadorClave, indicadorNom, relacion, naturaleza, indicadorSat, clasificaSat, cuentas, rutinaId, prioridad, recibo  }, dataConf.length>0 ? {basesIndicadores: [dataConf]} : null, indicadorParametros);
            }
        }
    }


    useEffect(() => {
        if(elementSelect) {

             //separa los string de partidas y cuentas para poner en los inputs cuando se va a editar
            if(elementSelect.partida.length > 5) {
                setPartida({ partidagen: elementSelect.partida.substr(0, 3), partidaesp: elementSelect.partida.substr(6, 3) });
            }

            if(elementSelect.partida.length > 5) {
                setCuenta({ ctacontable: elementSelect.cuenta.substr(0, 3), subcta: elementSelect.cuenta.substr(6, 6) });
            } 

            Axios.get(`${servicios_grupogack_catalogo}/indicadores/parametros/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setUpdateIndicadorParametros(true);
                        let response= res.data.dataResponse[0]; 
                        let calcSobreNew= informacionAdicional.filter( element => element.zonaId === parseInt(response.zona.id));
                        setDefValMonto(parseFloat(response.topeveces) * calcSobreNew[0].monto);
                        setIdIndicadorParametro( response.id );
                        setDefValTopVec( response.topeveces );
                        setDefCalcSob( response.zona.id );
                        let indicadorJson= indicadoresGeneral.filter( element => element.id === response.indicador)[0]; 
                        setIndicadorAuto(indicadorJson); 
                        setDefIndicador(indicadorJson); 
                        setExentoReq( true ); 
                        break;
                    case 404:
                        setUpdateIndicadorParametros(false);
                        break;
                    default:
                        setUpdateIndicadorParametros(false);
                        break;
                }
            })
            .catch(error => setUpdateIndicadorParametros(false));
        }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}/relacionlaboral/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(enviarIndicadorGeneral) {
                        setCompania(res.data.dataResponse);
                    } else {
                        setEditRelacionLaboral(res.data.dataResponse.find(rl => rl.id === elementSelect.relacionLabId ));
                    }
                    break;
                case 404:
                    setCompania([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        Axios.get(`${servicios_grupogack_catalogo}/tipoNaturaleza`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(enviarIndicadorGeneral) {
                        setNaturaleza(res.data.dataResponse);
                    } else {
                        setEditNaturaleza(res.data.dataResponse.find(csat => csat.id === elementSelect.naturalezaId ));
                    }
                    break;
                case 404:
                    setNaturaleza([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        Axios.get(`${servicios_grupogack_catalogo}/clasificaSat`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    if(enviarIndicadorGeneral) {
                        setClasificacionSAT(res.data.dataResponse);
                    } else {
                        setEditClasificacionSAT(res.data.dataResponse.find(csat => csat.id === elementSelect.clasificaSatId ));
                    }
                    break;
                case 404:
                    setClasificacionSAT([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const indicadorAutocomplete = valor => {
        setIndicadorAuto(valor); 
    }

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const actualizar = () => {
        if(indicadorAuto === null && exentoReq) {
            validarSubmit( 'EditarIndicadorConfig' )
            setErrorAuto(<AlertForm message="Complete los campos" />); 
        }else{
            if(validarSubmit( 'EditarIndicadorConfig')) {
                const indicadorClave = claveRef.current.value;
                const indicadorNom = nombreRef.current.value;
                const indicadorSat = indicadorSATRef.current.value;

                const rutinaId = parseInt(rutinaCalcRef.current.value);
                const prioridad = parseInt(prioridadRef.current.value);
                const recibo = apareceRecib; 

                var indicadorParametros=null;
                if(exentoReq){ 
                    const indicador = indicadorAuto.id;
                    const zonaId = parseInt(calcSobreRef.current.value);
                    const topVeces = parseInt( topeVecesRef.current.value );
                    indicadorParametros={ indicador, zonaId, topVeces };
                    if(updateIndicadorParametros){
                        indicadorParametros.id = idIndicadorParametro;
                    }
                } 

                let cuentas = {};
                if(partGenericaRef.current.value.length === 3) cuentas = { ...cuentas, partidagen: partGenericaRef.current.value };
                if(partEspecifRef.current.value.length === 3) cuentas = { ...cuentas, partidaesp: partEspecifRef.current.value };
                if(ctaContableRef.current.value.length === 3) cuentas = { ...cuentas, ctacontable: ctaContableRef.current.value };
                if(subCtaContableRef.current.value.length === 6) cuentas = { ...cuentas, subcta: subCtaContableRef.current.value };

                setError(null);
                actualizarIndicadorGeneral({ indicadorClave, indicadorNom, indicadorSat, cuentas, rutinaId, prioridad, recibo }, elementSelect.id, indicadorParametros, updateIndicadorParametros);
            }
        }
    };

     const siguiente = (e, pag) => {
        e.preventDefault();
        if(validarSubmit( 'AgregarIndicadorGeneral' )) {
            vista(pag);
            setActive(pag);
        }
    }; 
    
    const openConfig = (e) => {
        e.preventDefault();
        setOpenAdd(true);
    }

    const saveConfig = ( data ) =>{
        if(data===null){
            setTextAlert( 'Registro Actualizado' );
            setAlertSuccess( true );     
        }else{ 
            setTextAlert( registro_guardado );
            setAlertSuccess( true ); 
            setDataConfigBase( data );
        }

    } 
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }

    const successTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    
    const updateTopeVeces = ( value ) => {
        importeRef.current.value = value!=='' ? parseFloat(value) * montoZona : 0 ;
    }

    const getMontoZona = ( zona  ) => {
        var montoZon=0;  
        let calcSobreNew= salarioMinimos.filter( element => element.id === parseInt(zona)); 
        if(calcSobreNew.length>0){
            montoZon=calcSobreNew[0].monto;  
            setMontoZona(montoZon); 
        }else{
            setMontoZona(0);
        }
        importeRef.current.value= topeVecesRef.current.value !=='' ? parseFloat(topeVecesRef.current.value) * montoZon : 0 ; 
    }

    return (  
        <Fragment>
        <div id="form-part-1" style={{ display: (active === 1) ? 'block' : 'none' }}>
            <form id={ 'AgregarIndicadorGeneral' } onSubmit={e => siguiente(e, 2)}>
                {
                    (enviarIndicadorGeneral)
                    ?   <InputValidation title="Clave" type="claveIndGenerales" placeholder="Escriba la clave del concepto general"
                            refValue={claveRef} defaultValue={ elementSelect ? elementSelect.indicadorClave : '' } 
                            tooltipText={'Máximo 5 letras y/o números'} tooltipName={'tooltipCveIndGral'} maxLength={5} required alfanumeric/>
                    :   <div className="form-group">
                            <label>Clave  <strong className="requerido">* </strong></label>
                            <input type="text" className="form-control" readOnly ref={claveRef} defaultValue={elementSelect.indicadorClave} />
                        </div>
                }
                <InputValidation title="Nombre" type="nombreIndGenerales" placeholder="Escriba el nombre del concepto general"
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.indicadorNom : '' } 
                    tooltipText={`Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipNomIndGral'} required maxLength={128}/>
                
                <div className="row"> 
                {
                    <div className="col-md-6 col-sm-12"> 
                    {
                        (enviarIndicadorGeneral)
                        ?   
                            <SelectValidation title="Tipo de Relación Laboral" arrayOpciones={relacionLaboral} refValue={relLaboralRef} 
                                keyOpcion="nombre" required/>
                        :   <div className="form-group">
                                <label>Tipo de Relación Laboral  <strong className="requerido">* </strong></label>
                                <input type="text" className="form-control" readOnly defaultValue={editRelacionLaboral.nombre} disabled />
                            </div>
                        }
                    </div>
                }
                {
                    <div className="col-md-6 col-sm-12"> 
                    {
                        (enviarIndicadorGeneral)
                        ?   <SelectValidation title="Tipo de Naturaleza" arrayOpciones={naturaleza} refValue={naturalezaRef} 
                                keyOpcion="tipoNatuNom" required />
                        :   <div className="form-group">
                                <label>Tipo de Naturaleza <strong className="requerido">* </strong></label>
                                <input type="text" className="form-control" readOnly defaultValue={editNaturaleza.tipoNatuNom} disabled/>
                            </div>
                        }
                    </div>            
                }
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Indicador SAT" type="indicadorsat" placeholder="Escriba la Clave del Indicador SAT"
                            refValue={indicadorSATRef} defaultValue={ elementSelect ? elementSelect.indicadorSat : '' } 
                            tooltipText={'Máximo 3 letras y/o números'} tooltipName={'tooltipIndSatIndGral'} maxLength={3} required alfanumeric/> 
                    </div>
                    <div className="col-md-6 col-sm-12"> 
                    {
                        (enviarIndicadorGeneral)
                        ?   <SelectValidation title="Clasificación SAT" arrayOpciones={clasificacionSAT} refValue={clasificacionSATRef} 
                                keyOpcion="clasificaNom" required />
                        :   <div className="form-group">
                                <label>Clasificación SAT <strong className="requerido">* </strong></label>
                                <input type="text" className="form-control" readOnly defaultValue={editClasificacionSAT.clasificaNom} />
                            </div>    
                    }
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Partida Genérica" type="cuentaIndicadores3" placeholder="Escriba la Clave de Partida Genérica"
                            refValue={partGenericaRef} defaultValue={ elementSelect ? partida.partidagen.trim() : '' } optional={true} 
                            tooltipText={'Máximo 3 letras y/o números'} tooltipName={'tooltipPartGenerica'} maxLength={3} alfanumeric/>
                    </div>
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Partida Específica" type="cuentaIndicadores3" placeholder="Escriba la Clave de Partida Específica"
                            refValue={partEspecifRef} defaultValue={ elementSelect ? partida.partidaesp.trim() : '' } optional={true}
                            tooltipText={'Máximo 3 letras y/o números'} tooltipName={'tooltipPartEspecif'} maxLength={3} alfanumeric/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Cuenta Contable" type="cuentaIndicadores3" placeholder="Escriba la Clave de Cuenta Contable"
                            refValue={ctaContableRef} defaultValue={ elementSelect ? cuenta.ctacontable.trim() : '' } optional={true}
                            tooltipText={'Máximo 3 letras y/o números'} tooltipName={'tooltipCtaCntable'} maxLength={3} alfanumeric/>
                    </div>
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Subcuenta Contable" type="cuentaIndicadores6" placeholder="Escriba la Clave de Subcuenta Contable"
                            refValue={subCtaContableRef} defaultValue={ elementSelect ? cuenta.subcta.trim() : '' } optional={true}
                            tooltipText={'Máximo 6 letras y/o números'} tooltipName={'tooltipSubCtaCntable'} maxLength={6} alfanumeric/>
                    </div>
                </div>
                { error } 
                <Button variant="contained" className="btn-color" type="submit">{ 'Siguiente' }</Button>
            </form>
        </div>
        <div id="form-part-2" style={{ display: (active === 2) ? 'block' : 'none' }}>
            <form id={ elementSelect ? 'EditarIndicadorConfig' : 'AgregarIndicadorConfig' } onSubmit={ elementSelect ? mostrarAlert : enviarForm }>
                  
                <div className="row">
                     
                    <div className="col-md-12 col-sm-12">
                    {
                        rutinas
                        ?
                            <SelectValidation title="Rutina de Cálculo" arrayOpciones={rutinas} keyOpcion="rutinaNom" defaultValue={ elementSelect ? elementSelect.rutinaId : '' }
                                refValue={rutinaCalcRef} required />
                        :
                        null
                    }
                    </div>
                </div> 
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Prioridad" type="prioridad" placeholder="Escriba el valor de la prioridad"
                            tooltipText={'Máximo 254 números'} tooltipName={'tooltipPriorInd'} maxLength={254} onlyNumbers
                            refValue={prioridadRef} defaultValue={ elementSelect ? elementSelect.prioridad : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <button onClick={e => openConfig(e) }  className="form-control btn-third" style={{marginTop: "37px"}}>Bases</button>
                    </div>
                </div>
                <MuiThemeProvider theme={theme}>
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <Checkbox  
                            onChange={()=>{setApareceRecib(!apareceRecib)}}
                            color="secondary"
                            checked={apareceRecib}
                        /> 
                        <label> Aparece en Recibo </label>
                    </div>
                    <div className="col-md-6 col-sm-12"> 
                        <Checkbox 
                            onChange={()=>{setExentoReq(!exentoReq)}}
                            color="secondary"
                            checked={exentoReq}
                        />  
                        <label> Exento Requerido </label>
                    </div>
                </div>
                </MuiThemeProvider>
                
                {
                    exentoReq
                    ?
                    <div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            {
                                indicadoresGenerales
                                ?
                                <AutocompleteComponent
                                id="IndicadoresGeneralesInGral"
                                title="Conceptos"
                                options={indicadoresGenerales}
                                action={indicadorAutocomplete}
                                optionListView="indicadorNomClve"
                                placeholder="Conceptos"
                                size="100%"
                                tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                tooltipName="tooltipAutoIndGral"
                                required 
                                defaultValue={defValIndicador}
                                />
                                : null
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            {
                                salarioMinimos
                                ?
                                    <SelectValidation title="Cálculo Sobre" arrayOpciones={salarioMinimos} keyOpcion="zonaNom"
                                        refValue={calcSobreRef} required onChangeExecute={getMontoZona}
                                        defaultValue={ updateIndicadorParametros ? defValCalcSob : '' }/>
                                :
                                null
                            }
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Tope en Veces" type="topeVeces" placeholder="Escriba la cantidad de tope en veces"
                                tooltipText={'Máximo 100 números'} tooltipName={'tooltipNomRecInd'} maxLength={100} onlyNumbers
                                refValue={topeVecesRef} defaultValue={ updateIndicadorParametros ? defValTopVec : '' } required onChangeExecute={updateTopeVeces}/>
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <InputValidation title="Importe (MXN)"  type="monto"
                                tooltipText={'Importe'} tooltipName={'tooltipImpo'} refValue={ importeRef } defaultValue={ updateIndicadorParametros ? defValMonto : 0 } disabled optional/>
                        </div>
                    </div> 
                    </div>
                    : null
                    
                } 
                {error} 
                {errorAuto}
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Button variant="contained" className="btn-outline" onClick={e => siguiente(e, 1)}>{ 'Atras' } </Button>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                    </div> 
                </div>
            </form> 
        </div>
            { !elementSelect ? obtenerModalAdd( idModulo+"secondary", openAdd, setOpenAdd, saveConfig, null, errorTable, successTable, dataConfigBase ) : null }
            { elementSelect ? obtenerModalEdit( idModulo+"secondary", openAdd, setOpenAdd, elementSelect, saveConfig, null, errorTable, successTable, dataConfigBase ) : null }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
        </Fragment>
    );
}

export default FormIndicadoresGenerales;
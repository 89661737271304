import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_rh, politicas_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonPrimaVacacional, ArrayJsonAguinaldo, ArrayJsonDiasAjuste, ArrayJsonVacaciones} from '../../../Helpers/JsonToOneLevel';
import { inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
const Politicas = ({ id, permisos }) => {
    
    const [ primaVacacional, setPrimaVacacional ] = useState(null);
    const [ aguinaldo, setAguinaldo ] = useState(null);
    const [ vacaciones, setVacaciones ] = useState(null);
    const [ diasAjuste, setDiasAjuste ] = useState(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupogack_rh}/politica/prima/vacacional/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setPrimaVacacional( ArrayJsonPrimaVacacional(res.data.dataResponse) );
                    break;
                case 404:
                    setPrimaVacacional([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error)); 

        Axios.get(`${servicios_grupogack_rh}/politica/aguinaldo/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setAguinaldo( ArrayJsonAguinaldo(res.data.dataResponse) );
                    break;
                case 404:
                    setAguinaldo([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error)); 

        Axios.get(`${servicios_grupogack_rh}/politica/vacaciones/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setVacaciones( ArrayJsonVacaciones(res.data.dataResponse) );
                    break;
                case 404:
                    setVacaciones([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));

        Axios.get(`${servicios_grupogack_rh}/politica/dias/ajuste/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setDiasAjuste( ArrayJsonDiasAjuste(res.data.dataResponse) );
                    break;
                case 404:
                    setDiasAjuste([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));

    },[]);
    
    const politicas = (...arrays) => [].concat(...arrays.filter(Array.isArray));

    return (
        
        <div id={ id }>
            {
                 politicas(primaVacacional, aguinaldo, vacaciones, diasAjuste)   
                ? <DataTableService id={id} title={ politicas_ }
                    permisos={permisos} 
                    columns={[
                        { field: "tipo", title: "Tipo" },
                        { field: "fechaIni", title: "Fecha Inicial" },
                        { field: "fechaFin", title: "Fecha Final" } 
                    ]}
                    data={politicas(primaVacacional, aguinaldo, vacaciones, diasAjuste).sort((a, b)=> inputDateToLong(a.fechaIn) < inputDateToLong(b.fechaIn) ? 1 : inputDateToLong(a.fechaIn) > inputDateToLong(b.fechaIn) ? -1 :0 )}/>
                : <SpinnerOval/>
            }
        </div>

    );
}
 
export default Politicas;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ConvertDate from '../../../Helpers/ConvertDate';
import ModalService from '../../../ModalService/ModalService';
import FormAusentismos from './FormAusentismos';
import { inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';

const ModalAddAusentismos = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    
    const [error, setError] = useState(null);

    const { claveEmpleado, tiposAusentismo } = informacionAdicional;

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    };

    const enviarAusentismo = ausentismo => {
        const request = ausentismo;
        axios.post(`${servicios_grupogack_rh}/empleado/ausentismo/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const data = {
                        id: res.data.dataResponse.id,
                        ausentismoId: res.data.dataResponse.ausentismo.id,
                        ausentismoNom: res.data.dataResponse.ausentismo.ausentismoNom,
                        auseFechaIni: ConvertDate(res.data.dataResponse.auseFechaIni),
                        auseFechaIniInput: inputDateToLong(res.data.dataResponse.auseFechaIni),
                        auseFechaFin: ConvertDate(res.data.dataResponse.auseFechaFin),
                        auseFechaFinInput: inputDateToLong(res.data.dataResponse.auseFechaFin),
                        auseFechaAplica: ConvertDate(res.data.dataResponse.auseFechaAplica),
                        auseFechaAplicaInput: inputDateToLong(res.data.dataResponse.auseFechaAplica),
                        comentarios: res.data.dataResponse.comentarios
                    };
                    agregar(data);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    return (
        <ModalService
            title="Nuevo Ausentismo"
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormAusentismos
                        enviarAusentismo={enviarAusentismo}
                        error={error}
                        setError={setError}
                        claveEmpleado={claveEmpleado}
                        tiposAusentismo={tiposAusentismo}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddAusentismos;
import React from 'react';
import axios from 'axios';
import { registro_sera_eliminado, servicios_grupogack_rh, registro_no_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning'; 

const ModalDeletePoliticas = ({ alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const urls = [ { dir: 'aguinaldo/', nombre: 'Aguinaldo'},
                    {dir: 'prima/vacacional/', nombre: 'Prima Vacacional'},
                    {dir: 'dias/ajuste/', nombre: 'Días de Ajuste'},
                    {dir: 'vacaciones/', nombre: 'Vacaciones'}
                    ];

    const axiosEliminar = () => {
        const regex=/VA|PV|DA|AG$/;
        const dir = urls.filter(element => element.nombre===elementSelect.tipo)[0].dir;
        axios.delete( `${servicios_grupogack_rh}/politica/${dir}${elementSelect.id.replace(regex,'')}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        eliminar( elementSelect.id );
                        break;
                    case 404:
                        errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_eliminado );
            }) 
    }

    return (
        <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
    );
};

export default ModalDeletePoliticas;
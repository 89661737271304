import React, { createRef, useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import { servicios_grupogack_catalogo, servicios_grupogack_admon, servicios_grupogack_org, servicios_grupogack_nomina, servicios_grupogack_rh,
            cambios_no_guardados, registro_actualizado, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ConvertInfoEmp from '../Reingresos/Helpers/ConvertInfoEmp';
import FormDependientesEconomicos from './FormDependientesEconomicos';
import FormPensionesAlimenticias from './FormPensionesAlimenticias';
import FormOrganizacionEmpleado from './FormOrganizacionEmpleado';
import FormExperienciaLaboral from './FormExperienciaLaboral';
import FormNumerosEmergencia from './FormNumerosEmergencia';
import FormDatosPersonales from './FormDatosPersonales';
import FormPasatiempos from './FormPasatiempos';
import FormDomicilio from './FormDomicilio';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const FormEmpleados = ({ modulo, error, setError, enviarEmpleado, elementSelect, editar, errorTable, successTable }) => {
    
    const [active, setActive] = useState(1);
    const [infoEmpleado, setInfoEmpleado] = useState(null);
    
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlert2, setOpenAlert2] = useState(false);
    const [openValue2, setOpenValue2] = useState(0);
    const [openAlert3, setOpenAlert3] = useState(false);
    const [openValue3, setOpenValue3] = useState(0);
    const [openAlert4, setOpenAlert4] = useState(false);
    const [openValue4, setOpenValue4] = useState(0);
    const [openAlert5, setOpenAlert5] = useState(false);
    const [openValue5, setOpenValue5] = useState(0);
    const [openAlert6, setOpenAlert6] = useState(false);
    const [openValue6, setOpenValue6] = useState(0);
    const [openAlert7, setOpenAlert7] = useState(false);
    const [openValue7, setOpenValue7] = useState(0);
    const [openAlert8, setOpenAlert8] = useState(false);
    const [openValue8, setOpenValue8] = useState(0);
    const [openAlert9, setOpenAlert9] = useState(false);
    const [openValue9, setOpenValue9] = useState(0);
    const [openAlert10, setOpenAlert10] = useState(false);
    const [openValue10, setOpenValue10] = useState(0);
    const [openAlert11, setOpenAlert11] = useState(false);
    const [openValue11, setOpenValue11] = useState(0);
    const [openAlert12, setOpenAlert12] = useState(false);
    const [openValue12, setOpenValue12] = useState(0);
    const [openAlert13, setOpenAlert13] = useState(false);
    const [openValue13, setOpenValue13] = useState(0);
    
    // formulario de Información personal
    const [nivelesEscolaridad, setNivelesEscolaridad] = useState([]);
    const [estadosNacimiento, setEstadosNacimiento] = useState([]);
    const [tiposEstadoCivil, setTiposEstadoCivil] = useState([]);
    const [infoEscolaridad, setInfoEscolaridad] = useState(null);
    const [fotografiaEdit, setFotografiaEdit] = useState(null);
    const [fotografia, setFotografia] = useState(null);
    const nivelEscolaridadRef = createRef('');
    const fechaNacimientoRef = createRef('');
    const cedProfesionalRef = createRef('');
    const fechaIngresoRef = createRef('');
    const numEmpleadoRef = createRef('');
    const lNacimientoRef = createRef('');
    const estadoCivilRef = createRef('');
    const pApellidoRef = createRef('');
    const sApellidoRef = createRef('');
    const carreraRef = createRef('');
    const nombreRef = createRef('');
    const generoRef = createRef('');
    const curpRef = createRef('');
    const nssRef = createRef('');
    const rfcRef = createRef('');
    // formulario de formulario de organización del empleado
    const [selected, setSelected] = useState({ uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' }, plaza: { id: '', clave: '', nombre: '' }, puesto: { id: '', clave: '', nombre: '' } });
    const [disabledFormOrgEdit, setDisabledFormOrgEdit] = useState({ cPresupuestal: true, departamento: true, plaza: true, zonaPago: true, puesto: true, turno: true, nomina: true });
    const [disabled, setDisabled] = useState({ departamento: true, plaza: true, zonaPago: true });
    const [errorFormPago, setErrorFormPago] = useState({ active: false, message: '' });
    const [errorCPresupuestal, setErrorCPresupuestal] = useState(false);
    const [errorDepartamento, setErrorDepartamento] = useState(false);
    const [formasPagoSelected, setFormasPagoSelected] = useState([]);
    const [indicadoresSelected, setIndicadoresSelected] = useState([]);
    const [infoNombramiento, setInfoNombramiento] = useState(null);
    const [cPresupuestales, setCPresupuestales] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [infoAguinaldo, setInfoAguinaldo] = useState(null);
    const [infoContrato, setInfoContrato] = useState(null);
    const [errorPuesto, setErrorPuesto] = useState(false);
    const [errorPlaza, setErrorPlaza] = useState(false);
    const [indicadores, setIndicadores] = useState([]);
    const [zonasPago, setZonasPago] = useState([]);
    const [puestos, setPuestos] = useState([]);
    const [nominas, setNominas] = useState([]);
    const [turnos, setTurnos] = useState([]);
    const [plazas, setPlazas] = useState([]);
    const [monto, setMonto] = useState(0);
    const [infoRegPatronal, setInfoRegPatronal] = useState(null);
    const [infoSindicato, setInfoSindicato] = useState(null);
    const [sindicalizado, setSindicalizado] = useState('no');
    const [cuotaSindical, setCuotaSindical] = useState('no');
    const [sindicatoSeleccionado, setSindicatoSeleccionado] = useState({ id: '', sindicatoNombre: null, sindicatoPoc: null, sindicatoMonto: 0 });
    const fechaInicialContratoRef = createRef('');
    const fechaFinalContratoRef = createRef('');
    const fechaNombramientoRef = createRef('');
    const fechaAguinaldoRef = createRef('');
    const regPatronalRef = createRef('');
    const formaPagoRef = createRef('');
    const numCuentaRef = createRef('');
    const sindicatoRef = createRef('');
    const zonaPagoRef = createRef('');
    const cbeInterRef = createRef('');
    const contratoRef = createRef('');
    const nominaRef = createRef('');
    const turnoRef = createRef('');
    const bancoRef = createRef('');
    // formulario de domicilio
    const [infoDomicilio, setInfoDomicilio] = useState(null);
    const numExteriorRef = createRef('');
    const numInteriorRef = createRef('');
    const coloniaRef = createRef('');
    const ciudadRef = createRef('');
    const estadoRef = createRef('');
    const calleRef = createRef('');
    const cpRef = createRef('');
    //formulario de dependientes económicos
    const [elementoSeleccionadoDepEcon, setElementoSeleccionadoDepEcon] = useState(null);
    const [mostrarFormAddDepEconEdit, setMostrarFormAddDepEconEdit] = useState(false);
    const [depEconomicosSavedEdit, setDepEconomicosSavedEdit] = useState([]);
    const [mostrarDiscapacidad, setMostrarDiscapacidad] = useState(false);
    const [depEconomicosSaved, setDepEconomicosSaved] = useState([]);
    const [tiposParentesco, setTiposParentesco] = useState([]);
    const gradoEstudiosDepEconomicoRef = createRef('');
    const parentescoDepEconommicoRef = createRef('');
    const escolaridadDepEconomicoRef = createRef('');
    const pApellidoDepEconomicoRef = createRef('');
    const sApellidoDepEconomicoRef = createRef('');
    const fechaNacDepEconomicoRef = createRef('');
    const promedioDepEconomicoRef = createRef('');
    const nombreDepEconomicoRef = createRef('');
    const nombreDiscapacidadRef = createRef('');
    const nivelDiscapacidadRef = createRef('');
    const curpDepEconomicoRef = createRef('');
    //formulario de pensiones alimenticias
    const [elementoSeleccionadoPensionAlim, setElementoSeleccionadoPensionAlim] = useState(null);
    const [mostrarFormAddPenAlimEdit, setMostrarFormAddPenAlimEdit] = useState(false);
    const [pensionesAlimSavedEdit, setPensionesAlimSavedEdit] = useState([]);
    const [pensionesAlimSaved, setPensionesAlimSaved] = useState([]);
    const pApellidoPensionAlimRef = createRef('');
    const sApellidoPensionAlimRef = createRef('');
    const tipoCuotaPensionAlimRef = createRef('');
    const coloniaNumEmergenciaRef = createRef('');
    const ciudadNumEmergenciaRef = createRef('');
    const nombrePensionAlimRef = createRef('');
    const folioPensionAlimRef = createRef('');
    const montoPensionAlimRef = createRef('');
    //formulario de números de emergencia
    const [elementoSeleccionadoNumEmer, setElementoSeleccionadoNumEmer] = useState(null);
    const [llamadasEmergenciaSavedEdit, setLlamadasEmergenciaSavedEdit] = useState([]);
    const [mostrarFormAddNumEmerEdit, setMostrarFormAddNumEmerEdit] = useState(false);
    const [llamadasEmergenciaSaved, setLlamadasEmergenciaSaved] = useState([]);
    const numInteriorNumEmergenciaRef = createRef('');
    const numExteriorNumEmergenciaRef = createRef('');
    const parentescoNumEmergenciaRef = createRef('');
    const fechaInicioPensionAlimRef = createRef('');
    const pApellidoNumEmergenciaRef = createRef('');
    const sApellidoNumEmergenciaRef = createRef('');
    const telMovilNumEmergenciaRef = createRef('');
    const fechaFinalPensionAlimRef = createRef('');
    const telFijoNumEmergenciaRef = createRef('');
    const nombreNumEmergenciaRef = createRef('');
    const estadoNumEmergenciaRef = createRef('');
    const calleNumEmergenciaRef = createRef('');
    const cpNumEmergenciaRef = createRef('');
    //Formulario de Experiencia laboral
    const [elementoSeleccionadoExpLaboral, setElementoSeleccionadoExpLaboral] = useState(null);
    const [mostrarFormAddExpLaboralEdit, setMostrarFormAddExpLaboralEdit] = useState(false);
    const [expLaboralSavedEdit, setExpLaboralSavedEdit] = useState([]);
    const [expLaboralSaved, setExpLaboralSaved] = useState([]);
    const empresaExpLabRef = createRef('');
    const puestoExpLabRef = createRef('');
    const desdeMesExpLabRef = createRef('');
    const desdeAnioExpLabRef = createRef('');
    const hastaMesExpLabRef = createRef('');
    const hastaAnioExpLabRef = createRef('');
    const nombreJefeExpLabRef = createRef('');
    const telefonoExpLabRef = createRef('');
    //Formulario de Pasatiempos
    const [elementoSeleccionadoPasatiempo, setElementoSeleccionadoPasatiempo] = useState(null);
    const [mostrarFormAddPasatiempoEdit, setMostrarFormAddPasatiempoEdit] = useState(false);
    const [pasatiempoSavedEdit, setPasatiempoSavedEdit] = useState([]);
    const [pasatiempoSaved, setPasatiempoSaved] = useState([]);
    const [pasatiempos, setPasatiempos] = useState([]);
    const pasatiempoRef = createRef('');

    const validaAutocompleteOrganizacion = () => {
        if(selected.cPresupuestal.id === '') setErrorCPresupuestal(true);
        if(selected.departamento.id === '') setErrorDepartamento(true);
        if(selected.plaza.id === '') setErrorPlaza(true);
        if(selected.puesto.id === '') setErrorPuesto(true);
    };

    const verificaCambiosFormEmpleado = () => {
        if(
            numEmpleadoRef.current.value !== infoEmpleado.empleado.empleadoclave ||
            nombreRef.current.value !== infoEmpleado.empleado.empleadonom ||
            pApellidoRef.current.value !== (infoEmpleado.empleado.empleadopat ? infoEmpleado.empleado.empleadopat :'') ||
            sApellidoRef.current.value !== (infoEmpleado.empleado.empleadomat ? infoEmpleado.empleado.empleadomat : '') ||
            inputDateToLong(fechaNacimientoRef.current.value) !== infoEmpleado.empleado.empleadofecnac ||
            parseInt(lNacimientoRef.current.value) !== infoEmpleado.empleado.estado.id ||
            generoRef.current.value !== (infoEmpleado.empleado.empleadoGenero ? infoEmpleado.empleado.empleadoGenero : '') ||
            parseInt(estadoCivilRef.current.value) !== infoEmpleado.empleado.edoCivil.id ||
            rfcRef.current.value !== (infoEmpleado.empleado.empleadorfc ? infoEmpleado.empleado.empleadorfc : '') ||
            curpRef.current.value !== (infoEmpleado.empleado.empleadocurp ? infoEmpleado.empleado.empleadocurp : '') ||
            nssRef.current.value !== (infoEmpleado.empleado.empleadonss ? infoEmpleado.empleado.empleadonss : '') ||
            inputDateToLong(fechaIngresoRef.current.value) !== infoEmpleado.antiguedad.empantfecini ||
            parseInt(nivelEscolaridadRef.current.value) !== infoEscolaridad.escolaridad.id ||
            carreraRef.current.value !== (infoEscolaridad.empleadoCarrera ? infoEscolaridad.empleadoCarrera : '') ||
            cedProfesionalRef.current.value !== (infoEscolaridad.empleadoCedula ? infoEscolaridad.empleadoCedula : '') ||
            fotografiaEdit !== null
        ) {
            setError(null);
            return true;
        } else {
            setError(<AlertForm message="No se han realizado cambios en el formulario"/>);
            return false;
        }
    };

    const activaBtn = (campo, valor) => {
        switch (campo) {
            case "CPresupuestal":
                if(valor !== null) {
                    setDisabled({ ...disabled, departamento: false, zonaPago: false });
                } else {
                    setDepartamentos([]);
                    setDisabled({ ...disabled, departamento: true, zonaPago: true });
                    setSelected({ ...selected, uResponsable : { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' } });
                }
                if(valor === null) {
                    setSelected({ ...selected, cPresupuestal: { id: '', clave: '', nombre: '' } });
                } 
            break;
            case "Departamento":
                if(valor !== null) {
                    setDisabled({ ...disabled, plaza: false });
                } else {
                    setDisabled({ ...disabled, plaza: true });
                    setSelected({ ...selected, plaza: { id: '', clave: '', nombre: '' } });
                }
            break;
            default:
                setDisabled({ uEjecutora: true, programa: true, pAccion: true, cPresupuestal: true });
                setSelected({ ...selected, uResponsable : { id: '',  clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' } });
            break;
        }
    };
    
    const validarCPresupuestal = valor => {
        activaBtn("CPresupuestal", valor);

        if(valor) {
            setErrorCPresupuestal(false);
            setSelected({ ...selected, uResponsable: { id: valor.proyectoid.programaid.udejecutoraid.ciaid.id, clave: valor.proyectoid.programaid.udejecutoraid.ciaid.ciaUr, nombre: valor.proyectoid.programaid.udejecutoraid.ciaid.ciaRazonSocial }, uEjecutora: { id: valor.proyectoid.programaid.udejecutoraid.id, clave: valor.proyectoid.programaid.udejecutoraid.udejecutoraclave, nombre: valor.proyectoid.programaid.udejecutoraid.udejecutoranom }, programa: { id: valor.proyectoid.programaid.id, clave: '', nombre: valor.proyectoid.programaid.programanom }, pAccion: { id: valor.proyectoid.id, clave: '', nombre: valor.proyectoid.proyectonom }, cPresupuestal: { id: valor.id, clave: valor.cpresupuestalclave, nombre: valor.cpresupuestalnom }, departamento: { id: '', clave: '', nombre: '' }, plaza: { id: '', clave: '', nombre: '' } });

            //petición para obtener los departamentos, según la unidad responsable seleccionada
            axios.get(`${servicios_grupogack_org}/area/${valor.proyectoid.programaid.udejecutoraid.ciaid.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setDepartamentos(res.data.dataResponse);
                        break;
                    case 404:
                        setDepartamentos([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));

            //petición para obtener laz zonas de pago, según la unidad responsable seleccionada
            axios.get(`${servicios_grupogack_admon}/compania/domicilios/${valor.proyectoid.programaid.udejecutoraid.ciaid.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setZonasPago(res.data.dataResponse);
                        break;
                    case 404:
                        setZonasPago([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
        } else {
            setSelected({ ...selected, uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cPresupuestal: { id: '', clave: '', nombre: '' }, departamento: { id: '', clave: '', nombre: '' }, plaza: { id: '', clave: '', nombre: '' } })
            setDepartamentos([]);
            setPlazas([]);
            setZonasPago([]);
        }
    };

    const validarDepartamento = valor => {
        activaBtn("Departamento", valor);

        if(valor) {
            setErrorDepartamento(false);
            setSelected({ ...selected, departamento: { id: valor.id, clave: valor.areacve, nombre: valor.areanom }, plaza: { id: '', clave: '', nombre: '' } });
            
            axios.get(`${servicios_grupogack_org}/plaza/disponibles/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPlazas(res.data.dataResponse);
                        break;
                    case 404:
                        setPlazas([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
        } else {
            setSelected({ ...selected, departamento: { id: '', clave: '', nombre: '' }, plaza: { id: '', clave: '', nombre: '' } });
            setPlazas([]);
        }
    };

    const validarPuesto = valor => {
        if(valor) {
            setErrorPuesto(false);
            axios.get(`${servicios_grupogack_org}/tab/puestomonto/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setMonto(res.data.dataResponse.monto);
                        setSelected({ ...selected, puesto: { id: valor.id, clave: valor.puestoCve, nombre: valor.puestoNom } });
                        break;
                    case 404:
                        setMonto(0);
                        setSelected({ ...selected, puesto: { id: valor.id, clave: valor.puestoCve, nombre: valor.puestoNom } });
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => {alert('error'); console.log(error)});
            
            axios.get(`${servicios_grupogack_org}/tab/puestoindicadores/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setIndicadores(res.data.dataResponse);
                        break;
                    default:
                        setIndicadores([]);
                        break;
                }
            })
            .catch(error => setIndicadores([]));
        } else {
            setSelected({ ...selected, puesto: { id: '', clave : '', nombre : '' } });
            setIndicadores([]);
        }
    };

    const validarPlaza = valor => {
        if(valor) {
            setErrorPlaza(false);
            setSelected({ ...selected, plaza: { id: valor.id, clave : valor.plazaclave, nombre : '' } });
        } else {
            setSelected({ ...selected, plaza: { id: '', clave : '', nombre : '' } });
        }
    };

    useEffect(() => {
        //servicio que obtiene los niveles de escolaridad
        axios.get(`${servicios_grupogack_catalogo}/tipoEscolaridad`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNivelesEscolaridad(res.data.dataResponse);
                    break;
                case 404:
                    setNivelesEscolaridad([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        //servicio que obtiene los tipos de estado civil
        axios.get(`${servicios_grupogack_catalogo}/tipoedocivil/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTiposEstadoCivil(res.data.dataResponse);
                    break;
                case 404:
                    setTiposEstadoCivil([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        //servicio que obtiene las Entidades federativas
        axios.get(`${servicios_grupogack_catalogo}s/estado/1`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEstadosNacimiento(res.data.dataResponse);
                    break;
                case 404:
                    setEstadosNacimiento([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        //servicio para cargar las claves presupuestales
        axios.get(`${servicios_grupogack_admon}/presupuestal/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setCPresupuestales(res.data.dataResponse);
                    break;
                case 404:
                    setCPresupuestales([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        //servicio para cargar los puestos dependiendo de la relación laboral seleccionada al inicio
        axios.get(`${servicios_grupogack_org}/puesto/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPuestos(res.data.dataResponse);
                    break;
                case 404:
                    setPuestos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        //servicio para cargar los tipos de jornada
        axios.get(`${servicios_grupogack_catalogo}/turno/`, headersAuth() )
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTurnos(res.data.dataResponse);
                    break;
                case 404:
                    setTurnos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        //petición para obtener las nóminas según la relación laboral
        axios.get(`${servicios_grupogack_nomina}/config/nomina`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setNominas(res.data.dataResponse);
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        //petición para obtener el catálogo de pasatiempos
        axios.get(`${servicios_grupogack_catalogo}/pasatiempos/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const respuesta = res.data.dataResponse.map(item => ({ 'id': item.id, 'pasatiempo': item.pasaNom, 'descripcion': item.panDesc }));
                    setPasatiempos(respuesta);
                    break;
                case 404:
                    setPasatiempos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    useEffect(() => {
        if(active === 4 || active === 6) {
            if(tiposParentesco.length === 0) {
                //petición para obtener el catalogo de tipo de parentesco
                axios.get(`${servicios_grupogack_catalogo}/tipoparentesco/`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setTiposParentesco(res.data.dataResponse);
                            break;
                        case 404:
                            setTiposParentesco([]);
                            break;
                        default:
                            alert('error');
                            break;
                    }
                })
                .catch(error => alert('error'));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active]);

    useEffect(() => {
        if(elementSelect) {
            let url = '';
            if(modulo === 30) url = 'empleado';
            if(modulo === 32) url = 'empleado/inactivos';
            
            axios.get(`${servicios_grupogack_rh}/${url}/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        if(modulo === 30) setInfoEmpleado(res.data.dataResponse);
                        if(modulo === 32) setInfoEmpleado(ConvertInfoEmp((res.data.dataResponse)));
                        break;
                    default:
                        errorTable('No se puede obtener información del empleado');
                        break;
                }
            })
            .catch(error => errorTable('No se puede obtener información del empleado'));

            axios.get(`${servicios_grupogack_rh}/empleado/contrato/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setInfoContrato(res.data.dataResponse);
                        break;
                    case 404:
                        setInfoContrato({
                            contrato: {
                                id: ''
                            },
                            fechaini: null,
                            fechafin: null
                        });
                        break;
                    default:
                        errorTable('No se puede obtener fecha de contrato');
                        break;
                }
            })
            .catch(error => { errorTable('No se puede obtener la fecha de contrato'); console.log(error) });

            axios.get(`${servicios_grupogack_rh}/empleado/aguinaldo/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const respuesta = {
                            id: res.data.dataResponse.id,
                            fechaAguinaldo: res.data.dataResponse.empFecAgui
                        };
                        setInfoAguinaldo(respuesta);
                        break;
                    case 404:
                        const fechaAguinaldo = {
                            id: '',
                            fechaAguinaldo: ''
                        };
                        setInfoAguinaldo(fechaAguinaldo);
                        break;
                    default:
                        errorTable('No se puede obtener fecha de aguinaldo');
                        break;
                }
            })
            .catch(error => { errorTable('No se puede obtener fecha de aguinaldo'); console.log(error) });

            axios.get(`${servicios_grupogack_rh}/empleado/nombramiento/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const respuesta = {
                            id: res.data.dataResponse.id,
                            fechaNombramiento: res.data.dataResponse.empFecAgui
                        };
                        setInfoNombramiento(respuesta);
                        break;
                    case 404:
                        const fechaNombramiento = {
                            id: '',
                            fechaNombramiento: ''
                        };
                        setInfoNombramiento(fechaNombramiento);
                        break;
                    default:
                        errorTable('No se puede obtener la fecha de nombramiento');
                        break;
                }
            })
            .catch(error => { errorTable('No se puede obtener fecha de nombramiento'); console.log(error) });

            axios.get(`${servicios_grupogack_rh}/empleado/escolaridad/last/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const respuesta = { id: res.data.dataResponse.id , escolaridad: { id: (res.data.dataResponse.escolaridad.id) ? res.data.dataResponse.escolaridad.id : '' }, empleadoCarrera: (res.data.dataResponse.empleadoCarrera) ? res.data.dataResponse.empleadoCarrera : '', empleadoCedula: (res.data.dataResponse.empleadoCedula) ? res.data.dataResponse.empleadoCedula : '' };
                        setInfoEscolaridad(respuesta);
                        break;
                    case 404:
                        setInfoEscolaridad({ escolaridad: { id: '' }, empleadoCarrera: '', empleadoCedula: '' });
                        break;
                    default:
                        errorTable('No se puede obtener información de escolaridad');
                        break;
                }
            })
            .catch(error => { errorTable('No se puede obtener información de escolaridad'); console.log(error) });

            axios.get(`${servicios_grupogack_rh}/documentos/${elementSelect.id}/1`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setFotografia(res.data.dataResponse[0]);
                        break;
                    case 404:
                        setFotografia(null);
                        break;
                    default:
                        errorTable('No se puede obtener fotografía del colaborador');
                        break;
                }
            })
            .catch(error => { errorTable('No se puede obtener fotografía del colaborador'); console.log(error) });

            axios.get(`${servicios_grupogack_rh}/empleado/domicilio/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        setInfoDomicilio(res.data.dataResponse);
                        break;
                    case 404:
                        setInfoDomicilio([]);
                        break;
                    default:
                        errorTable('No se puede obtener información de domicilio');
                        break;
                }
            })
            .catch(error => { errorTable('No se puede obtener información de domicilio'); });
            //petición para obtener información de registro patronal
            axios.get(`${servicios_grupogack_rh}/empleado/registropat/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        const regPatronal = {
                            id: res.data.dataResponse.id,
                            regPatronal: {
                                id: res.data.dataResponse.regPat.id,
                                regPatronal: res.data.dataResponse.regPat.registroPatClave
                            }
                        };
                        setInfoRegPatronal(regPatronal);
                        break;
                    case 404:
                        setInfoRegPatronal({ id: '', regPatronal: { id: '' } });
                        break;
                    default:
                        errorTable('No se puede obtener información de registro patronal');
                        break;
                }
            })
            .catch(error => {errorTable('No se puede obtener información de registro patronal'); });
            //petición para obtener información de sindicato
            axios.get(`${servicios_grupogack_rh}/empleado/sindicatos/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status){
                    case 200:
                        const sindicato = {
                            id: res.data.dataResponse.id,
                            sindicato: res.data.dataResponse.sindicatoId,
                            aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                        };
                        setInfoSindicato(sindicato);
                        break;
                    case 404:
                        setInfoSindicato({ id: '', sindicato: { id: '' }, aplica: 'no' });
                        break;
                    default:
                        errorTable('No se puede obtener información de sindicato');
                        break;
                }
            })
            .catch(error => {errorTable('No se puede obtener información de sindicato'); });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const enviar = () => {
        if(validarSubmit('DatosPersonales') && validarSubmit('OrganizacionEmpleado') && formasPagoSelected.length > 0) {
            if(selected.cPresupuestal.id !== '' && selected.departamento.id !== '' && selected.plaza.id !== '' && selected.puesto.id !== '') {
                const empleadoclave = numEmpleadoRef.current.value;
                const empleadonom = nombreRef.current.value;
                const empleadopat = pApellidoRef.current.value;
                const empleadomat = sApellidoRef.current.value;
                const empleadofecnac = inputDateToLong(fechaNacimientoRef.current.value);
                const empleadocurp = curpRef.current.value;
                const empleadorfc = rfcRef.current.value;
                const empleadonss = nssRef.current.value;
                const empleadoGenero = generoRef.current.value;
                const edoCivil = parseInt(estadoCivilRef.current.value)
                const estado = parseInt(lNacimientoRef.current.value);
                
                let datosEmpleado = { empleadoclave, empleadonom, empleadofecnac, edoCivil, estado };
    
                if(empleadopat !== '') datosEmpleado = { ...datosEmpleado, empleadopat };
                if(empleadomat !== '') datosEmpleado = { ...datosEmpleado, empleadomat };
                if(empleadocurp !== '') datosEmpleado = { ...datosEmpleado, empleadocurp };
                if(empleadorfc !== '') datosEmpleado = { ...datosEmpleado, empleadorfc };
                if(empleadonss !== '') datosEmpleado = { ...datosEmpleado, empleadonss };
                if(empleadoGenero !== '') datosEmpleado = { ...datosEmpleado, empleadoGenero };
    
                const empantfecini = inputDateToLong(fechaIngresoRef.current.value);
                const fechaIngreso = empantfecini;
    
                const puestoId = selected.puesto.id;
                const puestoCve = selected.puesto.clave;
                const puestoNom = selected.puesto.nombre;
                const empPuestoFecIni = inputDateToLong(fechaIngresoRef.current.value);
                const datosPuesto = { puestoId, puestoCve, puestoNom, empPuestoFecIni };
    
                const domciaId = parseInt(zonaPagoRef.current.value);
                const domicilioSelected = zonasPago.find(zP => zP.id === domciaId); 
                const domciaCalle = domicilioSelected.domCiaCalle;
                const domciaNumext = domicilioSelected.domCiaNumInt;
                const domciaNumint = domicilioSelected.domCiaNumExt;
                const empzpfecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosZonaPago = { domciaId, domciaCalle, domciaNumext, domciaNumint, empzpfecini };
    
                const cpresupuestalid = selected.cPresupuestal.id;
                const cpresupuestalclave = selected.cPresupuestal.clave;
                const cpresupuestalNom = selected.cPresupuestal.nombre;
                const empcpresfecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosCPresupuestal = { cpresupuestalid, cpresupuestalclave, cpresupuestalNom, empcpresfecini };
    
                const turnoId = parseInt(turnoRef.current.value);
                const turnoSelected = turnos.find(turno => turno.id === turnoId)
                const turnoNom = turnoSelected.turnoNom;
                const fecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosTurno = { turnoId, turnoNom, fecini };
    
                const nominaid = parseInt(nominaRef.current.value);
                const infoNomina = nominas.find(n => n.id === nominaid);
                const nominaNomCorto = infoNomina.nominaNomCorto;
                const nominaNom = infoNomina.nominaNombre;
                const nominaClave = infoNomina.nominaClave;
                const empnominafecini = inputDateToLong(fechaIngresoRef.current.value);
                const datosNomina = { nominaid, nominaNomCorto, nominaNom, nominaClave, empnominafecini };
    
                const plazaId = selected.plaza.id;
                const plazaclave = selected.plaza.clave;
                const empFecIni = inputDateToLong(fechaIngresoRef.current.value);
                const datosPlaza = { plazaId, plazaclave, empFecIni };
    
                const calle = calleRef.current.value;
                const numint = numInteriorRef.current.value;
                const numext = numExteriorRef.current.value;
                const cpId = parseInt(coloniaRef.current.value);
                const domicilio = { calle, numint, numext, cpId };
    
                const escolaridad = parseInt(nivelEscolaridadRef.current.value);
                const empleadoCarrera = carreraRef.current.value;
                const empleadoCedula = cedProfesionalRef.current.value;
                const datosEscolaridad = { escolaridad, empleadoCarrera, empleadoCedula };
    
                const areaNom = selected.departamento.nombre;

                const documento = 1;
                const tempdocNota = "Fotografía del colaborador";
                const archivo = (fotografia) ? fotografia : null;
                let infoFofografia = null;
                if(archivo) infoFofografia = { documento, tempdocNota, archivo };

                let empleadoIndicadores = null;
                if(indicadoresSelected.length) empleadoIndicadores = indicadoresSelected;

                let empleadoContrato = null;
                if(contratoRef.current.value !== '') {
                    const contratoId = parseInt(contratoRef.current.value);
                    const Fecini = inputDateToLong(fechaIngresoRef.current.value);
                    const Fecfin = inputDateToLong(fechaFinalContratoRef.current.value);

                    empleadoContrato = { contratoId, Fecini }

                    if(fechaFinalContratoRef.current.value !== '') {
                        empleadoContrato = { ...empleadoContrato, Fecfin}
                    }
                }
    
                let empFecAgui = null;
                if(fechaAguinaldoRef.current.value !== '') {
                    empFecAgui = inputDateToLong(fechaAguinaldoRef.current.value);
                }

                let empFecNom = null;
                if(fechaNombramientoRef.current.value !== '') {
                    empFecNom = inputDateToLong(fechaNombramientoRef.current.value);
                }

                let infoSindicato = null;
                if(sindicatoRef.current) {
                    if(sindicatoRef.current.value !== '') {
                        infoSindicato = {
                            sindId: parseInt(sindicatoRef.current.value),
                            aplica: (cuotaSindical === 'si') ? true : false
                        };
                    }
                }

                let regPatId = null;
                if(regPatronalRef.current.value !== '') {
                    parseInt(regPatronalRef.current.value);
                }

                enviarEmpleado(
                    datosEmpleado,
                    fechaIngreso,
                    datosPuesto,
                    datosZonaPago,
                    datosCPresupuestal,
                    datosTurno,
                    datosNomina,
                    datosPlaza,
                    formasPagoSelected,
                    empPuestoFecIni,
                    infoSindicato,
                    depEconomicosSaved,
                    pensionesAlimSaved,
                    llamadasEmergenciaSaved,
                    domicilio,
                    datosEscolaridad,
                    areaNom,
                    infoFofografia,
                    empleadoIndicadores,
                    empleadoContrato,
                    empFecAgui,
                    empFecNom,
                    expLaboralSaved,
                    pasatiempoSaved,
                    regPatId
                );
            } else {
                validaAutocompleteOrganizacion();
            }
        } else {
            if(!formasPagoSelected.length) {
                setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
            }
            validaAutocompleteOrganizacion();
        }
    };

    const actualizarEmpleado = infoEmp => {
        if(editar) {
            axios.put(`${servicios_grupogack_rh}/empleado/`, infoEmp, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setInfoEmpleado({
                            ...infoEmpleado,
                            empleado: {
                                id: elementSelect.id,
                                edoCivil: res.data.dataResponse.edoCivil,
                                empleadoGenero: res.data.dataResponse.empleadoGenero,
                                empleadoclave: res.data.dataResponse.empleadoclave,
                                empleadocurp: res.data.dataResponse.empleadocurp,
                                empleadofecnac: res.data.dataResponse.empleadofecnac,
                                empleadonom: res.data.dataResponse.empleadonom,
                                empleadopat: res.data.dataResponse.empleadopat,
                                empleadomat: res.data.dataResponse.empleadomat,
                                nombreCompleto: res.data.dataResponse.nombreCompleto,
                                empleadonss: res.data.dataResponse.empleadonss,
                                empleadorfc: res.data.dataResponse.empleadorfc,
                                estado: res.data.dataResponse.estado
                            }
                        });

                        editar({
                            id: elementSelect.id,
                            areaNom: elementSelect.areaNom,
                            cpresupuestalClave: elementSelect.cpresupuestalClave,
                            cpresupuestalNom: elementSelect.cpresupuestalNom,
                            domciaId: elementSelect.domciaId,
                            domciaCalle: elementSelect.domciaCalle,
                            domciaNumext: elementSelect.domciaNumext,
                            domciaNumint: elementSelect.domciaNumint,
                            empleadoClave: res.data.dataResponse.empleadoclave,
                            nombreCompleto: res.data.dataResponse.nombreCompleto,
                            empleadoNom: res.data.dataResponse.empleadonom,
                            empleadoPat: res.data.dataResponse.empleadopat,
                            empleadoMat: res.data.dataResponse.empleadomat,
                            empleadoFecnac: res.data.dataResponse.empleadofecnac,
                            empleadoCurp: res.data.dataResponse.empleadocurp,
                            empleadoRfc: res.data.dataResponse.empleadorfc,
                            empleadoNss: res.data.dataResponse.empleadonss,
                            empantFecini: elementSelect.empantFecini,
                            empstatusFecini: elementSelect.empstatusFecini,
                            nominaClave: elementSelect.nominaClave,
                            nominaNomCorto: elementSelect.nominaNomCorto,
                            nominaNombre: elementSelect.nominaNombre,
                            plazaId: elementSelect.plazaId,
                            plazaClave: elementSelect.plazaClave,
                            puestoCve: elementSelect.puestoCve,
                            puestoNom: elementSelect.puestoNom,
                            tipojornadaClave: elementSelect.tipojornadaClave,
                            tipojornadaNom: elementSelect.tipojornadaNom,
                            turnoId: elementSelect.turnoId,
                            turnoNom: elementSelect.turnoNom
                        });
                        break;
                    case 400:
                    case 404:
                        errorTable(res.data.msg);
                        break;
                    default:
                        errorTable(cambios_no_guardados);
                        break;
    
                }
            })
            .catch(error => errorTable(cambios_no_guardados));
        }
    };

    const actualizarEscolaridad = (infoEsd, idEsc) => {
        axios.put(`${servicios_grupogack_rh}/empleado/escolaridad/${idEsc}`, infoEsd, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setInfoEscolaridad({
                        ...infoEscolaridad,
                        escolaridad: res.data.dataResponse.escolaridad,
                        empleadoCedula: res.data.dataResponse.empleadoCedula,
                        empleadoCarrera: res.data.dataResponse.empleadoCarrera
                    });
                    successTable(registro_actualizado)
                    break;
                case 400:
                case 404:
                    setError(<AlertForm message="res.data.msg"/>);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    const actualizarFotografia = () => {
        if(elementSelect) {
            if(fotografiaEdit !== null) {
                if(fotografia) {
                    //actualiza fotografía
                    const documento = 1;
                    const tempdocNota = "Fotografía del colaborador";
                    const archivo = (fotografiaEdit) ? fotografiaEdit : null;
                    const empleado = elementSelect.id;
        
                    let infoFofografia = null;
        
                    if(archivo) infoFofografia = { documento, tempdocNota, archivo, empleado };
                    axios.put(`${servicios_grupogack_rh}/documentos/${fotografia.id}`, infoFofografia, headersAuth())
                    .then(res => {
                        switch(res.data.status){
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch( error => errorTable(registro_no_guardado));
                } else {
                    //guarda fotografía
                    const documento = 1;
                    const tempdocNota = "Fotografía del colaborador";
                    const archivo = (fotografiaEdit) ? fotografiaEdit : null;
                    const empleado = elementSelect.id;
        
                    let infoFofografia = null;
        
                    if(archivo) infoFofografia = { documento, tempdocNota, archivo, empleado };

                    axios.post(`${servicios_grupogack_rh}/documentos/`, infoFofografia, headersAuth())
                    .then(res => {
                        switch(res.data.status){
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch( error => errorTable(registro_no_guardado));
                }
            }
        }
    };

    const actualizarOrganizacion = info => {
        if(validarSubmit("OrganizacionEmpleado")) {
            if(info) {
                if(info.cPresupuestal) {
                    axios.put(`${servicios_grupogack_rh}/empleado/presupuestal/`, info.cPresupuestal, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch(error => errorTable(registro_no_guardado));
                }
                if(info.plaza) {
                    axios.put(`${servicios_grupogack_rh}/empleado/plaza/`, info.plaza, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch(error => errorTable(registro_no_guardado));
                }
                if(info.puesto) {
                    axios.put(`${servicios_grupogack_rh}/empleado/puesto/`, info.puesto, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch(error => errorTable(registro_no_guardado));
                }
                if(info.zonaPago) {
                    axios.put(`${servicios_grupogack_rh}/empleado/zonapago/`, info.zonaPago, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch(error => errorTable(registro_no_guardado));
                }
                if(info.turno) {
                    axios.put(`${servicios_grupogack_rh}/empleado/turno/`, info.turno, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch(error => errorTable(registro_no_guardado));
                }
                if(info.nomina) {
                    axios.put(`${servicios_grupogack_rh}/empleado/nomina/`, info.nomina, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(registro_no_guardado);
                                break;
                        }
                    })
                    .catch(error => errorTable(registro_no_guardado));
                }
            }
    
            if(infoContrato !== null) {
                //actualizar
                if(
                    (infoContrato.contrato.id !== parseInt(contratoRef.current.value)) ||
                    (infoContrato.fechaini !== inputDateToLong(fechaInicialContratoRef.current.value)) ||
                    (infoContrato.fechafin !== ((fechaFinalContratoRef.current) ? (fechaFinalContratoRef.current.value !== '') ? inputDateToLong(fechaFinalContratoRef.current.value) : null : null))
                ) {
                    let info = { empId: elementSelect.id, contratoId: parseInt(contratoRef.current.value), Fecini: inputDateToLong(fechaInicialContratoRef.current.value) };
                    if(fechaFinalContratoRef.current.value !== '') info = { ...info, Fecfin: inputDateToLong(fechaFinalContratoRef.current.value) };
                    axios.put(`${servicios_grupogack_rh}/empleado/contrato/`, info, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                setInfoContrato({
                                    contrato: {
                                        id: info.contratoId
                                    },
                                    fechaini: info.Fecini,
                                    fechafin: fechaFinalContratoRef.current ? (fechaFinalContratoRef.current.value !== '') ? inputDateToLong(fechaFinalContratoRef.current.value) : null : null
                                });
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(`Contrato ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => { errorTable(`Contrato ${registro_no_guardado}`); console.log(error) });
                }
            } else {
                //agregar
                let info = { empId: elementSelect.id, contratoId: parseInt(contratoRef.current.value), Fecini: elementSelect.empantFecini };
                if(fechaFinalContratoRef.current.value !== '') info = { ...info, Fecfin: inputDateToLong(fechaFinalContratoRef.current.value) };
                axios.post(`${servicios_grupogack_rh}/empleado/contrato/`, info, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setInfoContrato({
                                contrato: {
                                    id: info.contratoId
                                },
                                fechaini: info.Fecini,
                                fechafin: (fechaFinalContratoRef.current.value !== '') ? inputDateToLong(fechaInicialContratoRef.current.value) : null
                            });
                            successTable(registro_actualizado);
                            break;
                        default:
                            errorTable(`Contrato ${registro_no_guardado}`);
                            break;
                    }
                })
                .catch(error => errorTable(`Contrato ${registro_no_guardado}`));
            }

            if(infoAguinaldo.fechaAguinaldo !== (fechaAguinaldoRef.current.value === '' ? '' : inputDateToLong(fechaAguinaldoRef.current.value))) {
                if(fechaAguinaldoRef.current.value === '') {
                    axios.delete(`${servicios_grupogack_rh}/empleado/aguinaldo/${infoAguinaldo.id}`, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const fechaAguinaldo = {
                                    id: '',
                                    fechaAguinaldo: ''
                                };
                                setInfoAguinaldo(fechaAguinaldo);
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`));
                } else {
                    if(infoAguinaldo.id === '') {
                        const info = {
                            empId: elementSelect.id,
                            empFecAgui: inputDateToLong(fechaAguinaldoRef.current.value)
                        };
                        axios.post(`${servicios_grupogack_rh}/empleado/aguinaldo/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const fechaAguinaldo = {
                                        id: res.data.dataResponse.id,
                                        fechaAguinaldo: res.data.dataResponse.empFecAgui
                                    };
                                    setInfoAguinaldo(fechaAguinaldo);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`));
                    } else {
                        const info = {
                            empId: elementSelect.id,
                            empFecAgui: inputDateToLong(fechaAguinaldoRef.current.value)
                        };
                        axios.put(`${servicios_grupogack_rh}/empleado/aguinaldo/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const fechaAguinaldo = {
                                        id: res.data.dataResponse.id,
                                        fechaAguinaldo: res.data.dataResponse.empFecAgui
                                    };
                                    setInfoAguinaldo(fechaAguinaldo);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => { errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`); console.log(error) });
                    }
                }
            }
            
            if(infoNombramiento.fechaNombramiento !== (fechaNombramientoRef.current.value === '' ? '' : inputDateToLong(fechaNombramientoRef.current.value))) {
                if(fechaNombramientoRef.current.value === '') {
                    axios.delete(`${servicios_grupogack_rh}/empleado/nombramiento/${infoNombramiento.id}`, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                const fechaNombramiento = {
                                    id: '',
                                    fechaNombramiento: ''
                                };
                                setInfoNombramiento(fechaNombramiento);
                                successTable(registro_actualizado);
                                break;
                            default:
                                errorTable(`Fecha de Nombramiento ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Fecha de Nombramiento ${registro_no_guardado}`));
                } else {
                    if(infoNombramiento.id === '') {
                        const info = {
                            empId: elementSelect.id,
                            empFecNom: inputDateToLong(fechaNombramientoRef.current.value)
                        };
                        axios.post(`${servicios_grupogack_rh}/empleado/nombramiento/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const fechaNombramiento = {
                                        id: res.data.dataResponse.id,
                                        fechaNombramiento: res.data.dataResponse.empFecAgui
                                    };
                                    setInfoNombramiento(fechaNombramiento);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Fecha de Nombramiento ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Fecha de Nombramiento ${registro_no_guardado}`));
                    } else {
                        const info = {
                            empId: elementSelect.id,
                            empFecNom: inputDateToLong(fechaNombramientoRef.current.value)
                        };
                        axios.put(`${servicios_grupogack_rh}/empleado/nombramiento/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const fechaNombramiento = {
                                        id: res.data.dataResponse.id,
                                        fechaNombramiento: res.data.dataResponse.empFecAgui
                                    };
                                    setInfoNombramiento(fechaNombramiento);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Fecha de Nombramiento ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => { errorTable(`Fecha de Nombramiento ${registro_no_guardado}`); console.log(error) });
                    }
                }
            }
//Registro patronal
            if(infoRegPatronal.regPatronal.id !== (regPatronalRef.current.value === '' ? '' : parseInt(regPatronalRef.current.value))) {
                if(regPatronalRef.current.value === '') {
                        axios.delete(`${servicios_grupogack_rh}/empleado/registropat/${infoRegPatronal.id}`, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const regPatronal = {
                                        id: '',
                                        regPatronal: { id: '' }
                                    };
                                    setInfoRegPatronal(regPatronal);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Registro Patronal ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Registro Patronal ${registro_no_guardado}`));
                } else {
                    if(infoRegPatronal.id === '') {
                        const requestRegPatronal = { 
                            empId: elementSelect.id,
                            regPatId: parseInt(regPatronalRef.current.value)
                        };
                        axios.post(`${servicios_grupogack_rh}/empleado/registropat/`, requestRegPatronal, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const regPatronal = {
                                        id: res.data.dataResponse.id,
                                        regPatronal: {
                                            id: res.data.dataResponse.regPat.id,
                                            regPatronal: res.data.dataResponse.regPat.registroPatClave
                                        }
                                    };
                                    setInfoRegPatronal(regPatronal);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Registro Patronal ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Registro Patronal ${registro_no_guardado}`));
                    } else {
                        const requestPatronal = {
                            empRegPatId: infoRegPatronal.id,
                            regPatId: parseInt(regPatronalRef.current.value)
                        };
                        axios.put(`${servicios_grupogack_rh}/empleado/registropat/`, requestPatronal, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const regPatronal = {
                                        id: res.data.dataResponse.id,
                                        regPatronal: {
                                            id: res.data.dataResponse.regPat.id,
                                            regPatronal: res.data.dataResponse.regPat.registroPatClave,
                                        }
                                    };
                                    setInfoRegPatronal(regPatronal);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Registro Patronal ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Registro Patronal ${registro_no_guardado}`));
                    }
                }
            }
//sindicatos
            if(
                (infoSindicato.sindicato.id !== sindicatoSeleccionado.id) ||
                (infoSindicato.aplica !== cuotaSindical)
            ) {
                if(sindicatoSeleccionado.id === '') {
                        axios.delete(`${servicios_grupogack_rh}/empleado/sindicatos/${infoSindicato.id}`, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const sindicato = {
                                        id: '',
                                        sindicato: { id: '' },
                                        aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                                    };
                                    setInfoSindicato(sindicato);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Sindicato ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Sindicato ${registro_no_guardado}`));
                } else {
                    if(infoSindicato.id === '') {
                        const requestSindicato = { 
                            empId: elementSelect.id,
                            sindId: sindicatoSeleccionado.id,
                            aplica: (cuotaSindical === 'si') ? true : false
                        };
                        axios.post(`${servicios_grupogack_rh}/empleado/sindicatos/`, requestSindicato, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const sindicato = {
                                        id: res.data.dataResponse.id,
                                        sindicato: res.data.dataResponse.sindicatoId,
                                        aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                                    };
                                    setInfoSindicato(sindicato);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Sindicato ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Sindicato ${registro_no_guardado}`));
                    } else {
                        const requestSindicato = { empSindId: infoSindicato.id, sindId: parseInt(sindicatoSeleccionado.id), aplica: cuotaSindical === 'si' ? true : false };
                        axios.put(`${servicios_grupogack_rh}/empleado/sindicatos/`, requestSindicato, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    const sindicato = {
                                        id: res.data.dataResponse.id,
                                        sindicato: res.data.dataResponse.sindicatoId,
                                        aplica: (res.data.dataResponse.aplica === true) ? 'si' : 'no'
                                    };
                                    setInfoSindicato(sindicato);
                                    successTable(registro_actualizado);
                                    break;
                                default:
                                    errorTable(`Sindicato ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Sindicato ${registro_no_guardado}`));
                    }
                }
            }
        }
    };

    const actualizarEmpleadoDomicilio = (info, infoAdic) => {
        axios.put(`${servicios_grupogack_rh}/empleado/domicilio/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setInfoDomicilio({
                        ...infoDomicilio,
                        empleado: {
                            id: info.empId
                        },
                        codPostal: {
                            codigopId: info.cpId,
                            codigopColonia: infoAdic.coloniaNom,
                            codigopCp: infoAdic.cpNom,
                            ciudadId: {
                                ciudadNom: infoAdic.ciudadNom,
                                estadoId: {
                                    estadoNom: infoAdic.estadoNom
                                }
                            }
                        },
                        calle: info.calle,
                        numExt: info.numext,
                        numInt: (info.numint) ? info.numint : ''
                    });
                    successTable(registro_actualizado);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
        setTimeout(() => {console.log(infoDomicilio)}, 1000)
    };

    const agregarEmpleadoDomicilio = (info, infoAdic) => {
        axios.post(`${servicios_grupogack_rh}/empleado/domicilio/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setInfoDomicilio({
                        empleado: {
                            id: info.empId
                        },
                        codPostal: {
                            codigopId: info.cpId,
                            codigopColonia: infoAdic.coloniaNom,
                            codigopCp: infoAdic.cpNom,
                            ciudadId: {
                                ciudadNom: infoAdic.ciudadNom,
                                estadoId: {
                                    estadoNom: infoAdic.estadoNom
                                }
                            }
                        },
                        calle: info.calle,
                        numExt: info.numext,
                        numInt: (info.numint) ? info.numint : ''
                    });
                    successTable(registro_actualizado);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                break;
                default:
                    errorTable(cambios_no_guardados);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    const actualizarEmpleadoDepEcon = (info, id) => {
        axios.put(`${servicios_grupogack_rh}/empleado/dependiente/economico/${id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = {
                        id: res.data.dataResponse.id,
                        nombre: res.data.dataResponse.nombre,
                        apellidoPat: res.data.dataResponse.apellidoPat,
                        apellidoMat: res.data.dataResponse.apellidoMat,
                        curp: res.data.dataResponse.curp,
                        fechaNac: res.data.dataResponse.fechaNac,
                        parentesco: res.data.dataResponse.parentesco.id,
                        parentescoNom: res.data.dataResponse.parentesco.parentescoNom,
                        escolaridad: res.data.dataResponse.escolaridad.id,
                        escolaridadNom: res.data.dataResponse.escolaridad.escolaridadNom,
                        gradoEscolar: res.data.dataResponse.gradoEscolar,
                        promedio: res.data.dataResponse.promedio,
                        depEconDiscapacidad: (res.data.dataResponse.discapacidad.id === 0) ? 'No' : 'Sí',
                        discapacidad: res.data.dataResponse.discapacidad.id,
                        nombreDisc: (res.data.dataResponse.discapacidad.id === 0) ? '' : res.data.dataResponse.discapacidad.nivel
                    };
                    const newDepEcon = depEconomicosSaved.filter(element => element.id && (element.id !== info.id));
                    setDepEconomicosSaved([ ...newDepEcon, info ]);
                    setElementoSeleccionadoDepEcon(null);
                    setMostrarFormAddDepEconEdit(false);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => { errorTable(cambios_no_guardados); console.log(error) });
    };

    const agregarEmpleadoDepEcon = () => {       
        axios.post(`${servicios_grupogack_rh}/empleado/dependiente/economico/`, depEconomicosSavedEdit, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = res.data.dataResponse.map(res => (
                        {
                            id: res.id,
                            nombre: res.nombre,
                            apellidoPat: res.apellidoPat,
                            apellidoMat: res.apellidoMat,
                            curp: res.curp,
                            fechaNac: res.fechaNac,
                            parentesco: res.parentesco.id,
                            parentescoNom: res.parentesco.parentescoNom,
                            escolaridad: res.escolaridad.id,
                            escolaridadNom: res.escolaridad.escolaridadNom,
                            gradoEscolar: res.gradoEscolar,
                            promedio: res.promedio,
                            depEconDiscapacidad: (res.discapacidad.id === 0) ? 'No' : 'Sí',
                            discapacidad: res.discapacidad.id,
                            nombreDisc: (res.discapacidad.id === 0) ? '' : res.discapacidad.nivel
                        }
                    ));
                    setDepEconomicosSaved(depEconomicosSaved.concat(info));
                    setDepEconomicosSavedEdit([]);
                    setElementoSeleccionadoDepEcon(null);
                    setMostrarFormAddDepEconEdit(false);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(`Dependientes Económicos ${registro_no_guardado}`);
                    break;
            }
        })
        .catch(error => errorTable(`Dependientes Económicos ${registro_no_guardado}`));
    };

    const actualizarEmpleadoPensionAlim = (info, id) => {
        axios.put(`${servicios_grupogack_rh}/empleado/pension/${id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info =
                        {
                            id: res.data.dataResponse.id,
                            folio: res.data.dataResponse.folio,
                            nombre: res.data.dataResponse.nombre,
                            apellidoPat: res.data.dataResponse.apellidoPat,
                            apellidoMat: res.data.dataResponse.apellidoMat,
                            tipoCuota: res.data.dataResponse.tipoCuota,
                            tipoCuotaNom: (res.data.dataResponse.tipoCuota === 'I') ? 'IMPORTE' : 'PORCENTAJE',
                            montoPorc: parseFloat(res.data.dataResponse.montoPorc),
                            fechaIni: res.data.dataResponse.fechaIni,
                            fechaFin: res.data.dataResponse.fechaFin
                        };
                    const newPenAlim = pensionesAlimSaved.filter(element => element.id && (element.id !== info.id));
                    setPensionesAlimSaved([...newPenAlim, info]);
                    setElementoSeleccionadoPensionAlim(null);
                    setPensionesAlimSavedEdit(false);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    const agregarEmpleadoPensionAlim = info => {
        const pensiones = info.map(penAlim => (
            (penAlim.fechaFin)
            ?   {
                    folio: penAlim.folio,
                    empleado: penAlim.empleadoId,
                    nombre: penAlim.nombre,
                    apellidoPat: penAlim.apellidoPat,
                    apellidoMat: penAlim.apellidoMat,
                    tipoCuota: penAlim.tipoCuota,
                    montoPorc: penAlim.montoPorc,
                    fechaIni: penAlim.fechaIni,
                    fechaFin: penAlim.fechaFin
                }
            :   {
                    folio: penAlim.folio,
                    empleado: penAlim.empleadoId,
                    nombre: penAlim.nombre,
                    apellidoPat: penAlim.apellidoPat,
                    apellidoMat: penAlim.apellidoMat,
                    tipoCuota: penAlim.tipoCuota,
                    montoPorc: penAlim.montoPorc,
                    fechaIni: penAlim.fechaIni,
                }
        ));

        axios.post(`${servicios_grupogack_rh}/empleado/pension/`, pensiones, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = res.data.dataResponse.map(res => (
                        {
                            id: res.id,
                            folio: res.folio,
                            nombre: res.nombre,
                            apellidoPat: res.apellidoPat,
                            apellidoMat: res.apellidoMat,
                            tipoCuota: res.tipoCuota,
                            tipoCuotaNom: (res.tipoCuota === 'I') ? 'IMPORTE' : 'PORCENTAJE',
                            montoPorc: parseFloat(res.montoPorc),
                            fechaIni: res.fechaIni,
                            fechaFin: res.fechaFin
                        }
                    ));
                    setPensionesAlimSaved(pensionesAlimSaved.concat(info));
                    setPensionesAlimSavedEdit([]);
                    setElementoSeleccionadoPensionAlim(null);
                    setMostrarFormAddPenAlimEdit(false);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(`Pensiones Alimenticias ${registro_no_guardado}`);
                    break;
            }
        })
        .catch(error => errorTable(`Pensiones Alimenticias ${registro_no_guardado}`));
    };

    const actualizarEmpleadoNumEmer = (info, id) => {
        axios.put(`${servicios_grupogack_rh}/empleado/llamada/emergencia/${id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info =
                        {
                            id: res.data.dataResponse.id,
                            nombre: res.data.dataResponse.nombre,
                            apellidoPat: res.data.dataResponse.apellidoPat,
                            apellidoMat: res.data.dataResponse.apellidoMat,
                            telefonoFijo: res.data.dataResponse.telefonoFijo,
                            telefonoCelular: res.data.dataResponse.telefonoCelular,
                            parentesco: res.data.dataResponse.parentesco.id,
                            parentescoNom: res.data.dataResponse.parentesco.parentescoNom,
                            calle: res.data.dataResponse.calle,
                            numeroInt: (res.data.dataResponse.numeroInt) ? res.data.dataResponse.numeroInt : '',
                            numeroExt: (res.data.dataResponse.numeroExt) ? res.data.dataResponse.numeroExt : '',
                            cp: res.data.dataResponse.cp.codigopCp,
                            numEmerColonia: res.data.dataResponse.cp.id,
                            coloniaNom: res.data.dataResponse.cp.codigopColonia,
                            coloniaId: res.data.dataResponse.cp.id,
                            numEmerCiudad: res.data.dataResponse.cp.ciudadId.ciudadNom,
                            numEmerEstado: res.data.dataResponse.cp.ciudadId.estadoId.estadoNom
                        };
                    const newNumEmer = llamadasEmergenciaSaved.filter(element => element.id && (element.id !== info.id));
                    setLlamadasEmergenciaSaved([...newNumEmer, info]);
                    setElementoSeleccionadoNumEmer(null);
                    setLlamadasEmergenciaSavedEdit([]);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    const agregarEmpleadoNumEmer = info => {
        const numEmergencia = info.map(numEme => (
            (numEme.calle & numEme.numeroExt & numEme.numeroInt)
            ?   {
                    empleado: numEme.empleadoId,
                    nombre: numEme.nombre,
                    apellidoPat: numEme.apellidoPat,
                    apellidoMat: numEme.apellidoMat,
                    telefonoFijo: numEme.telefonoFijo,
                    telefonoCelular: numEme.telefonoCelular,
                    parentesco: numEme.parentesco,
                    cp: numEme.coloniaId,
                    calle: numEme.calle,
                    numeroExt: numEme.numeroExt,
                    numeroInt: numEme.numeroInt
                }
            :   (numEme.calle & numEme.numeroExt)
                ?   {
                        empleado: numEme.empleadoId,
                        nombre: numEme.nombre,
                        apellidoPat: numEme.apellidoPat,
                        apellidoMat: numEme.apellidoMat,
                        telefonoFijo: numEme.telefonoFijo,
                        telefonoCelular: numEme.telefonoCelular,
                        parentesco: numEme.parentesco,
                        cp: numEme.coloniaId,
                        calle: numEme.calle,
                        numeroExt: numEme.numeroExt
                    }
                :   (numEme.calle)
                    ?   {
                            empleado: numEme.empleadoId,
                            nombre: numEme.nombre,
                            apellidoPat: numEme.apellidoPat,
                            apellidoMat: numEme.apellidoMat,
                            telefonoFijo: numEme.telefonoFijo,
                            telefonoCelular: numEme.telefonoCelular,
                            parentesco: numEme.parentesco,
                            cp: numEme.coloniaId,
                            calle: numEme.calle
                        }
                    :   {
                            empleado: numEme.empleadoId,
                            nombre: numEme.nombre,
                            apellidoPat: numEme.apellidoPat,
                            apellidoMat: numEme.apellidoMat,
                            telefonoFijo: numEme.telefonoFijo,
                            telefonoCelular: numEme.telefonoCelular,
                            parentesco: numEme.parentesco,
                            cp: numEme.coloniaId
                        }
        ));

        axios.post( `${servicios_grupogack_rh}/empleado/llamada/emergencia/`, numEmergencia, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const info = res.data.dataResponse.map(res => (
                        {
                            id: res.id,
                            nombre: res.nombre,
                            apellidoPat: res.apellidoPat,
                            apellidoMat: res.apellidoMat,
                            telefonoFijo: res.telefonoFijo,
                            telefonoCelular: res.telefonoCelular,
                            parentesco: res.parentesco.id,
                            parentescoNom: res.parentesco.parentescoNom,
                            calle: res.calle,
                            numeroInt: (res.numeroInt) ? res.numeroInt : '',
                            numeroExt: (res.numeroExt) ? res.numeroExt : '',
                            cp: res.cp.codigopCp,
                            numEmerColonia: res.cp.id,
                            coloniaNom: res.cp.codigopColonia,
                            coloniaId: res.cp.id,
                            numEmerCiudad: res.cp.ciudadId.ciudadNom,
                            numEmerEstado: res.cp.ciudadId.estadoId.estadoNom
                        }
                    ));
                    setLlamadasEmergenciaSaved(llamadasEmergenciaSaved.concat(info));
                    setLlamadasEmergenciaSavedEdit([]);
                    setElementoSeleccionadoNumEmer(null);
                    setMostrarFormAddNumEmerEdit(false);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(`Números de Emergencia ${registro_no_guardado}`);
                    break;
            }
        })
        .catch(error => errorTable(`Números de Emergencia ${registro_no_guardado}`));
    };
    
    const actualizarEmpleadoExpLab = info => {
        axios.put(`${servicios_grupogack_rh}/empleado/experiencia/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    const newExpLab = expLaboralSaved.filter(element => element.id && (element.id !== info.id));
                    setExpLaboralSaved([ ...newExpLab, info ]);
                    setElementoSeleccionadoExpLaboral(null);
                    setMostrarFormAddExpLaboralEdit(false);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => { errorTable(cambios_no_guardados); console.log(error) });
    };

    const agregarEmpleadoExpLab = info => {
        const agregaIDEmpleado = expLab => {
            const arrayExpLaboral = expLab.map(expL => (
                    {
                        empId: elementSelect.id,
                        empNom: expL.empNom,
                        puestoExp: expL.puestoExp,
                        dmes: expL.dmes,
                        danio: expL.danio,
                        hmes: expL.hmes,
                        hanio: expL.hanio,
                        jefe: expL.jefe,
                        tel: expL.tel
                    }                        
                ));
            
            return arrayExpLaboral;
        };

        const request = agregaIDEmpleado(info);
        axios.post(`${servicios_grupogack_rh}/empleado/experiencia/`, { empleados: request }, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    setExpLaboralSaved(expLaboralSaved.concat(info));
                    setExpLaboralSavedEdit([]);
                    setElementoSeleccionadoExpLaboral(null);
                    setMostrarFormAddExpLaboralEdit(false);
                    break;
                default:
                    errorTable(`Experiencia Laboral ${registro_no_guardado}`);
                    break;
            }
        })
        .catch(error => errorTable(`Experiencia Laboral ${registro_no_guardado}`));
    };

    const actualizarEmpleadoPasatiempo = (info, array) => {
        axios.put(`${servicios_grupogack_rh}/empleado/pasatiempo/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    setPasatiempoSaved(array);
                    setElementoSeleccionadoPasatiempo(null);
                    setMostrarFormAddPasatiempoEdit(false);
                    break;
                case 400:
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => { errorTable(cambios_no_guardados); console.log(error) });
    };

    const agregarEmpleadoPasatiempo = info => {
        const convierteArray = pasatiempos => {
            return pasatiempos.map(pasa => pasa.id); 
        };

        const arrayPasatiempos = convierteArray(info);

        const request = { empId: elementSelect.id, pasatiempos: arrayPasatiempos };
        axios.post(`${servicios_grupogack_rh}/empleado/pasatiempo/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    successTable(registro_actualizado);
                    setPasatiempoSaved(pasatiempoSaved.concat(info));
                    setPasatiempoSavedEdit([]);
                    setElementoSeleccionadoPasatiempo(null);
                    setMostrarFormAddPasatiempoEdit(false);
                    break;
                default:
                    errorTable(`Pasatiempos ${registro_no_guardado}`);
                    break;
            }
        })
        .catch(error => errorTable(`Pasatiempos ${registro_no_guardado}`));
    };

    const actualizar = () => {
        setError(null);
        let infoEmp = { };
            
        if(numEmpleadoRef.current.value !== infoEmpleado.empleado.empleadoclave ||
            nombreRef.current.value !== infoEmpleado.empleado.empleadonom ||
            pApellidoRef.current.value !== (infoEmpleado.empleado.empleadopat ? infoEmpleado.empleado.empleadopat :'') ||
            sApellidoRef.current.value !== (infoEmpleado.empleado.empleadomat ? infoEmpleado.empleado.empleadomat : '') ||
            inputDateToLong(fechaNacimientoRef.current.value) !== infoEmpleado.empleado.empleadofecnac ||
            parseInt(lNacimientoRef.current.value) !== infoEmpleado.empleado.estado.id ||
            generoRef.current.value !== (infoEmpleado.empleado.empleadoGenero ? infoEmpleado.empleado.empleadoGenero : '') ||
            parseInt(estadoCivilRef.current.value) !== infoEmpleado.empleado.edoCivil.id ||
            rfcRef.current.value !== (infoEmpleado.empleado.empleadorfc ? infoEmpleado.empleado.empleadorfc : '') ||
            curpRef.current.value !== (infoEmpleado.empleado.empleadocurp ? infoEmpleado.empleado.empleadocurp : '') ||
            nssRef.current.value !== (infoEmpleado.empleado.empleadonss ? infoEmpleado.empleado.empleadonss : '') ||
            inputDateToLong(fechaIngresoRef.current.value) !== infoEmpleado.antiguedad.empantfecini
        ) {
            infoEmp = {
                id: elementSelect.id,
                empleadoclave: numEmpleadoRef.current.value,
                empleadonom: nombreRef.current.value,
                empleadofecnac: inputDateToLong(fechaNacimientoRef.current.value),
                edoCivil: parseInt(estadoCivilRef.current.value),
                estado: parseInt(lNacimientoRef.current.value)
            };

            if(pApellidoRef.current.value !== '') infoEmp = { ...infoEmp, empleadopat: pApellidoRef.current.value };
            if(sApellidoRef.current.value !== '') infoEmp = { ...infoEmp, empleadomat: sApellidoRef.current.value };
            if(curpRef.current.value !== '') infoEmp = { ...infoEmp, empleadocurp: curpRef.current.value };
            if(rfcRef.current.value !== '') infoEmp = { ...infoEmp, empleadorfc: rfcRef.current.value };
            if(nssRef.current.value !== '') infoEmp = { ...infoEmp, empleadonss: nssRef.current.value };
            if(generoRef.current.value !== '') infoEmp = { ...infoEmp, empleadoGenero: generoRef.current.value };

        } else { 
            infoEmp = null;
        }

        let infoEsc = { };
            
        if(
            parseInt(nivelEscolaridadRef.current.value) !== infoEscolaridad.escolaridad.id ||
            carreraRef.current.value !== (infoEscolaridad.empleadoCarrera ? infoEscolaridad.empleadoCarrera : '') ||
            cedProfesionalRef.current.value !== (infoEscolaridad.empleadoCedula ? infoEscolaridad.empleadoCedula : '')
        ) {
            infoEsc = {
                escolaridad: parseInt(nivelEscolaridadRef.current.value),
                empleado: elementSelect.id
            };

            if(carreraRef.current.value !== '') infoEsc = { ...infoEsc, empleadoCarrera: carreraRef.current.value };
            if(cedProfesionalRef.current.value !== '') infoEsc = { ...infoEsc, empleadoCedula: cedProfesionalRef.current.value };

        } else {
            infoEsc = null;
        }            
           
        const idEscolaridad = infoEscolaridad.id;

        if(infoEmp !== null && infoEsc !== null && fotografiaEdit !== null) {
            actualizarEmpleado(infoEmp);
            actualizarEscolaridad(infoEsc, idEscolaridad);
            actualizarFotografia();
        } else if(infoEmp !== null && infoEsc !== null && fotografiaEdit === null) {
            actualizarEmpleado(infoEmp);
            actualizarEscolaridad(infoEsc, idEscolaridad);
        } else if(infoEmp === null && infoEsc !== null && fotografiaEdit !== null) {
            actualizarEscolaridad(infoEsc, idEscolaridad);
            actualizarFotografia();
        } else if(infoEmp === null && infoEsc !== null && fotografiaEdit === null) {
            actualizarEscolaridad(infoEsc, idEscolaridad);
        } else if(infoEmp !== null && infoEsc === null && fotografiaEdit !== null) {
            actualizarEmpleado(infoEmp);
            actualizarFotografia();
        } else if(infoEmp !== null && infoEsc === null && fotografiaEdit === null) {
            actualizarEmpleado(infoEmp);
        } else if(infoEmp === null && infoEsc === null && fotografiaEdit !== null) {
            actualizarFotografia();
        }
    };

    const actualizarDomicilio = (type) => {
        if(elementSelect) {
            setError(null);
    
            const empId = elementSelect.id; 
            const calle = calleRef.current.value;
            const numext = numExteriorRef.current.value;
            const numint = (numInteriorRef.current) ? numInteriorRef.current.value : null;
            const cpId = parseInt(coloniaRef.current.value);
            const estadoNom = estadoRef.current.value;
            const ciudadNom = ciudadRef.current.value;
            const cpNom = cpRef.current.value;
            const coloniaNom = coloniaRef.current.value;
    
            let info = {
                empId, calle, numext, cpId
            };
    
            let infoAdic = {
                estadoNom, ciudadNom, cpNom, coloniaNom
            };
    
            if(numint !== '' && numint !== null) info = { ...info, numint };
    
            if(type === 'Agregar') {
                if(validarSubmit('FormEmpleadoDomicilio')) {
                    agregarEmpleadoDomicilio(info, infoAdic);
                }
                return;
            } 
    
            if(type === 'Editar') {
                const id = infoDomicilio.id;
                info = { ...info, id};
    
                actualizarEmpleadoDomicilio(info, infoAdic);
                return;
            }
        }
    };

    const actualizarDepEcon = () => {
        if(validarSubmit("FormDependientesEconomicos")) {
            setError(null);
            const empleado = elementSelect.id; 
            const nombre = nombreDepEconomicoRef.current.value;
            const apellidoPat = pApellidoDepEconomicoRef.current.value;
            const apellidoMat = (sApellidoDepEconomicoRef.current) ? sApellidoDepEconomicoRef.current.value : null;
            const curp = (curpDepEconomicoRef.current) ? curpDepEconomicoRef.current.value : null;
            const fechaNac = inputDateToLong(fechaNacDepEconomicoRef.current.value);
            const parentesco = parseInt(parentescoDepEconommicoRef.current.value);
            const escolaridad = parseInt(escolaridadDepEconomicoRef.current.value);
            const gradoEscolar = gradoEstudiosDepEconomicoRef.current ? parseInt(gradoEstudiosDepEconomicoRef.current.value) : null;
            const promedio = (promedioDepEconomicoRef.current) ? parseFloat(promedioDepEconomicoRef.current.value) : null;
            const discapacidad = (nivelDiscapacidadRef.current) ? parseInt(nivelDiscapacidadRef.current.value) : 0;
            const nombreDisc = (nombreDiscapacidadRef.current) ? nombreDiscapacidadRef.current.value : null;

            let info = {
                empleado, nombre, apellidoPat, fechaNac, parentesco, escolaridad, discapacidad
            };

            if(apellidoMat !== null) info = { ...info, apellidoMat };
            if(curp !== null) info = { ...info, curp };
            if(gradoEscolar !== null) info = { ...info, gradoEscolar };
            if(promedio !== null) info = { ...info, promedio };
            if(discapacidad !== 0) info = { ...info, nombreDisc };

            const id = elementoSeleccionadoDepEcon.id;
            
            actualizarEmpleadoDepEcon(info, id);
        }
    };

    const agregarDepEconEdit = () => {
        if(depEconomicosSavedEdit.length) {
            agregarEmpleadoDepEcon();
        } else {
            setError(<AlertForm message="No has agregado algún Dependiente Económico para guardar"/>);
        }
    };

    const actualizarPensionAlim = () => {
        if(validarSubmit("FormPensionesAlimenticias")) {
            setError(null);
            const empleado = elementSelect.id;
            const folio = folioPensionAlimRef.current.value;
            const nombre = nombrePensionAlimRef.current.value;
            const apellidoPat = pApellidoPensionAlimRef.current.value;
            const apellidoMat = (sApellidoPensionAlimRef.current) ? sApellidoPensionAlimRef.current.value : null;
            const tipoCuota = tipoCuotaPensionAlimRef.current.value;
            const montoPorc = parseFloat(montoPensionAlimRef.current.value);
            const fechaIni = inputDateToLong(fechaInicioPensionAlimRef.current.value);
            const fechaFin = (fechaFinalPensionAlimRef.current) ? inputDateToLong(fechaFinalPensionAlimRef.current.value) : null;

            let info = {
                empleado, folio, nombre, apellidoPat, tipoCuota, montoPorc, fechaIni
            };

            if(apellidoMat !== null) info = { ...info, apellidoMat };
            if(fechaFin !== null) info = { ...info, fechaFin };

            const id = elementoSeleccionadoPensionAlim.id;

            actualizarEmpleadoPensionAlim(info, id)
        }
    };

    const agregarPensionAlimEdit = () => {
        if(pensionesAlimSavedEdit.length) {
            agregarEmpleadoPensionAlim(pensionesAlimSavedEdit);
        } else {
            setError(<AlertForm message="No has agregado alguna Pensión Alimenticia para guardar"/>);
        }
    };

    const actualizarNumEmer = () => {
        if(validarSubmit('FormNumerosEmergencia')) {
            setError(null);
            const empleado = elementSelect.id;
            const nombre = nombreNumEmergenciaRef.current.value;
            const apellidoPat = pApellidoNumEmergenciaRef.current.value;
            const apellidoMat = (sApellidoNumEmergenciaRef.current) ? sApellidoNumEmergenciaRef.current.value : null;
            const telefonoFijo = (telFijoNumEmergenciaRef.current.value) ? telFijoNumEmergenciaRef.current.value : null;
            const telefonoCelular = (telMovilNumEmergenciaRef.current.value) ? telMovilNumEmergenciaRef.current.value : null;
            const parentesco = parseInt(parentescoNumEmergenciaRef.current.value);
            const cp = parseInt(coloniaNumEmergenciaRef.current.value);
            const calle = (calleNumEmergenciaRef.current) ? calleNumEmergenciaRef.current.value : null;
            const numeroExt = (numExteriorNumEmergenciaRef.current) ? numExteriorNumEmergenciaRef.current.value : null;
            const numeroInt = (numInteriorNumEmergenciaRef.current) ? numInteriorNumEmergenciaRef.current.value : null;

            let info = {
                empleado, nombre, apellidoPat, parentesco, cp
            };

            if(apellidoMat !== null) info = { ...info, apellidoMat };
            if(telefonoFijo !== null) info = { ...info, telefonoFijo };
            if(telefonoCelular !== null) info = { ...info, telefonoCelular };
            if(calle !== null) {
                if(numeroExt !== null && numeroInt !== null) {
                    info = { ...info, calle, numeroExt, numeroInt };
                } else if(numeroExt !== null) {
                    info = { ...info, calle, numeroExt };
                } else {
                    info = { ...info, calle };
                }
            }

            const id = elementoSeleccionadoNumEmer.id;

            actualizarEmpleadoNumEmer(info, id);
        }
    };

    const agregarNumEmerEdit = () => {
        if(llamadasEmergenciaSavedEdit.length) {
            agregarEmpleadoNumEmer(llamadasEmergenciaSavedEdit);
        } else {
            setError(<AlertForm message="No has agregado algún Contacto de Emergencia para guardar"/>)
        }
    };

    const actualizarExpLaboral = () => {
        if(validarSubmit("FormEmpleadoExpLaboral")) {
            setError(null);
            const id = elementoSeleccionadoExpLaboral.id;
            const empId = elementSelect.id
            const empNom = empresaExpLabRef.current.value;
            const puestoExp = puestoExpLabRef.current.value;
            const dmes = parseInt(desdeMesExpLabRef.current.value);
            const danio = parseInt(desdeAnioExpLabRef.current.value);
            const hmes = parseInt(hastaMesExpLabRef.current.value);
            const hanio = parseInt(hastaMesExpLabRef.current.value);
            const jefe = nombreJefeExpLabRef.current.value;
            const tel = telefonoExpLabRef.current.value;

            let info = { id, empId, empNom, puestoExp, dmes, danio, hmes, hanio, jefe, tel };

            actualizarEmpleadoExpLab(info);
        }
    };

    const agregarExpLaboralEdit = () => {
        if(expLaboralSavedEdit.length) {
            agregarEmpleadoExpLab(expLaboralSavedEdit);
        } else {
            setError(<AlertForm message="No has agregado alguna Experiencia Laboral para guardar"/>)
        }
    };

    const actualizarPasatiempo = (newElement, val) => {
        const convierteArray = pasatiempos => {
            return pasatiempos.map(pasa => pasa.id); 
        };

        if(val === 1) {
            if(validarSubmit("FormEmpleadoPasatiempos")) {
                setError(null);

                const posicion = pasatiempoSaved.indexOf(elementoSeleccionadoPasatiempo);
                let newPasatiempos = pasatiempoSaved;
                newPasatiempos.splice(posicion, 1, newElement);
                const arrayPasatiempos = convierteArray(newPasatiempos);
                const empId = elementSelect.id;

                const info = { empId, pasatiempos: arrayPasatiempos };
                
                actualizarEmpleadoPasatiempo(info, newPasatiempos);
            }
        } else {
            setError(null);

            const arrayPasatiempos = convierteArray(newElement);
            const empId = elementSelect.id;

            const info = { empId, pasatiempos: arrayPasatiempos };
            
            actualizarEmpleadoPasatiempo(info, newElement);
        }
    };

    const agregarPasatiempoEdit = () => {
        if(pasatiempoSavedEdit.length) {
            agregarEmpleadoPasatiempo(pasatiempoSavedEdit);
        } else {
            setError(<AlertForm message="No has agregado algún Pasatiempo para guardar"/>)
        }
    };

    const irInfo = (e, pag) => {
        e.preventDefault();
        switch(active) {
            case 2:
                if(formaPagoRef.current.value !== '') {
                    setOpenValue2(pag);
                    setOpenAlert2(true);
                    return;
                }
                if(elementSelect) {
                    const validaDisabled = () => {
                        if(!disabledFormOrgEdit.cPresupuestal ||
                            !disabledFormOrgEdit.departamento ||
                            !disabledFormOrgEdit.plaza ||
                            !disabledFormOrgEdit.zonaPago ||
                            !disabledFormOrgEdit.puesto ||
                            !disabledFormOrgEdit.turno ||
                            !disabledFormOrgEdit.nomina)
                        {
                            return false;
                        } else {
                            return true;
                        }
                    };

                    const validaCampos = () => {
                        if(
                            (infoContrato.contrato.id !== parseInt(contratoRef.current.value)) ||
                            (infoContrato.fechaini !== inputDateToLong(fechaInicialContratoRef.current.value)) ||
                            (infoContrato.fechafin !== ((fechaFinalContratoRef.current) ? (fechaFinalContratoRef.current.value !== '') ? inputDateToLong(fechaFinalContratoRef.current.value) : null : null)) ||
                            ((infoAguinaldo.fechaAguinaldo) !== (fechaNombramientoRef.current ? (fechaAguinaldoRef.current.value === '' ? '' : inputDateToLong(fechaAguinaldoRef.current.value)) : '')) ||
                            ((infoNombramiento.fechaNombramiento) !== (fechaNombramientoRef.current ? (fechaNombramientoRef.current.value === '' ? '' : inputDateToLong(fechaNombramientoRef.current.value)) : '')) ||
                            (infoRegPatronal.regPatronal.id !== (regPatronalRef.current ? (regPatronalRef.current.value === '' ? '' : parseInt(regPatronalRef.current.value)) : '')) ||
                            (infoSindicato.sindicato.id !== sindicatoSeleccionado.id) ||
                            (infoSindicato.aplica !== cuotaSindical)
                        ) {
                            return false;
                        } else {
                            return true;
                        }
                    };

                    if(formasPagoSelected.length > 0) {
                        if(validaDisabled() && validaCampos()) {
                            setActive(pag);
                        } else {
                            setOpenValue2(pag);
                            setOpenAlert2(true);
                        }
                    } else {
                        setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                    }
                } else {
                    if(validarSubmit('OrganizacionEmpleado')) {
                        if(selected.cPresupuestal.id !== '' && selected.departamento.id !== '' && selected.plaza.id !== '' && selected.puesto.id !== '') {
                            if(formasPagoSelected.length > 0) {
                                setActive(pag);
                            } else {
                                setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                            }
                        } else {
                            validaAutocompleteOrganizacion();
                            setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                        }
                    } else {
                        validaAutocompleteOrganizacion();
                        setErrorFormPago({ active: true, message: 'No ha guardado alguna forma de pago' });
                    }
                }
                break;
            case 3:
                if(elementSelect) {
                    if(calleRef.current.value !== ((infoDomicilio.calle) ? infoDomicilio.calle : '') ||
                        numExteriorRef.current.value !== ((infoDomicilio.numExt) ? infoDomicilio.numExt : '') ||
                        numInteriorRef.current.value !== ((infoDomicilio.numInt) ? infoDomicilio.numInt : '' ) ||
                        cpRef.current.value !== ((infoDomicilio.codPostal) ? infoDomicilio.codPostal.codigopCp : '') ||
                        (isNaN(parseInt(coloniaRef.current.value)) ? '' : parseInt(coloniaRef.current.value)) !== ((infoDomicilio.codPostal) ? infoDomicilio.codPostal.codigopId : ''))
                    {
                        setOpenValue3(pag);
                        setOpenAlert3(true);
                    } else {
                        setActive(pag);
                    }
                } else {
                    if(calleRef.current.value !== '' ||
                        numExteriorRef.current.value !== '' ||
                        numInteriorRef.current.value !== '' ||
                        cpRef.current.value !== '' ||
                        coloniaRef.current.value !== '')
                    {
                        if(validarSubmit("FormEmpleadoDomicilio")) {
                            setActive(pag);
                        }
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 4:
                if(elementSelect) {
                    if(depEconomicosSaved.length > 0) {
                        if(elementoSeleccionadoDepEcon) {
                            if(nombreDepEconomicoRef.current.value !== elementoSeleccionadoDepEcon.nombre ||
                                pApellidoDepEconomicoRef.current.value !== (elementoSeleccionadoDepEcon.apellidoPat ? elementoSeleccionadoDepEcon.apellidoPat : '') ||
                                sApellidoDepEconomicoRef.current.value !== (elementoSeleccionadoDepEcon.apellidoMat ? elementoSeleccionadoDepEcon.apellidoMat : '') ||
                                curpDepEconomicoRef.current.value !== elementoSeleccionadoDepEcon.curp ||
                                inputDateToLong(fechaNacDepEconomicoRef.current.value) !== elementoSeleccionadoDepEcon.fechaNac ||
                                parseInt(parentescoDepEconommicoRef.current.value) !== elementoSeleccionadoDepEcon.parentesco ||
                                parseInt(escolaridadDepEconomicoRef.current.value) !== elementoSeleccionadoDepEcon.escolaridad ||
                                parseInt(gradoEstudiosDepEconomicoRef.current.value) !== elementoSeleccionadoDepEcon.gradoEscolar ||
                                parseFloat(promedioDepEconomicoRef.current.value) !== parseFloat(elementoSeleccionadoDepEcon.promedio) ||
                                ((nivelDiscapacidadRef.current) ? parseInt(nivelDiscapacidadRef.current.value) : 0) !== elementoSeleccionadoDepEcon.discapacidad ||
                                ((nombreDiscapacidadRef.current) ? parseInt(nombreDiscapacidadRef.current.value) : '') !== elementoSeleccionadoDepEcon.nombreDisc)
                            {
                                setOpenValue4(pag);
                                setOpenAlert4(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                            
                            if(depEconomicosSavedEdit.length > 0) {
                                setOpenValue5(pag);
                                setOpenAlert5(true);
                            } else {
                                setActive(pag);
                            }
                        }
                    } else {
                        if(nombreDepEconomicoRef.current.value !== '' ||
                            pApellidoDepEconomicoRef.current.value !== '' ||
                            sApellidoDepEconomicoRef.current.value !== '' ||
                            curpDepEconomicoRef.current.value !== '' ||
                            fechaNacDepEconomicoRef.current.value !== '' ||
                            parentescoDepEconommicoRef.current.value !== '' ||
                            escolaridadDepEconomicoRef.current.value !== '' ||
                            gradoEstudiosDepEconomicoRef.current.value !== '' ||
                            promedioDepEconomicoRef.current.value !== '' ||
                            mostrarDiscapacidad !== false)
                        {
                            setOpenValue4(pag);
                            setOpenAlert4(true);
                            return;
                        }

                        if(depEconomicosSavedEdit.length > 0) {
                            setOpenValue5(pag);
                            setOpenAlert5(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(nombreDepEconomicoRef.current.value !== '' ||
                        pApellidoDepEconomicoRef.current.value !== '' ||
                        sApellidoDepEconomicoRef.current.value !== '' ||
                        curpDepEconomicoRef.current.value !== '' ||
                        fechaNacDepEconomicoRef.current.value !== '' ||
                        parentescoDepEconommicoRef.current.value !== '' ||
                        escolaridadDepEconomicoRef.current.value !== '' ||
                        gradoEstudiosDepEconomicoRef.current.value !== '' ||
                        promedioDepEconomicoRef.current.value !== '')
                    {
                        setOpenValue4(pag);
                        setOpenAlert4(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 5:
                if(elementSelect) {
                    if(pensionesAlimSaved.length > 0) {
                        if(elementoSeleccionadoPensionAlim) {
                            if(folioPensionAlimRef.current.value !== elementoSeleccionadoPensionAlim.folio ||
                                nombrePensionAlimRef.current.value !== elementoSeleccionadoPensionAlim.nombre ||
                                pApellidoPensionAlimRef.current.value !== (elementoSeleccionadoPensionAlim.apellidoPat ? elementoSeleccionadoPensionAlim.apellidoPat : '') ||
                                sApellidoPensionAlimRef.current.value !== (elementoSeleccionadoPensionAlim.apellidoMat ? elementoSeleccionadoPensionAlim.apellidoMat : '') ||
                                parseInt(tipoCuotaPensionAlimRef.current.value) !== elementoSeleccionadoPensionAlim.tipoCuota ||
                                parseFloat(montoPensionAlimRef.current.value) !== parseFloat(elementoSeleccionadoPensionAlim.montoPorc) ||
                                inputDateToLong(fechaInicioPensionAlimRef.current.value) !== elementoSeleccionadoPensionAlim.fechaIni ||
                                inputDateToLong(fechaFinalPensionAlimRef.current.value) !== (elementoSeleccionadoPensionAlim.fechaFin ? elementoSeleccionadoPensionAlim.fechaFin : ''))
                            {
                                setOpenValue6(pag);
                                setOpenAlert6(true);
                            } else {
                                setActive(pag);
                            }
                        } else {
                            if(pensionesAlimSavedEdit.length > 0) {
                                setOpenValue7(pag);
                                setOpenAlert7(true);
                            } else {
                                setActive(pag);
                            }
                        }
                    } else {
                        if(folioPensionAlimRef.current.value !== '' ||
                            nombrePensionAlimRef.current.value !== '' ||
                            pApellidoPensionAlimRef.current.value !== '' ||
                            sApellidoPensionAlimRef.current.value !== '' ||
                            tipoCuotaPensionAlimRef.current.value !== '' ||
                            montoPensionAlimRef.current.value !== '' ||
                            fechaInicioPensionAlimRef.current.value !== '' ||
                            fechaFinalPensionAlimRef.current.value !== '')
                        {
                            setOpenValue6(pag);
                            setOpenAlert6(true);
                            return;
                        }

                        if(pensionesAlimSavedEdit.length > 0) {
                            setOpenValue7(pag);
                            setOpenAlert7(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(folioPensionAlimRef.current.value !== '' ||
                        nombrePensionAlimRef.current.value !== '' ||
                        pApellidoPensionAlimRef.current.value !== '' ||
                        sApellidoPensionAlimRef.current.value !== '' ||
                        tipoCuotaPensionAlimRef.current.value !== '' ||
                        montoPensionAlimRef.current.value !== '' ||
                        fechaInicioPensionAlimRef.current.value !== '' ||
                        fechaFinalPensionAlimRef.current.value !== '')
                    {
                        setOpenValue6(pag);
                        setOpenAlert6(true);
                    } else {
                        setActive(pag);
                    }
                }

                break;
            case 6:
                if(elementSelect) {
                    if(llamadasEmergenciaSaved.length > 0) {
                        if(elementoSeleccionadoNumEmer) {
                            if(nombreNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.nombre ||
                                pApellidoNumEmergenciaRef.current.value !== (elementoSeleccionadoNumEmer.apellidoPat ? elementoSeleccionadoNumEmer.apellidoPat : '') || 
                                sApellidoNumEmergenciaRef.current.value !== (elementoSeleccionadoNumEmer.apellidoMat ? elementoSeleccionadoNumEmer.apellidoMat : '' ) ||
                                telFijoNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.telefonoFijo ||
                                telMovilNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.telefonoCelular ||
                                parseInt(parentescoNumEmergenciaRef.current.value) !== elementoSeleccionadoNumEmer.parentesco ||
                                calleNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.calle ||
                                numExteriorNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.numeroExt ||
                                numInteriorNumEmergenciaRef.current.value !== (elementoSeleccionadoNumEmer.numeroInt ? elementoSeleccionadoNumEmer.numeroInt : '') ||
                                cpNumEmergenciaRef.current.value !== elementoSeleccionadoNumEmer.cp ||
                                parseInt(coloniaNumEmergenciaRef.current.value) !== elementoSeleccionadoNumEmer.coloniaId)
                            {
                                setOpenValue8(pag);
                                setOpenAlert8(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                          
                            if(llamadasEmergenciaSavedEdit.length > 0) {
                                setOpenValue9(pag);
                                setOpenAlert9(true);
                            } else {
                                setActive(pag);
                            }
                        }
                    } else {
                        if(nombreNumEmergenciaRef.current.value !== '' ||
                            pApellidoNumEmergenciaRef.current.value !== '' ||
                            sApellidoNumEmergenciaRef.current.value !== '' ||
                            telFijoNumEmergenciaRef.current.value !== '' ||
                            telMovilNumEmergenciaRef.current.value !== '' ||
                            parentescoNumEmergenciaRef.current.value !== '' ||
                            calleNumEmergenciaRef.current.value !== '' ||
                            numExteriorNumEmergenciaRef.current.value !== '' ||
                            numInteriorNumEmergenciaRef.current.value !== '' ||
                            cpNumEmergenciaRef.current.value !== '' ||
                            coloniaNumEmergenciaRef.current.value !== '')
                        {
                            setOpenValue8(pag);
                            setOpenAlert8(true);
                            return;
                        }

                        if(llamadasEmergenciaSavedEdit.length > 0) {
                            setOpenValue9(pag);
                            setOpenAlert9(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(nombreNumEmergenciaRef.current.value !== '' ||
                        pApellidoNumEmergenciaRef.current.value !== '' ||
                        sApellidoNumEmergenciaRef.current.value !== '' ||
                        telFijoNumEmergenciaRef.current.value !== '' ||
                        telMovilNumEmergenciaRef.current.value !== '' ||
                        parentescoNumEmergenciaRef.current.value !== '' ||
                        calleNumEmergenciaRef.current.value !== '' ||
                        numExteriorNumEmergenciaRef.current.value !== '' ||
                        numInteriorNumEmergenciaRef.current.value !== '' ||
                        cpNumEmergenciaRef.current.value !== '' ||
                        coloniaNumEmergenciaRef.current.value !== '')
                    {
                        setOpenValue8(pag);
                        setOpenAlert8(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 7:
                if(elementSelect) {                   
                    if(expLaboralSaved.length > 0) {
                        if(elementoSeleccionadoExpLaboral) {
                            if(empresaExpLabRef.current.value !== elementoSeleccionadoExpLaboral.empNom ||
                                puestoExpLabRef.current.value !== elementoSeleccionadoExpLaboral.puestoExp ||
                                parseInt(desdeMesExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.dmes ||
                                parseInt(desdeAnioExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.danio ||
                                parseInt(hastaMesExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.hmes ||
                                parseInt(hastaAnioExpLabRef.current.value) !== elementoSeleccionadoExpLaboral.hanio ||
                                nombreJefeExpLabRef.current.value !== elementoSeleccionadoExpLaboral.jefe ||
                                telefonoExpLabRef.current.value !== elementoSeleccionadoExpLaboral.tel)
                            {
                                setOpenValue10(pag);
                                setOpenAlert10(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                          
                            if(expLaboralSavedEdit.length > 0) {
                                setOpenValue11(pag);
                                setOpenAlert11(true);
                            } else {
                                setActive(pag);
                            }
                        }
                    } else {
                        if(empresaExpLabRef.current.value !== '' ||
                            puestoExpLabRef.current.value !== '' ||
                            desdeMesExpLabRef.current.value !== '' ||
                            desdeAnioExpLabRef.current.value !== '' ||
                            hastaMesExpLabRef.current.value !== '' ||
                            hastaAnioExpLabRef.current.value !== '' ||
                            nombreJefeExpLabRef.current.value !== '' ||
                            telefonoExpLabRef.current.value !== '')
                        {
                            setOpenValue10(pag);
                            setOpenAlert10(true);
                            return;
                        }

                        if(expLaboralSavedEdit.length > 0) {
                            setOpenValue11(pag);
                            setOpenAlert11(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(empresaExpLabRef.current.value !== '' ||
                        puestoExpLabRef.current.value !== '' ||
                        desdeMesExpLabRef.current.value !== '' ||
                        desdeAnioExpLabRef.current.value !== '' ||
                        hastaMesExpLabRef.current.value !== '' ||
                        hastaAnioExpLabRef.current.value !== '' ||
                        nombreJefeExpLabRef.current.value !== '' ||
                        telefonoExpLabRef.current.value !== '')
                    {
                        setOpenValue10(pag);
                        setOpenAlert10(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            case 8:
                if(elementSelect) {
                    if(pasatiempoSaved.length > 0) {
                        if(elementoSeleccionadoPasatiempo) {
                            if(pasatiempoRef.current.value !== elementoSeleccionadoPasatiempo.pasatiempo) {
                                setOpenValue12(pag);
                                setOpenAlert12(true);
                            } else {
                                setActive(pag);
                            }
                        } else {                          
                            if(pasatiempoSavedEdit.length > 0) {
                                setOpenValue13(pag);
                                setOpenAlert13(true);
                            } else {
                                setActive(pag);
                            }
                        }
                    } else {
                        if(pasatiempoRef.current.value !== '') {
                            setOpenValue12(pag);
                            setOpenAlert12(true);
                            return;
                        }

                        if(pasatiempoSavedEdit.length > 0) {
                            setOpenValue13(pag);
                            setOpenAlert13(true);
                            return;
                        }
                        
                        setActive(pag);
                    }
                } else {
                    if(pasatiempoRef.current.value !== '') {
                        setOpenValue12(pag);
                        setOpenAlert12(true);
                    } else {
                        setActive(pag);
                    }
                }
                break;
            default:
                break;
        }
    };

    const siguiente = (e, pag) => {
        e.preventDefault();
        if(validarSubmit('DatosPersonales')) { 
            setError(null);
            if(elementSelect) {
                const valida = (
                    ((numEmpleadoRef.current.value !== infoEmpleado.empleado.empleadoclave) === true) ||
                    ((nombreRef.current.value !== infoEmpleado.empleado.empleadonom) === true) ||
                    ((pApellidoRef.current.value !== ((infoEmpleado.empleado.empleadopat) ? infoEmpleado.empleado.empleadopat : '')) === true) ||
                    ((sApellidoRef.current.value !== ((infoEmpleado.empleado.empleadomat) ? infoEmpleado.empleado.empleadomat : '')) === true) ||
                    ((inputDateToLong(fechaNacimientoRef.current.value) !== infoEmpleado.empleado.empleadofecnac) === true) ||
                    ((parseInt(lNacimientoRef.current.value) !== infoEmpleado.empleado.estado.id) === true) ||
                    ((generoRef.current.value !== ((infoEmpleado.empleado.empleadoGenero) ? infoEmpleado.empleado.empleadoGenero : '')) === true) ||
                    ((parseInt(estadoCivilRef.current.value) !== infoEmpleado.empleado.edoCivil.id) === true) ||
                    ((rfcRef.current.value !== ((infoEmpleado.empleado.empleadorfc) ? infoEmpleado.empleado.empleadorfc : '')) === true) ||
                    ((curpRef.current.value !== ((infoEmpleado.empleado.empleadocurp) ? infoEmpleado.empleado.empleadocurp : '')) === true) ||
                    ((nssRef.current.value !== ((infoEmpleado.empleado.empleadonss) ? infoEmpleado.empleado.empleadonss : '')) === true) ||
                    ((inputDateToLong(fechaIngresoRef.current.value) !== infoEmpleado.antiguedad.empantfecini) === true) ||
                    ((isNaN(parseInt(nivelEscolaridadRef.current.value)) ? '' : parseInt(nivelEscolaridadRef.current.value) !== infoEscolaridad.escolaridad.id) === true) || 
                    ((carreraRef.current.value !== ((infoEscolaridad.empleadoCarrera) ? infoEscolaridad.empleadoCarrera : '')) === true) ||
                    ((cedProfesionalRef.current.value !== ((infoEscolaridad.empleadoCedula) ? infoEscolaridad.empleadoCedula : '')) === true) ||
                    (fotografiaEdit !== null)
                );

                if(valida === true) {
                    setOpenAlert(true);
                } else {
                    setActive(pag);
                }
            } else {
                    setActive(pag);
            }
        }
    };

    const siguienteEdit = () => {
        setTimeout(() => {
            setActive(2);
        }, 300);
    };

    return (
        <Fragment>
        {
        (!elementSelect || (infoEmpleado && infoEscolaridad))
        ?   <Fragment>
                <div id="form-part-1" style={{ display: (active === 1) ? 'block' : 'none' }}>
                    <FormDatosPersonales
                        elementSelect={elementSelect}
                        actualizar={actualizar}
                        verificaCambiosFormEmpleado={verificaCambiosFormEmpleado}
                        siguiente={siguiente}
                        nivelesEscolaridad={nivelesEscolaridad}
                        tiposEstadoCivil={tiposEstadoCivil}
                        numEmpleadoRef={numEmpleadoRef}
                        nombreRef={nombreRef}
                        pApellidoRef={pApellidoRef}
                        sApellidoRef={sApellidoRef}
                        fechaNacimientoRef={fechaNacimientoRef}
                        generoRef={generoRef}
                        nivelEscolaridadRef={nivelEscolaridadRef}
                        curpRef={curpRef}
                        carreraRef={carreraRef}
                        nssRef={nssRef}
                        estadosNacimiento={estadosNacimiento}
                        lNacimientoRef={lNacimientoRef}
                        estadoCivilRef={estadoCivilRef}
                        rfcRef={rfcRef}
                        cedProfesionalRef={cedProfesionalRef}
                        fechaIngresoRef={fechaIngresoRef}
                        infoEmpleado={infoEmpleado}
                        infoEscolaridad={infoEscolaridad}
                        setFotografia={setFotografia}
                        fotografia={fotografia}
                        fotografiaEdit={fotografiaEdit}
                        setFotografiaEdit={setFotografiaEdit}
                    />
                </div>
                <div id="form-part-2" style={{ display: (active === 2) ? 'block' : 'none' }}>
                    <FormOrganizacionEmpleado
                        active={active}
                        successTable={(successTable) ? successTable : null}
                        errorTable={(errorTable) ? errorTable : null}
                        irInfo={irInfo}
                        actualizar={actualizarOrganizacion}
                        enviar={enviar}
                        elementSelect={elementSelect}
                        selected={selected}
                        monto={monto}
                        setMonto={setMonto}
                        indicadores={indicadores}
                        enviarEmpleado={enviarEmpleado}
                        disabled={disabled}
                        setDisabled={setDisabled}
                        disabledFormOrgEdit={disabledFormOrgEdit}
                        setDisabledFormOrgEdit={setDisabledFormOrgEdit}
                        idTurno={(elementSelect) ? infoEmpleado.turno.id : null}
                        infoContrato={infoContrato}
                        fechaAguinaldo={infoAguinaldo}
                        fechaNombramiento={infoNombramiento}
                        cPresupuestales={cPresupuestales}
                        validarCPresupuestal={validarCPresupuestal}
                        departamentos={departamentos}
                        validarDepartamento={validarDepartamento}
                        plazas={plazas}
                        validarPlaza={validarPlaza}
                        validaAutocompleteOrganizacion={validaAutocompleteOrganizacion}
                        zonasPago={zonasPago}
                        zonaPagoRef={zonaPagoRef}
                        turnos={turnos}
                        turnoRef={turnoRef}
                        puestos={puestos}
                        validarPuesto={validarPuesto}
                        nominas={nominas}
                        nominaRef={nominaRef}
                        formaPagoRef={formaPagoRef}
                        formasPagoSelected={formasPagoSelected}
                        setFormasPagoSelected={setFormasPagoSelected}
                        setIndicadoresSelected={setIndicadoresSelected}
                        indicadoresSelected={indicadoresSelected}
                        bancoRef={bancoRef}
                        numCuentaRef={numCuentaRef}
                        cbeInterRef={cbeInterRef}
                        contratoRef={contratoRef}
                        fechaInicialContratoRef={fechaInicialContratoRef}
                        fechaFinalContratoRef={fechaFinalContratoRef}
                        fechaNombramientoRef={fechaNombramientoRef}
                        fechaAguinaldoRef={fechaAguinaldoRef}
                        regPatronalRef={regPatronalRef}
                        infoRegPatronal={infoRegPatronal}
                        sindicatoRef={sindicatoRef}
                        sindicalizado={sindicalizado}
                        setSindicalizado={setSindicalizado}
                        cuotaSindical={cuotaSindical}
                        setCuotaSindical={setCuotaSindical}
                        sindicatoSeleccionado={sindicatoSeleccionado}
                        setSindicatoSeleccionado={setSindicatoSeleccionado}
                        infoSindicato={infoSindicato}
                        errorFormPago={errorFormPago}
                        setErrorFormPago={setErrorFormPago}
                        errorCPresupuestal={errorCPresupuestal}
                        errorDepartamento={errorDepartamento}
                        errorPlaza={errorPlaza}
                        errorPuesto={errorPuesto}
                        setErrorCPresupuestal={setErrorCPresupuestal}
                        setErrorDepartamento={setErrorDepartamento}
                        setErrorPlaza={setErrorPlaza}
                        setErrorPuesto={setErrorPuesto}
                    />
                </div>
                <div id="form-part-3" style={{ display: (active === 3) ? 'block' : 'none' }}>
                    <FormDomicilio
                        elementSelect={elementSelect}
                        actualizarDomicilio={actualizarDomicilio}
                        enviar={enviar}
                        irInfo={irInfo}
                        calleRef={calleRef}
                        numExteriorRef={numExteriorRef}
                        numInteriorRef={numInteriorRef}
                        cpRef={cpRef}
                        coloniaRef={coloniaRef}
                        ciudadRef={ciudadRef}
                        estadoRef={estadoRef}
                        infoDomicilio={infoDomicilio}
                    />
                </div>
                <div id="form-part-4" style={{ display: (active === 4) ? 'block' : 'none' }}>
                    <FormDependientesEconomicos
                        elementSelect={elementSelect}
                        setError={setError}
                        actualizarDepEcon={actualizarDepEcon}
                        agregarDepEconEdit={agregarDepEconEdit}
                        enviar={enviar}
                        irInfo={irInfo}
                        nivelesEscolaridad={nivelesEscolaridad}
                        nombreDepEconomicoRef={nombreDepEconomicoRef}
                        pApellidoDepEconomicoRef={pApellidoDepEconomicoRef}
                        sApellidoDepEconomicoRef={sApellidoDepEconomicoRef}
                        curpDepEconomicoRef={curpDepEconomicoRef}
                        fechaNacDepEconomicoRef={fechaNacDepEconomicoRef}
                        tiposParentesco={tiposParentesco}
                        parentescoDepEconommicoRef={parentescoDepEconommicoRef}
                        escolaridadDepEconomicoRef={escolaridadDepEconomicoRef}
                        gradoEstudiosDepEconomicoRef={gradoEstudiosDepEconomicoRef}
                        promedioDepEconomicoRef={promedioDepEconomicoRef}
                        mostrarDiscapacidad={mostrarDiscapacidad}
                        setMostrarDiscapacidad={setMostrarDiscapacidad}
                        nivelDiscapacidadRef={nivelDiscapacidadRef}
                        nombreDiscapacidadRef={nombreDiscapacidadRef}
                        depEconomicosSaved={depEconomicosSaved}
                        setDepEconomicosSaved={setDepEconomicosSaved}
                        depEconomicosSavedEdit={depEconomicosSavedEdit}
                        setDepEconomicosSavedEdit={setDepEconomicosSavedEdit}
                        elementoSeleccionado={elementoSeleccionadoDepEcon}
                        setElementoSeleccionado={setElementoSeleccionadoDepEcon}
                        mostrarFormAddEdit={mostrarFormAddDepEconEdit}
                        setMostrarFormAddEdit={setMostrarFormAddDepEconEdit}
                        successTable={successTable}
                        errorTable={errorTable}
                    />
                </div>
                <div id="form-part-5" style={{ display: (active === 5) ? 'block' : 'none' }}>
                    <FormPensionesAlimenticias
                        elementSelect={elementSelect}
                        setError={setError}
                        agregarPensionAlimEdit={agregarPensionAlimEdit}
                        actualizarPensionAlim={actualizarPensionAlim}
                        enviar={enviar}
                        irInfo={irInfo}
                        folioPensionAlimRef={folioPensionAlimRef}
                        nombrePensionAlimRef={nombrePensionAlimRef}
                        pApellidoPensionAlimRef={pApellidoPensionAlimRef}
                        sApellidoPensionAlimRef={sApellidoPensionAlimRef}
                        tipoCuotaPensionAlimRef={tipoCuotaPensionAlimRef}
                        montoPensionAlimRef={montoPensionAlimRef}
                        fechaInicioPensionAlimRef={fechaInicioPensionAlimRef}
                        fechaFinalPensionAlimRef={fechaFinalPensionAlimRef}
                        pensionesAlimSaved={pensionesAlimSaved}
                        setPensionesAlimSaved={setPensionesAlimSaved}
                        pensionesAlimSavedEdit={pensionesAlimSavedEdit}
                        setPensionesAlimSavedEdit={setPensionesAlimSavedEdit}
                        elementoSeleccionado={elementoSeleccionadoPensionAlim}
                        setElementoSeleccionado={setElementoSeleccionadoPensionAlim}
                        mostrarFormAddEdit={mostrarFormAddPenAlimEdit}
                        setMostrarFormAddEdit={setMostrarFormAddPenAlimEdit}
                        successTable={successTable}
                        errorTable={errorTable}
                    />
                </div>
                <div id="form-part-6" style={{ display: (active === 6) ? 'block' : 'none' }}>
                    <FormNumerosEmergencia
                        elementSelect={elementSelect}
                        setError={setError}
                        agregarNumEmerEdit={agregarNumEmerEdit}
                        actualizarNumEmer={actualizarNumEmer}
                        enviar={enviar}
                        irInfo={irInfo}
                        nombreNumEmergenciaRef={nombreNumEmergenciaRef}
                        pApellidoNumEmergenciaRef={pApellidoNumEmergenciaRef}
                        sApellidoNumEmergenciaRef={sApellidoNumEmergenciaRef}
                        telFijoNumEmergenciaRef={telFijoNumEmergenciaRef}
                        telMovilNumEmergenciaRef={telMovilNumEmergenciaRef}
                        tiposParentesco={tiposParentesco}
                        parentescoNumEmergenciaRef={parentescoNumEmergenciaRef}
                        calleNumEmergenciaRef={calleNumEmergenciaRef}
                        numExteriorNumEmergenciaRef={numExteriorNumEmergenciaRef}
                        numInteriorNumEmergenciaRef={numInteriorNumEmergenciaRef}
                        cpNumEmergenciaRef={cpNumEmergenciaRef}
                        coloniaNumEmergenciaRef={coloniaNumEmergenciaRef}
                        ciudadNumEmergenciaRef={ciudadNumEmergenciaRef}
                        estadoNumEmergenciaRef={estadoNumEmergenciaRef}
                        llamadasEmergenciaSaved={llamadasEmergenciaSaved}
                        setLlamadasEmergenciaSaved={setLlamadasEmergenciaSaved}
                        llamadasEmergenciaSavedEdit={llamadasEmergenciaSavedEdit}
                        setLlamadasEmergenciaSavedEdit={setLlamadasEmergenciaSavedEdit}
                        elementoSeleccionado={elementoSeleccionadoNumEmer}
                        setElementoSeleccionado={setElementoSeleccionadoNumEmer}
                        mostrarFormAddEdit={mostrarFormAddNumEmerEdit}
                        setMostrarFormAddEdit={setMostrarFormAddNumEmerEdit}
                        successTable={successTable}
                        errorTable={errorTable}
                    />
                </div>
                <div id="form-part-7" style={{ display: (active === 7) ? 'block' : 'none' }}>
                    <FormExperienciaLaboral
                        empresaExpLabRef={empresaExpLabRef}
                        puestoExpLabRef={puestoExpLabRef}
                        desdeMesExpLabRef={desdeMesExpLabRef}
                        desdeAnioExpLabRef={desdeAnioExpLabRef}
                        hastaMesExpLabRef={hastaMesExpLabRef}
                        hastaAnioExpLabRef={hastaAnioExpLabRef}
                        nombreJefeExpLabRef={nombreJefeExpLabRef}
                        telefonoExpLabRef={telefonoExpLabRef}
                        elementSelect={elementSelect}
                        elementoSeleccionado={elementoSeleccionadoExpLaboral}
                        setElementoSeleccionado={setElementoSeleccionadoExpLaboral}
                        irInfo={irInfo}
                        actualizarExpLaboral={actualizarExpLaboral}
                        agregarExpLaboralEdit={agregarExpLaboralEdit}
                        enviar={enviar}
                        mostrarFormAddEdit={mostrarFormAddExpLaboralEdit}
                        setMostrarFormAddEdit={setMostrarFormAddExpLaboralEdit}
                        expLaboralSaved={expLaboralSaved}
                        setExpLaboralSaved={setExpLaboralSaved}
                        expLaboralSavedEdit={expLaboralSavedEdit}
                        setExpLaboralSavedEdit={setExpLaboralSavedEdit}
                        setError={setError}
                        successTable={successTable}
                        errorTable={errorTable}
                    />
                </div>
                <div id="form-part-8" style={{ display: (active === 8) ? 'block' : 'none' }}>
                    <FormPasatiempos
                        pasatiempoRef={pasatiempoRef}
                        pasatiempos={pasatiempos}
                        elementSelect={elementSelect}
                        elementoSeleccionado={elementoSeleccionadoPasatiempo}
                        setElementoSeleccionado={setElementoSeleccionadoPasatiempo}
                        irInfo={irInfo}
                        actualizarPasatiempo={actualizarPasatiempo}
                        agregarPasatiempoEdit={agregarPasatiempoEdit}
                        enviar={enviar}
                        mostrarFormAddEdit={mostrarFormAddPasatiempoEdit}
                        setMostrarFormAddEdit={setMostrarFormAddPasatiempoEdit}
                        pasatiempoSaved={pasatiempoSaved}
                        setPasatiempoSaved={setPasatiempoSaved}
                        pasatiempoSavedEdit={pasatiempoSavedEdit}
                        setPasatiempoSavedEdit={setPasatiempoSavedEdit}
                        setError={setError}
                    />
                </div>
                { error }
                <AlertWarning show={openAlert2} SetopenALert={setOpenAlert2} text={(formaPagoRef.current) ? (formaPagoRef.current.value === '') ? 'No ha agregado la Forma de Pago seleccionada. ¿Deseas continuar?' : 'No ha guardado cambios. ¿Deseas continuar?' : 'No ha guardado cambios. ¿Deseas continuar?'} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue2}/>
                {   elementSelect &&
                    <Fragment>
                        <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text="No ha guardado cambios. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={siguienteEdit}/>
                        <AlertWarning show={openAlert3} SetopenALert={setOpenAlert3} text="No ha guardado cambios. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue3}/>
                        <AlertWarning show={openAlert4} SetopenALert={setOpenAlert4} text="No ha agregado el elemento. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue4}/>
                        <AlertWarning show={openAlert5} SetopenALert={setOpenAlert5} text="No ha guardado cambios. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue5}/>
                        <AlertWarning show={openAlert6} SetopenALert={setOpenAlert6} text="No ha agregado el elemento. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue6}/>
                        <AlertWarning show={openAlert7} SetopenALert={setOpenAlert7} text="No ha guardado cambios. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue7}/>
                        <AlertWarning show={openAlert8} SetopenALert={setOpenAlert8} text="No ha agregado el elemento. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue8}/>
                        <AlertWarning show={openAlert9} SetopenALert={setOpenAlert9} text="No ha guardado cambios. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue9}/>
                        <AlertWarning show={openAlert10} SetopenALert={setOpenAlert10} text="No ha agregado el elemento. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue10}/>
                        <AlertWarning show={openAlert11} SetopenALert={setOpenAlert11} text="No ha guardado cambios. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue11}/>
                        <AlertWarning show={openAlert12} SetopenALert={setOpenAlert12} text="No ha agregado el elemento. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue12}/>
                        <AlertWarning show={openAlert13} SetopenALert={setOpenAlert13} text="No ha guardado cambios. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={setActive} value={openValue13}/>
                    </Fragment>
                }
            </Fragment>
        :   <SpinnerOval />
        }
        </Fragment>
    );
}
export default FormEmpleados;
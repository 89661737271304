import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, deleteOptionals, amountToFloat } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import AlertForm from '../../../../../../../Services/AlertForm';

const cantidadQuinquenios = [
    { id: 1, cantidad: "1" },
    { id: 2, cantidad: "2" },
    { id: 3, cantidad: "3" },
    { id: 4, cantidad: "4" },
    { id: 5, cantidad: "5" },
    { id: 6, cantidad: "6" },
]

const FormularioFijos = ({ error, enviarFijo, elementSelect, actualizarFijo, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ nominaIndicador, setNominaIndicador ] = useState( elementSelect ? informacion.indicadoresNomina.find( element => element.id === elementSelect.nomina.id ) : null );
    const [ errorIndicador, setErrorIndicador ] = useState( null );
    const cantidadRef = createRef('');
    const montoRef = createRef('');
    const acumuladoRef = createRef('');
    const fechaInicioRef = createRef('');
    const fechaFinalRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarFijo' ) ){
            if( nominaIndicador ){
                const nomina = nominaIndicador.id;
                const cantidad = parseFloat(cantidadRef.current.value);
                const monto = amountToFloat(montoRef.current.value);
                const acumulado = parseInt( acumuladoRef.current.value );
                const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
                const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
                //console.log( deleteOptionals({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaInicio, fechaFinal }) );
                enviarFijo( deleteOptionals({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaInicio, fechaFinal }) );
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarFijo' ) ){
            const nomina = elementSelect.nomina.id;
            const cantidad = parseFloat(cantidadRef.current.value);
            const monto = amountToFloat(montoRef.current.value);
            const acumulado = parseInt( acumuladoRef.current.value );
            const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
            const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
            //console.log( deleteOptionals({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaInicio, fechaFinal }) );
            actualizarFijo( deleteOptionals({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaInicio, fechaFinal }) , elementSelect.id);
        }
    };

    const validarIndicador = (valor) => {
        if(valor) {
            //console.log( valor );
            setErrorIndicador( null );
            setNominaIndicador( valor );
        }else{
            setErrorIndicador( true );
            setNominaIndicador( null );
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarFijo' : 'AgregarFijo' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                { elementSelect ?
                    <div> <b> Concepto de Nómina: </b> {elementSelect.nomina.nomIndicador} </div>
                    :
                    <AutocompleteComponent id="indicadorFijo" title="Concepto de Nómina" placeholder="Seleccione el Concepto de Nómina" size="100%"
                        tooltipName="tooltipIndicadorFijo" tooltipText={'Busque el Concepto, por nombre o clave, en el campo de autocompletado'} action={validarIndicador}
                        options={informacion.indicadoresNomina} optionListView={'nomIndicador'} optionKey="nomClave" error={errorIndicador} required/>
                }

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        { nominaIndicador === null ?
                            <InputValidation title="Cantidad" type="cantidad" placeholder="Digite la cantidad del movimiento fijo"
                                tooltipText={'Máximo 16 números'} tooltipName={'tooltipCantMov'} maxLength='16' onlyNumbers
                                refValue={cantidadRef} defaultValue={ elementSelect ? elementSelect.cantidad : '' } required/>
                            :
                            nominaIndicador && nominaIndicador.rutina.id === 13 ? //rutinaNom: "Quinquenio"
                                <SelectValidation title="Quinquenio" arrayOpciones={cantidadQuinquenios} keyOpcion="cantidad"
                                    refValue={cantidadRef} defaultValue={ elementSelect ? elementSelect.cantidad : '' } required/>
                                :
                                <InputValidation title="Cantidad" type="cantidad" placeholder="Digite la cantidad del movimiento fijo"
                                    tooltipText={'Máximo 16 números'} tooltipName={'tooltipCantMov'} maxLength='16' onlyNumbers
                                    refValue={cantidadRef} defaultValue={ elementSelect ? elementSelect.cantidad : '' } required/>
                        }
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Monto (MXN)" type="monto" placeholder="Digite el monto del movimiento fijo"
                            tooltipText={'Máximo 16 números y 2 decimales'} tooltipName={'tooltipMontMov'} maxLength='19'
                            refValue={montoRef} defaultValue={ elementSelect ? elementSelect.monto : '' } required
                            onlyNumbers="." onBlurDecimal={2} isAmount/>
                    </div>
                </div>

                <SelectValidation title="Tipo de Acumulado" arrayOpciones={informacion.tiposAcumulado} keyOpcion="acumuladoNombre"
                    refValue={acumuladoRef} defaultValue={ elementSelect ? elementSelect.acumulado.id : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechaInicioRef} required
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaInicio) : '' } />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} optional
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaFinal) : '' } />
                    </div>
                </div>

                {error}
                {errorIndicador}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioFijos;
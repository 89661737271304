import React from 'react';
//import {  indicadores_ } from '../../../../Constants/Constants';

import ModalAddTipoRelacion from '../Modulos/Generales/TipoRelacionLaboral/ModalAddTipoRelacion';
import ModalEditTipoRelacion from '../Modulos/Generales/TipoRelacionLaboral/ModalEditTipoRelacion';
import ModalDeleteTipoRelacion from '../Modulos/Generales/TipoRelacionLaboral/ModalDeleteTipoRelacion';

import ModalAddRegistroPatronal from '../Modulos/Generales/RegistroPatronal/ModalAddRegistroPatronal';
import ModalEditRegistroPatronal from '../Modulos/Generales/RegistroPatronal/ModalEditRegistroPatronal';
import ModalDeleteRegistroPatronal from '../Modulos/Generales/RegistroPatronal/ModalDeleteRegistroPatronal';

import ModalAddTiposSecDep from '../Modulos/Generales/TiposSecretariaDependencia/ModalAddTiposSecDep';
import ModalEditTiposSecDep from '../Modulos/Generales/TiposSecretariaDependencia/ModalEditTiposSecDep';
import ModalDeleteTiposSecDep from '../Modulos/Generales/TiposSecretariaDependencia/ModalDeleteTiposSecDep';

import ModalAddMotivosBaja from '../Modulos/Generales/MotivosBaja/ModalAddMotivosBaja';
import ModalEditMotivosBaja from '../Modulos/Generales/MotivosBaja/ModalEditMotivosBaja';
import ModalDeleteMotivosBaja from '../Modulos/Generales/MotivosBaja/ModalDeleteMotivosBaja';

import ModalAddBancos from '../Modulos/Generales/Bancos/ModalAddBancos';
import ModalEditBancos from '../Modulos/Generales/Bancos/ModalEditBancos';
import ModalDeleteBancos from '../Modulos/Generales/Bancos/ModalDeleteBancos';

import ModalAddTiposDomicilio from '../Modulos/Generales/TiposDomicilio/ModalAddTiposDomicilio';
import ModalEditTiposDomicilio from '../Modulos/Generales/TiposDomicilio/ModalEditTiposDomicilio';
import ModalDeleteTiposDomicilio from '../Modulos/Generales/TiposDomicilio/ModalDeleteTiposDomicilio';

import ModalAddNivelEscolaridad from '../Modulos/Generales/NivelEscolaridad/ModalAddNivelEscolaridad';
import ModalEditNivelEscolaridad from '../Modulos/Generales/NivelEscolaridad/ModalEditNivelEscolaridad';
import ModalDeleteNivelEscolaridad from '../Modulos/Generales/NivelEscolaridad/ModalDeleteNivelEscolaridad';

import ModalAddIndicadoresGenerales from '../Modulos/Generales/IndicadoresGenerales/ModalAddIndicadoresGenerales';
import ModalEditIndicadoresGenerales from '../Modulos/Generales/IndicadoresGenerales/ModalEditIndicadoresGenerales';
import ModalDeleteIndicadoresGenerales from '../Modulos/Generales/IndicadoresGenerales/ModalDeleteIndicadoresGenerales';
import ModalConfigBaseIndicadoresGral from '../Modulos/Generales/IndicadoresGenerales/ModalConfigBaseIndicadoresGral';

import ModalAddTipoEstadoCivil from '../Modulos/Generales/TipoEstadoCivil/ModalAddTipoEstadoCivil';
import ModalEditTipoEstadoCivil from '../Modulos/Generales/TipoEstadoCivil/ModalEditTipoEstadoCivil';
import ModalDeleteTipoEstadoCivil from '../Modulos/Generales/TipoEstadoCivil/ModalDeleteTipoEstadoCivil';

import ModalAddProyectos from '../Modulos/Administracion/Proyectos/ModalAddProyectos';
import ModalEditProyectos from '../Modulos/Administracion/Proyectos/ModalEditProyectos';
import ModalDeleteProyectos from '../Modulos/Administracion/Proyectos/ModalDeleteProyectos';

import ModalAddUsuarios from "../Modulos/Administracion/Usuarios/ModalAddUsuarios";
import ModalEditUsuarios from "../Modulos/Administracion/Usuarios/ModalEditUsuarios";
import ModalDeleteUsuarios from '../Modulos/Administracion/Usuarios/ModalDeleteUsuarios';

import ModalAddGiros from '../Modulos/Generales/Giros/ModalAddGiros';
import ModalEditGiros from '../Modulos/Generales/Giros/ModalEditGiros';
import ModalDeleteGiros from '../Modulos/Generales/Giros/ModalDeleteGiros';

import ModalAddParentesco from '../Modulos/Generales/Parentesco/ModalAddParentesco';
import ModalEditParentesco from '../Modulos/Generales/Parentesco/ModalEditParentesco';
import ModalDeleteParentesco from '../Modulos/Generales/Parentesco/ModalDeleteParentesco';

import ModalAddTiposJornada from '../Modulos/Generales/TiposJornada/ModalAddTiposJornada';
import ModalEditTiposJornada from '../Modulos/Generales/TiposJornada/ModalEditTiposJornada';
import ModalDeleteTiposJornada from '../Modulos/Generales/TiposJornada/ModalDeleteTiposJornada';

import ModalAddColonias from '../Modulos/Generales/Colonias/ModalAddColonias';
import ModalEditColonias from '../Modulos/Generales/Colonias/ModalEditColonias';
import ModalDeleteColonias from '../Modulos/Generales/Colonias/ModalDeleteColonias';

import ModalAddTiposAusentismos from '../Modulos/Generales/TiposAusentismos/ModalAddTiposAusentismos';
import ModalEditTiposAusentismos from '../Modulos/Generales/TiposAusentismos/ModalEditTiposAusentismos';
import ModalDeleteTiposAusentismos from '../Modulos/Generales/TiposAusentismos/ModalDeleteTiposAusentismos';

import ModalAddSeguroSocial from '../Modulos/Generales/TipoSeguroSocial/ModalAddSeguroSocial';
import ModalEditSeguroSocial from '../Modulos/Generales/TipoSeguroSocial/ModalEditSeguroSocial';
import ModalDeleteSeguroSocial from '../Modulos/Generales/TipoSeguroSocial/ModalDeleteSeguroSocial';

import ModalAddTipoDocumentos from '../Modulos/Generales/TipoDocumentos/ModalAddTipoDocumentos';
import ModalEditTipoDocumentos from '../Modulos/Generales/TipoDocumentos/ModalEditTipoDocumentos';
import ModalDeleteTipoDocumentos from '../Modulos/Generales/TipoDocumentos/ModalDeleteTipoDocumentos';

import ModalAddRegimenFiscal from '../Modulos/Generales/RegimenFiscal/ModalAddRegimenFiscal';
import ModalEditRegimenFiscal from '../Modulos/Generales/RegimenFiscal/ModalEditRegimenFiscal';
import ModalDeleteRegimenFiscal from '../Modulos/Generales/RegimenFiscal/ModalDeleteRegimenFiscal';

import ModalAddOrigenRecurso from '../Modulos/Generales/OrigenRecurso/ModalAddOrigenRecurso';
import ModalEditOrigenRecurso from '../Modulos/Generales/OrigenRecurso/ModalEditOrigenRecurso';
import ModalDeleteOrigenRecurso from '../Modulos/Generales/OrigenRecurso/ModalDeleteOrigenRecurso';

import ModalAddTipoContrato from '../Modulos/Generales/TipoContrato/ModalAddTipoContrato';
import ModalEditTipoContrato from '../Modulos/Generales/TipoContrato/ModalEditTipoContrato';
import ModalDeleteTipoContrato from '../Modulos/Generales/TipoContrato/ModalDeleteTipoContrato';

import ModalAddTipoRegimen from '../Modulos/Generales/TipoRegimen/ModalAddTipoRegimen';
import ModalEditTipoRegimen from '../Modulos/Generales/TipoRegimen/ModalEditTipoRegimen';
import ModalDeleteTipoRegimen from '../Modulos/Generales/TipoRegimen/ModalDeleteTipoRegimen';

import ModalAddTipoRiesgo from '../Modulos/Generales/TipoRiesgo/ModalAddTipoRiesgo';
import ModalEditTipoRiesgo from '../Modulos/Generales/TipoRiesgo/ModalEditTipoRiesgo';
import ModalDeleteTipoRiesgo from '../Modulos/Generales/TipoRiesgo/ModalDeleteTipoRiesgo';

import ModalAddTipoAcumulado from '../Modulos/Generales/TipoAcumulado/ModalAddTipoAcumulado'; 
import ModalEditTipoAcumulado from '../Modulos/Generales/TipoAcumulado/ModalEditTipoAcumulado'; 
import ModalDeleteTipoAcumulado from '../Modulos/Generales/TipoAcumulado/ModalDeleteTipoAcumulado';

import ModalAddContratosRelLab from '../Modulos/Generales/ContratosRealacionLaboral/ModalAddContratosRelLab';
import ModalEditContratosRelLab from '../Modulos/Generales/ContratosRealacionLaboral/ModalEditContratosRelLab';
import ModalDeleteContratosRelLab from '../Modulos/Generales/ContratosRealacionLaboral/ModalDeleteContratosRelLab';

import ModalAddSalarioMinUMA from '../Modulos/Generales/SalarioMinimoUMA/ModalAddSalarioMinUMA';
import ModalEditSalarioMinUMA from '../Modulos/Generales/SalarioMinimoUMA/ModalEditSalarioMinUMA';
import ModalDeleteSalarioMinUMA from '../Modulos/Generales/SalarioMinimoUMA/ModalDeleteSalarioMinUMA';

import ModalAddImpuestoNomina from '../Modulos/Generales/ImpuestoSobreNomina/ModalAddImpuestoNomina';
import ModalEditImpuestoNomina from '../Modulos/Generales/ImpuestoSobreNomina/ModalEditImpuestoNomina';
import ModalDeleteImpuestoNomina from '../Modulos/Generales/ImpuestoSobreNomina/ModalDeleteImpuestoNomina';

import ModalAddTablaImpuestos from '../Modulos/Generales/TablasImpuestos/ModalAddTablaImpuestos'; 
import ModalDeleteTablasImpuestos from '../Modulos/Generales/TablasImpuestos/ModalDeleteTablasImpuestos';

import ModalAddTablaSubsidios from '../Modulos/Generales/TablasSubsidio/ModalAddTablaSubsidios'; 
import ModalDeleteTablasSubsidios from '../Modulos/Generales/TablasSubsidio/ModalDeleteTablasSubsidios';

import ModalAddBases from '../Modulos/Generales/Bases/ModalAddBases';
import ModalEditBases from '../Modulos/Generales/Bases/ModalEditBases';
import ModalDeleteBases from '../Modulos/Generales/Bases/ModalDeleteBases';

import ModalAddTipoPasatiempo from '../Modulos/Generales/TipoPasatiempo/ModalAddTipoPasatiempo';
import ModalEditTipoPasatiempo from '../Modulos/Generales/TipoPasatiempo/ModalEditTipoPasatiempo';
import ModalDeleteTipoPasatiempo from '../Modulos/Generales/TipoPasatiempo/ModalDeleteTipoPasatiempo';

import ModalAddSindicatos from '../Modulos/Generales/Sindicatos/ModalAddSindicatos';
import ModalEditSindicatos from '../Modulos/Generales/Sindicatos/ModalEditSindicatos';
import ModalDeleteSindicatos from '../Modulos/Generales/Sindicatos/ModalDeleteSindicatos';

import ModalAddDepartamentos from '../Modulos/Generales/Departamentos/ModalAddDepartamentos';
import ModalEditDepartamentos from '../Modulos/Generales/Departamentos/ModalEditDepartamentos';
import ModalDeleteDepartamentos from '../Modulos/Generales/Departamentos/ModalDeleteDepartamentos';

import ModalAddDependencias from '../Modulos/Administracion/Dependencias/ModalAddDependencias';
import ModalEditDependencias from '../Modulos/Administracion/Dependencias/ModalEditDependencias';
import ModalDeleteDependencias from '../Modulos/Administracion/Dependencias/ModalDeleteDependencias';

import ModalAddNiveles from '../Modulos/Organizacion/Niveles/ModalAddNiveles';
import ModalEditNiveles from '../Modulos/Organizacion/Niveles/ModalEditNiveles';
import ModalDeleteNiveles from '../Modulos/Organizacion/Niveles/ModalDeleteNiveles';

import ModalAddPuestos from '../Modulos/Organizacion/Puestos/ModalAddPuestos';
import ModalEditPuestos from '../Modulos/Organizacion/Puestos/ModalEditPuestos';
import ModalDeletePuestos from '../Modulos/Organizacion/Puestos/ModalDeletePuestos';

import ModalAddEmpleados from '../Modulos/RecursosHumanos/Empleados/ModalAddEmpleados';
import ModalEditEmpleados from '../Modulos/RecursosHumanos/Empleados/ModalEditEmpleados';
import ModalDeleteFormEmpleados from '../Modulos/RecursosHumanos/Empleados/ModalDeleteFormEmpleados';
import ModalCuentaPortalEmpleados from '../Modulos/RecursosHumanos/Empleados/PortalEmpleado/ModalCuentaPortalEmpleados';

import ModalAddAusentismos from '../Modulos/RecursosHumanos/Ausentismos/ModalAddAusentismos';
import ModalEditAusentismos from '../Modulos/RecursosHumanos/Ausentismos/ModalEditAusentismos';
import ModalDeleteAusentismos from '../Modulos/RecursosHumanos/Ausentismos/ModalDeleteAusentismos';

import ModalEditExpediente from '../Modulos/RecursosHumanos/Expediente/ModalEditExpediente';

import ModalAddVariablesExcel from '../Modulos/Nomina/Variables/ModalAddVariablesExcel';
import ModalAddFijosExcel from '../Modulos/Nomina/Fijos/ModalAddFijosExcel';
import ModalAddPrestamosExcel from '../Modulos/Nomina/Prestamos/ModalAddPrestamosExcel';

import ModalConsultaPrestamos from '../Modulos/Nomina/Prestamos/ModalConsultaPrestamos';
import ModalAddPrestamos from '../Modulos/Nomina/Prestamos/PrestamosEmpleado/ModalAddPrestamos';
import ModalEditPrestamos from '../Modulos/Nomina/Prestamos/PrestamosEmpleado/ModalEditPrestamos';
import ModalFinalizaPrestamos from '../Modulos/Nomina/Prestamos/PrestamosEmpleado/ModalFinalizaPrestamos';
import ModalDeletePrestamos from '../Modulos/Nomina/Prestamos/PrestamosEmpleado/ModalDeletePrestamos';

import ModalInfoHistorico from '../Modulos/RecursosHumanos/Historico/ModalInfoHistorico';

import ModalAddPeriodoNomina from '../Modulos/Nomina/PeriodosNomina/ModalAddPeriodoNomina';
import ModalDeletePeriodoNomina from '../Modulos/Nomina/PeriodosNomina/ModalDeletePeriodoNomina';

import ModalAddTabuladores from '../Modulos/Organizacion/Tabuladores/ModalAddTabuladores';
import ModalDeleteIndicadorTab from '../Modulos/Organizacion/Tabuladores/ModalDeleteIndicadorTab';

import ModalAddTabuladoresQuinquenios from '../Modulos/Organizacion/TabuladoresQuinquenios/ModalAddTabuladoresQuinquenios';
import ModalDeleteAntiguedadQuinquenios from '../Modulos/Organizacion/TabuladoresQuinquenios/ModalDeleteAntiguedadQuinquenios';

import ModalAddTurnos from '../Modulos/Organizacion/Turnos/ModalAddTurnos';
import ModalDeleteTurnos from '../Modulos/Organizacion/Turnos/ModalDeleteTurnos';
import ModalEditTurnos from '../Modulos/Organizacion/Turnos/ModalEditTurnos';

import ModalAddNominasOrg from '../Modulos/Organizacion/Nominas/ModalAddNominasOrg';
import ModalEditNominasOrg from '../Modulos/Organizacion/Nominas/ModalEditNominasOrg';
import ModalDeleteNominasOrg from '../Modulos/Organizacion/Nominas/ModalDeleteNominasOrg';

import ModalAddIndicadores from '../Modulos/Nomina/Indicadores/ModalAddIndicadores';
import ModalEditIndicadores from '../Modulos/Nomina/Indicadores/ModalEditIndicadores';
import ModalDeleteIndicador from '../Modulos/Nomina/Indicadores/ModalDeleteIndicador';
import ModalConfigBaseIndicadoresNomina from '../Modulos/Nomina/Indicadores/ModalConfigBaseIndicadoresNomina';

import ModalAddIMSS from '../Modulos/Nomina/IMSS/ModalAddIMSS';
import ModalEditIMSS from '../Modulos/Nomina/IMSS/ModalEditIMSS';
import ModalDeleteIMSS from '../Modulos/Nomina/IMSS/ModalDeleteIMSS';

import ModalAddPensiones from '../Modulos/Nomina/Pensiones/ModalAddPensiones';
import ModalEditPensiones from '../Modulos/Nomina/Pensiones/ModalEditPensiones';
import ModalDeletePensiones from '../Modulos/Nomina/Pensiones/ModalDeletePensiones';

import ModalAddCalculo from '../Modulos/Nomina/Calculo/ModalAddCalculo';
//import ModalAddDispersion from '../Modulos/Reporteador/Dispersiones/ModalAddDispersion';

import ModalSaveReport from '../Modulos/Reporteador/RecursosHumanos/ModalSaveReport';
import ModalConsultaFichaEmpleado from '../Modulos/Reporteador/RecursosHumanos/ModalConsultaFichaEmpleado';

import ModalAddPoliticas from '../Modulos/Nomina/Politicas/ModalAddPoliticas';
import ModalEditPoliticas from '../Modulos/Nomina/Politicas/ModalEditPoliticas';
import ModalDeletePoliticas from '../Modulos/Nomina/Politicas/ModalDeletePoliticas';

import ModalAcumulados from '../Modulos/Nomina/Acumulados/ModalAcumulados';
import ModalAddAcumulados from '../Modulos/Nomina/Acumulados/ModalsTableAcumulados/ModalAddAcumulados';
import ModalEditAcumulados from '../Modulos/Nomina/Acumulados/ModalsTableAcumulados/ModalEditAcumulados';
import ModalDeleteAcumulados from '../Modulos/Nomina/Acumulados/ModalsTableAcumulados/ModalDeleteAcumulados';

import ModalInfoAuditoria from '../Modulos/Reporteador/Auditoria/ModalInfoAuditoria';

import ModalAddPlantillas from '../Modulos/RecursosHumanos/Plantillas/ModalAddPlantillas';
import ModalEditPlantillas from '../Modulos/RecursosHumanos/Plantillas/ModalEditPlantillas';
import ModalDeletePlantillas from '../Modulos/RecursosHumanos/Plantillas/ModalDeletePlantillas';

/* Funcion para determinar el modal que corresponde a cada DataTable */
/* Props: { openAdd, setOpenAdd, agregar, errorTable, informacionAdicional } */
export function obtenerModalAdd( Modulo, openAdd, setOpenAdd, agregar, editar, errorTable, successTable, informacionAdicional ) { 
    
    switch (  isNaN( Modulo ) ? !Modulo.includes('secondary') && !Modulo.includes('config') ? parseInt( Modulo.split('-')[0]) : Modulo: Modulo  ) {
        case 3: return <ModalAddTipoRelacion openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo }/>;
        case 4: return <ModalAddRegistroPatronal openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 6: return <ModalAddTiposSecDep openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 7: return <ModalAddMotivosBaja openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 12: return <ModalAddBancos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 15: return <ModalAddTiposDomicilio openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 17: return <ModalAddNivelEscolaridad openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 16: return <ModalAddIndicadoresGenerales openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 16+'secondary': return <ModalConfigBaseIndicadoresGral openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo.replace('secondary','') } />;
        case 44: return <ModalAddTipoEstadoCivil openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 21: return <ModalAddProyectos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        
        case 19: return <ModalAddUsuarios openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} editar={editar} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 20: return <ModalAddDependencias openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;

        case 5: return <ModalAddGiros openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 8: return <ModalAddParentesco openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 14: return <ModalAddTiposJornada openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 11: return <ModalAddColonias openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 13: return <ModalAddTiposAusentismos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 45: return <ModalAddSeguroSocial openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 46: return <ModalAddTipoDocumentos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 54: return <ModalAddRegimenFiscal openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 55: return <ModalAddOrigenRecurso openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 56: return <ModalAddTipoContrato openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 57: return <ModalAddTipoRegimen openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 58: return <ModalAddTipoRiesgo openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 60: return <ModalAddTipoAcumulado openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 61: return <ModalAddContratosRelLab openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 65: return <ModalAddSalarioMinUMA openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 66: return <ModalAddImpuestoNomina openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 67: return <ModalAddTablaImpuestos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 72:  return <ModalAddTablaSubsidios openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 73:  return <ModalAddBases openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={Modulo}/>;
        case 74:  return <ModalAddTipoPasatiempo openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={Modulo}/>;
        case 77:  return <ModalAddSindicatos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo }/>;
        case 81:  return <ModalAddDepartamentos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo }/>;

        case 23: return <ModalAddNiveles openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 24: return <ModalAddPuestos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 25: return <ModalAddTabuladores openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 27: return <ModalAddTabuladoresQuinquenios openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 59: return <ModalAddTurnos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 28: return <ModalAddNominasOrg openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 39: return <ModalAddPoliticas openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;

        case 30: return <ModalAddEmpleados openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} nombreModulo={Modulo} />;
        case 31: return null;
        case 31+'secondary': return <ModalAddAusentismos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={Modulo.replace('secondary','')} />;
        case 33: return null; //expediente_
        case 41: return <ModalAddVariablesExcel openAdd={openAdd} setOpenAdd={setOpenAdd} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 68: return <ModalAddFijosExcel openAdd={openAdd} setOpenAdd={setOpenAdd} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 75: return <ModalAddPrestamosExcel openAdd={openAdd} setOpenAdd={setOpenAdd} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 75+'secondary': return <ModalAddPrestamos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 69: return null; //movimientos_relacionados
        case 70: return null; //movimientos_pendientes
        case 64: return <ModalAddIMSS openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 71: return <ModalAddPensiones openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;

        case 34: return null; //historico_
        case 36: return null; //Nóminas/Nóminas
        case 37: return <ModalAddPeriodoNomina openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo }/>;
        case 38: return null; //indicadores
        case 38+'secondary': return <ModalAddIndicadores openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo.replace('secondary','') }/>;
        case 38+'config': return <ModalConfigBaseIndicadoresNomina openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={editar} informacionAdicional={informacionAdicional} nombreModulo={ Modulo.replace('config','') } />;
        case 40: return null;
        case 50: return null;//Layout CFDI
        case 51: return null;//Dispersiones
        case 48: return null;//Reporte de Nominas
        case 49+'secondary':
        case 49: return null;//Reporte de RecursosHumanos
        case 76: return null; //acumulados_
        case 76+'secondary': return <ModalAddAcumulados openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={editar} informacionAdicional={informacionAdicional} nombreModulo={Modulo.replace('secondary','')}/>;
        case 78: return <ModalAddPlantillas openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={editar} informacionAdicional={informacionAdicional} nombreModulo={Modulo}/>;

        case 80: return null;//Auditorias

        default: return <h2 className="text-center"> Modal Agregar No encontrado </h2>;
    }
}

/* Props: { openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional } */
export function obtenerModalEdit( Modulo, openEdit, setOpenEdit, elementSelect, editar, errorTable, successTable, informacionAdicional ) {
    
    switch ( isNaN( Modulo ) ? !Modulo.includes('secondary') && !Modulo.includes('config') ? parseInt( Modulo.split('-')[0]) : Modulo: Modulo  ) { 
        case 3: return <ModalEditTipoRelacion openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 4: return <ModalEditRegistroPatronal openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 6: return <ModalEditTiposSecDep openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 7: return <ModalEditMotivosBaja openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 12: return <ModalEditBancos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 15: return <ModalEditTiposDomicilio openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 17: return <ModalEditNivelEscolaridad openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 16: return <ModalEditIndicadoresGenerales openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo }  />;
        case 16+'secondary': return <ModalConfigBaseIndicadoresGral openAdd={openEdit} setOpenAdd={setOpenEdit} agregar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo.replace('secondary','') } elementSelect={elementSelect} />;
        case 44: return <ModalEditTipoEstadoCivil openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 21: return <ModalEditProyectos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        
        case 19: return <ModalEditUsuarios openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 20: return <ModalEditDependencias openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;

        case 5: return <ModalEditGiros openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 8: return <ModalEditParentesco openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 14: return <ModalEditTiposJornada openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 11: return <ModalEditColonias openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 13: return <ModalEditTiposAusentismos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 45: return <ModalEditSeguroSocial openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 46: return <ModalEditTipoDocumentos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 50: return null;//Layout CFDI
        case 54: return <ModalEditRegimenFiscal openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 55: return <ModalEditOrigenRecurso openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 56: return <ModalEditTipoContrato openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 57: return <ModalEditTipoRegimen openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 58: return <ModalEditTipoRiesgo openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 60: return <ModalEditTipoAcumulado openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 61: return <ModalEditContratosRelLab openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 65: return <ModalEditSalarioMinUMA openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 66: return <ModalEditImpuestoNomina openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 67: return null; //tablas_impuestos
        case 72:  return null; //tablas_subsidios
        case 73: return <ModalEditBases openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={Modulo}/>;
        case 74: return <ModalEditTipoPasatiempo openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={Modulo}/>;
        case 77: return <ModalEditSindicatos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 81: return <ModalEditDepartamentos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;

        case 23: return <ModalEditNiveles openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 24: return <ModalEditPuestos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 59: return <ModalEditTurnos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;
        case 28: return <ModalEditNominasOrg openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 25: return null; //tabuladores_
        case 27: return null; //tabuladores_quinquenios
        case 39: return <ModalEditPoliticas openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;

        case 30: return <ModalEditEmpleados openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} successTable={successTable} nombreModulo={Modulo} />;
        case 31: return null;
        case 31+'secondary': return <ModalEditAusentismos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={Modulo.replace('secondary','')} />;
        case 33: return <ModalEditExpediente openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 41: return null; //movimientos_
        case 68: return null; //movimientos_fijos
        case 69: return null; //movimientos_relacionados
        case 70: return null; //movimientos_pendientes
        case 75: return null; //prestamos_
        case 75+'secondary': return <ModalEditPrestamos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 64: return <ModalEditIMSS openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;
        case 71: return <ModalEditPensiones openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo } />;

        case 34: return null; //historico_
        case 36: return null; //Nóminas/Nóminas
        case 37: return null; //periodos_nomina
        case 38: return null; //Indicadores
        case 76: return null; //acumulados_
        case 76+'secondary': return <ModalEditAcumulados openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={Modulo.replace('secondary','')}/>;
        case 78: return <ModalEditPlantillas openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={Modulo}/>;

        case 38+'secondary': return <ModalEditIndicadores openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} successTable={successTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo.replace('secondary','') } />;
        case 38+'config': return <ModalConfigBaseIndicadoresNomina openAdd={openEdit} setOpenAdd={setOpenEdit} agregar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} nombreModulo={ Modulo.replace('config','') } elementSelect={elementSelect} />;

        case 40: return <ModalAddCalculo openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} errorTable={errorTable} successTable={successTable} nombreModulo={Modulo} informacionAdicional={informacionAdicional}/>;
        //case 51: return <ModalAddDispersion openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} errorTable={errorTable} successTable={successTable} nombreModulo={Modulo} />;
        case 49+'secondary': return <ModalConsultaFichaEmpleado openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} successTable={successTable} nombreModulo={Modulo.replace('secondary','')} />;
        case 49:
        case 48: return <ModalSaveReport openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} successTable={successTable} nombreModulo={Modulo} />;

        case 80: return <ModalInfoAuditoria openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} nombreModulo={ Modulo } />;

        default: return <h2 className="text-center"> Modal Editar No encontrado </h2>;
    }
}

/* Props: { alertWarning, setAlertWarning, elementSelect, eliminar, errorTable } */
export function obtenerModalDelete( Modulo, alertWarning, setAlertWarning, elementSelect, editar, eliminar, errorTable, informacionAdicional ) {
    
    switch ( isNaN( Modulo ) ? !Modulo.includes('secondary') ? parseInt( Modulo.split('-')[0]) : Modulo: Modulo ) { 
        case 3: return <ModalDeleteTipoRelacion alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 4: return <ModalDeleteRegistroPatronal alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 6: return <ModalDeleteTiposSecDep alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 7: return <ModalDeleteMotivosBaja alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 12: return <ModalDeleteBancos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 15: return <ModalDeleteTiposDomicilio alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 17: return <ModalDeleteNivelEscolaridad alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 16: return <ModalDeleteIndicadoresGenerales alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 44: return <ModalDeleteTipoEstadoCivil alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 21: return <ModalDeleteProyectos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;

        case 19: return <ModalDeleteUsuarios alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} editar={editar} errorTable={errorTable}/>;
        case 20: return <ModalDeleteDependencias alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;

        case 5: return <ModalDeleteGiros alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 8: return <ModalDeleteParentesco alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 14: return <ModalDeleteTiposJornada alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 11: return <ModalDeleteColonias alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 13: return <ModalDeleteTiposAusentismos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 45: return <ModalDeleteSeguroSocial alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 46: return <ModalDeleteTipoDocumentos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 50: return null;//Layout CFDI
        case 54: return <ModalDeleteRegimenFiscal alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 55: return <ModalDeleteOrigenRecurso alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 56: return <ModalDeleteTipoContrato alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 57: return <ModalDeleteTipoRegimen alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 58: return <ModalDeleteTipoRiesgo alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 60: return <ModalDeleteTipoAcumulado alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 61: return <ModalDeleteContratosRelLab alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 65: return <ModalDeleteSalarioMinUMA alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 66: return <ModalDeleteImpuestoNomina alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 67: return <ModalDeleteTablasImpuestos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;
        case 72: return <ModalDeleteTablasSubsidios alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;
        case 73: return <ModalDeleteBases alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 74: return <ModalDeleteTipoPasatiempo alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 77: return <ModalDeleteSindicatos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 81: return <ModalDeleteDepartamentos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;

        case 23: return <ModalDeleteNiveles alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>
        case 24: return <ModalDeletePuestos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>
        case 25: return <ModalDeleteIndicadorTab alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>
        case 59: return <ModalDeleteTurnos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>
        case 28: return <ModalDeleteNominasOrg alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 39: return <ModalDeletePoliticas alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        
        case 30: return null; //empleados_
        case 31: return null;
        case 31+'secondary': return <ModalDeleteAusentismos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} nombreModulo={Modulo.replace('secondary','')}/>;
        case 33: return null; //expediente_
        case 41: return null; //movimientos_
        case 68: return null; //movimientos_fijos
        case 69: return null; //movimientos_relacionados
        case 70: return null; //movimientos_pendientes
        case 75: return null; //prestamos_
        case 75+'secondary': return <ModalDeletePrestamos alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 76: return null; //acumulados_
        case 76+'secondary': return <ModalDeleteAcumulados alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} nombreModulo={Modulo.replace('secondary','')}/>; 
        case 78: return <ModalDeletePlantillas alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} nombreModulo={Modulo}/>; 
        
        case 64: return <ModalDeleteIMSS alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        case 71: return <ModalDeletePensiones alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        
        case 34: return null; //historico_
        case 36: return null; //Nóminas/Nóminas
        case 27: return <ModalDeleteAntiguedadQuinquenios alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>
        
        case 37: return <ModalDeletePeriodoNomina alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>
        case 38: return null;//Indicadores
        case 38+"secondary": return <ModalDeleteIndicador alertWarning={alertWarning} setAlertWarning={setAlertWarning} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} nombreModulo={ Modulo.replace('secondary','') } />
        case 49+'secondary':
        case 40: return null;
        case 51: return null;//Dispersiones 
        case 49: return null;//RecursosHumanos
        case 48: return null;//RecursosHumanos
        case 80: return null;//Auditorias
        
        default: return <h2 className="text-center"> Modal Eliminar No encontrado </h2>;
    }
    
}

export const obtenerModalDeleteForm = (Modulo, openDeleteForm, setOpenDeleteForm, elementSelect, eliminar, errorTable) => {
    switch ( isNaN( Modulo ) ? parseInt( Modulo.split('-')[0]) : Modulo ) {
        case 30: return <ModalDeleteFormEmpleados nombreModulo={Modulo} openDeleteForm={openDeleteForm} setOpenDeleteForm={setOpenDeleteForm} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable}/>;
        
        default: return null;
    }
}

export const obtenerModalInfo = (id, openInfo, setOpenInfo, elementSelect) => {
    switch ( isNaN( id ) ? parseInt( id.split('-')[0]) : id ) {
        case 34: return <ModalInfoHistorico id={id} openInfo={openInfo} setOpenInfo={setOpenInfo} elementSelect={elementSelect} />;
        
        default: return null;
    }
}

export const obtenerModalForm = (id, openModalForm, setOpenModalForm, elementSelect, editar, successTable, errorTable, informacionAdicional) => {
    switch ( isNaN( id ) ? !id.includes('secondary') ? parseInt( id.split('-')[0]) : id : id ) {
        case 30: return <ModalCuentaPortalEmpleados nombreModulo={id} openModalForm={openModalForm} setOpenModalForm={setOpenModalForm} elementSelect={elementSelect} successTable={successTable} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;
        case 75: return <ModalConsultaPrestamos id={id} openModalForm={openModalForm} setOpenModalForm={setOpenModalForm} elementSelect={elementSelect} />;
        case 75+'secondary': return <ModalFinalizaPrestamos id={id} openModalForm={openModalForm} setOpenModalForm={setOpenModalForm} elementSelect={elementSelect} editar={editar} errorTable={errorTable} />;
        case 76: return <ModalAcumulados id={id} openModalForm={openModalForm} setOpenModalForm={setOpenModalForm} elementSelect={elementSelect} successTable={successTable} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;
        default: return null;
    }
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { registro_no_guardado, servicios_grupogack_nomina, servicios_grupogack_catalogo } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadores, ArrayJsonSalarioMinUMA, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioIndicadores from './FormularioIndicadores';

const ModalAddIndicadores = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null ); 
    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null);

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarIndicador = ( indicador, configBaseData, indicadorParametros ) => {
       axios.post( `${servicios_grupogack_nomina}/indicadores/`, indicador, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const nuevoIndicador = JsonIndicadores(respuesta.data.dataResponse);
                        if(configBaseData!==null) enviarIndicadorBase(nuevoIndicador.id, configBaseData);
                        if(indicadorParametros !== null) enviarIndicadorParametros(nuevoIndicador.id, indicadorParametros);
                        agregar( JsonIndicadores(respuesta.data.dataResponse) );
                        setOpenAdd( false );
                        break;
                    case 400:
                    case 404:
                    case 302:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            }) 
    }

    const enviarIndicadorBase = async (idIndicador, configBaseData) => { 
        const newData = configBaseData.basesIndicadores[0].map(element => {return {nominId: idIndicador, baseId: element.baseId, operacion: element.operacion }});
        
        await axios.post( `${servicios_grupogack_nomina}/indicador/bases/`, {indicadoresBase:newData}, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(registro_no_guardado+" en Configuración Base");
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    const enviarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {nomindIndicador:indicadorParametros.nomindId , nomindId: idIndicador, zonaId: indicadorParametros.zonaId, topVeces: indicadorParametros.topVeces };
        
        await axios.post( `${servicios_grupogack_nomina}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(registro_no_guardado+" en Parametros Indicadores");
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
        
    }

    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    alert('error');
                    break;
            } 
        })
        .catch(error => alert(error));

        axios.get(`${servicios_grupogack_catalogo}/indicador/getall/relab`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse);
                    setIndicadoresGenerales(indicadores);
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

    },[]); 
    
    return (
        <ModalService title="Nuevo Concepto por Nómina" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioIndicadores
                    error={error}
                    informacion={informacionAdicional}
                    enviarIndicador={enviarIndicador}
                    idModulo={nombreModulo}
                    informacionAdicional={salarioMinimos}
                    indicadoresGeneral={indicadoresGenerales}
                />
        </ModalService>
    );
};

export default ModalAddIndicadores;
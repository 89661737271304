import React, { Component, Fragment } from 'react';
import Modal from "react-responsive-modal";

import './ModalResponsive.css';

class ModalService extends Component {
    agregar = () => {
        document.getElementById( 'html' ).style['position'] = "fixed";
        document.getElementById( 'html' ).style['overflow'] = "hidden";
    }
    eliminar = () => {
        document.getElementById( 'html' ).style['position'] = "";
        document.getElementById( 'html' ).style['overflow'] = "";
    }
    render() {
        const { children, title, subtitle, labelSubtitle, parentNode, isOpen, onClose, minWidth, maxWidth, withoutFocusTrapped } = this.props;
        return (
            <Modal open={ isOpen } onClose={ onClose }
                styles={{
                    modal: {
                        minWidth: minWidth ? minWidth : '45%',
                        padding:'1.5rem',
                        maxWidth: maxWidth ? maxWidth : '95%'
                    }
                }}
                center
                focusTrapped={(withoutFocusTrapped) ? false : true}
                closeOnOverlayClick={false}
                showCloseIcon={false} container={ document.getElementById( parentNode ) }
                onEntered={ () => this.agregar() }
                onExited={ () => this.eliminar() }
            >
                <Fragment>
                    <h4 style={ { paddingRight: '30spx', fontWeight: 'bold' } }> { title } </h4>
                    <button style={ { position: 'absolute', right: '0', top: '0', padding: '1.5rem', display: title ? 'block' : 'none' } }
                        type="button" className="close" aria-label="Close" onClick={ onClose }>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    {   (subtitle && labelSubtitle) &&
                        <h6 style={ { paddingRight: '30spx', color: '#5E5E5E' } }>
                            {   labelSubtitle &&
                                <span style={ { fontWeight: 'bold' } }>
                                    { `${labelSubtitle} ` }
                                </span>
                            }
                            {   subtitle &&
                                <span>
                                    { subtitle }
                                </span>
                            }
                        </h6>
                    }
                    {   (subtitle && !labelSubtitle) &&
                        subtitle
                    }
                    {   title &&
                        <hr/>
                    }
                </Fragment>
                { children }
            </Modal>
        );
    }
}

export default ModalService;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioTiposJornada = ({ error, enviarTipoJornada, elementSelect, actualizarTipoJornada }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const nombreRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarTipoJornada' ) ){
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value
            enviarTipoJornada( { clave, nombre } );
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarTipoJornada' ) ){
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value
            actualizarTipoJornada({ clave, nombre }, elementSelect.id);
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarTipoJornada' : 'AgregarTipoJornada' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveJornada" placeholder="Escriba la clave del tipo de Jornada"
                    tooltipText={'Máximo 2 letras y/o números'} tooltipName={'tooltipCveTipJor'} maxLength='2' alfanumeric
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.clave : '' } required/>
                <InputValidation title="Nombre" type="nombreJornada" placeholder="Escriba el nombre del tipo de jornada" maxLength='64'
                    tooltipText={'Máximo 64 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomTipAus'}
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.nombre : '' } required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioTiposJornada;
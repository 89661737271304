import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
//import AlertForm from '../../../../../../Services/AlertForm';
import FormularioParentesco from './FormularioParentesco';

const ModalAddParentesco = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarParentesco = ( parentesco ) => {
        axios.post( `${servicios_grupogack_catalogo}/tipoparentesco/`, parentesco, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Nuevo Parentesco" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioParentesco
                    error={error}
                    enviarParentesco={enviarParentesco}
                />
        </ModalService>
    );
};

export default ModalAddParentesco;
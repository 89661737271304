import React, { useState, useEffect, useRef } from 'react'; 

import Axios from 'axios';
import { servicios_grupogack_catalogo, servicios_grupogack, cambios_no_guardados, campos_vacios, registro_sera_eliminado, registro_guardado} from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import {  Delete} from '@material-ui/icons';
import {IconButton} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import '../../../../../../Components/Usuarios/Usuario/DataTableService/StyleDataTable.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';  
import { obtenerModalDelete } from '../../../../../../Components/Usuarios/Usuario/DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import { amountToFloat, floatToAmount, MONTO} from '../../../../../../Services/Validation/HelperValidation';

const DinamicTableQuinquenios = ({niveles, tabuladoresBack, idTab, fechainic, onShowTable, idModulo, permisos}) => {

    const permisosUsuario = permisos.map(element=> element.id);
    const [columnHeaderShow, setColumnHeaderShow] =useState(["Nivel"]);
    const columnHeader =["nivlaboralClave"];
    const [columnsTable, setColumnsTable] =  useState(columnHeader);
    const [antiguedades, setAntiguedades] = useState(null);
    const [antiguedadesBack, setAntiguedadesBack] = useState(null);
    const [antiguedad, setAntiguedad]= useState('');
    const [idAntiguedad, setIdAntiguedad]= useState('');  
    var indexTab=tabuladoresBack.findIndex(col=> col.id===idTab);
    const [columnasAgregadas, setColumnsAgregadas]=useState([]);
    

    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ alertWarningLocal, setAlertWarningLocal ] = useState( false );
    
    const [ deleteColumn, setDeleteColumn ] = useState( false );
    const [ shouldUpdate, setShouldUpdate ] = useState( false );
    const [ shouldInsert, setShouldInsert ] = useState( false );
    const [ loading, setLoading ] = useState( false );
    const [data, setData] =useState([]);
    const [headerTab, setHeaderTab]= useState(false);
    const fechainiRef = useRef(fechainic);
    
    var col=columnsTable;
    var colName=columnHeaderShow;
    const [ dta, setDta]= useState(data);
    const [ dtaBack, setDtaBack]= useState(data);
    const [idTabTemp, setIdTabTemp ] = useState( null );

    
    useEffect(() => { 
        if(idTab){
            setHeaderTab(tabuladoresBack[indexTab].fecFin !=='-');
            setData([]); 
            Axios.get(`${servicios_grupogack}/org/quinquenios/`, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        fechainiRef.current=res.data.dataResponse[0].fecIni;                   
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert(error));
                Axios.get(`${servicios_grupogack}/org/quinquenios/detalle/${idTab}`, headersAuth()) 
                .then(res => {   
                    switch(res.data.status) {
                        case 200:
                           var response=res.data.dataResponse;
                           for(let i=0; i<response.length; i++){
                                data.push({nivlaboralClave: response[i].nivlaboralClave, nivlId:response[i].nivlaboral})
                                response[i].antigs = response[i].antigs.sort((a, b) => (a.id > b.id) ? 1 : -1);
                                for(var j=0; j<response[i].antigs.length; j++){
                                    var ind=col.indexOf(response[i].antigs[j].id);
                                    if(ind ===-1){
                                        data[i][response[i].antigs[j].id]=floatToAmount(response[i].antigs[j].monto,2);
                                        col.push(response[i].antigs[j].id);
                                        colName.push(`${response[i].antigs[j].id}° Quinquenio (MXN)` );
                                    }else{
                                        if(data[i][response[i].antigs[j].id]>0){
                                            data[i][response[i].antigs[j].id]+=floatToAmount(response[i].antigs[j].monto,2);
                                        }else{
                                            data[i][response[i].antigs[j].id]=floatToAmount(response[i].antigs[j].monto,2);
                                        }
                                    }
                                } 
                            } 
                            antiguedadData(); 
                            completeColumns(col, colName); 
                            break;
                        case 404:
                            for(const index in niveles){
                                dta.push({nivlaboralClave:niveles[index].nivlaboralClave, nivlId:niveles[index].id });
                            }
                            antiguedadData();
                            break;
                        default:
                            alert('error');
                            break;
                    }
                })
                .catch(error => alert(error)); 
        }else{ 
            for(const index in niveles){
                dta.push({nivlaboralClave:niveles[index].nivlaboralClave, nivlId:niveles[index].id });
            }
            antiguedadData();
            setShouldInsert(true);
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    async function antiguedadData(){
        await Axios.get(`${servicios_grupogack_catalogo}/antiguedad/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    var response=res.data.dataResponse;
                    for(let i in columnsTable){
                        if(columnsTable[i]!=="nivlaboralClave"){ 
                            var a= response.filter(col=>col.id!==columnsTable[i]); 
                            response=a;
                        }
                    }                  
                    setAntiguedades(response);
                    setAntiguedadesBack(res.data.dataResponse);                     
                    break;
                case 404:
                    setAntiguedades([]);
                    setAntiguedadesBack([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error)); 
    }

    const completeColumns=(col, colName)=>{ 
        
        for (let j = 0; j < data.length; j++) {
            var dataArr= Object.keys(data[j]);
            for (let i = 0; i < columnsTable.length; i++) {
                var find=dataArr.find(col=> col ===columnsTable[i].toString());
                if(find===undefined){
                    data[j][columnsTable[i]]=floatToAmount( 0, 2 ); 
                }
            }
        } 
        setDta(data);
        setDtaBack(data);
        setColumnHeaderShow(colName);
        setColumnsTable(col);
        
    }; 
    

    const replicateData =()=>{
        var key=elemmentSelect;
        var obj=dta; 
        var newValue=isNaN(parseFloat(obj[0][key])) ? obj[0][key] : floatToAmount(obj[0][key], 2) ; 
        if(MONTO.test(newValue) && !/\$0.00/g.test(newValue)){ 
            for(var i=0; i<obj.length; i++){
                obj[i][key]=newValue;
                var name=`${i}-${key}`;
                var input = document.getElementsByName(name);
                if(input[0]!==undefined){
                    input[0].value = newValue;
                    input[0].className="inpTable minIn";
                }
            }
        }

        setDta(obj);
        setColumnHeaderShow(colName);
        setColumnsTable(col);
        if(!shouldInsert){
            setShouldUpdate(true);
        }
    }

   const alertReplicateData=(key)=>{
        setElemmentSelect(key);
        setTextAlert("¡Se aplicará este importe a todos los campos!");
        setAlertWarningLocal(true);
    }

    const del=(n)=>{ 
            const index = columnsTable.indexOf(n)
            const newColumnShow = columnHeaderShow.filter(col  => col !== columnHeaderShow[index]);
            setColumnHeaderShow(newColumnShow);

            const newColumn = columnsTable.filter(col  => col !== n);
            setColumnsTable(newColumn);
            
            const newAntiguedades = Object.assign({}, antiguedadesBack.filter(ant  => ant.id === parseInt(n)));
            setAntiguedades( antiguedades => [newAntiguedades[0], ...antiguedades ]);

            setColumnsAgregadas(columnasAgregadas.filter(col=>col!==parseInt(n)));

            for(var i=0; i<dta.length; i++){
                delete dta[i][n];
            }
            for (let j = 0; j < dtaBack.length; j++) {
                delete dtaBack[j][n];
            }
            setDta(dta);
            setDtaBack(dtaBack);
            if(idTab){
                setShouldUpdate(true);
            }
            if(!shouldInsert){
                setShouldUpdate(true);
            }
            
    }

    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    } 

    async function updateDta2(newData){
        setDta(newData);
        return;
    };

    async function refreshData2(newData){
        await updateDta2(newData);
        refresh2(newData);
    };

    const refresh2=(newData)=>{
        var inputClass;
        newData.map((key, index)=>{
            const llave=Object.keys(key);
            for (const i in llave) {
                if(llave[i]!== columnHeader[0] && llave[i] !== 'nivlId'){
                    var name=`${index}-${llave[i]}`;
                    var input = document.getElementsByName(name);
                    if(input[0]!==undefined){
                        input[0].value= newData[index][llave[i]]==='' ? '': MONTO.test( newData[index][llave[i]] ) && isNaN(newData[index][llave[i]]) ? newData[index][llave[i]] : floatToAmount( newData[index][llave[i]], 2);
                        if(!MONTO.test(input[0].value) || /\$0.00/g.test(input[0].value)){ 
                            inputClass="inpTable minIn is-invalid";
                        }else{
                            inputClass="inpTable minIn";
                        }
                        input[0].className=inputClass;
                        }
                }
            } 
            return null;  
        })  
    }
    
     const generateHeader=() =>{        
        return columnHeaderShow.map((key, index)=>{
         
            if(index===0 || headerTab)  return <th key={index} className="MuiTableCell-root negritas" style={{textAlign: index===0 ? 'left':'right'}}>{key}</th>
              
             return <th key={index} id={`${key}`} className="MuiTableCell-root negritas">
                         {key}{permisosUsuario.includes(3) && <IconButton onClick={e => alertDelete(columnsTable[index])} ><Delete fontSize="small"/></IconButton>}</th>
             })
     }
    
     const updateData=(key, value, index)=>{
        dta[index][key]= value==='' ? 0 :amountToFloat(value); 
        if(idTab){
            setShouldUpdate(true);
        }
        if(!shouldInsert){
            setShouldUpdate(true);
        }       
     }


     const validNumber=(index, value, e)=>{
         
        if(isNaN(amountToFloat(value)) || value<1 || /\$0.00/g.test(value)){
            e.target.className="inpTable minIn is-invalid";
            e.target.value= floatToAmount(0, 2);
        }else{
            e.target.className="inpTable minIn";
            e.target.value= floatToAmount(amountToFloat(value), 2);
        }
     }

     const keysAllowed = (e) =>{
        return e.keyCode!==8 && e.keyCode!==9 && e.keyCode!==46 && !(e.keyCode >= 37 && e.keyCode <= 40 ) && !((e.keyCode === 65 || e.keyCode === 67) && (e.ctrlKey || e.metaKey ));
    }

     const validarInput=e=>{
        
        const number = /^[0-9]$/; 
        if( !number.test(e.key) && keysAllowed(e) && (e.key!=='.' || e.target.value.includes('.')) ){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
    }

      const generateTableData=()=>{
        
         let res=[];
         for(let i =0; i < dtaBack.length; i++){
            res.push(
               <tr key={`${columnsTable[i]}-${i}`} className="MuiTableRow-root">
                  {
                    columnsTable.map((key, index)=>{
                        if(index===0 || headerTab) return <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={{textAlign: index===0 ? 'left':'right'}}>{dta[i][key]}</td>

                        if(index>=1 && i===0 )
                           return   <td key={`${key}${index}${i}`} className={`MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min`}>
                                         <input type="text" id={i} onBlur={e=> {validNumber(e.target.id, e.target.value, e);}} name={`${i}-${key}`}
                                            onKeyDown = { e => { validarInput(e) } } style={{textAlign:'right'}}
                                            className={`inpTable minIn`}  defaultValue={`${dta[i][key]}`} onChange={e =>{updateData(key, e.target.value,  e.target.id );
                                         }} />
                                         <IconButton onClick={()=>alertReplicateData(key)} >
                                            <Tooltip title="Aplicar mismo monto" placement="left-start" >
                                                <ArrowDropDownIcon fontSize="small"/>
                                            </Tooltip>
                                        </IconButton>
                                         
                                    </td>
                        return  <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                    <input type="text" name={`${i}-${key}`} onBlur={e=> {validNumber(e.target.id, e.target.value, e)}} 
                                        onKeyDown = { e => { validarInput(e) } } className={`inpTable minIn`} style={{textAlign:'right'}}
                                        defaultValue={dta[i][key]} id={i} onChange={e =>updateData(key, e.target.value, e.target.id )} 
                                    />
                                </td>
                    })
                  } 
               </tr>
            )
         }
         return res;
     }
     const alertDelete=(n)=>{        
        setElemmentSelect(n);
        
        if(idTab && !columnasAgregadas.includes(parseInt(n))){
            setAlertWarning( true );
        }else{
            setTextAlert(registro_sera_eliminado);
            setDeleteColumn(true);
            setAlertWarningLocal(true);
        }
     }

     const delColumn=()=> {
        setAlertSuccess(true);
        setTextAlert('Registro Eliminado');
        del(elemmentSelect);
        setDeleteColumn(false);
      }

     const addData = () =>{
         for(let i=0; i<dta.length; i++){
            var dat=dta;
            dat[i][idAntiguedad] = floatToAmount(0, 2);
            setDta(dat);
         }
         for(let j=0; j<dtaBack.length; j++){
            var dataBack=dtaBack;
            dataBack[j][idAntiguedad] = floatToAmount(0, 2);
            setDtaBack(dataBack);
         }
         setColumnsAgregadas(columnasAgregadas=>[...columnasAgregadas, parseInt(idAntiguedad)]);
         
         setColumnsTable( columnsTable => [...columnsTable, idAntiguedad]);
         setColumnHeaderShow( columnHeaderShow => [...columnHeaderShow, antiguedad+" (MXN)"]);

         const newAntiguedades = antiguedades.filter(ant  => ant.id !== parseInt(idAntiguedad));
         setAntiguedades(newAntiguedades); 
         setAntiguedad('');
         setIdAntiguedad('');
         if(!shouldInsert){
            setShouldUpdate(true);
        }
     }

     const table=()=>{
         return  <div><table style={{overflowX: "scroll"}} className="MuiTable-root">
            <thead className="MuiTableHead-root">
                <tr className="MuiTableRow-root MuiTableRow-head">
                {generateHeader()}
                </tr>
            </thead>
            {<tbody className="MuiTableBody-root">
                {generateTableData()}
                    {dta.length<1  
                    ?
                        <tr style={{textAlign: "center", height: "245px"}}>
                            <td colSpan={3}><SpinnerOval/></td>
                        </tr>
                    :   null
                    }
            </tbody>}
     </table></div> ;
     }
     
     const addAntiguedad=(e)=>{
        setAntiguedad(e.target.value); 
        setIdAntiguedad(e.target[e.target.selectedIndex].id);
        
        
     } 

     const saveInfo= () =>{
        setLoading(true);
        var saveDta=dta;
        var quinquenios=[];
        var montos=true;
        var numQuinquenios=0;
        dta.map((key, index) => {
            const llave=Object.keys(key)
            
            for (const i in llave) {
                if(llave[i]!=='nivlId' && llave[i]!=='nivlaboralClave'){ 
                    if(!MONTO.test(key[llave[i]]) || /\$0.00/g.test(key[llave[i]]) || key[llave[i]]<1){ 
                        montos = false;
                    }
                    quinquenios.push({"antiId":parseInt(llave[i]), "nivlId":key.nivlId, "monto": isNaN(key[llave[i]]) ? amountToFloat(key[llave[i]]) : key[llave[i]]});
                    numQuinquenios++;
                } 
            }    
            return null;
        });

        var request={"fechaInicial":fechainiRef.current,"quinquenios":quinquenios};
        if( montos && numQuinquenios>0){
            
            if(idTab && shouldUpdate){
                updateDataBack(request);
            }else{
                if(shouldInsert){
                    insertData(request);
                }else if(shouldUpdate){
                    updateDataBack(request);
                }else{
                    setTextAlert("¡No hay cambios por guardar!");
                    setAlertSuccess(true);
                    setLoading(false);
                }
            }
        }else{
            if(numQuinquenios<1){
                setLoading(false); 
                setTextAlert('Debe agregar al menos una antigüedad');
                setAlertError(true); 
            }
            if(!montos){
                setLoading(false);
                refreshData2(saveDta);
                setTextAlert(campos_vacios)
                setAlertError(true);  
            }
        }
     }
     
     const updateDataBack =(request)=>{
        Axios.put(`${servicios_grupogack}/org/quinquenios/`,request, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setLoading(false);
                    setTextAlert('Registro Actualizado');
                    setAlertSuccess(true);
                    setColumnsAgregadas([]);
                    setShouldUpdate(false);
                    break;
                case 404:
                    setLoading(false);
                    setAlertError(true);
                    break;
                case 400:
                    setLoading(false);
                    setTextAlert(res.data.msg);
                    setAlertError(true);
                    break;
                default:
                    alert('error');
                    setLoading(false);
                    setTextAlert(cambios_no_guardados);
                    setAlertError(true);
                    break;
            }
        }).catch(error => {setLoading(false); alert(error)});
     }

     const insertData=(request)=>{

        Axios.post(`${servicios_grupogack}/org/quinquenios/`,request, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        setLoading(false);
                        setTextAlert(registro_guardado);
                        getPeriodos();
                        setAlertSuccess(true);
                        setShouldInsert(false);
                        setColumnsAgregadas([]);
                        break;
                    case 404:
                        setLoading(false);
                        setAlertError(true);
                        break;
                    case 400:
                        setLoading(false);
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                    default:
                        alert('error');
                        setLoading(false);
                        setTextAlert(cambios_no_guardados);
                        setAlertError(true);
                        break;
                }
            }).catch(error => {setLoading(false); alert(error)}); 

     }

     const getPeriodos = ()=>{
        Axios.get(`${servicios_grupogack}/org/quinquenios/`, headersAuth())
            .then(res => {            
            switch(res.data.status) {
                case 200:
                    setIdTabTemp(res.data.dataResponse[0].id);
                    onShowTable(true, res.data.dataResponse[0].id, null, fechainic);
                    break;
                case 404: 
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    }

     const verificarSalida = ()=>{
        if(idTab || idTabTemp){
            Axios.get(`${servicios_grupogack}/org/quinquenios/detalle/${idTab ? idTab : idTabTemp}`, headersAuth())
            .then((respuesta) => {   
                switch(respuesta.data.status) {
                    case 200:                 
                            onShowTable(false);
                        break;
                    case 404:
                        setTextAlert('Debe agregar y guardar al menos una antiüedad');
                        setAlertError(true); 
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert(error));
        }else{
            onShowTable(false);
        }
    } 

   return (
       <div id={idModulo} className="back MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded">
            {
                headerTab
                ?
                    <div className="row justify-content-between">
                        <div className="col-md-10 col-sm-12 ml-4" style={{padding:'1rem'}}>
                            <div><b>{`Fecha Inicio: `}</b> <span>{`${tabuladoresBack[indexTab].fecIni} - `}</span> <b>{`Fecha Final: `}</b> <span>{tabuladoresBack[indexTab].fecFin}</span></div>
                        </div>
                        <div className="col-1">
                            <button className="form-control" onClick={()=>onShowTable(false)}> 
                                <Tooltip title="Regresar a Tabuladores" placement="left-start">
                                    <ArrowBackIcon/>
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                :
                <div className="row justify-content-between titleDinamicTable">
                    <div className="col-auto mr-auto ">
                        <label>Agregar Antigüedad</label>
                        <div className="form-inline" >
                             <select  className="form-control" value={ antiguedad } onChange={e=> addAntiguedad(e) }>
                                <optgroup label="Agregar Antigüedad">
                                        <option value="">- Seleccione -</option>
                                        {
                                            antiguedades
                                            ?
                                            antiguedades.map((antiguedad, index) => (     
                                                <option key={Math.random() * (500 - 1) + 1} value={`${antiguedad.id}° Quinquenio`} id={antiguedad.id}>
                                                    {`${antiguedad.id}° Quinquenio`}
                                                </option>
                                            ))
                                            :null
                                        }
                                </optgroup>
                            </select>
                            <button  onClick={()=> antiguedad!=='' ? addData():null}  className="form-control btn-third" style={{marginLeft: "10px"}}>Agregar</button> 
                        </div>
                    </div>
                    <div className="col-auto form-inline">
                        
                        {   loading
                            ? 
                                <SpinnerOval/>
                            :
                            <button className="form-control btn-color" onClick={()=>{saveInfo(); }}  style={{marginRight: "10px"}}> 
                                <DoneIcon/>
                            </button>
                        }
                        <button className="form-control btn-outline" onClick={()=>verificarSalida()}  > 
                                <CloseIcon/>
                        </button>
                    </div> 
                </div> 
            }         
           {
               <div style={{overflowX: "scroll"}} className="ContenedorDataTable">
                  {table()}
               </div>
           }
           {obtenerModalDelete( idModulo, alertWarning, setAlertWarning, elemmentSelect, null, delColumn, errorTable , null )}
           <Alertwarning show={alertWarningLocal} SetopenALert={setAlertWarningLocal} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={ deleteColumn ? delColumn : replicateData} />
           <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
       </div>

   );
}
 
export default DinamicTableQuinquenios;
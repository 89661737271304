import { makeStyles } from '@material-ui/core/styles';
import Image from '../../../../Media/Img/mn.jpg';

const drawerWidth = 240;
const useStylesMenu = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& label.Mui-focused': {
      color: '#1572E8',
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: '#697B9F',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1572E8',
      },
    },
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth,
    background: 'white',
    color: 'black'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundImage: `url(${Image})`,
    //background: '#0F0F0F',
    color: 'white',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 100,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  imageLogo: {
    marginTop: 10,
    maxWidth: '100%',
    height: 'auto',
  },
  color: {
    color: 'white',
  }, 
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  toolbar: theme.mixins.toolbar,
  appModal: {
    flexGrow: 100,
    padding: theme.spacing(2.5),
  },
  contentModal: {
    flexGrow: 100,
    padding: theme.spacing(2.5),
    minHeight: '650px',
  },
  notificationColor: {
    backgroundColor: "#F25A5A",
    color: "white"
  },  
}));


export default useStylesMenu;
import React, { Component } from 'react'
import axios from 'axios';
import { servicios_grupogack_url,servicios_grupogack } from '../../../../Constants/Constants';
import { cerrarSession, headersAuth, obtenerUsuario, refreshToken } from '../../../../Services/AuthService';
/* -----------------------------------------------------------------------------------------------------  */
//import modulosJSON from '../Datos/Modulos.json';
/* -----------------------------------------------------------------------------------------------------  */

export default function withDataFetching(ComponenteDrawer) {
    return class extends Component {
        
        constructor(props) {
            super(props)
            this.state = { 
                modulos: [],
                openContrasena: false,
                newTokenRefresh: null
            }
        }

        refreshTime = 300000;
        nextCycle = new Date( Date.now() + this.refreshTime );
        idRefresh = null;

        events = [ 'load', 'mousemove', 'mousedown', 'click', 'scroll', 'keypress' ];
        inactivityTime = 600000;
        idInactivity = null;
    /* -------------------------------------------------------------------------------------------------------------------------- */
        activarEventosInactividad = ( value ) => {
            if( value ){
                this.events.forEach( evnt => window.addEventListener( evnt, this.resetTimeout) );
            } else {
                this.events.forEach( evnt => window.removeEventListener( evnt, this.resetTimeout) );
            }
        }
    /* -------------------------------------------------------------------------------------------------------------------------- */
        setModalContraseña = ( value ) => {
            this.setState( { openContrasena: value } )
        };
    /* -------------------------------------------------------------------------------------------------------------------------- */
        componentDidMount() {
            /* ------------------------------------- Servicios ------------------------------------- */
            this.auth2();
            /* ------------------------------- Actualizacion de token ------------------------------ */
            
            this.idRefresh = setInterval( () => {
                this.intervaloSession();
                this.nextCycle =  new Date( Date.now() + this.refreshTime );
            }, this.refreshTime );
            
            /* ---------------------- Define el estado de revalidacion Inicial --------------------- */
            const revalidate = JSON.parse( localStorage.getItem("revalidateSession") );
            if( revalidate && revalidate.revalidate === true ) {
                this.setModalContraseña( true );
                this.clearTimeoutFunc();
                this.activarEventosInactividad( false );
            }else{
            /* -------------------- Eventos para detectar estado de Inactividad -------------------- */
                this.setModalContraseña( false );
                this.setTimeout();
                this.activarEventosInactividad( true );
            }
        }
    /* -------------------------------------------------------------------------------------------------------------------------- */
        componentWillUnmount() {
            this.cleanIntervals();
            this.setModalContraseña( false );
            this.activarEventosInactividad( false );
        }
    /* -------------------------------------------------------------------------------------------------------------------------- */
        clearTimeoutFunc = () => {
            if (this.idInactivity){ clearTimeout(this.idInactivity); }
        };

        setTimeout = () => {
            this.idInactivity = setTimeout(this.activarModal, this.inactivityTime);
        };

        resetTimeout = () => {
            this.clearTimeoutFunc();
            this.setTimeout();
        };

        activarModal = () => {
            localStorage.setItem("revalidateSession", JSON.stringify( { revalidate: true } ));

            this.setModalContraseña( true );
            this.clearTimeoutFunc();
            this.activarEventosInactividad( false );
        };
    /* -------------------------------------------- Servicio de Revalidacion de Token ------------------------------------------- */
        intervaloSession = () => {
            const usuario = obtenerUsuario();
            axios.get( `${servicios_grupogack}/account/reset/token/${usuario.token}` )
                .then( res => {
                    const newToken = res.data.dataResponse.token;
                    if( newToken ){
                        this.setState({ newTokenRefresh: newToken });
                        refreshToken( newToken );
                    }
                })
                .catch( error => {
                    axios.post( `${servicios_grupogack_url}/account/logout`, null, headersAuth() );
                    cerrarSession();
                });
        }

        cleanIntervals = () => {
            clearInterval( this.idRefresh );
            clearTimeout( this.idInactivity );
        }
    /* -------------------------------------------- Llamado de Servicios Principales -------------------------------------------- */
        auth2 = () => {
                axios.get( `${servicios_grupogack_url}/menu`, headersAuth() )
                .then( res => {
                    this.setState({ modulos: res.data.dataResponse.menu });
                } )
                .catch( error => {
                    axios.post( `${servicios_grupogack_url}/account/logout`, null, headersAuth() );
                    cerrarSession();
                } );
        }
    /* -------------------------------------------------------------------------------------------------------------------------- */
        render() {
            const { openContrasena, newTokenRefresh } = this.state;
            /* ------------------- Actualizacion de la pagina y/o cierre de la pagina ------------------ */
            window.addEventListener("beforeunload", () => {
                if( window.location.pathname === "/usuario" ){
                    if( openContrasena === true ){
                        localStorage.setItem("revalidateSession", JSON.stringify( { revalidate: true } ));
                    }else{
                        localStorage.setItem("revalidateSession", JSON.stringify( { revalidate: false } ));
                    }
                }
            });

            return (
                <ComponenteDrawer modulosApi={this.state.modulos} cleanIntervals={this.cleanIntervals} newToken={newTokenRefresh} {...this.props}
                    openContrasena={openContrasena} setModalContraseña={this.setModalContraseña} setTimeout={this.setTimeout} activarEventosInactividad={this.activarEventosInactividad}/>
            )
        }
    }
}
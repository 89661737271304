import React from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, servicios_grupogack_org } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import AlertErrorDescarga from './AlertErrorDescarga';

export const modulosDeCarga = [
    {
        icono: "dehaze",
        id: 21,
        nombre: "Proyectos",
        superior: 18
    },
    {
        icono: "people",
        id: 30,
        nombre: "Empleados",
        superior: 29,
    },
    {
        icono: "playlist_add",
        id: 31,
        nombre: "Ausentismos",
        superior: 29,
    },
    {
        icono: "view-list",
        id: 25,
        nombre: "Tabuladores de Sueldo",
        superior: 22,
    },
    {
        icono: "scatter_plot",
        id: 27,
        nombre: "Tabuladores de Quinquenios",
        superior: 22,
    },
    {
        icono: "apps",
        id: 76,
        nombre: "Acumulados",
        superior: 35,
    }
];

/* Funcion que permitira determinar si un modulo existe en los modulos obtenidos por esl servicio de menu */
export function obtenerAccesos( menu, modulo ) {
    const superior = menu.find( moduloSuperior => moduloSuperior === modulo.superior );
    console.log( superior );
}


/* ---------------------- Funciones para obtener informacion necesaria para cada carga de archivos ---------------------- */
export function obtenerInformacionExcel( idModulo, setInformacion ){
    switch( idModulo ) {
        case 21:
            console.log( 'Proyectos' );
            break;
        case 30:
            console.log( 'Empleados' );
            break;
        case 31:
            console.log( 'Ausentismos' );
            break;
        case 25:
            console.log( 'Tabuladores Sueldos' );
            axiosTabuladoresSueldo( setInformacion );
            break;
        case 27:
            console.log( 'Quinquenios' );
            axiosTabuladoresQuinquenios( setInformacion );
            break;
        case 76:
            console.log( 'Acumulados' );
            break;

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

/* --- Funciones para obtener fomratoExcel de Tabuladores de Sueldo */
const axiosTabuladoresSueldo = ( setInformacion ) => {
    axios.get( `${servicios_grupogack_catalogo}/nvlaboral/byrelab/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacion({
                        informacion: formatoTabuladoresSueldo(respuesta.data.dataResponse),
                        nombreArchivo: 'Carga_tabuladores',
                    });
                    break;
                case 404:
                    //setInformacion( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
}

const formatoTabuladoresSueldo = ( tabuladoresSueldo ) =>  {
    return tabuladoresSueldo.map( item => ({
        'Clave del indicador / Nivel': (item.nivlaboralClave !== null) ? item.nivlaboralClave : '',
    }));
}

/* --- Funciones para obtener fomratoExcel de Tabuladores de Quinquenios */
const axiosTabuladoresQuinquenios = ( setInformacion ) => {
    axios.get( `${servicios_grupogack_catalogo}/nvlaboral/byrelab/`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setInformacion({
                        informacion: formatoTabuladoresQuinquenios(respuesta.data.dataResponse),
                        nombreArchivo: 'Tabulador_quinquenios',
                    });
                    break;
                case 404:
                    //setInformacion( [] );
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
}

const formatoTabuladoresQuinquenios = ( tabuladoresSueldo ) =>  {
    return tabuladoresSueldo.map( item => ({
        'Nivel': (item.nivlaboralClave !== null) ? item.nivlaboralClave : '',
    }));
}

/* -------------------------------- Funciones para carga de archivos & axios a servicios -------------------------------- */
export function axiosExcel( idModulo, archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert ){
    switch( idModulo ) {
        case 21:
            console.log( 'Proyectos' );
            break;
        case 30:
            console.log( 'Empleados' );
            break;
        case 31:
            console.log( 'Ausentismos' );
            break;
        case 25:
            console.log( 'Tabuladores Sueldos' );
            axiosExcelTabuladores( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert );
            break;
        case 27:
            console.log( 'Quinquenios' );
            axiosExcelTabQuinquenios( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert );
            break;
        case 76:
            console.log( 'Acumulados' );
            break;

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const axiosExcelTabuladores = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert ) => {
    axios.post( `${servicios_grupogack_org}/tab/masivos/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_tabuladores" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( 'Error al cargar archivo' );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
}

const axiosExcelTabQuinquenios = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert ) => {
    axios.post( `${servicios_grupogack_org}/quinquenios/masivos/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successAlert( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400:
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_quinquenios" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorAlert( 'Error al cargar archivo' );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorAlert( 'Error al cargar archivo' );
        })
}
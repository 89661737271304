import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { servicios_grupogack_catalogo, servicios_grupogack_rh, registro_actualizado, cambios_no_guardados, registro_sera_actualizado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import InfoNumerosEmergencia from './InfoNumerosEmergencia';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';

const FormNúmerosEmergencia = ({ elementSelect, setError, agregarNumEmerEdit, actualizarNumEmer, enviar, irInfo, nombreNumEmergenciaRef, pApellidoNumEmergenciaRef, sApellidoNumEmergenciaRef, telFijoNumEmergenciaRef, telMovilNumEmergenciaRef, tiposParentesco, parentescoNumEmergenciaRef, calleNumEmergenciaRef, numExteriorNumEmergenciaRef, numInteriorNumEmergenciaRef, cpNumEmergenciaRef, coloniaNumEmergenciaRef, ciudadNumEmergenciaRef, estadoNumEmergenciaRef, llamadasEmergenciaSaved, setLlamadasEmergenciaSaved, llamadasEmergenciaSavedEdit, setLlamadasEmergenciaSavedEdit, elementoSeleccionado, setElementoSeleccionado, mostrarFormAddEdit, setMostrarFormAddEdit, successTable, errorTable }) => {
    
    const [colonias, setColonias] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);

    const guardar = e => {
        e.preventDefault();
        enviar();
    };

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const abrirFormAddEdit = e => {
        e.preventDefault();
        setMostrarFormAddEdit(true);
    };

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupogack_rh}/empleado/llamada/emergencia/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const info = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                nombre: res.nombre,
                                apellidoPat: res.apellidoPat,
                                apellidoMat: res.apellidoMat,
                                telefonoFijo: res.telefonoFijo,
                                telefonoCelular: res.telefonoCelular,
                                parentesco: res.parentesco.id,
                                parentescoNom: res.parentesco.parentescoNom,
                                calle: res.calle,
                                numeroExt: (res.numeroExt) ? res.numeroExt : '',
                                numeroInt: (res.numeroInt) ? res.numeroInt : '',
                                cp: res.cp.codigopCp,
                                numEmerColonia: res.cp.id,
                                coloniaNom: res.cp.codigopColonia,
                                coloniaId: res.cp.id,
                                numEmerCiudad: res.cp.ciudadId.ciudadNom,
                                numEmerEstado: res.cp.ciudadId.estadoId.estadoNom
                            }
                        ));
                        setLlamadasEmergenciaSaved(info);
                        break;
                    case 404:
                        setLlamadasEmergenciaSaved([]);
                        break;
                    default:
                        alert('No se pudo obtener la información. Intente de nuevo.');
                        break;
                }
            })
            .catch(error => { alert('No se pudo obtener la información. Intente de nuevo.'); console.log(error) });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eliminarLlamEmer = item => {
        axios.delete(`${servicios_grupogack_rh}/empleado/llamada/emergencia/${item.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setLlamadasEmergenciaSaved(llamadasEmergenciaSaved.filter(llEme => llEme.id !== item.id));
                    successTable(registro_actualizado);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => { errorTable(cambios_no_guardados); console.log(error) });
    };

    const guardaNumero = (e, type) => {
        e.preventDefault();

        let lengthLlamEmer = 0;
        let lengthLlamEmerS = 0;

        if(llamadasEmergenciaSaved.length) lengthLlamEmer = llamadasEmergenciaSaved.length;
        if(llamadasEmergenciaSavedEdit.length) lengthLlamEmerS = llamadasEmergenciaSavedEdit.length;

        const total = lengthLlamEmer + lengthLlamEmerS;

        if(total < 3) {
            setError(null);
            if(validarSubmit('FormNumerosEmergencia')) {
                const nombreParentesco = tiposParentesco.find(p => p.id === parseInt(parentescoNumEmergenciaRef.current.value));
                const coloniaNombre = colonias.find(c => c.id === coloniaNumEmergenciaRef.current.value);
                let info = {
                    id: `${llamadasEmergenciaSaved.length}${nombreNumEmergenciaRef.current.value}${pApellidoNumEmergenciaRef.current.value}`,
                    nombre: nombreNumEmergenciaRef.current.value,
                    telefonoFijo: telFijoNumEmergenciaRef.current.value,
                    telefonoCelular: telMovilNumEmergenciaRef.current.value,
                    parentesco: parseInt(parentescoNumEmergenciaRef.current.value),
                    parentescoNom: nombreParentesco.parentescoNom,
                    calle: calleNumEmergenciaRef.current.value,
                    numeroInt: numInteriorNumEmergenciaRef.current.value,
                    cp: parseInt(cpNumEmergenciaRef.current.value),
                    numEmerColonia: parseInt(coloniaNumEmergenciaRef.current.value),
                    coloniaNom: coloniaNombre.codigopColonia,
                    coloniaId: parseInt(coloniaNumEmergenciaRef.current.value),
                    numEmerCiudad: ciudadNumEmergenciaRef.current.value,
                    numEmerEstado: estadoNumEmergenciaRef.current.value
                };
                
                if(pApellidoNumEmergenciaRef.current.value !== '') info = { ...info, apellidoPat: pApellidoNumEmergenciaRef.current.value };
                if(sApellidoNumEmergenciaRef.current.value !== '') info = { ...info, apellidoMat: sApellidoNumEmergenciaRef.current.value };
                if(numExteriorNumEmergenciaRef.current.value !== '') info = { ...info, numeroExt: numExteriorNumEmergenciaRef.current.value };
    
                if(type === 'FormAgregar') setLlamadasEmergenciaSaved([ ...llamadasEmergenciaSaved, info ]);
                if(type === 'FormEditar') {
                    info = { ...info, empleadoId: elementSelect.id }
                    setLlamadasEmergenciaSavedEdit([ ...llamadasEmergenciaSavedEdit, info ]);
                }
                
                nombreNumEmergenciaRef.current.value = '';
                pApellidoNumEmergenciaRef.current.value = '';
                sApellidoNumEmergenciaRef.current.value = '';
                telFijoNumEmergenciaRef.current.value = '';
                telMovilNumEmergenciaRef.current.value = '';
                parentescoNumEmergenciaRef.current.value = '';
                calleNumEmergenciaRef.current.value = '';
                numExteriorNumEmergenciaRef.current.value = '';
                numInteriorNumEmergenciaRef.current.value = '';
                cpNumEmergenciaRef.current.value = '';
                coloniaNumEmergenciaRef.current.value = '';
                ciudadNumEmergenciaRef.current.value = '';
                estadoNumEmergenciaRef.current.value = '';
            }
        } else {
            setError(<AlertForm message="Has llegado al límite de Contactos de Emergencia"/>);
        }
    };

    const servicioColonias = value => {
        axios.get(`${servicios_grupogack_catalogo}s/colonias/${value}`, headersAuth())
        .then(res => {
            switch(res.data.status){
                case 200:
                    const { estadoNom, ciudadNom, colonias } = res.data.dataResponse;
                    setColonias(colonias);
                    estadoNumEmergenciaRef.current.value = estadoNom;
                    ciudadNumEmergenciaRef.current.value = ciudadNom;
                    break;
                default:
                    //setError( <AlertForm message={ ocurrio_un_error }/> );
                    setColonias([{id: '', codigopColonia: 'No hay colonia'}]);
                    break;
            }
        })
        .catch(error => {
            setColonias([{id: '', codigopColonia: 'No hay colonia'}]);
        })
    };

    const buscaCP = () => {
        if(cpNumEmergenciaRef.current.value.length === 5) {
            servicioColonias(cpNumEmergenciaRef.current.value);
        } else {
            setColonias([{id: '', codigopColonia: 'No hay colonia'}]);
            estadoNumEmergenciaRef.current.value = '';
            ciudadNumEmergenciaRef.current.value = '';
        }
    };

    const limpiaSelected = e => {
        e.preventDefault();
        if(llamadasEmergenciaSavedEdit.length) setLlamadasEmergenciaSavedEdit([])
        else setElementoSeleccionado(null);
        setMostrarFormAddEdit(false);
    };

    const guardaElementoSeleccionado = elemento => {
        axios.get(`${servicios_grupogack_catalogo}s/colonias/${elemento.cp}`, headersAuth())
        .then(res => {
            switch(res.data.status){
                case 200:
                    const { colonias } = res.data.dataResponse;
                    setColonias(colonias);
                    break;
                default:
                    //setError( <AlertForm message={ ocurrio_un_error }/> );
                    setColonias([]);
                    break;
            }
        })
        .catch(error => {
            setColonias([]);
        })
        setElementoSeleccionado(elemento);
    };

    const enviaTnfo = () => {
        setError(null);
        if(!llamadasEmergenciaSavedEdit.length && !mostrarFormAddEdit) {
            actualizarNumEmer()
        } else {
            agregarNumEmerEdit();
        }
    };

    return (
        <Fragment>
            <h5>Números de Emergencia</h5>
            {
                (!elementSelect || (elementoSeleccionado === null && llamadasEmergenciaSaved.length > 0 && !mostrarFormAddEdit))
                ?  llamadasEmergenciaSaved.map((llem, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            {
                                (elementSelect)
                                ?   <Fragment>
                                        <BtnEditItem id={llem.id} idArray="id" array={llamadasEmergenciaSaved} action={guardaElementoSeleccionado} remove/>
                                        <BtnRemoveItem id={llem.id} idArray="id" array={llamadasEmergenciaSaved} action={eliminarLlamEmer} remove/>
                                    </Fragment>
                                :   <BtnRemoveItem id={llem.id} idArray="id" array={llamadasEmergenciaSaved} action={setLlamadasEmergenciaSaved}/>
                            }
                            <InfoNumerosEmergencia llem={llem}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (llamadasEmergenciaSavedEdit.length)
                ?  llamadasEmergenciaSavedEdit.map((llem, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            <BtnRemoveItem id={llem.id} idArray="id" array={llamadasEmergenciaSavedEdit} action={setLlamadasEmergenciaSavedEdit}/>
                            <InfoNumerosEmergencia llem={llem}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (!elementSelect || (elementoSeleccionado !== null && colonias.length) || llamadasEmergenciaSaved.length === 0 || (llamadasEmergenciaSaved.length > 0 && mostrarFormAddEdit))
                ?   <form id="FormNumerosEmergencia" onSubmit={(elementSelect) ? mostrarAlert : guardar}>
                        <InputValidation title="Nombre" type="nombre" placeholder="Escriba el nombre" onlyLetters=" "
                            refValue={nombreNumEmergenciaRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.nombre : ''} 
                            tooltipText="Máximo 60 letras y/o espacio" tooltipName="tooltipNomNumEmergencia" maxLength={60} required/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Primer Apellido" type="pApellido" placeholder="Escriba el primer apellido" onlyLetters=" "
                                    refValue={pApellidoNumEmergenciaRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoPat) ? elementoSeleccionado.apellidoPat : '' : ''} 
                                    tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipPApellidoNumEmergencia" maxLength={30} optional={true}/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Segundo Apellido" type="sApellido" placeholder="Escriba el segundo apellido" onlyLetters=" "
                                    refValue={sApellidoNumEmergenciaRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoMat) ?elementoSeleccionado.apellidoMat : '' : ''}
                                    tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipSApellidoNumEmergencia" maxLength={3} optional={true}/>
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Teléfono Fijo" type="telefono" placeholder="Teléfono Fijo"
                                    tooltipName="tooltipTelFijoNumEmergencia" tooltipText="10 números" maxLength={10} onlyNumbers
                                    refValue={telFijoNumEmergenciaRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.telefonoFijo : ''} required/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Teléfono Móvil" type="telefono" placeholder="Teléfono Móvil"
                                    tooltipName="tooltipTelMovilNumEmergencia" tooltipText="10 números" maxLength={10} onlyNumbers
                                    refValue={telMovilNumEmergenciaRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.telefonoCelular : ''} required/>
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <SelectValidation title="Parentesco" arrayOpciones={tiposParentesco} keyOpcion="parentescoNom"
                                    refValue={parentescoNumEmergenciaRef} defaultValue={ elementoSeleccionado ? elementoSeleccionado.parentesco : '' } required/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Calle" type="calle" placeholder="Calle"
                                    tooltipName="tooltipCalleNumEmergencia" tooltipText="Máximo 60 letras, números, espacio y/o signos de puntuación . , - & #"
                                    maxLength={60} refValue={calleNumEmergenciaRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.calle : ''} required/>
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Número Exterior" type="numIntExt" placeholder="Número Exterior"
                                    tooltipName="tooltipNumExt2" tooltipText="Máximo 6 letras, números, espacio y/o guión"
                                    maxLength={6} refValue={numExteriorNumEmergenciaRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.numeroExt : ''} required/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Número Interior" type="numIntExt" placeholder="Número Interior"
                                    tooltipName="tooltipNumInt2" tooltipText="Máximo 6 letras, números, espacio y/o guión"
                                    maxLength={6} refValue={numInteriorNumEmergenciaRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.numeroInt) ? elementoSeleccionado.numeroInt : '' : '' } optional={true}/>
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Código Postal" type="cp" placeholder="Código Postal"
                                    tooltipName="tooltipCPNumEmergencia" tooltipText="5 números" onlyNumbers
                                    maxLength={5} refValue={cpNumEmergenciaRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.cp : ''} action={buscaCP} required/>
                            </div>
                            <div className="form-right">
                                <SelectValidation title="Colonia" arrayOpciones={colonias} keyOpcion="codigopColonia"
                                    tooltipName="tooltipColoniaNumEmergencia" tooltipText="Seleccione la colonia después de capturar el código postal"
                                    refValue={coloniaNumEmergenciaRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.coloniaId : ''} required/>
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Ciudad" type="ciudad" placeholder="Ciudad"
                                    tooltipName="tooltipCiudadNumEmergencia" tooltipText="Se autocompleta después de escribir el código postal"
                                    refValue={ciudadNumEmergenciaRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.numEmerCiudad) ? elementoSeleccionado.numEmerCiudad : '' : ''} disabled optional={true}/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Estado" type="estado" placeholder="Estado"
                                    tooltipName="tooltipEstadoNumEmergencia" tooltipText="Se autocompleta después de escribir el código postal"
                                    refValue={estadoNumEmergenciaRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.numEmerEstado) ? elementoSeleccionado.numEmerEstado : '' : ''} disabled optional={true}/>
                            </div>
                        </div>
                        <BtnsInfoAdicional numForm={6} irInfo={irInfo}/>
                        {
                            (!elementSelect || llamadasEmergenciaSaved.length === 0 || (llamadasEmergenciaSaved.length > 0 && mostrarFormAddEdit))
                            ?   (!elementSelect || llamadasEmergenciaSaved.length === 0)
                                ?   <div className="formRelLab">
                                        <div className="form-left">
                                            <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                                        </div>
                                        <div className="form-right">
                                            <Button variant="outlined" className="btn-outline" onClick={e => (!elementSelect) ? guardaNumero(e, 'FormAgregar') : guardaNumero(e, 'FormEditar')}>Agregar</Button>
                                        </div>
                                    </div>
                                :   <div className="formRelLab">
                                        <div className="form-left">
                                            <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                        </div>
                                        <div className="form-right">
                                            <Button variant="outlined" className="btn-outline" onClick={e => guardaNumero(e, 'FormEditar')}>Agregar</Button>
                                        </div>
                                    </div>
                            :   null
                        }
                        {
                            (elementSelect && llamadasEmergenciaSaved.length > 0 && !mostrarFormAddEdit)
                            ?   <div className="formRelLab">
                                    <div className="form-left">
                                        <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                    </div>
                                    <div className="form-right">
                                        <Button variant="contained" className="btn-color" type="submit">{(!elementSelect || llamadasEmergenciaSaved.length === 0 || (llamadasEmergenciaSaved.length > 0 && mostrarFormAddEdit)) ? 'Guardar' : 'Actualizar' }</Button>
                                    </div>
                                </div>
                            :   <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
                        }
                    </form>
                :   <Fragment>
                        <br/>
                        <BtnsInfoAdicional numForm={6} irInfo={irInfo}/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                            </div>
                            <div className="form-right">
                                <Button variant="outlined" className="btn-outline" onClick={e => abrirFormAddEdit(e)}>Agregar</Button>
                            </div>
                        </div>
                    </Fragment>
            }
            {   elementSelect &&
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaTnfo}/>
            }
        </Fragment>
    );
}
 
export default FormNúmerosEmergencia;
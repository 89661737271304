import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { headersAuth, obtenerUsuario } from '../../../../../Services/AuthService';
import { servicios_grupogack } from '../../../../../Constants/Constants';
import SpinnerOval from '../../../../../Services/Spinner/SpinnerOval';

import './styles.css';
import './stylesGeneric.css';
import Button from '@material-ui/core/Button';
import Create from '@material-ui/icons/Create';
import Email from '@material-ui/icons/Email';
import Send from '@material-ui/icons/Send';
import Delete from '@material-ui/icons/Delete';

import SeleccionaElemento from './MensajesContenido/SeleccionaElemento';
import BandejaEntrada from './MensajesContenido/BandejaEntrada/BandejaEntrada';
import BandejaSalida from './MensajesContenido/BandejaSalida/BandejaSalida';
import PapeleraReciclaje from './MensajesContenido/PapeleraReciclaje/PapeleraReciclaje';

import EnviarMensaje from './MensajesContenido/EnviarMensaje/EnviarMensaje';

/* ------------------------- Elementos necesarios para la creacion de MuiTheme -------------------------  */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#1572E8' },
    },
});
/* -----------------------------------------------------------------------------------------------------  */

const Mensajes = () => {
    const [ accionesDerecho, setAccionesDerecho ] = useState( null );
    const [ accionesIzquierdo, setAccionesIzquierdo ] = useState( null );
    const [ panelDerecho, setPanelDerecho ] = useState( <SeleccionaElemento /> );
    const [ panelIzquierdo, setPanelIzquierdo ] = useState( <BandejaEntrada setPanelDerecho={setPanelDerecho} setAccionesDerecho={setAccionesDerecho}/> );
    const [ seleccionado, setSeleccionado ] = useState( 'entrada' );
    /* --------------------------------- Axios para obtener lista de usuarios --------------------------------- */
    const usuario = obtenerUsuario();
    const [ usuariosChat, setUsuariosChat ] = useState( null );
    useEffect(() => {
        axiosUsuariosChat();
        // eslint-disable-next-line
    }, []);

    const axiosUsuariosChat = () => {/*
        axios.get( `${servicios_grupogack}/usuarioschat/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuariosChat( respuesta.data.dataResponse.usuarios.filter( user => user.username !== usuario.usuario) );
                        break;
                    case 404:
                        //setUsuariosChat( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
        */}
    /* ---------------------- Acciones para mostrar Bandejas (Entrada, Salida, Papelera) ---------------------- */
    const mostrarEntrada = () => {
        setPanelIzquierdo( <BandejaEntrada setPanelDerecho={setPanelDerecho} setAccionesDerecho={setAccionesDerecho} setAccionesIzquierdo={setAccionesIzquierdo}/> );
        setSeleccionado( 'entrada' );
        setAccionesDerecho( null );
    }
    const mostrarSalida = () => {
        setPanelIzquierdo( <BandejaSalida setPanelDerecho={setPanelDerecho} setAccionesDerecho={setAccionesDerecho} setAccionesIzquierdo={setAccionesIzquierdo}/> );
        setSeleccionado( 'salida' );
        setAccionesDerecho( null );
    }
    const mostrarPapelera = () => {
        setPanelIzquierdo( <PapeleraReciclaje setPanelDerecho={setPanelDerecho} setAccionesDerecho={setAccionesDerecho} setAccionesIzquierdo={setAccionesIzquierdo}/> );
        setSeleccionado( 'papelera' );
        setAccionesDerecho( null );
    }
    const mostrarEnviarMensaje = () => {
        setPanelDerecho( <EnviarMensaje usuarios={usuariosChat} setPanelDerecho={setPanelDerecho}/> );
        setAccionesDerecho( null );
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <MuiThemeProvider theme={theme}>
            { usuariosChat ?
                <div className="mensajesPrincipal MuiPaper-elevation1">
                    <div className="mensajesHeader">
                        <div className="panelIzquierdo">
                            <Button className="btn-color" onClick={ () => mostrarEnviarMensaje() }> <Create/> Mensaje Nuevo </Button>
                        </div>
                        <div className="panelDerecho">
                            <Button className={ `btn-incolor ${seleccionado === 'entrada' && 'btn-seleccionado'}` } onClick={ () => mostrarEntrada() }> <Email/> Buzón </Button>
                            <Button className={ `btn-incolor ${seleccionado === 'salida' && 'btn-seleccionado'}` } onClick={ () => mostrarSalida() }> <Send/> Enviados </Button>
                            <Button className={ `btn-incolor ${seleccionado === 'papelera' && 'btn-seleccionado'}` } onClick={ () => mostrarPapelera() }> <Delete/> Eliminados </Button>
                        </div>
                    </div>

                    <div className="mensajesAction">
                        <div className="panelIzquierdo">
                            { accionesIzquierdo }
                        </div>
                        <div className="panelDerecho">
                            <div className="buttonsActions">
                                { accionesDerecho }
                            </div>
                        </div>
                    </div>

                    <div className="mensajesContenido">
                        <div className="panelIzquierdo mensajesBandeja">
                            { panelIzquierdo }
                        </div>
                        <div className="panelDerecho mensajesVista">
                            { panelDerecho }
                        </div>
                    </div>
                </div>
                :
                <SpinnerOval />
            }
        </MuiThemeProvider>
    );
};

export default Mensajes;
import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AutoCompleteUsuarios from '../../Mensajes/MensajesContenido/EnviarMensaje/AutoCompleteUsuarios';
import { headersAuth, obtenerUsuario } from '../../../../../../Services/AuthService';
import axios from 'axios';
import { servicios_grupogack_url, servicios_grupogack } from '../../../../../../Constants/Constants';
import { ArrayJsonSubordinatesUsers, ArrayJsonAuditorias } from '../../../Helpers/JsonToOneLevel'; 
import { InfoRounded, Description } from '@material-ui/icons';
import AlertForm from '../../../../../../Services/AlertForm'; 
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import { informacionModulos } from '../../../Helpers/HelperNavegacion';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { iconosDrawer } from '../../../MenuDrawer/Drawer/IconosDrawer';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Tooltip } from 'reactstrap';

const FormConsultaAuditoria = ({ error, setAuditorias, modulosApi}) => { 

    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'));    
    const fechaIniRef = createRef('');
    const fechaFinRef = createRef('');
    const [ user, setUser ] = useState( null );
    const [ modulo, setModulo ] = useState( '' );
    const [ operacion, setOperacion ] = useState( '' ); 
    const [ isLoading, setIsLoading ] = useState( false );
    const [tooltipOpenUsuario, setTooltipOpenUsuario] = useState(false);
    const [tooltipOpenModulo, setTooltipOpenModulo] = useState(false);
    const { usuario, nombre, apaterno, amaterno,  claveUsuario } = obtenerUsuario(); 
    const [ usuarios, setUsuarios ] = useState( [{id:parseInt(claveUsuario) , username:usuario ,datosUsuarioNom: nombre, datosUsuarioPat: apaterno, datosUsuarioMat: amaterno}] );
    const [ errorForm, setErrorForm] = useState( null );
    const operaciones = [ 
        {id:1, operacion:'Agregar', operation:"INSERT"},
        {id:2, operacion:'Actualizar', operation:"UPDATE"},
        {id:3, operacion:'Eliminar', operation:"DELETE"}
    ];
    var modulos = informacionModulos( modulosApi ); 

    useEffect(()=>{ 
        axios.get( `${servicios_grupogack}/img/${usuario}/${claveUsuario}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        usuarios[0].img=respuesta.data.dataResponse;
                        break;
                    case 500:
                        usuarios[0].img="";
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });

        axios.get( `${servicios_grupogack_url}/all/subordinates`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios(  [...usuarios, ...ArrayJsonSubordinatesUsers(respuesta.data.dataResponse) ]);
                        break;
                    case 404:
                        setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) }); 
            // eslint-disable-next-line
    },[]);
    
    const consultarAuditorias = ( data, infoUsuario ) => {
        setIsLoading(true);
        axios.post( `${servicios_grupogack}/Auditoria/`, data,headersAuth() )
        .then( respuesta => { 
            switch( respuesta.data.status ){
                case 200:
                    setAuditorias( ArrayJsonAuditorias(respuesta.data.dataResponse, infoUsuario ) );
                    setIsLoading(false);
                    break;
                case 404:
                    setIsLoading(false);
                    setErrorForm( <AlertForm message="No se encontraron datos." /> ); 
                    break;
                default:
                    setIsLoading(false);
                    break;
            }
        })
        .catch( error => { setIsLoading(false); console.log( error ) });   
    }

    const enviarAuditorias = (evt) => {
        evt.preventDefault();
        if( user!==null){
            setErrorForm(null);
            if( validarSubmit( 'consultarAuditorias' ) && user!==null){                
                const usuario = user;
                const fechaIni = fechaIniRef.current.value.includes('/') ? fechaIniRef.current.value.split('/').reverse().join('-') :fechaIniRef.current.value;
                const fechaFin = fechaFinRef.current.value.includes('/') ? fechaFinRef.current.value.split('/').reverse().join('-') :fechaFinRef.current.value;
                consultarAuditorias(deleteOptionals({usuario:usuario.id, fechaIni, fechaFin, modulo, operacion}), user);
            }
        }else{
            setErrorForm( <AlertForm message="Complete los campos requeridos." /> ); 
        }
    }

    const selectOperacion = ( element )=>{
        if(element!==null){ 
            setOperacion(element.operation);
        }else{
            setOperacion('');
        }
    }
 
    const validar = e => {
        if (e === undefined || e === null) {
            setModulo('');
        } else {
            let result = modulos.filter(mod => e.nombre === mod.nombre);
            if(result.length !== 0 ){
                setModulo(result[0].id);
            }
        }
    }; 
    const toggle = (tooltip) => {
        switch(tooltip) {
            case "Usuario":
                setTooltipOpenUsuario(!tooltipOpenUsuario);
                break; 
            case "Modulo":
                setTooltipOpenModulo(!tooltipOpenModulo);
                break; 
            default:
                break;
        }
    }
    return (
        <div className="card py-2 px-3 mb-2">
            <form id='consultarAuditorias' onSubmit={enviarAuditorias}>
                <h4 className="mt-2"> Consultar Auditoría </h4>
                <label>Filtre la Información que desea consultar, utilizando los siguientes campos de autocompletados.</label>
                <hr/>
                <div className="row">
                    <div className="col-md-3 col-sm-12" style={{margin: '.5rem 0'}}>
                        <label>{'Usuario'}
                            <strong className="requerido">*</strong> 
                            <span id={'tooltipUsuario'} className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRounded color="disabled"/></span> 
                        </label>
                        <Tooltip placement="right" isOpen={tooltipOpenUsuario} target="tooltipUsuario" toggle={() => toggle("Usuario")}>
                            { 'Seleccione un Usuario' }
                        </Tooltip>
                        <AutoCompleteUsuarios usuarios={usuarios} setDestinatarios={setUser} multiple={false}/>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechaIniRef} required/>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechaFinRef} required/>
                    </div>
                    <div className="col-md-2 col-sm-12" style={{margin: '.1rem 0'}}>
                        <label>{'Módulo'} 
                            <span id={'tooltipModulo'} className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRounded color="disabled"/></span> 
                        </label>
                        <Tooltip placement="right" isOpen={tooltipOpenModulo} target="tooltipModulo" toggle={() => toggle("Modulo")}>
                            { 'Seleccione un Módulo' }
                        </Tooltip>
                        <Autocomplete 
                            id="free-solo-demo" 
                            autoHighlight={true}
                            onChange={(e,v) => validar(v)}
                            getOptionLabel={option => option.nombre}
                            options={modulos.map(modu => ({'nombre':modu.nombre,'icono':modu.icon}))}
                            size="small" 
                            noOptionsText="No existen coincidencias" 
                            renderOption={ (options,{inputValue}) => {
                                const matches = match(options.nombre, inputValue)
                                const parts = parse(options.nombre, matches);
                                return (
                                    <React.Fragment>
                                        <table >
                                            <thead>
                                                <tr>
                                                    <th className="Icono">
                                                        { iconosDrawer[ options.icono ] }
                                                    </th>
                                                    <th >
                                                        { parts.map((part, index) => (
                                                            <span key={index} style={{ color: part.highlight ? '#4C638E': 'black' }}>
                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </React.Fragment>
                                );
                            }}      
                            renderInput={ params => (
                                    <TextField
                                        id="mui-theme-provider-outlined-input"
                                        {...params} 
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                            )}
                        />

                    </div>
                    <div className="col-md-2 col-sm-12"> 
                        <AutocompleteComponent
                                id="operacion"
                                title="Operación"
                                options={operaciones}
                                optionListView="operacion"
                                action={selectOperacion}
                                placeholder="Operación"
                                tooltipText="Busque la Operación, en el campo de autocompletado"
                                tooltipName="tooltipOperacion"
                            />
                    </div>
                    <div className="col-md-1 col-sm-12" style={{margin: '2rem 0'}}>
                        <Button variant="contained" className="btn-third" type="submit"> { isLoading ? <CircularProgress size={25}/>:<Description/> }</Button> 
                    </div> 
                </div>
                <div className="row">
                    <div className="col-md-3 col-sm-12">
                        {errorForm}
                    </div>
                </div>
            </form>
        </div>
    );
};

export default FormConsultaAuditoria;
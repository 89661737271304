import React, { createRef, useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';

import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

const FormularioCodigoPostal = ({ error, enviarCodigoPostal, informacion }) => {
    const estadoRef = createRef('');
    const ciudadRef = createRef('');
    const coloniaRef = createRef('');
    /* ----------------------------- Metodo axios para consultar Ciudades por Estado ---------------------------- */
    const [ ciudades, setCiudades ] = useState( null );

    const axiosCiudades = ( estadoId ) => {
        axios.get( `${servicios_grupogack_catalogo}s/ciudad/${estadoId}`, headersAuth() ) /* País 1: México */
            .then( respuesta => {
                console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setCiudades( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposParentesco( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------ Metodo para enviar objeto a Servicio Nuevo Código Postal ------------------------ */
    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarCodigoPostal' ) ){
            const ciudadId = ciudadRef.current.value;
            const codigoPostal = informacion.postalCode;
            const coloniaNombre = coloniaRef.current.value;
            console.log({ ciudadId, codigoPostal, coloniaNombre });
            enviarCodigoPostal({ ciudadId: parseInt(ciudadId, 10), codigoPostal, coloniaNombre });
        }
    }
    /* ------------------------------------------------ return() ------------------------------------------------ */
    return (
        <form id="AgregarCodigoPostal" onSubmit={enviar}>
            <h5> Colonia </h5>
            <SelectValidation title="Estado" required arrayOpciones={informacion.estados} keyOpcion="estadoNom" refValue={estadoRef} onChangeExecute={axiosCiudades}/>
            { ciudades &&
                <SelectValidation title="Ciudad" required arrayOpciones={ciudades} keyOpcion="ciudadNom" refValue={ciudadRef}/>
            }
            <span> <b> Código Postal: </b> {informacion.postalCode} </span>
            <InputValidation title="Colonia" type="nombreColonia" placeholder="Escriba nombre de la colonia" maxLength='120'
                tooltipText={'Máximo 120 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomCol'}
                refValue={coloniaRef} required/>
            {error}
            <Button variant="contained" className="btn-color" type="submit">Agregar Código Postal</Button>
        </form>
    );
};

export default FormularioCodigoPostal;
import { GUARDAR_SESSION, ERROR_MESSAGE_LOGIN, CATCH_ERROR_SERVER_LOGIN, CERRAR_SESSION } from '../actionTypes';

const initialstate = {
    token: '',
    logueado: false,
    usuario: {},
    messageError: '',
    errorServerLogin: ''
}

const SessionReducer = ( state = initialstate, action ) => {

    switch( action.type ) {
        case GUARDAR_SESSION:
            return{
                ...state,
                token: action.session.token,
                logueado: true,
                usuario: action.session.usuario,
                messageError: '',
                errorServerLogin: ''
            }

        case ERROR_MESSAGE_LOGIN:
            return{
                ...state,
                messageError: action.message
            }

        case CATCH_ERROR_SERVER_LOGIN:
            return{
                ...state,
                errorServerLogin: action.errorServer
            }
        

        case CERRAR_SESSION:
            return{
                token: '',
                logueado: false,
                usuario: {},
                messageError: '',
                errorServerLogin: ''
            }

        default:
            return state;
    }
}

export default SessionReducer;
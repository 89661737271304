import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_admon, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import AlertForm from '../../../../../../../Services/AlertForm';
import FormularioDomicilios from './FormularioDomicilios';

const ModalEditDomicilio = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacion, idModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarDomicilio = ( domicilio, domicilioID ) => {
        axios.put( `${servicios_grupogack_admon}/compania/domicilios/${informacion.dependenciaID}/${domicilioID}`, domicilio, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        editar( respuesta.data.dataResponse );
                        setOpenEdit( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Domicilio" parentNode={idModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioDomicilios
                    error={error}
                    elementSelect={elementSelect}
                    actualizarDomicilio={actualizarDomicilio}
                    informacion={informacion}
                />
        </ModalService>
    );
};

export default ModalEditDomicilio;
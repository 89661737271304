import React, { useState, useEffect } from 'react';

import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';

//import Image from '../../../../../Media/Img/logo-oax-color.png';
import UserBlock from './UserBlock';
import ModulosBlock from './ModulosBlock';
import PerfilModal from '../../Perfil/PerfilModal';
import './Drawerstyles.css'

/* Importacion de elementos para peticiones axios */
import axios from 'axios';
import { headersAuth, obtenerUsuario } from '../../../../../Services/AuthService';
import { servicios_grupogack } from '../../../../../Constants/Constants';

function MenuDrawer({ classes, openMenu, modulosApi, closeSession, usuario, setUsuario, steps, run }) {

    const [modalProfile, setModalProfile] = useState(false);
    const toogleProfile = () => { setModalProfile(!modalProfile) }
    /* ------------------------------- Axios para obtener imagen de perfil ------------------------------ */
    const [ imagenPerfil, setImagenPerfil ] = useState( null );
    const [ logotipo, setLogotipo ] = useState( null );
    useEffect(() => {
        axiosImagenPerfil();
        axiosLogotipo();
    }, []);

    const axiosImagenPerfil = () => {
        const { usuario, claveUsuario } = obtenerUsuario();
        axios.get( `${servicios_grupogack}/img/${usuario}/${claveUsuario}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setImagenPerfil( respuesta.data.dataResponse );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener logotipo en base64 */
    const axiosLogotipo = () => {/*
        axios.get( `${servicios_grupogack}/logo/`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setLogotipo( respuesta.data.dataResponse );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
        */}
    /* -------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <style>
                {
                    (openMenu)
                    ?  `
                            .sweet-alert {
                                margin: 0 0 0 240px !important;
                                transition: all 0.2s ease-in-out !important;
                                -webkit-transition: all 0.2s ease-out !important;
                                -moz-transition: all 0.2s ease-out !important;
                                -o-transition: all 0.2s ease-out !important;
                            }`
                        
                    :   `
                            .sweet-alert {
                                margin: 0 0 0 0 !important;
                                transition: all 0.2s ease-in-out !important;
                                -webkit-transition: all 0.2s ease-out !important;
                                -moz-transition: all 0.2s ease-out !important;
                                -o-transition: all 0.2s ease-out !important;
                            }
                            .back{
                                max-width: 1800px;
                            }`
                }
            </style>
            <Drawer className={classes.drawer} variant="persistent" anchor="left" classes={{paper: classes.drawerPaper,}} open={openMenu}>
                {/*<div className={classes.toolbar}>{/*
                    <div id="MenuDrawerLogo" className="text-center">
                        { logotipo && <img src={`data:image/jpeg;base64, ${logotipo}`} className="imageLogo" alt="site-logo"/> }
                </div>
                </div>*/}
                <UserBlock steps={steps}  run={run} closeSession={closeSession} toogleProfile={toogleProfile} usuario={usuario} imagenPerfil={imagenPerfil}/>
                <Divider />
                <ModulosBlock modulos={modulosApi} steps={steps} run={run}/>
            </Drawer>
            <PerfilModal modalProfile={modalProfile} toogleProfile={toogleProfile} usuario={usuario} setUsuario={setUsuario} imagenPerfil={imagenPerfil} setImagenPerfil={setImagenPerfil} openMenu={openMenu}/>
        </React.Fragment>
    );
}

export default (MenuDrawer);
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
//import AlertForm from '../../../../../../Services/AlertForm';
import FormularioPoliticas from './FormularioPoliticas';
import { JsonPrimaVacacional, JsonAguinaldo, JsonDiasAjuste, JsonVacaciones} from '../../../Helpers/JsonToOneLevel';

const ModalAddPoliticas = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {

    const [ error, setError ] = useState( null ); 
    const [ nombreVista, setNombreVista ]= useState(null);

    const cerrarModal = () => {
        setNombreVista(null);
        setOpenAdd( false );
        setError( null );
    }
    const tipoTitulos = [ {id: 0, nombre: 'Nuevo Aguinaldo', dir: 'aguinaldo/'},
                    {id: 1, nombre: 'Nueva Prima Vacacional', dir: 'prima/vacacional/'},
                    {id: 2, nombre: 'Nuevo Día de Ajuste', dir: 'dias/ajuste/'},
                    {id: 3, nombre: 'Nuevas Vacaciones', dir: 'vacaciones/'}
                    ];

    const enviarPolitica = ( politica, active, dataPeriodosAjuste=null ) => {
        const dir = tipoTitulos.filter(element => element.id ===parseInt(active))[0].dir;
        axios.post( `${servicios_grupogack_rh}/politica/${dir}`, politica, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        switch (active){
                            case 0: 
                            agregar( JsonAguinaldo( respuesta.data.dataResponse ) );
                            break;
                            case 1: 
                            agregar( JsonPrimaVacacional( respuesta.data.dataResponse ) );
                            break;
                            case 2: 
                                if(dataPeriodosAjuste!==null) enviarPeriodoAjuste(respuesta.data.dataResponse.id, dataPeriodosAjuste);
                                agregar( JsonDiasAjuste( respuesta.data.dataResponse ) );
                            break;
                            case 3:
                                if(dataPeriodosAjuste!==null) enviarVacacionesAnti(respuesta.data.dataResponse.id, dataPeriodosAjuste);
                            agregar( JsonVacaciones( respuesta.data.dataResponse ) );
                            break; 
                            default:
                            break; 
                        }
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }
    const enviarPeriodoAjuste = async (idPolitica, dataPeriodos) => { 
        const newData = dataPeriodos.map(element => {return {diasAjuste: idPolitica, periodoPago: element.periodoPago, diasPagoPeriodo: element.diasPagoPeriodo }});
        for (const key in newData) {
            await axios.post( `${servicios_grupogack_rh}/periodo/ajuste/`, newData[key], headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        return true; 
                    default:
                        errorTable(registro_no_guardado+" en Periodos de Ajuste");
                        return false; 
                }
            })
            .catch(error => errorTable(registro_no_guardado));
        } 
    }

    const enviarVacacionesAnti = async (idPolitica, dataPeriodos) => { 
        const newData = dataPeriodos.map(element => {return {politcaVacaciones: idPolitica, anioIni: element.anioIni, anioFin: element.anioFin, diasSem1: element.diasSem1, diasSem2: element.diasSem2 }});
        for (const key in newData) {
            await axios.post( `${servicios_grupogack_rh}/vacaciones/antiguedad/`, newData[key], headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        return true; 
                    default:
                        errorTable(registro_no_guardado+" en Vacaciones Antiguedad");
                        return false; 
                }
            })
            .catch(error => errorTable(registro_no_guardado));
        } 
    }

    const vista = (numero) =>{
        numero!==null ? setNombreVista(tipoTitulos.filter(element => element.id ===parseInt(numero))[0].nombre) : setNombreVista(null);
    } 

    return (
        <ModalService title={nombreVista ? nombreVista : "Nueva Política"} parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioPoliticas
                    error={error}
                    enviarPolitica={enviarPolitica}
                    vista={vista}
                />
        </ModalService>
    );
};

export default ModalAddPoliticas;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina, servicios_grupogack, servicios_grupogack_rh } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import ModalService from '../../../ModalService/ModalService'; 
import FormularioNewReportNomina from './FormularioNewReportNomina';
import AlertForm from '../../../../../../Services/AlertForm';
import { ArrayJsonEmpleadosReportesNominas } from '../../../Helpers/JsonToOneLevel'; 

import {sdisbc,variabilidad,fonacot, infonavit} from '../../../../../Datos/archivos';

const ModalNewReportNomina = ({ openNewReportNomina, setOpenNewReportNomina, setElementSelect, nombreModulo, title, setShowReport, setInfoReport, downloadReportDefinido, setDownloadReportDefinido, infoReport }) => {
    const [ error, setError ] = useState( null ); 
    const [ isLoading, setIsLoading ] = useState( false );
    const cerrarModal = () => {
        setInfoReport(null);
        setOpenNewReportNomina( false );
        setDownloadReportDefinido(false);
        setError( null );
    }

    const enviarNewReportNomina = ( data ) => {
        setIsLoading(true);
        setError( null );
        axios.get( `${servicios_grupogack_nomina}/vacumperxnom/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setInfoReport(data);
                        setElementSelect(ArrayJsonEmpleadosReportesNominas( respuesta.data.dataResponse )); 
                        setDownloadReportDefinido(false);
                        setOpenNewReportNomina( false );
                        setShowReport(true);
                        setError( null ); 
                        setIsLoading(false);
                        break;
                    case 404:
                        setError(<AlertForm message={respuesta.data.msg} />); 
                        setIsLoading(false);
                        break;
                    default: 
                        setError(<AlertForm message={respuesta.data.msg} />); 
                        setIsLoading(false);
                        break;
                }
            })
            .catch( error => { 
            });
    }

    const enviarReportDownload = ( data ) => {
        setIsLoading(true);
        setError( null ); 
        let direccion = '';        
        switch(infoReport){
            case 'Formato Horizontal 2':
                const datosh = {
                    "periodicidad" : 2, 
                    "nomina" : parseInt(data.nominaId), 
                    "anio" : parseInt(data.periodoanio),
                    "periodoIni": parseInt(data.periodoIniperanio),
                    "periodoFin": parseInt(data.periodoFinperanio),
                    "acumulados" : data.acumulado
                  }
                direccion = `${servicios_grupogack}/reportes/nomina/acumulado/horizontal/formato2`;
                axios.post( direccion, datosh, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport}.xls`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => { 
                    });
                break;
            case 'Dispersión':
                    const datosd = {
                        "periodicidad" : 2, 
                        "nomina" : parseInt(data.nominaId), 
                        "anio" : parseInt(data.periodoanio),
                        "periodoIni": parseInt(data.periodoIniperanio),
                        "periodoFin": parseInt(data.periodoFinperanio),
                        "acumulados" : data.acumulado
                      }
                    direccion = `${servicios_grupogack}/reportes/nomina/acumulado/horizontal/formato2`;
                    axios.post( direccion, datosd, headersAuth() )
                        .then( respuesta => { 
                            switch( respuesta.data.status ){
                                case 200:  
                                    const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                                    const downloadLink = document.createElement("a");
                                    const fileName =`${infoReport}.xls`; 
                                    downloadLink.href = linkSource;
                                    downloadLink.download = fileName;
                                    downloadLink.click();
                                    setInfoReport(null);
                                    setDownloadReportDefinido(false);
                                    setOpenNewReportNomina( false ); 
                                    setError( null ); 
                                    setIsLoading(false);
                                    break;
                                case 404:
                                    setError(<AlertForm message={respuesta.data.message} />); 
                                    setIsLoading(false);
                                    break;
                                default: 
                                    setError(<AlertForm message={respuesta.data.message} />); 
                                    setIsLoading(false);
                                    break;
                            }
                        })
                        .catch( error => { 
                        });
                    break;
            case 'SDI SBC':
                direccion = `${servicios_grupogack}/reportes/imss/sdi/sbc/${data.nominaId}`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport}.xls`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => { console.log( error ) 
                    });
            break;
            case 'Variabilidad':
               direccion = `${servicios_grupogack}/reportes/imss/sdi/variabilidad2/${data.nominaId}/${data.periodoanio}/${data.bimestre}`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource1 = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                                const downloadLink1 = document.createElement("a");
                                const fileName1 =`${infoReport}.xls`; 
                                downloadLink1.href = linkSource1;
                                downloadLink1.download = fileName1;
                                downloadLink1.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => { console.log( error ) 
                    });
            break;
            case 'Infonavit':
                direccion = `${servicios_grupogack}/reportes/imss/infonavit/${data.nominaId}/${data.fechaInicio}/${data.fechaFinal}`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource2 = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                                const downloadLink2 = document.createElement("a");
                                const fileName2 =`${infoReport}.xls`; 
                                downloadLink2.href = linkSource2;
                                downloadLink2.download = fileName2;
                                downloadLink2.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => { console.log( error ) 
                    });
            break;
            case 'Fonacot':
                direccion = `${servicios_grupogack}/reportes/imss/fonacot/${data.nominaId}/${data.fechaInicio}/${data.fechaFinal}`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource3 = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                                const downloadLink3 = document.createElement("a");
                                const fileName3 =`${infoReport}.xls`; 
                                downloadLink3.href = linkSource3;
                                downloadLink3.download = fileName3;
                                downloadLink3.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => { 
                    });
                break;
            case 'Formato Vertical':
                const datosreq = {
                    "anio" : parseInt(data.periodoanio),
                    "periodoIni": parseInt(data.periodoIniperanio),
                    "periodoFin": parseInt(data.periodoFinperanio),
                    "acumulados" : data.acumulado
                };
                console.log(datosreq);
                //direccion = `${servicios_grupogack}/generar/reportes/${data.nominaId}/${data.periodoanio}/${data.periodoperanio}/${data.acumulado}/${data.concepto}`;
                direccion = `${servicios_grupogack}/reportes/nomina/acumulado/vertical/${data.nominaId}`;
                axios.post( direccion, datosreq, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){
                            case 200:  
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse.reporte}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport}.xls`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => { 
                    });
                break;
            case 'Variables':
                direccion = `${servicios_grupogack_rh}/movimientos/fijo/reporte/variable/excel/${data.nominaId}`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.data.status ){ 
                            case 200:
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport}.xls`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }
                    })
                    .catch( error => { 
                    });
                break;
            case 'Fijos':
                direccion = `${servicios_grupogack_rh}/movimientos/fijo/reporte/excel/${data.nominaId}`;
                axios.get( direccion, headersAuth() )
                    .then( respuesta => { 
                        switch( respuesta.status ){
                            case 200:
                                const linkSource = `data:application/vnd.ms-excel;base64,${respuesta.data.dataResponse}`;
                                const downloadLink = document.createElement("a");
                                const fileName =`${infoReport}.xls`; 
                                downloadLink.href = linkSource;
                                downloadLink.download = fileName;
                                downloadLink.click();
                                setInfoReport(null);
                                setDownloadReportDefinido(false);
                                setOpenNewReportNomina( false ); 
                                setError( null ); 
                                setIsLoading(false);
                                break;
                            case 404:
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                            default: 
                                setError(<AlertForm message={respuesta.data.message} />); 
                                setIsLoading(false);
                                break;
                        }  
                    })
                    .catch( error => { 
                    });
                break;
            default:
                setInfoReport(null);
                setDownloadReportDefinido(false);
                setError(<AlertForm message={'No se pudo descargar el reporte.'} />); 
                setIsLoading(false);
            break;
        }
    }

    return (
        <ModalService title={`${infoReport}`} parentNode={nombreModulo} minWidth="50%" maxWidth="50%"
            isOpen={ openNewReportNomina } onClose={ () => cerrarModal() } >
                <FormularioNewReportNomina
                    error={error}
                    enviarNewReportNomina={enviarNewReportNomina}
                    title={title}
                    isLoading={isLoading} 
                    setError={setError}
                    downloadReportDefinido={downloadReportDefinido}
                    setDownloadReportDefinido={setDownloadReportDefinido}
                    enviarReportDownload={enviarReportDownload}
                    infoReport={infoReport}
                />
        </ModalService>
    );
};

export default ModalNewReportNomina;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_no_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormBajaEmpleados from './FormBajaEmpleados';

const ModalDeleteFormEmpleados = ({ nombreModulo, openDeleteForm, setOpenDeleteForm, elementSelect, eliminar, errorTable }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenDeleteForm(false);
        setError(null);
    };

    const bajaEmpleado = info => {
        const infoBaja = info;
        axios.put(`${servicios_grupogack_rh}/empleado/status/`, infoBaja, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect.id);
                    cerrarModal();
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_eliminado));
    };

    return(
        <ModalService
            title="Baja de Empleado"
            parentNode={nombreModulo}
            isOpen={openDeleteForm} 
            onClose={cerrarModal}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormBajaEmpleados
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        bajaEmpleado={bajaEmpleado}
                    />
                </div>
            </div>
            
        </ModalService>
    );
}

export default ModalDeleteFormEmpleados;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormBases from './FormBases';
import AlertForm from '../../../../../../Services/AlertForm';


const ModalEditBases = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarBase = (base, baseId) => {
        const info = { basesId: baseId, baseClave: base.baseClave, baseNom: base.baseNom, baseDescrip: base.baseDescrip };
        axios.put( `${servicios_grupogack_catalogo}/bases/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(res.data.dataResponse);
                    setOpenEdit(false);
                    break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg}/>);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    return (
        <ModalService
            title="Actualizar Base"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormBases
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarBase={actualizarBase}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditBases;
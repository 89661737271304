import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_url } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

//import AlertForm from '../../../../../../../Services/AlertForm';
import { modulosDeCarga } from './CargasMasivas/HelperCargasMasivas';
import TarjetaCarga from './TarjetaCarga';
import ModalAddMasivos from './ModalAddMasivos';

const CargaMasivaDatos = ({ id }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    const [ openAdd, setOpenAdd ] = useState( false );
    /* ------------------------------- Axios para obtener los menu de usuario logueado ------------------------------- */
    const [ modulos, setModulos ] = useState( null );
    useEffect(() => {
        axiosModulos();
    }, []);

    const axiosModulos = () => {
        axios.get( `${servicios_grupogack_url}/menu`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setModulos( respuesta.data.dataResponse.menu );
                        break;
                    case 404:
                        //setModulos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <div id={ id }>
            { modulos ?
                <React.Fragment>
                    <div className="row">
                        { modulosDeCarga.map( modulo => {
                            return(
                                <div key={modulo.id} className="col-md-3 col-sm-12 my-2">
                                    <TarjetaCarga modulo={modulo} setElementSelect={setElementSelect} setOpenAdd={setOpenAdd}/>
                                </div>
                            )
                        })}
                    </div>
                    <ModalAddMasivos moduloID={id} openAdd={openAdd} setOpenAdd={setOpenAdd} modulo={elementSelect}/>
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default CargaMasivaDatos;
import React, { useState } from 'react';
//import axios from 'axios';
//import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../../Constants/Constants';
//import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import FormAcumulados from './FormAcumulados';
import { floatToAmount } from '../../../../../../../Services/Validation/HelperValidation';
//import AlertForm from '../../../../../../../Services/AlertForm';

const ModalEditAcumulados = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo, informacionAdicional }) => {

    const [error, setError] = useState( null );

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarAcumulado = (acumulado, acumuladoId) => {
        //const info = { id: acumuladoId, acumuladoCve: acumulado.acumuladoCve, acumuladoNom: acumulado.acumuladoNom, acumuladoDescrip: acumulado.acumuladoDescrip };
        console.log({ id: acumuladoId, nominaClave: elementSelect.nominaClave, nominaIndicador: elementSelect.nominaIndicador, indicadorSat: elementSelect.indicadorSat, cantidad: acumulado.cantidad, monto: floatToAmount ( acumulado.importe ) });
        
        editar({ id: acumuladoId, nominaClave: elementSelect.nominaClave, nominaIndicador: elementSelect.nominaIndicador, indicadorSat: elementSelect.indicadorSat, cantidad: acumulado.cantidad, monto: floatToAmount ( acumulado.importe ) });
        setOpenEdit(false);
        /*axios.put( `${servicios_grupogack_catalogo}/acumulado/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar(res.data.dataResponse);
                setOpenEdit(false);
                break;
            case 400:
            case 404:
                setError(<AlertForm message={res.data.msg} />);
                break;
            default:
                errorTable(cambios_no_guardados);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));*/
    }

    return (
        <ModalService
            title="Actualizar Acumulado"
            parentNode={nombreModulo}
            isOpen={ openEdit }
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormAcumulados
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarAcumulado={actualizarAcumulado}
                        informacionAdicional={informacionAdicional}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditAcumulados;
import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTiposSecDep from './FormTiposSecDep';

const ModalAddTipoSecDep = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarTipoSecDep = tipoSecDep => {
        const info = tipoSecDep;
        Axios.post( `${servicios_grupogack_catalogo}/dependencia/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    return (
        <ModalService title="Nuevo Tipo de Secretaría o Dependencia" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTiposSecDep
                        enviarTipoSecDep={enviarTipoSecDep}
                        error={error}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddTipoSecDep;
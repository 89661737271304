import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack, empleados_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Empleados = ({ id, permisos }) => {

    const [empleados, setEmpleados] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupogack}/empleado`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEmpleados(res.data.dataResponse);
                    break;
                case 404:
                    setEmpleados([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    return (
        <div id={ id }>
            {   
                empleados
                ?   <DataTableService id={id} title={empleados_}
                        columns={[
                            { field: "clave", title: "Número de Empleado" },
                            { field: "nombreCompleto", title: "Nombre Completo" },
                            { field: "rfc", title: "RFC" },
                            { field: "curp", title: "CURP" },
                            { field: "nss", title: "NSS" },
                            { field: "nominaNombre", title: "Nómina" },
                        ]}
                        data={empleados}
                        permisos={permisos}
                        informacionAdicional={{ permisos }}
                        showIconEmployee
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default Empleados;
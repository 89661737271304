import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';

import ModalService from '../../../../ModalService/ModalService';
import AlertForm from '../../../../../../../Services/AlertForm';
import FormularioRelacionados from './FormularioRelacionados';

const obtenerAregloIndicadores = ( indicadores, elementSelectID ) => {
    return indicadores.map( item => ({
        'movrelacionado': elementSelectID,
        'nominaIndicador': item.nominaIndicador,
        'porcentaje': item.porcentaje,
    }));
}

const ModalAddRelacionados = ({ openAdd, setOpenAdd, agregar, editar, errorTable, informacionAdicional, idModulo }) => {
    /* -------------------------------------- Axios para enviarRelacionado -------------------------------------- */
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarRelacionado = ( relacionado, indicadoresAdd ) => {
        axios.post( `${servicios_grupogack_rh}/movimientos/relacionado/`, relacionado, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse[0] );
                        const elementSelect = respuesta.data.dataResponse[0];
                        if( elementSelect ){
                            //console.log( obtenerAregloIndicadores(indicadoresAdd, elementSelect.id) );
                            enviarIndicadores( obtenerAregloIndicadores(indicadoresAdd, elementSelect.id) );
                        }
                        setOpenAdd( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }
    /* ---------------------------------------------------------------------------------------------------------- */
    const enviarIndicadores = ( indicadores ) => {
        axios.post( `${servicios_grupogack_rh}/indicador/movimientos/`, indicadores, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //axiosIndicadores();
                        break;
                    default:
                        //errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_guardado );
            })
    }
    /* ---------------------------------------------------------------------------------------------------------- */
    return (
        <ModalService title="Nuevo Movimiento Relacionado" parentNode={idModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioRelacionados
                    error={error}
                    informacion={informacionAdicional}
                    enviarRelacionado={enviarRelacionado}
                />
        </ModalService>
    );
};

export default ModalAddRelacionados;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_rh, servicios_grupogack_nomina, movimientos_fijos } from '../../../../../../../Constants/Constants';
import DataTableMovimientos from '../../Variables/DataTableMovimientos/DataTableMovimientos';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonMovimientosFijosEmpleado } from '../../../../Helpers/JsonToOneLevel';

const FijosEmpleado = ({ empleado, setEmpleado, tiposAcumulado, idModulo }) => {
    const [ fijos, setFijos ] = useState( null );
    const [ indicadoresNomina, setIndicadoresNomina ] = useState( null );
    useEffect(() => {
        axiosFijosEmpleado();
        axiosIndicadoresNomina();
        // eslint-disable-next-line
    }, []);

    const axiosFijosEmpleado = () => {
        axios.get( `${servicios_grupogack_rh}/movimientos/fijo/empleado/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setFijos( ArrayJsonMovimientosFijosEmpleado( respuesta.data.dataResponse ) );
                        break;
                    case 404: //No hay datos disponibles
                        setFijos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener los indicadores por nomina (Nomina a la cual esta relacionada el empleado) */
    const axiosIndicadoresNomina = () => {
        axios.get( `${servicios_grupogack_nomina}/indicadores/getbynomina/${empleado.nominaId}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setIndicadoresNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setIndicadoresNomina( [] );
            })
    }

    return (
        <React.Fragment>
            { fijos && indicadoresNomina ?
                <DataTableMovimientos
                    idModulo={idModulo}
                    title={movimientos_fijos}
                    empleado={empleado}
                    setEmpleado={setEmpleado}
                    columns={[
                        { field: "nomina.nomClave", title: "Clave Indicador" },
                        { field: "nomina.nomIndicador", title: "Nombre Indicador" },
                        { field: "cantidad", title: "Cantidad", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}  },
                        { field: "monto", title: "Monto (MXN)", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}  },
                        { field: "acumulado.acumuladoNombre", title: "Tipo de Acumulado" },
                        { field: "fechaInicioTab", title: "Fecha Inicio" },
                        { field: "fechaFinalTab", title: "Fecha Final" },
                    ]}
                    data={ fijos }
                    informacionAdicional={{ empleado, tiposAcumulado, indicadoresNomina }}/>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default FijosEmpleado;
import React, { useState, useEffect, createRef } from 'react'; 
import Axios from 'axios';
import {  servicios_grupogack, cambios_no_guardados, campos_vacios, registro_sera_eliminado} from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import {  Delete} from '@material-ui/icons';
import {IconButton, Button} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import '../../../../../../Components/Usuarios/Usuario/DataTableService/StyleDataTable.css';  
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import { obtenerModalDelete, obtenerModalAdd } from '../../../../../../Components/Usuarios/Usuario/DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; 
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';

const DinamicTablePeriodosNomina = ({idTab, onShowTable, idModulo, permisos}) => {

    const permisosUsuario = permisos.map(element=> element.id);
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
    const columnHeaderShow =
        ["Fecha Inicial", "Fecha Final", "Año", "Mes", "Periodo", "Periodo Mes",
        "Días IMSS Por Periodo", "Días Nómina Por Periodo", "Fecha de Corte", "Procesado", "Acciones"];
    const columnsTable =[
        "periodoFecini","periodoFecfin", "periodoAnio", "periodoMes","periodoPerAnio", "periodoPerMes",
        "periodoDiasImss", "periodoDiasNom", "periodoFeccorte", "periodoProcesado", "accion"];   

    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ alertWarningLocal, setAlertWarningLocal ] = useState( false );
    const [ openAdd, setOpenAdd ] = useState( false ); 
    const [ shouldUpdate, setShouldUpdate ] = useState( false ); 
    const [ loading, setLoading ] = useState( false ); 
    const [ dta, setDta]= useState(null);  
    const [ isNew, setIsNew]= useState(false);
    const [ loadingData, setLoadingData]= useState(false);
    const [idTabBack, setIdTabBack] = useState({id: idTab.id, fechaFin:''});
    const inputSafariRefs = createRef([]);
     

    useEffect(() => {
            Axios.get(`${servicios_grupogack}/nomina/config/periodos/${idTab.id}`, headersAuth())
            .then(res => {
                setLoadingData(true);   
                switch(res.data.status) {
                    case 200:   
                        setDta(res.data.dataResponse.reverse());
                        if(res.data.dataResponse.length>0){
                            setIsNew(false)
                            idTabBack.fechaFin=longDateToInput(res.data.dataResponse[res.data.dataResponse.length-1].periodoFecfin+86400000+86400000);
                        }else{
                            setIsNew(true)
                        } 
                        break;
                    case 404:
                        break;
                    default:
                        alert('error');
                        break;
                }
                setLoadingData(false);
            })
            .catch(error => alert(error));  
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []);
 

    const del=async (n)=>{
        
        var newDta=[]; 
        if(n.toString().length>10){
            newDta=dta.filter(element  => element.periodoFecfin !== parseInt(n)) 
        }else{
            newDta=dta.filter(element  => element.id !== parseInt(n)) 
        }    
        await setDta(newDta);
        for(var i=0; i<newDta.length; i++){
            for(var j=0; j<columnsTable.length; j++){ 
                
                var input = document.getElementsByName(`${i}${columnsTable[j]}`); 
                if(input[0]!==undefined){
                    if(columnsTable[j]==='periodoFeccorte'){
                        input[0].value=longDateToInput(newDta[i][columnsTable[j]]); 
                    }else if(columnsTable[j]==='periodoFecini' || columnsTable[j]==='periodoFecfin' ){
                        input[0].textContent=convertDateFormat(newDta[i][columnsTable[j]]);
                    }else if(columnsTable[j]==='periodoDiasImss'){
                        input[0].value=newDta[i][columnsTable[j]]; 
                    }else if(columnsTable[j]==='accion' ){
                    }else{
                        input[0].textContent=columnsTable[j]==='periodoProcesado' ? newDta[i][columnsTable[j]] ?'Sí':'No':newDta[i][columnsTable[j]];
                    } 
                }
            }
        }    
        if(dta.length<=1){
            idTabBack.fechaFin='';
        }else{
            idTabBack.fechaFin=longDateToInput(dta[dta.length-1].periodoFecfin+86400000+86400000);
        }
            
    }
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    } 

    const invalidInputs=(index)=>{ 
        var name=`${index}periodoFeccorte`;
        var input = document.getElementsByName(name);
        input[0].className="inpTable minIn is-invalid"; 
    } 

    const generateHeader=() =>{
        
       return columnsTable.map((key, index)=>{
         return <th key={index} className="MuiTableCell-root negritas">{columnHeaderShow[index]}</th> 
        })
     }

     const updateData = (key, value, index) => {       
        if(key==='periodoFeccorte'){            
            dta[index][key]=value;
        }else{ 
            dta[index][key]=Number(value);
        }
        setShouldUpdate(true);
     }

     const validNumber=(index, value, e)=>{
        if(isNaN(value) || value<1){
            e.target.className="inpTable minIn is-invalid";
        }else{
            e.target.className="inpTable minIn";
        }
     }

     const convertDateFormat=(date)=>{
        var info = longDateToInput(date).split('-').reverse().join('/');
        return info;
     }

     const validDate=(index, value, e)=>{
        if(value===''){
            e.target.className="inpTable minIn is-invalid";
        }else{
            e.target.className="inpTable minIn";
        }
     }

     const keysAllowed = (e) =>{
        return e.keyCode!==8 && e.keyCode!==9 && !(e.keyCode >= 37 && e.keyCode <= 40 ) && !((e.keyCode === 65 || e.keyCode === 67) && (e.ctrlKey || e.metaKey ));
    }

     const validarInput=e=>{
        
        const number = /^[0-9]$/; 
        if( !number.test(e.key) && keysAllowed(e) ){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
    }
    const executeChange = (value, index) => { 
        updateData('periodoFeccorte', value.getTime(), index );
    }

      const generateTableData=()=>{

         let res=[];  
         for(let i =0; i < dta.length; i++){
            res.push(
               <tr key={`${columnsTable[i]}-${i}`} className="MuiTableRow-root">
                  {
                    columnsTable.map((key, index)=>{ 
                        if(key==='periodoDiasImss') 
                        return  <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                    <input type="text" name={`${i}${key}`} onBlur={e=> {validNumber(e.target.id, e.target.value, e)}}  
                                        onKeyDown = { e => { validarInput(e) } } maxLength={8} 
                                        className={`inpTable minIn`} defaultValue={dta[i][key]} id={i} onChange={e =>updateData(key, e.target.value, e.target.id )}/>
                                </td>
                        if(key==='periodoFeccorte'){
                            return  <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                    {
                                        isSafari
                                        ? 
                                            <InputDateValidation  title="" defaultValue={longDateToInput(dta[i][key])} refValue={ inputSafariRefs } onChangeExecute={executeChange} informacionAdicional={i}/>
                                        :
                                            <input type="date" name={`${i}${key}`}  onBlur={e=> {validDate(e.target.id, e.target.value, e)}}  className={`inpTable minIn`} defaultValue={longDateToInput(dta[i][key])} id={i} onChange={e =>updateData(key, e.target.value, e.target.id )}/>
                                    } 
                                </td>
                        }
                        
                        
                        return <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">{
                                key==='periodoFecini' || key==='periodoFecfin' ? <span name={`${i}${key}`}>{convertDateFormat(dta[i][key])}</span>  : key==='periodoProcesado' ? dta[i][key]?<span name={`${i}${key}`}>{'Sí'}</span>:<span name={`${i}${key}`}>{'No'}</span>: 
                        key==='accion' && !dta[i]['periodoProcesado'] && i === dta.length-1 ? <span name={`${i}${key}`}>{permisosUsuario.includes(3) &&<IconButton  onClick={e => alertDelete(dta[i]['id'] ? dta[i]['id'] : dta[i]['periodoFecfin'])}><Delete fontSize="small"/></IconButton>}</span>: key==='accion' ? '' : <span name={`${i}${key}`}>{`${dta[i][key]}`}</span>}</td>                        
                    })
                  } 
               </tr>
            )
         }
         return res;
     }

     const alertDelete=(n)=>{ 
        
        setElemmentSelect(n)
        if(n.toString().length>10){
            setTextAlert(registro_sera_eliminado); 
            setAlertWarningLocal(true);
        }else{
            setAlertWarning( true ); 
        }
     }

     const delRow=()=> {
        setAlertSuccess(true);
        setTextAlert('Registro Eliminado');
        del(elemmentSelect);
      }

     const table=()=>{
         if(dta)
         return  <div><table style={{overflowX: "scroll"}} className="MuiTable-root">
            <thead className="MuiTableHead-root">
                <tr className="MuiTableRow-root MuiTableRow-head">
                {generateHeader()}
                </tr>
            </thead>
            <tbody className="MuiTableBody-root">
                {dta ? generateTableData() : null}
                 
                {dta.length<1  ?
                    <tr style={{textAlign: "center", height: "245px"}}>
                        <td colSpan={columnHeaderShow.length}>{loadingData ? <SpinnerOval/> : 'No existen registros'}</td>
                    </tr>
                :null}
            </tbody>
     </table></div> ;
     }
     
     const saveInfo= () =>{
        setLoading(true); 
        const obj=[]; 
        var validos=true;
        var invalidos=[]; 
        dta.map((key, index)=>{
            
            obj.push({
                periodoAnio: key['periodoAnio'],
                periodoMes:key['periodoMes'],
                periodoPerMes:key['periodoPerMes'],
                periodoPerAnio: key['periodoPerAnio'],
                periodoFecini: key['periodoFecini'],
                periodoFecfin: key['periodoFecfin'],
                periodoFeccorte: key['periodoFeccorte'],
                periodoDiasNom: key['periodoDiasNom'],
                periodoDiasImss: key['periodoDiasImss']
            }); 

            if(key['periodoDiasImss']===0 || isNaN(key['periodoDiasImss']) || key['periodoFeccorte']==='' || isNaN(key['periodoFeccorte'])){
                validos=false;
                if(key['periodoFeccorte']===''){
                    invalidos.push(index);
                }
            }
            return null;
        }) 

        if(validos){ 
            if(isNew){ 
                insertData({periodosNom:obj});
            }else if(shouldUpdate){
                updateDataBack({periodosNom:obj});
            }else{
                setTextAlert("¡No hay cambios por guardar!");
                setAlertSuccess(true);
                setLoading(false);
            }
        }else{
            setLoading(false);
            for (const key in invalidos) {
                invalidInputs(invalidos[key]);
            } 
            setTextAlert(campos_vacios)
            setAlertError(true);
        } 
     }
     
     const insertData=(request)=>{ 
        Axios.post(`${servicios_grupogack}/nomina/config/periodos/${idTab.id}`, request , headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        setLoading(false);
                        setTextAlert(res.data.msg);
                        setAlertSuccess(true);
                        setIsNew(false);
                        break;
                    case 404:
                        setLoading(false);
                        setAlertError(true);
                        break;
                    case 400:
                        setLoading(false);
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                    default:
                        alert('error');
                        setLoading(false);
                        setTextAlert(cambios_no_guardados);
                        setAlertError(true);
                        break;
                }
            }).catch(error =>  {setLoading(false); alert(error)});
     }
     
     const updateDataBack =(request)=>{
        Axios.put(`${servicios_grupogack}/nomina/config/periodos/${idTab.id}`,request, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setLoading(false);
                    setTextAlert(res.data.msg);
                    setAlertSuccess(true); 
                    setShouldUpdate(false);
                    break;
                case 404:
                    setLoading(false);
                    setAlertError(true);
                    break;
                case 400:
                    setLoading(false);
                    setTextAlert(res.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setLoading(false);
                    alert('error');
                    setTextAlert(cambios_no_guardados);
                    setAlertError(true);
                    break;
            }
        }).catch(error => {setLoading(false); alert(error)});
     }
 
     const agregar = ( newElement ) => { 
        setDta( dta.concat(newElement) ); 
        setShouldUpdate(true);
        idTabBack.fechaFin=longDateToInput(newElement[newElement.length-1].periodoFecfin+86400000+86400000);
    }

    const closeForm=()=>{ 
        setIdTabBack({id: idTab.id, fechaFin:''});
        onShowTable(false, {});
    }
   return (
       <div id={idModulo} className="back MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded">
            {
                
                <div className="row justify-content-between titleDinamicTable">
                    <div className="col-auto mr-auto "> 
                        <div className="form-inline" > 
                            <div className=" form-inline">
                                <b>Clave: </b>{`  ${idTab.nominaClave}`} 
                                <b style={{paddingLeft:'3rem'}}>Nómina: </b> <p style={{paddingRight:'3rem'}}>{` ${idTab.nominaNombre}`}</p>
                            </div>
                            {
                                ( permisosUsuario.includes(1) || permisosUsuario.includes(2) ) &&
                                <Button className="form-control btn-third" onClick={()=>setOpenAdd(true)} style={{marginRight: "10px"}}> 
                                    <AddIcon/>
                                </Button>   
                            }              
                        </div>
                    </div>
                    <div className="col-auto form-inline">
                        {   
                            loading
                            ? 
                                <SpinnerOval/>
                            :
                            ( permisosUsuario.includes(1) || permisosUsuario.includes(2) ) &&
                            <button className="form-control btn-color" onClick={()=>saveInfo()}  style={{marginRight: "10px"}}> 
                                <DoneIcon/>
                            </button>
                        }
                        <button className="form-control btn-outline" onClick={()=>{closeForm()}}  > 
                                <CloseIcon/>
                        </button>
                    </div> 
                </div> 
            }         
           {
               <div style={{overflowX: "scroll"}} className="ContenedorDataTable">
                  {table()}
               </div>
           }
           {obtenerModalDelete( idModulo, alertWarning, setAlertWarning, elemmentSelect, null, delRow, errorTable, null )}
           { obtenerModalAdd( idModulo, openAdd, setOpenAdd, agregar, null, errorTable, null, idTabBack ) }
            <Alertwarning show={alertWarningLocal} SetopenALert={setAlertWarningLocal} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={delRow } />
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
       </div>

   );
}
 
export default DinamicTablePeriodosNomina;
import React, { useState, useEffect, Fragment, createRef } from 'react';
import Axios from 'axios';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { servicios_grupogack_admon } from '../../../../../../Constants/Constants';
import ModalAddFaltante from './ModalAddFaltante';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertForm from '../../../../../../Services/AlertForm';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import { Tooltip } from 'reactstrap';
import AddIcon from '@material-ui/icons/Add';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const FormProyectos = ({ error, setError, enviarProyecto, elementSelect, actualizarProyecto, agregar, errorTable, setOpenAddFirst, idModulo }) => {


    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
    const [uResponsable, setUResponsable] = useState([]);
    const [uEjecutora, setUEjecutora] = useState([]);
    const [programa, setPrograma] = useState([]);
    const [pAccion, setPAccion] = useState([]);
    const [selected, setSelected] = useState({ uResponsable: { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
    const [disabled, setDisabled] = useState({ uEjecutora: true, programa: true, pAccion: true, cvePresupuestal: true });
    const [openAdd, setOpenAdd] = useState(false);
    const [info, setInfo] = useState({});
    const [tooltipOpenCtrTrabajo, setTooltipOpenCtrTrabajo] = useState(false);

    const clavePresupuestalClaveRef = createRef('');
    const clavePresupuestalNombreRef = createRef('');

    const toggle = () => {
        setTooltipOpenCtrTrabajo(!tooltipOpenCtrTrabajo);
    }

    useEffect(() => {
        Axios.get(`${servicios_grupogack_admon}/compania`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setUResponsable(res.data.dataResponse);
                    break;
                case 404:
                    setUResponsable([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    const enviar = e => {
        e.preventDefault();
        if(!clavePresupuestalNombreRef.current.value) {
            setError(<AlertForm message="Complete los campos" />);
        } else {
            if(validarSubmit('AgregarProyecto')) {
                const clavePresupuestalClave = clavePresupuestalClaveRef.current.value;
                const clavePresupuestalNombre = clavePresupuestalNombreRef.current.value;
                enviarProyecto({ proyectoid: selected.pAccion.id, cpresupuestalclave: `${selected.pAccion.clave}${clavePresupuestalClave}`, cpresupuestalnom: clavePresupuestalNombre });
            }
        }
    };

    const actualizar = e => {
        e.preventDefault();
        if(validarSubmit('EditarProyecto')) {
            const clavePresupuestalClave = clavePresupuestalClaveRef.current.value;
            const clavePresupuestalNombre = clavePresupuestalNombreRef.current.value;
            setError(null);
            actualizarProyecto({ proyectoid: elementSelect.proyectoid, cpresupuestalclave: `${elementSelect.cpresupuestalclave.substr(0,17)}${clavePresupuestalClave}`, cpresupuestalnom: clavePresupuestalNombre }, elementSelect.id);
        }
    };

    const activaBtn = (campo, valor) => {
        switch (campo) {
            case "UResponsable":
                if(valor !== null) {
                    setDisabled({ uEjecutora: false, programa: true, pAccion: true, cvePresupuestal: true });
                } else {
                    setDisabled({ uEjecutora: true, programa: true, pAccion: true, cvePresupuestal: true });
                    setSelected({ uResponsable : { id: '', clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "UEjecutora":
                if(valor !== null) {
                    setDisabled({ ...disabled, programa: false, pAccion: true, cvePresupuestal: true });
                } else {
                    setDisabled({ ...disabled, programa: true, pAccion: true, cvePresupuestal: true });
                    setSelected({ ...selected, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "Programa":
                if(valor !== null) {
                    setDisabled({ ...disabled, pAccion: false, cvePresupuestal: true });
                } else {
                    setDisabled({ ...disabled, pAccion: true, cvePresupuestal: true });
                    setSelected({ ...selected, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            case "PAccion":
                if(valor !== null) {
                    setDisabled({ ...disabled, cvePresupuestal: false });
                } else {
                    setDisabled({ ...disabled, cvePresupuestal: true });
                    setSelected({ ...selected, pAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
                }
            break;
            default:
                setDisabled({ uEjecutora: true, programa: true, pAccion: true, cvePresupuestal: true });
                setSelected({ uResponsable : { id: '',  clave: '', nombre: '' }, uEjecutora: { id: '', clave: '', nombre: '' }, programa: { id: '', clave: '', nombre: '' }, pAccion: { id: '', clave: '', nombre: '' }, cvePresupuestal: { id: '', clave: '', nombre: '' } });
            break;
        }
    };

    const validarUResponsable = valor => {
        activaBtn("UResponsable", valor);

        if(valor) {
            setSelected({...selected, uResponsable:{id: valor.id, clave: valor.ciaUr , nombre : valor.ciaRazonSocial}});
            
            Axios.get(`${servicios_grupogack_admon}/ejecutora/compani/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setUEjecutora(res.data.dataResponse);
                        break;
                    case 404:
                        setUEjecutora([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
        } else {
            setUEjecutora([]);
        }
    };

    const validarUEjecutora = valor => {
        activaBtn("UEjecutora", valor);
        
        if(valor) {
            setSelected({...selected, uEjecutora: {id: valor.id, clave : valor.udejecutoraclave, nombre : valor.udejecutoranom}});
        
            Axios.get(`${servicios_grupogack_admon}/programa/ejecutora/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPrograma(res.data.dataResponse);
                        break;
                    case 404:
                        setPrograma([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
        }
    };

    const validarPrograma = valor => {
        activaBtn("Programa", valor);

        if(valor) {
            setSelected({...selected, programa: {id: valor.id, clave : valor.programaclave, nombre : valor.programanom}});
    
            Axios.get(`${servicios_grupogack_admon}/proyecto/programa/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setPAccion(res.data.dataResponse);
                        break;
                    case 404:
                        setPAccion([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
        }
    };

    const validarPAccion = valor => {
        activaBtn("PAccion", valor);
        
        if(valor) {
            setSelected({...selected, pAccion: {id: valor.id, clave : valor.proyectoclave, nombre : valor.proyectonom}});
        }
    };

    const abrirModal = (e, info) => {
        e.preventDefault();
        setInfo({ uResponsable: selected.uResponsable, uEjecutora: selected.uEjecutora, programa: selected.programa, pAccion: selected.pAccion, info });
        setOpenAdd(true);
    };

    return (
        <Fragment>
            <form id={elementSelect ? 'EditarProyecto' : 'AgregarProyecto'} onSubmit={ elementSelect ? actualizar : enviar }>
                {
                    (enviarProyecto)
                    ?   <Fragment>
                            <AutocompleteComponent
                                id="uResponsable"
                                title="Unidad Responsable"
                                options={uResponsable}
                                optionListView="ciaRazonSocial"
                                action={validarUResponsable}
                                placeholder="Unidad Responsable"
                                tooltipText="Busque la Unidad Responsable, por nombre o clave, en el campo de autocompletado"
                                tooltipName="tooltipUResponsable"
                                required
                            />
                            <hr />
                            <div className="row">
                                <div className="col-10">
                                    <AutocompleteComponent
                                        id="uEjecutora"
                                        title="Unidad Ejecutora"
                                        options={uEjecutora}
                                        optionListView="udejecutoranom"
                                        action={validarUEjecutora}
                                        disabled={disabled.uEjecutora}
                                        placeholder="Unidad Ejecutora"
                                        tooltipText="Busque la Unidad Ejecutora, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                        tooltipName="tooltipuEjecutora"
                                        required
                                    />
                                </div>
                                <div className="col-2 align-self-top">
                                    <button className="btn-autocomplete" disabled={disabled.uEjecutora} onClick={e => abrirModal(e, 1)}><AddIcon/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10">
                                    <AutocompleteComponent
                                        id="programa"
                                        title="Programa"
                                        options={programa}
                                        optionListView="programanom"
                                        action={validarPrograma}
                                        disabled={disabled.programa}
                                        placeholder="Programa"
                                        tooltipText="Busque el Programa, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                        tooltipName="tooltipPrograma"
                                        required
                                    />
                                </div>
                                <div className="col-2 align-self-top">
                                    <button className="btn-autocomplete" disabled={disabled.programa} onClick={e => abrirModal(e, 2)}><AddIcon/></button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10">
                                    <AutocompleteComponent
                                        id="pAccion"
                                        title="Proyecto Acción"
                                        options={pAccion}
                                        optionListView="proyectonom"
                                        action={validarPAccion}
                                        disabled={disabled.pAccion}
                                        placeholder="Proyecto Acción"
                                        tooltipText="Busque el Proyecto Acción, por nombre o clave, en el campo de autocompletado o de clic en el botón + para agregar una nueva"
                                        tooltipName="tooltipPAccion"
                                        required
                                    />
                                </div>
                                <div className="col-2 align-self-top">
                                    <button className="btn-autocomplete" disabled={disabled.pAccion} onClick={e => abrirModal(e, 3)}><AddIcon/></button>
                                </div>
                            </div>
                        </Fragment>
                    :   <Fragment>
                            <label>Unidad Responsable</label>
                            <input type="text" className="form-control" defaultValue={elementSelect.ciaRazonSocial} disabled/>
                            <hr />
                            <label>Unidad Ejecutora</label>
                            <input type="text" className="form-control" defaultValue={elementSelect.udejecutoranom} disabled/>
                            <br/>
                            <label>Programa</label>
                            <input type="text" className="form-control" defaultValue={elementSelect.programanom} disabled/>
                            <br/>
                            <label>Proyecto Acción</label>
                            <input type="text" className="form-control" defaultValue={elementSelect.proyectonom} disabled/>
                            <br/>
                        </Fragment>
                }
                <Fragment>
                    <div className="row">
                        <div className="col-12">
                            <label>Centro de Trabajo / Proyecto / Clave Presupuestal</label>
                        </div>
                    </div>
                    <label>
                        Clave<strong className="requerido">* </strong>
                        <span id="cveCtrTrabajo" className={isSafari ? "spanInfoSelect" : "spanInfo"}>
                            <InfoRoundedIcon color="disabled"/>
                        </span>
                    </label>
                    <Tooltip placement="right" isOpen={tooltipOpenCtrTrabajo} target="cveCtrTrabajo" toggle={toggle}>
                        { '2 caracteres alfanuméricos o caracteres especiales = @ # * < >' }
                    </Tooltip>
                    <div className="row">
                        <div className="col-7">
                            <label className="form-control label-control">
                                { elementSelect ? elementSelect.cpresupuestalclave.substr(0,17) : selected.pAccion.clave }
                            </label>
                        </div>
                        <div className="col-5">
                            <InputValidation type="claveCvePresupuestal" placeholder="Clave" refValue={clavePresupuestalClaveRef}
                                defaultValue={elementSelect ? elementSelect.cpresupuestalclave.substr(17,2) : ''} maxLength={2}
                                disabled={!elementSelect ? (disabled.cvePresupuestal === false) ? false : true : false}/> 
                        </div>
                    </div>
                    <InputValidation title="Nombre" type="nombreCvePresupuestal" placeholder="Nombre"
                        refValue={clavePresupuestalNombreRef} defaultValue={elementSelect ? elementSelect.cpresupuestalnom : ''} maxLength={128}
                        disabled={!elementSelect ? (disabled.cvePresupuestal === false) ? false : true : false}
                        tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'}
                        tooltipName="tooltipNomCvePress" required/>
                </Fragment>
                { error }
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <ModalAddFaltante openAdd={openAdd} setOpenAdd={setOpenAdd} info={info} agregar={agregar} errorTable={errorTable}
                setOpenAddFirst={setOpenAddFirst}  idModulo={idModulo}/>
        </Fragment>
    );
}

export default FormProyectos;
import React from 'react';
import axios from 'axios';
import { servicios_grupogack, registro_sera_eliminado, registro_no_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';

const ModalDeleteReport = ({ openDelete, setOpenDelete, elementSelect, eliminar, error}) => {

    const axiosEliminar = () => {
        axios.delete( `${servicios_grupogack}/configuracion/reportes/${elementSelect.id ? elementSelect.id : elementSelect.reportesId }`, headersAuth() )
            .then( async (respuesta) => {
                switch( respuesta.data.status ){
                    case 200:
                        eliminar( elementSelect.id ? elementSelect.id : elementSelect.reportesId );
                        break;
                    case 400:
                        error( respuesta.data.msg );
                        break;
                    default:
                        error( respuesta.data.msg );
                        break;
                }
            })
            .catch( error => {
                error( registro_no_eliminado );
            });  
    }

    return (
        <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
    );
};

export default ModalDeleteReport;
import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';  
import ArrowBackIcon from '@material-ui/icons/ArrowBack'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import { deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import { ArrayJsonEmpleadosReportesNominas } from '../../../Helpers/JsonToOneLevel';

const NominaTablaReporte = ({ id, permisos, openReport, elementSelect, onAdd, infoReport, setInfoReport}) => {
    
    let filtroGeneral ='';
    let acumulados = [];
    let data = elementSelect.id ? JSON.parse(elementSelect.reporteJson)[0].infoReport : elementSelect.reportesId ? JSON.parse(elementSelect.reportesReporteJson)[0].infoReport : null;
    const convertColumns = (columns)=>{ 
        filtroGeneral = columns[0].filtroGeneral;
        data = columns[0].infoReport;
        let resultado = columns[0].campos.map(element =>{ return deleteOptionals({
            field        : element.nombre,
            title        : element.titulo==='Clave Nómina' ? 'Concepto' : element.titulo,
            defaultSort  : element.ordenDefault,
            defaultFilter: element.defaultFiltro,
            hidden: element.oculto,
            defaultGroupOrder: element.posicionDefaultGrupo,
            defaultGroupSort: element.ordenDefaultGrupo,
            cellStyle: rowData => ({ textAlign: element.nombre==='cantidad' ||element.nombre==='monto'? 'right':'left'})
        })}); 
        return resultado; 
    }
    
    const [empleados, setEmpleados] = useState(infoReport ? elementSelect : null);
    
    const columns = infoReport ? [
        { field: "empleadoClave", title: "Número de Empleado", hidden:false},
        { field: "nombreCompleto", title: "Nombre", hidden:false}, 
        { field: "empleadoFecnac", title: "Fecha de Nacimiento", hidden:false },
        { field: "empleadoCurp", title: "CURP", hidden:false },
        { field: "empleadoRfc", title: "RFC", hidden:false},
        { field: "empleadoNss", title: "NSS", hidden:false}, 
        { field: "cpresupuestalClave", title: "Clave Presupuestal", hidden:false }, 
        { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto", hidden:false },
        { field: "ciaRazonSocial", title: "Razon Social", hidden:false},
        { field: "nomindClave", title: "Clave Concepto", hidden:false }, 
        { field: "tiponatuNom", title: "Nombre Tipo Naturaleza", hidden:false },
        { field: "nomindNomIndicador", title: "Nombre Indicador", hidden:false },
        { field: "cantidad", title: "Cantidad", cellStyle: rowData => ({ textAlign:'right'}), hidden:false },
        { field: "monto", title: "Monto", cellStyle: rowData => ({ textAlign:'right'}), hidden:false },
        { field: "acumuladoClave", title: "Clave Acumulado" },
        { field: "acumuladoNombre", title: "Nombre Acumulado" },
    ]: elementSelect.id ?
            convertColumns(JSON.parse(elementSelect.reporteJson))  
        : 
            elementSelect.reportesId && convertColumns(JSON.parse(elementSelect.reportesReporteJson));

    useEffect(() => {
        if(!infoReport){ 
            axios.get( `${servicios_grupogack_nomina}/vacumperxnom/${data.nominaId}/${data.periodoperanio}/${data.periodoanio}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setEmpleados(ArrayJsonEmpleadosReportesNominas(respuesta.data.dataResponse));  
                        break;
                    case 404:
                        setEmpleados([]);
                        break;
                    default: 
                        break;
                }
            })
            .catch( error => { 
            });
        }
        // eslint-disable-next-line  
    }, []);
    
    return (
        <div id={ id }>
            
            {
                empleados && columns
                ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-11"> 
                        {
                            empleados[0] &&
                            <Fragment>
                                {
                                    empleados.every(item=>item.acumuladoClave===empleados[0].acumuladoClave)
                                    ?  
                                        <div> <b> Clave Acumulado: </b> {empleados[0].acumuladoClave} <b> Nombre Acumulado: </b> {empleados[0].acumuladoNombre} </div>
                                    :
                                        
                                        empleados.map((item, index)=>{
                                            if(!acumulados.includes(item.acumuladoClave) || index===0){
                                                acumulados.push(item.acumuladoClave);
                                                return <div> <b> Clave Acumulado: </b> {item.acumuladoClave} <b> Nombre Acumulado: </b> {item.acumuladoNombre} </div>
                                            }
                                            return null;   
                                        })

                                }
                                <div> <b> Fecha Periodo: </b> {empleados[0].periodoFecini} <b> - </b> {empleados[0].periodoFecfin} </div>
                                <div style={{marginBottom:'1rem'}}> <b> Año: </b> {empleados[0].periodoAnio} <b> Periodo: </b> {empleados[0].periodoPeranio} <br/> </div>
                                  
                            </Fragment>
                        }
                        </div>
                        <div className="col-1 float-left" style={{marginBlockEnd:'18px'}}>
                            <button className="form-control" onClick={()=>{openReport(false); setInfoReport(null)}}> 
                                <Tooltip title="Regresar" placement="left-start">
                                    <ArrowBackIcon/>
                                </Tooltip>
                            </button> 
                        </div>
                    </div> 
                    <DataTableService id={id} title={'Reporte de Nóminas'}
                        columns={columns}
                        data={empleados}
                        isReport
                        hideIconAdd
                        hideIconDelete
                        hideIconEdit
                        informacionAdicional={{filtroGeneral, infoReport: elementSelect.reportesId ? JSON.parse(elementSelect.reportesReporteJson)[0].infoReport : infoReport}}
                        onShowTable={onAdd} 
                    />

                </React.Fragment>
                :
                <SpinnerOval/>
            }
        </div>
    );
}
 
export default NominaTablaReporte;
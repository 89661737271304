import React, { useState } from 'react';

import axios from 'axios';
import { servicios_grupogack_mensajes } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';

import Button from '@material-ui/core/Button';
import Reply from '@material-ui/icons/Reply';
import Delete from '@material-ui/icons/Delete';

const AccionesPapelera = ({ mensaje, usuario, eliminarPapelera }) => {
    /* ----------------------------- Axios para restaurar mensajes desde Papelera ----------------------------- */
    const [ openRestore, setOpenRestore ] = useState( false );
    const axiosRestaurarPapelera = () => {
        axios.put( `${servicios_grupogack_mensajes}/restaura/${mensaje.id}/${usuario}`, null, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        eliminarPapelera( mensaje.id );
                        break;
                    case 400:
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        //errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                //errorTable( cambios_no_guardados );
            })
    }
    /* ------------------------------ Axios para eliminar mensajes desde Papelera ----------------------------- */
    const [ openDelete, setOpenDelete ] = useState( false );
    const axiosEliminarPapelera = () => {
        axios.delete( `${servicios_grupogack_mensajes}/papelera/eliminar/${mensaje.id}/${usuario}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        eliminarPapelera( mensaje.id );
                        break;
                    case 400:
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        //errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_eliminado );
            })
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <Button className="btn-incolor" onClick={ () => setOpenRestore(true) }> <Reply/> Restaurar </Button>
            <Button className="btn-incolor" onClick={ () => setOpenDelete(true) }> <Delete/> Eliminar </Button>

            <Alertwarning show={openRestore} SetopenALert={setOpenRestore} text="El elemento será restaurado"
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosRestaurarPapelera} />
            <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text="El elemento será eliminado definitivamente"
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminarPapelera} />
        </React.Fragment>
    );
};

export default AccionesPapelera;
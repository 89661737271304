import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_admon, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonProyectos } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormProyectos from './FormProyectos';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditProyectos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState( null );

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarProyecto = (proyecto, proyectoId) => {
        const info = proyecto;
        Axios.put(`${servicios_grupogack_admon}/presupuestal/${proyectoId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                const proyecto = JsonProyectos(res.data.dataResponse);
                editar(proyecto);
                setOpenEdit(false);
                break;
            case 400:
            case 404:
                setError(<AlertForm message={res.data.msg} />);
                break;
            default:
                errorTable(cambios_no_guardados);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Proyecto" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormProyectos
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarProyecto={actualizarProyecto}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditProyectos;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_url, auditoria_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import FormConsultaAuditoria from './FormConsultaAuditoria';

const Auditoria = ({ id, permisos }) => {
    const [ auditorias, setAuditorias ] = useState( null );
    const [ modulosApi, setModulosApi ]= useState(null);
    useEffect(() => {
        axios.get( `${servicios_grupogack_url}/menu`, headersAuth() )
            .then( res => {
                switch( res.data.status ){
                    case 200:
                        setModulosApi(res.data.dataResponse.menu);
                        break;
                    default:
                        break;
                }
            } )
            .catch( error => { console.log( error ) });
    }, []);
 

    return (
        <div id={ id }>
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    {
                        modulosApi ? 
                        <FormConsultaAuditoria setAuditorias={setAuditorias} modulosApi={modulosApi}/>
                        : <SpinnerOval/>
                    }
                    
                </div>
            </div>
            { auditorias &&
                <div style={{marginTop:'2rem'}}>
                    <DataTableService id={id} title={ auditoria_ } permisos={permisos}
                        columns={[
                            { field: "usuario", title: "Usuario" },
                            { field: "date", title: "Fecha" },
                            { field: "moduleName", title: "Módulo" },
                            { field: "crudOperation", title: "Operación" }
                        ]}
                        data={ auditorias } 
                        hideIconAdd
                        hideIconDelete
                        hideIconEdit
                        showIconDescription 
                        />
                </div>
            }
        </div>
    );
}
 
export default Auditoria;
import React from 'react';
import MaterialTable from 'material-table';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
import { localization, tableIcons } from '../../Components/Usuarios/Usuario/DataTableService/ElementTable';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#81A1DB' },
        secondary: { main: '#81A1DB' },
    },
});

const TableSelectRows = ({ title, columns, data, icon, tooltip, action }) => {

    const optionsTable = {
        columnsButton: false,
        headerStyle: { zIndex: "0", color: '#4C638E', fontweight: 'bold' },
        actionsColumnIndex: -1,
        searchFieldAlignment: "left",
        selection: true
    };

    return (
        <MuiThemeProvider theme={theme}>
            <MaterialTable
                title={title}
                columns={columns}
                data={data}
                options={optionsTable}
                icons={tableIcons}
                localization={localization}
                actions={[
                    {
                        tooltip,
                        icon,
                        onClick: (evt, data) => action(data)
                    }
                ]}
                style={{ boxShadow: '0px 0px 0px 0px' }}
            />
        </MuiThemeProvider>
    );
}
 
export default TableSelectRows;
import React, { Fragment, useState } from 'react';
import ConvertDate from '../../../../Helpers/ConvertDate';
import ButtonReturn from '../components/ButtonReturn';
import Alert from '../../../../../../../Services/Alerts/AlertSyE';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import CanceladoExitoso from '../components/TimbradoCanceladoExitoso';
import CanceladoErrores from '../components/TimbradoCanceladoErrores';
import TableSelectRows from '../../../../../../../Services/Tables/TableSelectRows';
import BlockIcon from '@material-ui/icons/Block';

const TableCancelar = ({ title, data, setData, hidenTable, setOpenLoading, setMostrarTablaCancelar, setMostrarTablaConsultar }) => {

    const [openAlertW, setOpenAlertW] = useState(false);
    const [openAlertRSuccess, setOpenAlertRSuccess] = useState(false);
    const [openAlertRWarning, setOpenAlertRWarning] = useState(false);
    const [valueCancel, setValueCancel] = useState(false);

    const openAlertCancel = value => {
        setValueCancel(value);
        setOpenAlertW(true);
    };

    const cancelarCFDI = () => {
        setOpenLoading(true);
        
        setTimeout(() => {
            setOpenLoading(false);
            setOpenAlertRSuccess(true);
            setTimeout(() => {
                console.log(valueCancel);
                setOpenAlertRSuccess(false);
                setOpenAlertRWarning(true);
            }, 3000);
        }, 3000);
    };

    const showTable = () => {
        setData({
            title: <strong>CVE- INTERNA</strong>,
            data: [
                {
                    numRegistro: 1,
                    status: 'Código 123: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel felis a turpis fringilla vulputate et at dolor. Sed finibus facilisis accumsan. Duis bibendum a libero sed elementum. Nulla et magna quis elit scelerisque dapibus volutpat vitae lectus. Nulla mattis.',
                    numEmpleado: 112233,
                    nombre: 'Juan Pérez',
                    periodo: 9,
                    anio: 2020,
                    acumulado: 'Nómina',
                    fechaOperacion: ConvertDate(Date.now())
                },
                {
                    numRegistro: 2,
                    status: 'Código 123: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel felis a turpis fringilla vulputate et at dolor. Sed finibus facilisis accumsan. Duis bibendum a libero sed elementum. Nulla et magna quis elit scelerisque dapibus volutpat vitae lectus. Nulla mattis.',
                    numEmpleado: 112234,
                    nombre: 'Carlos Ramírez',
                    periodo: 9,
                    anio: 2020,
                    acumulado: 'Nómina',
                    fechaOperacion: ConvertDate(Date.now())
                },
                {
                    numRegistro: 3,
                    status: 'Código 123: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque vel felis a turpis fringilla vulputate et at dolor. Sed finibus facilisis accumsan. Duis bibendum a libero sed elementum. Nulla et magna quis elit scelerisque dapibus volutpat vitae lectus. Nulla mattis.',
                    numEmpleado: 112235,
                    nombre: 'Josué Cuervo',
                    periodo: 9,
                    anio: 2020,
                    acumulado: 'Nómina',
                    fechaOperacion: ConvertDate(Date.now())
                }
            ]
        });
        setMostrarTablaCancelar(false);
        setMostrarTablaConsultar(true);
    };

    return (
        <Fragment>
            <ButtonReturn action={hidenTable} type="cancelar"/>
            <TableSelectRows
                title={title}
                columns={[
                    { field: 'numRegistro', title: 'N° Registro' },
                    { field: 'numEmpleado', title: 'Número de Empleado' },
                    { field: 'rfcEmisor', title: 'RFC Emisor' },
                    { field: 'nombre', title: 'Nombre' },
                    { field: 'acumulado', title: 'Acumulado' },
                    { field: 'uuid', title: 'UUID' },
                    { field: 'versionCFDI', title: 'Versión de CFDI' },
                    { field: 'fecha', title: 'Fecha' },
                ]}
                data={data}
                icon={BlockIcon}
                tooltip="Cancelar CFDI"
                action={openAlertCancel}
            />
            <AlertWarning show={openAlertW} SetopenALert={setOpenAlertW} text="¡Se cancelarán los CDFDI de los empleados seleccionados!" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={cancelarCFDI}/>
            <Alert show={openAlertRSuccess} setOpenAlertSyE={setOpenAlertRSuccess} title="Petición Exitosa" text={<CanceladoExitoso type="cancelados"/>} textbtnconfirm="Aceptar" type="success" />
            <AlertWarning show={openAlertRWarning} SetopenALert={setOpenAlertRWarning} title="Aviso" text={<CanceladoErrores type="cancelados"/>} textButtonAceptar="Ver Errores" action={showTable}/>
        </Fragment>
    );
}
 
export default TableCancelar;
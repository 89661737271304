import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormTiposDomicilio = ({ error, setError, enviarTipoDomicilio, elementSelect, actualizarTipoDomicilio }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const nombreRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('TipoDomicilio')) {
            const tipodomNom = nombreRef.current.value;
            enviarTipoDomicilio({ tipodomNom });
        }
    };

    const actualizar = () => {
        if(validarSubmit('TipoDomicilio')) {
            const tipodomNom = nombreRef.current.value;
            setError(null);
            actualizarTipoDomicilio({ tipodomNom }, elementSelect.id);
        }
    };

    return ( 
        <Fragment>
            <form id="TipoDomicilio" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Nombre" type="nombreTipoDomicilio" placeholder="Escriba el nombre del tipo de domicilio"
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.tipodomNom : '' } 
                    tooltipText={'Máximo 120 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName="tooltipNomTipDom" maxLength={120} required/>
                { error }
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}

export default FormTiposDomicilio;
import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

const BlueRadio = withStyles({
    root: {
      color: '#1572E8',
      '&$checked': {
        color: '#1572E8',
      },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);

const Sindicato = ({ infoSindicato, sindicatoRef, sindicalizado, setSindicalizado, cuotaSindical, setCuotaSindical, sindicatoSeleccionado, setSindicatoSeleccionado }) => {
    
    const [sindicatos, setSindicatos] = useState([]);

    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}/sindicatos/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setSindicatos(res.data.dataResponse);
                    break;
                default:
                    setSindicatos([]);
                    break;
            }
        })
        .catch(error =>  setSindicatos([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if(infoSindicato) {
            const sindicato = sindicatos.find(sindicato => sindicato.id === infoSindicato.sindicato.id);
            if(sindicato) {
                setSindicalizado('si');
                setSindicatoSeleccionado(sindicato);
                setCuotaSindical(infoSindicato.aplica);
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sindicatos]);

    const estableceCuota = info => {
        if(info !== '') {
            const sindicato = sindicatos.find(sindicato => sindicato.id === parseInt(info));
            if(sindicato) {
                setSindicatoSeleccionado(sindicato);
            };
        } else {
            setCuotaSindical('no');
        }
    };

    const handleRadioSindicatoChange = (event) => {
        setSindicalizado(event.target.value);

        if(event.target.value === 'no') {
            setCuotaSindical('no');
            setSindicatoSeleccionado({
                id: '',
                sindicatoNombre: null,
                sindicatoPoc: null,
                sindicatoMonto: 0
            });
        }
    };

    const handleRadioCuotaChange = (event) => {
        if(sindicatoRef.current) {
            if(sindicatoRef.current.value === '') {
                setCuotaSindical('no');
            } else {
            setCuotaSindical(event.target.value);
            }
        } else {
            setCuotaSindical('no');
        }
        
    };
    
    return (
        <Fragment>
            <h5>Sindicato</h5>
            <div className="row justify-content-center align-items-center">
                <div className="col">
                    <label>¿Empleado Sindicalizado?</label>
                </div>
                <div className="col">
                    <FormControl component="fieldset">
                        <RadioGroup row aria-label="position" name="position"
                            defaultValue={infoSindicato ? (infoSindicato.id === '') ? 'no' : 'si' : 'no'}
                            onChange={handleRadioSindicatoChange}
                        >
                            <FormControlLabel value="si" control={<BlueRadio />} label="Sí"/>
                            <FormControlLabel value="no" control={<BlueRadio />} label="No"/>
                        </RadioGroup>
                    </FormControl>
                </div>
            </div>
            { 
                sindicalizado === 'si'
                ?   <Fragment>
                        <SelectValidation title="Sindicato" arrayOpciones={sindicatos} keyOpcion="sindicatoNombre"
                            refValue={sindicatoRef} onChangeExecute={estableceCuota}
                            defaultValue={infoSindicato ? sindicatoSeleccionado.id : ' '} required/>
                        <div className="row justify-content-center align-items-center">
                            <div className="col">
                                <label>¿Aplica Cuota Sindical?</label>
                            </div>
                            <div className="col">
                                <FormControl component="fieldset">
                                    <RadioGroup row aria-label="position" name="position" value={cuotaSindical}
                                        onChange={handleRadioCuotaChange}
                                    >
                                        <FormControlLabel value="si" control={<BlueRadio />} label="Sí"/>
                                        <FormControlLabel value="no" control={<BlueRadio />} label="No"/>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        {
                            cuotaSindical === 'si'
                            ?   <div className="row">
                                    <div className="col">
                                        <label>Cuota Sindical</label>
                                    </div>
                                    <div className="col">
                                        <strong>
                                            { (sindicatoSeleccionado.sindicatoPoc === 'C') ? '$ ' : null }
                                            { `${parseFloat(sindicatoSeleccionado.sindicatoMonto).toFixed(2)}` }
                                            { (sindicatoSeleccionado.sindicatoPoc === 'P') ? ' %' : null }
                                        </strong>
                                    </div>
                                </div>
                            :   null
                        }
                    </Fragment>
                :   null
            }
        </Fragment>
    );
}
 
export default Sindicato;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import FileService from '../../../../CropService/FileService';

import AlertForm from '../../../../../../../Services/AlertForm';

import Tooltip from '@material-ui/core/Tooltip';
import GetApp from '@material-ui/icons/GetApp';
import Search from '@material-ui/icons/Search';
import { Progress } from 'reactstrap';
import CloudUpload from '@material-ui/icons/CloudUpload';

import csvDownload from 'json-to-csv-export';
import { obtenerInformacionExcel, axiosExcel } from './HelperMovimientosExcel';

const MovimientosExcel = ({ idModulo, errorTable, successTable, informacion }) => {
    /* ------------------------------------ Funcion para obtener Excel base64 por NominaID ----------------------------------- */
    const nominaIdRef = createRef('');
    const [ nominaSelect, setNominaSelect ] = useState( null );
    const [ excel64, setExcel64 ] = useState( null );

    const obtenerExcel64 = ( nominaID ) => {
        if( nominaID !== '' ){
            setNominaSelect( informacion.nominas.find( element => element.id === parseInt(nominaID) ) );
            //axiosExcelNominaID( nominaID );
            setExcel64( true );
        }else{
            setNominaSelect( null );
            setExcel64( null );
        }
    }

    const decargarExcel = () => {
        const empleadosNomina = informacion.empleados.filter( empleado => empleado.nominaId === nominaSelect.id );
        const formato = obtenerInformacionExcel( idModulo, empleadosNomina );
        if( formato ){
            csvDownload( formato, `${nominaSelect.nominaNombre}.csv` );
        }
    }
    /* ---------------------------------------- Funcion para obtener Tipo de Documento --------------------------------------- */
    const [ error, setError ] = useState( null );
    const [ progreso, setProgreso ] = useState( 0 );
    const [ documentoExcel, setDocumentoExcel ] = useState( null );

    const obtenerArchivo = ( file ) => {
        setDocumentoExcel( file );
    }

    const enviarExcel64 = () => {
        setError( null );
        if( documentoExcel.name.includes( 'csv' ) ){
            setProgreso( 100 );
            //axiosMultiplesExcel({ archivo: documentoExcel.base64 });
            axiosExcel( idModulo, { archivo: documentoExcel.base64 }, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
        }else{
            setError( <AlertForm message="Asegúrate de seleccionar un archivo con formato .csv" /> );
        }
    }
    /* ----------------------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <div className="movimientosFlex">
                <div className="movimientosIzquerdo">
                    <SelectValidation title="" arrayOpciones={informacion.nominas} keyOpcion="nominaNombre"
                        refValue={nominaIdRef} onChangeExecute={obtenerExcel64}/>
                </div>
                <div className="movimientosDerecho file-upload">
                    { excel64 ?
                        <Button className="btn-third btn-auto ml-2 mr-2" onClick={() => decargarExcel()}> <GetApp/> </Button>
                        :
                        <Button className="btn-third btn-auto ml-2 mr-2" disabled> <GetApp/> </Button>
                    }

                    <Tooltip title="Cargar archivo" placement="top-start">
                        <label htmlFor={`carga_excel_${idModulo}`} className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                    </Tooltip>
                    <FileService inputId={`carga_excel_${idModulo}`} getFile={obtenerArchivo} formatAccept={`.csv`}/>
                </div>
            </div>

            { documentoExcel &&
                <React.Fragment>
                    <div className="movimientosFlex mt-3">
                        <div className="movimientosIzquerdo upload">
                            <div>
                                <span> {documentoExcel.name} </span>
                                <button style={{ float: "right" }} type="button" className="close" onClick={() => {setDocumentoExcel( null ); setError(null);}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Progress animated value={progreso} />
                        </div>
                        <div className="movimientosUploadDerecho btn-upload">
                            <Button variant="contained" className="btn-color" onClick={() => enviarExcel64()}> <CloudUpload/> </Button>
                        </div>
                    </div>
                    { error }
                </React.Fragment>
            }
            <Button style={{ display: 'none' }}> Test </Button>
        </React.Fragment>
    );
};

export default MovimientosExcel;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioParentesco = ({ error, enviarParentesco, elementSelect, actualizarParentesco }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const nombreRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarParentesco' ) ){
            const parentesco_clave = claveRef.current.value;
            const parentesco_nom = nombreRef.current.value
            enviarParentesco( { parentesco_clave, parentesco_nom } );
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarParentesco' ) ){
            const parentesco_clave = claveRef.current.value;
            const parentesco_nom = nombreRef.current.value
            actualizarParentesco({ parentesco_clave, parentesco_nom }, elementSelect.id);
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarParentesco' : 'AgregarParentesco' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveParentesco" placeholder="Escriba la clave del parentesco"
                    tooltipText={'Máximo 2 letras y/o números'} tooltipName={'tooltipCveParen'} maxLength='2' alfanumeric
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.parentescoCve : '' } required/>
                <InputValidation title="Nombre" type="nombreParentesco" placeholder="Escriba el nombre del parentesco"
                    tooltipText={'Máximo 64 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomParen'} maxLength='64'
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.parentescoNom : '' } required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioParentesco;
import React, {  useState, useEffect } from 'react';
import { Button, createMuiTheme, MuiThemeProvider }from '@material-ui/core';
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation'; 
import { servicios_grupogack_url, servicios_grupogack } from '../../../../../../Constants/Constants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded'; 
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import { headersAuth } from '../../../../../../Services/AuthService';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../Media/Img/profile.jpg'; 
import ConvertDate from '../../../Helpers/ConvertDate';
import AlertForm from '../../../../../../Services/AlertForm';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#1572E8' },
        secondary: { main: '#1572E8' },
    },
    
});

const FormularioInfoReport = ({ error, enviarReportePermisos, elementSelect, title }) => { 
    
    const [ errorUsers, setErrorUsers] = useState( null );
    const [ usuarios, setUsuarios ] = useState( [] );
    const [ selectAll, setSelectAll ] = useState( false );
    const [ seleccionados, setSeleccionados ] =useState([]);

    const seleccionarTodo = () => {
        if(!selectAll){
            setSeleccionados(usuarios.map(element => element.id));
        }else{
            setSeleccionados([]);
        }
        setSelectAll( !selectAll ); 
    }

    useEffect(()=>{ 

        axios.get( `${servicios_grupogack_url}/all/subordinates`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });

            axios.get(`${servicios_grupogack}/configuracion/reportes/permisos/${ elementSelect.id ? elementSelect.id : elementSelect.reportesId }`, headersAuth())
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            const subordinadosPermitidos = respuesta.data.dataResponse.filter(element=> element.status).map(element => element.id); 
                            setSeleccionados( subordinadosPermitidos );
                            break;
                        case 404:
                            setSeleccionados( [] );
                            break;
                        default:
                            break;
                    }
                })
                .catch( error => { console.log( error ) });
            // eslint-disable-next-line
    },[]);

    const enviar = (evt) => {
        evt.preventDefault();
        if(seleccionados.length>0){ 
            enviarReportePermisos({ subordinados: seleccionados, reporte:elementSelect.id ? elementSelect.id : elementSelect.reportesId });
        }else{
            setErrorUsers( <AlertForm message="Seleccione al menos a un Usuario." /> ); 
        }
    }

    const cambiarSelect = (username) => {
        if(seleccionados.includes(username)){
            setSeleccionados(seleccionados.filter(element => element!==username));
            setSelectAll(false);
        }else{
            setSeleccionados([...seleccionados, username]);
        } 
    }  

    const getSubordinados = () => {
        let res=[]; 
        usuarios.map((user, index)=>{ 
        
                res.push(<tr key={index} >
                            <td key={`${index}-dia`} className="label">
                                <div className="row">
                                    <div className="col-md-1 col-sm-12">
                                    <MuiThemeProvider theme={theme}> 
                                        <Checkbox
                                            checked={ selectAll || seleccionados.includes(user.id)} 
                                            onChange={()=>cambiarSelect(user.id)} 
                                        /> 
                                    </MuiThemeProvider>
                                    </div>
                                    <div className="col-md-2 col-sm-12" style={{marginLeft:'0.5rem'}}>
                                        {
                                            user.img !== ''
                                            ? <Avatar alt="avatar" src={`data:image/jpeg;base64, ${user.foto}`}/>
                                            : <Avatar alt="avatar" src={profileIMG}/>
                                        } 
                                    </div>
                                    <div className="col-md-8 col-sm-12" style={{marginTop:'0.5rem'}}> 
                                        {`${user.datosUsuarioNom} ${user.datosUsuarioPat} ${user.datosUsuarioMat}`}
                                    </div>
                                </div> 
                            </td>
                            <td key={`${index}-ent`}> 
                                {`${user.username}`}
                            </td>
                        </tr>); 
                return null;
            })
        return res;
    }

    return (
        <React.Fragment>
            {
                title==='Asignar Reporte'
                ?
                <form id={'AsignarReporte' } onSubmit={ enviar }>
                <table style={{width:"100%", margin:"30px 0px 30px 0px"}}>
                    <thead>
                        <tr>
                            <th className="label">Nombre  
                                <Tooltip title="Nombre Subordinado." >
                                    <span ><InfoRoundedIcon color="disabled" style={{fontSize:'1rem', marginLeft:'0.5rem'}}/></span> 
                                </Tooltip>
                            </th>
                            <th className="label">Usuario</th>
                            <th>Seleccionar Todo  
                                <MuiThemeProvider theme={theme}> 
                                    <Checkbox checked={selectAll} onChange={ () => seleccionarTodo() }/> 
                                </MuiThemeProvider>
                            </th> 
                        </tr>
                        </thead>
                        <tbody>
                                {getSubordinados()} 
                        </tbody>
                    </table>
                    {errorUsers}
                    <Button variant="contained" className="btn-color" type="submit">{ 'Guardar' }</Button>
                </form>
                :
                <form id={'infoReport'}>
                    <div> <b>Nombre Reporte: </b> <span>{` ${elementSelect.reportes ? elementSelect.reportes.reporteNom : elementSelect.reportesReporteNom}`}</span> </div>
                    <div> <b>Descripción: </b> <span>{` ${elementSelect.reportes ? elementSelect.reportes.reporteDescrip :elementSelect.reportesReporteDescrip}`}</span> </div>
                    <div> <b>Fecha: </b> <span>{` ${ConvertDate(longDateToInput(elementSelect.reportes ? elementSelect.reportes.created :elementSelect.reportesCreated))}`}</span> </div>
                    <div> <b>Creado por: </b> <span>{` ${elementSelect.usuarioDatosUsuarioNom} ${elementSelect.usuarioDatosUsuarioPat} ${elementSelect.usuarioDatosUsuarioMat}`}</span> </div>
                    <div> <b>Usuario: </b> <span>{` ${elementSelect.usuarioUsername}`}</span> </div>
                </form> 

            }
        </React.Fragment>
    );
};

export default FormularioInfoReport;
import React, { useState, useEffect, createRef } from 'react';
import axios from 'axios';
import { historico_, servicios_grupogack_rh } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ConvertDate from '../../../Helpers/ConvertDate';
import ModalService from '../../../ModalService/ModalService';
import DataTableService from '../../../DataTableService/DataTableService';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';

const ModalInfoHistorico = ({ id, openInfo, setOpenInfo, elementSelect }) => {
    
    const [error, setError] = useState(null);
    const [historico, setHistorico] = useState(null);
    const [seleccionado, setSeleccionado] = useState("");
    const [nombreEmpleado, setNombreEmpleado] = useState(null);

    const items = [
        { id: 'nomina', nombre: 'Nómina' },
        { id: 'plaza', nombre: 'Plaza' },
        { id: 'presupuestal', nombre: 'Presupuestal' },
        { id: 'puesto', nombre: 'Puesto' },
        { id: 'status', nombre: 'Status' },
        { id: 'sueldo', nombre: 'Sueldo' },
        { id: 'turno', nombre: 'Turno' },
        { id: 'zonadepago', nombre: 'Zona de Pago' },
    ];
    const elementoRef = createRef('');

    useEffect(() => {
        if(elementSelect.id) {
            setNombreEmpleado(`${elementSelect.empleadoNom} ${elementSelect.empleadoPat} ${elementSelect.empleadoMat ? elementSelect.empleadoMat : ''}`);
            //histórico empleado
            axios.get(`${servicios_grupogack_rh}/empleado/${elementSelect.id}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setHistorico(res.data.dataResponse);
                        break;
                    case 404:
                        setHistorico([]);
                        break;
                    default:
                        setError(res.data.message);
                        break;
                }
            })
            .catch(error => setError("Falló el servicio"));
        }
    }, [elementSelect]);

    const cambiaElemento = (item) => {
        const elemento = elementoRef.current.value;
        const elementView = [historico[elemento]].map(item => ( { id: item.id, fecini: ConvertDate(item.fecini), fecfin: (item.fecfin) ? ConvertDate(item.fecfin) : '--/--/----'} ));
        setSeleccionado(elementView);
    };

    const cerrarModal = () => {
        setOpenInfo(false);
        setNombreEmpleado(null);
        setError(null);
        setSeleccionado('');
    };

    return (
        <ModalService
            title={`Consulta ${historico_}`}
            labelSubtitle="Empleado:"
            subtitle={nombreEmpleado}
            parentNode={historico_}
            isOpen={openInfo} 
            onClose={cerrarModal}
            maxWidth="80%"
            minWidth="80%"
        >
            <div className="row">
                <div className="col-12">
                    <SelectValidation title="Item" arrayOpciones={items} keyOpcion="nombre" onChangeExecute={cambiaElemento}
                        refValue={elementoRef} defaultValue="" required/>
                    {   
                        seleccionado !== ""
                        ?   <DataTableService
                                id={`${id}.1`}
                                title={historico_}
                                columns={[
                                    { field: "id", title: "Id" },
                                    { field: "fecini", title: "Fecha Inicio" },
                                    { field: "fecfin", title: "Fecha Final" }
                                ]}
                                hideIconAdd hideIconDelete hideIconEdit
                                data={seleccionado}
                            />
                        :   null
                    }
                { error }
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalInfoHistorico;
import React, { createRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import PasswordValidation from '../../../../../../Services/Validation/PasswordValidation';

const FormularioUsuario = ({ error, enviarUsuario, elementSelect, actualizarUsuario, informacion, usernameEmail }) => {
    const usuarioNomRef = createRef('');
    const usuarioPatRef = createRef('');
    const usuarioMatRef = createRef('');
    const usuarioCelRef = createRef('');
    const usuarioFecfinRef = createRef('');
    const usuarioDescripRef = createRef('');
    /* Solo son necesarios si se desea agregar un nuevo usuario */
    const usernameRef = createRef('');
    const passwordRef = createRef('');

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarUsuario' ) ){
            const username = usernameRef.current.value;
            const password = passwordRef.current.value;

            const usuarioNom = usuarioNomRef.current.value;
            const usuarioPat = usuarioPatRef.current.value;
            const usuarioMat = usuarioMatRef.current.value;
            const usuarioCel = usuarioCelRef.current.value;
            const usuarioFecfin = inputDateToLong( usuarioFecfinRef.current.value );
            const usuarioDescrip = usuarioDescripRef.current.value;
            //console.log({ username, password, datos: deleteOptionals({ usuarioNom, usuarioPat, usuarioMat, usuarioCel, usuarioFecfin, usuarioDescrip }) });
            enviarUsuario({ username, password, datos: deleteOptionals({ usuarioNom, usuarioPat, usuarioMat, usuarioCel, usuarioFecfin, usuarioDescrip }) });
        }
    }

    const actualizar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'EditarUsuario' ) ){
            const usuarioNom = usuarioNomRef.current.value;
            const usuarioPat = usuarioPatRef.current.value;
            const usuarioMat = usuarioMatRef.current.value;
            const usuarioCel = usuarioCelRef.current.value;
            const usuarioFecfin = inputDateToLong( usuarioFecfinRef.current.value );
            const usuarioDescrip = usuarioDescripRef.current.value;
            //console.log( deleteOptionals({ usuarioNom, usuarioPat, usuarioMat, usuarioCel, usuarioFecfin, usuarioDescrip }) );
            actualizarUsuario(deleteOptionals({ usuarioNom, usuarioPat, usuarioMat, usuarioCel, usuarioFecfin, usuarioDescrip }), elementSelect.id);
        }
    }

    return (
        <form id={ elementSelect ? 'EditarUsuario' : 'AgregarUsuario' } onSubmit={ elementSelect ? actualizar : enviar }>
            { elementSelect && <span> <b> Usuario: </b> {usernameEmail} </span> }

            <InputValidation title="Nombre" type="usuarioNombre" placeholder="Escriba el nombre del usuario" maxLength='100'
                tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUserNom'}
                refValue={usuarioNomRef} defaultValue={ elementSelect ? elementSelect.usuarioNom : '' } required/>

            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Primer Apellido" type="usuarioApellido" placeholder="Escriba el primer apellido del usuario" maxLength='100'
                        tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUserPat'}
                        refValue={usuarioPatRef} defaultValue={ elementSelect ? elementSelect.usuarioPat : '' } required/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Segundo Apellido" type="usuarioApellido" placeholder="Escriba el segundo apellido del usuario" maxLength='100'
                        tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUserMat'}
                        refValue={usuarioMatRef} defaultValue={ elementSelect ? elementSelect.usuarioMat : '' }
                        optional={true} />
                </div>
            </div>

            { !elementSelect &&
                <React.Fragment>
                    <InputValidation title="Correo Electrónico" type="usuarioCorreo" placeholder="Escriba el correo electrónico del usuario" maxLength='100'
                        tooltipName={'tooltipUserEMail'} tooltipText={'De 6 a 100 letras, números, y/o signos . _ - con la estructura de correo electrónico (incluyendo @)'}
                        refValue={usernameRef} required/>

                    <PasswordValidation title="Contraseña" type="password" placeholder="Escriba la contraseña del usuario" maxLength='100'
                        tooltipName={'tooltipUserPass'} tooltipText={'De 8 a 16 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial incluyendo !@#$&*%/()=?¡.,|°<>;+_'}
                        refValue={passwordRef} required/>
                </React.Fragment>
            }

            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Número Celular" type="telefono" placeholder="Escriba el número celular del usuario"
                        tooltipText={'10 números'} tooltipName={'tooltipUserCel'} maxLength='10' onlyNumbers
                        refValue={usuarioCelRef} defaultValue={ elementSelect ? elementSelect.usuarioCel : '' }
                        optional={true} />
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputDateValidation title="Fecha Fin" refValue={usuarioFecfinRef} optional
                        defaultValue={ elementSelect ? longDateToInput( elementSelect.usuarioFecfin ) : '' }/>
                </div>
            </div>

            <InputValidation title="Descripción Usuario" type="descripcionUsuario" placeholder="Escriba la descripción del usuario" maxLength='1024'
                tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipUserDesc'}
                refValue={usuarioDescripRef} defaultValue={ elementSelect ? elementSelect.usuarioDescrip : '' }
                optional={true} />

            {error}
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
        </form>
    );
};

export default FormularioUsuario;
import React, { Fragment } from 'react';
import ModalService from '../../../../ModalService/ModalService';
import OrganigramaPlazas from './Plazas/OrganigramaPlazas';
import FormCAdscripcion from './CentrosAdscripcion/FormCAdscripcion';
import FromPlaza from './Plazas/FormPlaza';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../../Media/Img/profile.jpg';


const OrganigramaModal = ({ minWidth, modalIsOpen, setModalIsOpen, enviar, actualizar, openAlertSyESuccess, setOpenAlertSyESuccess, openAlertSyEError, setOpenAlertSyEError, setToDoOrg, id, permisos }) => {
    let contenido = null;
    let title = null;

    switch(modalIsOpen.type) {
        case "organigrama":
            title = 'Plazas';
            contenido = <OrganigramaPlazas data={modalIsOpen.data} permisos={permisos}/>;
        break;
        case "agregar":
            title = 'Nuevo Centro de Adscripción';
            contenido = <FormCAdscripcion enviar={enviar} data={modalIsOpen.data} id={id}/>;
        break;
        case "editar":
            title = 'Actualizar Centro de Adscripción';
            contenido = <FormCAdscripcion actualizar={actualizar} data={modalIsOpen.data} id={id}/>;
        break;
        case "agregarPlazaNuevoOrg":
            title = 'Nueva Plaza';
            contenido = <FromPlaza enviar={enviar} data={modalIsOpen.data}/>;
        break;
        case "agregarPlaza":
            title = 'Nueva Plaza';
            contenido = <FromPlaza enviar={enviar} data={modalIsOpen.data}/>;
        break;
        case "editarPlaza":
            title = 'Actualizar Plaza';
            contenido = <FromPlaza actualizar={actualizar} data={modalIsOpen.data}/>;
        break;
        case "infoPlaza":
            title = 'Información de Plaza';
            contenido =
            <div className="row">
                <div className="col-1 col-md-2 col-sm-4 center">
                    <Avatar alt="avatar" src={profileIMG} />
                </div>
                <div className="col-11 col-md-10 col-sm-8">
                    {   !modalIsOpen.data.plazadisponible &&
                        <Fragment><label><strong>Nomenclatura del puesto: </strong>{ modalIsOpen.data.puestoNom }</label><br/></Fragment>
                    }
                    {   !modalIsOpen.data.plazadisponible &&
                        <Fragment><label><strong>Nombre del empleado: </strong>{ `${modalIsOpen.data.empleadoNom} ${modalIsOpen.data.empleadoPat} ${modalIsOpen.data.empleadoMat}` }</label><br/></Fragment>
                    }
                    <label><strong>Número Único de la Plaza (NUP): </strong>{ modalIsOpen.data.plazaclave }</label><br/>
                    {   !modalIsOpen.data.plazadisponible
                        ?   <Fragment>
                                <label><strong>Clave del Puesto: </strong>{ modalIsOpen.data.puestoCve }</label><br/>
                                <label><strong>Nivel del Puesto: </strong>{ modalIsOpen.data.nivLaboralClave }</label><br/>
                                <label><strong>Identificación de la Plaza acorde a la estructura vigente: </strong></label>
                            </Fragment>
                        :   <label><strong>PLAZA DISPONIBLE</strong></label>
                    }
                </div>
            </div>;
        break;
        default:
            title = null;
            contenido = null;
        break;
    }

    return (
        <ModalService
            title={title}
            parentNode="organigrama"
            isOpen={modalIsOpen.state}
            onClose={() => setModalIsOpen({ state: false, type: null })}
            minWidth={minWidth}
        >
            <div className="col-12">
                { contenido }
                { 
                    openAlertSyESuccess
                    ?   <AlertSyE show={openAlertSyESuccess} setOpenAlertSyE={setOpenAlertSyESuccess} title="Plaza" text="La tarea se realizó con éxito" textbtnconfirm="Aceptar" type="success"
                            action={() => { setModalIsOpen({ state: false, type: null, data: {} }); setToDoOrg(false); }}/>
                    :   null
                }
                { 
                    openAlertSyEError
                    ?   <AlertSyE show={openAlertSyEError} setOpenAlertSyE={setOpenAlertSyEError} title="Plaza" text="La tarea no se ha podido realizar" textbtnconfirm="Aceptar" type="error" />
                    :   null
                }
            </div>
        </ModalService>
    );
}

export default OrganigramaModal;
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, motivos_baja } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import motibajajson from '../../../../../Datos/TipoMotivoBaja.json';

const MotivosBaja = ({ id, permisos }) => {

    const [motivosBaja, setMotivosBaja] = useState(null);

    useEffect(() => {/*
        Axios.get(`${servicios_grupogack_catalogo}/motivobaja/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:*/
                    setMotivosBaja(motibajajson);
                    /*break;
                case 404:
                    setMotivosBaja([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));*/
    },[]);

    return (
        <div id={ id }>
            {   
                motivosBaja
                ?   <DataTableService id={id} title={ motivos_baja }
                        columns={[
                            { field: "clave", title: "Clave" },
                            { field: "nombre", title: "Nombre" }
                        ]}
                        data={motivosBaja}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default MotivosBaja;
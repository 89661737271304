import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService'; 
import FormSaveConfigReport from './FormSaveConfigReport';
import { JsonNewReport } from '../../../Helpers/JsonToOneLevel';

const ModalSaveReport = ({ openEdit, setOpenEdit, errorTable, editar, successTable, elementSelect, nombreModulo }) => {
      
    const [error, setError] = useState(null); 

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }; 

    const enviarConfigReport = ( configuracion ) => {
        axios.post( `${servicios_grupogack}/configuracion/reportes/`, configuracion, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:

                        editar(JsonNewReport(respuesta.data.dataResponse));
                        setOpenEdit( false );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }
    
    return (
        <ModalService
            title={`Guardar Configuración de Reporte`}
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="40%"
            maxWidth="40%"
        >
            <div className="row">
                <div className="col-12"> 
                    <FormSaveConfigReport  
                        error={error} 
                        elementSelect={elementSelect} 
                        enviarConfigReport={enviarConfigReport}
                    />
                </div>  
            </div>
        </ModalService>
    );
}

export default ModalSaveReport;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, especiales_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Especiales = ({ id }) => {

    const [empleados, setEmpleados] = useState(null);

    useEffect(() => {
        //empleados activos y bajas
        axios.get(`${servicios_grupogack_rh}/empleado/activos/`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEmpleados(res.data.dataResponse);
                    break;
                case 404:
                    setEmpleados([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    return (
        <div id={ id }>
            {   
                empleados
                ?   <DataTableService
                        id={id}
                        title={ especiales_ }
                        columns={[
                            { field: "empleadoClave", title: "Núm. Empleado" },
                            { field: "empleadoNom", title: "Nombre" },
                            { field: "empleadoPat", title: "Primer Apellido" },
                            { field: "empleadoMat", title: "Segundo Apellido" },
                            { field: "empleadoRfc", title: "RFC" },
                            { field: "puestoNom", title: "Puesto" },
                            { field: "nominaNombre", title: "Nómina" },
                            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                        ]}
                        hideIconAdd hideIconDelete hideIconEdit
                        data={empleados}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default Especiales;
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, tipo_relacion_laboral } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const TipoRelacionLaboral = ({ id, permisos }) => {

    const [relacionLaboral, setCompania] = useState(null);

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}/relacionlaboral/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setCompania(res.data.dataResponse);
                    break;
                case 404:
                    setCompania([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]);

    return (
        <div id={ id }>
            {   
                relacionLaboral
                ?   <DataTableService id={id} title={ tipo_relacion_laboral }
                        columns={[
                            { field: "clave", title: "Clave" },
                            { field: "nombre", title: "Nombre" },
                            { field: "descripcion", title: "Descripción" }
                        ]}
                        data={relacionLaboral}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}

export default TipoRelacionLaboral;
import React from 'react';
import ModalService from '../../../../ModalService/ModalService';
import Formulario from '../components/Formulario';

const ModalDescargarCFDI = ({ nombreModulo, open, setOpen, nombre, setOpenLoading }) => {

    const cerrarModal = () => {
        setOpen(false);
    };

    const descargarCFDI = data => {
        setOpenLoading(true);

        setTimeout(() => {
            setOpenLoading(false);
        }, 3000);
    };

    return (
        <ModalService
            title="Descargar CFDI"
            parentNode={nombreModulo}
            isOpen={open}
            onClose={cerrarModal}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <Formulario
                        nombre={nombre}
                        action={descargarCFDI}
                    />
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalDescargarCFDI;
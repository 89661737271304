import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, servicios_grupogack_rh } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import CardComponent from '../../../../../../Services/Components/SecondTable/SecondTableCard/CardComponent';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import ConvertDate from '../../../Helpers/ConvertDate';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import TitleAusentismosTable from './components/TitleAusentismosTable';
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import AusentismosTable from './tables/AusentismosTable';
import EmpleadosTable from './tables/EmpleadosTable';

const Ausentismos = ({ id, permisos }) => {

    const [empleados, setEmpleados] = useState(null);
    const [ausentismos, setAusentismos] = useState(null);
    const [tiposAusentismo, setTiposAusentismo] = useState([]);
    const [tablaAusentismos, setTablaAusentismos] = useState(false);

    useEffect(() => {
        //empleados activos
        axios.get(`${servicios_grupogack_rh}/empleado/activos/`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEmpleados(res.data.dataResponse);
                    break;
                case 404:
                    setEmpleados([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        axios.get(`${servicios_grupogack_catalogo}/tipoausentismo/`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTiposAusentismo(res.data.dataResponse);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    const mostrarTablaAusentismos = selected => {
        setTablaAusentismos(true);
        axios.get(`${servicios_grupogack_rh}/empleado/ausentismo/${selected.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const data = res.data.dataResponse.map(item => (
                        {
                            id: item.id,
                            ausentismoId: item.ausentismo.id,
                            ausentismoNom: item.ausentismo.ausentismoNom,
                            auseFechaIni: ConvertDate(item.auseFechaIni),
                            auseFechaIniInput: longDateToInput(item.auseFechaIni),
                            auseFechaFin: ConvertDate(item.auseFechaFin),
                            auseFechaFinInput: longDateToInput(item.auseFechaFin),
                            auseFechaAplica: ConvertDate(item.auseFechaAplica),
                            auseFechaAplicaInput: longDateToInput(item.auseFechaAplica),
                            comentarios: item.comentarios
                        }
                    ));
                    setAusentismos({ empleado: selected, data });
                    break;
                case 404:
                    setAusentismos({ empleado: selected, data: [] });
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    };

    const mostrarTablaEmpleados = () => {
        setTablaAusentismos(false);
        setAusentismos(null);
    };

    return (
        <div id={id}>
            {
                (tablaAusentismos)
                ?   (ausentismos && ausentismos.empleado && ausentismos.data)
                    ?   <Fragment>
                            <BtnBack tooltipText="Regresar a tabla de empleados" action={mostrarTablaEmpleados}/>
                            <CardComponent
                                title={<TitleAusentismosTable empleado={ausentismos.empleado}/>}
                                table={
                                    <AusentismosTable id={id} permisos={permisos}
                                        empleado={ausentismos.empleado} data={ausentismos.data}
                                        tiposAusentismo={tiposAusentismo}
                                    />
                                }
                            />
                        </Fragment>
                    :   <SpinnerOval />
                :   (empleados)
                    ?   <EmpleadosTable id={id} permisos={permisos}
                            empleados={empleados} mostrarTablaAusentismos={mostrarTablaAusentismos}
                        />
                    :   <SpinnerOval />
            }
        </div>
    );
}
 
export default Ausentismos;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, amountToFloat } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import AlertForm from '../../../../../../../Services/AlertForm';

const FormularioPrestamos = ({ error, enviarPrestamo, elementSelect, actualizarPrestamo, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ nominaIndicador, setNominaIndicador ] = useState( elementSelect ? informacion.indicadoresNomina.find( element => element.id === elementSelect.indicadorId ) : null );
    const [ errorIndicador, setErrorIndicador ] = useState( null );
    const folioRef = createRef('');
    const numeroPagosRef = createRef('');
    const importeRef = createRef('');
    const fechaIniRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarPrestamos' ) ){
            if( nominaIndicador ){
                const empleado = informacion.empleado.id;
                const folio = folioRef.current.value;
                const indicador = nominaIndicador.id;
                const numeroPagos = parseInt(numeroPagosRef.current.value);
                const importe = amountToFloat(importeRef.current.value);
                const fechaIni = inputDateToLong( fechaIniRef.current.value );
                //console.log({ empleado, folio, indicador, numeroPagos, importe, fechaIni });
                enviarPrestamo({ empleado, folio, indicador, numeroPagos, importe, fechaIni });
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarPrestamos' ) ){
            if( nominaIndicador ){
                const empleado = informacion.empleado.id;
                const folio = folioRef.current.value;
                const indicador = nominaIndicador.id;
                const numeroPagos = parseInt(numeroPagosRef.current.value);
                const importe = amountToFloat(importeRef.current.value);
                const fechaIni = inputDateToLong( fechaIniRef.current.value );
                //console.log({ empleado, folio, indicador, numeroPagos, importe, fechaIni });
                actualizarPrestamo({ empleado, folio, indicador, numeroPagos, importe, fechaIni });
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const validarIndicador = (valor) => {
        if(valor) {
            //console.log( valor );
            setErrorIndicador( null );
            setNominaIndicador( valor );
        }else{
            setErrorIndicador( true );
            setNominaIndicador( null );
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarPrestamos' : 'AgregarPrestamos' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Folio" type="folioPrestamo" placeholder="Escriba el folio del préstamo"
                    tooltipText={'Máximo 10 letras y/o números'} tooltipName={'tooltipFolioPrest'} maxLength='10' alfanumeric
                    refValue={folioRef} defaultValue={ elementSelect ? elementSelect.folio : '' } required/>

                <AutocompleteComponent id="indicadorPrestamos" title="Concepto de Nómina" placeholder="Seleccione el Concepto de Nómina" size="100%"
                    tooltipName="tooltipIndicadorPrestamos" tooltipText={'Busque el Concepto, por nombre o clave, en el campo de autocompletado'} action={validarIndicador}
                    options={informacion.indicadoresNomina} optionListView={'nomIndicador'} optionKey="nomClave" error={errorIndicador} required
                    value={nominaIndicador}/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Número de Pagos" type="cantidad" placeholder="Digite el número de pagos"
                            tooltipText={'Máximo 16 números'} tooltipName={'tooltipNumPrest'} maxLength='16' onlyNumbers
                            refValue={numeroPagosRef} defaultValue={ elementSelect ? elementSelect.numeroPagos : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Importe de Pagos (MXN)" type="monto" placeholder="Digite el importe de pagos"
                            tooltipText={'Máximo 16 números y 2 decimales'} tooltipName={'tooltipImportPrest'} maxLength='19'
                            refValue={importeRef} defaultValue={ elementSelect ? elementSelect.importe : '' } required
                            onlyNumbers="." onBlurDecimal={2} isAmount/>
                    </div>
                </div>

                <InputDateValidation title="Fecha Inicio del Préstamo" refValue={fechaIniRef} required
                    defaultValue={ elementSelect ? elementSelect.fechaIni : '' } />

                {error}
                {errorIndicador}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioPrestamos;
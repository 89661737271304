import React, { useState, useEffect } from 'react';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import TooltipComponent from '../Tooltip/TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import DateFnsUtils from "@date-io/date-fns";
import { es } from 'date-fns/locale'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers"; 
import { comprobar, inputDateToLong, longDateToInput } from './HelperValidation';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

const InputDateValidation = ({ title, refValue, defaultValue, required, optional, disabled, tooltipText, onChangeExecute, informacionAdicional }) => {
    
    const [ valid, setValid ] = useState( null );

    let mensaje = '';
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'));    
     
    const [ value, setValue ] = useState(defaultValue ? longDateToInput(inputDateToLong(defaultValue)+86400000) : null);
    

    useEffect(() => {
        if(refValue.current) {
            if(refValue.current.value === '') setValid(null);
        }
    }, [refValue]);
    

    const comprobarValid = ( obj ) => {
        if( valid !== obj.status || mensaje !== obj.mensaje ){
            setValid(obj.status);
            mensaje = obj.mensaje;
        }
    };

    const validarSafari = (date) => {
        setValue(date);
        if( date === '' || date === null ){
            if( optional ){
                setValid( null );
            }else{
                setValid( false );
            }
        }else{ 
            var d = new Date(date);
            function menor(s) { return (s < 10) ? '0' + s : s; }
            let fecha = [menor(d.getDate()),menor(d.getMonth()+1), menor(d.getFullYear())].join('/');
            refValue.current.value=[menor(d.getFullYear()),menor(d.getMonth()+1),  menor(d.getDate())].join('-');
            comprobarValid( comprobar( fecha, 'date' ) );
            if(onChangeExecute){
                onChangeExecute(date, informacionAdicional);
            }
        } 
    }

    const validar = () => {
        if( refValue.current.value === '' ){
            if( optional ){
                setValid( null );
            }else{
                setValid( false );
            }
        }else{
            setValid( true );
        }
    } 

    return (
        <div className={`form-group ${ isSafari || (optional && valid === null) ? '' : 'validar' }`}>
            {   title &&
                <label>
                    { title }{ required && <strong className="requerido">* </strong> }
                    <TooltipComponent tooltipText={(tooltipText) ? tooltipText : 'Seleccione una fecha del calendario'}>
                        <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                    </TooltipComponent>
                </label>
            }
            {
                isSafari
                ? 
                <MuiThemeProvider theme={theme}> 
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}> 
                            <KeyboardDatePicker 
                                disableToolbar
                                placeholder='dd/mm/aaaa'
                                format="dd/MM/yyyy" 
                                id="date-picker-inline"  
                                onChange={validarSafari}
                                inputRef={refValue}
                                fullWidth={true}
                                KeyboardButtonProps={{
                                    "aria-label": "change date"
                                }} 
                                className={ `form-control ${ valid === null ? '' : valid ? 'is-valid' : 'is-invalid' }  ${ optional && valid === null ? '' : 'validar' }` }
                                value={value} 
                                disabled={(disabled) ? disabled : false} 
                                autoOk={true}
                                helperText="" 
                                InputProps={{ disableUnderline: true,  autoComplete: "off"}} 
                                cancelLabel={null}
                                okLabel={null}
                                DialogProps={{disableEnforceFocus:true }}
                            />  
                    </MuiPickersUtilsProvider>
                </MuiThemeProvider> 
                :
            <input type="date" className={ `form-control ${ valid === null ? '' : valid ? 'is-valid' : 'is-invalid' }` }
                ref={refValue} defaultValue={defaultValue} onChange={ () => validar() } disabled={(disabled) ? disabled : false}/>
            }            
            <div className="invalid-feedback"> Seleccione una fecha válida </div>
        </div>
    );
};

export default InputDateValidation;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, bases_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import basesJson from '../../../../../Datos/basesJson.json';

const Bases = ({ id, permisos }) => {

    const [bases, setBases] = useState(null);

    useEffect(() => {
        setBases(basesJson);
        /*
        axios.get(`${servicios_grupogack_catalogo}/bases/getall/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setBases(res.data.dataResponse);
                    break;
                case 404:
                    setBases([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));*/
    }, []);

    return (
        <div id={id}>
            {   
                (bases)
                ?   <DataTableService id={id} title={bases_}
                        columns={[
                            { field: "baseClave", title: "Clave" },
                            { field: "baseNom", title: "Nombre" },
                            { field: "baseDescrip", title: "Descripción" },
                        ]}
                        data={bases}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default Bases;

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { iconosDrawer } from '../../../MenuDrawer/Drawer/IconosDrawer'; //Importacion de Iconos

const TarjetaCarga = ({ modulo, setElementSelect, setOpenAdd }) => {
    const seleccionarElemento = () => {
        setElementSelect( modulo );
        setOpenAdd( true );
    }

    return (
        <Card className="cardBoard" onClick={ () => seleccionarElemento() }>
            <CardContent>
                <div className="icon">{iconosDrawer[ modulo.icon ]}</div>
                <p className="name">{modulo.nombre}</p>
            </CardContent>
        </Card>
    );
};

export default TarjetaCarga;
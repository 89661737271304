import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong } from '../../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import FileService from '../../../../CropService/FileService';

//import axios from 'axios';
import AlertForm from '../../../../../../../Services/AlertForm';

import Tooltip from '@material-ui/core/Tooltip';
//import GetApp from '@material-ui/icons/GetApp';
import Search from '@material-ui/icons/Search';
import { Progress } from 'reactstrap';
import CloudUpload from '@material-ui/icons/CloudUpload';

import csvDownload from 'json-to-csv-export';
import { obtenerInformacionExcel, axiosExcel } from './HelperCargasMasivas';

const CargasMasivas = ({ modulo, errorAlert, successAlert }) => {
    const [ informacionExcel, setInformacionExcel ] = useState( null );
    useEffect(() => {
        obtenerInformacionExcel( modulo.id, setInformacionExcel );
        // eslint-disable-next-line
    }, []);

    const decargarExcel = () => {
        //console.log( informacionExcel );
        csvDownload( informacionExcel.informacion, `${informacionExcel.nombreArchivo}.csv` );
    }

    /* ---------------------------------------- Funcion para obtener Tipo de Documento --------------------------------------- */
    const fechaRef = createRef('');
    const [ error, setError ] = useState( null );
    const [ progreso, setProgreso ] = useState( 0 );
    const [ documentoExcel, setDocumentoExcel ] = useState( null );

    const obtenerArchivo = ( file ) => {
        setDocumentoExcel( null );
        setError( null );
        setDocumentoExcel( file );
    }

    const enviarExcel64 = () => {
        setError( null );
        if( documentoExcel.name.includes( 'csv' ) ){
            if( modulo.id === 25 || modulo.id === 27 ){ //Tabuladores Sueldo & Quinquenios
                if( validarSubmit( 'enviarTabulador' ) ){
                    setProgreso( 100 );
                    const fecha = inputDateToLong( fechaRef.current.value );
                    axiosExcel( modulo.id, { archivo: documentoExcel.base64, fecha }, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert );
                }
            }else{
                setProgreso( 100 );
                axiosExcel( modulo.id, { archivo: documentoExcel.base64 }, setProgreso, setDocumentoExcel, setError, errorAlert, successAlert );
            }
        }else{
            setError( <AlertForm message="Asegúrate de seleccionar un archivo con formato .csv" /> );
        }
    }
    /* ----------------------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <div className="movimientosFlex">
                <div className="movimientosIzquerdo">
                    { informacionExcel ?
                        <Button className="btn-color" onClick={() => decargarExcel()}> Descargar Formato </Button>
                        :
                        <Button className="btn-color" disabled> Descargar Formato </Button>
                    }
                </div>

                <div className="movimientosUploadDerecho file-upload">
                    <Tooltip title="Cargar archivo" placement="top-start">
                        <label htmlFor="carga_masiva_excel" className="MuiButtonBase-root MuiButton-root MuiButton-text btn-outline btn-auto" type="button"> <Search/> </label>
                    </Tooltip>
                    <FileService inputId="carga_masiva_excel" getFile={obtenerArchivo} formatAccept={`.csv`}/>
                </div>
            </div>

            { documentoExcel &&
                <React.Fragment>
                    <div className="movimientosFlex mt-3">
                        <div className="movimientosIzquerdo upload">
                            <div>
                                <span> {documentoExcel.name} </span>
                                <button style={{ float: "right" }} type="button" className="close" onClick={() => {setDocumentoExcel( null ); setError(null);}}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <Progress animated value={progreso} />
                        </div>
                        <div className="movimientosUploadDerecho btn-upload">
                            <Tooltip title="Enviar Archivo" placement="top-start">
                                <Button variant="contained" className="btn-color" onClick={() => enviarExcel64()}> <CloudUpload/> </Button>
                            </Tooltip>
                        </div>
                    </div>
                    { modulo.id === 25 || modulo.id === 27 ?
                        <form id={'enviarTabulador'}>
                            <InputDateValidation title="Fecha Inicial" refValue={fechaRef} required/>
                        </form>
                        :
                        null
                    }
                    { error }
                </React.Fragment>
            }
            <Button style={{ display: 'none' }}> Test </Button>
        </React.Fragment>
    );
};

export default CargasMasivas;
import React from 'react';
import axios from 'axios';
import { servicios_grupogack_url } from '../../../../Constants/Constants';
import { headersAuth } from '../../../../Services/AuthService';

import ModalTerminosPrivacidad from './ModalTerminosPrivacidad';

const TerminosPrivacidad = ({ modal, setModal, setTyC, closeSession }) => {
    const cambiarTerminosCondiciones = () => {
        setModal( false );
        axios.post( `${servicios_grupogack_url}/agree/terms/and/conditions`, null, headersAuth())
            .then( res => {
                const auxUsuarioJson = JSON.parse(localStorage.getItem("IGSESSID"));
                auxUsuarioJson.tyc = "true";
                const usuarioAceptadoTyC = JSON.stringify(auxUsuarioJson);
                localStorage.setItem("IGSESSID", usuarioAceptadoTyC);
                setTyC("true");
            })
            .catch( error => { alert('error') })
    }

    const cerrarCession = () => {
        setModal( false );
        closeSession();
    }

    return (
        <ModalTerminosPrivacidad open={modal} setOpen={setModal} actionAcept={cambiarTerminosCondiciones} actionCancel={cerrarCession}/>
    );
}

export default TerminosPrivacidad;

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, bancos_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import bancojson from '../../../../../Datos/bancos.json';

const Bancos = ({ id, permisos }) => {

    const [bancos, setBancos] = useState(null);

    useEffect(() => {/*
        Axios.get(`${servicios_grupogack_catalogo}/banco/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:*/
                    setBancos(bancojson);
                    /*break;
                case 404:
                    setBancos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));*/
    },[]);

    return (
        <div id={ id }>
            {   
                bancos
                ?   <DataTableService id={id} title={ bancos_ }
                        columns={[
                            { field: "bancoCve", title: "Clave" },
                            { field: "bancoNom", title: "Nombre" },
                            { field: "bancoDescrip", title: "Descripción" },
                        ]}
                        data={bancos}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default Bancos;

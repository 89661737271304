import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, tipos_secretaria_o_dependencia } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const TiposSecretariaDependencia = ({ id, permisos }) => {

    const [tiposSecDep, setTiposSecDep] = useState(null);

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}/dependencia/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTiposSecDep(res.data.dataResponse);
                    break;
                case 404:
                    setTiposSecDep([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]);

    return (
        <div id={ id }>
            {   
                tiposSecDep
                ?   <DataTableService id={id} title={ tipos_secretaria_o_dependencia }
                        columns={[
                            { field: "nombre", title: "Nombre" }
                        ]}
                        data={tiposSecDep}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default TiposSecretariaDependencia;

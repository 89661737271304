import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from './../../../ModalService/ModalService';
import FormNivelEscolaridad from './FormNivelEscolaridad';

const ModalAddTiposDomicilio = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    }

    const enviarNivelEscolaridad = nivelEscolaridad => {
        const info = nivelEscolaridad;
        Axios.post( `${servicios_grupogack_catalogo}/tipoEscolaridad`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd( false );
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    return (
        <ModalService title="Nuevo Nivel de Escolaridad" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormNivelEscolaridad
                        enviarNivelEscolaridad={enviarNivelEscolaridad}
                        error={error}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddTiposDomicilio;
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import './Styles.css';

export default class AlertSyE extends Component {
    /*
      Para utlizar la alerta de warning, esta debe de recibir los siguientes props props: 
      - show, propiedad para mostrar el alert solo puede ser true, debe ser un hook con la proipiedad SetopenALert
      - setOpenAlertSyE, propiedad para ocultar el alert solo puede ser false
      - titulo, Esta cadena sera el titulo del alert
      - text, Esta cadena se muestra en el cuerpo de la alerta
      - textbtnconfirm, Esta cadena se muestra en el cuerpo del BOTON 
      - type, Esta propiedad solo puede ser 'success' o 'error'
      - action, esta propiedad recibe la funcion a ejecutar, opcional
      
      Por ejemplo :
      <Alert show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Cambio de contraseña" text="La tarea se realizo con exito" textbtnconfirm="Aceptar" type="success" action={namefunction} />
      <Alert show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Cambio de contraseña" text="La tarea se realizo con exito" textbtnconfirm="Aceptar" type="success" />
      <Alert show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Cambio de contraseña" text="No se pudo realizar la petición" textbtnconfirm="Aceptar" type="error" action={namefunction} />
      <Alert show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Cambio de contraseña" text="No se pudo realizar la petición" textbtnconfirm="Aceptar" type="error" />
    */

    Confirmsuccess = (e) => {
        this.hideAlert();
        //si existe la propiedad this.props.action, se ejecutará
        if(this.props.action) this.props.action(e);
    }

    Confirmerror = (e) => {
        this.hideAlert()
        //si existe la propiedad this.props.action, se ejecutará
        if(this.props.action) this.props.action(e);
    }

    hideAlert = () => {
        this.props.setOpenAlertSyE(false);
    }
  
    render() {
      return (
            (this.props.show===false)
            ?   null
            :   (this.props.type==="success")
                ?
                  <SweetAlert 
                    success
                    title={this.props.title}
                    customButtons={
                                    <React.Fragment>
                                      <button  className="btn-color-alert" onClick={(e) => this.Confirmsuccess(e)}>{this.props.textbtnconfirm}</button>
                                    </React.Fragment>
                                  }
                    onConfirm={() => this.Confirmsuccess()}
                    onCancel={() => this.Confirmsuccess()}
                  >
                      {this.props.text}
                  </SweetAlert> 
                :
                  <SweetAlert 
                    error
                    title={this.props.title}
                    customButtons={
                                    <React.Fragment>
                                      <button  className="btn-color-alert" onClick={(e) => this.Confirmerror(e)}>{this.props.textbtnconfirm}</button>
                                    </React.Fragment>
                                  }
                    onConfirm={() => this.Confirmerror()}
                    onCancel={() => this.Confirmerror()}
                  >
                      {this.props.text}
                  </SweetAlert> 
      );
    }
  }
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormBases from './FormBases';

const ModalAddBases = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    };

    const enviarBase = base => {
        const info = base;
        axios.post(`${servicios_grupogack_catalogo}/bases/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    };

    return (
        <ModalService
            title="Nueva Base"
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormBases
                        enviarBase={enviarBase}
                        error={error}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddBases;
import React, { createRef, useState, Fragment, useEffect } from 'react';
import {Button } from '@material-ui/core';
import { validarSubmit, inputDateToLong, amountToFloat } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado, servicios_grupogack_catalogo } from '../../../../../../Constants/Constants';
import Axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';

const FormularioSalarioMinUMA = ({ error, setError, enviarSalarioMinUMA, elementSelect, actualizarSalarioMinUMA }) => {
    
    const [openAlert, setOpenAlert] = useState(false);
    const tipoSalarioRef = createRef('');
    const montoRef = createRef('');
    const fechaInicialRef = createRef('');
    const fechaFinalRef = createRef('');
    const enviar = e => {
        e.preventDefault();
        
        if(validarSubmit('AgregarSalarioMinUMA')) {
            const zona = parseInt(tipoSalarioRef.current.value);
            const monto = amountToFloat(montoRef.current.value);
            const fechaIni = inputDateToLong(fechaInicialRef.current.value);
            const fechaFin = fechaFinalRef.current.value!=='' ? inputDateToLong(fechaFinalRef.current.value) : null; 
            if(fechaFin===null){
                enviarSalarioMinUMA( { zona, monto, fechaIni} );
            }else{ 
                enviarSalarioMinUMA( { zona, monto, fechaIni, fechaFin } );
            }     
        }
    }; 
    
    const actualizar = () => {
        if(validarSubmit('EditarSalarioMinUMA')) {
            const zona = parseInt(tipoSalarioRef.current.value);
            const monto = amountToFloat(montoRef.current.value);
            const fechaIni = inputDateToLong(fechaInicialRef.current.value);
            const fechaFin = fechaFinalRef.current.value!=='' ? inputDateToLong(fechaFinalRef.current.value) : null; 
            if(fechaFin===null){
                actualizarSalarioMinUMA( { zona, monto, fechaIni} );
            }else{ 
                actualizarSalarioMinUMA( { zona, monto, fechaIni, fechaFin } );
            } 
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    }; 
    
    const [zonas, setZonas] = useState(null);
     
    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}s/zonas/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setZonas(res.data.dataResponse);
                    break;
                case 404:
                    setZonas([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    },[]);

    return ( 
        <Fragment> 
        <form id={ elementSelect ? 'EditarSalarioMinUMA' : 'AgregarSalarioMinUMA'} onSubmit={ elementSelect ? mostrarAlert : enviar  }> 
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    {
                        zonas
                        ?
                        <SelectValidation title="Tipo de Salario" arrayOpciones={zonas} keyOpcion="zonaNom" refValue={tipoSalarioRef}
                            defaultValue={elementSelect ? elementSelect.zonaId : ''} required/>
                        :null
                    } 
                </div>
                <div className="col-md-6 col-sm-12"> 
                    <InputValidation title="Monto (MXN)" type="monto" placeholder="Escriba el Monto"
                        tooltipText={'Máximo 128 números y 2 decimales'} tooltipName={'tooltipMonSalMinUMA'} maxLength={128}
                        refValue={montoRef} required defaultValue={elementSelect ? elementSelect.monto :''} onlyNumbers = '.' onBlurDecimal={2} isAmount/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12">  
                    <InputDateValidation title="Fecha Inicial" refValue={fechaInicialRef} required
                        defaultValue={ elementSelect ? elementSelect.fechaIniInp : '' } />
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} optional
                        defaultValue={ elementSelect ? elementSelect.fechaFinInp : ''  } /> 
                </div>
            </div> 
            {error} 
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button> 
        </form>
        <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}
export default FormularioSalarioMinUMA;

import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioTipoRiesgo from './FormularioTipoRiesgo';

const ModalAddTipoRiesgo = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarTipoRiesgo = ( tipoRiesgo ) => {
        axios.post( `${servicios_grupogack_catalogo}/tiporiesgo/`, tipoRiesgo, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        setOpenAdd( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Nuevo Tipo de Riesgo" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioTipoRiesgo
                    error={error}
                    enviarTipoRiesgo={enviarTipoRiesgo}
                />
        </ModalService>
    );
};

export default ModalAddTipoRiesgo;
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { agregarNavegador } from '../../../../../Redux/actions/NavActions';
/* ------------------------------------ Estilos y componentes Header -----------------------------------  */
import './style.css';
import { iconosDrawer } from '../Drawer/IconosDrawer'; //Importacion de Iconos
/* -----------------------------------------------------------------------------------------------------  */
import { informacionModulos } from '../../Helpers/HelperNavegacion';
import TextField from '@material-ui/core/TextField';
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import Axios from 'axios';
import {servicios_grupogack} from '../../../../../Constants/Constants';
import { headersAuth } from '../../../../../Services/AuthService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
export const StoreContext = React.createContext('vacio');

export const searchComponentTex={text:''};
const useStyles = makeStyles(()=> ({
    root: {
      color:'#4C638E'
    }
}));

function SearchComponent({ modulosApi, navegadoresStore, agregarNavegador, componenteActivoID }){
    const classes = useStyles();
    var modulos = informacionModulos( modulosApi );
    var removeDiacritics = require('diacritic').clean;
    // https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_special_characters
    var specialCharsRegex = /[.*+?^${}()|[\]\\]/g;
    var whitespacesRegex = /\s+/;
    const searchTextRef=useRef('');
    
    const [open, setOpen] = React.useState(false);
    const [searched, setSearched] = React.useState(false);
    const [options, setOptions] = React.useState([]); 
    const [loading, setLoading]=useState(false); 
 
    const escapeRegexCharacters = ( str ) => {
      return str.replace(specialCharsRegex, '\\$&');
    }
    const match = ( text, query ) => {

        text = removeDiacritics(text);
        query = removeDiacritics(query);
        text = text.toLowerCase();
        query = query.toLowerCase();
        return (
            query
            .trim()
            .split(whitespacesRegex)
            // If query is blank, we'll get empty string here, so let's filter it out.
            .filter(function(word) {
                return word.length > 0;
            })
            .reduce( function( result, word ) {
                var wordLen = word.length;
                //var prefix = wordCharacterRegex.test(word[0]) ? '\\b' : '';
                var regex = new RegExp(escapeRegexCharacters(word), 'gi');
                var index = text.search(regex);
                if (index > -1) {
                result.push([index, index + wordLen]);

                // Replace what we just found with spaces so we don't find it again.
                text =
                    text.slice(0, index) +
                    new Array(wordLen + 1).join(' ') +
                    text.slice(index + wordLen);
                }

                return result;
            }, [])
            .sort(function(match1, match2) {
                return match1[0] - match2[0];
            })
        );
    };

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: modulos => modulos.nombre
    });

    const abrirventana = ( modSearch ) =>{
        const {id,nombre,ruta, permisos} = modSearch
        const modulo = { id, nombre, ruta, permisos};
        setLoading(false); 
        agregarNavegador(navegadoresStore,modulo,componenteActivoID, "SearchComponent", searchComponentTex.text!==''?searchComponentTex.text:null );   
    }



    const validar = e => {         
        if (e === undefined || e === null) {
            
        } else {
            setLoading(true);
            setOpen(false); 
            let result = modulos.filter(mod => e.id === mod.id);
            if(result.length !== 0 ){
                setOpen(false);
                abrirventana(result[0]);
                if(searched){
                    setOptions(modulos.map(modu => ({'nombre':modu.nombre,'icono':modu.icon})));
                    setOpen(false);
                    setSearched(false);
                    setLoading(false); 
                }
            }
            if(e.nombre==='Buscar en el sistema' && searchTextRef.current.value!=='' ){ 
                searchData({cadena:searchTextRef.current.value});
                searchComponentTex.text=searchTextRef.current.value;
                //setTextoo(searchTextRef.current.value);       
            }else{
                setLoading(false);
            }
            
        }
    };
    
    

    const searchData=async (info)=>{
        await Axios.post(`${servicios_grupogack}/buscador/detalle/`,info , headersAuth())
        .then(res => {
            var opc=[];             
            switch(res.data.status) { 
                case 200: 
                    if(res.data.dataResponse.length>0){
                        res.data.dataResponse.map(mod=>{  
                            let result=modulos.filter(modulo=>modulo.id===mod[0]);
                            result[0].coicidencias=mod[1];  
                            opc.push(result); 
                            return null;
                        });
                        opc.unshift({'nombre':'Se encontraron:','icono':'notIcon'});
                        setOptions(opc.flat());
                        setSearched(true);
                        setOpen(true);
                        setLoading(false);
                    }else{
                        opc.unshift({'nombre':'No se encontraron resultados','icono':'notIcon'});
                        setOptions(opc);
                        setSearched(true);
                        setOpen(true);
                        setLoading(false);
                    }  
                    break;
                case 404:
                        opc.unshift({'nombre':'No se encontraron resultados','icono':'notIcon'});
                        setOptions(opc);
                        setSearched(true);
                        setOpen(true);
                        setLoading(false); 
                    break;
                default:
                        opc.unshift({'nombre':'No se encontraron resultados','icono':'notIcon'});
                        setOptions(opc);
                        setSearched(true);
                        setOpen(true);
                        setLoading(false);
                    break;
            }
        })
        .catch(error =>  console.log(error));   
    }
    const PopperSearch = (props) =>{
        return (<Popper {...props} placement='bottom-start' className="generalSearch MuiAutocomplete-popper"/>)
      }
    return (
        <div className="my-third-step" style={{ width: 300 , marginBottom:'0.5rem'}}> 
                <Autocomplete 
                    id="tooltipSearchComponent" 
                    autoHighlight={true}
                    onChange={(e,v) => validar(v)}
                    getOptionLabel={option => ""}
                    options={options}
                    size="small"
                    open={open}
                    debug={true}
                    openText=""
                    popupIcon={<Tooltip title={open? "Cerrar" :"Abrir"} placement="right">
                        <ArrowDropDownIcon />
                    </Tooltip>}
                    closeText=""
                    PopperComponent={PopperSearch}
                    onOpen={() => {
                        searchComponentTex.text='';
                        setOptions(modulos.map(modu => ({'nombre':modu.nombre,'icono':modu.icon, 'id':modu.id}))); 
                        setOpen(true);
                    }}
                    onClose={() => { 
                        setSearched(false);
                        setOptions(modulos.map(modu => ({'nombre':modu.nombre,'icono':modu.icon}))); 
                    }}
                    noOptionsText="No existen coincidencias" 
                    loading={loading}
                    getOptionDisabled={options=> options.icono==='notIcon'}
                    filterOptions={(options, params) => {
                        const filtered = filterOptions(options, params);
                        if(!searched && params.inputValue.length>3){ 
                            filtered.push({'nombre':'Buscar en el sistema','icono':'search'}); 
                        }  
                        /* if (params.inputValue !== '' && filtered.length <2) {
                            setEmpty(true);  
                        }else{
                            setEmpty(false); 
                        }  */
                        return filtered;  
                    }}
                    renderOption={ (options,{inputValue}) => {
                        const matches = match(options.nombre, inputValue)
                        const parts = parse(options.nombre, matches);
                        return (
                            <React.Fragment>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="Icono">
                                                { iconosDrawer[ options.icono ] }
                                            </th>
                                            {
                                                <th >
                                                    {
                                                    /* empty
                                                    ?
                                                    <div>
                                                        <span key={"notfound"} style={{ color: 'black', fontWeight:'lighter', fontSize:'14px' }}>
                                                            No existen coincidencias de módulo
                                                        </span>
                                                    </div>
                                                    :null */
                                                    }
                                                    {
                                                        options.coicidencias
                                                        ?
                                                        <span key={'index'} style={{ color:'#4C638E' }}>
                                                            {`${options.coicidencias} `} 
                                                        </span>
                                                        :null
                                                    }
                                                    { parts.map((part, index) => (
                                                        
                                                        <span key={index} style={{ color: part.highlight ? '#4C638E': 'black' }}>
                                                            {part.text}
                                                        </span>
                                                        
                                                    ))}
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                </table>
                            </React.Fragment>
                        );
                    }}      
                    renderInput={ params => (
                        <Tooltip title={open ? "" : "Busque un módulo o realice una búsqueda avanzada"}>
                            <TextField
                                id="mui-theme-provider-outlined-input"
                                {...params}
                                label="Buscar"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                inputRef={searchTextRef}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress className={classes.root} size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    ),
                                    id: 'tooltipSearchComponent', 
                                }} 
                                onBlur={()=>{
                                    setOpen(false);
                                    setLoading(false);
                                    searchComponentTex.text="";
                                }}  
                            />
                        </Tooltip>
                    )}
                />  
        </div>
    );
}

const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});

const mapDispatchToProps = { agregarNavegador };

export default connect(mapStateToProps, mapDispatchToProps)(SearchComponent);
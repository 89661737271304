import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, contratos_relacion_laboral } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonContratoRelLab } from '../../../Helpers/JsonToOneLevel';
const ContratosRealacionLaboral = ({ id, permisos }) => {

    const [contratosRealacionLaboral, setContratosRealacionLaboral] = useState(null);

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}s/contrato/sao/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setContratosRealacionLaboral(ArrayJsonContratoRelLab(res.data.dataResponse));
                    break;
                case 404:
                    setContratosRealacionLaboral([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    },[]);

    return (
        <div id={ id }>
            {   
                contratosRealacionLaboral
                ?   <DataTableService id={id} title={ contratos_relacion_laboral }
                        permisos={permisos} 
                        columns={[
                            { field: "contratoSaoNom", title: "Nombre de Contrato" },
                            { field: "tipoContrato", title: "Tipo de Contrato" },
                            { field: "relacionLaboral", title: "Relación Laboral" },
                            { field: "contratoSaoFechas", title: "Fecha" }
                        ]}
                        data={contratosRealacionLaboral}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default ContratosRealacionLaboral;

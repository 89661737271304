import React from 'react';

const SubtitleCard = ({ subtitle }) => {
    return (
        <h6>
            <small className="text-muted">
                { subtitle }
            </small>
        </h6>
    );
}
 
export default SubtitleCard;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioPoliticas from './FormularioPoliticas';
import { JsonPrimaVacacional, JsonAguinaldo, JsonDiasAjuste, JsonVacaciones} from '../../../Helpers/JsonToOneLevel';

const ModalEditPoliticas = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo}) => {

    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const tipoTitulos = [ {id: 0, nombre: 'Nuevo Aguinaldo', dir: 'aguinaldo/'},
                    {id: 1, nombre: 'Nueva Prima Vacacional', dir: 'prima/vacacional/'},
                    {id: 2, nombre: 'Nuevo Día de Ajuste', dir: 'dias/ajuste/'},
                    {id: 3, nombre: 'Nuevas Vacaciones', dir: 'vacaciones/'}
                    ];
    const regex=/VA|PV|DA|AG$/;

    const actualizarPolitica = ( politica, active ) => {
        
        const dir = tipoTitulos.filter(element => element.id ===parseInt(active))[0].dir;
        axios.put( `${servicios_grupogack_rh}/politica/${dir}${elementSelect.id.replace(regex,'')}` , politica, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        switch (active){
                            case 0: 
                            editar( JsonAguinaldo( respuesta.data.dataResponse ));
                            break;
                            case 1: 
                            editar( JsonPrimaVacacional( respuesta.data.dataResponse ));
                            break;
                            case 2: 
                            editar( JsonDiasAjuste( respuesta.data.dataResponse ));
                            break;
                            case 3: 
                            editar( JsonVacaciones( respuesta.data.dataResponse ));
                            break;
                            default:
                            break;
                        }
                        setOpenEdit( false );
                        break;
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            });
    }
    

    
    
    return (
        <ModalService title={`Actualizar ${elementSelect.tipo}`} parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioPoliticas
                    error={error}
                    elementSelect={elementSelect}
                    actualizarPolitica={actualizarPolitica}
                />
        </ModalService>
    );
};

export default ModalEditPoliticas;
import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormBancos = ({ error, setError, enviarBanco, elementSelect, actualizarBanco }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const claveRef = createRef('');
    const nombreRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('Banco')) {
            const bancoCve = claveRef.current.value;
            const bancoNom = nombreRef.current.value
            const bancoDescrip = descripcionRef.current.value;
            enviarBanco( { bancoCve, bancoNom, bancoDescrip } );
        }
    };

    const actualizar = () => {
        if(validarSubmit('Banco')) {
            const bancoCve = claveRef.current.value;
            const bancoNom = nombreRef.current.value
            const bancoDescrip = descripcionRef.current.value;
            setError(null);
            actualizarBanco({ bancoCve, bancoNom, bancoDescrip }, elementSelect.id);
        }
    };

    return ( 
        <Fragment>
            <form id="Banco" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Clave" required type="claveBanco" placeholder="Escriba la clave del banco"
                            refValue={claveRef} defaultValue={ elementSelect ? elementSelect.bancoCve : '' } 
                            tooltipText="Máximo 3 letras y/o números" tooltipName="tooltipCveBan" maxLength={3} alfanumeric/>
                        <InputValidation title="Nombre" type="nombreBanco" placeholder="Escriba el nombre del banco"
                            refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.bancoNom : '' } 
                            tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            tooltipName="tooltipNomBan" maxLength={128} required/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Descripción" type="descripcionBanco" placeholder="Escriba la descripción del banco"
                            refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.bancoDescrip : '' }
                            tooltipText={'Máximo 256 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            tooltipName="tooltipDesBan" required maxLength={256} fieldType="textarea"/>
                    </div>  
                { error }
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                </div>
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment> 
    );
}
export default FormBancos;
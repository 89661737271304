import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_rh, servicios_grupogack_catalogo, servicios_grupogack_nomina, movimientos_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import VariablesEmpleado from './VariablesEmpleado/VariablesEmpleado';

const MovimientosVariables = ({ id }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    /* ----------------------------- Axios para opbtener empleados activos ----------------------------- */
    const [ empleados, setEmpleados ] = useState( null );
    const [ tiposAcumulado, setTiposAcumulado ] = useState( null );
    const [ nominas, setNominas ] = useState( null );
    useEffect(() => {
        axiosEmpleados();
        axiosTiposAcumulado();
        axiosNomninas();
    }, []);

    const axiosEmpleados = () => {
        axios.get( `${servicios_grupogack_rh}/empleado/activos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEmpleados( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setEmpleados( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener tipos de acumulado */
    const axiosTiposAcumulado = () => {
        axios.get( `${servicios_grupogack_catalogo}s/tipoacumulado/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposAcumulado( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposAcumulado([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener listado de Nóminas */
    const axiosNomninas = () => {
        axios.get( `${servicios_grupogack_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setNominas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------- */
    const onShowTable = ( elementSelect ) => {
        //console.log( elementSelect );
        setElementSelect( elementSelect );
    }

    return (
        <div id={ id }>
            { elementSelect && tiposAcumulado ?
                <VariablesEmpleado empleado={elementSelect} setEmpleado={setElementSelect} tiposAcumulado={tiposAcumulado} idModulo={id}/>
                :
                <React.Fragment>
                    { empleados && nominas ?
                        <DataTableService id={id} title={ movimientos_ }
                            columns={[
                                { field: "empleadoClave", title: "Núm. Empleado" },
                                { field: "empleadoNom", title: "Nombre" },
                                { field: "empleadoPat", title: "Primer Apellido" },
                                { field: "empleadoMat", title: "Segundo Apellido" },
                                { field: "empleadoRfc", title: "RFC" },
                                { field: "puestoNom", title: "Plaza / Puesto" },
                                { field: "nominaNombre", title: "Nómina" },
                                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                            ]}
                            hideIconEdit hideIconDelete
                            showIconDescription onShowTable={onShowTable}
                            data={ empleados }
                            informacionAdicional={{ empleados, nominas }} />
                        :
                        <SpinnerOval />
                    }
                </React.Fragment>
            }
        </div>
    );
}
 
export default MovimientosVariables;
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandMore from '@material-ui/icons/ExpandMore';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { iconosDrawer } from './IconosDrawer'; //Importacion de Iconos
/* -----------------------------------------------------------------------------------------------------  */
import { agregarNavegador, cambiarActivo } from '../../../../../Redux/actions/NavActions';
/* -----------------------------------------------------------------------------------------------------  */
const useStyles = makeStyles(theme => ({
    nested: {
        paddingLeft: theme.spacing(5),
    },
}));
  
function Modulo({ superModulo, navegadoresStore, agregarNavegador, cambiarActivo, componenteActivoID }){
    const classes = useStyles();
    const [ open, setOpen ] = useState( false );

    if( superModulo.children ){
        return(
            <div>
            <ListItem button onClick={ () => setOpen(!open) }>
                <ListItemIcon>
                    { iconosDrawer[ superModulo.icon ] }
                </ListItemIcon>
                <ListItemText primary={ superModulo.nombre } />
                { open ? <ExpandMore style={{ fontSize: 15 }} /> : <KeyboardArrowRight style={{ fontSize: 15 }}/> }
            </ListItem>
    
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    { superModulo.children.map( mod => {
                        const {id, nombre, permisos} = mod;
                        const modulo = { id, nombre, ruta: `${superModulo.nombre}/${mod.nombre}`, permisos };
                        return(
                            <ListItem key={ mod.nombre } button className={classes.nested}
                                onClick={ () => agregarNavegador( navegadoresStore, modulo, componenteActivoID) }>
                                <ListItemIcon>
                                    { iconosDrawer[ mod.icon ] }
                                </ListItemIcon>
                                <ListItemText primary={ mod.nombre } />
                            </ListItem>
                        )
                    })}
                </List>
            </Collapse>
            </div>
        );
    }else{
        if( superModulo.nombre === 'Dashboard' ){
            return(
                <ListItem button onClick={ () => cambiarActivo( 1, componenteActivoID ) }>
                    <ListItemIcon>
                        { iconosDrawer[ superModulo.icon ] }
                    </ListItemIcon>
                    <ListItemText primary={ superModulo.nombre } />
                </ListItem>
            );
        }else{
            const {id, nombre, permisos} = superModulo;
            const modulo = { id, nombre, ruta: `${superModulo.nombre}`, permisos };
            return(
                <ListItem button onClick={ () => agregarNavegador( navegadoresStore, modulo, componenteActivoID) }>
                    <ListItemIcon>
                        { iconosDrawer[ superModulo.icon ] }
                    </ListItemIcon>
                    <ListItemText primary={ superModulo.nombre } />
                </ListItem>
            );
        }
    }
}
/* ------------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
const mapDispatchToProps = { agregarNavegador, cambiarActivo };
/* ------------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, mapDispatchToProps)(Modulo);
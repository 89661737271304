import React from 'react';
import Axios from 'axios';
import { servicios_grupogack, registro_no_eliminado, registro_sera_eliminado, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';

const ModalDeletePeriodoNomina = ({ alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const axiosEliminar = () => {
        
        var info={periodosNom : [parseInt(elementSelect)]};
        var headers= headersAuth();
        var payload= {"Content-Type": headers['headers']["Content-Type"],"X-Authorization":headers['headers']["X-Authorization"]};
        
        Axios.delete(`${servicios_grupogack}/nomina/config/periodos`, {headers: payload, data: info }) 
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    eliminar(elementSelect);
                    break;
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(registro_no_eliminado);
                    break;
            }
        })
        .catch(error => errorTable( registro_no_guardado ) );
        
    }

    return(
        <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
    );
}

export default ModalDeletePeriodoNomina;
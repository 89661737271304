import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonPensiones } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioPensiones from './FormularioPensiones';

const ModalAddPensiones = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarPension = ( pension ) => {
        axios.post( `${servicios_grupogack_nomina}s/pensiones/`, pension, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonPensiones(respuesta.data.dataResponse) );
                        setOpenAdd( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Nueva Pensión" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioPensiones
                    error={error}
                    informacion={informacionAdicional}
                    enviarPension={enviarPension}
                />
        </ModalService>
    );
};

export default ModalAddPensiones;
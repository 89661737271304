import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormMotivosBaja from './FormMotivosBaja';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditMotivosBaja = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarMotivoBaja = (motivoBaja, motivoBajaId) => {
        const info = { id: motivoBajaId, clave: motivoBaja.clave, nombre: motivoBaja.nombre };
        Axios.put( `${servicios_grupogack_catalogo}/motivobaja/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar(info);
                setOpenEdit(false);
                break;
            case 400:
            case 404:
                setError(<AlertForm message={res.data.msg} />);
                break;
            default:
                errorTable(cambios_no_guardados);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Motivo de Baja" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormMotivosBaja
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarMotivoBaja={actualizarMotivoBaja}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditMotivosBaja;
import React , {useState, useEffect }  from 'react';
import Axios from 'axios';
import { servicios_grupogack_nomina, indicadores_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import { ArrayJsonIndicadores } from '../../../Helpers/JsonToOneLevel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import Button from '@material-ui/core/Button';

const Indicadores = ({ id, permisos }) => {

    const [ nominas, setNominas ] = useState(null); 
    const [ indicadores, setIndicadores ] = useState(null); 
    const [dinamicTable, setDinamicTable] =useState(false); 
    const [ elemmentSelect, setElemmentSelect ] = useState( null );

    useEffect(()=>{
        Axios.get(`${servicios_grupogack_nomina}/config/nomina`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setNominas(res.data.dataResponse);
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    },[]);

    const getDataIndicadores = (elemmentSelect)=>{
        Axios.get(`${servicios_grupogack_nomina}/indicadores/getbynomina/${elemmentSelect.id}`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setIndicadores(ArrayJsonIndicadores(res.data.dataResponse));
                    break;
                case 404:
                    setIndicadores([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    };
    
    const showTable=async(value, elemmentSelect={}, fechaIn=null)=>{  
        setElemmentSelect(elemmentSelect);
        if(elemmentSelect!==null) await getDataIndicadores(elemmentSelect);
        setDinamicTable(value);  
        return dinamicTable;
    }    

    return (
        <div id={ id }>
        {
            dinamicTable && indicadores
            ?
            <div>
            <div className="row">
                <div className="col-11"></div>
                <div className="col-1 float-left" style={{marginBlockEnd:'18px'}}>
                    <Button variant="contained" className="form-control" onClick={()=>showTable(false, null)}> 
                        <Tooltip title="Regresar a Indicadores" placement="left-start">
                            <ArrowBackIcon/>
                        </Tooltip>
                    </Button> 
                </div>
            </div> 
                <DataTableService id={id +'secondary'} title={ indicadores_ }
                    permisos={permisos} 
                    columns={[
                        { field: "nomClaveIndicador", title: "Clave" },
                        { field: "prioridad", title: "Prioridad" },
                        { field: "nomIndicador", title: "Nombre" },
                        { field: "nomNaturaleza", title: "Naturaleza" },
                        { field: "indicadorSat", title: "Indicador SAT" },
                        { field: "partida", title: "Partida" },
                        { field: "cuenta", title: "Cuenta" },
                        { field: "nomRecibo", title: "Recibo" }
                    ]}
                    data={indicadores}
                    informacionAdicional={elemmentSelect}
                /> 
            </div>
            :
            <div>
            {
                nominas   
                ? 
                <DataTableService id={id} title={ indicadores_ }
                columns={[
                    { field: "nominaClave", title: "Clave Nómina" },
                    { field: "nominaNomCorto", title: "Nombre Corto" },
                    { field: "nominaNombre", title: "Nombre Nómina" }
                ]}
                data={nominas}
                hideIconAdd
                hideIconEdit
                hideIconDelete
                showIconDescription 
                onShowTable={showTable}
                />
            : <SpinnerOval/>
            }
        </div>
    }
    </div>
    );
}
 
export default Indicadores;
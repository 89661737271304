import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { agregarNavegador } from '../../../../../Redux/actions/NavActions';

import axios from 'axios';
import { headersAuth, obtenerUsuario } from '../../../../../Services/AuthService';
import { servicios_grupogack_mensajes } from '../../../../../Constants/Constants';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MailIcon from '@material-ui/icons/Mail';

const ShowEmails = ({ navegadoresStore, agregarNavegador, componenteActivoID }) => {
    const { usuario } = obtenerUsuario();
    /* ---------------------------- Axios para obtener mensajes Bandeja de Entrada ---------------------------- */
    const [emails, setEmails] = useState( null );
    useEffect(() => {
        axiosMensajesEntrada();
        // eslint-disable-next-line
    }, []);

    const axiosMensajesEntrada = () => {/*
        axios.get( `${servicios_grupogack_mensajes}/entrada/${usuario}`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        //setMensajesEntrada( respuesta.data.dataResponse );
                        const noLeidos = respuesta.data.dataResponse.filter( email => email.estado === 'no leido' );
                        setEmails( noLeidos.length );
                        break;
                    case 404:
                        //setMensajesEntrada([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })*/
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <Tooltip title="Mostrar correos" >
            <IconButton aria-label="show new mails" onClick={ () => agregarNavegador(navegadoresStore, {id: 1000, nombre: 'Mensajes', ruta: 'Mensajes'}, componenteActivoID) }>
                <Badge classes={{ badge: 'notificationColor' }} badgeContent={emails} id="showEmails">
                    <MailIcon />
                </Badge>
            </IconButton>
        </Tooltip>
    );
};
/* --------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
/* --------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, { agregarNavegador })(ShowEmails);
import React, { Fragment } from 'react';
import ConvertDate from '../../../Helpers/ConvertDate';

const InfoPensionesAlimenticias = ({ pAlim }) => {
    return (
        <Fragment>
            <div><strong>Número de Folio: </strong>{ pAlim.folio }</div>
            <div><strong>Nombre: </strong>{ pAlim.nombre }</div>
            <div className="formRelLab">
                <div className="form-left">
                    <div><strong>Primer Apellido: </strong>{ pAlim.apellidoPat }</div>
                    <div><strong>Tipo de Cuota: </strong>{ pAlim.tipoCuotaNom }</div>
                    <div><strong>Fecha de Inicio: </strong>{ ConvertDate(pAlim.fechaIni) }</div>
                </div>
                <div className="form-right">
                    <div><strong>Segundo Apellido: </strong>{ pAlim.apellidoMat }</div>
                    <div><strong>Monto: </strong>{ pAlim.montoPorc }</div>
                    <div><strong>Fecha Final: </strong>{ (pAlim.fechaFin) ? (pAlim.fechaFin !== null && pAlim.fechaFin !== '') ? ConvertDate(pAlim.fechaFin) : '--/--/----' : '--/--/----' }</div>
                </div>
            </div>
        </Fragment>
    );
}
 
export default InfoPensionesAlimenticias;
import React from 'react';

import ModalAddVariables from '../VariablesEmpleado/ModalAddVariables';
import ModalEditVariables from '../VariablesEmpleado/ModalEditVariables';
import ModalDeleteVariables from '../VariablesEmpleado/ModalDeleteVariables';

import ModalAddFijos from '../../Fijos/FijosEmpleado/ModalAddFijos';
import ModalEditFijos from '../../Fijos/FijosEmpleado/ModalEditFijos';
import ModalDeleteFijos from '../../Fijos/FijosEmpleado/ModalDeleteFijos';

import ModalAddRelacionados from '../../Relacionados/RelacionadosEmpleado/ModalAddRelacionados';
import ModalEditRelacionados from '../../Relacionados/RelacionadosEmpleado/ModalEditRelacionados';
import ModalDeleteRelacionados from '../../Relacionados/RelacionadosEmpleado/ModalDeleteRelacionados';

import ModalDeletePendientes from '../../Pendientes/PendientesEmpleado/ModalDeletePendientes';

export function obtenerModalAdd( modulo, openAdd, setOpenAdd, agregar, editar, errorTable, successTable, informacionAdicional ) {
    
    switch ( isNaN( modulo ) ? parseInt( modulo.split('-')[0]) : modulo ) {
        case 41: return <ModalAddVariables openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} idModulo={modulo}/>;
        case 68: return <ModalAddFijos openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacionAdicional={informacionAdicional} idModulo={modulo}/>;
        case 69: return <ModalAddRelacionados openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} idModulo={modulo}/>;
        case 70: return null;

        default: return <h2 className="text-center"> Modal Agregar No encontrado </h2>;
    }
}

export function obtenerModalEdit( modulo, openEdit, setOpenEdit, elementSelect, editar, errorTable, successTable, informacionAdicional ) {
    switch ( isNaN( modulo ) ? parseInt( modulo.split('-')[0]) : modulo ) {
        case 41: return <ModalEditVariables openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} idModulo={modulo}/>;
        case 68: return <ModalEditFijos openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} idModulo={modulo}/>;
        case 69: return <ModalEditRelacionados openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacionAdicional={informacionAdicional} idModulo={modulo}/>;
        case 70: return null;

        default: return <h2 className="text-center"> Modal Editar No encontrado </h2>;
    }
}

export function obtenerModalDelete( modulo, openDelete, setOpenDelete, elementSelect, eliminar, errorTable, informacionAdicional ) {
    switch ( isNaN( modulo ) ? parseInt( modulo.split('-')[0]) : modulo ) {
        case 41: return <ModalDeleteVariables openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;
        case 68: return <ModalDeleteFijos openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;
        case 69: return <ModalDeleteRelacionados openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;
        case 70: return <ModalDeletePendientes openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} informacionAdicional={informacionAdicional}/>;

        default: return <h2 className="text-center"> Modal Eliminar No encontrado </h2>;
    }
}
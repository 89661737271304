import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

//import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
//import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import AlertForm from '../../../../../../../Services/AlertForm';

import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_rh } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import Add from '@material-ui/icons/Add';
import Close from '@material-ui/icons/Close';
import { ArrayJsonIndicadorRelacionado } from '../../../../Helpers/JsonToOneLevel';

const IndicadorRelacionado = ({ elementSelect, informacion }) => {
    /* -------------------------------- Axios para opbtener indicadores -------------------------------- */
    const [ indicadores, setIndicadores ] = useState( null );
    useEffect(() => {
        axiosIndicadores();
        // eslint-disable-next-line
    }, []);

    const axiosIndicadores = () => {
        setIndicadores( null );
        axios.get( `${servicios_grupogack_rh}/indicador/movimientos/${elementSelect.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadores( ArrayJsonIndicadorRelacionado( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        setIndicadores( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------- */
    const [ error, setError ] = useState( null );
    const nominaIndicadorRef = createRef('');
    const porcentajeRef = createRef('');

    const enviar = () => {
        setError( null );
        if( nominaIndicadorRef.current.value !== '' && porcentajeRef.current.value !== '' ){
            if( validarSubmit( 'AgregarIndicadorRelacionado' ) ){
                const nominaIndicador = parseInt( nominaIndicadorRef.current.value );
                const porcentaje = parseFloat( porcentajeRef.current.value );
                //console.log([{ movrelacionado: elementSelect.id, nominaIndicador, porcentaje }]);
                enviarIndicador([{ movrelacionado: elementSelect.id, nominaIndicador, porcentaje }]);
            }
        }else{
            setError( <AlertForm message={ 'Completa todos los campos para agregar un Indicador' } /> );
        }
    };

    const enviarIndicador = ( indicador ) => {
        axios.post( `${servicios_grupogack_rh}/indicador/movimientos/`, indicador, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        axiosIndicadores();
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        //errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_guardado );
            })
    }
    /* ------------------------------------------------------------------------------------------------- */
    const axiosEliminar = ( indicadorID ) => {
        axios.delete( `${servicios_grupogack_rh}/indicador/movimientos/${indicadorID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //eliminar( elementSelect.id );
                        axiosIndicadores();
                        break;
                    default:
                        //errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_eliminado );
            })
    }
    /* ------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <form id="AgregarIndicadorRelacionado">
                <div className="relacionadosFlex">
                    <div className="relacionadosIzquerdo row">
                        <div className="col-md-7 col-sm-12">
                            <SelectValidation title="Concepto Relacionado" arrayOpciones={informacion.indicadoresNomina} keyOpcion="nomIndicador"
                                refValue={nominaIndicadorRef} optional/>
                        </div>
                        <div className="col-md-5 col-sm-12">
                            <InputValidation title="Porcentaje (%)" type="porcentaje" placeholder="Escriba el porcentaje del concepto relacionado"
                                tooltipText={'Máximo 3 números y dos decimales del 0.00 al 100.00'} tooltipName={'tooltipPorcRel'} maxLength='6'
                                refValue={porcentajeRef} optional
                                onlyNumbers="." onBlurDecimal={2}/>
                        </div>
                    </div>
                    <div className="relacionadosDerecho marginRelacionadosDerecho">
                        <Button className="btn-third" onClick={() => enviar()}> <Add/> </Button>
                    </div>
                </div>
                {error}
            </form>

            { indicadores ?
                <div className="mb-3">
                    { indicadores.map( indicador =>(
                        <div key={indicador.id} className="relacionadosFlex mt-2">
                            <div className="relacionadosIzquerdo row">
                                <div className="col-md-7 col-sm-12">
                                    <div className="listIndicadores"> { indicador.nominaIndicador.nomIndicador } </div>
                                </div>
                                <div className="col-md-5 col-sm-12 deletePaddingCol">
                                    <div className="listIndicadores"> { indicador.porcentaje } </div>
                                </div>
                            </div>
                            <div className="relacionadosDerecho">
                                <Button className="buttonDeleteIndicador" onClick={ () => axiosEliminar( indicador.id ) }> <Close/> </Button>
                            </div>
                        </div>
                    ))}
                </div>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default IndicadorRelacionado;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormCalculo from './FormCalculo';
import InfoNomina from './InfoNomina';

const ModalAddCalculo = ({ openEdit, setOpenEdit, errorTable, successTable, elementSelect, nombreModulo, informacionAdicional }) => {
    
    const [error, setError] = useState(null);
    const [tiposAcumulado, setTiposAcumulado] = useState([]);
    const [periodo, setPeriodo] = useState(null);

    const { permisos } = informacionAdicional;

    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}s/tipoacumulado/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTiposAcumulado(res.data.dataResponse);
                    break;
                case 404:
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => { alert('error') });
    },[]);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    return (
        <ModalService
            title="Cálculo de Nómina"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <InfoNomina 
                        elementSelect={elementSelect}
                        periodo={periodo}
                        setPeriodo={setPeriodo}
                        setOpenEdit={setOpenEdit}
                    />
                    <hr/>
                    <FormCalculo
                        nombreModulo={nombreModulo}
                        elementSelect={elementSelect}
                        tiposAcumulado={tiposAcumulado}
                        periodo={periodo}
                        error={error}
                        setError={setError}
                        errorTable={errorTable}
                        successTable={successTable}
                        permisos={permisos}
                        setOpenEdit={setOpenEdit}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddCalculo;
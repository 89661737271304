import React, { createRef, useEffect, useState, Fragment } from 'react';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { servicios_grupogack_catalogo } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';

const FormBajaEmpleados = ({ error, setError, elementSelect, bajaEmpleado }) => {

    const [motivosBaja, setMotivosBaja] = useState([]);
    const [openAlert, setOpenAlert] = useState(false);

    const motivoBajaRef = createRef('');
    const descripcionRef = createRef('');
    const fechaBajaRef = createRef('');

    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}/motivobaja/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                    case 200:
                    setMotivosBaja(res.data.dataResponse);
                    break;
                case 404:
                    setMotivosBaja([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    const muestraAlerta = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const baja = () => {
        if(validarSubmit('BajaEmpleado')) {
            const motivoBajaId = parseInt(motivoBajaRef.current.value);
            const motBaja = descripcionRef.current.value;
            const empPuestoFecfin = inputDateToLong(fechaBajaRef.current.value);

            setError(null);
            bajaEmpleado({ motivoBajaId, motBaja, empPuestoFecfin, empId: elementSelect.id });
        }
    };

    return (
        <Fragment>
            <form id="BajaEmpleado" onSubmit={muestraAlerta}>
                <div className="formRelLab">
                    <div className="form-left">
                        <SelectValidation title="Motivo de Baja" arrayOpciones={motivosBaja} keyOpcion="nombre" refValue={motivoBajaRef} required/>
                        <InputDateValidation title="Fecha de Baja" refValue={fechaBajaRef} defaultValue="" required/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Descripción" type="descripcionBaja" placeholder="Escriba la descripción de la baja"
                            ooltipText={'Máximo 2024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            tooltipName="tooltipBaja" refValue={descripcionRef} fieldType="textarea" maxLength={2024} required/>
                    </div>
                </div>
                { error }
                <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
            </form>
            <Alertwarning show={openAlert} SetopenALert={setOpenAlert} text="El empleado será dado de baja" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={baja}/>
        </Fragment>
    );
}
 
export default FormBajaEmpleados;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormularioTiposAusentismos from './FormularioTiposAusentismos';

const ModalAddTiposAusentismos = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );
    
    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    }

    const enviarTipoAusentismo = ausentismo => {
        axios.post( `${servicios_grupogack_catalogo}/tipoausentismo/`, ausentismo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        setOpenAdd( false );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Nuevo Tipo Ausentismo" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioTiposAusentismos
                    error={error}
                    enviarTipoAusentismo={enviarTipoAusentismo}
                />
        </ModalService>
    );
};

export default ModalAddTiposAusentismos;
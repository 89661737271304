import React, { createRef, useEffect, useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import { validarSubmit } from '../../../../../../../Services/Validation/HelperValidation';

const Formulario = ({ nombre, action }) => {

    const [nominas, setNominas] = useState([]);
    const [anios, setAnios] = useState([]);
    const [periodos, setPeriodos] = useState([]);
    const [acumulados, setAcumulados] = useState([]);

    const nominaRef = createRef('');
    const anioRef = createRef('');
    const periodoRef = createRef('');
    const acumuladoRef = createRef('');

    useEffect(() => {
        axios.get(`${servicios_grupogack_nomina}/config/nomina/`, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:
                    const respuesta = res.data.dataResponse.map(nomina => ( { id: nomina.id, nomina: nomina.nominaNombre } ));
                    setNominas(respuesta);
                    break;
                default:
                    break;
            }
        })
        .catch(error => console.log(error));
    }, []);

    const envia = (e, tipo) => {
        e.preventDefault();
        if(validarSubmit("LayoutCFDI")) {
            const data = {};
            switch(tipo) {
                case 'PDF':
                case 'XML':
                    action(data, tipo);
                break;
                case 'Consultar':
                case 'Cancelar':
                case 'Timbrar':
                    action(data);
                break;
                default:
                break;
            };
        }
    };

    const seleccionaNomina = value => {
        if(value.trim() === '') {
            setAnios([]);
        } else {
            setAnios([{ id: 1, anio: 2020 }]);
            anioRef.current.value = '';
        }
        setPeriodos([]);
        setAcumulados([]);
    };
    
    const seleccionaAnio = value => {
        if(value.trim() === '') {
            setPeriodos([]);
        } else {
            setPeriodos([{ id: 1, periodo: 9 }]);
            periodoRef.current.value = '';
        }
        setAcumulados([]);
    };
    
    const seleccionaPeriodo = value => {
        if(value.trim() === '') {
            setAcumulados([]);
        } else {
            if(nombre === 'Timbrar') setAcumulados([{ id: 1, acumulado: 'Nómina' }])
            else setAcumulados([{ id: 'all', acumulado: 'Todos' }, { id: 1, acumulado: 'Nómina' }])
            acumuladoRef.current.value = '';
        }
    };

    return (
        <form id="LayoutCFDI">
            <SelectValidation
                title="Nómina"
                arrayOpciones={nominas} keyOpcion="nomina"
                refValue={nominaRef} defaultValue=""
                onChangeExecute={seleccionaNomina}
                disabled={(nominas.length > 0) ? false : true}
                required
            />
            <div className="row">
                <div className="col">
                    <SelectValidation
                        title="Año"
                        arrayOpciones={anios} keyOpcion="anio"
                        refValue={anioRef} defaultValue=""
                        onChangeExecute={seleccionaAnio}
                        disabled={(anios.length > 0) ? false : true}
                        required
                    />
                </div>
                <div className="col">
                    <SelectValidation
                        title="Periodo"
                        arrayOpciones={periodos} keyOpcion="periodo"
                        refValue={periodoRef} defaultValue=""
                        onChangeExecute={seleccionaPeriodo}
                        disabled={(periodos.length > 0) ? false : true}
                        required
                    />
                </div>
            </div>
            <SelectValidation
                title="Tipo de Acumulado"
                arrayOpciones={acumulados} keyOpcion="acumulado"
                refValue={acumuladoRef} defaultValue=""
                disabled={(acumulados.length > 0) ? false : true}
                required
            />
            {
                nombre === 'Descargar'
                ?   <div className="row">
                        <div className="col">
                            <Button variant="contained" className="btn-color" onClick={(e) => envia(e, 'PDF')}>PDF</Button>
                        </div>
                        <div className="col">
                            <Button variant="contained" className="btn-color" onClick={(e) => envia(e, 'XML')}>{'<XML>'}</Button>
                        </div>
                    </div>
                :   <Button variant="contained" className="btn-color" onClick={(e) => envia(e, nombre)}>{nombre}</Button>
            }
        </form>
    );
}
 
export default Formulario;
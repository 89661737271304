import React from 'react';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';

/* Estilos Avatar */
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
    small: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
        marginRight: '1rem'
    },
}));

const AutoCompleteUsuarios = ({ usuarios, setDestinatarios, multiple=true }) => {
    const classes = useStyles();
    return (
            <Autocomplete id="tags-outlined" size="small" multiple={multiple} filterSelectedOptions
                options={ usuarios } getOptionLabel={ option => option.username }
                onChange={ (event, value) => setDestinatarios( value ) }

                renderInput={ (params) => (
                    <TextField {...params} variant="outlined" placeholder="" />
                )}

                renderOption={ (option) => (
                    <React.Fragment>
                        <Avatar src={`data:image/jpeg;base64, ${option.img}`} className={classes.small}/>
                        {option.username} / {option.datosUsuarioNom} {option.datosUsuarioPat}
                    </React.Fragment>
                )}

                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        <Chip variant="outlined" size="small" label={option.username} {...getTagProps({ index })}
                            avatar={<Avatar src={`data:image/jpeg;base64, ${option.img}`} />} />
                    ))
                }
            />
    );
};

export default AutoCompleteUsuarios;
import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormMotivosBaja = ({ error, setError, enviarMotivoBaja, elementSelect, actualizarMotivoBaja }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const claveRef = createRef('');
    const nombreRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('MotivoBaja')) {
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value;
            enviarMotivoBaja({ clave, nombre });
        }
    };

    const actualizar = () => {
        if(validarSubmit('MotivoBaja')) {
            const clave = claveRef.current.value;
            const nombre = nombreRef.current.value;
            setError(null);
            actualizarMotivoBaja({ clave, nombre }, elementSelect.id);
        }
    };

    return (
        <Fragment>
            <form id="MotivoBaja" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveMotBaja" placeholder="Escriba la clave del motivo de baja"
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.clave : '' } 
                    tooltipText="Máximo 3 letras y/o números" tooltipName="tooltipCveMotBaja" maxLength={3} alfanumeric required/>
                <InputValidation title="Nombre" type="nombreMotBaja" placeholder="Escriba el nombre del motivo de baja"
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.nombre : '' } 
                    tooltipText={'Máximo 256 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName="tooltipNomMotBaja" maxLength={256} required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment>
    );
}

export default FormMotivosBaja;
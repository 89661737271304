import React, { useState, Fragment } from 'react';
import { validarSubmit, longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import { empleados_, registro_sera_actualizado } from '../../../../../../Constants/Constants';
import Button from '@material-ui/core/Button';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import CropService from '../../../CropService/CropService';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../Media/Img/profile.jpg';

const FormDatosPersonales = ({ elementSelect, verificaCambiosFormEmpleado, actualizar, siguiente, nivelesEscolaridad, tiposEstadoCivil, numEmpleadoRef, nombreRef, pApellidoRef, sApellidoRef, fechaNacimientoRef, generoRef, nivelEscolaridadRef, curpRef, estadosNacimiento, lNacimientoRef, estadoCivilRef, rfcRef, cedProfesionalRef, carreraRef, nssRef, fechaIngresoRef, infoEmpleado, infoEscolaridad, fotografia, setFotografia, fotografiaEdit, setFotografiaEdit }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const [mostrarContenido, setMostrarContenido] = useState(false);
    const genero = [{id: 'F', genero: 'Femenino'}, {id: 'M', genero: 'Masculino'}];

    const mostrarAlert = e => {
        e.preventDefault();
        if(validarSubmit('DatosPersonales') & verificaCambiosFormEmpleado()) {
            setOpenAlert(true);
        }
    };
    
    const obtenerBase64 = imgBase64 => {
        if(elementSelect) {
            setFotografiaEdit(imgBase64);
        } else {
            setFotografia(imgBase64);
        }
    };

    setTimeout(() => {
        setMostrarContenido(true);
    }, 1000);

    return (
        <Fragment>
            {
                (!elementSelect || (infoEscolaridad !== undefined && mostrarContenido === true))
                ?   <Fragment>
                        <h5>Datos Personales</h5>
                            <form id="DatosPersonales" onSubmit={e => siguiente(e, 2)}>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-8">
                                        <InputValidation title="Número de Empleado" type="numEmpleado" placeholder="Escriba el número de empleado"
                                            refValue={numEmpleadoRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadoclave : ''} 
                                            tooltipText="Máximo 10 caracteres alfanuméricos" tooltipName="tooltipNoEmpleado" maxLength={10} required/>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                                        <div className="imagen-upload btn-addPhotoEmp">
                                            <label style={{ backgroundColor: 'transparent'}} htmlFor="fotoEmpleado" type="button"><AddAPhotoIcon className="photoIcon"/></label>
                                            <CropService inputId="fotoEmpleado" parentNode={empleados_} getBase64={obtenerBase64} fotoEmpleado/>
                                        </div>
                                        <div className="avatar-addPhotoEmp">
                                            <Avatar alt="avatar" src={(fotografia) ? `data:image/jpeg;base64, ${(elementSelect) ? (fotografiaEdit) ? fotografiaEdit : atob(fotografia.ruta) : fotografia}` : (elementSelect) ? (fotografiaEdit) ? `data:image/jpeg;base64, ${fotografiaEdit}` : profileIMG : profileIMG}/>
                                        </div>
                                    </div>
                                </div>
                                <InputValidation title="Nombre" type="nombre" placeholder="Escriba el nombre"
                                    refValue={nombreRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadonom : ''} 
                                    tooltipText="Máximo 60 letras y/o espacio" tooltipName="tooltipNomEmp" onlyLetters=" " maxLength={60} required/>
                                <div className="row">
                                    <div className="col-6">
                                        <InputValidation title="Primer Apellido" type="pApellido" placeholder="Escriba el primer apellido" refValue={pApellidoRef}
                                            defaultValue={infoEmpleado ? (infoEmpleado.empleado.empleadopat ? infoEmpleado.empleado.empleadopat : '') : ''} 
                                            tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipPApellido" onlyLetters=" " maxLength={30} optional={true}/>
                                    </div>
                                    <div className="col-6">
                                        <InputValidation title="Segundo Apellido" type="sApellido" placeholder="Escriba el segundo apellido" refValue={sApellidoRef}
                                            defaultValue={infoEmpleado ? (infoEmpleado.empleado.empleadomat ? infoEmpleado.empleado.empleadomat : '') : ''}
                                            tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipSApellido" onlyLetters=" " maxLength={30} optional={true}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <InputDateValidation title="Fecha de Nacimiento" refValue={fechaNacimientoRef}
                                            defaultValue={elementSelect ? longDateToInput(elementSelect.empleadoFecnac) : ''} required/>
                                    </div>
                                    <div className="col-6">
                                        <SelectValidation title="Lugar de Nacimiento" arrayOpciones={estadosNacimiento} keyOpcion="estadoNom"
                                            refValue={lNacimientoRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.estado.id : ''} required/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <SelectValidation title="Género" arrayOpciones={genero} keyOpcion="genero"
                                            refValue={generoRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadoGenero : ''} optional={true}/>
                                    </div>
                                    <div className="col-6">
                                        <SelectValidation title="Estado Civil" arrayOpciones={tiposEstadoCivil} keyOpcion="edoCivilnom"
                                            refValue={estadoCivilRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.edoCivil.id : ''} required/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <SelectValidation title="Escolaridad" arrayOpciones={nivelesEscolaridad} keyOpcion="escolaridadNom"
                                            refValue={nivelEscolaridadRef} defaultValue={infoEmpleado ? infoEscolaridad !== null ? infoEscolaridad.escolaridad.id : '' : ''} required/>
                                    </div>
                                    <div className="col-6">
                                        <InputValidation title="RFC" type="rfc" placeholder="Escriba el RFC"
                                            refValue={rfcRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadorfc : ''}
                                            tooltipText="13 letras y números en formato de RFC" tooltipName="tooltipRFC" maxLength={13} alfanumeric optional={true}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <InputValidation title="CURP" type="curp" placeholder="Escriba el CURP"
                                            refValue={curpRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadocurp : ''} 
                                            tooltipText="18 letras y números en formato de CURP" tooltipName="tooltipCURP" maxLength={18} alfanumeric optional={true}/>
                                    </div>
                                    <div className="col-6">
                                        <InputValidation title="Cédula Profesional" type="cedulaProfesional" placeholder="Escriba la cédula profesional"
                                            refValue={cedProfesionalRef} defaultValue={infoEmpleado ? infoEscolaridad !== null ? infoEscolaridad.empleadoCedula : '' : ''}
                                            tooltipText="Máximo 13 letras, números y/o guión" tooltipName="tooltipCedulaProf" maxLength={13} optional={true}/>
                                    </div>
                                </div>
                                <InputValidation title="Carrera" type="carrera" placeholder="Escriba el nombre de la carrera"
                                    refValue={carreraRef} defaultValue={infoEmpleado ? infoEscolaridad !== null ? infoEscolaridad.empleadoCarrera : '' : ''} 
                                    tooltipText="Máximo 230 letras y/o espacio" tooltipName="tooltipCarrera" maxLength={230} onlyLetters=" " optional={true}/>
                                <div className="row">
                                    <div className="col-6">
                                        <InputValidation title="Número de Seguridad Social" type="nss" placeholder="Escriba el NSS"
                                            refValue={nssRef} defaultValue={infoEmpleado ? infoEmpleado.empleado.empleadonss : ''} 
                                            tooltipText="11 números" tooltipName="tooltipNSS" maxLength={11} onlyNumbers optional={true}/>
                                    </div>
                                    <div className="col-6">
                                        <InputDateValidation title="Fecha de Ingreso a Gobierno" refValue={fechaIngresoRef} disabled={(elementSelect) ? true : false }
                                            defaultValue={infoEmpleado ? longDateToInput(infoEmpleado.antiguedad.empantfecini) : ''} required/>
                                    </div>
                                </div>
                                {
                                    (elementSelect)
                                    ?   <div className="row">
                                            <div className="col-6">
                                                <Button variant="outlined" className="btn-outline" onClick={mostrarAlert}>Actualizar</Button>
                                            </div>
                                            <div className="col-6">
                                                <Button variant="contained" className="btn-color" type="submit">Siguiente</Button>
                                            </div>
                                        </div>
                                    :   <Button variant="contained" className="btn-color" type="submit">Siguiente</Button>
                                }
                            </form>
                            {   elementSelect &&
                                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
                            }
                    </Fragment>
                :   null
            }
        </Fragment>
    );
}
 
export default FormDatosPersonales;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioTipoRegimen = ({ error, enviarTipoRegimen, elementSelect, actualizarTipoRegimen }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarTipoRegimen' ) ){
            const regClave = claveRef.current.value;
            const regDesc = descripcionRef.current.value;
            enviarTipoRegimen({ regClave, regDesc });
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarTipoRegimen' ) ){
            const regClave = claveRef.current.value;
            const regDesc = descripcionRef.current.value;
            actualizarTipoRegimen({ id: elementSelect.id, regClave, regDesc });
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarTipoRegimen' : 'AgregarTipoRegimen' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveTipoRegimen" placeholder="Escriba la clave del Tipo de Régimen"
                    tooltipText={'Máximo 2 números'} tooltipName={'tooltipCveTipoReg'} maxLength='2' onlyNumbers
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.regimenClave : '' } required/>
                <InputValidation title="Descripción" type="descripcionTipoRegimen" placeholder="Escriba la descripción del Tipo de Régimen" maxLength='128'
                    tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescTipoReg'}
                    refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.regimenDesc : '' } required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioTipoRegimen;
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack, puestos_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonPuestos } from '../../../Helpers/JsonToOneLevel';

const Puestos = ({ id, permisos }) => {

    const [puestos, setPuestos] = useState(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupogack}/org/puesto/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    const puesto = ArrayJsonPuestos(res.data.dataResponse);
                    setPuestos(puesto);
                    break;
                case 404:
                    setPuestos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    },[]);
    
    return (
        
        <div id={ id }>
            {
                 puestos   
                ? <DataTableService id={id} title={ puestos_ }
                    permisos={permisos}
                    columns={[
                        { field: "nivlaboralClave", title: "Clave Nivel Laboral" },
                        { field: "puestoCve", title: "Clave de Puesto" },
                        { field: "puestoNom", title: "Nombre del Puesto" },
                        { field: "puestoDescrip", title: "Descripción Puesto" },
                        { field: "status", title: "Estado" },
                        { field: "relabIdNombre", title: "Relación Laboral" },
                        { field: "relabIdDescripcion", title: "Descripción Relación Laboral" }
                    ]}
                    data={puestos}/>
                : <SpinnerOval/>
            }
        </div>

    );
}
 
export default Puestos;
import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormNivelEscolaridad from './FormNivelEscolaridad';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditNivelEscolaridad = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarNivelEscolaridad = (nivelEscolaridad, nivelEscolaridadId) => {
        const info = nivelEscolaridad;
        Axios.put( `${servicios_grupogack_catalogo}/tipoEscolaridad/${nivelEscolaridadId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(res.data.dataResponse);
                    setOpenEdit(false);
                break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg} />);
                    break;

                default:
                    errorTable(cambios_no_guardados);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Nivel de Escolaridad" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormNivelEscolaridad
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarNivelEscolaridad={actualizarNivelEscolaridad}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditNivelEscolaridad;
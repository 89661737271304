import React, { useState } from 'react'; 
import ModalService from '../../../ModalService/ModalService'; 
import FormularioConfigBasesIndGral from './FormularioConfigBasesIndGral';
import axios from 'axios';
import { cambios_no_guardados, servicios_grupogack_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const ModalConfigBaseIndicadoresGral = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo, elementSelect }) => {
    const [ error, setError ] = useState( null );
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ textAlert, setTextAlert ] = useState(''); 
    const [ alertError, setAlertError ] = useState( false );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarConfigBaseIndicadoresGral = dataConfigBase => {
       agregar(dataConfigBase); 
       setOpenAdd( false );
    }
    
    const enviarConfigBaseIndicadoresGralExiste= dataConfigBase => {
        axios.post( `${servicios_grupogack_nomina}/bases/indicadores/`, {basesIndicadores:dataConfigBase} , headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(null);
                    setOpenAdd( false );
                    break;
                default:
                    setTextAlert(cambios_no_guardados);
                    setAlertError( true );
                    break;
            }
        })
        .catch(error => { setTextAlert(cambios_no_guardados);
            setAlertError( true );});
     }

    const actualizarImpuestoNomina = dataConfigBase => {
        axios.put( `${servicios_grupogack_nomina}/bases/indicadores/`, dataConfigBase, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200: 
                        /* setTextAlert('Registro Actualizado');
                        setAlertSuccess( true ); */
                        agregar(null);
                        setOpenAdd( false );
                        break;
                    case 404:
                        setTextAlert(cambios_no_guardados);
                        setAlertError( true );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        setTextAlert(cambios_no_guardados);
                        setAlertError( true );
                        break;
                }
            })
            .catch( error => {
                setTextAlert(cambios_no_guardados);
                setAlertError( true );
            })
    }  

    return (
        <ModalService title="Configuración de Bases" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioConfigBasesIndGral
                    error={error}
                    informacion={informacionAdicional}
                    enviarConfigBaseIndicadoresGral={enviarConfigBaseIndicadoresGral}
                    elementSelect={elementSelect}
                    actualizarImpuestoNomina={actualizarImpuestoNomina}
                    enviarConfigBaseIndicadoresGralExiste={enviarConfigBaseIndicadoresGralExiste}
                />
                <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
                <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            
        </ModalService>
        
            
    );
};

export default ModalConfigBaseIndicadoresGral;
export function headerExcel( jsonElement, separator) {
    var headerText = '';
    Object.keys( jsonElement ).forEach( key => {
        if( key !== 'id' && key !== 'tableData' ){
            headerText = headerText + key + separator;
        }
    })
    return headerText + '\n';
}

export function formatExcel( json, groupBy=0, separator='\t' ) { 
    let headers = json[groupBy]; 
    var cadenaExcel = headerExcel( groupBy>0 ? Object.assign({" ": " "}, headers ): headers, separator);  
    json.forEach( element => {
        var filaExcel = '';
        Object.keys(element).forEach( (key, index) => {
            if( key !== 'id' && key !== 'tableData' ){
                if(index===0 && groupBy>0 && key!== " ") filaExcel = filaExcel + separator;
                let text =  element[key]===null ? '' :  `${key==="Clave Presupuestal" ? "'" :'' }${element[key]}${key==="Clave Presupuestal" ?"'":''}`;
                filaExcel = filaExcel + text + separator;
            }
        })
        cadenaExcel = cadenaExcel + filaExcel + '\n';
    });
    return cadenaExcel;
}

export function  convertColumnasPDF( jsonColumnas, isReport=false, groupBy=false ) {
    const newColumns = groupBy ? [{ 'dataKey': " ", 'title': " " }] : [];
    jsonColumnas.map( (column) => {
        newColumns.push( { 'dataKey': isReport ? column.title : column.field, 'title': column.title } );
        return '';
    });
    return newColumns;
}

export function  convertColumnasPrint( jsonColumnas, isReport=false, groupBy=false  ) {
    const newColumns = groupBy ? [{ 'field': " ", 'displayName': " " }] : [];
    jsonColumnas.map( (column) => {
        newColumns.push( { 'field': isReport ? column.title :column.field, 'displayName': column.title } );
        return '';
    });
    return newColumns;
}

export function getStylesPDF( id ){
    switch(id) {
        case 12: //Bancos
            return {
                orientation: '',
                styles: { },
                columnStyles: { 0: {cellWidth: 13} }
            }
        case 20: //Dependencias
            return {
                orientation: 'landscape',
                styles: { cellPadding: 0.6, fontSize: 8 },
                columnStyles: { 0: {cellWidth: 25}, 1: {cellWidth: 100} }
            }
        case 21: //Proyectos
            return {
                orientation: 'landscape',
                styles: { cellPadding: 0.6, fontSize: 7.5 },
                columnStyles: { 0: {cellWidth: 30}, 5: {cellWidth: 40} }
            }
        case 30: //Empleados
            return {
                orientation: 'landscape',
                columnStyles: { 0: {cellWidth: 30}, 1: {cellWidth: 60}, 5: {cellWidth: 70} }
            }
        case 49: //Reporte Recursos Humanos
            return {
                orientation: 'landscape',
                styles: { cellPadding: 0.6, fontSize: 5.5 }, 
            }
        default:
            return {
                orientation: '',
                styles: { },
                columnStyles: { }
            }
    }
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_catalogo, tipos_jornada } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import jornadaJson from '../../../../../Datos/TipoJornada.json';
const TiposJornada = ({ id, permisos }) => {
    const [ tiposJornada, setTiposJornada ] = useState( null );
    useEffect(() => {
        axiosTiposJornada();
    }, []);

    const axiosTiposJornada = () => {
        /*axios.get( `${servicios_grupogack_catalogo}/tipoJornada`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:*/
                        setTiposJornada( jornadaJson );
          /*              break;
                    case 404:
                        setTiposJornada([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })*/
    }

    return (
        <div id={ id }>
            { tiposJornada ?
                <DataTableService id={id} title={ tipos_jornada } permisos={permisos}
                    columns={[
                        { field: "clave", title: "Clave" },
                        { field: "nombre", title: "Nombre" },
                    ]}
                    data={ tiposJornada } /> : <SpinnerOval />
            }
        </div>
    );
}
 
export default TiposJornada;
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CodeIcon from '@material-ui/icons/Code';

const Tablero = ({ setOpenCancelar, setOpenConsultar, setOpenDescargar, setOpenTimbrar }) => {

    const openModal = nombre => {
        switch(nombre) {
            case 'cancelar':
                setOpenCancelar(true);
            break;
            case 'consultar':
                setOpenConsultar(true);
            break;
            case 'descargar':
                setOpenDescargar(true);
            break;
            case 'timbrar':
                setOpenTimbrar(true);
            break;
            default:
            break;
        }
    };

    return (
        <div className="row">
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('timbrar')}>
                    <CardContent>
                        <div className="icon"><CodeIcon/></div>
                        <p className="name">Timbrar CFDI</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('consultar')}>
                    <CardContent>
                        <div className="icon"><CodeIcon/></div>
                        <p className="name">Consultar Timbrados</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('cancelar')}>
                    <CardContent>
                        <div className="icon"><CodeIcon/></div>
                        <p className="name">Cancelar Timbrados</p>
                    </CardContent>
                </Card>
            </div>
            <div className="col-md-3 col-sm-12 my-2">
                <Card className="cardBoard" onClick={() => openModal('descargar')}>
                    <CardContent>
                        <div className="icon"><CodeIcon/></div>
                        <p className="name">Descargar CFDI</p>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
}
 
export default Tablero;
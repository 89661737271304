import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_admon, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonDependencias } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioDependencia from './FormularioDependencia';
import DomiciliosDependencia from './DomiciliosDependencia/DomiciliosDependencia';

const ModalEditDependencias = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarDependencia = ( dependencia, companiaID ) => {
        axios.put( `${servicios_grupogack_admon}/compania/${companiaID}`, dependencia, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        editar( JsonDependencias(respuesta.data.dataResponse) );
                        setOpenEdit( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Dependencia" parentNode={nombreModulo} minWidth="80%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <FormularioDependencia
                        error={error}
                        elementSelect={elementSelect}
                        informacion={informacionAdicional}
                        actualizarDependencia={actualizarDependencia}
                    />
                </div>
                <div className="col-md-6 col-sm-12">
                    <DomiciliosDependencia
                        dependenciaID={elementSelect.id}
                        errorTable={errorTable}
                        successTable={successTable}
                        idModulo={nombreModulo}
                    />
                </div>
            </div>
        </ModalService>
    );
};

export default ModalEditDependencias;
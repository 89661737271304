import React, { useState, useCallback } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

/* Importaciones necesarias para mostrar un modal */
import ModalService from '../ModalService/ModalService';
import AlertForm from '../../../../Services/AlertForm';
import Button from '@material-ui/core/Button';

import './styles.css';
import image2base64 from "image-to-base64";

const CropService = ({ inputId, parentNode, getBase64, fotoEmpleado }) => {
    /* ------------------------------------------------------------------------------- */
    const [ openModal, setOpenModal ] = useState( false );
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenModal( false );
        setError( null );
        setImgRef( null );
        setPreviewUrl( null );
    }
    /* ------------------------------------------------------------------------------- */
    const [upImg, setUpImg] = useState(null);
    const [imgRef, setImgRef] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(null);
    const [crop, setCrop] = useState(
        inputId === "carga_logo" ? { unit: '%', width: 30, aspect: 305 / 65 } :
        {
            unit: "%",
            width: 100,
            aspect: 16 / 16
        }
    );

    const onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setUpImg(reader.result);
                setOpenModal(true);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        document.getElementById( inputId ).value = "";
    };

    const onLoad = useCallback(img => {
        setImgRef(img);
    }, []);

    const makeClientCrop = async crop => {
        if (imgRef && crop.width && crop.height) {
            createCropPreview(imgRef, crop, 'newFile.jpeg');
        }
    };

    const createCropPreview = async (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    reject(new Error('Canvas is empty'));
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(previewUrl);
                setPreviewUrl(window.URL.createObjectURL(blob));
            }, (fotoEmpleado) ? 'image/jpeg' : 'image/png');
        });
    };
    /* ------------------------------------------------------------------------------- */
    const obtenerBase64 = ( blob ) => {
        if( blob ){
            setError( null );
            image2base64( blob )
                .then( response => {
                    cerrarModal();
                    getBase64( response );
                })
                .catch( error => console.log(error) )
        }else{
            setError( <AlertForm message="Desliza dentro de la imagen para seleccionar un tamaño" /> );
        }
    }
    /* ------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            <input id={inputId} type="file" className="btn btn-light col-md-12"
                onChange={onSelectFile} accept={(fotoEmpleado) ? 'image/jpeg' : 'image/png, image/jpeg'}/>

            <ModalService title="Seleccionar imagen" parentNode={parentNode} minWidth="40%" maxWidth="40%"
                isOpen={ openModal } onClose={ () => cerrarModal() } >
                <div className="cropCenter">
                    <ReactCrop
                        src={upImg}
                        crop={crop}
                        onImageLoaded={onLoad}
                        onChange={crop => setCrop(crop)}
                        onComplete={makeClientCrop}
                    />
                    {/* previewUrl && <img alt="Crop preview" src={previewUrl} /> */}
                </div>
                
                { error }
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <Button variant="outlined" className="btn-outline" onClick={ () => cerrarModal() }> Cancelar </Button>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <Button variant="contained" className="btn-color" onClick={ () => obtenerBase64( previewUrl ) }> Aceptar </Button>
                    </div>
                </div>
            </ModalService>
        </React.Fragment>
    );
};

export default CropService;
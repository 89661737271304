import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonSindicatos } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioSindicatos from './FormularioSindicatos';

const ModalEditSindicatos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarSindicato = ( sindicato ) => {
        axios.put( `${servicios_grupogack_catalogo}/sindicatos/`, sindicato, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        editar( JsonSindicatos(respuesta.data.dataResponse) );
                        setOpenEdit( false );
                        break;
                    case 400:
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Sindicato" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioSindicatos
                    error={error}
                    elementSelect={elementSelect}
                    informacion={informacionAdicional}
                    actualizarSindicato={actualizarSindicato}
                />
        </ModalService>
    );
};

export default ModalEditSindicatos;
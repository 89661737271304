
/* SessionReducer: Control de Sesion */
export const GUARDAR_SESSION = 'GUARDAR_SESSION';
export const ERROR_MESSAGE_LOGIN = 'ERROR_MESSAGE_LOGIN';
export const CATCH_ERROR_SERVER_LOGIN = 'CATCH_ERROR_SERVER_LOGIN';
export const CERRAR_SESSION = 'CERRAR_SESSION';


export const GUARDAR_MODULOS_SERVICE = 'GUARDAR_MODULOS_SERVICE';
export const AGREGAR_NAVEGADOR = 'AGREGAR_NAVEGADOR';
export const QUITAR_NAVEGADOR = 'QUITAR_NAVEGADOR';
export const CAMBIAR_COMPONENTE_ACTIVO = 'CAMBIAR_COMPONENTE_ACTIVO';
export const LIMPIAR_PESTANAS = 'LIMPIAR_PESTANAS';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { Tooltip } from '@material-ui/core';

const BtnEditItem = ({ id, idArray, array, action, remove }) => {

    const muestrainfo = e => {
        e.preventDefault();
        const elementSelected = array.find(element => element[idArray] === id);
        action(elementSelected);
    };

    return (
        <Tooltip title="Editar">
            <button className={remove ? 'btn-editItem-outlined btn-removeItem-service': 'btn-removeItem-outlined'} onClick={e => muestrainfo(e)}><EditIcon fontSize="small"/></button>
        </Tooltip>
    );
}
 
export default BtnEditItem;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioTipoDocumentos = ({ error, enviarTipoDocumento, elementSelect, actualizarTipoDocumento, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const tipodocNomRef = createRef('');
    const extencionRef = createRef('');
    const tipodocCantRef = createRef('');
    const tipodocDescRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarTipoDocumento' ) ){
            const tipodocNom = tipodocNomRef.current.value;
            const extencion = parseInt(extencionRef.current.value);
            const tipodocCant = parseInt(tipodocCantRef.current.value);
            const tipodocDesc = tipodocDescRef.current.value;

            enviarTipoDocumento({ tipodocNom, extencion, tipodocCant, tipodocDesc });
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarTipoDocumento' ) ){
            const tipodocNom = tipodocNomRef.current.value;
            const extencion = parseInt(extencionRef.current.value);
            const tipodocCant = parseInt(tipodocCantRef.current.value);
            const tipodocDesc = tipodocDescRef.current.value;

            actualizarTipoDocumento({ tipodocNom, extencion, tipodocCant, tipodocDesc }, elementSelect.id);
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarTipoDocumento' : 'AgregarTipoDocumento' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Nombre" type="nombreDocumento" placeholder="Escriba el nombre del tipo de documento" maxLength='64'
                    tooltipText={'Máximo 64 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipTipoDocNom'}
                    refValue={tipodocNomRef} defaultValue={ elementSelect ? elementSelect.tipodocNom : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Extensión" arrayOpciones={informacion.extensiones} keyOpcion="tipoextNom"
                            refValue={extencionRef} defaultValue={ elementSelect ? elementSelect.tipoextId : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Cantidad" type="cantidad" placeholder="Digite la cantidad de documentos que serán permitidos"
                            tooltipText={'Máximo 3 números'} tooltipName={'tooltipTipoDocCant'} maxLength='3' onlyNumbers
                            refValue={tipodocCantRef} defaultValue={ elementSelect ? elementSelect.tipodocCant : '' } required/>
                    </div>
                </div>

                <InputValidation title="Descripción" type="descripcionDocumento" placeholder="Escriba la descripción del documento" maxLength='1024'
                    tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipTipoDocDesc'}
                    refValue={tipodocDescRef} defaultValue={ elementSelect ? elementSelect.tipodocDesc : '' } required/>

                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioTipoDocumentos;
import React, { useState, useEffect } from 'react'; 

import Axios from 'axios';
import {  servicios_grupogack_catalogo, cambios_no_guardados, campos_vacios, registro_sera_eliminado, registro_sera_actualizado} from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import {  Delete} from '@material-ui/icons';
import {IconButton, Button} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import '../../../../../../Components/Usuarios/Usuario/DataTableService/StyleDataTable.css';  
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import { obtenerModalDelete, obtenerModalAdd } from '../../../../../../Components/Usuarios/Usuario/DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; 
import { longDateToInput, amountToFloat, floatToAmount } from '../../../../../../Services/Validation/HelperValidation';
import { ArrayJsonTablasSubsidios } from '../../../Helpers/JsonToOneLevel'; 

const DinamicTableTablasSubsidios = ({idTab, onShowTable, onlyRead, idModulo}) => { 
    const columnHeaderShow =
        ["Para Ingresos de (MXN)", "Hasta Ingreso de (MXN)", "Ingreso Abierto", "Cantidad de subsidio para el Empleo Actual (MXN)", "Acciones"];
    const columnsTable =[
        "limiteInf","limiteSup", "limAbi", "importe","accion"];  

    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ alertWarningLocal, setAlertWarningLocal ] = useState( false );
    const [ openAdd, setOpenAdd ] = useState( false ); 
    const [ shouldUpdate, setShouldUpdate ] = useState( false ); 
    const [ loading, setLoading ] = useState( false ); 
    const [ dta, setDta]= useState([]);  
    const [ isNew, setIsNew]= useState(false);
    const [ loadingData, setLoadingData]= useState(false);
    const [ limAbierto, setLimAbierto ] = useState(-1); 
    const [ fechaFinal, setFechaFinal] = useState(idTab.fechaFin === '-');
    const [ addNewRow, setAddNewRow ] = useState( false );
    const [ fuenteAlertUpdate, setFuenteAlertUpdate ] = useState(false);
     
    useEffect(() => { 
 
            Axios.get(`${servicios_grupogack_catalogo}s/subsidio/bysubdiorango/${idTab.id}`, headersAuth())
            .then( res => {
                setLoadingData(true); 
                switch(res.data.status) {
                    case 200:
                        let dataSubsidios =  ArrayJsonTablasSubsidios(res.data.dataResponse);
                        if(dataSubsidios[dataSubsidios.length-1].limiteSup===0 && dataSubsidios.length>=2 ) { 
                            dataSubsidios[dataSubsidios.length-2].limAbi=true; 
                            setAddNewRow(true); 
                            setLimAbierto(dataSubsidios.length-2);
                        } 
                        setDta(dataSubsidios);
                        break;
                    case 404:
                        setIsNew(true)
                        break;
                    default:
                        alert('error');
                        break;
                }
                setLoadingData(false);
            })
            .catch(error => alert(error));  
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
[]);  

    const del=async (n)=>{
        
        var newDta=[]; 
        if(isNaN(n)){
            const index=n.split('-');
            newDta=dta.filter(element  => element.idLocal !== parseInt(index[0])) 
        }else{
            newDta=dta.filter(element  => element.id !== parseInt(n)) 
        }    
        await setDta(newDta);
        for(var i=0; i<newDta.length; i++){
            for(var j=0; j<columnsTable.length; j++){  
                var input = document.getElementsByName(`${i}${columnsTable[j]}`); 
                if(input[0]!==undefined){ 
                    input[0].value=newDta[i][columnsTable[j]];  
                }
            }
        } 

        var obj=[];
        for (const key in newDta) { 
            obj.push(newDta[key].limiteInf === '' || (newDta[key].limiteSup === '' && limAbierto+1!==key )|| newDta[key].importe === '' ); 
        }
        
        setAddNewRow((obj.includes(true) || limAbierto+1 === newDta.length-1) && limAbierto!==-1); 


    }
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    } 

    const invalidInputs=(name)=>{
        let input = document.getElementsByName(name); 
        input[0].className="inpTable minIn is-invalid"; 
    } 

    const generateHeader=() =>{
        
       return columnsTable.map((key, index)=>{
           if(onlyRead){
               if(key!=='accion')
               return <th key={index} className="MuiTableCell-root negritas">{ columnHeaderShow[index] }</th> 
           }else{
                return <th key={index} className="MuiTableCell-root negritas">{ columnHeaderShow[index] }</th> 
           }
           return null;
       })
     }

     const updateData=(key, value, index)=>{ 
            
        dta[index][key]= value==='' ? value : amountToFloat(value); 
        setShouldUpdate(true);
                  
     }


     const validNumber=(key, value, e, index)=>{

        value =   !/^\$(\d+|\d{1,3}(,\d{3})*)(\.\d+)/g.test(value) ? floatToAmount(parseFloat(value), 2) : value; 
        if( !/^\$(\d+|\d{1,3}(,\d{3})*)(\.\d+)/g.test(value) || /\$0.00/g.test(value) || value.includes('NaN') ){ 
            if(key==='limiteSup' && index===dta.length-1 ){ 
                e.target.className="inpTable minIn"; 
            }else{
                e.target.className="inpTable minIn is-invalid";
            }
        }else{ 
            e.target.className="inpTable minIn"; 
            e.target.value = value
        }

        var obj=[];
        for (const key in dta) { 
            obj.push(dta[key].limiteInf === '' || (dta[key].limiteSup === '' && limAbierto+1!==key )|| dta[key].importe === '' ); 
        } 
        setAddNewRow((obj.includes(true) || (limAbierto+1 === dta.length-1 && limAbierto!==-1)));
     }

    const updateLimiteAbierto=(index)=>{
        
        setLimAbierto(index);
        setAddNewRow(index!==dta.length-1)
        var input = document.getElementsByName(`${index+1}limiteSup`);
        if(input[0]!==undefined){
            input[0].className="inpTable minIn";
            dta[index+1].limiteSup=null; 
        }
        setShouldUpdate(true);
        
    }
    
    const deleteOptionals = ( objectRequest )=>{
        var temporal = objectRequest;
        Object.keys( temporal ).forEach( key => {
            if( temporal[key] === '' || temporal[key] === null){
                delete temporal[key];
            }
        });
        return temporal;
    }

    const keysAllowed = (e) =>{
        return e.keyCode!==8 && e.keyCode!==9 && e.keyCode!==46 && !(e.keyCode >= 37 && e.keyCode <= 40 ) && !((e.keyCode === 65 || e.keyCode === 67) && (e.ctrlKey || e.metaKey ));
    }

     const validarInput=e=>{
        
        const number = /^[0-9]$/; 
        if( !number.test(e.key) && keysAllowed(e) && (e.key!=='.' || e.target.value.includes('.')) ){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
    }


      const generateTableData=()=>{ 
         let res=[]; 
         
         for(let i =0; i < dta.length; i++){
             
            res.push(
               <tr key={`${columnsTable[i]}-${i}`} className="MuiTableRow-root">
                  { 
                    columnsTable.map((key, index)=>{
                        
                        if(onlyRead){
                            if(key !== 'accion')
                            return  <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                {dta[i][key]}
                            </td>
                        }else{ 
                            if(key==='limAbi'){  
                                
                                if((dta[i]['limiteSup']!==null || dta[i]['limiteSup']!=='' ) && i>=dta.length-2){
                                    return <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                    <label className="labelRadio">
                                        <input type="radio"  name="fecha" onChange={e=>{ updateLimiteAbierto(i) }}
                                            defaultChecked={ dta[i].limAbi}/>
                                        <span className="spanRadio"></span>
                                    </label>
                                    </td>
                                }else{
                                    return <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min"></td>
                                } 
                            } 
                            
                            return  <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                        {key==='accion' && !dta[i][key] ?<span name={`${i}${key}`}><IconButton  onClick={e => alertDelete(dta[i]['id'] ? dta[i]['id'] : `${dta[i]['idLocal']}-id`)}><Delete fontSize="small"/></IconButton></span>:
                                        <input type="text" name={`${i}${key}`} 
                                        onKeyDown = { e => { validarInput(e) } }  
                                        style={{textAlign: 'right'}}
                                        onBlur={e=> {validNumber(key, e.target.value, e, i)}}  
                                        className={`inpTable minIn`} defaultValue={ (key === 'limiteSup' && limAbierto+1===i && limAbierto!==-1) ? '' :dta[i][key]} id={i} onChange={e =>updateData(key, e.target.value, e.target.id )}/>}
                                    </td>                        
                            }
                            return null;
                        }
                    )
                  } 
               </tr>
            )
         }
         return res;
     }

     const alertDelete=(n)=>{
        setElemmentSelect(n)
        
        if(isNaN(n)){
            setTextAlert(registro_sera_eliminado); 
            setAlertWarningLocal(true);
        }else{
            setAlertWarning( true ); 
        } 
     }

     const delRow=()=> {
        setAlertSuccess(true);
        setTextAlert('Registro Eliminado');
        del(elemmentSelect);
        
      }
    
    const addRow=()=>{
        setDta([...dta, {idLocal: dta.length, limiteInf: '', limiteSup: '', limAbi: '', importe: '' }]);
        setShouldUpdate(true);
        setAddNewRow(true);
    } 

     const table=()=>{
         if(dta)
         return  <div><table style={{overflowX: "scroll"}} className="MuiTable-root">
            <thead className="MuiTableHead-root">
                <tr className="MuiTableRow-root MuiTableRow-head">
                {generateHeader()}
                </tr>
            </thead>
            <tbody className="MuiTableBody-root">
                {dta ? generateTableData() : null}
                 
                {dta.length<1  ?
                    <tr style={{textAlign: "center", height: "245px"}}>
                        <td colSpan={columnHeaderShow.length}>{loadingData ? <SpinnerOval/> : 'No existen registros'}</td>
                    </tr>
                :null}
            </tbody>
     </table></div> ;
     }
     
     const saveInfo= async () =>{
        setLoading(true); 
        const obj=[]; 
        var validos=true;
        var invalidos=[]; 
        dta.map((key, index)=>{ 
            
            const llaves=Object.keys(key);  
            const llave= llaves.filter(element=>element!=='idLocal').filter(element=> element!=='id').filter(element=>element!=='limAbi'); 
            for (const i in llave) {   
                if( key[llave[i]]==='' ){
                     
                    if(llave[i]==='limiteSup' && index===dta.length-1){

                    }else{
                        invalidos.push(`${index}${llave[i]}`);
                        validos = false;
                    }
                    
                }
            } 

            obj.push(deleteOptionals({
                id: key['id'] ? key['id'] : '' ,
                subdioRango:idTab.id,
                limiteInf: isNaN(key['limiteInf']) ? amountToFloat(key['limiteInf']) : key['limiteInf'], 
                limiteSup: isNaN(key['limiteSup']) ? amountToFloat(key['limiteSup']) : key['limiteSup']==='' || key['limiteSup']===null? 0 : key['limiteSup'],
                importe: isNaN(key['importe']) ? amountToFloat(key['importe']) : key['importe']
            }))
            return null;
        }) 
            
        if(validos){ 
            if(isNew){  
                insertData(obj);
            }else if(shouldUpdate){
                setFuenteAlertUpdate(true);
                setElemmentSelect(obj); 
                setTextAlert(registro_sera_actualizado); 
                setAlertWarningLocal(true);
                setLoading(false);
            }else{
                setTextAlert("¡No hay cambios por guardar!");
                setAlertSuccess(true);
                setLoading(false);
            }
        }else{
            setLoading(false);
            for (const key in invalidos) {
                invalidInputs(invalidos[key]);
            } 
            setTextAlert(campos_vacios)
            setAlertError(true);
        } 
     }

     const insertData=(request)=>{ 
        Axios.post(`${servicios_grupogack_catalogo}s/subsidio/`, request , headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        setLoading(false);
                        setTextAlert(res.data.msg);
                        setAlertSuccess(true);
                        setIsNew(false);
                        break;
                    case 404:
                        setLoading(false);
                        setAlertError(true);
                        break;
                    case 400:
                        setLoading(false);
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                    default:
                        alert('error');
                        setLoading(false);
                        setTextAlert(cambios_no_guardados);
                        setAlertError(true);
                        break;
                }
            }).catch(error =>  {setLoading(false); alert(error)});
     }
     
     const updateDataBack =()=>{
        setLoading(true);
        Axios.put(`${servicios_grupogack_catalogo}s/subsidio/`, elemmentSelect, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setLoading(false);
                    setTextAlert(res.data.msg);
                    setAlertSuccess(true); 
                    setShouldUpdate(false);
                    let dataImpuestos =  ArrayJsonTablasSubsidios(res.data.dataResponse);
                        if(dataImpuestos[dataImpuestos.length-1].limiteSup==='' && dataImpuestos.length>=2) { 
                            dataImpuestos[dataImpuestos.length-2].limAbi=true; 
                            setAddNewRow(true);
                        };
                    setDta(dataImpuestos);
                    break;
                case 404:
                    setLoading(false);
                    setAlertError(true);
                    break;
                case 400:
                    setLoading(false);
                    setTextAlert(res.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setLoading(false);
                    alert('error');
                    setTextAlert(cambios_no_guardados);
                    setAlertError(true);
                    break;
            }
        }).catch(error => {setLoading(false); alert(error)});
     }
 
     const agregar = ( newElement, editElement ) => { 
        if(newElement!==null){
            setDta( newElement.concat(dta) ); 
            setShouldUpdate(true);
            idTab.fechaFin=longDateToInput(newElement[0].periodoFecfin+86400000+86400000);
        }else{
            onShowTable(true, idTab, null, editElement);
            idTab.fechaFin=editElement.fechaFin;
            setFechaFinal(false);
            setTextAlert('Registro Actualizado');
            setAlertSuccess(true); 
        }
        
    }
 

   return (
       <div id={ idModulo } className="back MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded">
            {
                
                <div className="row justify-content-between titleDinamicTable">
                    <div className="col-auto mr-auto "> 
                        <div className="form-inline" > 
                            <div className=" form-inline">
                                <b> Tabla </b> {`: ${idTab.periodoImpto} `} 
                                <b style={{paddingLeft:'3rem'}}>Periodo </b> <p style={{paddingRight:'3rem'}}>{`: ${idTab.fechaIni} - `}{fechaFinal ?
                                    <Button className="form-control btn-color" onClick={()=>setOpenAdd(true)}  style={{marginRight: "10px"}}> 
                                        Finalizar Periodo
                                    </Button> : idTab.fechaFin }</p>
                            </div> 
                            {
                            !onlyRead
                            ?
                            <Button className="form-control btn-third" disabled={addNewRow} onClick={()=>addRow()}  style={{marginRight: "10px"}}> 
                                <AddIcon/>
                            </Button>
                            :
                            null
                            }                   
                        </div>
                    </div>
                    <div className="col-auto form-inline">
                        {   
                            loading
                            ? 
                                <SpinnerOval/>
                            :
                            !onlyRead
                            ?
                            <button className="form-control btn-color" onClick={()=>saveInfo()}  style={{marginRight: "10px"}}> 
                                <DoneIcon/>
                            </button>
                            : null
                        }
                        <button className={!onlyRead ? "form-control btn-outline": "form-control btn-color"} onClick={()=>onShowTable(false)}  > 
                                <CloseIcon/>
                        </button>
                    </div> 
                </div> 
            }         
           {
               <div style={{overflowX: "scroll"}} className="ContenedorDataTable">
                  {table()}
               </div>
           }
           {obtenerModalDelete( idModulo, alertWarning, setAlertWarning, elemmentSelect, null, delRow, errorTable, true )}
           { obtenerModalAdd( idModulo, openAdd, setOpenAdd, agregar, null, errorTable, null, idTab ) }
            <Alertwarning show={alertWarningLocal} SetopenALert={setAlertWarningLocal} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={fuenteAlertUpdate ? updateDataBack : delRow } />
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
       </div>

   );
}
 
export default DinamicTableTablasSubsidios;
import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, deleteOptionals, DIAS_AGNIO, MONTO } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation'; 
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado, registro_sera_eliminado, registro_no_eliminado } from '../../../../../../Constants/Constants';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import { servicios_grupogack_rh } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import AlertForm from '../../../../../../Services/AlertForm'; 

const FormularioPoliticas = ({ error, enviarPolitica, elementSelect, actualizarPolitica, vista }) => {
    
    const tipos = [ {id: 0, nombre: 'Aguinaldo'},
                    {id: 1, nombre: 'Prima Vacacional'},
                    {id: 2, nombre: 'Día de Ajuste'},
                    {id: 3, nombre: 'Vacaciones'}
                    ];

    const [ errorDate, setErrorDate ] = useState( null );
    const [ errorDias, setErrorDias ] = useState( null );
    const [ errorPeriodosDias, setErrorPeriodosDias ] = useState( null );
    const [ errorPeriodos, setErrorPeriodos ] = useState( null );
    const [ diasBonoSemestre, setDiasBonoSemestre ] = useState( elementSelect ? elementSelect.diasBonoAnio/2 : null );
    const [ diasAguinaldoSemestre, setDiasAguinaldoSemestre ] = useState( elementSelect ? tipos.filter(element => element.nombre.includes(elementSelect.tipo.replace('Días','Día')))[0].id === 0 ? elementSelect.diasAguinaldoAnio/2 : tipos.filter(element => element.nombre.includes(elementSelect.tipo.replace('Días','Día')))[0].id === 1 ? elementSelect.diasPrimaAnio/2 :null :null ); 
    const [ openAlert, setOpenAlert ] = useState( false );
    const tipoRef = createRef('');
    const [active, setActive] = useState(elementSelect ? tipos.filter(element => element.nombre.includes(elementSelect.tipo.replace('Días','Día')))[0].id :-1);

    const feciniRef = createRef('');
    const fecfinRef = createRef('');

    const feciniPrimaRef = createRef('');
    const fecfinPrimaRef = createRef('');
    
    const feciniDiaAjusteRef = createRef('');
    const fecfinDiaAjusteRef = createRef('');
    
    const feciniVacacionesRef = createRef('');
    const fecfinVacacionesRef = createRef('');

    const diasAguinaldoRef = createRef(''); 
    const diasAjusteRef = createRef('');
    const diasAjustePeriodoRef = createRef(''); 
    const diasBonoRef = createRef(''); 
    const pagoSegundoSemRef = createRef(''); 
    const pagoPrimerSemRef = createRef(''); 
    const deAgniosRef = createRef(''); 
    const hastaAgniosRef = createRef(''); 
    const diasPrimerSemRef = createRef('');
    const diasSegundoSemRef = createRef('');  
    const periodoPagoRef = createRef('');

    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const [ deleteRow, setDeleteRow ] = useState( false );
    const [ canAdd, setCanAdd ] = useState( false );
    const [ dias, setDias] = useState([]);
    const [ diasVacaciones, setDiasVacaciones] = useState([]);
    const [ idToDelete, setIdToDelete ] = useState( null );
    
    const [ periodosAgnio, setPeriodosAgnio ] =useState([
        {id:1}, {id:2}, {id:3}, {id:4}, {id:5},
        {id:6}, {id:7}, {id:8}, {id:9}, {id:10},
        {id:11}, {id:12}, {id:13}, {id:14}, {id:15},
        {id:16}, {id:17}, {id:18}, {id:19}, {id:20},
        {id:21}, {id:22}, {id:23}, {id:24}  
    ]);

    const regex=/VA|PV|DA|AG$/;

    const compararPeridos = ( periodoInicial, periodoFinal ) => {
        if( periodoFinal === '' ){
            return true;
        }else{
            if( periodoInicial < periodoFinal ){
                return true;
            }else{
                return false;
            }
        }
    }

    useEffect(()=>{
        if( elementSelect && active === 2 ){ 
            axios.get(`${servicios_grupogack_rh}/periodo/ajuste/politica/ajuste/${elementSelect.id.replace(regex,'')}`, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        const respuesta =res.data.dataResponse;
                        let newPeriodosAgnio = periodosAgnio;
                        let result =0;
                        let diaAjus=0;
                        for (const key in respuesta) {
                            diaAjus=parseFloat(respuesta[key].diasAjuste.diasAjuste);
                            result+=parseFloat(respuesta[key].diasPagoPeriodo); 
                            const newPeriodos = newPeriodosAgnio.filter(element => element.id !== respuesta[key].periodoPago) ;
                            newPeriodosAgnio = newPeriodos;
                        } 
                        if(result>=diaAjus){
                            setCanAdd(true);
                        }
                        setPeriodosAgnio(newPeriodosAgnio);
                        setDias( respuesta );
                        break;
                    case 404:
                        setDias([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert(error));
        }
        if( elementSelect && active === 3 ){ 
            axios.get(`${servicios_grupogack_rh}/vacaciones/antiguedad/politcaVacaciones/${elementSelect.id.replace(regex,'')}`, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        setDiasVacaciones( res.data.dataResponse );
                        break;
                    case 404:
                        setDias([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert(error));
        }

        // eslint-disable-next-line
    },[]);

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setDeleteRow(false);
        setTextAlert(registro_sera_actualizado);
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();  
        switch (active){
            case 0:
                if(validarSubmit('AgregarAguinaldo')){  
                    const fechaIni = inputDateToLong( feciniRef.current.value );
                    const fechaFin = inputDateToLong( fecfinRef.current.value );
                    const diasAguinaldoAnio = parseFloat( diasAguinaldoRef.current.value );  
                    const diasBonoAnio = parseFloat( diasBonoRef.current.value );
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value );

                    if( compararPeridos(fechaIni, fechaFin) ){
                        setErrorDate( null );
                        if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                            setErrorPeriodos( null );
                            enviarPolitica(deleteOptionals({fechaIni, fechaFin, diasAguinaldoAnio, diasBonoAnio, periodoPagoSem1, periodoPagoSem2}), active);
                        }else{
                            setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre" /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                    } 
                }
            break; 
            case 1:
                if(validarSubmit('AgregarPrimaVacacional')){
                    const fechaIni = inputDateToLong( feciniPrimaRef.current.value );
                    const fechaFin = inputDateToLong( fecfinPrimaRef.current.value );
                    const diasPrimaAnio = parseFloat( diasAguinaldoRef.current.value ); 
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value );
                    if( compararPeridos(fechaIni, fechaFin) ){
                        setErrorDate( null );
                        if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                            setErrorPeriodos( null );
                            enviarPolitica(deleteOptionals({ fechaIni, fechaFin, diasPrimaAnio, periodoPagoSem1, periodoPagoSem2 }), active);
                        }else{
                            setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre" /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                    }
                }
            break;
            case 2:
                
                if(validarSubmit('AgregarDiaAjuste')){
                    if(dias.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Pago y Días a Pagar." /> );
                    }else{
                        let result =0;
                        for (const key in dias) {
                            result+=parseFloat(dias[key].diasPagoPeriodo)
                        }
                        if(result > parseFloat( diasAjusteRef.current.value ) ) {
                            setErrorDias(<AlertForm message="La suma de días a pagar es mayor a la cantidad de días de ajuste" />); 
                        }else{
                            const fechaIni = inputDateToLong( feciniDiaAjusteRef.current.value );
                            const fechaFin = inputDateToLong( fecfinDiaAjusteRef.current.value );
                            const diasAjuste = parseFloat( diasAjusteRef.current.value ); 
                            if( compararPeridos(fechaIni, fechaFin) ){
                                setErrorDate( null );
                                enviarPolitica(deleteOptionals({ fechaIni, fechaFin, diasAjuste }), active, dias);
                            }else{
                                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                            }
                        }
                    }
                    
                }
            break;
            case 3:
                if(validarSubmit('AgregarVacaciones')){ 
                    const fechaIni = inputDateToLong( feciniVacacionesRef.current.value );
                    const fechaFin = inputDateToLong( fecfinVacacionesRef.current.value );
                    if(diasVacaciones.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Vacaciones" /> );
                    }else{
                        if( compararPeridos(fechaIni, fechaFin) ){
                            setErrorDate( null );
                            enviarPolitica(deleteOptionals({ fechaIni, fechaFin }), active, diasVacaciones); 
                        }else{
                            setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                        }
                    }
                    
                }
            break;
            default:
            break;
        }
        
    }

    const actualizar = () => {
        switch (active){
            case 0:
                if(validarSubmit('EditarAguinaldo')){  
                    const fechaIni = inputDateToLong( feciniRef.current.value );
                    const fechaFin = inputDateToLong( fecfinRef.current.value );
                    const diasAguinaldoAnio = parseFloat( diasAguinaldoRef.current.value );  
                    const diasBonoAnio = parseFloat( diasBonoRef.current.value );
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value );
                    if( compararPeridos(fechaIni, fechaFin) ){
                        setErrorDate( null );
                        if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                            setErrorPeriodos( null );
                            actualizarPolitica(deleteOptionals({fechaIni, fechaFin, diasAguinaldoAnio, diasBonoAnio, periodoPagoSem1, periodoPagoSem2}), active);      
                        }else{
                            setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre" /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                    }
                }
            break; 
            case 1:
                if(validarSubmit('EditarPrimaVacacional')){
                    const fechaIni = inputDateToLong( feciniPrimaRef.current.value );
                    const fechaFin = inputDateToLong( fecfinPrimaRef.current.value );
                    const diasPrimaAnio = parseFloat( diasAguinaldoRef.current.value ); 
                    const periodoPagoSem1 = parseInt( pagoPrimerSemRef.current.value );
                    const periodoPagoSem2 = parseInt( pagoSegundoSemRef.current.value );
                    if( compararPeridos(fechaIni, fechaFin) ){
                        setErrorDate( null );
                        if( compararPeridos(periodoPagoSem1, periodoPagoSem2) ){
                            setErrorPeriodos( null );
                            actualizarPolitica(deleteOptionals({ fechaIni, fechaFin, diasPrimaAnio, periodoPagoSem1, periodoPagoSem2 }), active);
                        }else{
                            setErrorPeriodos( <AlertForm message="El periodo del 2do semestre no puede ser menor o igual al periodo del 1er semestre" /> );
                        }
                    }else{
                        setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                    }
                }
            break;
            case 2:
                if(validarSubmit('EditarDiaAjuste')){
                    if(dias.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Pago y Días a Pagar." /> );
                    }else{
                        let result =0;
                        for (const key in dias) {
                            result+=parseFloat(dias[key].diasPagoPeriodo)
                        }
                        if(result > parseFloat( diasAjusteRef.current.value ) ) { 
                            setErrorDias(<AlertForm message="La suma de días a pagar es mayor a la cantidad de los días de ajuste" />); 
                        }else{
                            const fechaIni = inputDateToLong( feciniDiaAjusteRef.current.value );
                            const fechaFin = inputDateToLong( fecfinDiaAjusteRef.current.value );
                            const diasAjuste = parseFloat( diasAjusteRef.current.value );
                            if( compararPeridos(fechaIni, fechaFin) ){
                                setErrorDate( null );
                                actualizarPolitica(deleteOptionals({ fechaIni, fechaFin, diasAjuste }), active); 
                            }else{
                                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                            }
                        }
                    }
                }
            break;
            case 3:
                if(validarSubmit('EditarVacaciones')){ 
                    const fechaIni = inputDateToLong( feciniVacacionesRef.current.value );
                    const fechaFin = inputDateToLong( fecfinVacacionesRef.current.value );
                    if(diasVacaciones.length<1){
                        setErrorPeriodosDias( <AlertForm message="Se debe agregar mínimo un Periodo de Vacaciones" /> );
                    }else{
                        if( compararPeridos(fechaIni, fechaFin) ){
                            setErrorDate( null ); 
                            actualizarPolitica(deleteOptionals({ fechaIni, fechaFin }), active);
                        }else{
                            setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
                        }
                    }
                }
            break;
            default:
            break;
        }
    }
    

    const showForm = (id) => {
        
        tipoRef.current.value=''; 
        vista(parseInt(id));
        setActive(parseInt(id));
        
    }
    
    const atras = (e, pag) => {
        e.preventDefault();
        setErrorDate( null );
        setErrorPeriodos( null );
        setErrorDias( null );
        switch (active){
            case 0: 
                    feciniRef.current.value = '';
                    fecfinRef.current.value = '';
                    diasAguinaldoRef.current.value = '';
                    diasBonoRef.current.value = '';
                    pagoPrimerSemRef.current.value = '';
                    pagoSegundoSemRef.current.value = '';
                    setDiasAguinaldoSemestre(null);
                    setDiasBonoSemestre(null);
            break; 
            case 1: 
                    feciniPrimaRef.current.value = '';
                    fecfinPrimaRef.current.value = '';
                    diasAguinaldoRef.current.value = '';
                    pagoPrimerSemRef.current.value = '';
                    pagoSegundoSemRef.current.value = '';
                    setDiasAguinaldoSemestre(null);
            break;
            case 2:
                    feciniDiaAjusteRef.current.value = ''; 
                    fecfinDiaAjusteRef.current.value = ''; 
                    diasAjusteRef.current.value = '';
                    periodoPagoRef.current.value = '';
                    diasAjustePeriodoRef.current.value = '';
            break;
            case 3:
                feciniVacacionesRef.current.value = '';
                fecfinVacacionesRef.current.value = '';
                deAgniosRef.current.value = '';
                hastaAgniosRef.current.value = '';
                diasPrimerSemRef.current.value = '';
                diasSegundoSemRef.current.value = '';
            break;
            default:
            break;
        }
        vista(null)
        setActive(pag);
    };
 
    const deleteDay=(id)=>{
        
        axios.delete( `${servicios_grupogack_rh}/periodo/ajuste/${id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setTextAlert('Registro Eliminado');
                    setAlertSuccess(true);
                    break;
                case 404:
                    setTextAlert(respuesta.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setTextAlert( registro_no_eliminado );
                    setAlertError(true);
                    break;
            }
        })
        .catch( error => {
            setTextAlert( registro_no_eliminado );
            setAlertError(true);
        }) 
    }

    const deleteDayVac=(id)=>{
        
        axios.delete( `${servicios_grupogack_rh}/vacaciones/antiguedad/${id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setTextAlert('Registro Eliminado');
                    setAlertSuccess(true);
                    break;
                case 404:
                    setTextAlert(respuesta.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setTextAlert( registro_no_eliminado );
                    setAlertError(true);
                    break;
            }
        })
        .catch( error => {
            setTextAlert( registro_no_eliminado );
            setAlertError(true);
        }) 
    }

    const deleteDias = async () => {
        let diaAjus = parseFloat(diasAjusteRef.current.value);

        const indice = dias.findIndex(dia  => dia.id === parseInt(idToDelete));
        let newDias =[];
        if(indice!==-1){
            await deleteDay(parseInt(idToDelete));
            setPeriodosAgnio([...periodosAgnio, { id: parseInt(dias[indice].periodoPago)}].sort((a, b) => (a.id > b.id) ? 1 : -1));
            newDias = dias.filter(dia  => dia.id !== parseInt(idToDelete)); 
            await setDias(newDias);
        }else{
            const index = dias.findIndex(dia  => dia.idLocal === idToDelete);
            setPeriodosAgnio([...periodosAgnio, { id: parseInt(dias[index].periodoPago)}].sort((a, b) => (a.id > b.id) ? 1 : -1));
            newDias = dias.filter(dia  => dia.idLocal !==idToDelete); 
            await setDias(newDias);
        }
 
        let result=0;
        for(var i=0; i<newDias.length; i++){
            for(var j=0; j<2; j++){
                var label = document.getElementsByName(`${j}-${newDias[i].id}`);                
                label[0].value=newDias[i].periodoPago; 
            }
            result+=parseFloat(newDias[i].diasPagoPeriodo);
            if(result<diaAjus){
                setCanAdd(false);
            } 
        }
    }

    const deleteDiasVac = async () => { 

        const indice = diasVacaciones.findIndex(dia  => dia.id === parseInt(idToDelete));
        let newDias =[];
        if(indice!==-1){
            await deleteDayVac(parseInt(idToDelete)); 
            newDias = diasVacaciones.filter(dia  => dia.id !== parseInt(idToDelete)); 
            await setDiasVacaciones(newDias);
        }else{ 
            newDias = diasVacaciones.filter(dia  => dia.idLocal !==idToDelete); 
            await setDiasVacaciones(newDias);
        }
  
        for(var i=0; i<newDias.length; i++){
            for(var j=0; j<2; j++){
                var label = document.getElementsByName(`${j}-${newDias[i].id}`);                
                label[0].value=newDias[i].periodoPago; 
            }  
        }
    }

    const addDias = () =>{
        setErrorPeriodosDias(null);

        let result =0;
        if(diasAjusteRef.current.value === ''){
            diasAjusteRef.current.focus();
            return null;
        }

        if(periodoPagoRef.current.value === ''){
            periodoPagoRef.current.focus();
            return null;
        }
        
        if(diasAjustePeriodoRef.current.value === ''){
            diasAjustePeriodoRef.current.focus(); 
            return null;
        }else{
            for (const key in dias) {
                result+=parseFloat(dias[key].diasPagoPeriodo);
            }
            result += parseFloat(diasAjustePeriodoRef.current.value)
        }

        if( result <= parseFloat(diasAjusteRef.current.value) ){
            if(elementSelect){
                addDiasBack( parseInt(periodoPagoRef.current.value), parseFloat(diasAjustePeriodoRef.current.value), result );
            }else{
                
                const newDiasSelect=periodosAgnio.filter(dia=>dia.id!==parseInt(periodoPagoRef.current.value));
                setDias([...dias, {idLocal: `${dias.length}-id`, periodoPago:parseInt(periodoPagoRef.current.value),
                        diasPagoPeriodo: parseInt(diasAjustePeriodoRef.current.value) }]);
                setPeriodosAgnio(newDiasSelect);  
                periodoPagoRef.current.value = '';
                diasAjustePeriodoRef.current.value = '';
                if(result===parseFloat(diasAjusteRef.current.value)){
                    setCanAdd( true );
                } 
            }
        }else{
            diasAjustePeriodoRef.current.focus(); 
        }    
    }

    const addDiasVacaciones = () =>{
        if(deAgniosRef.current.value === '' || !MONTO.test(deAgniosRef.current.value)){
            deAgniosRef.current.focus();
            return null;
        }

        if(hastaAgniosRef.current.value === '' || !MONTO.test(hastaAgniosRef.current.value) ){
            hastaAgniosRef.current.focus();
            return null;
        }
        
        if(diasPrimerSemRef.current.value === '' || !DIAS_AGNIO.test(diasPrimerSemRef.current.value) ){
            diasPrimerSemRef.current.focus(); 
            return null;
        }

        if(diasSegundoSemRef.current.value === '' || !DIAS_AGNIO.test(diasSegundoSemRef.current.value)){
            diasSegundoSemRef.current.focus(); 
            return null;
        }
 
        if(elementSelect){
            addDiasVacacionesBack( parseFloat(deAgniosRef.current.value), parseFloat(hastaAgniosRef.current.value), 
            parseInt(diasPrimerSemRef.current.value), parseInt(diasSegundoSemRef.current.value));
        }else{

            setErrorPeriodosDias(null);
            setDiasVacaciones([...diasVacaciones, {idLocal: `${diasVacaciones.length}-id`, anioIni:parseFloat(deAgniosRef.current.value),
            anioFin: parseFloat(hastaAgniosRef.current.value), diasSem1: parseInt(diasPrimerSemRef.current.value), diasSem2: parseInt(diasSegundoSemRef.current.value)  }]); 
            deAgniosRef.current.value = '';
            hastaAgniosRef.current.value = '';
            diasPrimerSemRef.current.value = '';
            diasSegundoSemRef.current.value = ''; 

        }  

    }

    const alertDelete=(id)=>{
        setIdToDelete(id);
        setTextAlert(registro_sera_eliminado);
        setDeleteRow(true);
        setOpenAlert(true);
    }
    
    const addDiasBack = (periodoPago, diasPagoPeriodo, result) => {

        const diasAj={ diasAjuste: parseInt( elementSelect.id.replace(regex,'')), periodoPago, diasPagoPeriodo}; 
        axios.post( `${servicios_grupogack_rh}/periodo/ajuste/`, diasAj, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:

                        periodoPagoRef.current.value = '';
                        diasAjustePeriodoRef.current.value = '';
                        const newDiasSelect=periodosAgnio.filter(dia=>dia.id!==parseInt(periodoPago));
                        setDias([...dias, {id:respuesta.data.dataResponse.id, periodoPago:respuesta.data.dataResponse.periodoPago,
                                diasPagoPeriodo: respuesta.data.dataResponse.diasPagoPeriodo }]);
                        setPeriodosAgnio(newDiasSelect);  
                        if(result===parseFloat(diasAjusteRef.current.value)){
                            setCanAdd( true );
                        } 
                        break;
                    default: 
                        break;
                }
            })
            .catch( error => {  })
    }
    const addDiasVacacionesBack = (anioIni, anioFin, diasSem1, diasSem2) => {

        const diasVac={ politcaVacaciones: parseInt( elementSelect.id.replace(regex,'')), anioIni, anioFin, diasSem1, diasSem2}; 
        axios.post( `${servicios_grupogack_rh}/vacaciones/antiguedad/`, diasVac, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        deAgniosRef.current.value = '';
                        hastaAgniosRef.current.value = '';
                        diasPrimerSemRef.current.value = '';
                        diasSegundoSemRef.current.value = '';
                        setDiasVacaciones([...diasVacaciones, {id:respuesta.data.dataResponse.id, anioIni:respuesta.data.dataResponse.anioIni,
                        anioFin: respuesta.data.dataResponse.anioFin, diasSem1: respuesta.data.dataResponse.diasSem1, diasSem2: respuesta.data.dataResponse.diasSem2  }]); 
                        setErrorPeriodosDias(null);    
                        break;
                    default: 
                        break;
                }
            })
            .catch( error => {  })
    }

    const getDiasAjuste = () => {
        let res=[]; 
        dias.map((key, index)=>{
            res.push(
                <div className="row" key={`${index}`}>
                    <div className="col-md-5 col-sm-12" key={`${index}-per`}> 
                        <div className="form-group" key={`${index}-per2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`0-${key.id}`} id={key.id}>
                                {key.periodoPago}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-5 col-sm-12" key={`${index}-dia`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.diasPagoPeriodo}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12" key={`${index}-del`} style={{textAlign:'center'}}> 
                        <div className="form-group" key={`${index}-del2`}>
                            <IconButton aria-label="delete" size="small" onClick={e => alertDelete(key.id ? key.id : key.idLocal)} >
                                <ClearIcon style={{ color: "#4C638E" }}/>
                            </IconButton> 
                        </div>
                    </div>
                </div>
            );
            return null;
        });
        return res;
    }

    const canAddDia = (diaAjus) => { 
        if(!isNaN(diaAjus)){
            let result = 0;
            for (const key in dias) {
                result+=parseFloat(dias[key].diasPagoPeriodo);
            }  
            setCanAdd( result>=parseFloat(diaAjus) ); 
        } 
    }

    const getDiasVacaciones = () => {
        let res=[]; 
        diasVacaciones.map((key, index)=>{
            res.push(
                <div className="row" key={`${index}`}>
                    <div className="col-md-3 col-sm-12" key={`${index}-ini`}> 
                        <div className="form-group" key={`${index}-per2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`0-${key.id}`} id={key.id}>
                                {key.anioIni}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-12" key={`${index}-fin`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.anioFin}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12" key={`${index}-dia1`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.diasSem1}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-12" key={`${index}-dia2`}> 
                        <div className="form-group" key={`${index}-dia2`}>
                            <label className="form-control label-control" style={{marginTop:'0'}} name={`1-${key.id}`} id={key.id}>
                                {key.diasSem2}
                            </label>
                        </div>
                    </div>
                    <div className="col-md-1 col-sm-12" key={`${index}-del`} style={{textAlign:'center'}}> 
                        <div className="form-group" key={`${index}-del2`}>
                            <IconButton aria-label="delete" size="small" onClick={e => alertDelete(key.id ? key.id : key.idLocal)} >
                                <ClearIcon style={{ color: "#4C638E" }}/>
                            </IconButton> 
                        </div>
                    </div>
                </div>
            );
            return null;
        });
        return res;
    }

    return (
        <React.Fragment>
            {
                !elementSelect
                ?
                <div id="form-part-1" style={{ display: (active === -1) ? 'block' : 'none' }}>
                    <form id={'SelectTipoPolitica' } >
                        <SelectValidation title="Tipo" arrayOpciones={tipos} keyOpcion="nombre"
                            refValue={tipoRef} onChangeExecute={showForm}/>
                    </form>
                </div>
                :
                null
            }
        

        {/* FORM PARA AGUINALDO Y PRIMA VACACIONAL */}
        <div id="form-part-2" style={{ display: (active === 0 || active === 1) ? 'block' : 'none' }}>
            <form id={ elementSelect ? active === 0 ? 'EditarAguinaldo': 'EditarPrimaVacacional'  : active === 0 ? 'AgregarAguinaldo' : 'AgregarPrimaVacacional' } 
                  onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={ active === 0 ? feciniRef : feciniPrimaRef}
                            defaultValue={ elementSelect ? elementSelect.fechaIn : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={ active === 0 ? fecfinRef : fecfinPrimaRef }
                            defaultValue={ elementSelect ? elementSelect.fechaFi : '' }
                            optional />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title={ active === 0 ? "Días Aguinaldo por Año": "Días Prima Vacacional por Año" } type="diasAgino" placeholder="Escriba la Cantidad de Días" 
                            refValue={diasAguinaldoRef} defaultValue={ elementSelect ? active === 0 ? elementSelect.diasAguinaldoAnio :elementSelect.diasPrimaAnio : '' } required 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDiasAguiPoliticas'} maxLength={6} 
                            onChangeExecute={(value)=>{ if( isNaN(value) ) {setDiasAguinaldoSemestre(0)}else{ setDiasAguinaldoSemestre((parseFloat(value)/2).toFixed(2))}}}
                            onlyNumbers ='.' onBlurDecimal={2}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                            <label>{ active === 0 ? "Días Aguinaldo por Semestre": "Días Prima Vacacional por Semestre"}</label> 
                            <label className="form-control label-control" style={{marginTop:'0'}}>{ diasAguinaldoSemestre }</label>
                        </div>
                    </div>
                </div>
                {
                    active ===0 ?
                     <div className="row"> 
                        <div className="col-md-6 col-sm-12">
                            <InputValidation title="Días Bono por Año" type="diasAgino" placeholder="Escriba la Cantidad de Días de Bono por Año" 
                                refValue={diasBonoRef} defaultValue={ elementSelect ? elementSelect.diasBonoAnio : '' } required 
                                tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDiasBonoPoliticas'} maxLength={6} 
                                onChangeExecute={(value)=>{ if( isNaN(value) ) {setDiasBonoSemestre(0)}else{ setDiasBonoSemestre((parseFloat(value)/2).toFixed(2))}}}
                                onlyNumbers='.' onBlurDecimal={2}
                                />
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="form-group">
                                <label>Días Bono por Semestre</label> 
                                <label className="form-control label-control" style={{marginTop:'0'}}>{diasBonoSemestre}</label>
                            </div>
                        </div>
                    </div>
                    :null
                } 
                <div className="row"> 
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Pago 1er Semestre" arrayOpciones={periodosAgnio} keyOpcion="id"
                            refValue={pagoPrimerSemRef} defaultValue={ elementSelect ? elementSelect.periodoPagoSem1 : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Periodo Pago 2do Semestre" arrayOpciones={periodosAgnio} keyOpcion="id"
                            refValue={pagoSegundoSemRef} defaultValue={ elementSelect ? elementSelect.periodoPagoSem2 : '' } required/>
                    </div>
                </div>
                {errorDate}
                {errorPeriodos}
                <div className="row">
                    {
                        !elementSelect
                        ?
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={e => atras(e, -1)}>{ 'Atrás' } </Button>
                        </div>
                        :
                        null
                    } 
                    
                    <div className={ elementSelect ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'}  >
                        <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                    </div>
                </div>
            </form>
        </div>


        {/* FORM PARA DÍAS DE AJUSTE*/}
        <div id="form-part-3" style={{ display: (active === 2 ) ? 'block' : 'none' }}>
            <form id={ elementSelect ? 'EditarDiaAjuste' : 'AgregarDiaAjuste' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={feciniDiaAjusteRef}
                            defaultValue={ elementSelect ? elementSelect.fechaIn : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fecfinDiaAjusteRef}
                            defaultValue={ elementSelect ? elementSelect.fechaFi : '' }
                            optional />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-md-12 col-sm-12">
                        <InputValidation title={ "Días de Ajuste" } type="diasAgino" placeholder="Escriba la Cantidad de Días de Ajuste por Año" 
                            refValue={diasAjusteRef} defaultValue={ elementSelect ? elementSelect.diasAjuste : '' } required 
                            onlyNumbers 
                            tooltipText={'Máximo 3 números'} tooltipName={'tooltipPeriodoDiasAjusPoliticas'} maxLength={3} onChangeExecute={canAddDia}/>
                    </div> 
                </div>
                <div className="row"> 
                    <div className="col-md-5 col-sm-12">
                        <SelectValidation title="Periodo Pago" arrayOpciones={periodosAgnio} keyOpcion="id"
                            refValue={periodoPagoRef} defaultValue={ elementSelect ? elementSelect.tipoDependId : '' } optional/>
                    </div>
                    <div className="col-md-5 col-sm-12">                        
                        <InputValidation title={ "Días a Pagar en el Periodo" } type="diasAgino" placeholder="Escriba la Cantidad de Días de Ajuste en el Periodo" 
                            refValue={diasAjustePeriodoRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDiasPagarDiasAjusPoliticas'} maxLength={6} optional onlyNumbers='.'/>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <div className="form-group" style={{paddingTop:'1.7rem', textAlign:'right'}}>
                            <Button variant="contained" className="btn-third" onClick={e =>addDias() } disabled={canAdd}>
                                <AddIcon/>
                            </Button>
                        </div>
                    </div>
                </div> 
                {getDiasAjuste()} 
                {errorDate}
                {errorDias}
                {errorPeriodosDias}
                <div className="row"> 
                    {
                        !elementSelect
                        ?
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={e => atras(e, -1)}>{ 'Atrás' } </Button>
                        </div>
                        :
                        null
                    }
                    <div className={ elementSelect ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'} >
                        <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                    </div>
                </div>
            </form>
        </div>


        {/* FORM PARA VACACIONES */}
        <div id="form-part-4" style={{ display: (active === 3 ) ? 'block' : 'none' }}>
            <form id={ elementSelect ? 'EditarVacaciones' : 'AgregarVacaciones' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={feciniVacacionesRef}
                            defaultValue={ elementSelect ? elementSelect.fechaIn : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fecfinVacacionesRef}
                            defaultValue={ elementSelect ? elementSelect.fechaFi : '' }
                            optional />
                    </div>
                </div>
                <div className="row"> 
                    <div className="col-md-3 col-sm-12">
                        <InputValidation title={ "De (Años)" } type="diasAgino" placeholder="Escriba la Cantidad de Años" 
                            refValue={deAgniosRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipDeAgniosVacPoliticas'} maxLength={6} onlyNumbers='.'/>
                    </div>
                    <div className="col-md-2 col-sm-12">
                        <InputValidation title={ "Hasta (Años)" } type="diasAgino" placeholder="Escriba la Cantidad de Años" 
                            refValue={hastaAgniosRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números y 2 decimales'} tooltipName={'tooltipHastaVacPoliticas'} maxLength={6} onlyNumbers='.'/>
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <InputValidation title={ "Días 1er Semestre" } type="diasAgino" placeholder="Escriba la Cantidad de Días" 
                            refValue={diasPrimerSemRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números'} tooltipName={'tooltipDiasPrimSemVacPoliticas'} maxLength={3} />
                    </div>
                    <div className="col-md-3 col-sm-12">
                        <InputValidation title={ "Días 2do Semestre" } type="diasAgino" placeholder="Escriba la Cantidad de Días" 
                            refValue={diasSegundoSemRef} defaultValue={ elementSelect ? elementSelect.giroNom : '' } optional 
                            tooltipText={'Máximo 3 números'} tooltipName={'tooltipDiasSegSemVacPoliticas'} maxLength={3} />
                    </div>
                    <div className="col-md-1 col-sm-12">
                        <div className="form-group" style={{paddingTop:'1.7rem' }}>
                            <Button variant="contained" className="btn-third" onClick={e =>addDiasVacaciones() } >
                                <AddIcon/>
                            </Button>
                        </div>
                    </div>
                </div>
                {getDiasVacaciones()} 
                {errorDate}
                {errorPeriodosDias}
                <div className="row"> 
                    {
                        !elementSelect
                        ?
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-outline" onClick={e => atras(e, -1)}>{ 'Atrás' } </Button>
                        </div>
                        :
                        null
                    }
                    <div className={ elementSelect ? 'col-md-12 col-sm-12' : 'col-md-6 col-sm-12'} >
                        <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                    </div>
                </div>
            </form>
        </div>
        <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={deleteRow ? active === 2 ? deleteDias : deleteDiasVac: actualizar}/>
        <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
        <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </React.Fragment>
    );
};

export default FormularioPoliticas;
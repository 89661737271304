import { longDateToInput, floatToAmount } from '../../../../Services/Validation/HelperValidation';
import ConvertDate from './ConvertDate';

export const filtraJson = (id, dataContent) => {
    switch(id) {
        case 3:
            return dataContent.map(item => ({ 'Clave': item.clave, 'Nombre': item.descripcion }));
        case 4:
            return dataContent.map(item => ({ 'Registro Patronal': item.registroPatClave, 'Seguridad Social': item.segSocNom }));
        case 5:
            return dataContent.map(item => ({ 'Giro': item.giroNom }));
        case 6:
            return dataContent.map(item => ({ 'Tipo de Secretaría o Dependencia': item.nombre }));
        case 7:
            return dataContent.map(item => ({ 'Clave': item.clave, 'Nombre': item.nombre }));
        case 8:
            return dataContent.map(item => ({ 'Clave': item.parentescoCve, 'Nombre': item.parentescoNom }));
        case 11:
            return dataContent.map(item => ({ 'Colonia': item.codigopColonia }));
        case 12:
        case 51:
            return dataContent.map(item => ({ 'Clave': item.bancoCve, 'Nombre': item.bancoNom, 'Descripción': item.bancoDescrip }));
        case 13:
            return dataContent.map(item => ({ 'Clave': item.ausentismoCve, 'Nombre': item.ausentismoNom }));
        case 14:
            return dataContent.map(item => ({ 'Clave': item.clave, 'Nombre': item.nombre }));
        case 15:
            return dataContent.map(item => ({ 'Nombre': item.tipodomNom }));
        case 16:
            return dataContent.map(item => ({ 'Clave': item.indicadorClave, 'Nombre': item.indicadorNom, 'Relación Laboral': item.relacionLabNombre, 'Naturaleza': item.tipoNatuNom, 'Indicador SAT': `'${item.indicadorSat}`, 'Clasificación SAT': item.clasificaNom, 'Partida': item.partida, 'Cuenta': item.cuenta }));
        case 17: 
            return dataContent.map(item => ({ 'Nombre': item.escolaridadNom }));
        case 40: //Cálculo
            return dataContent.map(item => ({ 'Clave': item.nominaClave, 'Nombre Corto': item.nominaNomCorto, 'Nombre Nómina': item.nominaNombre, 'nominaNombre': item.nominaDescrip }));
        case 44:
            return dataContent.map(item => ({ 'Nombre': item.edoCivilnom }));
        case 45:
            return dataContent.map(item => ({ 'Clave': item.segSocClave, 'Nombre': item.segSocNom }));
        case 46:
            return dataContent.map(item => ({ 'Nombre': item.tipodocNom, 'Extensión': item.tipoextNom, 'Cantidad': item.tipodocCant, 'Descripción': item.tipodocDesc }));
        case 54:
            return dataContent.map(item => ({ 'Clave': item.regFiscalClave, 'Descripción': item.regFiscalDesc }));
        case 55:
            return dataContent.map(item => ({ 'Clave': item.origenRecClave, 'Descripción': item.origenRecDesc }));
        case 56:
            return dataContent.map(item => ({ 'Clave': item.tipoContratoClave, 'Descripción': item.tipoContratoDesc }));
        case 57:
            return dataContent.map(item => ({ 'Clave': item.regimenClave, 'Descripción': item.regimenDesc }));
        case 58:
            return dataContent.map(item => ({ 'Clave': item.riesgoPtoClave, 'Descripción': item.riesgoPtoDesc }));
        case 19:
            return dataContent.map(item => ({ 'Nombre': item.datosUsuarioNom, 'Primer Apellido': item.datosUsuarioPat, 'Segundo Apellido': item.datosUsuarioMat, 'Usuario': item.username }));
        case 20:
            return dataContent.map(item => ({ 'RFC': (item.ciaRfc !== null) ? item.ciaRfc : '', 'Razón Social': (item.ciaRazonSocial !== null) ? item.ciaRazonSocial : '', 'Unidad Responsable': (item.ciaUr !== null) ? item.ciaUr : '', 'Nombre Corto': (item.ciaNomCorto !== null) ? item.ciaNomCorto : '', 'Tipo de Dependencia': (item.tipoDependNombre !== null) ? item.tipoDependNombre : '', 'Giro Comercial': (item.giroNom !== null) ? item.giroNom : '', 'Fecha de Constitución': (item.ciaFecConst !== null) ? item.ciaFecConst : '' , 'Sitio Web': (item.ciaWeb !== null) ? item.ciaWeb : '' }));
        case 21:
            return dataContent.map(item => ({ 'Unidad Responsable': item.ciaRazonSocial, 'Unidad Ejecutora': item.udejecutoranom, 'Programa': item.programanom, 'Proyecto Acción': item.proyectonom, 'Clave': `'${item.cpresupuestalclave}`, 'Centro de Trabajo / Proyecto / Clave Presupuestal': item.cpresupuestalnom }));
        case 23:
            return dataContent.map(item => ({ 'Clave de Nivel Laboral': item.nivlaboralClave }));
        case 24:
            return dataContent.map(item => ({ 'Clave de Nivel Laboral': item.nivlaboralClave, 'Clave de Puesto': item.puestoCve, "Nombre del Puesto": item.puestoNom, "Descripción Puesto": item.puestoDescrip, "Relación Laboral": item.relabIdNombre, "Descripción Relación Laboral":item.relabIdDescripcion }));
        case 25:
            return dataContent.map(item => ({ 'Fecha de Inicio': item.fechaini, 'Fecha Final': item.fechafin }));
        case 27:
            return dataContent.map(item => ({ 'Fecha de Inicio': item.fecIni, 'Fecha Final': item.fecFin }));
        case 28:
            return dataContent.map(item => ({ 'Clave': item.nominaClave, 'Nombre Corto': item.nominaNomCorto, 'Nombre Nómina': item.nominaNombre, 'Descripción': item.nominaDescrip }));
        case 30: //Proyectos
        case 31: //Empleados de Ausentismos
        case 41:
        case 68:
        case 69:
        case 70: 
            return dataContent.map(item => ({ 'Núm. Empleado': (item.empleadoClave !== null) ? item.empleadoClave : '', 'Nombre': (item.empleadoNom !== null) ? item.empleadoNom : '', 'Primer Apellido': (item.empleadoPat !== null) ? item.empleadoPat : '', 'Segundo Apellido': (item.empleadoMat !== null) ? item.empleadoMat : '', 'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '', 'Puesto': (item.puestoNom !== null) ? item.puestoNom : '', 'Nómina': (item.nominaNombre !== null) ? item.nominaNombre : '' , 'Clave Presupuestal / Proyecto': item.cpresupuestalNom !== null ? item.cpresupuestalNom : '' }));
        case 31+'secondary': 
            return dataContent.map(item => ({ 'Fecha Inicial': (item.auseFechaIni !== null) ? ConvertDate(item.auseFechaIni) : '', 'Fecha Final': (item.auseFechaFin !== null) ? ConvertDate(item.auseFechaFin) : '', 'Tipo de Ausentismo': (item.ausentismo !== null) ? item.ausentismoNom : '', 'Fecha de Aplicación': (item.auseFechaAplica !== null) ? ConvertDate(item.auseFechaAplica) : '', 'Comentarios': (item.comentarios !== null) ? item.comentarios : '' }));
        case 59:  
            return dataContent.map(item => ({ 'Nombre del Turno': item.turnoNom, 'Jornada Laboral': item.jornadaNombre }));
        case 60:  
            return dataContent.map(item => ({ 'Clave': item.acumuladoClave, 'Nombre': item.acumuladoNombre, 'Descripción': item.acumuladoDescripcion }));
        case 61:  
            return dataContent.map(item => ({ 'Nombre de Contrato': item.contratoSaoNom, 'Tipo de Contrato': item.tipoContrato, 'Relación Laboral': item.relacionLaboral, 'Fecha': item.contratoSaoFechas }));
        case 37:  
        case 38:
            return dataContent.map(item => ({ 'Clave Nómina': item.nominaClave, 'Nombre Corto': item.nominaNomCorto, 'Nombre Nómina': item.nominaNombre }));
        case 38+'secondary':
            return dataContent.map(item => ({ 'Clave': item.nomClaveIndicador, 'Prioridad': item.prioridad, 'Nombre': item.nomIndicador, 'Naturaleza': item.nomNaturaleza, 'Indicador SAT': item.indicadorSat, 'Partida': item.partida, 'Cuenta': item.cuenta, 'Recibo': item.nomRecibo }));
        case 39:
            return dataContent.map(item => ({ 'Tipo': item.tipo, 'Fecha Inicial': item.fechaIni, 'Fecha Final': item.fechaFin }));
        case 64:
            return dataContent.map(item => ({ 'Fecha Inicial': item.fechaini, 'Fecha Final': item.fechafin, '% Enfermedades Maternidad Patrón': item.enfmatpat, '% Enfermedades Maternidad Obrera': item.enfmatobr, 'Tope Cuotas Obrero-Patronal': item.pat, 'Factor Aplicable al Sueldo': item.sueldo }));
        case 65:
            return dataContent.map(item => ({ 'Zona': item.zonaNom, 'Monto': item.monto, 'Fecha Inicial': item.fechaIni, 'Fecha Final': item.fechaFin}));
        case 66:
            return dataContent.map(item => ({ 'Estado': item.estadoNom, '% ISN': item.porcentaje, 'Fecha Inicial': item.fechaini, 'Fecha Final': item.fechafin !== null ? item.fechafin : '' }));
        case 67:
        case 72:
            return dataContent.map(item => ({ 'Tipo': item.periodoImpto, 'Fecha Inicial': item.fechaIni, 'Fecha Final': item.fechaFin }));
        case 71:
            return dataContent.map(item => ({ 'Fecha Inicial': item.fechaini, 'Fecha Final': item.fechafin, '% Fondo de Pensión Obrero': item.pensionFpo, '% Fondo de Pensión Patronal': item.pensionFpp }));
        case 73: //Bases
            return dataContent.map(item => ({ 'Clave': item.baseClave, 'Nombre': item.baseNom, 'Descripción': item.baseDescrip }));
        case 74: //Pasatiempos
            return dataContent.map(item => ({ 'Nombre': item.pasaNom, 'Descripción': item.panDesc }));
        case 78: //Plantillas
            return dataContent.map(item => ({ 'Clave': item.plantillaClave, 'Nombre': item.plantillaNom, 'Descripción': item.plantillaDesc }));
        case 80: //Auditorias
            return dataContent.map(item => ({ 'Usuario': item.usuario, 'Fecha': item.date, 'Módulo': item.moduleName, 'Operación': item.crudOperation })); 
        default:
            return false;
    }
};

export const ArrayJsonIndicadoresGenerales = data => { 
    
    const newJson = data.map(item => (
        {
            id: item.id,
            indicadorClave: item.indicadorClave,
            indicadorNom: item.indicadorNom,
            relacionLabId: item.relacionLab.id,
            relacionLabNombre: item.relacionLab.nombre,
            naturalezaId: item.naturaleza.id,
            tipoNatuNom: item.naturaleza.tipoNatuNom,
            indicadorSat: item.indicadorSat,
            clasificaSatId: item.clasificaSat.id,
            clasificaNom: item.clasificaSat.clasificaNom,
            partida: `${item.cuentas.partidagen ? item.cuentas.partidagen : '   '} - ${item.cuentas.partidaesp ? item.cuentas.partidaesp : '   '}`,
            cuenta: `${item.cuentas.ctacontable ? item.cuentas.ctacontable : '   '} - ${item.cuentas.subcta ? item.cuentas.subcta : '      '}`,
            indicadorNomClve: `${ item.indicadorClave} - ${item.indicadorNom}`,
            prioridad: item.prioridad ? item.prioridad : null,
            rutinaId: item.rutina ? item.rutina.id : null,
            recibo: item.recibo ? item.recibo : null
        }
    )); 

    return newJson;
};

export const JsonIndicadoresGenerales = data => {
    const newJson = {
                        id: data.id,
                        indicadorClave: data.indicadorClave,
                        indicadorNom: data.indicadorNom,
                        relacionLabId: data.relacionLab.id,
                        relacionLabNombre: data.relacionLab.nombre,
                        naturalezaId: data.naturaleza.id,
                        tipoNatuNom: data.naturaleza.tipoNatuNom,
                        indicadorSat: data.indicadorSat,
                        clasificaSatId: data.clasificaSat.id,
                        clasificaNom: data.clasificaSat.clasificaNom,
                        partida: `${data.cuentas.partidagen ? data.cuentas.partidagen : ''} - ${data.cuentas.partidaesp ? data.cuentas.partidaesp : ''}`,
                        cuenta: `${data.cuentas.ctacontable ? data.cuentas.ctacontable : ''} - ${data.cuentas.subcta ? data.cuentas.subcta : ''}`,
                        indicadorNomClve: `${ data.indicadorClave} - ${data.indicadorNom}`,
                        prioridad: data.prioridad ? data.prioridad : null,
                        rutinaId: data.rutina ? data.rutina.id: null,
                        recibo: data.recibo ? data.recibo :null
                    };

    return newJson;
};

export const ArrayJsonIndicadoresGeneralesByRelLab = data => {
    const newJson = data.map( item => JsonIndicadoresGeneralesByRelLab( item ) );
    return newJson;
};

export const JsonIndicadoresGeneralesByRelLab = data => {
    
    return {
        id: data.indicadorId,
        indicadorClave: data.indicadorClave,
        indicadorNom: data.indicadorNom,
        relacionLabId: data.relacionLab.id,
        relacionLabNombre: data.relacionLab.nombre,
        indicadorNomClve: `${ data.indicadorClave} - ${data.indicadorNom}`,
        naturalezaId: data.naturaleza.id,
        tipoNatuNom: data.naturaleza.tipoNatuNom,
        indicadorSat: data.indicadorSat,
        clasificaSatId: data.clasificaSat.id,
        clasificaNom: data.clasificaSat.clasificaNom,
        rutinaId: data.rutina,
        prioridad: data.prioridad, 
        recibo: data.recibo
    };
};



export const ArrayJsonProyectos = data => {
    const newJson = data.map( item => (
        {
            id: item.id,
            cpresupuestalclave: item.cpresupuestalclave,
            cpresupuestalnom: item.cpresupuestalnom,
            proyectoid: item.proyectoid.id,
            proyectonom: item.proyectoid.proyectonom,
            programaid: item.proyectoid.programaid.id,
            programanom: item.proyectoid.programaid.programanom,
            udejecutoraid: item.proyectoid.programaid.udejecutoraid.id,
            udejecutoraclave: item.proyectoid.programaid.udejecutoraid.udejecutoraclave,
            udejecutoranom: item.proyectoid.programaid.udejecutoraid.udejecutoranom,
            ciaid: item.proyectoid.programaid.udejecutoraid.ciaid.id,
            ciaUr: item.proyectoid.programaid.udejecutoraid.ciaid.ciaUr,
            ciaNomCorto: item.proyectoid.programaid.udejecutoraid.ciaid.ciaNomCorto,
            ciaRazonSocial: item.proyectoid.programaid.udejecutoraid.ciaid.ciaRazonSocial
        }
    )); 

    return newJson;
};

export const JsonProyectos = data => {
    const newJson = {
                        id: data.id,
                        cpresupuestalclave: data.cpresupuestalclave,
                        cpresupuestalnom: data.cpresupuestalnom,
                        proyectoid: data.proyectoid.id,
                        proyectonom: data.proyectoid.proyectonom,
                        programaid: data.proyectoid.programaid.id,
                        programanom: data.proyectoid.programaid.programanom,
                        udejecutoraid: data.proyectoid.programaid.udejecutoraid.id,
                        udejecutoraclave: data.proyectoid.programaid.udejecutoraid.udejecutoraclave,
                        udejecutoranom: data.proyectoid.programaid.udejecutoraid.udejecutoranom,
                        ciaid: data.proyectoid.programaid.udejecutoraid.ciaid.id,
                        ciaUr: data.proyectoid.programaid.udejecutoraid.ciaid.ciaUr,
                        ciaNomCorto: data.proyectoid.programaid.udejecutoraid.ciaid.ciaNomCorto,
                        ciaRazonSocial: data.proyectoid.programaid.udejecutoraid.ciaid.ciaRazonSocial
                    };

    return newJson;
};

/* Registros Patronales */
export const ArrayJsonRegistroPatronal = data => {
    const newJson = data.map( item => JsonRegistroPatronal( item ) );
    return newJson;
};

export const JsonRegistroPatronal = data => {
    return {
        id: data.id,
        registroPatClave: data.registroPatClave,
        segSocId: data.segSoc.id,
        segSocNom: data.segSoc.segSocNom,
        tipoRiesgoId: data.tipoRiesgo.id,
        tipoRiesgoNom: data.tipoRiesgo.riesgoPtoDesc
    };
};

/* Tipo de Documentos */
export const ArrayJsonTipoDocumentos = data => {
    const newJson = data.map( item => JsonTipoDocumentos( item ) );
    return newJson;
};

export const JsonTipoDocumentos = data => {
    return {
        id: data.id,
        tipodocNom: data.tipodocNom,
        tipodocDesc: data.tipodocDesc,
        tipodocCant: data.tipodocCant,
        tipoextId: data.extencion.id,
        tipoextNom: data.extencion.tipoextNom
    };
};

/* Dependencias */
export const ArrayJsonDependencias = data => {
    const newJson = data.map( item => JsonDependencias( item ) );
    return newJson;
};

export const JsonDependencias = data => {
    return {
        id: data.id,
        ciaRfc: data.ciaRfc,
        ciaUr: data.ciaUr,
        ciaRazonSocial: data.ciaRazonSocial,
        ciaNomCorto: data.ciaNomCorto,

        tipoDependId: data.tipoDepend.id,
        tipoDependNombre: data.tipoDepend.nombre,

        giroId: data.giroId.id,
        giroNom: data.giroId.giroNom,

        ciaFecConst: longDateToInput( data.ciaFecConst ),
        ciaWeb: data.ciaWeb,
    };
};

/* Usuarios */
export const ArrayJsonUsuarios = data => {
    const newJson = data.map( item => JsonUsuarios( item ) );
    return newJson;
};

export const JsonUsuarios = data => {
    return {
        id: data.id,
        datosUsuarioNom: data.datosUsuarioNom,
        datosUsuarioPat: data.datosUsuarioPat,
        datosUsuarioMat: data.datosUsuarioMat,
        foto: data.foto,
        status: data.status,
        username: data.username,
        verificado: data.verificado,
        nombreCompleto: `${data.datosUsuarioNom} ${data.datosUsuarioPat} ${data.datosUsuarioMat}`,
        usuarioCompleto: `${data.username} / ${data.datosUsuarioNom} ${data.datosUsuarioPat} ${data.datosUsuarioMat}`,
    };
};

export const ArrayJsonPuestos = data => {
    const newJson = data.map( item => JsonPuestos( item ) );
    return newJson;
};

export const JsonPuestos = data => {     
    return {
        id:data.id,
        nivlaboralId: data.nvlId.id,
        nivlaboralClave: data.nvlId.nivlaboralClave,
        puestoNom: data.puestoNom,
        puestoCve: data.puestoCve,
        puestoDescrip: data.puestoDescrip,
        relabId: data.relabId.id,
        relabIdNombre: data.relabId.nombre,
        relabIdDescripcion: data.relabId.descripcion,
        status: data.statusId.statusNom
    };
};

export const ArrayJsonTurnos = data => {
    const newJson = data.map( item => JsonTurnos( item ) );
    return newJson;
};

export const JsonTurnos = data => {
    return {
        id:data.id,
        turnoNom:data.turnoNom,
        jornadaNombre: data.jornada.nombre,
        jornadaId: data.jornada.id,
    };
};

export const JsonTurnosAdd = data => {
    return {
        id:data.turno.id,
        turnoNom:data.turno.turnoNom,
        jornadaNombre: data.turno.jornada.nombre,
        jornadaId: data.turno.jornada.id,
    };
};

export const ArrayJsonContratoRelLab = data => {
    const newJson = data.map( item => JsonContratoRelLab( item ) );
    return newJson;
};

export const JsonContratoRelLab = data => {
    return {
        id:data.id,
        contratoSaoNom:data.contratoSaoNom,
        tipoContrato: data.tipoContrato.tipoContratoDesc,
        tipoContratoId: data.tipoContrato.id,
        relacionLaboral: data.relacionLaboral.nombre,
        relacionLaboralId: data.relacionLaboral.id,
        contratoSaoFechas: data.contratoSaoFechas ? 'Sí' : 'No',
    };
};

export const ArrayJsonSalarioMinUMA = data => {
    const newJson = data.map( item => JsonSalarioMinUMA( item ) );
    return newJson;
};

export const JsonSalarioMinUMA = data => {    
    return {
        id:data.id,
        fechaIni: ConvertDate(data.fechaIni),
        fechaFin: data.fechaFin!== null ? ConvertDate(data.fechaFin) : '-',
        monto: floatToAmount(data.monto, 2),
        zonaNom: data.zona.zonaNom,
        zonaId: data.zona.id,
        fechaIniInp: longDateToInput(data.fechaIni),
        fechaFinInp: longDateToInput(data.fechaFin)
    };
};

export const ArrayJsonSalarioMinUMAIndicadores = data => {
    const newJson = data.map( item => JsonSalarioMinUMAIndicadores( item ) );
    return newJson;
};

export const JsonSalarioMinUMAIndicadores = data => {    
    return {
        id: data.zona.id,
        fechaIni: ConvertDate(data.fechaIni),
        fechaFin: data.fechaFin!== null ? ConvertDate(data.fechaFin) : '-',
        monto: data.monto,
        zonaNom: data.zona.zonaNom, 
        fechaIniInp: longDateToInput(data.fechaIni),
        fechaFinInp: longDateToInput(data.fechaFin)
    };
};

/* ImpuestoSobreNomina */
export const ArrayJsonImpuestoNomina = data => {
    const newJson = data.map( item => JsonImpuestoNomina( item ) );
    return newJson;
};

export const JsonImpuestoNomina = data => {
    return {
        id:data.id,
        estadoId: data.estadoId.id,
        estadoNom: data.estadoId.estadoNom,
        porcentaje: data.porcentaje.toFixed(2),
        fechaini: longDateToInput( data.fechaini ),
        fechafin: longDateToInput( data.fechafin ),
        fechainiTab: data.fechaini ? ConvertDate( longDateToInput( data.fechaini ) ) : '-',
        fechafinTab: data.fechafin ? ConvertDate( longDateToInput( data.fechafin ) ) : '-',
    };
};

/* Sindicatos */
export const ArrayJsonSindicatos = data => {
    const newJson = data.map( item => JsonSindicatos( item ) );
    return newJson;
};

export const JsonSindicatos = data => {
    return {
        id:data.id,
        sindicatoAbrevia: data.sindicatoAbrevia,
        sindicatoNombre: data.sindicatoNombre,
        sindicatoDescrip: data.sindicatoDescrip,
        sindicatoPoc: data.sindicatoPoc,
        porcentajeCuota: data.sindicatoPoc === 'P' ? parseFloat(data.sindicatoMonto).toFixed(2) : '-',
        importeCuota: data.sindicatoPoc === 'C' ? parseFloat(data.sindicatoMonto).toFixed(2) : '-',
    };
};

/* Departamentos */
export const ArrayJsonDepartamentos = data => {
    const newJson = data.map( item => JsonDepartamentos( item ) );
    return newJson;
};

export const JsonDepartamentos = data => {
    return {
        id: data.id,
        deptoClave: data.deptoClave,
        deptoNom: data.deptoNom,
        ciaId: data.ciaId.id,
        ciaRazonSocial: data.ciaId.ciaRazonSocial,
        deptoDescrip: data.deptoDescrip,
    };
};

/* IMSS */
export const ArrayJsonIMSS = data => {
    const newJson = data.map( item => JsonIMSS( item ) );
    return newJson;
};

export const JsonIMSS = data => {
    return {
        id: data.id,
        fechaini: longDateToInput( data.fechaini ),
        fechafin: longDateToInput( data.fechafin ),
        enfmatpat: parseFloat(data.enfmatpat).toFixed(3),
        enfmatobr: parseFloat(data.enfmatobr).toFixed(3),
        pat: floatToAmount(data.pat, 2),
        sueldo: parseFloat(data.sueldo).toFixed(6),
        fechainiTab: data.fechaini ? ConvertDate( longDateToInput( data.fechaini ) ) : '-',
        fechafinTab: data.fechafin ? ConvertDate ( longDateToInput( data.fechafin ) ): '-',
    };
};

/* Pensiones */
export const ArrayJsonPensiones = data => {
    const newJson = data.map( item => JsonPensiones( item ) );
    return newJson;
};

export const JsonPensiones = data => {
    return {
        id: data.id,
        fechaini: longDateToInput( data.fechaini ),
        fechafin: longDateToInput( data.fechafin ),
        pensionFpo: parseFloat(data.pensionFpo).toFixed(2),
        pensionFpp: parseFloat(data.pensionFpp).toFixed(2),
        fechainiTab: data.fechaini ? ConvertDate ( longDateToInput( data.fechaini ) ) : '-',
        fechafinTab: data.fechafin ? ConvertDate ( longDateToInput( data.fechafin ) ) : '-',
    };
};

/* Préstamos */
export const ArrayJsonPrestamos = data => {
    const newJson = data.map( item => JsonPrestamos( item ) );
    return newJson;
};

export const JsonPrestamos = data => {
    return {
        id: data.id,
        folio: data.folio,
        indicadorId: data.indicador.id,
        indicadorNombre: `${data.indicador.nomClave} - ${data.indicador.nomIndicador}`,
        numeroPagos: data.numeroPagos,
        importe: floatToAmount(data.importe, 2),
        fechaIni: longDateToInput( data.fechaIni ),
        fechaFin: longDateToInput( data.fechaFin ),
        fechaIniTab: data.fechaIni ? ConvertDate( longDateToInput( data.fechaIni ) ) : '-',
        fechaFinTab: data.fechaFin ? ConvertDate( longDateToInput( data.fechaFin ) ) : '-',
    };
};

export const ArrayJsonTablasImpuestos = data => {
     
    data.sort((a, b)=> a.id < b.id ? 1 : a.id > b.id ? -1 :0 );
    var periodosIndex=[];
    var periodosId=[];
    data.map((key, index)=>{
        if(!periodosId.includes(key.periodoImpto.id)){
            periodosId.push(key.periodoImpto.id);
            periodosIndex.push(index);
        }
        return null;
    })
    for (const key in periodosIndex) {
        data[periodosIndex[key]].status=true;
    }  
    const newJson = data.map( (item, index) => JsonTablasImpuestos( item ) );    
    return newJson;
};

export const JsonTablasImpuestos = data => {
    
    return {
        id:data.id,
        periodoImptoId: data.periodoImpto.id,
        periodoImpto: data.periodoImpto.periodoNombre,
        fechaIni: data.periodoImpto.periodoNombre==='Anual' ? longDateToInput(data.fechaIni).split('-')[0] : longDateToInput(data.fechaIni).split('-').reverse().join('/'),
        fechaFin: data.periodoImpto.periodoNombre==='Anual' && data.fechaFin!== null ? longDateToInput(data.fechaIni).split('-')[0] : data.fechaFin!== null ? longDateToInput(data.fechaFin).split('-').reverse().join('/'):'-',
        fechaIniLong: data.fechaIni,
        fechaFinLong: data.fechaFin,
        status: data.status ? data.status: false
    };
};

export const ArrayJsonTablasImpuestosAdd = data => {

    data.sort((a, b)=> a.id < b.id ? 1 : a.id > b.id ? -1 :0 );
    var periodosIndex=[];
    var periodosId=[];
    data.map((key, index)=>{
        if(key.status && key.periodoImptoId === data[0].periodoImptoId){
            data[index].status=false;
        }
        if(!periodosId.includes(key.periodoImptoId)){
            periodosId.push(key.periodoImptoId);
            periodosIndex.push(index);
        }
        return null;
    })
    for (const key in periodosIndex) {
        data[periodosIndex[key]].status=true;
    }  
    return data;
};


export const ArrayJsonIndicadores = data => {
    const newJson = data.map( item => JsonIndicadores( item ) ); 
    return newJson.sort((a, b)=> a.prioridad > b.prioridad ? 1 : a.prioridad < b.prioridad ? -1 :0 );
};

export const JsonIndicadores = data => {  
    return {
        id:data.id,
        nomClaveIndicador: data.nomClave,
        nomIndicador: data.nomIndicador,
        idNaturaleza: data.indicador.naturaleza.id,
        nomNaturaleza: data.indicador.naturaleza.tipoNatuNom,
        indicadorSat: data.indicador.indicadorSat,
        clasificaSat: data.indicador.clasificaSat.clasificaNom,
        nomRecibo: data.nomRecibo ? 'Sí' : 'No',
        relacionLaboral: data.indicador.relacionLab.nombre,
        prioridad: data.prioridad,
        rutinaId: data.rutina.id,
        partida: data.cuentas ? `${data.cuentas.partidagen ? data.cuentas.partidagen : ''} - ${data.cuentas.partidaesp ? data.cuentas.partidaesp : ''}` : '-',
        cuenta: data.cuentas ? `${data.cuentas.ctacontable ? data.cuentas.ctacontable : ''} - ${data.cuentas.subcta ? data.cuentas.subcta : ''}` : '-'
    };
};

export const ArrayJsonIndGralsConfigBases = data => {
    const newJson = data.map( (item, index) =>  JsonIndGralsConfigBases( item, index ) );
    return newJson;
};

export const JsonIndGralsConfigBases = (data, index) => {  
    return {
        id:data.id,
        baseClave: data.baseClave,
        baseNom: data.baseNom,
        baseDescrip: data.baseDescrip,
        operacion:`${index}/ `
    };
};

export const ArrayJsonIndGralsConfigBasesEdit = data => { 
    const newJson = data.map( (item, index) =>  JsonIndGralsConfigBasesEdit( item, index ) );
    return newJson;
};

export const JsonIndGralsConfigBasesEdit = (data, index) => {
    return {
        id:data.id,
        indicadorId: data.indicador.indicadorId,
        baseId: data.base.id,
        baseClave: data.base.baseClave,
        baseNom: data.base.baseNom,
        baseDescrip: data.base.baseDescrip,
        operacion: `${index}/${data.operacion}`
    };
};

export const ArrayJsonIndNominaConfigBasesEdit = data => { 
    const newJson = data.map( (item, index) =>  JsonIndNominaConfigBasesEdit( item, index ) );
    return newJson;
};

export const JsonIndNominaConfigBasesEdit = (data, index) => {
    return {
        id         : data.id,
        nominId    : data.nomind.id,
        indicadorId: data.nomind.indicador.indicadorId,
        baseId     : data.base.id,
        baseClave  : data.base.baseClave,
        baseNom    : data.base.baseNom,
        baseDescrip: data.base.baseDescrip,
        operacion  : `${index}/${data.operacion}`
    };
};

export const ArrayJsonPrimaVacacional = data => { 
    const newJson = data.map( (item, index) =>  JsonPrimaVacacional( item ) );
    return newJson;
};

export const JsonPrimaVacacional = data => {
    return {
        id:data.id+'PV',
        tipo: 'Prima Vacacional',
        fechaIni: longDateToInput( data.fechaIni ).split('-').reverse().join('/') ,
        fechaFin: data.fechaFin ? longDateToInput( data.fechaFin ).split('-').reverse().join('/') :'-',
        diasPrimaAnio: data.diasPrimaAnio,
        periodoPagoSem1: data.periodoPagoSem1,
        periodoPagoSem2: data.periodoPagoSem2,
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonAguinaldo = data => { 
    const newJson = data.map( (item, index) =>  JsonAguinaldo( item ) );
    return newJson;
};

export const JsonAguinaldo = data => {
    return {
        id:data.id+'AG',
        tipo: 'Aguinaldo',
        fechaIni: longDateToInput( data.fechaIni ).split('-').reverse().join('/') ,
        fechaFin: data.fechaFin ? longDateToInput( data.fechaFin ).split('-').reverse().join('/') :'-',
        diasAguinaldoAnio: data.diasAguinaldoAnio,
        diasBonoAnio: data.diasBonoAnio,
        periodoPagoSem1: data.periodoPagoSem1,
        periodoPagoSem2: data.periodoPagoSem2,
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonDiasAjuste = data => { 
    const newJson = data.map( (item, index) =>  JsonDiasAjuste( item ) );
    return newJson;
};

export const JsonDiasAjuste = data => {
    return {
        id:data.id+'DA',
        tipo: 'Días de Ajuste',
        fechaIni: longDateToInput( data.fechaIni ).split('-').reverse().join('/') ,
        fechaFin: data.fechaFin ? longDateToInput( data.fechaFin ).split('-').reverse().join('/') :'-',
        diasAjuste: data.diasAjuste,
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonVacaciones = data => { 
    const newJson = data.map( (item, index) =>  JsonVacaciones( item ) );
    return newJson;
};

export const JsonVacaciones = data => {
    return {
        id:data.id+'VA',
        tipo: 'Vacaciones',
        fechaIni: longDateToInput( data.fechaIni ).split('-').reverse().join('/') ,
        fechaFin: data.fechaFin ? longDateToInput( data.fechaFin ).split('-').reverse().join('/') :'-',
        fechaIn: longDateToInput( data.fechaIni ),
        fechaFi: data.fechaFin ? longDateToInput( data.fechaFin ):null,
    };
};

export const ArrayJsonTablasSubsidios = data => { 
    const newJson = data.map( item  =>  JsonTablasSubsidios( item ) );
    return newJson;
};

export const JsonTablasSubsidios = data => {
    return {
        id:data.id,
        limiteInf: floatToAmount( data.limiteInf, 2 ),
        limiteSup: data.limiteSup ? floatToAmount( data.limiteSup, 2) : 0, 
        importe: floatToAmount( data.importe, 2)
    };
};

export const ArrayJsonTablasImpuestosData= data => { 
    const newJson = data.map( item  =>  JsonTablasImpuestosData( item ) );
    return newJson;
};

export const JsonTablasImpuestosData = data => {
    return {
        id:data.id,
        limiteInf: floatToAmount( data.limiteInf, 2 ),
        limiteSup: data.limiteSup ? floatToAmount( data.limiteSup, 2) : '', 
        cuotaFija: parseFloat( data.cuotaFija).toFixed(2),
        porcentaje: parseFloat( data.porcentaje).toFixed(2)
    };
};


export const ArrayJsonTabuladoresSueldo= data => { 
    const newJsonData = data.map( item  =>  JsonTabuladoresSueldo( item ) ); 
    return newJsonData;
};

export const JsonTabuladoresSueldo = data => {
    const newJsonIndicador={};
    const auxiliar = data.indicadores.map( item  =>  JsonTabuladoresSueldoIndicadores( item ) ); 
    Object.assign(newJsonIndicador, ...auxiliar); 
    return {
        puestoId:data.id,
        nivel: data.nivlaboralClave,
        codigo: data.puestoCve,
        puesto: data.puestoNom,
        ...newJsonIndicador
    };
}; 
export const JsonTabuladoresSueldoIndicadores = data => {
    const element={};
    element[data.id] = floatToAmount(data.monto, 2);
    return element;
};

export const ArrayJsonUsuariosReportesRRHH = data => {
    const newJson = data.map( item => JsonUsuariosReportesRRHH( item ) );
    return newJson;
};

export const JsonUsuariosReportesRRHH = data => {
    
    return {
        id: data.id,
        clave: data.clave,
        nombre: data.nombre,
        paterno: data.paterno,
        materno: data.materno,  
        fecnacimiento: ConvertDate( longDateToInput(data.fecnacimiento)),  
        curp  : data.curp,
        rfc  : data.rfc,
        fecantiguedad  : ConvertDate( longDateToInput(data.fecantiguedad)),
        fecingreso  : ConvertDate( longDateToInput(data.fecingreso)),
        puestoClave  : data.puestoClave,
        puestoNom: data.puestoNom,
        puestoClave  : data.puestoClave,
        puestoNombre  : data.puestoNombre,
        cencosClave  : data.cencosClave,
        cencosNombre : data.cencosNombre, 
        regpatronalClave  : data.regpatronalClave,
        sueldoDiario: data.sueldoDiario,  
        jornadaNombre : data.jornadaNombre, 
        deptoClave  : data.deptoClave,
        deptoNombre : data.deptoNombre, 
        nominaClave : data.nominaClave,
        nominaNombre : data.nominaNombre, 
        tipoPago : data.tipoPago, 
        sexo : data.sexo, 
        nss : data.nss, 
        email : data.email,
        prestacionClave : data.prestacionClave,
        isnEstadoNombre : data.isnEstadoNombre,
        contratoNombre : data.contratoNombre,
        jornadaNombre : data.jornadaNombre,
        tipoPago : data.tipoPago,
        bancoNombre : data.bancoNombre,
        cuentaBancaria : data.cuentaBancaria,
        clabeInterbancaria : data.clabeInterbancaria,
        /*

                { field: "prestacionClave", title: "prestacionClave"},
        { field: "isnEstadoNombre", title: "isnEstadoNombre"},
        { field: "contratoNombre", title: "contratoNombre"},
        { field: "jornadaNombre", title: "jornadaNombre"},
        { field: "tipoPago", title: "tipoPago"},
        { field: "bancoNombre", title: "bancoNombre"},
        { field: "cuentaBancaria", title: "cuentaBancaria"},
        { field: "clabeInterbancaria", title: "clabeInterbancaria"},

        id: data.id,
        empleadoClave: data.empleadoClave,
        empleadoNom: data.empleadoNom,
        empleadoPat: data.empleadoPat,
        empleadoMat: data.empleadoMat,  
        empleadoFecnac: ConvertDate( longDateToInput( data.empleadoFecnac)),  
        empleadoCurp  : data.empleadoCurp,
        empleadoRfc  : data.empleadoRfc,
        empantFecini  : ConvertDate( longDateToInput( data.empantFecini)),
        puestoCve  : data.puestoCve,
        puestoNom: data.puestoNom,
        relabClave  : data.relabClave,
        realabNom  : data.realabNom,
        cpresupuestalClave  : data.cpresupuestalClave,
        cpresupuestalNom : data.cpresupuestalNom, 
        turnoNom  : data.turnoNom,
        tipojornadaClave: data.tipojornadaClave,  
        tipojornadaNom : data.tipojornadaNom, 
        nominaClave  : data.nominaClave,
        nominaNomCorto : data.nominaNomCorto, 
        nominaNombre : data.nominaNombre, 
        plazaClave : data.plazaClave, 
        areaNom : data.areaNom, 
        */

    };
};

export const ArrayJsonEmpleadosReportesNominas = data => {
    const newJson = data.map( item => JsonEmpleadosReportesNominas( item ) );
    return newJson;
};

export const JsonEmpleadosReportesNominas = data => {
    
    return {
        id: data.id,
        empleadoId: data.empleadoId,
        empleadoClave: data.empleadoClave,
        nombreCompleto: `${data.empleadoNom} ${data.empleadoPat} ${data.empleadoMat}`, 
        empleadoFecnac: ConvertDate( longDateToInput( data.empleadoFecnac)),
        fecingreso: ConvertDate( longDateToInput( data.fecingreso)),   
        empleadoCurp  : data.empleadoCurp,
        empleadoRfc  : data.empleadoRfc,
        empleadoNss  : data.empleadoNss, 
        cpresupuestalClave  : data.cpresupuestalClave,
        cpresupuestalNom : data.cpresupuestalNom, 
        ciaUr  : data.ciaUr,
        ciaRazonSocial: data.ciaRazonSocial,
        nominaId  : data.nominaId,
        nomindClave  : data.nomindClave,
        tiponatuId  : data.tiponatuId,
        tiponatuNom: data.tiponatuNom,  
        nomindNomIndicador : data.nomindNomIndicador, 
        cantidad  : parseFloat(data.cantidad).toFixed(2),
        monto : parseFloat(data.monto).toFixed(2), 
        periodoId : data.periodoId, 
        acumuladoClave : data.acumuladoClave, 
        acumuladoNombre : data.acumuladoNombre, 
        periodoFecini : ConvertDate( longDateToInput( data.periodoFecini)), 
        periodoFecfin : ConvertDate( longDateToInput( data.periodoFecfin)), 
        periodoPeranio : data.periodoPeranio, 
        periodoAnio : data.periodoAnio,
          
    };
};

export const ArrayJJsonNewReport = data => {
    const newJson = data.map( item => JsonNewReport( item ) );
    return newJson;
};

export const JsonNewReport = data => {
    
    return {
        reportesId: data.id,
        reportesReporteDescrip: data.reporteDescrip, 
        reportesReporteJson: data.reporteJson,
        reportesReporteNom: data.reporteNom, 
        reportesCreated: data.created,
        usuarioDatosUsuarioMat: data.usuarioDatosUsuarioMat,
        usuarioDatosUsuarioNom: data.usuarioDatosUsuarioNom,
        usuarioId: data.usuarioId,
        usuarioUsername: data.usuarioUsername,
        usuarioStatus: data.usuarioStatus,
    };
};

export const ArrayJsonSubordinatesUsers= data => {
    const newJson = data.map( item => JsonSubordinatesUsers( item ) );
    return newJson;
};

export const JsonSubordinatesUsers = data => {
    
    return {
        id: data.id,
        datosUsuarioNom: data.datosUsuarioNom, 
        datosUsuarioPat: data.datosUsuarioPat,
        datosUsuarioMat: data.datosUsuarioMat, 
        status: data.status,
        username: data.username,
        img: data.foto
    };
};

export const ArrayJsonAuditorias= (data, datosUser) => {
    const newJson = data.map( item => JsonAuditorias( item, datosUser ) );
    return newJson;
};

export const JsonAuditorias = (data, username) => {
    return {
        id: data.id,
        usuario: username.username,
        nombre: username.datosUsuarioNom,
        apaterno: username.datosUsuarioPat, 
        amaterno: username.datosUsuarioMat,
        moduleName: data.moduleName,
        crudOperation: data.crudOperation ==="INSERT" ? 'Agregar': data.crudOperation ==="UPDATE" ? 'Actualizar': 'Eliminar', 
        date : ConvertDate(data.date),
        fecha: data.date,
        datos: data.datos,
        img: username.img
    };
};

export const ArrayJsonPlantillas = data => {
    const newJson = data.map(item => JsonPlantillas(item));
    return newJson;
};

export const JsonPlantillas = data => {
    return {
        id: data.id,
        plantillaClave: data.plantillaClave,
        plantillaNom: data.plantillaNom,
        plantillaDesc: data.plantillaDesc, 
        plantillaHtml: data.plantillaHtml
    };
};

export const ArrayJsonMovimientosEmpleado = data => {
    const newJson = data.map(item => JsonMovimientosEmpleado(item));
    return newJson;
};

export const JsonMovimientosEmpleado = data => {
    return {
        id: data.id,
        acumulado: data.acumulado,
        cantidad: data.cantidad,
        empleado: data.empleado, 
        fechaAplicacion: data.fechaAplicacion,
        monto: floatToAmount(data.monto, 2),
        nomina: data.nomina,
        fechaAplicacionTab: data.fechaAplicacion ? ConvertDate( longDateToInput( data.fechaAplicacion ) ) : '-',
    };
};

export const ArrayJsonMovimientosFijosEmpleado = data => {
    const newJson = data.map(item => JsonMovimientosFijosEmpleado(item));
    return newJson;
};

export const JsonMovimientosFijosEmpleado = data => {
    return {
        id: data.id, 
        empleado: data.empleado,
        nomina: data.nomina,
        cantidad: data.cantidad, 
        monto: floatToAmount(data.monto, 2),
        acumulado: data.acumulado, 
        fechaInicio: data.fechaInicio,
        fechaFinal: data.fechaFinal, 
        fechaInicioTab: data.fechaInicio ? ConvertDate( longDateToInput( data.fechaInicio ) ) : '-',
        fechaFinalTab: data.fechaFinal ? ConvertDate( longDateToInput( data.fechaFinal ) ) : '-',
    };
};

export const ArrayJsonMovimientosRelacionadosEmpleado = data => {
    const newJson = data.map(item => JsonMovimientosRelacionadosEmpleado(item));
    return newJson;
};

export const JsonMovimientosRelacionadosEmpleado = data => {
    return {
        id: data.id, 
        empleado: data.empleado,
        nomina: data.nomina,
        acumulado: data.acumulado, 
        fechaInicio: data.fechaInicio,
        fechaFinal: data.fechaFinal, 
        fechaInicioTab: data.fechaInicio ? ConvertDate( longDateToInput( data.fechaInicio ) ) : '-',
        fechaFinalTab: data.fechaFinal ? ConvertDate( longDateToInput( data.fechaFinal ) ) : '-',
    };
};

export const ArrayJsonIndicadorRelacionado = data => {
    const newJson = data.map(item => JsonIndicadorRelacionado(item));
    return newJson;
};

export const JsonIndicadorRelacionado = data => {
    return {
        id: data.id, 
        movrelacionado: data.movrelacionado,
        nominaIndicador: data.nominaIndicador,
        porcentaje: data.porcentaje.toFixed(2) 
    };
};

export const ArrayJsonConsultaNomina = data => { 
    const newJson = data.map( item =>  JsonConsultaNomina( item ) );
    return newJson;
};

export const JsonConsultaNomina = (data, index) => {
    return { 
        filaId : data.filaId,
        nominaId : data.nominaId,
        periodoId : data.periodoId,
        fecIni : data.fecIni,
        fecFin :  data.fecFin,
        acumuladoId : data.acumuladoId,
        acumuladoClave : data.acumuladoClave,
        acumuladoNombre :  data.acumuladoNombre ,
        indicadorId : data.indicadorId,
        nomindClave : data.nomindClave,
        nomindIndicador :  data.nomindIndicador ,
        round : `${data.tipoNatuId === 2 ? '-':''} ${floatToAmount(data.round, 2)}`,
        tipoNatuId : data.tipoNatuId,
        tipoNatuNom :  data.tipoNatuNom 
    };
};



export const ArrayJsonConceptosEmp = data => { 
    const newJson = data.map( item =>  JsonConceptosEmp( item ) );
    return newJson;
};

export const JsonConceptosEmp = data => {
    return { 
        empleadoId: data.empleadoId,
        indicadorSat: data.indicadorSat,
        nominaClave: data.nominaClave,
        nominaIndicador: data.nominaIndicador,
        cantidad: data.cantidad,
        monto: floatToAmount(data.monto, 2),
        aplica: data.aplica,
        procesoId: data.procesoId,
        nominaId: data.nominaId,
        periodoId: data.periodoId,
        acumuladoId: data.acumuladoId,
        acumuladoClave: data.acumuladoClave,
        acumuladoNombre: data.acumuladoNombre,
        periodoIni: data.periodoIni,
        periodoFin: data.periodoFin,
        periodoPerAnio: data.periodoPerAnio,
        periodoAnio: data.periodoAnio,
        empleadoClave: data.empleadoClave,
        empleadoNom: data.empleadoNom,
        empleadoPat: data.empleadoPat,
        empleadoMat: data.empleadoMat,
        indicadorId: data.indicadorId,
        tiponatuId: data.tiponatuId,
        tiponatuNom: data.tiponatuNom,
        nombreCompleto: data.nombreCompleto,
        id: data.indicadorId,
 
    };
};

export const ArrayJsonNominas = data => { 
    const newJson = data.map( item =>  JsonNominas( item ) );
    return newJson;
};

export const JsonNominas = data => {
    return { 
        id: data.id,
        relacionLab: data.empresa, 
        periodicidad: data.periodicidad,
        nominaClave: data.clave,
        nominaNomCorto: data.nombre,
        nominaNombre: data.nombre,
        nominaDescrip: data.nombre,
        /*status: data.id,
        paramNom: data.paramNom,
        paramImpto: data.paramImpto,
        origenRecurso: data.origenRecurso,
        regimenFiscal: data.regimenFiscal,
        tipoRegimen:  data.tipoRegimen,*/
        nominaClaveNombre: `${data.clave} (${data.nombre})`
    };
};
import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormularioTipoRelacion from './FormularioTipoRelacion';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditTipoRelacion = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarTipoRelacion = (tipoRelacion, tipoRelacionId) => {
        const info = { id: tipoRelacionId, clave: tipoRelacion.clave, nombre: tipoRelacion.nombre, descripcion: tipoRelacion.descripcion };
        Axios.put( `${servicios_grupogack_catalogo}/relacionlaboral/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar(res.data.dataResponse);
                setOpenEdit(false);
                break;
            case 400:
            case 404:
                setError(<AlertForm message={res.data.msg} />);
                break;
            default:
                errorTable(cambios_no_guardados);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Tipo de Relación Laboral" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormularioTipoRelacion
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarTipoRelacion={actualizarTipoRelacion}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTipoRelacion;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import ConvertDate from '../../../Helpers/ConvertDate';
import FormAusentismos from './FormAusentismos';
import AlertForm from '../../../../../../Services/AlertForm';
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation';

const ModalEditAusentismos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo }) => {

    const [error, setError] = useState(null);

    const { claveEmpleado, tiposAusentismo } = informacionAdicional;

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarAusentismo = (ausentismo, ausentismoId) => {
        const request = ausentismo;
        axios.put(`${servicios_grupogack_rh}/empleado/ausentismo/${ausentismoId}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const data = {
                        id: res.data.dataResponse.id,
                        ausentismoId: res.data.dataResponse.ausentismo.id,
                        ausentismoNom: res.data.dataResponse.ausentismo.ausentismoNom,
                        auseFechaIni: ConvertDate(res.data.dataResponse.auseFechaIni),
                        auseFechaIniInput: longDateToInput(res.data.dataResponse.auseFechaIni),
                        auseFechaFin: ConvertDate(res.data.dataResponse.auseFechaFin),
                        auseFechaFinInput: longDateToInput(res.data.dataResponse.auseFechaFin),
                        auseFechaAplica: ConvertDate(res.data.dataResponse.auseFechaAplica),
                        auseFechaAplicaInput: longDateToInput(res.data.dataResponse.auseFechaAplica),
                        comentarios: res.data.dataResponse.comentarios
                    };
                    editar(data);
                    setOpenEdit(false);
                    break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg} />);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService
            title="Actualizar Ausentismo"
            parentNode={nombreModulo}
            isOpen={openEdit}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormAusentismos
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarAusentismo={actualizarAusentismo}
                        claveEmpleado={claveEmpleado}
                        tiposAusentismo={tiposAusentismo}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditAusentismos;
import React, { useState } from 'react';
import axios from 'axios';
import { registro_no_guardado, servicios_grupogack_plantillas } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonPlantillas } from '../../../Helpers/JsonToOneLevel';
import ModalService from './../../../ModalService/ModalService';
import FormPlantillas from './FormPlantillas';

const ModalAddPlantillas = ({ nombreModulo, openAdd, setOpenAdd, agregar, errorTable }) => {
    
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    };

    const enviarDatosPlantilla = plantilla => {
        const info = plantilla;
        axios.post(`${servicios_grupogack_plantillas}/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newPlantilla = JsonPlantillas(res.data.dataResponse);
                    agregar(newPlantilla);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    return (
        <ModalService
            title="Nueva Plantilla"
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormPlantillas
                        enviarDatosPlantilla={enviarDatosPlantilla}
                        error={error}
                    />
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalAddPlantillas;
import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_actualizado, cambios_no_guardados, registro_sera_actualizado } from '../../../../../../Constants/Constants';
import { validarSubmit, inputDateToLong, longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';
import InfoDependientesEconomicos from './InfoDependientesEconomicos';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertForm from '../../../../../../Services/AlertForm';
import { withStyles } from '@material-ui/core/styles';

const DepEconCheckbox = withStyles({
    root: {
      color: '#1572E8',
      '&$checked': {
        color: '#1572E8',
      },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

const FormDependientesEconomicos = ({ elementSelect, setError, agregarDepEconEdit, actualizarDepEcon, enviar, irInfo, nivelesEscolaridad, nombreDepEconomicoRef, pApellidoDepEconomicoRef, sApellidoDepEconomicoRef, curpDepEconomicoRef, fechaNacDepEconomicoRef, tiposParentesco, parentescoDepEconommicoRef, escolaridadDepEconomicoRef, gradoEstudiosDepEconomicoRef, promedioDepEconomicoRef, mostrarDiscapacidad, setMostrarDiscapacidad, nivelDiscapacidadRef, nombreDiscapacidadRef, depEconomicosSaved, setDepEconomicosSaved, depEconomicosSavedEdit, setDepEconomicosSavedEdit, elementoSeleccionado, setElementoSeleccionado, mostrarFormAddEdit, setMostrarFormAddEdit, successTable, errorTable }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const guardar = e => {
        e.preventDefault();
        enviar();
    };

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const handleChange = () => {
        setMostrarDiscapacidad(!mostrarDiscapacidad);
    };

    const abrirFormAddEdit = e => {
        e.preventDefault();
        setMostrarFormAddEdit(true);
    };

    const cargarFormularioEditar = elemento => {
        if(elemento.discapacidad > 0) setMostrarDiscapacidad(true);
        setElementoSeleccionado(elemento);
    };

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupogack_rh}/empleado/dependiente/economico/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const info = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                nombre: res.nombre,
                                apellidoPat: res.apellidoPat,
                                apellidoMat: res.apellidoMat,
                                curp: res.curp,
                                fechaNac: res.fechaNac,
                                parentesco: res.parentesco.id,
                                parentescoNom: res.parentesco.parentescoNom,
                                escolaridad: res.escolaridad.id,
                                escolaridadNom: res.escolaridad.escolaridadNom,
                                gradoEscolar: res.gradoEscolar,
                                promedio: res.promedio,
                                depEconDiscapacidad: (res.discapacidad.id === 0) ? 'No' : 'Sí',
                                discapacidad: res.discapacidad.id,
                                nombreDisc: (res.discapacidad.id === 0) ? '' : res.discapacidad.nivel
                            }
                        ));
                        setDepEconomicosSaved(info);
                        break;
                    case 404:
                        setDepEconomicosSaved([]);
                        break;
                    default:
                        alert('No se puede obtener información de Dependientes Económicos');
                        break;
                }
            })
            .catch(error => { alert('No se puede obtener información de Dependientes Económicos'); console.log(error) });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eliminarDepEcon = item => {
        axios.delete(`${servicios_grupogack_rh}/empleado/dependiente/economico/${item.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setDepEconomicosSaved(depEconomicosSaved.filter(llEme => llEme.id !== item.id));
                    successTable(registro_actualizado);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => { errorTable(cambios_no_guardados); console.log(error) });
    };

    const guardaDependiente = (e, type) => {
        e.preventDefault();

        let lengthDepEcon = 0;
        let lengthDepEconS = 0;

        if(depEconomicosSaved.length) lengthDepEcon = depEconomicosSaved.length;
        if(depEconomicosSavedEdit.length) lengthDepEconS = depEconomicosSavedEdit.length;

        const total = lengthDepEcon + lengthDepEconS;

        if(total < 10) {
            setError(null);
            if(validarSubmit("FormDependientesEconomicos")) {
                const nombreParentesco = tiposParentesco.find(p => p.id === parseInt(parentescoDepEconommicoRef.current.value));
                const nombreEscolaridad = nivelesEscolaridad.find(e => e.id === parseInt(escolaridadDepEconomicoRef.current.value));
                let info = {
                    id: `${depEconomicosSaved.length}${nombreDepEconomicoRef.current.value}${pApellidoDepEconomicoRef.current.value}`,
                    nombre: nombreDepEconomicoRef.current.value,
                    fechaNac: inputDateToLong(fechaNacDepEconomicoRef.current.value),
                    parentesco: parseInt(parentescoDepEconommicoRef.current.value),
                    parentescoNom: nombreParentesco.parentescoNom,
                    escolaridad: parseInt(escolaridadDepEconomicoRef.current.value),
                    escolaridadNom: nombreEscolaridad.escolaridadNom,
                    discapacidad: (nivelDiscapacidadRef.current) ? parseInt(nivelDiscapacidadRef.current.value) : 0
                };
    
                if(pApellidoDepEconomicoRef.current.value !== '') info = { ...info, apellidoPat: pApellidoDepEconomicoRef.current.value };
                if(sApellidoDepEconomicoRef.current.value !== '') info = { ...info, apellidoMat: sApellidoDepEconomicoRef.current.value };
                if(curpDepEconomicoRef.current.value !== '') info = { ...info, curp: curpDepEconomicoRef.current.value };
                if(gradoEstudiosDepEconomicoRef.current.value !== '') info = { ...info, gradoEscolar: parseInt(gradoEstudiosDepEconomicoRef.current.value) };
                if(promedioDepEconomicoRef.current.value !== '') info = { ...info, promedio: parseFloat(promedioDepEconomicoRef.current.value) };
                
                if(info.discapacidad > 0) {
                    info = { ...info, nombreDisc: nombreDiscapacidadRef.current.value, depEconDiscapacidad: 'Sí'};
                } else {
                    info = { ...info, depEconDiscapacidad: 'No'};
                }
    
                if(type === 'FormAgregar') setDepEconomicosSaved([ ...depEconomicosSaved, info ]);
                if(type === 'FormEditar') {
                    info = { ...info, empleado: elementSelect.id }
                    setDepEconomicosSavedEdit([ ...depEconomicosSavedEdit, info ]);
                }
    
                nombreDepEconomicoRef.current.value = '';
                pApellidoDepEconomicoRef.current.value = '';
                sApellidoDepEconomicoRef.current.value = '';
                curpDepEconomicoRef.current.value = '';
                fechaNacDepEconomicoRef.current.value = '';
                parentescoDepEconommicoRef.current.value = '';
                escolaridadDepEconomicoRef.current.value = '';
                gradoEstudiosDepEconomicoRef.current.value = '';
                promedioDepEconomicoRef.current.value = '';
                if(nivelDiscapacidadRef.current & nombreDiscapacidadRef.current) {
                    nivelDiscapacidadRef.current.value = '';
                    nombreDiscapacidadRef.current.value = '';
                }
                setMostrarDiscapacidad(false);
            }
        } else {
            setError(<AlertForm message="Has llegado al límite de Dependientes Económicos"/>)
        }

    };

    const limpiaSelected = e => {
        e.preventDefault();
        if(depEconomicosSavedEdit.length) setDepEconomicosSavedEdit([]);
        else setElementoSeleccionado(null);
        setMostrarFormAddEdit(false);
    };

    const enviaTnfo = () => {
        setError(null);
        if(!depEconomicosSavedEdit.length && !mostrarFormAddEdit) {
            actualizarDepEcon();
        } else {
            agregarDepEconEdit();
        }
    };

    const discapacidad = <div className="formRelLab">
                            <div className="form-left">
                                <SelectValidation title="Nivel de Discapacidad"
                                    arrayOpciones={[{id: 1, nivelDiscapacidad: 'Nivel 1'}, {id: 2, nivelDiscapacidad: 'Nivel 2'}]} keyOpcion="nivelDiscapacidad"
                                    refValue={nivelDiscapacidadRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.discapacidad : ''} required/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Discapacidad" type="nombreDiscapacidad" placeholder="Escriba el nombre de la discapacidad"
                                    refValue={nombreDiscapacidadRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.nombreDisc : ''} 
                                    tooltipText={'Máximo 20 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                                    tooltipName="tooltipNombreDiscapacidad" maxLength={20} required/>
                            </div>
                        </div>;

    return (
        <Fragment>
            <h5>Dependientes Económicos</h5>
            {
                (!elementSelect || (elementoSeleccionado === null && depEconomicosSaved.length > 0 && !mostrarFormAddEdit))
                ?   depEconomicosSaved.map((dep, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            {
                                (elementSelect)
                                ?   <Fragment>
                                        <BtnEditItem id={dep.id} idArray="id" array={depEconomicosSaved} action={cargarFormularioEditar} remove/>
                                        <BtnRemoveItem id={dep.id} idArray="id" array={depEconomicosSaved} action={eliminarDepEcon} remove/>
                                    </Fragment>
                                :   <BtnRemoveItem id={dep.id} idArray="id" array={depEconomicosSaved} action={setDepEconomicosSaved}/>
                            }
                            <InfoDependientesEconomicos dep={dep} />
                        </Fragment>
                    ))
                :   null
            }
            {
                (depEconomicosSavedEdit.length)
                ?   depEconomicosSavedEdit.map((dep, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            <BtnRemoveItem id={dep.id} idArray="id" array={depEconomicosSavedEdit} action={setDepEconomicosSavedEdit}/>
                            <InfoDependientesEconomicos dep={dep} />
                        </Fragment>
                    ))
                :   null
            }
            {
                (!elementSelect || elementoSeleccionado !== null || depEconomicosSaved.length === 0 || (depEconomicosSaved.length > 0 && mostrarFormAddEdit))
                ?   <form id="FormDependientesEconomicos" onSubmit={(elementSelect) ? mostrarAlert : guardar}>
                            <InputValidation title="Nombre" type="nombre" placeholder="Escriba el nombre"
                                refValue={nombreDepEconomicoRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.nombre : ''} 
                                tooltipText="Máximo 60 letras y/o espacio" tooltipName="tooltipNomDepEcon" maxLength={60} onlyLetters=" " required/>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <InputValidation title="Primer Apellido" type="pApellido" placeholder="Escriba el primer apellido" refValue={pApellidoDepEconomicoRef}
                                        defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoPat) ? elementoSeleccionado.apellidoPat : '' : ''} 
                                        tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipPApellidoDepEcon" maxLength={30} onlyLetters=" "
                                        optional={true}/>
                                </div>
                                <div className="form-right">
                                    <InputValidation title="Segundo Apellido" type="sApellido" placeholder="Escriba el segundo apellido" refValue={sApellidoDepEconomicoRef}
                                        defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoMat) ? elementoSeleccionado.apellidoMat : '' : ''}
                                        tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipSApellidoDepEcon" maxLength={30} onlyLetters=" "
                                        optional={true}/>
                                </div>
                            </div>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <InputValidation title="CURP" type="curp" placeholder="Escriba el CURP"
                                        refValue={curpDepEconomicoRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.curp : ''} 
                                        tooltipText="18 letras y números en formato de CURP" tooltipName="tooltipCURPDepEcon" maxLength={18}
                                        alfanumeric optional={true}/>
                                </div>
                                <div className="form-right">
                                    <InputDateValidation title="Fecha de Nacimiento" refValue={fechaNacDepEconomicoRef}
                                        defaultValue={elementoSeleccionado ? longDateToInput(elementoSeleccionado.fechaNac) : ''} required/>
                                </div>
                            </div>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <SelectValidation title="Parentesco" arrayOpciones={tiposParentesco} keyOpcion="parentescoNom"
                                        refValue={parentescoDepEconommicoRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.parentesco : ''}
                                        required/>
                                </div>
                                <div className="form-right">
                                    <SelectValidation title="Escolaridad" arrayOpciones={nivelesEscolaridad} keyOpcion="escolaridadNom"
                                        refValue={escolaridadDepEconomicoRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.escolaridad : ''}
                                        required/>
                                </div>
                            </div>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <InputValidation title="Grado de Estudios" type="gradoEstudios" placeholder="Escriba el grado de estudios"
                                        refValue={gradoEstudiosDepEconomicoRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.gradoEscolar : ''}
                                        tooltipText="1 número del 1 al 9" tooltipName="tooltipGradoEstDepEcon" maxLength={1} optional={true}/>
                                </div>
                                <div className="form-right">
                                    <InputValidation title="Promedio" type="promedio" placeholder="Escriba el promedio"
                                        refValue={promedioDepEconomicoRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.promedio : ''}
                                        tooltipText="Máximo 2 números y dos decimales del 0.00 al 10.00" tooltipName="tooltipPromedioDepEcon"
                                        onBlurDecimal={2} onlyNumbers="." maxLength={5} optional={true}/>
                                </div>
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<DepEconCheckbox checked={mostrarDiscapacidad} onChange={handleChange} name="checkedDiscapacidad" />}
                                    label="Discapacidad"
                                />
                                { mostrarDiscapacidad && discapacidad }
                            </div>
                            <BtnsInfoAdicional numForm={4} irInfo={irInfo}/>
                            {
                                (!elementSelect || depEconomicosSaved.length === 0 || (depEconomicosSaved.length > 0 && mostrarFormAddEdit))
                                ?   (!elementSelect || depEconomicosSaved.length === 0)
                                    ?   <div className="formRelLab">
                                            <div className="form-left">
                                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                                            </div>
                                            <div className="form-right">
                                                <Button variant="outlined" className="btn-outline" onClick={e => (!elementSelect) ? guardaDependiente(e, 'FormAgregar') : guardaDependiente(e, 'FormEditar')}>Agregar</Button>
                                            </div>
                                        </div>
                                    :   <div className="formRelLab">
                                            <div className="form-left">
                                                <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                            </div>
                                            <div className="form-right">
                                                <Button variant="outlined" className="btn-outline" onClick={e => guardaDependiente(e, 'FormEditar')}>Agregar</Button>
                                            </div>
                                        </div>
                                :   null
                            }
                            {
                                (elementSelect && depEconomicosSaved.length > 0 && !mostrarFormAddEdit)
                                ?   <div className="formRelLab">
                                        <div className="form-left">
                                            <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                        </div>
                                        <div className="form-right">
                                            <Button variant="contained" className="btn-color" type="submit">{(!elementSelect || depEconomicosSaved.length === 0 || (depEconomicosSaved.length > 0 && mostrarFormAddEdit)) ? 'Guardar' : 'Actualizar' }</Button>
                                        </div>
                                    </div>
                                :   <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
                            }
                    </form>
                :   <Fragment>
                        <br/>
                        <BtnsInfoAdicional numForm={4} irInfo={irInfo}/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                            </div>
                            <div className="form-right">
                                <Button variant="outlined" className="btn-outline" onClick={e => abrirFormAddEdit(e)}>Agregar</Button>
                            </div>
                        </div>
                    </Fragment>
            }
            {   elementSelect &&
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaTnfo}/>
            }
        </Fragment>
    );
}
 
export default FormDependientesEconomicos;
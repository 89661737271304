import React, { createRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong } from '../../../../../../../Services/Validation/HelperValidation';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

const FormFinalizaPrestamos = ({ error, cerrarPrestamo }) => {
    const fechaFinRef = createRef('');

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'CerrarPrestamos' ) ){
            const fechaFin = inputDateToLong( fechaFinRef.current.value );
            //console.log({ fechaFin });
            cerrarPrestamo({ fechaFin });
        }
    };

    return (
        <form id={ 'CerrarPrestamos' } onSubmit={ enviar }>
            <InputDateValidation title="Fecha Cierre del Préstamo" refValue={fechaFinRef} required />

            {error}
            <Button variant="contained" className="btn-color" type="submit">{ 'Finalizar' }</Button>
        </form>
    );
};

export default FormFinalizaPrestamos;
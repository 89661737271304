import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from './../../../ModalService/ModalService';
import FormNiveles from './FormNiveles'; 

const ModalAddNiveles = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarNivel = nivel => {
        const info = { nivlaboralClave: nivel.nivelCve };
        Axios.post(`${servicios_grupogack_catalogo}/nvlaboral/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    agregar({ id: res.data.dataResponse.id, nivlaboralClave: res.data.dataResponse.nivlaboralClave, statusNom: res.data.dataResponse.status.statusNom });
                    setOpenAdd( false );
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    return (
        <ModalService title="Nuevo Nivel" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormNiveles
                        enviarNivel={enviarNivel}
                        error={error}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddNiveles;
import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { cambios_no_guardados, registro_actualizado, servicios_grupogack_plantillas } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonPlantillas } from '../../../Helpers/JsonToOneLevel';
import BtnBack from '../../../../../../Services/Components/SecondTable/BtnBack';
import EditorHTML from './EditorHTML';
import Variables from './Variables';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TitleCard from './components/TitleCard';
import SubtitleCard from './components/SubtitleCard';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import { pushWarning } from '../../../../../../Services/Notifications/PushService';
import { useStyles } from './styles/stylesCard';
import './styles/styles.css';

const EditPlantilla = ({ item, onShowTable, plantillas, setPlantillas }) => {

    const classes = useStyles();

    const { plantillaClave: clave, plantillaNom: nombre, plantillaHtml: html, id, plantillaDesc } = item;

    const [openAlertSuccess, setOpenAlertSuccess] = useState(false);
    const [openAlertError, setOpenAlertError] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        setText(html);
    //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actualizarDatosPlantilla = () => {
        const request = { plantillaClave: clave, plantillaNom: nombre, plantillaDesc, plantillaHtml: text };
        axios.put(`${servicios_grupogack_plantillas}/${id}`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const newPlantilla = JsonPlantillas(res.data.dataResponse);
                    setPlantillas(plantillas.map(element => element.id === newPlantilla.id ? element = newPlantilla : element));
                    setOpenAlertSuccess(true);
                break;
                default:
                    setOpenAlertError(true);
                    break;
            }
        })
        .catch(error => setOpenAlertError(true));
    };

    const asignarVariable = variable => {
        let tempInput = document.createElement("input");
        tempInput.style = "position: absolute; left: -1000px; top: -1000px";
        tempInput.value = `{{${variable}}}`;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        pushWarning({ title: 'La variable se copió al portapapeles', message: 'Agréguela a la plantilla con Ctrl + V' });
    };

    return (
        <Fragment>
            <BtnBack tooltipText="Regresar a tabla de plantillas" action={onShowTable}/>
            <div className="row">
                <div className="col col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 mb-1">
                    <Card className={classes.root}>
                        <CardContent className={classes.content}>
                            <div className="row">
                                <div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-12 d-flex align-items-end">
                                    <TitleCard title={`${clave} - ${nombre}`}/>
                                </div>
                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-4 col-4">
                                    <Tooltip title="Guardar" placement="left-start">
                                        <Button variant="contained" className="btn-w50 btn-color float-right" onClick={() => actualizarDatosPlantilla('Guardando...')}> 
                                            <SaveIcon/>
                                        </Button>
                                    </Tooltip>
                                </div>
                            </div>
                            <hr/>
                            <EditorHTML text={text} setText={setText}/>
                        </CardContent>
                    </Card>
                </div>
                <div className="col col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mb-1">
                    <Card className={classes.root}>
                        <CardContent className={classes.content}>
                            <div className="row">
                                <div className="col">
                                    <TitleCard title="Variables"/>
                                    <SubtitleCard subtitle="Seleccione la variable y pegue en el texto conforme lo vaya solicitando el documento"/>
                                </div>
                            </div>
                            <Variables asignarVariable={asignarVariable}/>
                        </CardContent>
                    </Card>
                </div>
            </div>
            <AlertSyE show={openAlertSuccess} setOpenAlertSyE={setOpenAlertSuccess} title="Actualizar Plantilla" text={registro_actualizado} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={openAlertError} setOpenAlertSyE={setOpenAlertError} title="Actualizar Plantilla" text={cambios_no_guardados} textbtnconfirm="Aceptar" type="error"/>
        </Fragment>
    );
}
 
export default EditPlantilla;
import React, { useState, Fragment } from 'react';
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined';
import { Tooltip } from '@material-ui/core';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_eliminado } from '../../../../../../Constants/Constants';

const BtnRemoveItem = ({ id, idArray, array, action, remove }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const mostrarAlerta = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const removeItem = () => {
        if(remove) {
            const elementSelected = array.find(element => element[idArray] === id);
            action(elementSelected);
        } else {
            const newArray = array.filter(element => element[idArray] !== id);
            action(newArray);
        }
    };

    return (
        <Fragment>
            <Tooltip title="Eliminar">
                <button className="btn-removeItem-outlined" onClick={mostrarAlerta}><RemoveCircleOutlineOutlinedIcon fontSize="small"/></button>
            </Tooltip>
            <Alertwarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={removeItem}/>
        </Fragment>
    );
}
 
export default BtnRemoveItem;
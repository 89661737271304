import React, { useState, useEffect } from 'react';
import CropService from '../../../CropService/CropService';

import './styles.css';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

/* Importaciones de elementos para peticiones axios */
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack, registro_no_guardado } from '../../../../../../Constants/Constants';
import AlertForm from '../../../../../../Services/AlertForm';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

/* Importacion de elementos para uso de Alertas */
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

const CargaLogo = ({ id }) => {
    const [ error, setError ] = useState( null );
    /* ---------------------------------- Funciones para el manejo de alertas ---------------------------------- */
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );

    const successAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorAlert = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }
    /* --------------------------------- Axios para obtener logotipo en base64 --------------------------------- */
    const [ logotipo, setLogotipo ] = useState( null );
    useEffect(() => {
        axiosLogotipo();
    }, []);

    const axiosLogotipo = () => {
        axios.get( `${servicios_grupogack}/logo/`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setLogotipo( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setLogotipo( '' );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* -------------------------------- Axios para actualizar logotipo en base64 ------------------------------- */
    const obtenerBase64 = ( base64 ) => {
        //console.log( base64 );
        enviarLogotipo({ imagen: base64 });
    }

    const reemplazarLogo = ( base64 ) => {
        successAlert( 'Registro Actualizado' );
        setLogotipo( base64 );
        const divLogo = document.getElementById( 'MenuDrawerLogo' );
        divLogo.childNodes[0].src = `data:image/jpeg;base64, ${base64}`;
    }

    /* Axios para almacenar nuevo logotipo en base64 */
    const enviarLogotipo = ( imagen64 ) => {
        axios.post( `${servicios_grupogack}/logo/`, imagen64, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        reemplazarLogo( respuesta.data.dataResponse );
                        setError( null );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorAlert( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorAlert( registro_no_guardado );
            })
    }
    /* --------------------------------------------------------------------------------------------------------- */
    return (
        <div id={ id } className="d-flex justify-content-center">
            <Card className="cardLogo">
                <CardContent>
                    <p className="titulo mb-3"> Actualizar </p>
                    <div className="imagen-upload">
                        <label htmlFor="carga_logo" className="MuiButtonBase-root MuiButton-root MuiButton-contained btn-color btnLogo"
                            type="button"> Cargar Logotipo </label>
                        <CropService inputId="carga_logo" parentNode={ id } getBase64={obtenerBase64}/>
                        <span className="especificacion"> Tamaño de logo 305px largo x 65px alto </span>
                    </div>

                    { logotipo ?
                        <div className="centerImg">
                            <img src={ `data:image/jpeg;base64, ${logotipo}` } alt="avatar"/>
                        </div>
                        :
                        <SpinnerOval />
                    }
                    { error }
                </CardContent>
            </Card>
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
};

export default CargaLogo;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import DescriptionIcon from '@material-ui/icons/Description';

const FormAcumulados = ({ elementSelect, anios, setAnios, anioRef, periodos, setPeriodos, periodoRef, acumulados, setAcumulados, acumuladoRef, setMuestraTable, error, setError }) => {

    const [disabledPeriodos, setDisabledPeriodos] = useState(true);
    const [disabledAcumulados, setDisabledAcumulados] = useState(true);

    useEffect(() => {
        axios.get(`${servicios_grupogack_nomina}/acumulados/${elementSelect.id}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const arrayAnios = res.data.dataResponse.map(item => ( { id: item.periodoAnio, anio: item.periodoAnio } ))
                    setAnios(arrayAnios);
                    break;
                default:
                    setAnios([]);
                    break;
            }
        })
        .catch(error => setError('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const muestraPeriodos = value => {
        if(value !== '') {
            periodoRef.current.value = '';
            setAcumulados([]);
            setDisabledPeriodos(true);
            setDisabledAcumulados(true);
            setMuestraTable(false);
            axios.get(`${servicios_grupogack_nomina}/acumulados/${elementSelect.id}/${value}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayPeriodos = res.data.dataResponse.map(item => ( { id: item.periodoId, periodo: item.periodoPerAnio } ))
                        setPeriodos(arrayPeriodos);
                        break;
                    default:
                        setPeriodos([]);
                        break;
                }
            })
            .catch(error => setError('error'));
            setDisabledPeriodos(false);
        } else {
            setPeriodos([]);
            setAcumulados([]);
            setDisabledPeriodos(true);
            setMuestraTable(false);
        }
    };

    const muestraAcumulados = value => {
        if(value !== '') {
            acumuladoRef.current.value = '';
            setMuestraTable(false);
            setDisabledAcumulados(true);
            axios.get(`${servicios_grupogack_nomina}/acumulados/inner/${elementSelect.id}/${value}`, headersAuth()) 
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const arrayAcumulados = res.data.dataResponse.map(item => ( { id: item.acumuladoId, acumulado: item.acumuladoNombre } ))
                        setAcumulados(arrayAcumulados);
                        break;
                    default:
                        setAcumulados([]);
                        break;
                }
            })
            .catch(error => setError('error'));
            setDisabledAcumulados(false);
        } else {
            setAcumulados([]);
            setDisabledAcumulados(true);
            setMuestraTable(false);
        }
    };

    const validaAcumulado = value => {
        if(value === '') {
            setMuestraTable(false);
        }
    };

    const mostrarTable = e => {
        e.preventDefault();
        if(validarSubmit("FormConsultaAcumulado")) {
            setMuestraTable(true);
        }
    };

    return (
        <form id="FormConsultaAcumulado" onSubmit={mostrarTable}>
            <div className="row">
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <SelectValidation title="Año" arrayOpciones={anios} keyOpcion="anio" onChangeExecute={muestraPeriodos}
                        refValue={anioRef} defaultValue="" required/>
                </div>
                <div className="col col-lg-3 col-md-3 col-sm-6">
                    <SelectValidation title="Periodo" arrayOpciones={periodos} keyOpcion="periodo" onChangeExecute={muestraAcumulados}
                        refValue={periodoRef} defaultValue="" disabled={disabledPeriodos} required/>
                </div>
                <div className="col col-lg-5 col-md-4 col-sm-9">
                    <SelectValidation title="Tipo de Acumulado" arrayOpciones={acumulados} keyOpcion="acumulado"
                        onChangeExecute={validaAcumulado} refValue={acumuladoRef} defaultValue="" disabled={disabledAcumulados} required/>
                </div>
                <div className="col col-lg-1 col-md-2 col-sm-3">
                    <button type="submit" className="btn-autocompleteInput"><DescriptionIcon /></button>
                </div>
            </div>
        </form>
    );
}
 
export default FormAcumulados;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
//import { JsonTipoDocumentos } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioNominasOrg from './FormularioNominasOrg';

const ModalEditNominasOrg = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarNomina = ( nomina, nominaId ) => {
        axios.put( `${servicios_grupogack_nomina}/config/nomina/${nominaId}`, nomina, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //editar( JsonTipoDocumentos(respuesta.data.dataResponse) );
                        editar( respuesta.data.dataResponse );
                        setOpenEdit( false );
                        break;
                    case 400:
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Nómina" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioNominasOrg
                    error={error}
                    elementSelect={elementSelect}
                    informacion={informacionAdicional}
                    actualizarNomina={actualizarNomina}
                />
        </ModalService>
    );
};

export default ModalEditNominasOrg;
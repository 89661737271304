import React, { useState, createRef } from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../Services/Validation/HelperValidation';
import PasswordValidation from '../../../../../Services/Validation/PasswordValidation';

import { servicios_grupogack_url, cambios_no_guardados, ocurrio_un_error } from '../../../../../Constants/Constants';
import { headersAuth, obtenerUsuario } from '../../../../../Services/AuthService';
import AlertForm from '../../../../../Services/AlertForm';



const FormularioContrasena = ({ setOpenForm }) => {
    const [ error, setError ] = useState( null );
    const newPasswordRef = createRef('');
    const confirmPasswordRef = createRef('');

    const actualizar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'actualizarContraseña' ) ){
            const newPassword = newPasswordRef.current.value;
            const confirmPassword = confirmPasswordRef.current.value;
            if( newPassword === confirmPassword ){
                setError( null );
                actualizarPassword({ newPassword, confirmPassword });
            }else{
                setError( <AlertForm message="Los campos no coinciden" /> );
            }
        }
    }

    /* 1.- Verifica que el nuevo password y la confirmación sean identicas. 2.- Actualiza el password
       3.- Cierra la sesión del usuario en el sistema. */
    const actualizarPassword = ( newPassword ) => {
        axios.put( `${servicios_grupogack_url}/password`, newPassword, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        actualizarSesion( newPassword.confirmPassword );
                        break;
                    case 400:
                        setError( <AlertForm message={ `Actualizar contraseña: ${respuesta.data.msg}` } /> );
                        break;
                    default:
                        setError( <AlertForm message={ `Actualizar contraseña: ${cambios_no_guardados}` } /> );
                        break;
                }
            })
            .catch( error => {
                setError( <AlertForm message={ `Actualizar Contraseña: ${ocurrio_un_error}` } /> );
            })
    }

    const logout = () => {
        axios.post( `${servicios_grupogack_url}/account/logout`, null, headersAuth() );
    }

    /* Funcion para actualizar sesión con nueva contraseña (Ya que al actualizar contraseña se realiza un cierre de sesión automatico en sistema) */
    const actualizarSesion = ( newPassword ) => {
        axios.post( `${servicios_grupogack_url}/account/login`, { username: obtenerUsuario().usuario, password: newPassword } )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        const authToken = respuesta.headers[ "x-authorization" ];
                        if( authToken ){
                            const { id, nombre, paterno, materno, username, tour, tyc } = respuesta.data.dataResponse;
                            localStorage.setItem( "IGSESSID", JSON.stringify( { "token": authToken, "claveUsuario": id, "nombre" : nombre, "apaterno": paterno, "amaterno": materno, "usuario": username, "tour": tour, "tyc": tyc }));
                            setOpenForm( false );
                        }else{
                            setError( <AlertForm message={ `Actualizar Sesión: ${ocurrio_un_error} al actualizar la sesión` } /> );
                        }
                        break;

                    case 401: //Cuenta (bloqueda ó inactiva), Contraseña es incorrecta ó -Existe una sesión activa-
                        /* Validamos -Sesión activa- (En caso de que la peticion -Login- sea mas rapida que el cierre de sesion automatico del servicio -actualizarContraseña-) */
                        if( respuesta.data.msg && respuesta.data.msg.includes('sesión activa') ){
                            logout(); //Cerramos sesión para asegurarnos que la proxima petición de Login sera correcta
                            actualizarSesion( newPassword ); //Llamamos la funcion nuevamente para reintentar la actualizacion de la sesión
                        }
                        break;

                    case 400: //Bad request
                    case 404: //La cuenta no existe
                        setError( <AlertForm message={ `Actualizar Sesión: ${respuesta.data.msg}` } /> );
                        break;
                    default:
                        setError( <AlertForm message={ `Actualizar Sesión: ${cambios_no_guardados}` } /> );
                        break;
                }
            })
            .catch( error => {
                setError( <AlertForm message={ `Actualizar Sesión: ${ocurrio_un_error}` } /> );
            })
    }

    return (
        <form id="actualizarContraseña" onSubmit={actualizar}>
            <PasswordValidation title="Nueva contraseña" type="password" required placeholder="Escriba su nueva contraseña" maxLength='16'
                tooltipText={'De 8 a 16 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial incluyendo !@#$&*%/()=?¡.,|°<>;+_'} tooltipName={'tooltipPassword'}
                refValue={newPasswordRef}/>
            <PasswordValidation title="Confirme su contraseña" type="password" required placeholder="Confirme su nueva contraseña" maxLength='16'
                tooltipText={'De 8 a 16 caracteres, una letra mayúscula, una letra minúscula, un número y un caracter especial incluyendo !@#$&*%/()=?¡.,|°<>;+_'} tooltipName={'tooltipConfirmPassword'}
                refValue={confirmPasswordRef}/>
            { error }
            <Button type="submit" variant="contained" className="btn-color">Actualizar Contraseña</Button>
        </form>
    );
};

export default FormularioContrasena;
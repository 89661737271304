import React, { createRef, useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import { servicios_grupogack_org, servicios_grupogack_catalogo, registro_sera_eliminado, registro_no_eliminado} from '../../../../../../Constants/Constants';
import Axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import AlertForm from '../../../../../../Services/AlertForm';

const FormTurnos = ({ error, enviarTurnos, elementSelect, actualizarTurno, informacion }) => {
    
    const [ errorDias, setErrorDias ] = useState( null );
    const nombreTurnoRef = createRef('');
    const diaRef= createRef('');
    const jornadaIdRef= createRef('');
    const colReplicateRef = useRef(null);
    const delRowRef= useRef(null); 
    const horas={horalab_id_entrada_hora:"00:00",
            horalab_id_salida_comida_hora:"00:00",
            horalab_id_regreso_comida_hora:"00:00",
            horalab_id_salida_hora:"00:00"
    };
    const nombreDias=[{id:0, nombre:"Lunes"}, {id:1, nombre:"Martes"}, {id:2, nombre:"Miércoles"}, {id:3, nombre:"Jueves"}, {id:4, nombre:"Viernes"}, {id:5, nombre:"Sábado"}, {id:6, nombre:"Domingo"}];
    const [dias, setDias]=useState([]);
    const [tipoJornadas, setTipoJornadas]= useState(null); 
    const [diasSelect, setDiasSelect]=useState(nombreDias);
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const [ deleteRow, setDeleteRow ] = useState( false );
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    
    useEffect(()=>{
        if(elementSelect){
            Axios.get(`${servicios_grupogack_org}/turno/getall/${elementSelect.id}`, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        let response=[];
                        let newDiasSelect=[];
                        res.data.dataResponse.map((key)=>{
                            let horario={};
                            horario.horalab_id_entrada_hora=`${key.entrada.hour<10 ? "0"+key.entrada.hour : key.entrada.hour}:${key.entrada.minute<10 ? "0"+key.entrada.minute : key.entrada.minute}`;
                            horario.horalab_id_salida_comida_hora=`${key.salcomida.hour<10 ? "0"+key.salcomida.hour : key.salcomida.hour}:${key.salcomida.minute<10 ? "0"+key.salcomida.minute : key.salcomida.minute}`;
                            horario.horalab_id_regreso_comida_hora=`${key.regcomida.hour<10 ? "0"+key.regcomida.hour : key.regcomida.hour}:${key.regcomida.minute<10 ? "0"+key.regcomida.minute : key.regcomida.minute}`;
                            horario.horalab_id_salida_hora=`${key.salida.hour<10 ? "0"+key.salida.hour : key.salida.hour}:${key.salida.minute<10 ? "0"+key.salida.minute : key.salida.minute}`; 
                            response.push({nombre:nombreDias[key.diaLab.id-1], id: key.id, horas:horario});
                            newDiasSelect.push(key.diaLab.id-1);
                            return null;
                        }); 
                        setDias(response);
                        setSelectDias(newDiasSelect); 
                        break;
                    case 404:
                        console.log(res.data.dataResponse);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert(error));
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const setSelectDias= (diasTurno)=>{
        let diasSelectTemp=diasSelect;
        for (const index in diasTurno) {
            diasSelectTemp=diasSelectTemp.filter(dia=>dia.id!==parseInt(diasTurno[index]));
        }
        setDiasSelect(diasSelectTemp);
    }
    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarTurno' ) ){
            if(dias.length<1){
                setErrorDias( <AlertForm message="Agregue un día." /> );
            }else{
                const turno_nom = nombreTurnoRef.current.value;
                const tipojornada_id= parseInt(jornadaIdRef.current.value);
                var horario=[];
                for (const index in dias) {  
                    for(const indice in horas){
                        if(dias[index].horas[indice]==='--:--' || dias[index].horas[indice]==='' ){
                            dias[index].horas[indice]=null;
                        }
                    }              
                    horario.push({dialab_id: dias[index].nombre.id+1, horas: dias[index].horas });
                }
                enviarTurnos({turno_nom, tipojornada_id, horario});
            }
        }
    }

    const deleteDay=(id)=>{
        
        Axios.delete( `${servicios_grupogack_org}/turno/${id}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    setTextAlert('Registro Eliminado');
                    setAlertSuccess(true);
                    break;
                case 404:
                    setTextAlert(respuesta.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setTextAlert( registro_no_eliminado );
                    setAlertError(true);
                    break;
            }
        })
        .catch( error => {
            setTextAlert( registro_no_eliminado );
            setAlertError(true);
        }) 
    } 
    
    useEffect(()=>{
        Axios.get(`${servicios_grupogack_catalogo}/tipoJornada`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200: 
                    setTipoJornadas(res.data.dataResponse); 
                    break;
                case 404:
                    setTipoJornadas([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    },[]);

    const actualizar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'EditarTurno' ) ){
            if(dias.length<1){
                setErrorDias( <AlertForm message="Agregue un día." /> );
            }else{
                const turno_nom = nombreTurnoRef.current.value;
                const tipojornada_id= parseInt(jornadaIdRef.current.value);
                var horario=[];
                for (const index in dias) {
                    
                    for(const indice in horas){
                        if(dias[index].horas[indice]==='--:--' || dias[index].horas[indice]==='' ){
                            dias[index].horas[indice]=null;
                        } 
                    }
                    if(dias[index].id!==undefined){                    
                        horario.push({id:dias[index].id, turno_nom, tipojornada_id, dias:[{dialab_id: dias[index].nombre.id+1, horas: dias[index].horas }]});
                    }else{
                        horario.push({turno_nom, tipojornada_id, dias:[{dialab_id: dias[index].nombre.id+1, horas: dias[index].horas }]});
                    }
                }
                actualizarTurno({turnoid:elementSelect.id, horario});
            }
        }
    }
    const addDia=async(value)=>{
        setErrorDias(null);
        var diasTab=value;
        const newDias=[...dias, {nombre:nombreDias[diasTab], horas}];
        await setDias(newDias);   
        const newDiasSelect=diasSelect.filter(dia=>dia.id!==parseInt(diasTab)); 
        setDiasSelect(newDiasSelect);
        for(var i=0; i<newDias.length; i++){
            for(var j=0; j<4; j++){
                var input = document.getElementsByName(`${j}-${newDias[i].nombre.id}`); 
                const llave=Object.keys(horas);                
                input[0].value=newDias[i].horas[llave[j]]; 
            }
        }
    }

    const updateData=(key, value, name)=>{ 

        const llave=Object.keys(horas);
        let index=dias.findIndex(dia=> dia.nombre.id===parseInt(key)); 
        dias[index].horas[llave[parseInt(name.charAt(0))]]=(value!=='' ? value:'00:00'); 
        
    }

    const getDias=()=>{
        let res=[];
        var diasBack=dias.sort((a, b) => (a.nombre.id > b.nombre.id) ? 1 : -1);
        var newDias=[]; 
        diasBack.map((key, index)=>{ 
        
                res.push(<tr key={index} >
                            <td key={`${index}-dia`} className="label"> {key.nombre.nombre}</td>
                            <td key={`${index}-ent`}> <input required type="text" maxLength={5} autoComplete="off" onKeyDown={e=>{validInput(e)}} className="inpTable inputTime" name={`0-${key.nombre.id}`} id={key.nombre.id}
                                defaultValue={elementSelect ? key.horas.horalab_id_entrada_hora : ''} onBlur={e=>{validHour(e);updateData(e.target.id, e.target.value, e.target.name, e)}}></input>
                            </td>
                            <td key={`${index}-com`}> <input  type="text" maxLength={5} autoComplete="off" onKeyDown={e=>{validInput(e)}} className="inpTable inputTime" name={`1-${key.nombre.id}`} id={key.nombre.id}  
                                defaultValue={elementSelect ? key.horas.horalab_id_salida_comida_hora : ''}  onBlur={e=>{validHour(e);updateData(e.target.id, e.target.value, e.target.name, e)}}></input> </td>
                            <td key={`${index}-reg`}> <input  type="text" maxLength={5} autoComplete="off" onKeyDown={e=>{validInput(e)}} className="inpTable inputTime" name={`2-${key.nombre.id}`} id={key.nombre.id}  
                                defaultValue={elementSelect ? key.horas.horalab_id_regreso_comida_hora : ''} onBlur={e=>{validHour(e);updateData(e.target.id, e.target.value, e.target.name, e)}}></input> </td>
                            <td key={`${index}-sal`}> <input required type="text" maxLength={5} autoComplete="off" onKeyDown={e=>{validInput(e)}} className="inpTable inputTime" name={`3-${key.nombre.id}`}  id={key.nombre.id}  
                                defaultValue={elementSelect ? key.horas.horalab_id_salida_hora : ''} onBlur={e=>{validHour(e);updateData(e.target.id, e.target.value, e.target.name, e)}}></input> </td>
                            <td key={`${index}-del`}><IconButton aria-label="delete" size="small" onClick={e => alertDelete(key.nombre.id)}><ClearIcon style={{ color: "#4C638E" }}/></IconButton> </td>
                        </tr>);
                newDias.push(key.dia);
                return null;
            })
        return res;
    }

    const convertDate=e=>{
        var time = e.target.value;
        var hours = Number(time.match(/^(\d+)/)[1]);
        var minutes = Number(time.match(/:(\d+)/)[1]); 
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        var change=false;
        if(hours>23){ sHours = '23'; sMinutes = '59'; change=true };
        if(minutes>59){ sMinutes = '59'; change=true};
        if(change)  e.target.value=sHours + ":" + sMinutes;
    }

    const validHour=e=>{
        const HORA = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; //00:00, 01:59, 23:59
        const HORA_AM= /^[0-9]:[0-5][0-9]$/;//8:59, 2:00 ->08:59
        const HORA_SIMPLE= /^[0-9]$/;//9 -> 09:00
        const HORA_INCOMPLETA= /^([0-1]?[0-9]|2[0-3])$/;//19 -> 19:00
        const HORA_MAYOR=/^[0-9][0-9]:[0-9][0-9]$/;
        const HORA_SIMPLE_MAYOR=/^([2-9][4-9])$/;
        const HORA_INCOMPLETA_PUNTOS=/^([0-1]?[0-9]|2[0-3]):$/;
        const HORA_NUMEROS=/^([0-9][0-9][0-9][0-9]|[0-9])$/;
        const HORA_TRES_NUMEROS=/^([0-1]?[0-9]|2[0-3])?[0-5]$/;
        const HORA_TRES_NUMEROS_PUNTOS=/^([0-1]?[0-9]|2[0-3]):[0-5]$/;
        const HORA_DOS_NUMEROS_PUNTOS=/^([0-1]?[0-9]|2[0-3]):$/;
        const HORA_MAYOR_NUMEROS=/^2[4-9][0-9][0-9]$/;
        const HORA_MAYOR_INCOMPLETA=/^[2-9][4-9]$/;
        const HORA_NUMEROS_INCOMPLETA=/^[0-9][0-5][0-9]$/;
        
        if(HORA_AM.test(e.target.value)){
            e.target.value='0'+e.target.value;  
        }else if(HORA_MAYOR_INCOMPLETA.test(e.target.value)){
            e.target.value="00:00"; 
        }else if(HORA_NUMEROS_INCOMPLETA.test(e.target.value)){
            e.target.value='0'+e.target.value.charAt(0)+":"+e.target.value.charAt(1)+e.target.value.charAt(2);  
        }else if(HORA_SIMPLE.test(e.target.value)){
            e.target.value='0'+e.target.value+':00'; 
        }else if(HORA_INCOMPLETA.test(e.target.value)){
            e.target.value=e.target.value+':00'; 
        }else if(HORA_MAYOR.test(e.target.value)){ 
            convertDate(e); 
        }else if(HORA_DOS_NUMEROS_PUNTOS.test(e.target.value)){
            e.target.value=e.target.value+'00'; 
        }else if(HORA_SIMPLE_MAYOR.test(e.target.value)){
            e.target.value='00:00';  
        }else if(HORA_INCOMPLETA_PUNTOS.test(e.target.value)){
            e.target.value=e.target.value+'00';  
        }else if(HORA_MAYOR_NUMEROS.test(e.target.value)){
            e.target.value="00:00";
        }else if(HORA_TRES_NUMEROS.test(e.target.value)){
            e.target.value=e.target.value.charAt(0)+e.target.value.charAt(1)+":"+e.target.value.charAt(2)+'0';
            convertDate(e);
        }else if(HORA_TRES_NUMEROS_PUNTOS.test(e.target.value)){
            e.target.value=e.target.value.charAt(0)+e.target.value.charAt(1)+":"+e.target.value.charAt(3)+'0'; 
        }else if(HORA_NUMEROS.test(e.target.value)){
            e.target.value=e.target.value.charAt(0)+e.target.value.charAt(1)+":"+e.target.value.charAt(2)+e.target.value.charAt(3);
            convertDate(e); 
        } 
        if(!HORA.test(e.target.value)){
            if(e.target.required || (!e.target.required && e.target.value!=='')){
                e.target.className='inpTable inputTime is-invalid'; 
            }else{
                e.target.className='inpTable inputTime';
            }
        }else{
            e.target.className='inpTable inputTime';
        }
    }

    const validInput=e=>{ 
        
        if (((!(e.keyCode >= 48 && e.keyCode <= 57) && !(e.keyCode >= 96 && e.keyCode <= 105 ) && e.keyCode!==8) 
                && e.target.value.toString().length<5 && e.keyCode!==190 && !(e.keyCode >= 37 && e.keyCode <= 40 )
                && e.keyCode!==9 && e.keyCode!==46 && e.key!==':') || e.key==='.' ) { 
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
    }

    const alertDelete=(n)=>{
        delRowRef.current=n;
        setTextAlert(registro_sera_eliminado);
        setDeleteRow(true);
        setAlertWarning( true );
     }

    const del= async()=>{
        const indice = dias.findIndex(dia  => dia.nombre.id === parseInt(delRowRef.current));
        
        if(dias[indice].id!==undefined) await deleteDay(dias[indice].id);

        const newDias = dias.filter(dia  => dia.nombre.id !== parseInt(delRowRef.current)); 
        await setDias(newDias); 
        for(var i=0; i<newDias.length; i++){
            for(var j=0; j<4; j++){
                var input = document.getElementsByName(`${j}-${newDias[i].nombre.id}`);
                const llave=Object.keys(horas);                
                input[0].value=newDias[i].horas[llave[j]]; 
            }
        }
        const newDiasSelect=[...diasSelect, nombreDias[parseInt(delRowRef.current)]].sort((a, b) => (a.id > b.id) ? 1 : -1);
        setDiasSelect(newDiasSelect);
        setDeleteRow(false);

    }


    const alertReplicateData=(key)=>{
        colReplicateRef.current=key;
        setTextAlert("!Se aplicará este horario a todos los campos!");
        setAlertWarning(true);
    }


    const replicateData =()=>{        
        if(dias.length>1){
            var key=colReplicateRef.current;
            var obj=dias;
            var input = document.getElementsByName(`${key}-0`); 
            var newValue= input[0].value;
            for(var i=0; i<obj.length; i++){
                const llave=Object.keys(horas);
                var inputs = document.getElementsByName(`${key}-${obj[i].nombre.id}`);
                obj[i].horas[llave[key]]=newValue; 
                inputs[0].value=newValue; 
            }
            setDias(obj);
        }
        
    }
    
    return (
        <form id={ elementSelect ? 'EditarTurno' : 'AgregarTurno' } onSubmit={ elementSelect ? actualizar : enviar }>
            
            <div className="row">
                <div className="col-md-12 col-sm-12">
                    <InputValidation title="Nombre del Turno *" type="nombreTurno" placeholder="Escriba el nombre del Turno"
                        tooltipText={`Máximo 100 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipNomTur'} maxLength={100}
                        refValue={nombreTurnoRef} defaultValue={ elementSelect ? elementSelect.turnoNom : '' } />
                </div>
            </div>
 
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    {
                        tipoJornadas
                        ?
                        <SelectValidation title="Tipo de Jornada" arrayOpciones={tipoJornadas} keyOpcion="nombre" 
                            refValue={jornadaIdRef} defaultValue={ elementSelect ? elementSelect.jornadaId : ''} required/>
                        :null
                    } 
                </div>
                <div className="col-md-6 col-sm-12">
                    {
                        diasSelect
                        ?
                        diasSelect.length>0
                        ?
                        <SelectValidation title="Agregar Día" arrayOpciones={diasSelect} keyOpcion="nombre" 
                            refValue={diaRef} onChangeExecute={addDia} optional={true}/>
                        :
                            <div className={`form-group`}>
                                <label>{ "Agregar Día" } 
                                </label>
                                <select className={ `form-control`}
                                    disabled={true}>
                                    <optgroup label={"Agregar Día"}>
                                    <option value=""> NOTA: Se habilita al eliminar un día </option> 
                                    </optgroup>
                                </select>
                            </div>
                        :null
                    } 
                </div>
            </div>
            <table style={{width:"100%", margin:"30px 0px 30px 0px"}}>
                <thead>
                        <tr>
                            <th> </th>
                            <th className="label" style={{fontSize:'12px'}}>Entrada *  
                                <Tooltip title="Hora de Entrada. Formato 24 hrs." placement="right">
                                        <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span> 
                                    </Tooltip>
                                <IconButton onClick={()=>alertReplicateData(0)} style={{marginLeft:"30px"}}>
                                    <Tooltip title="Aplicar mismo horario" placement="right" >
                                        <ArrowDropDownIcon fontSize="small"/>
                                    </Tooltip>
                                </IconButton></th>
                            <th className="label" style={{fontSize:'12px'}}>Salida Comida
                                    <Tooltip title="Hora de Salida a Comer. Formato 24 hrs." placement="right">
                                        <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span> 
                                    </Tooltip>
                                <IconButton onClick={()=>alertReplicateData(1)} style={{marginLeft:"30px"}}>
                                    <Tooltip title="Aplicar mismo horario" placement="right" >
                                        <ArrowDropDownIcon fontSize="small"/>
                                    </Tooltip>
                                </IconButton></th>
                            <th className="label" style={{fontSize:'12px'}}>Regreso Comida
                                <Tooltip title="Hora de Regreso de Comer. Formato 24 hrs." placement="right">
                                        <span className="spanInfo"><InfoRoundedIcon color="disabled" /></span> 
                                </Tooltip>
                                <IconButton onClick={()=>alertReplicateData(2)} style={{marginLeft:"30px"}}>
                                    <Tooltip title="Aplicar mismo horario" placement="right" >
                                        <ArrowDropDownIcon fontSize="small"/>
                                    </Tooltip>
                                </IconButton></th>
                            <th className="label" style={{fontSize:'12px'}}>Salida *
                                <Tooltip title="Hora de Salida. Formato 24 hrs." placement="right">
                                    <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span> 
                                </Tooltip>
                                <IconButton onClick={()=>alertReplicateData(3)} style={{marginLeft:"30px"}}>
                                    <Tooltip title="Aplicar mismo horario" placement="right" >
                                        <ArrowDropDownIcon fontSize="small"/>
                                    </Tooltip>
                                </IconButton></th> 
                        </tr>
                </thead>
                <tbody>
                        {getDias()} 
                </tbody>
            </table>
            {error}
            {errorDias}
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={deleteRow ? del : replicateData} />
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </form>
    );
};

export default FormTurnos;
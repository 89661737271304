import React, { createRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../../Services/Validation/InputValidation';

const FormPlaza = ({ enviar, actualizar, data }) => {

    const claveRef = createRef('');

    const agregar = e => {
        e.preventDefault();
        if(validarSubmit('Plaza')) {
            const clave = claveRef.current.value;
            enviar({ clave, nivel: data.nivel, id: data.id});
        }
    };

    const editar = e => {
        e.preventDefault();
        if(validarSubmit('Plaza')) {
            const clave = claveRef.current.value;
            actualizar({ plazaclave: clave, id: data.id, plazaidsup: data.plazaidsup, plazanivel: data.plazanivel, plazasecuencia: data.plazasecuencia,  plazadisponible: true  });
        }
    };

    return (
        <form id="Plaza" onSubmit={ enviar ? agregar : editar } >
            <InputValidation title="Número Único de Plaza (NUP)" type="clavePlaza" placeholder="Escriba el NUP"
                refValue={claveRef} defaultValue={ actualizar ? data.plazaclave : '' } 
                tooltipText="De 2 a 20 letras y/o números" tooltipName="plazaCve" maxLength={20} alfanumeric required/>
            <Button variant="contained" className="btn-color" type="submit">{ enviar ? 'Agregar' : 'Actualizar' }</Button>
        </form>
    );
}
 
export default FormPlaza;
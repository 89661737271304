import React, { useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import './styles.css';
import TerminosyCondiciones from './TerminosyCondiciones';
import AvisoPrivacidad from './AvisoPrivacidad';

const ModalTerminosPrivacidad = ({ open, setOpen, actionAcept, actionCancel }) => {
    const [ activo, setActivo ] = useState( 'terminos' );
    return (
        <Dialog open={open} onClose={() => setOpen(false)} disableEscapeKeyDown={true} disableBackdropClick={true} className="contenedorTerminosPrivacidad">
            <DialogTitle>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-dialog">
                        <div className={`tab-modal ${activo === 'terminos' ? 'tab-modal-active' : ''}`} onClick={() => setActivo('terminos')}>
                            Términos y Condiciones
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 col-dialog">
                        <div className={`tab-modal ${activo === 'privacidad' ? 'tab-modal-active' : ''}`} onClick={() => setActivo('privacidad')}>
                            Aviso de Privacidad
                        </div>
                    </div>
                </div>
            </DialogTitle>

            <DialogContent>
                <div style={{ display: activo === 'terminos' ? 'block' : 'none' }}>
                    <TerminosyCondiciones />
                </div>
                <div style={{ display: activo === 'privacidad' ? 'block' : 'none' }}>
                    <AvisoPrivacidad />
                </div>
            </DialogContent>

            <DialogActions>
                { actionAcept && actionCancel ?
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Button variant="contained" className="btn-color" onClick={() => actionAcept()}> Aceptar </Button>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Button variant="outlined" className="btn-outline" onClick={() => actionCancel()}> Cancelar </Button>
                        </div>
                    </div>
                    :
                    <Button variant="contained" className="btn-color" onClick={() => setOpen(false)}> Aceptar </Button>
                }
            </DialogActions>
        </Dialog>
    );
};

export default ModalTerminosPrivacidad;
import React from 'react';

import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_rh, registro_no_guardado } from '../../../../../../../Constants/Constants';
import AlertErrorDescarga from '../../CargaMasivaDatos/CargasMasivas/AlertErrorDescarga';

/* ---------------------- Funciones para obtener informacion necesaria para cada carga de archivos ---------------------- */
export function obtenerInformacionExcel( idModulo, empleados ){
    switch( idModulo ) {
        case 41:
            console.log( 'Variables' );
            return formatoExcelVariables( empleados );
        case 68:
            console.log( 'Fijos' );
            return formatoExcelFijos( empleados );

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const formatoExcelVariables = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'NOMBRE': (item.nombreCompleto !== null) ? item.nombreCompleto : '',

        'CLAVE DE LA NÓMINA': item.nominaClave !== null ? item.nominaClave : '',
        'NOMBRE DE LA NÓMINA': (item.nominaNombre !== null) ? item.nominaNombre : '',

        'FECHA': '',
        'CANTIDAD': '',
        'INDICADOR': '',
        'TIPO DE ACUMULADO': '',
        'IMPORTE': '',
    }));
}

const formatoExcelFijos = ( empleados ) =>  {
    return empleados.map( item => ({
        'NUE': (item.empleadoClave !== null) ? item.empleadoClave : '',
        'NUP': (item.plazaClave !== null) ? item.plazaClave : '',
        'RFC': (item.empleadoRfc !== null) ? item.empleadoRfc : '',
        'NOMBRE': (item.nombreCompleto !== null) ? item.nombreCompleto : '',

        'CLAVE DE LA NÓMINA': item.nominaClave !== null ? item.nominaClave : '',
        'NOMBRE DE LA NÓMINA': (item.nominaNombre !== null) ? item.nominaNombre : '',

        'FECHA INICIAL': '',
        'FECHA FINAL': '',
        'CANTIDAD': '',
        'INDICADOR': '',
        'TIPO DE ACUMULADO': '',
        'IMPORTE': '',
    }));
}

/* -------------------------------- Funciones para carga de archivos & axios a servicios -------------------------------- */
export function axiosExcel( idModulo, archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ){
    switch( idModulo ) {
        case 41:
            console.log( 'Variables' );
            axiosExcelVariables( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
            break;
        case 68:
            console.log( 'Fijos' );
            axiosExcelFijos( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable );
            break;

        default:
            console.log( 'No Encontrado' );
            break;
    }
}

const axiosExcelVariables = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ) => {
    axios.post( `${servicios_grupogack_rh}/movimientos/excel/test/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Variables" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( registro_no_guardado );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}

const axiosExcelFijos = ( archivoExcel, setProgreso, setDocumentoExcel, setError, errorTable, successTable ) => {
    axios.post( `${servicios_grupogack_rh}/movimientos/fijo/excel/insert/`, archivoExcel, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    successTable( 'El archivo se cargó correctamente' );
                    setProgreso( 0 );
                    setDocumentoExcel( null );
                    break;
                case 400: //La peticion No Cumple con los Datos
                    setProgreso( 0 );
                    setError( <AlertErrorDescarga data={respuesta.data} fileName="Error_Fijos" /> );
                    break;
                default:
                    setProgreso( 0 );
                    errorTable( registro_no_guardado );
                    break;
            }
        })
        .catch( error => {
            setProgreso( 0 );
            errorTable( registro_no_guardado );
        })
}
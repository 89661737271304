import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormContratosRelLab from './FormContratosRelLab';
import { JsonContratoRelLab } from '../../../Helpers/JsonToOneLevel';

const ModalAddContratosRelLab = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    }

    const enviarContratosRelLab = info => {
        Axios.post( `${servicios_grupogack_catalogo}s/contrato/sao/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(JsonContratoRelLab(res.data.dataResponse));
                    setOpenAdd( false );
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    return (
        <ModalService title="Nuevo Contrato de Relación Laboral" parentNode={nombreModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormContratosRelLab
                        enviarContratosRelLab={enviarContratosRelLab}
                        error={error}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddContratosRelLab;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from './../../../ModalService/ModalService';
import FormEmpleados from './FormEmpleados';


const ModalAddEmpleados = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    };

    const enviarEmpleado = (datosEmpleado, fechaIngreso, datosPuesto, datosZonaPago, datosCPresupuestal, datosTurno, datosNomina, datosPlaza, formasPagoSelected, empPuestoFecIni, infoSindicato, depEconomicosSaved, pensionesAlimSaved, llamadasEmergenciaSaved, domicilio, datosEscolaridad, areaNom, infoFotografia, empleadoIndicadores, empleadoContrato, empFecAgui, empFecNom, expLaboralSaved, pasatiempoSaved, regPatId) => {
        const empleado = datosEmpleado;
        axios.post( `${servicios_grupogack_rh}/empleado/`, empleado, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const antiguedad = { empleadoid: res.data.dataResponse.id, empantfecini: fechaIngreso }
                    axios.post( `${servicios_grupogack_rh}/empleado/antiguedad/`, antiguedad, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
        
                                break;
                            default:
                                errorTable(`Antigüedad ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Antigüedad ${registro_no_guardado}`));

                    const puesto = { empId: res.data.dataResponse.id, puestoId: datosPuesto.puestoId, empPuestoFecIni: datosPuesto.empPuestoFecIni }
                    axios.post( `${servicios_grupogack_rh}/empleado/puesto/`, puesto, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errorTable(`Puesto ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Puesto ${registro_no_guardado}`));

                    const zonaPago = { empId: res.data.dataResponse.id, domciaId: datosZonaPago.domciaId, empzpfecini: datosZonaPago.empzpfecini }
                    axios.post( `${servicios_grupogack_rh}/empleado/zonapago/`, zonaPago, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                
                                break;
                            default:
                                errorTable(`Zona de Pago ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Zona de Pago ${registro_no_guardado}`));

                    const presupuestal = { empleadoid: res.data.dataResponse.id, cpresupuestalid: datosCPresupuestal.cpresupuestalid, empcpresfecini: datosCPresupuestal.empcpresfecini }
                    axios.post( `${servicios_grupogack_rh}/empleado/presupuestal/`, presupuestal, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                
                                break;
                            default:
                                errorTable(`Clave Prseupuestal ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Clave Prseupuestal ${registro_no_guardado}`));

                    const turno = { empId: res.data.dataResponse.id, turnoId: datosTurno.turnoId, fecini: datosTurno.fecini }
                    axios.post( `${servicios_grupogack_rh}/empleado/turno/`, turno, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errorTable(`Turno ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Turno ${registro_no_guardado}`));

                    const nomina = { empleadoid: res.data.dataResponse.id, nominaid: datosNomina.nominaid, empnominafecini: datosNomina.empnominafecini }
                    axios.post( `${servicios_grupogack_rh}/empleado/nomina/`, nomina, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                
                                break;
                            default:
                                errorTable(`Nómina ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Nómina ${registro_no_guardado}`));

                    const plaza = { empId: res.data.dataResponse.id, plazaId: datosPlaza.plazaId, empFecIni: datosPlaza.empFecIni };
                    axios.post( `${servicios_grupogack_rh}/empleado/plaza/`, plaza, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errorTable(`Plaza ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Plaza ${registro_no_guardado}`));

                    const formasPago = formasPagoSelected.map(fPago => ({ empleado: res.data.dataResponse.id, formapago: fPago.formapago }));
                    axios.post(`${servicios_grupogack_rh}/empleado/formapago/`, formasPago, headersAuth())
                    .then(respuesta => {
                        switch(respuesta.data.status) {
                            case 200:
                                if(formasPagoSelected.length){
                                    for(let i = 0; i < formasPagoSelected.length; i++) {
                                        if(formasPagoSelected[i].formapago > 2 && formasPagoSelected[i].formapago < 10) {
                                            const array = respuesta.data.dataResponse.map(item => ({ id: item.id, formapago: item.formapago.id }));
                                            const id = array.find(res => res.formapago === formasPagoSelected[i].formapago );
                                            const cuenta = {
                                                                empleadoFormaPago: id.id,
                                                                formaPago: formasPagoSelected[i].formapago,
                                                                banco: formasPagoSelected[i].bancoId,
                                                                clabe: formasPagoSelected[i].cbeInterbancaria,
                                                                nocuenta: formasPagoSelected[i].numCuenta
                                                            };
                                            axios.post(`${servicios_grupogack_rh}/empleado/cuentahabiente/`, cuenta, headersAuth())
                                            .then(res => {
                                                switch(res.data.status) {
                                                    case 200:
                        
                                                        break;
                                                    default:
                                                        errorTable(`Forma de Pago / Cuenta ${registro_no_guardado}`);
                                                        break;
                                                }
                                            })
                                            .catch(error => {errorTable(`Forma de Pago / Cuenta ${registro_no_guardado}`); console.log(error)});
                                        }
                                    }
                                }

                                break;
                            default:
                                errorTable(`Forma de Pago ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => {errorTable(`Forma de Pago ${registro_no_guardado}`, console.log(error))});

                    const status = { empId: res.data.dataResponse.id, empPuestoFecIni };
                    axios.post( `${servicios_grupogack_rh}/empleado/status/`, status, headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                break;
                            default:
                                errorTable(`Status ${registro_no_guardado}`);
                                break;
                        }
                    })
                    .catch(error => errorTable(`Status ${registro_no_guardado}`));

                    if(empleadoIndicadores) {
                        axios.post(`${servicios_grupogack_rh}/empleado/indicadores/`, { Empleado: [{ EmpleadoId: res.data.dataResponse.id, Indicadores:empleadoIndicadores }] }, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Indicadores ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Indicadores ${registro_no_guardado}`));
                    }

                    //agrega tipo de Contrato
                    if(empleadoContrato !== null) {
                        const info = { ...empleadoContrato, empId: res.data.dataResponse.id };
                        axios.post(`${servicios_grupogack_rh}/empleado/contrato/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Contrato ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Contrato ${registro_no_guardado}`));
                    }
                    
                    //agrega fecha de aguinaldo
                    if(empFecAgui !== null) {
                        const info = { empId: res.data.dataResponse.id, empFecAgui: empFecAgui };
                        axios.post(`${servicios_grupogack_rh}/empleado/aguinaldo/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Fecha de Aguinaldo ${registro_no_guardado}`));
                    }
                    
                    //agrega fecha de nombramiento
                    if(empFecNom !== null) {
                        const info = { empId: res.data.dataResponse.id, empFecNom: empFecNom };
                        axios.post(`${servicios_grupogack_rh}/empleado/nombramiento/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Fecha de Nombramiento ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Fecha de Nombramiento ${registro_no_guardado}`));
                    }

                    //agrega reg patronal
                    if(regPatId !== null) {
                        const requestRegPatronal = { empId: res.data.dataResponse.id, regPatId };
                        axios.post(`${servicios_grupogack_rh}/empleado/sindicatos/`, requestRegPatronal, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Registro Patronal ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Registro Patronal ${registro_no_guardado}`));
                    }

                    //agrega sindicato
                    if(infoSindicato !== null) {
                        const requestSindicato = { ...infoSindicato, empId: res.data.dataResponse.id };
                        axios.post(`${servicios_grupogack_rh}/empleado/sindicatos/`, requestSindicato, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Sindicato ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Sindicato ${registro_no_guardado}`));
                    }

                    //agrega dependientes económicos
                    if(depEconomicosSaved.length > 0) {
                        axios.post( `${servicios_grupogack_rh}/empleado/dependiente/economico/`, depEconomicosSaved, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    
                                    break;
                                default:
                                    errorTable(`Dependientes Económicos ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Dependientes Económicos ${registro_no_guardado}`));
                    }

                    //agrega pensiones alimenticias
                    if(pensionesAlimSaved.length > 0) {
                        const pensiones = pensionesAlimSaved.map(penAlim => (
                            (penAlim.fechaFin)
                            ?   {
                                    folio: penAlim.folio,
                                    empleado: res.data.dataResponse.id,
                                    nombre: penAlim.nombre,
                                    apellidoPat: penAlim.apellidoPat,
                                    apellidoMat: penAlim.apellidoMat,
                                    tipoCuota: penAlim.tipoCuota,
                                    montoPorc: penAlim.montoPorc,
                                    fechaIni: penAlim.fechaIni,
                                    fechaFin: penAlim.fechaFin
                                }
                            :   {
                                    folio: penAlim.folio,
                                    empleado: res.data.dataResponse.id,
                                    nombre: penAlim.nombre,
                                    apellidoPat: penAlim.apellidoPat,
                                    apellidoMat: penAlim.apellidoMat,
                                    tipoCuota: penAlim.tipoCuota,
                                    montoPorc: penAlim.montoPorc,
                                    fechaIni: penAlim.fechaIni,
                                }
                        ));

                        axios.post(`${servicios_grupogack_rh}/empleado/pension/`, pensiones, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    
                                    break;
                                default:
                                    errorTable(`Pensiones Alimenticias ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Pensiones Alimenticias ${registro_no_guardado}`));
                    }

                    //agrega números de emergencia
                    if(llamadasEmergenciaSaved.length > 0) {
                        const numEmergencia = llamadasEmergenciaSaved.map(numEme => (
                            (numEme.calle & numEme.numeroExt & numEme.numeroInt)
                            ?   {
                                    empleado: res.data.dataResponse.id,
                                    nombre: numEme.nombre,
                                    apellidoPat: numEme.apellidoPat,
                                    apellidoMat: numEme.apellidoMat,
                                    telefonoFijo: numEme.telefonoFijo,
                                    telefonoCelular: numEme.telefonoCelular,
                                    parentesco: numEme.parentesco,
                                    cp: numEme.coloniaId,
                                    calle: numEme.calle,
                                    numeroExt: numEme.numeroExt,
                                    numeroInt: numEme.numeroInt
                                }
                            :   (numEme.calle & numEme.numeroExt)
                                ?   {
                                        empleado: res.data.dataResponse.id,
                                        nombre: numEme.nombre,
                                        apellidoPat: numEme.apellidoPat,
                                        apellidoMat: numEme.apellidoMat,
                                        telefonoFijo: numEme.telefonoFijo,
                                        telefonoCelular: numEme.telefonoCelular,
                                        parentesco: numEme.parentesco,
                                        cp: numEme.coloniaId,
                                        calle: numEme.calle,
                                        numeroExt: numEme.numeroExt
                                    }
                                :   (numEme.calle)
                                    ?   {
                                            empleado: res.data.dataResponse.id,
                                            nombre: numEme.nombre,
                                            apellidoPat: numEme.apellidoPat,
                                            apellidoMat: numEme.apellidoMat,
                                            telefonoFijo: numEme.telefonoFijo,
                                            telefonoCelular: numEme.telefonoCelular,
                                            parentesco: numEme.parentesco,
                                            cp: numEme.coloniaId,
                                            calle: numEme.calle
                                        }
                                    :   {
                                            empleado: res.data.dataResponse.id,
                                            nombre: numEme.nombre,
                                            apellidoPat: numEme.apellidoPat,
                                            apellidoMat: numEme.apellidoMat,
                                            telefonoFijo: numEme.telefonoFijo,
                                            telefonoCelular: numEme.telefonoCelular,
                                            parentesco: numEme.parentesco,
                                            cp: numEme.coloniaId
                                        }
                        ));

                        axios.post(`${servicios_grupogack_rh}/empleado/llamada/emergencia/`, numEmergencia, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    
                                    break;
                                default:
                                    errorTable(`Números de Emergencia ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Números de Emergencia ${registro_no_guardado}`));
                    }
                    
                    //agrega domicilio
                    if(domicilio.calle.length > 2 && domicilio.cpId > 0) {
                        const domicilioSend = { empId: res.data.dataResponse.id, calle: domicilio.calle, numint: domicilio.numint, numext: domicilio.numext, cpId: domicilio.cpId }
                        axios.post(`${servicios_grupogack_rh}/empleado/domicilio/`, domicilioSend, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    
                                    break;
                                default:
                                    errorTable(`Domicilio ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Domicilio ${registro_no_guardado}`));
                    }

                    //agrega escolaridad 
                    if(datosEscolaridad.escolaridad) {
                        let escolaridadSend = { empleado: res.data.dataResponse.id, escolaridad: datosEscolaridad.escolaridad };

                        if(datosEscolaridad.empleadoCarrera.length > 2) {
                            escolaridadSend = { ...escolaridadSend, empleadoCarrera: datosEscolaridad.empleadoCarrera };
                        }
                        
                        if(datosEscolaridad.empleadoCedula.length > 2) {
                            escolaridadSend = { ...escolaridadSend, empleadoCedula: datosEscolaridad.empleadoCedula };
                        }

                        axios.post(`${servicios_grupogack_rh}/empleado/escolaridad/`, escolaridadSend, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    
                                    break;
                                default:
                                    errorTable(`Escolaridad ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Escolaridad ${registro_no_guardado}`));
                    }

                    if(infoFotografia) {
                        let info = infoFotografia;
                        info = { ...info, empleado: res.data.dataResponse.id };
                        axios.post(`${servicios_grupogack_rh}/documentos/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status){
                                case 200:
                                    break;
                                default:
                                    errorTable(registro_no_guardado);
                                    break;
                            }
                        })
                        .catch( error => errorTable(registro_no_guardado));
                    }

                    if(expLaboralSaved.length > 0) {
                        const agregaIDEmpleado = expLab => {
                            const arrayExpLaboral = expLab.map(expL => (
                                    {
                                        empId: res.data.dataResponse.id,
                                        empNom: expL.empNom,
                                        puestoExp: expL.puestoExp,
                                        dmes: expL.dmes,
                                        danio: expL.danio,
                                        hmes: expL.hmes,
                                        hanio: expL.hanio,
                                        jefe: expL.jefe,
                                        tel: expL.tel
                                    }                        
                                ));
                            
                            return arrayExpLaboral;
                        };

                        const info = agregaIDEmpleado(expLaboralSaved);
                        axios.post(`${servicios_grupogack_rh}/empleado/experiencia/`, { empleados: info }, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Experiencia Laboral ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Experiencia Laboral ${registro_no_guardado}`));
                    }

                    if(pasatiempoSaved.length > 0) {
                        const convierteArray = pasatiempos => {

                            return pasatiempos.map(pasa => pasa.id); 
                        };

                        const arrayPasatiempos = convierteArray(pasatiempoSaved);

                        const info = { empId: res.data.dataResponse.id, pasatiempos: arrayPasatiempos };
                        axios.post(`${servicios_grupogack_rh}/empleado/pasatiempo/`, info, headersAuth())
                        .then(res => {
                            switch(res.data.status) {
                                case 200:
                                    break;
                                default:
                                    errorTable(`Pasatiempo ${registro_no_guardado}`);
                                    break;
                            }
                        })
                        .catch(error => errorTable(`Pasatiempo ${registro_no_guardado}`));
                    }

                    agregar(
                        {
                            id: res.data.dataResponse.id,
                            areaNom: areaNom,
                            cpresupuestalClave: datosCPresupuestal.cpresupuestalclave,
                            cpresupuestalNom: datosCPresupuestal.cpresupuestalNom,
                            empleadoClave: res.data.dataResponse.empleadoclave,
                            nombreCompleto: res.data.dataResponse.nombreCompleto,
                            empleadoNom: res.data.dataResponse.empleadonom,
                            empleadoPat: res.data.dataResponse.empleadopat,
                            empleadoMat: res.data.dataResponse.empleadomat,
                            empleadoFecnac: res.data.dataResponse.empleadofecnac,
                            empleadoCurp: res.data.dataResponse.empleadocurp,
                            empleadoRfc: res.data.dataResponse.empleadorfc,
                            empleadoNss: res.data.dataResponse.empleadonss,
                            empantFecini: fechaIngreso.empantfecini,
                            empstatusFecini: empPuestoFecIni,
                            nominaClave: datosNomina.nominaClave,
                            nominaNomCorto: datosNomina.nominaNomCorto,
                            nominaNombre: datosNomina.nominaNom,
                            puestoId: datosPuesto.puestoId,
                            puestoCve: datosPuesto.puestoCve,
                            puestoNom: datosPuesto.puestoNom,
                            tipojornadaClave: datosTurno.jornadaClave,
                            tipojornadaNom: datosTurno.jornadaNombre,
                            plazaId: datosPlaza.plazaId,
                            plazaClave: datosPlaza.plazaclave,
                            turnoId: datosTurno.turnoId,
                            turnoNom: datosTurno.turnoNom,
                            domciaId: datosZonaPago.domciaId,
                            domciaCalle: datosZonaPago.domciaCalle,
                            domciaNumext: datosZonaPago.domciaNumext,
                            domciaNumint: datosZonaPago.zonaPagoNumInt
                        }
                    );
                        
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => {errorTable(`Empleado ${registro_no_guardado}`); console.log(error)});
    }

    return (
        <ModalService 
            title="Nuevo Empleado"
            parentNode={nombreModulo}
            isOpen={openAdd}
            onClose={() => cerrarModal()}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <FormEmpleados
                        enviarEmpleado={enviarEmpleado}
                        error={error}
                        setError={setError}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddEmpleados;
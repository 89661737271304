import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_guardado, servicios_grupogack_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadoresGenerales } from '../../../Helpers/JsonToOneLevel';
import ModalService from './../../../ModalService/ModalService';
import FormIndicadoresGenerales from './FormIndicadoresGenerales';

const ModalAddIndicadoresGenerales = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );
    const [ numeroVista, setNumeroVista ]= useState(1);
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }  

    const enviarIndicadorGeneral = (indicadorGeneral, configBaseData, indicadorParametros) => {
        const info = indicadorGeneral;
        Axios.post( `${servicios_grupogack_catalogo}/indicador`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const nuevoIndicador = JsonIndicadoresGenerales(res.data.dataResponse);
                    if(configBaseData!==null) enviarIndicadorBase(nuevoIndicador.id, configBaseData);
                    if(indicadorParametros !== null) enviarIndicadorParametros(nuevoIndicador.id, indicadorParametros);
                    agregar(nuevoIndicador);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    const enviarIndicadorBase = async (idIndicador, configBaseData) => { 
        const newData = configBaseData.basesIndicadores[0].map(element => {return {indicadorId: idIndicador, baseId: element.baseId, operacion: element.operacion }});
        
        await Axios.post( `${servicios_grupogack_nomina}/bases/indicadores/`, {basesIndicadores:newData}, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(registro_no_guardado+" en Configuración Base");
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    const enviarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {indicadorId: idIndicador, zonaId: indicadorParametros.zonaId, indicador: indicadorParametros.indicador, topVeces: indicadorParametros.topVeces };
        
        await Axios.post( `${servicios_grupogack_catalogo}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(registro_no_guardado+" en Parametros de Concepto");
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    const vista = (numero) =>{
        setNumeroVista(numero);
    }
    
    return (

        <ModalService title={numeroVista===1 ? "Nuevo Concepto General" : "Configuración de Concepto"} parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormIndicadoresGenerales
                        enviarIndicadorGeneral={enviarIndicadorGeneral}
                        error={error}
                        vista={vista}
                        idModulo={nombreModulo}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddIndicadoresGenerales;
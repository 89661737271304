import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_url } from '../../../../../../../Constants/Constants';
import { ArrayJsonUsuarios } from '../../../../Helpers/JsonToOneLevel';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import Modulo from './Modulo';
import Button from '@material-ui/core/Button';
import AlertForm from '../../../../../../../Services/AlertForm';
import { obtenerAccesos, eliminarSuperior } from './CollapseMaterial/HelperModulos';
import CopiarAccesos from './CopiarAccesos/CopiarAccesos';

const AsignacionModulos = ({ error, setError, menuUsuario, guardarAccesosMenu, informacion }) => {
    const [ elementoActivo, setElementoActivo ] = useState( '' );
    /* ------------------------- Axios para obtener los modulos del usuario superior ------------------------ */
    const [ modulosSuperior, setModulosSuperior ] = useState( null );
    const [ accesosMenu, setAccesosMenu ] = useState( null );
    const [ usuarios, setUsuarios ] = useState( null );
    useEffect(() => {
        axiosModulosSuperior();
        setAccesosMenu( obtenerAccesos(menuUsuario) );
        axiosUsuarios();
        // eslint-disable-next-line
    }, []);

    const axiosModulosSuperior = () => {
        axios.get( `${servicios_grupogack_url}/menu`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setModulosSuperior( respuesta.data.dataResponse.menu );
                        break;
                    case 404:
                        //setModulosSuperior([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosUsuarios = () => {
        axios.get( `${servicios_grupogack_url}/all/subordinates`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios( ArrayJsonUsuarios(respuesta.data.dataResponse) );
                        break;
                    case 404:
                        setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------ Funcion que permite consumir el servicio AsignarModulos&Permisos ------------------ */
    const guardarAccesos = () => {
        if( accesosMenu.length > 0 ){
            setError( null );
            console.log( eliminarSuperior( accesosMenu ) );
            guardarAccesosMenu( { accesosMenu: eliminarSuperior( accesosMenu ) }, informacion.usuarioID );
        }else{
            setError( <AlertForm message={ 'Asegúrate de haber seleccionado permisos' } /> );
        }
    }
    /* ------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            { accesosMenu && modulosSuperior && usuarios ?
                <React.Fragment>
                    <div className="paddingCopiarAccesos"> <b> Usuario: </b> {informacion.username} </div>
                    { usuarios.length > 1 &&
                        <CopiarAccesos usuarios={ usuarios.filter(usuario => usuario.id !== informacion.usuarioID) } setAccesosMenu={setAccesosMenu}/> }

                    { modulosSuperior.map( moduloPadre => {
                        if( moduloPadre.nombre !== 'Dashboard' && moduloPadre.nombre !== 'Ayuda' && moduloPadre.children ){
                            return <Modulo key={moduloPadre.id} moduloPadre={moduloPadre} accesosMenu={accesosMenu} setAccesosMenu={setAccesosMenu}
                                elementoActivo={elementoActivo} setElementoActivo={setElementoActivo}/>
                        }else{
                            return '';
                        }
                    })}
                    { error }
                    <Button variant="contained" className="btn-color" onClick={ () => guardarAccesos() }> Guardar </Button>
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default AsignacionModulos;
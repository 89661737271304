import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_nomina, pensiones_ } from '../../../../../../Constants/Constants';
import { ArrayJsonPensiones } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Pensiones = ({ id, permisos }) => {
    const [ pensiones, setPensiones ] = useState( null );
    useEffect(() => {
        axiosPensiones();
    }, []);

    const axiosPensiones = () => {
        axios.get( `${servicios_grupogack_nomina}s/pensiones/getall/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setPensiones( ArrayJsonPensiones(respuesta.data.dataResponse) );
                        break;
                    case 404: //No hay datos para mostrar
                        setPensiones([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={ id }>
            { pensiones ?
                <DataTableService id={id} title={ pensiones_ } permisos={permisos}
                    columns={[
                        { field: "fechainiTab", title: "Fecha Inicial" },
                        { field: "fechafinTab", title: "Fecha Final" },
                        { field: "pensionFpo", title: "% Fondo de Pensión Obrero", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "pensionFpp", title: "% Fondo de Pensión Patronal", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                    ]}
                    data={ pensiones } />
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default Pensiones;
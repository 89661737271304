import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_url } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import FormularioUsuario from './FormularioUsuario';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

/* Elementos para la Asignación de Módulos y Permisos */
import Button from '@material-ui/core/Button';
import ModalAsignacionModulos from './AsignacionModulos/ModalAsignacionModulos';
import ModalAsignacionNomina from './AsignacionNomina/ModalAsignacionNomina';

const InformacionUsuario = ({ error, userID, informacion, actualizarUsuario, errorTable, successTable, idModulo }) => {
    const [ openAsign, setOpenAsign ] = useState( false );
    const [ openNomina, setOpenNomina ] = useState( false );
    /* --------------------- Axios para obtener la información del usuario seleccionado --------------------- */
    const [ usuario, setUsuario ] = useState( null );
    useEffect(() => {
        axiosUsuario();
        // eslint-disable-next-line
    }, []);

    const axiosUsuario = () => {
        setUsuario( null );
        axios.get( `${servicios_grupogack_url}/info/and/permissions/${userID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setUsuario( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setUsuario([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            { usuario ?
                <React.Fragment>
                    <FormularioUsuario
                        error={error}
                        elementSelect={usuario.perfil.datos}
                        actualizarUsuario={actualizarUsuario}
                        informacion={informacion}
                        usernameEmail={usuario.perfil.username}
                    />
                    <div className="row mt-2">
                        <div className="col-md-6 col-sm-12">
                            <Button className="btn-third btn-with" onClick={() => setOpenAsign( true )}> Asignar Módulos </Button>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <Button className="btn-third btn-with" onClick={() => setOpenNomina( true )}> Asignar Nómina </Button>
                        </div>
                    </div>
                    <ModalAsignacionModulos openAsign={openAsign} setOpenAsign={setOpenAsign} menuUsuario={usuario.menu} errorTable={errorTable} successTable={successTable}
                        informacion={{ usuarioID: usuario.perfil.id, username: usuario.perfil.username, usuarios: informacion.usuarios }} axiosUsuario={axiosUsuario} idModulo={idModulo}/>
                    <ModalAsignacionNomina openNomina={openNomina} setOpenNomina={setOpenNomina} errorTable={errorTable} successTable={successTable}
                        informacion={{ usuarioID: usuario.perfil.id, username: usuario.perfil.username }} idModulo={idModulo}/>
                </React.Fragment>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default InformacionUsuario;
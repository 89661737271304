import React,{Component} from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import './Styles.css'

export default class Alertwarning extends Component {
    /*
      Para utlizar la alerta de warnning , esta debe de recibir por props  
      show={} ,propiedad para mostrar el alert solo puede ser true 
      SetopenALert={} ,propiedad para ocultar el alert solo puede ser false
      text="" ,Esta cadena se muestra en el cuerpo de la alerta
      textButtonAceptar="" , Esta cadena se mostrará en el cuerpo del boton aceptar
      textButtonCancelar="" , Esta dadena se mostrará en el cuerpo del boton cancelar
      action={}, esta propiedad recibe la funcion a ejecutar, opcional
      valor={} esta propiedad es opcional y es el valor que se le quier pasara la función
      Por ejemplo :
      <Alertwarning show={openAlertW} SetopenALert={SetopenALertW} title="Warning" text="Hola desde otro componente 1"  textButtonAceptar=" Aceptar 1" textButtonCancelar=" Cancelar 1" action={namefunction} value={valor}/>
    */

    Confirm(e){
        //metodo pro props
        this.hideAlert();
        //si existe la propiedad this.props.action, se ejecutartá
        if(this.props.action) { 
          if(this.props.value) {
            this.props.action(this.props.value);
          } else {
            this.props.action(e);
          }
        }
    }

    Cancel(){
        this.hideAlert();
    }

    hideAlert() {
      this.props.SetopenALert(false);
    }
  
    render() {
      return (
        
            (this.props.show===false)?null:
            <SweetAlert 
                warning
                showCancel   
                //titulo del modal
                title={this.props.title ? this.props.title :"¿Estás Seguro?"}
                customButtons={
                  <React.Fragment>
                    <button  className="btn-color-alert" onClick={() => this.Confirm()}>{this.props.textButtonAceptar}</button>
                    { (this.props.textButtonCancelar) &&
                      <button className="btn-outline-alert-cancel" onClick={()=>this.Cancel()}> {this.props.textButtonCancelar} </button>
                    }
                  </React.Fragment>
                }
                /*Metodos que se ejecutan en cual de las 2 opciones */
                onConfirm={() => this.Confirm()}
                onCancel={()=>this.Cancel()}
            >
                {this.props.text}
            </SweetAlert> 
      );
    }
  }
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_url, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import AlertForm from '../../../../../../../Services/AlertForm';
import AsignacionModulos from './AsignacionModulos';

const ModalAsignacionModulos = ({ openAsign, setOpenAsign, menuUsuario, errorTable, successTable, informacion, axiosUsuario, idModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAsign( false );
        setError( null );
    }

    const guardarAccesosMenu = ( accesosMenu, usuarioID ) => {
        axios.post( `${servicios_grupogack_url}/menu/${usuarioID}`, accesosMenu, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        successTable( 'Registro Actualizado' );
                        axiosUsuario(); //Para volver a obtener la informacion actualizada del usuario
                        setOpenAsign( false );
                        break;
                    case 400:
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Asignar Módulos y Permisos" parentNode={idModulo} minWidth="40%" maxWidth="40%"
            isOpen={ openAsign } onClose={ () => cerrarModal() } >
                <AsignacionModulos
                    error={error}
                    setError={setError}
                    menuUsuario={menuUsuario}
                    guardarAccesosMenu={guardarAccesosMenu}
                    informacion={informacion}
                />
        </ModalService>
    );
};

export default ModalAsignacionModulos;
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack, periodos_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import DinamicTablePeriodosNomina from './DinamicTablePeriodosNomina';

const PeriodosNomina = ({ id, permisos }) => {

    const [nominas, setNominas] = useState(null);
    const [dinamicTable, setDinamicTable] =useState(''); 
    const [ elemmentSelect, setElemmentSelect ] = useState( {} );

    useEffect(()=>{
        Axios.get(`${servicios_grupogack}/nomina/config/nomina`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setNominas(res.data.dataResponse);
                    break;
                case 404:
                    setNominas([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    },[]);
    
    const showTable=async(value, elemmentSelect={}, fechaIn=null)=>{  
        await setElemmentSelect(elemmentSelect);
        setDinamicTable(value);  
        return dinamicTable;
    }
    
    return (
        
        <div id={ id }>
            {
                dinamicTable
                ?
                    <DinamicTablePeriodosNomina idTab={elemmentSelect}  onShowTable={showTable} idModulo={ id } permisos={permisos}/>
               :
               <div>
               {
                 nominas   
                ? <DataTableService id={id} title={ periodos_nomina }
                    columns={[
                        { field: "nominaClave", title: "Clave Nómina" },
                        { field: "nominaNomCorto", title: "Nombre Corto" },
                        { field: "nominaNombre", title: "Nombre Nómina" }
                    ]}
                    data={nominas}
                    hideIconAdd
                    hideIconEdit
                    hideIconDelete
                    showIconDescription={permisos.find(permiso => permiso.id === 4)}
                    onShowTable={showTable} 
                    />
                : <SpinnerOval/>
                }
                </div>
            }
        </div>

    );
}
 
export default PeriodosNomina;
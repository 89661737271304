import React from 'react';
import ModalService from '../../Components/Usuarios/Usuario/ModalService/ModalService';
import SpinnerOval from '../Spinner/SpinnerOval';

const ModalLoading = ({ nombreModulo, open, setOpen }) => {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <ModalService
            parentNode={nombreModulo}
            isOpen={open}
            onClose={handleClose}
            maxWidth="40%"
            minWidth="40%"
            withoutFocusTrapped
        >
            <div className="mt-5 mb-2">
                <SpinnerOval />
            </div>
            <div className="text-center mt-2 mb-5">
                <h3 className="without">En proceso...</h3>
                <p style={{ color: '#747474' }}>
                    Por favor, espere un momento
                </p>
            </div>
        </ModalService>
    );
}
export default ModalLoading;
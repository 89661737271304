import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTipoPasatiempo from './FormTipoPasatiempo';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditTipoPasatiempo = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState( null );

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarTipoPasatiempo = (pasatiempo, pasatiempoId) => {
        const info = { id: pasatiempoId, pasaNom: pasatiempo.pasaNom, pasaDesc: pasatiempo.pasaDesc };
        axios.put(`${servicios_grupogack_catalogo}/pasatiempos/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(res.data.dataResponse);
                    setOpenEdit( false );
                    break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg} />);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Tipo de Pasatiempo" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTipoPasatiempo
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarTipoPasatiempo={actualizarTipoPasatiempo}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTipoPasatiempo;
import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormTiposDomicilio from './FormTiposDomicilio';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditTiposDomicilio = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarTipoDomicilio = (tipoDomicilio, tipoDomicilioId) => {
        const info = { nombre: tipoDomicilio.tipodomNom };
        Axios.put(`${servicios_grupogack_catalogo}s/tipos/domicilio/${tipoDomicilioId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar({ id: tipoDomicilioId, tipodomNom: tipoDomicilio.tipodomNom });
                    setOpenEdit(false);
                    break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg} />);
                    break;

                default:
                    errorTable(cambios_no_guardados);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Tipo de Domicilio" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormTiposDomicilio
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarTipoDomicilio={actualizarTipoDomicilio}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditTiposDomicilio;
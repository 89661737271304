import React, { useState } from 'react';
//import axios from 'axios';
//import { servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../../Constants/Constants';
//import { headersAuth } from '../../../../../../../Services/AuthService';
import ModalService from '../../../../ModalService/ModalService';
import FormAcumulados from './FormAcumulados';
import { floatToAmount } from '../../../../../../../Services/Validation/HelperValidation';

const ModalAddAcumulados = ({ openAdd, setOpenAdd, agregar, errorTable, nombreModulo, informacionAdicional }) => {
    
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    }

    const enviarAcumulado = acumulado => {
        //const info = acumulado;
        agregar({ id: 6, nominaClave: acumulado.acumulado, nominaIndicador: acumulado.acumulado, indicadorSat: acumulado.acumulado, cantidad: acumulado.cantidad, monto: floatToAmount(acumulado.importe) })
        setOpenAdd(false);
        /*axios.post(`${servicios_grupogack_catalogo}/acumulado/`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    agregar(res.data.dataResponse);
                    setOpenAdd(false);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));*/
    }

    return (
        <ModalService
            title="Nuevo Acumulado"
            parentNode={nombreModulo}
            isOpen={ openAdd }
            onClose={cerrarModal}
        >
            <div className="row">
                <div className="col-12">
                    <FormAcumulados
                        enviarAcumulado={enviarAcumulado}
                        error={error}
                        informacionAdicional={informacionAdicional}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddAcumulados;
import React, { useState } from 'react';
import { obtenerModalAdd, obtenerModalEdit, obtenerModalDelete } from './HelperMovimientos';
import { localization, tableIcons } from './../../../../DataTableService/ElementTable';
import AlertSyE from '../../../../../../../Services/Alerts/AlertSyE';

import MaterialTable, { MTableCell } from 'material-table'
import { Edit, Delete } from '@material-ui/icons';
import { MuiThemeProvider, createMuiTheme, Tooltip } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Close from '@material-ui/icons/Close';
import { longDateToInput } from '../../../../../../../Services/Validation/HelperValidation';
import './styles.css'

const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

const DataTableMovimientos = ({ idModulo, empleado, setEmpleado, columns, data, informacionAdicional, hideIconAdd, hideIconEdit, hideIconDelete }) => {
    const columnsTable = columns; //this.props.columns

    const [ textAlert, setTextAlert ] = useState(''); //this.props.data
    const [ dataTable, setDataTable ] = useState( data ); //this.props.data

    const [ openAdd, setOpenAdd ] = useState( false );
    const [ openEdit, setOpenEdit ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ elemmentSelect, setElemmentSelect ] = useState( {} );

    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    /* ---------------------------------------------------------------------------------------------- */
    const optionsTable = {
        columnsButton: true,
        pageSize: (dataTable.length <= 5) ? 5 : 10,
        headerStyle: { zIndex: "0", color: '#4C638E', fontweight: 'bold'},
        actionsColumnIndex:-1,
        searchFieldAlignment: "left",
    }

    const actionsTable = [
        ...( hideIconEdit ? [] : [
            {
                icon: Edit, tooltip: 'Editar',
                onClick: (event, rowData) => {
                    setElemmentSelect( rowData );
                    setOpenEdit( true );
                }
            }
        ]),
        ...( hideIconDelete ? [] : [
            {
                icon: Delete, tooltip: 'Eliminar',
                onClick: (event, rowData) => {
                    setElemmentSelect( rowData );
                    setOpenDelete( true );
                }
            }
        ])
    ]
    /* ------------------- Actualizaciones de estado(Contenido) Acciones API-State ------------------ */
    const agregar = ( newElement ) => {
        setDataTable( [ newElement, ...dataTable ] );
        setTextAlert('Registro Agregado');
        setAlertSuccess( true );
    }

    const editar = ( newElement ) => {
        setDataTable( dataTable.map( element => element.id === newElement.id ? element = newElement : element ) );
        setTextAlert('Registro Actualizado');
        setAlertSuccess( true );
    }

    const eliminar = ( idElement ) => {
        setDataTable( dataTable.filter( element => element.id !== idElement ) );
        setTextAlert('Registro Eliminado');
        setAlertSuccess( true );
    }
    /* ---------------------------------------------------------------------------------------------- */
    const successTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }
    /* ---------------------------------------------------------------------------------------------- */
    return (
        <div className="ContenedorDataTable">
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    title={ '' }
                    columns={ columnsTable }
                    data={ dataTable }

                    actions={ actionsTable }
                    options={ optionsTable }
                    localization={ localization }
                    icons={ tableIcons }

                    components={{
                        Toolbar: props => {
                            //return( <div className="TableroDataTable"> <MTableToolbar {...props} /> </div> )
                            return(
                                <div className="TableroDataTable">
                                    <div className="MuiToolbar-root">
                                        <div className="row"></div>
                                            <div className="col-md-4 col-sm-12 ml-4">
                                                <div> <b> Empleado: </b> {empleado.nombreCompleto} </div>
                                                <div> <b> Número Empleado: </b> {empleado.empleadoClave} </div>
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div> <b> Nómina: </b> {empleado.nominaNombre} </div>
                                                {/*<div> <b> Periodo: </b> {empleado.nominaNombre} </div>*/}
                                            </div>
                                            <div className="col-md-4 col-sm-12">
                                                <div className="movimientosRight">
                                                    { !hideIconAdd && <Button className="btn-third" onClick={() => setOpenAdd( true )}> Agregar Movimiento </Button> }
                                                    <Button variant="outlined" className="btn-outline btn-auto ml-4" onClick={() => setEmpleado(null)}> <Close/> </Button>
                                                </div>
                                            </div>
                                    </div>
                                </div>
                            )
                        },
                        Cell: props => {
                            if( typeof( props.value ) === 'string' && props.value.length > 60 ){
                                return(
                                    <Tooltip title={ props.value } placement="bottom-start" arrow>
                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                            { `${props.value.substring(0,45)} ...` }
                                        </td>
                                    </Tooltip>
                                );
                            }else{
                                if( props.columnDef.field === 'fechaAplicacion' || props.columnDef.field === 'fechaInicio' || props.columnDef.field === 'fechaFinal' ){
                                    return(
                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                            { longDateToInput( props.value ) }
                                        </td>
                                    );
                                }else{
                                    return( <MTableCell {...props} /> );
                                }
                            }
                        }
                    }}
                />
            </MuiThemeProvider>
            { /* ----------------------------------------------------- Modals ----------------------------------------------------- */ }
            { obtenerModalAdd( idModulo, openAdd, setOpenAdd, agregar, editar, errorTable, successTable, informacionAdicional ) }
            { obtenerModalEdit( idModulo, openEdit, setOpenEdit, elemmentSelect, editar, errorTable, successTable, informacionAdicional ) }
            { obtenerModalDelete( idModulo, openDelete, setOpenDelete, elemmentSelect, eliminar, errorTable, informacionAdicional ) }
            { /* ---------------------------------------------------- Modal PDF --------------------------------------------------- */ }
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
};

export default DataTableMovimientos;
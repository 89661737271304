import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, amountToFloat } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

const FormAcumulados = ({ error, setError, enviarAcumulado, elementSelect, actualizarAcumulado, informacionAdicional}) => {

    const [openAlert, setOpenAlert] = useState(false);
    const conceptoRef = createRef('');
    const cantidadRef = createRef('');
    const importeRef = createRef('');

    const { anio, periodo, acumulados } = informacionAdicional;

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('FormAcumuladoSec')) {
            const acumulado = conceptoRef.current.value;
            const cantidad = cantidadRef.current.value
            const importe = amountToFloat( importeRef.current.value );
            enviarAcumulado( { acumulado, cantidad, importe } );
        }
    };

    const actualizar = () => {
        if(validarSubmit('FormAcumuladoSec')) {
            const cantidad = cantidadRef.current.value
            const importe = amountToFloat( importeRef.current.value );
            setError(null);
            actualizarAcumulado({ cantidad, importe }, elementSelect.indicadorId);
        }
    };

    return ( 
        <Fragment>
            <form id="FormAcumuladoSec" onSubmit={elementSelect ? mostrarAlert : enviar}>
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Año" type="anio" defaultValue={anio} required disabled/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Periodo" type="periodoAcumulado" defaultValue={periodo} required disabled/>
                    </div>
                </div>
                {
                    (elementSelect)
                    ?   <InputValidation title="Concepto" type="concepto" defaultValue={elementSelect.nominaIndicador} required disabled/>
                    :   <SelectValidation title="Concepto" arrayOpciones={acumulados} keyOpcion="acumulado"
                            refValue={conceptoRef} defaultValue="" required/>
                }
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Cantidad" type="diasAgino" placeholder="Escriba la cantidad"
                            tooltipName="tooltipCantidadAcum" tooltipText="Máximo 3 números"
                            refValue={cantidadRef} defaultValue={elementSelect ? elementSelect.cantidad : ''} 
                            maxLength={3} onlyNumbers required/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Importe (MXN)" type="monto" placeholder="Escriba el importe"
                            tooltipName="tooltipImorteAcum" tooltipText="Máximo 6 números y dos decimales"
                            refValue={importeRef} defaultValue={elementSelect ? elementSelect.monto : ''} onlyNumbers="."
                            onBlurDecimal={2} maxLength={9} required isAmount/>
                    </div>
                { error }
                </div>
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment> 
    );
}
export default FormAcumulados;
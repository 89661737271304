import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_url, registro_no_guardado, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioUsuario from './FormularioUsuario';
import InformacionUsuario from './InformacionUsuario';

const ModalAddUsuarios = ({ openAdd, setOpenAdd, agregar, editar, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setElementSelect( null );
        setError( null );
    }

    /* Axios para agregación de un nuevo usuario */
    const enviarUsuario = ( usuario ) => {
        axios.post( `${servicios_grupogack_url}/new`, usuario, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        agregar( respuesta.data.dataResponse );
                        //setOpenAdd( false );
                        setError( null );
                        setElementSelect( respuesta.data.dataResponse );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    /* Axios para actualización de usuario */
    const actualizarUsuario = ( usuario, userID ) => {
        axios.put( `${servicios_grupogack_url}/info/${userID}`, usuario, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        editar( respuesta.data.dataResponse );
                        setOpenAdd( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Nuevo Usuario" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                { !elementSelect ?
                    <FormularioUsuario
                        error={error}
                        informacion={informacionAdicional}
                        enviarUsuario={enviarUsuario}
                    />
                    :
                    <InformacionUsuario
                        error={error}
                        userID={elementSelect.id}
                        informacion={informacionAdicional}
                        actualizarUsuario={actualizarUsuario}
                        errorTable={errorTable}
                        successTable={successTable}
                        idModulo={nombreModulo}
                    />
                }
        </ModalService>
    );
};

export default ModalAddUsuarios;
import React, { useRef, useState } from 'react';

/*Complementos*/
import { lengthEmail, servicios_grupogack, ocurrio_un_error, campos_vacios } from '../../Constants/Constants';
import AlertForm from '../../Services/AlertForm';
import AlertSyE from '../../Services/Alerts/AlertSyE';
import { trimValue } from '../../Services/Validation/HelperValidation';

/*Vista Material UI*/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
//import Image from '../../Media/Img/logo-oax-color.png';

const RecuperarPassFormulario = ({ onChangeFormClick, logotipo }) => {

    const [ mensaje, setMensaje ] = useState('');
    const [ btnBloqueado, setBtnBloqueado ] = useState( false );
    //state para control de alert
    const[openAlertSyE, setOpenAlertSyE] = useState(true);
    
    const emailRef = useRef('');

    const handleChangeFormClick = (e) => {
        e.preventDefault();
        onChangeFormClick(1);
    }

    const handleSendEMailClick = e => {
            e.preventDefault();
            setBtnBloqueado(true);
            const email = emailRef.current.value;
            if(email.trim() !== ''){
                fetch(`${servicios_grupogack}/account/recover/password/generate/token`,
                { method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({ username: email })})
                .then( response => {
                    return response.json();
                })
                .then( response => {
                    switch(response.status){
                        case 200:
                            setMensaje(<AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Recuperar contraseña" text={response.msg} textbtnconfirm="Aceptar" type="success" action={handleChangeFormClick}/>);
                            break;
                        case 401:
                        case 403:
                        case 404:
                            setBtnBloqueado(false);
                            setMensaje(<AlertForm message={response.msg} />);
                            break;
                        default:
                            setBtnBloqueado(false);
                            setMensaje( <AlertForm message={ocurrio_un_error}/> );
                            break;
                    }
                })
                .catch( error => {
                    setBtnBloqueado(false);
                    setMensaje( <AlertForm message={ocurrio_un_error}/> );
                })
            } else {
                setBtnBloqueado(false);
                setMensaje(<AlertForm message={campos_vacios} />);
            }
    }

    return (
        
        <Paper className="cont_log" elevation={6}>
            <div className="log_form">
                <div className = "title">
                    { logotipo && <img src="/images/logo.png" width="20%" className="header_logo separation" alt="site-logo"/> }
                    <span>Recupere su acceso</span>
                </div>
                <form onSubmit={ e => handleSendEMailClick(e) } autoComplete="off">
                    <div className="form-group">
                        <label className="col-form-label label-form">Correo electrónico<strong className="requerido">* </strong></label>
                        <input type="text" className="form-control" placeholder="Correo electrónico" ref={emailRef} maxLength={lengthEmail} defaultValue={""} onBlur={() => trimValue(emailRef)} />
                    </div>
                    { mensaje }
                    <Button type="submit" variant="contained" className="btn-color" disabled={btnBloqueado}>Cambiar contraseña</Button>
                    <div className="div-text">
                        <span>¿Ya Tiene Cuenta?</span>
                        <button className="subrayado link" onClick={e => handleChangeFormClick(e)}>Iniciar Sesión</button>
                    </div>
                </form>
            </div>
        </Paper>
    );
};

export default RecuperarPassFormulario;
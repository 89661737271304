import React from 'react';

const TitleCard = ({ title }) => {
    return (
        <div className="row">
            <div className="col-xl-10 col-lg-10 col-md-9 col-sm-8 col-12 d-flex align-items-end margintb">
                <h4 style={{ paddingRight: '30px', fontWeight: 'bold' }}>
                    { title }
                </h4>
            </div>
        </div>
    );
}
 
export default TitleCard;
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import { Redirect } from 'react-router-dom';

/*Complementos*/
import { servicios_grupogack_url, servicios_grupogack_token, ocurrio_un_error } from '../../Constants/Constants';
import AlertSyE from '../../Services/Alerts/AlertSyE';
import Footer from '../Footer/Footer';

const Registro = ({history}) => {

    const [tokenExist, setTokenExist] = useState(null);
    const [mensajeContrasena, setMensajeContrasena] = useState('');
    //state para control de alert
    const [openAlertSyE, setOpenAlertSyE] = useState(true);

    const { token } = useParams();

    const returnLogin = (e) => {
        if(e) {
            e.preventDefault();
        }
        history.push("/acceso");
    }

    const enviaMensajeConAction = (type, message) => {
        setMensajeContrasena(<AlertSyE show={openAlertSyE} setOpenAlertSyE={setOpenAlertSyE} title="Activar Cuenta" text={message} textbtnconfirm="Aceptar" type={type} action={returnLogin}/>);
    }

    useEffect(() => {
        if(tokenExist === null) {    
            const activacionCuenta = ( token ) => {
                fetch(`${servicios_grupogack_url}/account/verify/${token}`, { method: 'POST' })
                .then( response => { return response.json(); })
                .then( response => {
                    switch(response.status) {
                        case 200:
                            enviaMensajeConAction("success", response.msg);
                            break;
                        case 401:
                            enviaMensajeConAction("error", response.msg);
                            break;
                        default:
                            setMensajeContrasena(<Redirect to="/acceso" />);
                            break;
                    }
                })
                .catch( error => {
                    enviaMensajeConAction("error", ocurrio_un_error);
                });
            }
    
            //Al iniciar el componente se verifica que el token sea valido
            fetch(`${servicios_grupogack_token}/account/token/validate/${token}`, { method: 'POST' })
            .then( response => { return response.json(); })
            .then( response => {
                switch(response.status) {
                    case 200:
                        setTokenExist(true);
                        activacionCuenta(token);
                        break;
                    case 401:
                        setTokenExist(false);
                        enviaMensajeConAction("error", response.msg);
                        break;
                    default:
                        setTokenExist(false);
                        setMensajeContrasena(<Redirect to="/acceso" />);
                        break;
                }
            })
            .catch( error => {
                setTokenExist(false);
                enviaMensajeConAction("error", ocurrio_un_error);
            });
        }
    });

    return(
        <div className = "background">
            <div className="container-fluid">
                { mensajeContrasena }
            </div>
            <Footer className="footer-login" />
        </div>
    );
}

export default Registro;
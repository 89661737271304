import React from 'react';
import DataTableService from '../../../../DataTableService/DataTableService';

const EmpleadosTable = ({ id, permisos, empleados, mostrarTablaAusentismos }) => {
    return (
        <DataTableService
            id={id}
            title="Empleados"
            columns={[
                { field: "empleadoClave", title: "Núm. Empleado" },
                { field: "empleadoNom", title: "Nombre" },
                { field: "empleadoPat", title: "Primer Apellido" },
                { field: "empleadoMat", title: "Segundo Apellido" },
                { field: "empleadoRfc", title: "RFC" },
                { field: "puestoNom", title: "Puesto" },
                { field: "nominaNombre", title: "Nómina" },
                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
            ]}
            data={empleados}
            hideIconAdd hideIconDelete hideIconEdit
            showIconDescription
            onShowTable={mostrarTablaAusentismos}
            permisos={permisos}
        />
    );
}
 
export default EmpleadosTable;
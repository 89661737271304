import React, { useState, createRef } from 'react';
import Axios from 'axios';
import { servicios_grupogack_admon, registro_no_guardado } from '../../../../../../Constants/Constants';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonProyectos } from '../../../Helpers/JsonToOneLevel';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import Button from '@material-ui/core/Button';
import { Tooltip } from 'reactstrap';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const FormFaltante = ({ error, info, setOpenAdd, agregar, errorTable, setOpenAddFirst }) => {

    const [claveUEjecutora, setClaveUEjecutora] = useState('');
    const [clavePrograma, setClavePrograma] = useState('');
    const [clavePAccion, setClavePAccion] = useState('');
    const [tooltipOpenUEjecutora, setTooltipOpenUEjecutora] = useState(false);
    const [tooltipOpenPrograma, setTooltipOpenPrograma] = useState(false);
    const [tooltipOpenProyAccion, setTooltipOpenProyAccion] = useState(false);
    const [tooltipOpenCtrTrabajo, setTooltipOpenCtrTrabajo] = useState(false);

    const claveUEjecutoraRef = createRef('');
    const nombreUEjecutoraRef = createRef('');
    const claveProgramaRef = createRef('');
    const nombreProgramaRef = createRef('');
    const clavePAccionRef = createRef('');
    const nombrePAccionRef = createRef('');
    const claveCvePresupuestalRef = createRef('');
    const nombreCvePresupuestalRef = createRef('');

    const toggle = (tooltip) => {
        switch(tooltip) {
            case "UnidadEjecutora":
                setTooltipOpenUEjecutora(!tooltipOpenUEjecutora);
                break;
            case "Programa":
                setTooltipOpenPrograma(!tooltipOpenPrograma);
                break;
            case "ProyectoAccion":
                setTooltipOpenProyAccion(!tooltipOpenProyAccion);
                break;
            case "CentroTrabajo":
                setTooltipOpenCtrTrabajo(!tooltipOpenCtrTrabajo);
                break;
            default:
                break;
        }
    }

    const guardarUEjecutora = () => {
        const uEjecutoraClave = claveUEjecutoraRef.current.value;
        const uEjecutoraNombre = nombreUEjecutoraRef.current.value;
        const udejecutoraClave = `${info.uResponsable.clave}${uEjecutoraClave}`;

        const request = { ciaId: info.uResponsable.id, udejecutoraClave, udejecutoraNom: uEjecutoraNombre };
        Axios.post( `${servicios_grupogack_admon}/ejecutora/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:

                    guardarPrograma(res.data.dataResponse.id);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    };

    const guardarPrograma = (id = info.uEjecutora.id) => {
        const programaClave = claveProgramaRef.current.value;
        const programaNombre = nombreProgramaRef.current.value;
        const programaclave = (info.info === 1)
                            ?   `${info.uResponsable.clave}${claveUEjecutora}${programaClave}`
                            :   `${info.uEjecutora.clave}${programaClave}`;

        const request = { udejecutoraid: id, programaclave, programanom: programaNombre };
        Axios.post( `${servicios_grupogack_admon}/programa/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    guardarPAccion(res.data.dataResponse.id);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    };

    const guardarPAccion = (id = info.programa.id) => {
        const pAccionClave = clavePAccionRef.current.value;
        const pAccionNombre = nombrePAccionRef.current.value;
        const proyectoclave = (info.info === 1)
                            ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${pAccionClave}`
                            :   (info.info === 2) 
                                ?   `${info.uEjecutora.clave}${clavePrograma}${pAccionClave}`
                                :   `${info.programa.clave}${pAccionClave}`;

        const request = { programaid: id, proyectoclave, proyectonom: pAccionNombre };
        Axios.post( `${servicios_grupogack_admon}/proyecto/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    guardarCvePresupuestal(res.data.dataResponse.id);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    };

    const guardarCvePresupuestal = id => {
        const cvePresupuestalClave = claveCvePresupuestalRef.current.value;
        const cvePresupuestalNombre = nombreCvePresupuestalRef.current.value;
        const cpresupuestalclave =  (info.info === 1)
                                    ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${clavePAccion}${cvePresupuestalClave}`
                                    :   (info.info === 2) 
                                        ?   `${info.uEjecutora.clave}${clavePrograma}${clavePAccion}${cvePresupuestalClave}`
                                        :   (info.info === 3)
                                            ?   `${info.programa.clave}${clavePAccion}${cvePresupuestalClave}`
                                            :   `${info.pAccion.clave}${cvePresupuestalClave}`;

        const request = { proyectoid: id, cpresupuestalclave, cpresupuestalnom: cvePresupuestalNombre };
        Axios.post( `${servicios_grupogack_admon}/presupuestal/`, request, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const proyecto = JsonProyectos(res.data.dataResponse);
                    agregar(proyecto);
                    setOpenAdd(false);
                    setOpenAddFirst(false);
                    break;
                default:
                    errorTable(registro_no_guardado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    };
    
    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarFaltanteProyecto')) {
            switch(info.info) {
                case 1:
                    guardarUEjecutora();
                break;
                case 2:
                    guardarPrograma();
                break;
                case 3:
                    guardarPAccion();
                break;
                default:
                    alert("error");
                break;
            }
        }
    };

    const obtenerCveUE = () => {
        setClaveUEjecutora(claveUEjecutoraRef.current.value);
    };

    const obtenerCveProg = () => {
        setClavePrograma(claveProgramaRef.current.value);
    };

    const obtenerCvePA = () => {
        setClavePAccion(clavePAccionRef.current.value);
    };

    return (
        <form id="AgregarFaltanteProyecto" onSubmit={ e => enviar(e) }>
            <div className="row">
                <div className="col-12">
                    <label className="textGreen">Unidad Ejecutora</label>
                </div>
            </div>
            <label>
                Clave<strong className="requerido">* </strong>
                <span id="cveUEjecutora" className="spanInfo">
                    <InfoRoundedIcon color="disabled"/>
                </span>
            </label>
            <Tooltip placement="right" isOpen={tooltipOpenUEjecutora} target="cveUEjecutora" toggle={() => toggle("UnidadEjecutora")}>
                { (info.info >= 2) ? '6 números' : '3 números' }
            </Tooltip>
            <div className="row">
                {
                    (info.info >= 2)
                    ?   null
                    :   <div className="col-7">
                            <label className="form-control label-control">
                                { `${info.uResponsable.clave}` }
                            </label>
                        </div>
                }
                <div className={(info.info >= 2) ? 'col-12' : 'col-5'}>
                    <InputValidation type={(info.info >= 2) ? 'claveUEjecutora6' : 'claveUEjecutora3'} placeholder="Clave"
                        refValue={claveUEjecutoraRef} defaultValue={(info.info >= 2) ? info.uEjecutora.clave : ''} onlyNumbers
                        maxLength={(info.info >= 2) ? 6 : 3} action={obtenerCveUE} disabled={ (info.info >= 2) ? true : false}
                        optional={ (info.info >= 2) ? true : false}/>
                </div>
            </div>
            <InputValidation title="Nombre" type="nombreUEjecutora" placeholder="Nombre" refValue={nombreUEjecutoraRef}
                tooltipText={'128 caracteres alfanuméricos , espacios y/o caracteres especiales . , - ) ( " \' #'}
                tooltipName={'nombreUEjecutoraRef'} maxLength={128} defaultValue={(info.info >= 2) ? info.uEjecutora.nombre : ''}
                disabled={ (info.info >= 2) ? true : false} optional={ (info.info >= 2) ? true : false} required/>
            <br/>
            <div className="row">
                <div className="col-12">
                    <label className="textGreen">Programa</label>
                </div>
            </div>
            <label>
                Clave<strong className="requerido">* </strong>
                <span id="cvePrograma" className="spanInfo">
                    <InfoRoundedIcon color="disabled"/>
                </span>
            </label>
            <Tooltip placement="right" isOpen={tooltipOpenPrograma} target="cvePrograma" toggle={() => toggle("Programa")}>
                { (info.info >= 3) ? '9 números' : '3 números' }
            </Tooltip>
            <div className="row">
                {
                    (info.info >= 3)
                    ?   null
                    :   <div className="col-7">
                            <label className="form-control label-control">
                                {
                                    (info.info === 1)
                                    ?   `${info.uResponsable.clave}${claveUEjecutora}`
                                    :   info.uEjecutora.clave
                                }
                            </label>
                        </div>
                }
                <div className={(info.info >= 3) ? 'col-12' : 'col-5'}>
                    <InputValidation type={(info.info >= 3) ? 'clavePrograma9' : 'clavePrograma3'} placeholder="Clave" refValue={claveProgramaRef}
                        defaultValue={(info.info >= 3) ? info.programa.clave : ''} action={obtenerCveProg} onlyNumbers
                        maxLength={(info.info >= 3) ? 9 : 3} disabled={(info.info >= 3) ? true : false} optional={(info.info >= 3) ? true : false}/>
                </div>
            </div>
            <InputValidation title="Nombre" type="nombrePrograma" placeholder="Nombre" refValue={nombreProgramaRef}
                tooltipText={'128 caracteres alfanuméricos , espacios y/o caracteres especiales . , - ) ( " \' #'}
                tooltipName="nombrePrograma" maxLength={128} defaultValue={(info.info >= 3) ? info.programa.nombre : ''}
                disabled={ (info.info >= 3) ? true : false} optional={ (info.info >= 3) ? true : false} required/>
            <br/>
            <div className="row">
                <div className="col-12">
                    <label className="textGreen">Proyecto Acción</label>
                </div>
            </div>
            <label>
                Clave<strong className="requerido">* </strong>
                <span id="cveProyAccion" className="spanInfo">
                    <InfoRoundedIcon color="disabled"/>
                </span>
            </label>
            <Tooltip placement="right" isOpen={tooltipOpenProyAccion} target="cveProyAccion" toggle={() => toggle("ProyectoAccion")}>
                { '8 números' }
            </Tooltip>
            <div className="row">
                <div className="col-7">
                    <label className="form-control label-control">
                        {
                            (info.info === 1)
                            ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}`
                            :   (info.info === 2) 
                                ?   `${info.uEjecutora.clave}${clavePrograma}`
                                :   `${info.programa.clave}`
                        }
                    </label>
                </div>
                <div className="col-5">
                    <InputValidation type="clavePAccion" placeholder="Clave" refValue={clavePAccionRef} defaultValue="" maxLength={8}
                        action={obtenerCvePA} onlyNumbers/>
                </div>
            </div>
            <InputValidation title="Nombre" type="nombrePAccion" placeholder="Nombre" refValue={nombrePAccionRef} defaultValue=""
                tooltipText={'128 caracteres alfanuméricos , espacios y/o caracteres especiales . , - ) ( " \' #'} tooltipName={'nombrePAccion'}
                maxLength={128} required/>
            <br/>
            <div className="row">
                <div className="col-12">
                    <label className="textGreen">Centro de Trabajo / Proyecto / Clave Presupuestal</label>
                </div>
            </div>
            <label>
                Clave<strong className="requerido">* </strong>
                <span id="cveCtrTrabajo" className="spanInfo">
                    <InfoRoundedIcon color="disabled"/>
                </span>
            </label>
            <Tooltip placement="right" isOpen={tooltipOpenCtrTrabajo} target="cveCtrTrabajo" toggle={() => toggle("CentroTrabajo")}>
                { '2 letras, números y/o caracteres especiales = @ # * < >' }
            </Tooltip>
            <div className="row">
                <div className="col-7">
                    <label className="form-control label-control">
                        {
                            (info.info === 1)
                            ?   `${info.uResponsable.clave}${claveUEjecutora}${clavePrograma}${clavePAccion}`
                            :   (info.info === 2) 
                                ?   `${info.uEjecutora.clave}${clavePrograma}${clavePAccion}`
                                :   `${info.programa.clave}${clavePAccion}`
                        }
                    </label>
                </div>
                <div className="col-5">
                    <InputValidation type="claveCvePresupuestal" placeholder="Clave" refValue={claveCvePresupuestalRef} defaultValue="" maxLength={2}/>
                </div>
            </div>
            <InputValidation title="Nombre" type="nombreCvePresupuestal" placeholder="Nombre" refValue={nombreCvePresupuestalRef} defaultValue=""
                tooltipText={'128 caracteres alfanuméricos, espacios y/o caracteres especiales . , - ) ( " \' #'} tooltipName="nombreCvePresupuestal"
                maxLength={128} required/>
            { error }
            <br/>
            <Button variant="contained" className="btn-color" type="submit">Agregar</Button>
        </form>
    );
}
 
export default FormFaltante;
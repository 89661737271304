import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, cambios_no_guardados } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonPrestamos } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import AlertForm from '../../../../../../../Services/AlertForm';
import FormFinalizaPrestamos from './FormFinalizaPrestamos';

const ModalFinalizaPrestamos = ({ id, openModalForm, setOpenModalForm, elementSelect, editar, errorTable }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenModalForm( false );
        setError( null );
    }

    const cerrarPrestamo = ( fechaFinPrestamo ) => {
        axios.put( `${servicios_grupogack_rh}/empleado/prestamos/close/${elementSelect.id}`, fechaFinPrestamo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        editar( JsonPrestamos(respuesta.data.dataResponse) );
                        cerrarModal();
                        break;
                    case 400:
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Finalizar Préstamo" parentNode={id} minWidth="35%" maxWidth="35%"
            isOpen={ openModalForm } onClose={ () => cerrarModal() } >
                <div> <b> Folio: </b> {elementSelect.folio} </div>
                <div> <b> Concepto: </b> {elementSelect.indicadorNombre} </div>
                <div> <b> Número de Pagos: </b> {elementSelect.numeroPagos} </div>
                <div> <b> Importe Pagos (MXN): </b> {elementSelect.importe} </div>
                <div> <b> Fecha Inicio de Préstamo: </b> {elementSelect.fechaIniTab} </div>
                <hr/>

                <FormFinalizaPrestamos
                    error={error}
                    cerrarPrestamo={cerrarPrestamo}
                />
        </ModalService>
    );
};

export default ModalFinalizaPrestamos;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioTipoRiesgo = ({ error, enviarTipoRiesgo, elementSelect, actualizarTipoRiesgo }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarTipoRiesgo' ) ){
            const riegClave = claveRef.current.value;
            const riegDesc = descripcionRef.current.value;
            enviarTipoRiesgo({ riegClave, riegDesc });
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarTipoRiesgo' ) ){
            const riegClave = claveRef.current.value;
            const riegDesc = descripcionRef.current.value;
            actualizarTipoRiesgo({ id: elementSelect.id, riegClave, riegDesc });
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarTipoRiesgo' : 'AgregarTipoRiesgo' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveTipoRiesgo" placeholder="Escriba la clave del Tipo de Riesgo"
                    tooltipText={'Máximo 2 números'} tooltipName={'tooltipCveTipoRiesgo'} maxLength='2' onlyNumbers
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.riesgoPtoClave : '' } required/>
                <InputValidation title="Descripción" type="descripcionTipoRiesgo" placeholder="Escriba la descripción del Tipo de Riesgo" maxLength='12'
                    tooltipText={'Máximo 12 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescTipoRiesgo'}
                    refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.riesgoPtoDesc : '' } required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioTipoRiesgo;
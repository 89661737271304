import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, registro_patronal } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonRegistroPatronal } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const RegistroPatronal = ({ id, permisos }) => {
    const [registroPatronal, setRegistroPatronal] = useState(null);
    const [tiposSeguridadSocial, setTiposSeguridadSocial] = useState(null);
    const [tiposRiesgo, setTiposRiesgo] = useState( null );
    useEffect(() => {
        axiosRegistroPatronal();
        axiosTipoSeguridadSocial();
        axiosTiposRiesgo();
    },[]);

    const axiosRegistroPatronal = () => {
        Axios.get(`${servicios_grupogack_catalogo}/registropat/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setRegistroPatronal( ArrayJsonRegistroPatronal( res.data.dataResponse ) );
                        break;
                    case 404:
                        setRegistroPatronal([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
    }

    /* Axios para obtener Tipos de Seguridad Social */
    const axiosTipoSeguridadSocial = () => {
        Axios.get(`${servicios_grupogack_catalogo}/tiposegsoc/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setTiposSeguridadSocial(res.data.dataResponse);
                        break;
                    case 404:
                        setTiposSeguridadSocial([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
    }

    /* Axios para obtener Tipos de Riesgo */
    const axiosTiposRiesgo = () => {
        Axios.get(`${servicios_grupogack_catalogo}/tiporiesgo/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setTiposRiesgo(res.data.dataResponse);
                        break;
                    case 404:
                        setTiposRiesgo([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
    }

    return (
        <div id={ id }>
            { registroPatronal && tiposSeguridadSocial && tiposRiesgo ?
                <DataTableService id={id} title={ registro_patronal }
                    columns={[
                        { field: "registroPatClave", title: "Clave" },
                        { field: "segSocNom", title: "Seguridad Social" },
                        { field: "tipoRiesgoNom", title: "Riesgo de Trabajo" }
                    ]}
                    data={registroPatronal}
                    informacionAdicional={{ tiposSeguridadSocial, tiposRiesgo }}
                    permisos={permisos}
                /> : <SpinnerOval />
            }
        </div>
    );
}
 
export default RegistroPatronal;

import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioSeguroSocial = ({ error, enviarSeguroSocial, elementSelect, actualizarSeguroSocial }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const nombreRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarSeguroSocial' ) ){
            const segSocClave = claveRef.current.value;
            const segSocNom = nombreRef.current.value;
            enviarSeguroSocial( { segSocClave, segSocNom } );
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarSeguroSocial' ) ){
            const segSocClave = claveRef.current.value;
            const segSocNom = nombreRef.current.value;
            actualizarSeguroSocial({ segSocClave, segSocNom }, elementSelect.id);
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarSeguroSocial' : 'AgregarSeguroSocial' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveSeguroSocial" placeholder="Escriba la clave del tipo de seguro social"
                    tooltipText={'Máximo 2 letras y/o números'} tooltipName={'tooltipCveSegSoc'} maxLength='2' alfanumeric
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.segSocClave : '' } required/>
                <InputValidation title="Nombre" type="nombreSeguroSocial" placeholder="Escriba el nombre del tipo de seguro social" maxLength='20'
                    tooltipText={'Máximo 20 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomSegSoc'}
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.segSocNom : '' } required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioSeguroSocial;
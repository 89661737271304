import React from 'react';
import ModalService from '../../../ModalService/ModalService';
//import FijosExcel from './FijosExcel';
import MovimientosExcel from '../Variables/DataTableMovimientos/MovimientosExcel';

const ModalAddFijosExcel = ({ openAdd, setOpenAdd, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const cerrarModal = () => {
        setOpenAdd( false );
    }

    return (
        <ModalService title="Carga Masiva de Movimientos Fijos" parentNode={nombreModulo} minWidth="33%" maxWidth="35%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <b>Descarga</b>
                <div>1. Seleccione la nómina</div>
                <div>2. Baje el archivo</div>
                <div>3. Realice los cambios necesarios en el archivo descargado</div>
                <br/>
                <b>Carga</b>
                <div>1. Busque el archivo con extencion .csv</div>
                <div>2. Una vez que el sistema lo analice:</div>
                <div>Si no se presentan incidencias haga la carga, de lo contrario modifique el archivo y repita el procedimiento</div>
                <hr/>
                {/*
                <FijosExcel
                    errorTable={errorTable}
                    successTable={successTable}
                    informacion={informacionAdicional}
                />
                */}
                <MovimientosExcel
                    idModulo={nombreModulo}
                    errorTable={errorTable}
                    successTable={successTable}
                    informacion={informacionAdicional}
                />
        </ModalService>
    );
};

export default ModalAddFijosExcel;
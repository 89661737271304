import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import FormContratosRelLab from './FormContratosRelLab';
import AlertForm from '../../../../../../Services/AlertForm';
import { JsonContratoRelLab } from '../../../Helpers/JsonToOneLevel';

const ModalEditContratosRelLab = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarContratosRelLab = (info, contratoId) => {
        Axios.put( `${servicios_grupogack_catalogo}s/contrato/sao/${contratoId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(JsonContratoRelLab(res.data.dataResponse));
                    setOpenEdit(false);
                break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg} />);
                    break;

                default:
                    errorTable(cambios_no_guardados);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Contrato de Relación Laboral" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormContratosRelLab
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarContratosRelLab={actualizarContratosRelLab}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditContratosRelLab;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

import AlertForm from '../../../../../../Services/AlertForm';

const compararFechas = ( fechaInicial, fechaFinal ) => {
    if( fechaFinal === '' ){
        return true;
    }else{
        if( fechaInicial < fechaFinal ){
            return true;
        }else{
            return false;
        }
    }
}

const FormularioImpuestoNomina = ({ error, enviarImpuestoNomina, elementSelect, actualizarImpuestoNomina, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ errorDate, setErrorDate ] = useState( null );
    const estadoRef = createRef('');
    const porcentajeRef = createRef('');
    const feciniRef = createRef('');
    const fecfinRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarImpuestoNomina' ) ){
            const estadoId = parseInt( estadoRef.current.value );
            const porcentaje = parseFloat( porcentajeRef.current.value );
            const fecini = inputDateToLong( feciniRef.current.value );
            const fecfin = inputDateToLong( fecfinRef.current.value );
            if( compararFechas(fecini, fecfin) ){
                setErrorDate( null );
                //console.log( deleteOptionals({ estadoId, porcentaje, fecini, fecfin }) );
                enviarImpuestoNomina( deleteOptionals({ estadoId, porcentaje, fecini, fecfin }) );
            }else{
                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
            }
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarImpuestoNomina' ) ){
            const estadoId = parseInt( estadoRef.current.value );
            const porcentaje = parseFloat( porcentajeRef.current.value );
            const fecini = inputDateToLong( feciniRef.current.value );
            const fecfin = inputDateToLong( fecfinRef.current.value );
            if( compararFechas(fecini, fecfin) ){
                setErrorDate( null );
                //console.log( deleteOptionals({ id: elementSelect.id, estadoId, porcentaje, fecini, fecfin }) );
                actualizarImpuestoNomina( deleteOptionals({ id: elementSelect.id, estadoId, porcentaje, fecini, fecfin }) );
            }else{
                setErrorDate( <AlertForm message="La Fecha Final no puede ser menor o igual a la Fecha Inicial" /> );
            }
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarImpuestoNomina' : 'AgregarImpuestoNomina' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Estado" arrayOpciones={informacion.estados} keyOpcion="estadoNom"
                            refValue={estadoRef} defaultValue={ elementSelect ? elementSelect.estadoId : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Porcentaje (%)" type="porcentaje" placeholder="Escriba el porcentaje del impuesto"
                            tooltipText={'Máximo 3 números y dos decimales del 0.00 al 100.00'} tooltipName={'tooltipPorcImp'} maxLength='6'
                            refValue={porcentajeRef} defaultValue={ elementSelect ? elementSelect.porcentaje : '' } required
                            onlyNumbers="." onBlurDecimal={2}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={feciniRef}
                            defaultValue={ elementSelect ? elementSelect.fechaini : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fecfinRef}
                            defaultValue={ elementSelect ? elementSelect.fechafin : '' }
                            optional />
                    </div>
                </div>

                {error}
                {errorDate}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioImpuestoNomina;
import React, { useState, Fragment } from 'react';
import { Tooltip } from 'reactstrap';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import HouseIcon from '@material-ui/icons/House';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import WorkIcon from '@material-ui/icons/Work';
import PaletteIcon from '@material-ui/icons/Palette';

const BtnsInfoAdicional = ({ numForm, irInfo }) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipOpenDomicilio, setTooltipOpenDomicilio] = useState(false);
    const [tooltipOpenDependientes, setTooltipOpenDependientes] = useState(false);
    const [tooltipOpenPension, setTooltipOpenPension] = useState(false);
    const [tooltipOpenEmergencia, setTooltipOpenEmergencia] = useState(false);
    const [tooltipOpenLaboral, setTooltipOpenLaboral] = useState(false);
    const [tooltipOpenPasatiempo, setTooltipOpenPasatiempo] = useState(false);
    
    const toggle = () => setTooltipOpen(!tooltipOpen);
    const toggleDomicilio = () => setTooltipOpenDomicilio(!tooltipOpenDomicilio);
    const toggleDependientes = () => setTooltipOpenDependientes(!tooltipOpenDependientes);
    const togglePension = () => setTooltipOpenPension(!tooltipOpenPension);
    const toggleEmergencia = () => setTooltipOpenEmergencia(!tooltipOpenEmergencia);
    const toggleLaboral = () => setTooltipOpenLaboral(!tooltipOpenLaboral);
    const togglePasatiempo = () => setTooltipOpenPasatiempo(!tooltipOpenPasatiempo);

    return (
        <Fragment>
            <label>Información Adicional </label><span id="tooltipInfo" className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                <div className="row" style={{ marginBottom: '1rem', marginTop: '.6rem' }}>
                    <div className="col-2 align-self-center text-center">
                        <button className={(numForm === 3) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 3)} disabled={(numForm === 3) ? true : false}>
                            <span id="tooltipInfoDomicilio">
                                <HouseIcon />
                            </span>
                        </button>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <button className={(numForm === 4) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 4)} disabled={(numForm === 4) ? true : false}>
                            <span id="tooltipInfoDependientes">
                                <MonetizationOnIcon />
                            </span>
                        </button>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <button className={(numForm === 5) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 5)} disabled={(numForm === 5) ? true : false}>
                            <span id="tooltipInfoPension">
                                <RestaurantIcon />
                            </span>
                        </button>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <button className={(numForm === 6) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 6)} disabled={(numForm === 6) ? true : false}>
                            <span id="tooltipInfoEmergencia">
                                <LocalHospitalIcon />
                            </span>
                        </button>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <button className={(numForm === 7) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 7)} disabled={(numForm === 7) ? true : false}>
                            <span id="tooltipExpLaboral">
                                <WorkIcon />
                            </span>
                        </button>
                    </div>
                    <div className="col-2 align-self-center text-center">
                        <button className={(numForm === 8) ? 'btn-selected' : 'btn-infoAdicional'} onClick={e => irInfo(e, 8)} disabled={(numForm === 8) ? true : false}>
                            <span id="tooltipPasatiempo">
                                <PaletteIcon />
                            </span>
                        </button>
                    </div>
                </div>
            <Tooltip placement="right" isOpen={tooltipOpen} target="tooltipInfo" toggle={toggle}>De clic en el botón correspondiente a la información que desea agregar</Tooltip>
            <Tooltip placement="right" isOpen={tooltipOpenDomicilio} target="tooltipInfoDomicilio" toggle={toggleDomicilio}>Domicilio</Tooltip>
            <Tooltip placement="right" isOpen={tooltipOpenDependientes} target="tooltipInfoDependientes" toggle={toggleDependientes}>Dependientes Económicos</Tooltip>
            <Tooltip placement="right" isOpen={tooltipOpenPension} target="tooltipInfoPension" toggle={togglePension}>Pensiones Alimenticias</Tooltip>
            <Tooltip placement="left" isOpen={tooltipOpenEmergencia} target="tooltipInfoEmergencia" toggle={toggleEmergencia}>Números de Emergencia</Tooltip>
            <Tooltip placement="left" isOpen={tooltipOpenLaboral} target="tooltipExpLaboral" toggle={toggleLaboral}>Experiencia Laboral</Tooltip>
            <Tooltip placement="left" isOpen={tooltipOpenPasatiempo} target="tooltipPasatiempo" toggle={togglePasatiempo}>Pasatiempos</Tooltip>
        </Fragment>
    );
}
 
export default BtnsInfoAdicional;
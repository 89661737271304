import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';  
import ArrowBackIcon from '@material-ui/icons/ArrowBack'; 
import Tooltip from '@material-ui/core/Tooltip'; 
import { deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import { ArrayJsonUsuariosReportesRRHH } from '../../../Helpers/JsonToOneLevel';


const RecursosHumanosTablaReporte = ({ id, permisos, openReport, elementSelect, onAdd }) => {
    
    let filtroGeneral ='';
    const convertColumns = (columns)=>{ 
        filtroGeneral = columns[0].filtroGeneral;
        let resultado = columns[0].campos.map(element =>{ return deleteOptionals({
            field        : element.nombre,
            title        : element.titulo,
            defaultSort  : element.ordenDefault,
            defaultFilter: element.defaultFiltro,
            hidden: element.oculto,
            defaultGroupOrder: element.posicionDefaultGrupo,
            defaultGroupSort: element.ordenDefaultGrupo
        })}); 
        return resultado; 
    }
    
    const [empleados, setEmpleados] = useState(null);
    
    const columns = elementSelect.id ?
            convertColumns(JSON.parse(elementSelect.reporteJson))  
        : 
        elementSelect.reportesId ?
            convertColumns(JSON.parse(elementSelect.reportesReporteJson)) 
    :[
        { field: "clave", title: "Núm. Empleado", },
        { field: "nombre", title: "Nombre" },
        { field: "paterno", title: "Paterno" },
        { field: "materno", title: "Materno" },
        { field: "fecnacimiento", title: "Nacimiento" },
        { field: "curp", title: "CURP" },
        { field: "rfc", title: "RFC"},
        { field: "fecantiguedad", title: "Antigüedad"},
        { field: "fecingreso", title: "Ingreso"},
        { field: "puestoClave", title: "Clave"},
        { field: "puestoNombre", title: "Puesto" },
        { field: "cencosClave", title: "Clave" },
        { field: "cencosNombre", title: "Centro" },
        { field: "regpatronalClave", title: "Registro Patronal" }, 
        { field: "sueldoDiario", title: "SD" },
        { field: "jornadaNombre", title: "Jornada" },
        { field: "deptoClave", title: "Clave" },
        { field: "deptoNombre", title: "Depto" },
        { field: "nominaClave", title: "Clave Nómina" },
        { field: "nominaNombre", title: "Nómina" },
        { field: "sexo", title: "Sexo" },
        { field: "nss", title: "NSS" },
        { field: "email", title: "email" },
        { field: "prestacionClave", title: "Prestación"},
        { field: "isnEstadoNombre", title: "Edo. ISN"},
        { field: "contratoNombre", title: "Contrato"},
        { field: "jornadaNombre", title: "Jornada"},
        { field: "tipoPago", title: "Tipo de Pago"},
        { field: "bancoNombre", title: "Banco"},
        { field: "cuentaBancaria", title: "Cuenta Bancaria"},
        { field: "clabeInterbancaria", title: "Clabe"},
    ] ;

    useEffect(() => {  
        axios.get(`${servicios_grupogack}/empleado`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEmpleados(ArrayJsonUsuariosReportesRRHH(res.data.dataResponse));
                    break;
                case 404:
                    setEmpleados([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);     

    return (
        <div id={ elementSelect.isStatic ? `${id}secondary`: id }>
            <style>
                {`
                    .btnback {
                        background-color: #ED1C27 !important; 
                        color: #fff !important;
                        border-color: #ED1C27 !important; 
                    }
                `}
            </style>
            
            {
                empleados && columns
                ?
                <React.Fragment>
                    <div className="row">
                        <div className="col-11"></div>
                        <div className="col-1 float-left" style={{marginBlockEnd:'18px'}}>
                            <button className="form-control btnback" onClick={()=>openReport(false)}> 
                                <Tooltip title="Regresar" placement="left-start">
                                    <ArrowBackIcon/>
                                </Tooltip>
                            </button> 
                        </div>
                    </div> 
                    <DataTableService id={ elementSelect.isStatic ? `${id}secondary`: id} title={'Reporte de Recursos Humanos'}
                        columns={columns}
                        data={empleados}
                        isReport
                        hideIconAdd
                        hideIconDelete
                        hideIconEdit
                        showIconPDF={elementSelect.isStatic}
                        informacionAdicional={{filtroGeneral, isStatic: elementSelect.isStatic}}
                        onShowTable={onAdd} 
                    />

                </React.Fragment>
                :
                <SpinnerOval/>
            }
        </div>
    );
}
 
export default RecursosHumanosTablaReporte;
import React from 'react';
import axios from 'axios';
import { servicios_grupogack_admon, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';

const ModalDeleteDomicilio = ({ openDelete, setOpenDelete, elementSelect, eliminar, errorTable, informacion }) => {

    const axiosEliminar = () => {
        axios.delete( `${servicios_grupogack_admon}/compania/domicilios/${informacion.dependenciaID}/${elementSelect.id}`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        eliminar( elementSelect.id );
                        break;
                    case 400:
                        errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_eliminado );
            })
    }
    return (
        <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
    );
};

export default ModalDeleteDomicilio;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioTiposAusentismos from './FormularioTiposAusentismos';

const ModalEditTiposAusentismos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );
    
    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarTipoAusentismo = ( ausentismo, ausentismoId ) => {
        ausentismo.id = ausentismoId;
        axios.put( `${servicios_grupogack_catalogo}/tipoausentismo/`, ausentismo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        editar( respuesta.data.dataResponse );
                        setOpenEdit( false );
                        break;
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Tipo Ausentismo" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioTiposAusentismos
                    error={error}
                    elementSelect={elementSelect}
                    actualizarTipoAusentismo={actualizarTipoAusentismo}
                />
        </ModalService>
    );
};

export default ModalEditTiposAusentismos;
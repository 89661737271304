import React, { useState, createRef } from 'react';

import axios from 'axios';
import { servicios_grupogack_mensajes } from '../../../../../../../Constants/Constants';
import { headersAuth, obtenerUsuario } from '../../../../../../../Services/AuthService';

import AutoCompleteUsuarios from './AutoCompleteUsuarios';
import TextField from '@material-ui/core/TextField';
import QuillComponent from './QuillComponent';

import { getToArray, getToNombreArray } from '../../HelperMensajes';
import SeleccionaElemento from '../SeleccionaElemento';

const EnviarMensaje = ({ usuarios, setPanelDerecho }) => {
    const asuntoRef = createRef('');
    const [ destinatarios, setDestinatarios ] = useState( [] );
    const [ mensaje, setMensaje ] = useState( '' );
    const { usuario, nombre, apaterno } = obtenerUsuario();

    const enviar = () => {
        if( destinatarios.length > 0 ){
            const asunto = asuntoRef.current.value;
            console.log({ from: usuario, fromNombre: `${nombre} ${apaterno}`, to: getToArray(destinatarios), toNombre: getToNombreArray(destinatarios), mensaje, asunto });
            axiosMensaje({ from: usuario, fromNombre: `${nombre} ${apaterno}`, to: getToArray(destinatarios), toNombre: getToNombreArray(destinatarios), mensaje, asunto });
        }
    }

    const axiosMensaje = ( mensaje ) => {
        axios.post( `${servicios_grupogack_mensajes}/`, mensaje, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setPanelDerecho( <SeleccionaElemento /> );
                        break;
                    case 400:
                        break;
                    default:
                        break;
                }
            })
            .catch( error => console.log(error) )
    }

    return (
        <div className="enviarMensaje">
            <AutoCompleteUsuarios usuarios={usuarios} setDestinatarios={setDestinatarios}/>
            <TextField variant="outlined" size="small" placeholder="Agregar un asunto"
                inputRef={asuntoRef}/>
            <QuillComponent mensaje={mensaje} setMensaje={setMensaje} actionEnviar={enviar}/>
        </div>
    );
};

export default EnviarMensaje;
import React, { Fragment } from 'react';
import ConvertDate from '../../../Helpers/ConvertDate';

const InfoDependientesEconomicos = ({ dep }) => {
    return (
        <Fragment>
            <div><strong>Nombre: </strong>{ dep.nombre }</div>
            <div className="formRelLab">
                <div className="form-left">
                    <div><strong>Primer Apellido: </strong>{ dep.apellidoPat }</div>
                    <div><strong>CURP: </strong>{ dep.curp ? dep.curp : '-' }</div>
                    <div><strong>Parentesco: </strong>{ dep.parentescoNom }</div>
                    <div><strong>Grado de Estudios: </strong>{ dep.gradoEscolar ? dep.gradoEscolar : '-' }</div>
                    <div><strong>Discapacidad: </strong>{ dep.depEconDiscapacidad }</div>
                </div>
                <div className="form-right">
                    <div><strong>Segundo Apellido: </strong>{ dep.apellidoMat }</div>
                    <div><strong>Fecha de Nacimiento: </strong>{ ConvertDate(dep.fechaNac) }</div>
                    <div><strong>Escolaridad: </strong>{ dep.escolaridadNom }</div>
                    <div><strong>Promedio: </strong>{ dep.promedio ? dep.promedio.toFixed(2) : '-'}</div>
                </div>
                {
                    (dep.depEconDiscapacidad === 'Sí')
                    ?   <Fragment >
                            <div className="form-left">
                                <div><strong>Nivel de Discapacidad: </strong>{ dep.discapacidad }</div>
                            </div>
                            <div className="form-right">
                                <div><strong>Nombre de la Discapacidad: </strong>{ dep.nombreDisc }</div>
                            </div>
                        </Fragment>
                    :   null
                }
            </div>
        </Fragment>
    );
}
 
export default InfoDependientesEconomicos;
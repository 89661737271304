import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { headersAuth, obtenerUsuario } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_mensajes } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

import { eliminarEtiquetas, actualizarBadge, mostrarFecha } from '../../HelperMensajes';
import Avatar from '@material-ui/core/Avatar';
import Checkbox from '@material-ui/core/Checkbox';

import AccionesEntrada from './AccionesEntrada';
import MostrarMensaje from '../MostrarMensaje/MostrarMensaje';
import SeleccionaElemento from '../SeleccionaElemento';
import SinElementos from '../SinElementos';

const BandejaEntrada = ({ setPanelDerecho, setAccionesDerecho }) => {
    const { usuario } = obtenerUsuario();
    const [ seleccionado, setSeleccionado ] = useState( '' );
    /* -------------------- Funcion para actualizar state noLeidos & Badge en HeaderAppBar -------------------- */
    const [ noLeidos, setNoLeidos ] = useState( null );
    const restarNoLeidos = () => {
        setNoLeidos( noLeidos - 1 );
        actualizarBadge( noLeidos - 1 );
    }
    /* ---------------------------- Axios para obtener mensajes Bandeja de Entrada ---------------------------- */
    const [ mensajesEntrada, setMensajesEntrada ] = useState( null );
    useEffect(() => {
        axiosMensajesEntrada();
        // eslint-disable-next-line
    }, []);

    const axiosMensajesEntrada = () => {/*
        axios.get( `${servicios_grupogack_mensajes}/entrada/${usuario}`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setMensajesEntrada( respuesta.data.dataResponse );
                        const noLeidos = respuesta.data.dataResponse.filter( email => email.estado === 'no leido' );
                        setNoLeidos( noLeidos.length );
                        actualizarBadge( noLeidos.length );
                        break;
                    case 404:
                        setMensajesEntrada([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })*/
    }
    /* -------------------------- Funciones para actualizar estado (mensajesEntrada) -------------------------- */
    const actualizarMensajeLeido = ( mensaje ) => {
        const newMensaje = mensaje;
        newMensaje[ 'estado' ] = 'leido';
        setMensajesEntrada( mensajesEntrada.map( element => element.id === mensaje.id ? newMensaje : element ) );
        restarNoLeidos();
    }

    const eliminarEntrada  = ( mensajeID ) => {
        setMensajesEntrada( mensajesEntrada.filter( element => element.id !== mensajeID ) );
        setAccionesDerecho( null );
        setPanelDerecho( <SeleccionaElemento /> );
    }

    const mostrarMensaje = ( mensaje ) => {
        setPanelDerecho( <MostrarMensaje mensaje={mensaje}/> );
        setAccionesDerecho( <AccionesEntrada mensaje={mensaje} usuario={usuario} eliminarEntrada={eliminarEntrada}/> );
        setSeleccionado( mensaje.id );
        if( mensaje.estado === 'no leido' ){
            axiosMensajeLeido( mensaje );
        }
    }
    /* ------------------------------- Axios para actualizar mensaje como leido ------------------------------- */
    const axiosMensajeLeido = ( mensaje ) => {
        axios.put( `${servicios_grupogack_mensajes}/marcar/leido/${mensaje.id}/${usuario}`, null, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        actualizarMensajeLeido( mensaje );
                        break;
                    case 400:
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <React.Fragment>
            { mensajesEntrada ?
                mensajesEntrada.length > 0 ?
                    mensajesEntrada.map( mensaje => {
                        if( mensaje.estado === 'no leido' ){
                            return(
                                <div key={mensaje.id} className={ `mensajeEntrada noLeido ${ seleccionado === mensaje.id && 'seleccionado' }` }
                                    onClick={ () => mostrarMensaje( mensaje ) }>
                                    <div className="mensajesFlex">
                                        <Avatar src={`data:image/jpeg;base64, ${mensaje.foto}`} className="mensajesAvatar"/>
                                        <div className="divAvatarFrom">
                                            <div className="toNoLeido"> {mensaje.fromNombre} </div>
                                        </div>
                                        <div className="divFecha">
                                            <div className="mensajesFecha"> { mostrarFecha(mensaje.fechaHora) } </div>
                                        </div>
                                    </div>
                                    <div className="mensajesFlex">
                                        <div className="mensajesPunto"></div>
                                        <div className="mensajesAsunto asuntoNoLeido"> {mensaje.asunto} </div>
                                    </div>
                                    <div className="mensajesFlex">
                                        <Checkbox className="mensajesCheckBox"/>
                                        <div className="mensajesMensaje"> {eliminarEtiquetas(mensaje.mensaje)} </div>
                                    </div>
                                </div>
                            );
                        }else{
                            return(
                                <div key={mensaje.id} className={ `mensajeEntrada siLeido ${ seleccionado === mensaje.id && 'seleccionado' }` }
                                    onClick={ () => mostrarMensaje( mensaje ) }>
                                    <div className="mensajesFlex">
                                        <Avatar src={`data:image/jpeg;base64, ${mensaje.foto}`} className="mensajesAvatar"/>
                                        <div className="divAvatarFrom">
                                            <div className="toLeido"> {mensaje.fromNombre} </div>
                                        </div>
                                        <div className="divFecha">
                                            <div className="mensajesFecha"> { mostrarFecha(mensaje.fechaHora) } </div>
                                        </div>
                                    </div>
                                    <div className="mensajesFlex">
                                        <div className="marginAsunto"></div>
                                        <div className="mensajesAsunto asuntoLeido"> {mensaje.asunto} </div>
                                    </div>
                                    <div className="mensajesFlex">
                                        <Checkbox className="mensajesCheckBox"/>
                                        <div className="mensajesMensaje"> {eliminarEtiquetas(mensaje.mensaje)} </div>
                                    </div>
                                </div>
                            );
                        }
                    })
                    : <SinElementos />
                : <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default BandejaEntrada;
import React, { useState, createRef } from 'react';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import { validarSubmit, deleteOptionals } from '../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../Services/Validation/InputValidation';

import { servicios_grupogack_url, cambios_no_guardados, ocurrio_un_error } from '../../../../../Constants/Constants';
import { headersAuth, actualizarUsuarioStorage, obtenerUsuario } from '../../../../../Services/AuthService';
import AlertForm from '../../../../../Services/AlertForm';

const FormularioInformacion = ({ usuario, setUsuario, setOpenForm }) => {
    const [ error, setError ] = useState( null );
    const usuarioNomRef = createRef('');
    const usuarioPatRef = createRef('');
    const usuarioMatRef = createRef('');

    const actualizar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'EditarPerfil' ) ){
            const usuarioNom = usuarioNomRef.current.value;
            const usuarioPat = usuarioPatRef.current.value;
            const usuarioMat = usuarioMatRef.current.value;
            //console.log( deleteOptionals({ usuarioNom, usuarioPat, usuarioMat }) );
            actualizarUsuario( deleteOptionals({ usuarioNom, usuarioPat, usuarioMat }) );
        }
    }

    const actualizarUsuario = ( informacionUsuario ) => {
        axios.put( `${servicios_grupogack_url}/info`, informacionUsuario, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        actualizarUsuarioStorage( informacionUsuario );
                        setUsuario( obtenerUsuario() );
                        setOpenForm( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        setError( <AlertForm message={ cambios_no_guardados } /> );
                        break;
                }
            })
            .catch( error => {
                setError( <AlertForm message={ ocurrio_un_error } /> );
            })
    }

    return (
        <form id="EditarPerfil" onSubmit={actualizar}>
            <InputValidation title="Nombre" type="usuarioNombre" placeholder="Escriba su nombre"
                tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUsuarioNom'} maxLength='100'
                refValue={usuarioNomRef} defaultValue={ usuario.nombre } required/>
            <InputValidation title="Primer Apellido" type="usuarioApellido" placeholder="Escriba su primer apellido"
                tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUsuarioPat'} maxLength='100'
                refValue={usuarioPatRef} defaultValue={ usuario.apaterno } required/>
            <InputValidation title="Segundo Apellido" type="usuarioApellido" placeholder="Escriba su segundo apellido"
                tooltipText={'Máximo 100 letras, espacios y/o signos de puntuación . , -'} tooltipName={'tooltipUsuarioMat'} maxLength='100'
                refValue={usuarioMatRef} defaultValue={ usuario.amaterno }
                optional={true} />
            { error }
            <Button className="btn-color" type="submit"> Guardar Cambios </Button>
        </form>
    );
};

export default FormularioInformacion;
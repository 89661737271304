import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupogack_admon, servicios_grupogack_org } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import OrganigramaModal from './Organigrama/OrganigramaModal';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import OrganigramaAdscripcion from './Organigrama/CentrosAdscripcion/OrganigramaAdscripcion';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';

const Departamentos = ({ permisos }) => {

    const [cAdList, setCAdList] = useState([]);
    const [cAdSelected, setCAdSelected] = useState(null);
    const [organigrama, setOrganigrama] = useState([]);
    const [openAlertSyESuccess, setOpenAlertSyESuccess] = useState(false);
    const [openAlertSyEError, setOpenAlertSyEError] = useState(false);
    const [mensajeError, setMensajeError] = useState('');
    const [toDoOrg, setToDoOrg] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState({ state: false, type: null, data: {} });

    useEffect(() => {
        axios.get(`${servicios_grupogack_admon}/compania`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setCAdList(res.data.dataResponse);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    }, []);

    const guardaSeleccionado = valor => {
        setCAdSelected(valor);
        if(valor) {
            axios.get(`${servicios_grupogack_org}/area/organigrama/${valor.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setOrganigrama(res.data.dataResponse);
                        break;
                    case 404:
                        setOrganigrama(null);
                        break;
                    default:
                        setOrganigrama([]);
                        alert('No se ha podido consultar');
                        break;
                }
            })
            .catch((error) => {
                alert('No se ha podido consultar');
            });
        } else {
            setOrganigrama([]);
        }
    };

    const enviarNuevoOrg = data => {
        const info = {
                        ciaid: cAdSelected.id,
                        areacve: data.clave,
                        areanivel: data.nivel,
                        areasecuencia: 1,
                        areanom: data.nombre,
                        areadescrip: data.descripcion,
                        areaidsup: data.id,
                        presupuestal: data.presupuestal
                    };

        axios.post(`${servicios_grupogack_org}/area/`, info, headersAuth())
        .then(res => {
            if(res.data.status === 200) {
                setOrganigrama([res.data.dataResponse]);
                setOpenAlertSyESuccess(true);
            } else {
                setMensajeError(res.data.msg);
                setOpenAlertSyEError(true);
            }
        })
        .catch((error) => {
            setOpenAlertSyEError(true);
        });
    };

    return (
        <div id="organigrama">
            <AutocompleteComponent id="D001" title="Dependencia" options={cAdList} optionListView="ciaRazonSocial" action={guardaSeleccionado} disabled={false} size="100%" placeholder="Escriba el nombre o clave de la Dependencia"/>
            {
                (organigrama === null)
                ?   <Fragment>
                        <AlertForm message="No hay elementos" />
                        {   (permisos.find(permiso => permiso.id === 1)) &&
                            <Fragment>
                                <button className="btn btn-color-form" onClick={() => setModalIsOpen({ state: true, type: 'agregar', data: { id: 0, nivel: 1 } }) }>Comenzar nuevo organigrama</button>
                                <AlertSyE show={openAlertSyESuccess} setOpenAlertSyE={setOpenAlertSyESuccess} title="Centro de Adscripción" text="La tarea se realizó con éxito" textbtnconfirm="Aceptar" type="success" action={() => setModalIsOpen({ state: false, type: null, data: {} })}/>
                                <AlertSyE show={openAlertSyEError} setOpenAlertSyE={setOpenAlertSyEError} title="Centro de Adscripción" text={mensajeError} textbtnconfirm="Aceptar" type="error" />
                                <OrganigramaModal modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} enviar={enviarNuevoOrg} id={cAdSelected.id}/>
                            </Fragment>
                        }
                    </Fragment>
                :   (cAdSelected !== null)
                    ? <OrganigramaAdscripcion id={cAdSelected.id} organigrama={organigrama} setOrganigrama={setOrganigrama} toDoOrg={toDoOrg} setToDoOrg={setToDoOrg} permisos={permisos}/>
                    : null
            }
        </div>
    );
}
 
export default Departamentos;
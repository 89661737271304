import React, {  useState, useEffect, createRef, Fragment } from 'react';
import { Button }from '@material-ui/core'; 
import { servicios_grupogack, servicios_grupogack_nomina } from '../../../../../../Constants/Constants'; 
import axios from 'axios'; 
import { headersAuth } from '../../../../../../Services/AuthService';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import { validarSubmit,inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../Services/AlertForm'; 
import { ArrayJsonNominas } from '../../../Helpers/JsonToOneLevel';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import InputValidation from    '../../../../../../Services/Validation/InputValidation';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import TooltipComponent from '../../../../../../../src/Services/Tooltip/TooltipComponent';

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const FormularioNewReportNomina = ({ error, enviarNewReportNomina, enviarReportDownload, isLoading, setError, downloadReportDefinido, setDownloadReportDefinido, infoReport}) => { 


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 180,
      maxWidth: 350,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 350,
      },
    },
  };

  
  const names = [
    {tipoacum_nom: 'TODOS', tipoacum_id: 0},
    {tipoacum_nom: 'AGUINALDO', tipoacum_id: 2},
    {tipoacum_nom: 'BONOS', tipoacum_id: 8},
    {tipoacum_nom: 'FINIQUITO', tipoacum_id: 5},
    {tipoacum_nom: 'FONDO DE AHORRO', tipoacum_id: 4},
    {tipoacum_nom: 'INDEMNIZACIONES', tipoacum_id: 9},
    {tipoacum_nom: 'NÓMINA', tipoacum_id: 1},
    {tipoacum_nom: 'OTROS', tipoacum_id: 12},
    {tipoacum_nom: 'PENSIONES', tipoacum_id: 10},
    {tipoacum_nom: 'PTU', tipoacum_id: 3},
    {tipoacum_nom: 'SEGUROS', tipoacum_id: 11},
    {tipoacum_nom: 'VALES', tipoacum_id: 7},
    {tipoacum_nom: 'VIÁTICOS', tipoacum_id: 6}
      ];
  
  const classes = useStyles();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);
  
  const handleChange = (event) => {
    setPersonName(event.target.value);
  };
  
  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setPersonName(value);
  };
  
    

    const nominaRef= createRef('');
    const agnioRef = createRef('');
    const periodoRef= createRef('');
    const tipoAcumuladoRef = createRef('');
    const conceptosRef = createRef('');
    const fechaInicioRef = createRef('');
    const fechaFinalRef = createRef('');
    const anio = createRef('');
    const periodo = createRef('');
    const refAnio = createRef('');
    const refPeriodoIni = createRef('');
    const refPeriodoFin = createRef('');
    const refTipoP = createRef('');
    const refDispersion = createRef('');
    var periodoFinperanio = 0;
    const periodoanio = createRef('');

    const [ nominas, setNominas ] = useState(null);
    const [ agniosNomina, setAgniosNomina ] = useState(null); 
    const [ periodosAgnio, setPeriodosAgnio ] =  useState(null);
    const [ msgError, setMsgError ] = useState(null);
    const [tiposAcumulado, setTiposAcumulado] = useState(null);
    const [ conceptos, setConceptos ] = useState(null);

    useEffect(()=>{ 

        axios.get( `${servicios_grupogack}/nomina`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( ArrayJsonNominas( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        setNominas( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });  
            // eslint-disable-next-line
    },[]);

    const enviar = (evt) => {
        evt.preventDefault(); 
        if(validarSubmit('NewReportNomina')) { 
            const nominaId = nominaRef.current.value;
            if(downloadReportDefinido){
                if(infoReport==='Infonavit' || infoReport==='Fonacot' || infoReport === 'Ausentismos'){
                    const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
                    const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
                    //periodoanio = agnioRef.current ? agnioRef.current.value : null;
                    enviarReportDownload({ nominaId,fechaInicio,fechaFinal });
                }else{
                    const periodoanio = refAnio.current.value;
                    const acumulado =   personName;
                    const periodoIniperanio = refPeriodoIni.current.value;
                    if(infoReport==='Dispersión'){
                        periodoFinperanio = 0;
                    }else{
                        periodoFinperanio = refPeriodoFin.current.value;
                    }
                    //const acumulado = tiposAcumulado.filter( element => element.acumuladoNombre===tipoAcumuladoRef.current.value)[0].acumuladoId;
                    if(infoReport==='Formato Vertical'||infoReport==='Formato Horizontal 1'||infoReport==='Formato Horizontal 2' || infoReport==='Dispersión'){
                        const concepto = "";
                        enviarReportDownload({nominaId, periodoIniperanio, periodoFinperanio, periodoanio, acumulado, concepto });
                    }else{
                        enviarReportDownload({nominaId, periodoIniperanio, periodoanio, acumulado });
                    }
                }                
            }else{
                const periodoperanio = periodoRef.current.value;
               enviarNewReportNomina({nominaId, periodoperanio, periodoanio }); 
            }
        } 
    }
 
    const searchAgnioNomina = (nominaId) =>{ 
        if(nominaId!==""){
            setAgniosNomina(null);
            setPeriodosAgnio(null);
            setConceptos(null);
            setTiposAcumulado(null);
            axios.get( `${servicios_grupogack_nomina}/acumulados/periodo/${nominaId}`, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        setAgniosNomina(respuesta.data.dataResponse)
                        break;
                    case 404:
                        setAgniosNomina(null);
                        setMsgError(<AlertForm message={respuesta.data.msg} />)
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) });
        }else{ 
            setAgniosNomina(null);
            setPeriodosAgnio(null);
            setConceptos(null);
            setTiposAcumulado(null);
            setError(null);
            setMsgError(null);
        }
    }

    const searchPeriodosNomina = ( periodoID ) => { 
        if(periodoID!==""){
            setPeriodosAgnio(null);
            axios.get( `${servicios_grupogack_nomina}/acumulados/peranio/${nominaRef.current.value}/${periodoID}`, headersAuth() )
                .then( respuesta => { 
                    switch( respuesta.data.status ){
                        case 200:
                            setPeriodosAgnio(respuesta.data.dataResponse)
                            break;
                        case 404:
                            setPeriodosAgnio( [] );
                            break;
                        default:
                            break;
                    }
                })
                .catch( error => { console.log( error ) });
        }else{ 
            setPeriodosAgnio(null);
            setError(null);
            setMsgError(null);
        }
    } 
    
    const searchAcumulado = ( value ) =>{
        setError(null);
        setConceptos(null);
        if(downloadReportDefinido && value!==''){
            setTiposAcumulado(null);
            //const periodoId = periodosAgnio.filter(element=> element.periodoPerAnio===periodoRef.current.value)[0].periodoId;
            axios.get(`${servicios_grupogack}/catalogo/acumulado/`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        setTiposAcumulado(res.data.dataResponse);
                        break;
                    case 404:
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => { alert('error') });
        }else{
            setTiposAcumulado(null);
        }
    }

    const searchConceptos = ( value )=>{
        setError(null);
        setConceptos(null);
        if(downloadReportDefinido && infoReport==='Formato Vertical' && value!==''){
            const datosreq = {
                "anio" : 2020,
                "periodoIni": 1,
                "periodoFin": 1,
                "acumulados" : [ 1 ]
            };
            const periodoperanio = periodosAgnio.filter(element=> element.periodoPerAnio===periodoRef.current.value)[0].periodoId;
            axios.post(`${servicios_grupogack_nomina}/acumulados/procesos/${nominaRef.current.value}/${periodoperanio}`, datosreq, headersAuth())
            .then(respues => {
                switch(respues.data.status) {
                    case 200:
                        const indicadores = respues.data.dataResponse.map(item => (
                            {
                                id: item.indicadorId,
                                indicadorNomClave: `${item.nominaClave} - ${item.nominaIndicador}`
                            }
                        ));
                        console.log();
                        
                        setConceptos(indicadores);
                        break;
                    case 404:
                        setConceptos([]);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert('error'));
        }else{
            setConceptos(null);
        }
    }
    
    return (
        <React.Fragment>
            { 
                <form id={'NewReportNomina' } onSubmit={ enviar }>
                    {
                        infoReport==='Infonavit' || infoReport==='Fonacot'  || infoReport==='Ausentismos' ? 
                        <Fragment>
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    {
                                        nominas ? <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nominaClaveNombre" 
                                        refValue={nominaRef} required /> : <SpinnerOval/>
                                    } 
                                </div>
                            </div>
                            <div className="row">
                                    <div className="col-md-6">
                                        <InputDateValidation title="Fecha Inicial" refValue={fechaInicioRef} required
                                            defaultValue={''} />
                                    </div>
                                    <div className="col-md-6">
                                        <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} required
                                            defaultValue={''} />
                                    </div>
                            </div>
                            {error}
                            {msgError}
                            <div className="row">
                                <div className="col-md-12 col-sm-12">
                                    {
                                        isLoading ? 
                                        <SpinnerOval /> 
                                        :   
                                        <Button variant="contained" className="btn-color" type="submit">{ 'Generar' }</Button>  
                                    } 
                                </div>
                            </div>
                        </Fragment>
                        :
                        <Fragment>
                            <div className="row">
                                                             
                                <div className="col-md-12 col-sm-12">
                                    {
                                        nominas ? <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nominaClaveNombre" 
                                        refValue={nominaRef}  required /> : <SpinnerOval/>
                                    } 
                                </div> 
                            </div>

                            <div className="row">                                
                                { 
                                    infoReport!=='Dispersión' ?                                
                                    <Fragment>

                                <div className={periodosAgnio ? `col-md-6 col-sm-12` : `col-md-12 col-sm-12`}>
                                </div>
                                {
                                     <div className={`col-md-4 col-sm-12`}>
                                    <InputValidation title="Año" type="text" placeholder="Año" refValue={refAnio}
                                        tooltipText="4 números" tooltipName="Año" maxLength={4} onlyNumbers optional={true}/>
                                </div>
                                }
                                <div className={`col-md-4 col-sm-12`}>
                                    <InputValidation title="Periodo Inicial" type="text" placeholder="Periodo Inicial" refValue={refPeriodoIni}
                                        tooltipText="2 números" tooltipName="Periodo Inicial" maxLength={2} onlyNumbers optional={true}/>
                                </div>
                                
                                <div className={`col-md-4 col-sm-12`}>
                                    <InputValidation title="Periodo Final" type="text" placeholder="Periodo Final" refValue={refPeriodoFin}
                                        tooltipText="2 números" tooltipName="Periodo Final" maxLength={2} onlyNumbers optional={true}/>
                                </div>
                                    </Fragment>
                                :
                                <Fragment>
                                
                                {
                                     <div className={`col-md-4 col-sm-12`}>
                                    <InputValidation title="Año" type="text" placeholder="Año" refValue={refAnio}
                                        tooltipText="4 números" tooltipName="Año" maxLength={4} onlyNumbers optional={true}/>
                                </div>
                                }
                                
                                <div className={`col-md-4 col-sm-12`}>
                                <label> 
                                Formas de Pago<strong className="requerido">* </strong> 
                                <TooltipComponent tooltipText="Formas de Pago">
                                    <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                                </TooltipComponent>
                                </label>
                                <select className={ `form-control`}  refValue={refTipoP}>
                                <optgroup label="Seleccione">
                                    <option key={0} value="0">Todas</option>
                                    <option key={1} value="1">Efectivo</option>
                                    <option key={2} value="2">Cheque nominativo</option>
                                    <option key={3} value="3">Transferencia Electrónica de Fondos</option>
                                    <option key={4} value="4">Monedero Electrónico</option>
                                    <option key={5} value="5">Por definir</option>
                                    </optgroup>
                                </select>
                                </div>

                                <div className={`col-md-4 col-sm-12`}>
                                <label>
                                Tipos de Dispersión<strong className="requerido">* </strong> 
                                <TooltipComponent tooltipText="Tipo de Dispersión">
                                    <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                                </TooltipComponent>
                                </label>
                                <select refValue={refDispersion} className={ `form-control`}
                                 defaultValue="0">
                                <optgroup label="Seleccione">
                                    <option key={1} value="1">Regular</option>
                                    <option key={2} value="2">Especie</option>
                                    </optgroup>
                                </select>
                                </div>
                             
                                <div className={`col-md-4 col-sm-12`}>
                                    <InputValidation title="Periodo" type="text" placeholder="Periodo" refValue={refPeriodoIni}
                                        tooltipText="2 números" tooltipName="Periodo" maxLength={2} onlyNumbers optional={true}/>
                                </div>
                                
                                    </Fragment>
                                }

                                {
                                    <div className={`col-md-6 col-sm-12`}>
                                    <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">Tipos de Acumulados</InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input />}
          //renderValue = {(selected) => selected.join(', ')}
          renderValue={
            personName.length > 0
              ? undefined
              : () => <em>Tipos de acumulados</em>
          }
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name.tipoacum_id} value={name.tipoacum_id}>
              {name.tipoacum_nom}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      </div>
                                }
                            
                                <div className="col-md-12 col-sm-12">
                                    {
                                        isLoading ? 
                                        <SpinnerOval /> 
                                        :   
                                        <Button variant="contained" className="btn-color" type="submit">{ 'Generar' }</Button>  
                                    } 
                                </div>
                            </div>
                            {
                                tiposAcumulado &&
                                <div className="row">
                                    <div className={ conceptos ? "col-md-6 col-sm-12" : "col-md-12 col-sm-12"}>
                                        <SelectValidation title="Tipo de Acumulado" arrayOpciones={tiposAcumulado} keyOpcion="acumuladoNombre"
                                            refValue={tipoAcumuladoRef} onChangeExecute={searchConceptos} required/>
                                    </div>

                                    {
                                    conceptos && 
                                        <div className={`col-md-6 col-sm-12`}>
                                                <SelectValidation title="Concepto" arrayOpciones={conceptos} keyOpcion="indicadorNomClave" 
                                                refValue={conceptosRef} onChangeExecute={()=>setError(null)} required/>
                                        </div> 
                                    }
                                </div>
                            }
                                
                            {error}
                            {msgError}
                            {   
                                downloadReportDefinido
                                ?
                                    tiposAcumulado && infoReport!=='Concepto'
                                    ? 
                                        isLoading 
                                        ? 
                                            <SpinnerOval /> 
                                        : 
                                            <Button variant="contained" className="btn-color" type="submit">{ 'Generar' }</Button> 
                                    :
                                        conceptos 
                                        ?
                                            isLoading ? 
                                                <SpinnerOval /> 
                                            : 
                                                <Button variant="contained" className="btn-color" type="submit">{ 'Generar' }</Button>
                                        : null
                                :
                                    periodosAgnio ?
                                        isLoading ? 
                                            <SpinnerOval /> 
                                            :   
                                            <Button variant="contained" className="btn-color" type="submit">{ 'Generar' }</Button>  
                                        :null
                                
                            }
                        </Fragment>
                    }
                </form> 

            }
        </React.Fragment>
    );
};

export default FormularioNewReportNomina;
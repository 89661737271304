import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import ConvertDate from '../../../Helpers/ConvertDate';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const InfoNomina = ({ elementSelect, periodo, setPeriodo, setOpenEdit }) => {

    const [openAlert, setOpenAlert] = useState(false);

    useEffect(() => {
        axios.get(`${servicios_grupogack_nomina}/config/periodos/ultimo/${elementSelect.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPeriodo(res.data.dataResponse);
                    break;
                case 404:
                    setOpenAlert(true);
                    break;
                default:
                    setOpenEdit(false);
                    break;
            }
        })
        .catch(error => { setOpenEdit(false); });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSelect]);

    const cerrarModal = () => {
        setOpenEdit(false)
    };

    return (
        <Fragment>
            {
                (periodo)
                ?   <Fragment>
                        <div><label><strong>Clave: </strong>{ elementSelect.nominaClave }</label></div>
                        <div><label><strong>Nombre Corto: </strong>{ elementSelect.nominaNomCorto }</label></div>
                        <div><label><strong>Nombre: </strong>{ elementSelect.nominaNombre }</label></div>
                        <div><label><strong>Descripción: </strong>{ elementSelect.nominaDescrip }</label></div>
                        <div><label><strong>Periodicidad: </strong>{ elementSelect.periodicidad.perdenomNombre }</label></div>
                        <div><label><strong>Origen del Recurso: </strong>{ elementSelect.origenRecurso.origenRecDesc }</label></div>
                        <div><label><strong>Régimen Físcal: </strong>{ elementSelect.regimenFiscal.regFiscalDesc }</label></div>
                        <div><label><strong>Tipo de Régimen: </strong>{ elementSelect.tipoRegimen.regimenDesc }</label></div>
                        <div><label><strong>Número de Periodo: </strong>{ (periodo) ? periodo.periodoPerAnio : '-' }</label></div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <div><label><strong>Fecha Inicial: </strong>{ (periodo) ? ConvertDate(periodo.periodoFecini) : '--/--/----' }</label></div>
                            </div>
                            <div className="form-right">
                                <div><label><strong>Fecha Final: </strong>{ (periodo) ? ConvertDate(periodo.periodoFecfin) : '--/--/----' }</label></div>
                            </div>
                        </div>
                    </Fragment>
                :   <SpinnerOval />

            }
            <AlertSyE show={openAlert} setOpenAlertSyE={setOpenAlert} title="No hay periodos disponibles para esta nómina" text="Debe generar el calendario de periodos"
                textbtnconfirm="Aceptar" type="error" action={cerrarModal}/>
        </Fragment>
        
    );
}
 
export default InfoNomina;
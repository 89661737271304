import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import { Bar } from 'react-chartjs-2';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Menu from '@material-ui/core/Menu';

const getCanvasJPG = ( id ) => {
    var canvas = document.getElementById( id );
    if( canvas ){ //Procedimiento para obtener un canvas/jpeg con fondo blanco
        var newCanvas = canvas.cloneNode(true);
        var ctx = newCanvas.getContext('2d');
        ctx.fillStyle = "#FFF";
        ctx.fillRect(0, 0, newCanvas.width, newCanvas.height);
        ctx.drawImage(canvas, 0, 0);
        return newCanvas.toDataURL("image/jpeg");
    }
}

const getCanvasPNG = ( id ) => {
    var canvas = document.getElementById( id );
    if( canvas ){
        return canvas.toDataURL("image/png");
    }
}

function GraficaEstadisticas({ estadisticas }) {
    const [ anchorEl, setAnchorEl ] = useState( null );
    const data = {
        //labels: estadisticas.keys,
        labels: [ 'Total de Empleados', 'Empleados Activos', 'Empleados Inactivos', 'Empleados Masculino', 'Empleados Femenino', 'Edad Promedio' ],
        datasets: [
            {
                label: 'Cantidad',
                backgroundColor: 'rgba(129,161,221,1)',
                borderColor: 'rgba(129,161,221,1)',
                borderWidth: 2,
                hoverBackgroundColor: 'rgba(129,161,221,0.8)',
                hoverBorderColor: 'rgba(129,161,221,1)',
                data: estadisticas.values,
            },
        ]
    };

    const [ urls, setUrls ] = useState( null );
    const showOptions = (evt) => {
        setAnchorEl( evt.currentTarget );
        if( !urls ){
            setUrls([ getCanvasJPG('graphEstadisticas'), getCanvasPNG('graphEstadisticas') ]);
        }
    }

    return (
        <div className="row">
            <div className="col-md-11 col-sm-11">
                <Card>
                    <CardHeader action={ <IconButton onClick={(evt) => showOptions(evt)}> <MenuIcon /> </IconButton> } />
                    <CardContent>
                        <Bar id="graphEstadisticas" data={data} width={100} height={250} options={{ maintainAspectRatio: false }} />
                    </CardContent>
                </Card>

                { urls &&
                    <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} >
                        <a className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
                            href={urls[0]} download={ `Estadísticas.jpeg` } onClick={() => setAnchorEl(null)}>
                            Exportar JPG
                        </a>
                        <a className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root MuiMenuItem-gutters MuiListItem-gutters MuiListItem-button"
                            href={urls[1]} download={ `Estadísticas.png` } onClick={() => setAnchorEl(null)}>
                            Exportar PNG
                        </a>
                    </Menu>
                }
            </div>
        </div>
    );
}

export default GraficaEstadisticas;
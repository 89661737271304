import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, servicios_grupogack_rh, cambios_no_guardados, registro_actualizado, registro_guardado, registro_no_guardado,
            registro_no_eliminado, registro_eliminado } from '../../../../../../Constants/Constants';
import { validarSubmit, longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import ModalOrganizacionEmpleadoEdit from './OrganizacionEmpleado/ModalOrganizacionEmpleadoEdit';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import AlertForm from '../../../../../../Services/AlertForm';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import Sueldo from './OrganizacionEmpleado/Sueldo';
import RegistroPatronal from './OrganizacionEmpleado/RegistroPatronal';
import Sindicato from './OrganizacionEmpleado/Sindicato';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';

const FormOrganizacionEmpleado = (props) => {

    const {
            successTable, errorTable, irInfo, actualizar, enviar, elementSelect, selected,
            monto, setMonto, indicadores, enviarEmpleado, disabled, setDisabled, disabledFormOrgEdit,
            setDisabledFormOrgEdit, idTurno, infoContrato, fechaAguinaldo, fechaNombramiento,
            cPresupuestales, validarCPresupuestal, departamentos,
            validarDepartamento, plazas, validarPlaza, validaAutocompleteOrganizacion,
            zonasPago, zonaPagoRef, turnos, turnoRef, puestos, validarPuesto, nominas, nominaRef,
            formaPagoRef, formasPagoSelected, setFormasPagoSelected, setIndicadoresSelected, indicadoresSelected,
            bancoRef, numCuentaRef, cbeInterRef, contratoRef,
            fechaInicialContratoRef, fechaFinalContratoRef, fechaNombramientoRef, fechaAguinaldoRef,
            regPatronalRef, infoRegPatronal,
            sindicatoRef, sindicalizado, setSindicalizado, cuotaSindical, setCuotaSindical,
            sindicatoSeleccionado, setSindicatoSeleccionado, infoSindicato, errorFormPago, setErrorFormPago,
            errorCPresupuestal, errorDepartamento, errorPlaza, errorPuesto
    } = props;
    
    const [muestraFormDatosBancarios, setMuestraFormDatosBancarios] = useState(false);
    const [editarElementoFormaPago, setEditarElementoFormaPago] = useState(null);
    const [consultaCuentahabiente, setConsultaCuentahabiente] = useState(false);
    const [infoClavePresupuestal, setInfoClavePresupuestal] = useState(null);
    const [enabledFechaContrato, setEnabledFechaContrato] = useState(false);
    const [mostrarContenido, setMostrarContenido] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false);
    const [formasPago, setFormasPago] = useState([]);
    const [contratos, setContratos] = useState([]);
    const [idNomina, setIdNomina] = useState('');
    const [bancos, setBancos] = useState([]);

    useState(() => {
        if(elementSelect && infoContrato) {
            if(infoContrato.fechafin !== null) setEnabledFechaContrato(true);
        }
    }, [infoContrato]);

    const guardar = e => {
        e.preventDefault();
        if(formaPagoRef.current.value) {
            setOpenAlert(true);
        } else {
            enviar();
        }
    };

    const abrirModal = () => {
        if(validarSubmit('OrganizacionEmpleado')) {
            if(selected.cPresupuestal.id !== '' && selected.departamento.id !== '' && selected.plaza.id !== '' && selected.puesto.id !== '') {
                setModalIsOpen(true);
            } else {
                if(disabledFormOrgEdit.cPresupuestal === true && disabledFormOrgEdit.departamento === true && disabledFormOrgEdit.plaza === true &&
                    disabledFormOrgEdit.zonaPago === true && disabledFormOrgEdit.puesto === true && disabledFormOrgEdit.turno === true && disabledFormOrgEdit.nomina === true) {
                    actualizar();
                } else {
                    validaAutocompleteOrganizacion();
                }
            }
        } else {
            validaAutocompleteOrganizacion();
        }
    };

    const editar = e => {
        e.preventDefault();
        if(formaPagoRef.current.value !== '') {
            setOpenAlert(true);
        } else {
            abrirModal();
        }
    };
    
    const muestraEditarFPago = elemento => {
        obtenerBancos();
        setTimeout(() => {
            if(elemento === editarElementoFormaPago) {
                formaPagoRef.current.value = '';
                setEditarElementoFormaPago(null);
            } else {
                switch(elemento.formapago) {
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                        formaPagoRef.current.value = elemento.formapago;
                        setEditarElementoFormaPago(elemento);
                    break;
                    default:
                        formaPagoRef.current.value = '';
                        setEditarElementoFormaPago(null);
                    break;
                }
            }
        }, 500);
    };

    const validaContrato = idContrato => {
        if(idContrato !== '') {
            const contrato = contratos.find(c => c.id === parseInt(idContrato));
            if(contrato.contratoSaoFechas && infoContrato) fechaFinalContratoRef.current.value = longDateToInput(infoContrato.fechafin);
            else fechaFinalContratoRef.current.value = '';
            setEnabledFechaContrato(contrato.contratoSaoFechas);
        } else {
            fechaFinalContratoRef.current.value = '';
            setEnabledFechaContrato(false);
        }
    };

    const eliminaFormaPago = elemento => {
        if(formasPagoSelected.length < 2) {
            errorTable("El empleado debe tener, al menos, una Forma de Pago asignada");
        } else {
            axios.delete(`${servicios_grupogack_rh}/empleado/formapago/${elemento.idFormaPago}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const formasPago = formasPagoSelected.filter(fpago => fpago.idFormaPago !== elemento.idFormaPago);
                        setFormasPagoSelected(formasPago);
                        successTable(registro_eliminado);
                        setEditarElementoFormaPago(null);
                        break;
                    default:
                        errorTable(registro_no_eliminado);
                        break;
                }
            })
            .catch(error => errorTable(registro_no_eliminado));
        }
    };

    const editarFormaPago = e => {
        e.preventDefault();
        setErrorFormPago({ active: false, message: '' });
        
        if(validarSubmit('formasPago')) {
            const id = parseInt(editarElementoFormaPago.idCuentahabiente)
            const nuevoElemento = {
                banco: parseInt(bancoRef.current.value),
                clabe: cbeInterRef.current.value,
                nocuenta: numCuentaRef.current.value
            };

            axios.put(`${servicios_grupogack_rh}/empleado/cuentahabiente/${id}`, nuevoElemento, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const formasPago = formasPagoSelected.map(fpago => (
                            (fpago.id === res.data.dataResponse.id)
                            ?   {   
                                    idCuentahabiente: res.data.dataResponse.id,
                                    idFormaPago: res.data.dataResponse.empleadoFormaPago.id,
                                    formapago: res.data.dataResponse.empleadoFormaPago.formapago.id,
                                    formaPagoNom: res.data.dataResponse.empleadoFormaPago.formapago.formapagoNom,
                                    bancoId: res.data.dataResponse.banco.id,
                                    banco: res.data.dataResponse.banco.bancoNom,
                                    cbeInterbancaria: res.data.dataResponse.clabe,
                                    numCuenta: res.data.dataResponse.nocuenta
                                }
                            :   fpago
                        ))
                        setFormasPagoSelected(formasPago);
                        successTable(registro_actualizado);
                        formaPagoRef.current.value = '';
                        setEditarElementoFormaPago(null);
                        break;
                    default:
                        errorTable(cambios_no_guardados);
                        break;
                }
            })
            .catch(error => errorTable(cambios_no_guardados));
        }
    };

    const obtenerBancos = () => {
        axios.get(`${servicios_grupogack_catalogo}/banco/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setBancos(res.data.dataResponse);
                    break;
                case 404:
                    setBancos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    };

    const obtienecPresupuestalActual = () => {
        const presupuestalActual = cPresupuestales.find(cPresupuestal => cPresupuestal.cpresupuestalclave === elementSelect.cpresupuestalClave);
        validarCPresupuestal(presupuestalActual);
    };

    useEffect(() => {
        if(elementSelect) {
            obtienecPresupuestalActual();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cPresupuestales])

    useEffect(() => {
        if(elementSelect) {
            obtienePuesto();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [puestos])

    const obtienePuesto = () => {
        const puesto = puestos.find(puesto => puesto.puestoCve === elementSelect.puestoCve);
        validarPuesto(puesto);
    };

    const enableCPresupuestal = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, cPresupuestal: value, departamento: value, plaza: value, zonaPago: value });
        setDisabled({ ...disabled, departamento: !value });
        if(value === false) {
            validarCPresupuestal(null);
            validarDepartamento(null);
            validarPlaza(null);
        } else {
            obtienecPresupuestalActual();
        }
    };

    const enableDepartamento = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, cPresupuestal: value, departamento: value, plaza: value, zonaPago: value });
        setDisabled({ ...disabled, departamento: !value });
        if(value === false) {
            validarCPresupuestal(null);
            validarDepartamento(null);
            validarPlaza(null);
        } else {
            obtienecPresupuestalActual();
        }
    };

    const enablePlaza = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, cPresupuestal: value, departamento: value, plaza: value, zonaPago: value });
        setDisabled({ ...disabled, departamento: !value });
        if(value === false) {
            validarCPresupuestal(null);
            validarDepartamento(null);
            validarPlaza(null);
        } else {
            obtienecPresupuestalActual();
        }
    };

    const enabledTurno = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, turno: value });
    };

    const enableNomina = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, nomina: value });
    };

    const enablePuesto = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, puesto: value });
        if(value === false) {
            validarPuesto(null);
        } else {
            obtienePuesto();
        }
    };

    const enableZonaPago = (e, value) => {
        e.preventDefault();
        setDisabledFormOrgEdit({ ...disabledFormOrgEdit, cPresupuestal: value, departamento: value, plaza: value, zonaPago: value });
        setDisabled({ ...disabled, departamento: !value });
        if(value === false) {
            validarCPresupuestal(null);
            validarDepartamento(null);
            validarPlaza(null);
        } else {
            obtienecPresupuestalActual();
        }
    };

    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}s/tipoformapago/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setFormasPago(res.data.dataResponse);
                    break;
                case 404:
                    setFormasPago([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => {alert('error'); console.log(error)});

        axios.get(`${servicios_grupogack_catalogo}s/contrato/sao/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setContratos(res.data.dataResponse);
                    break;
                case 404:
                    setContratos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => {alert('error'); console.log(error)});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupogack_rh}/empleado/formapago/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const formasPago = res.data.dataResponse.filter(fPago => 
                            (fPago.formapago.id === 1 || fPago.formapago.id === 2 || fPago.formapago.id === 10)
                        );
                        if(formasPago.length > 0) {
                            const array = formasPago.map(fPago => ( { idFormaPago: fPago.id, formapago: fPago.formapago.id, formaPagoNom: fPago.formapago.formapagoNom } ));
                            setFormasPagoSelected(array);
                        }
                        setConsultaCuentahabiente(true);
                        break;
                    case 404:
                        setConsultaCuentahabiente(true);
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => {alert('error'); console.log(error)});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(elementSelect && consultaCuentahabiente) {
            axios.get(`${servicios_grupogack_rh}/empleado/cuentahabiente/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const formasPago = res.data.dataResponse.map(fPago => (
                            {   
                                idFormaPago: fPago.empleadoFormaPago.id, 
                                idCuentahabiente: fPago.id,
                                formapago: parseInt(fPago.formaPago),
                                formaPagoNom: fPago.empleadoFormaPago.formapago.formapagoNom,
                                bancoId: parseInt(fPago.banco.id),
                                banco: fPago.banco.bancoNom,
                                numCuenta: fPago.nocuenta,
                                cbeInterbancaria: fPago.clabe
                            }
                        ));
                            
                        if(formasPago.length > 0) {
                            const array = formasPagoSelected.concat(formasPago);
                            setFormasPagoSelected(array);
                        }
                        break;
                    case 404:
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => {alert('error'); console.log(error)});
            setConsultaCuentahabiente(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consultaCuentahabiente]);

    useEffect(() => {
        if(elementSelect) {
            if(nominas.length > 0) {
                const nomina = nominas.find(nomina => nomina.nominaClave === elementSelect.nominaClave);
                setIdNomina(nomina.id);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nominas]);
    
    useEffect(() => {
        if(selected.uResponsable.nombre !== '') {
            setInfoClavePresupuestal(
                <Fragment>
                    <br/>
                    <div><label><strong>Unidad Responsable: </strong>{ selected.uResponsable.nombre }</label></div>
                    <div><label><strong>Unidad Ejecutora: </strong>{ selected.uEjecutora.nombre }</label></div>
                    <div><label><strong>Programa: </strong>{ selected.programa.nombre }</label></div>
                    <div><label><strong>Proyecto Acción: </strong>{ selected.pAccion.nombre }</label></div>
                    <div><label><strong>Clave Presupuestal: </strong>{ selected.cPresupuestal.clave }</label></div>
                    <br/>
                </Fragment>
            );
        }
    }, [selected]);       

    const datosTransferencia =  <Fragment>
                                    <SelectValidation title="Banco" arrayOpciones={bancos} keyOpcion="bancoNom"
                                        refValue={bancoRef} defaultValue={editarElementoFormaPago ? editarElementoFormaPago.bancoId : ''} required/>
                                    <div className="formRelLab">
                                        <div className="form-left">
                                            <InputValidation title="Número de Cuenta" type="numCuenta" placeholder="Número de Cuenta" maxLength={11}
                                                tooltipText="10 u 11 números" tooltipName="tooltipNumCuenta"
                                                refValue={numCuentaRef} defaultValue={editarElementoFormaPago ? editarElementoFormaPago.numCuenta : ''} required/>
                                        </div>
                                        <div className="form-right">
                                            <InputValidation title="CLABE Interbancaria" type="cbeInterbancaria" placeholder="CLABE Interbancaria" maxLength={18}
                                                tooltipText="18 números" tooltipName="tooltipCbeInterbancaria"
                                                refValue={cbeInterRef} defaultValue={editarElementoFormaPago ? editarElementoFormaPago.cbeInterbancaria : ''} required/>
                                        </div>
                                    </div>
                                </Fragment>;
    
    const verificaCheck = e => {
        switch(e) {
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
                if(bancos.length === 0) obtenerBancos();
                setMuestraFormDatosBancarios(true);
            break;
            default:
                setMuestraFormDatosBancarios(false);
            break;
        }
    };

    const guardarFormaPago = (e) => {
        e.preventDefault();
        if(validarSubmit('formasPago')) { 
            const nomFormaPago = formasPago.find(forma => forma.id === parseInt(formaPagoRef.current.value));
            let findFormaPago = {};
            if(nomFormaPago) findFormaPago = formasPagoSelected.find(forma => forma.formaPagoNom === nomFormaPago.formapagoNom);
            if(!findFormaPago) {
                if(elementSelect) {
                
                    const nuevaFormaPago = {
                        empleado: elementSelect.id,
                        formapago: parseInt(formaPagoRef.current.value)
                    };

                    axios.post(`${servicios_grupogack_rh}/empleado/formapago/`, [nuevaFormaPago], headersAuth())
                    .then(res => {
                        switch(res.data.status) {
                            case 200:
                                if(res.data.dataResponse[0].formapago.id < 10 && res.data.dataResponse[0].formapago.id > 2) {
                                    const datosCuenta = {
                                        empleadoFormaPago: res.data.dataResponse[0].id,
                                        formaPago: parseInt(formaPagoRef.current.value),
                                        banco: parseInt(bancoRef.current.value),
                                        clabe: cbeInterRef.current.value,
                                        nocuenta: numCuentaRef.current.value
                                    };
                                    
                                    axios.post(`${servicios_grupogack_rh}/empleado/cuentahabiente/`, datosCuenta, headersAuth())
                                    .then(res2 => {
                                        switch(res2.data.status) {
                                            case 200:
                                                setFormasPagoSelected([ ...formasPagoSelected, 
                                                    { 
                                                        idFormaPago: res2.data.dataResponse.empleadoFormaPago.id,
                                                        formapago: res2.data.dataResponse.empleadoFormaPago.formapago.id,
                                                        formaPagoNom: res2.data.dataResponse.empleadoFormaPago.formapago.formapagoNom,
                                                        banco: res2.data.dataResponse.banco.bancoNom,
                                                        bancoId: res2.data.dataResponse.banco.id,
                                                        numCuenta: res2.data.dataResponse.nocuenta,
                                                        cbeInterbancaria: res2.data.dataResponse.clabe
                                                    }
                                                ]);
                                                setMuestraFormDatosBancarios(false);
                                                if(formaPagoRef.current) formaPagoRef.current.value = '';
                                                setErrorFormPago({ active: false, message: '' });
                                                successTable(registro_guardado);
                                            break;
                                            default:
                                                errorTable(`Cuenta, ${registro_no_guardado}`);
                                            break;
                                        }
                                        return;
                                    })
                                    .catch(error => errorTable(`Cuenta, ${registro_no_guardado}`));
                                } else {
                                    setFormasPagoSelected([ ...formasPagoSelected,
                                        {
                                            idFormaPago: res.data.dataResponse[0].id,
                                            formapago: res.data.dataResponse[0].formapago.id,
                                            formaPagoNom: res.data.dataResponse[0].formapago.formapagoNom
                                        }
                                    ]);
                                    if(formaPagoRef.current) formaPagoRef.current.value = '';
                                    setErrorFormPago({ active: false, message: '' });
                                    successTable(registro_guardado);
                                }
                            break;
                            default:
                                errorTable(`Forma de Pago, ${registro_no_guardado}`);
                            break;
                        }
                    })
                    .catch(error => errorTable(`Forma de Pago, ${registro_no_guardado}`));
                
                } else {
                    if(numCuentaRef.current !== null || cbeInterRef.current !== null) {
                        const banco = bancos.find(banco => banco.id === parseInt(bancoRef.current.value));
                        setFormasPagoSelected([ ...formasPagoSelected, 
                                                { 
                                                    formapago: parseInt(formaPagoRef.current.value),
                                                    formaPagoNom: nomFormaPago.formapagoNom,
                                                    banco: banco.bancoNom,
                                                    bancoId: parseInt(bancoRef.current.value),
                                                    numCuenta: numCuentaRef.current.value,
                                                    cbeInterbancaria: cbeInterRef.current.value
                                                }
                                            ]);
                    } else {
                            setFormasPagoSelected([ ...formasPagoSelected,
                                                    {
                                                        formapago: parseInt(formaPagoRef.current.value),
                                                        formaPagoNom: nomFormaPago.formapagoNom
                                                    }
                                                ]);
                    }
        
                    setMuestraFormDatosBancarios(false);
                    if(formaPagoRef.current) formaPagoRef.current.value = '';
                    setErrorFormPago({ active: false, message: '' });
                }
            } else {
                if(!nomFormaPago) {
                    setErrorFormPago({ active: true, message: 'Seleccione una Forma de Pago' });
                } else {
                    setErrorFormPago({ active: true, message: 'Forma de pago ya registrada' });
                }
            }
        }
    };

    setTimeout(() => {
        setMostrarContenido(true);
    }, 2000);

    return (
        <Fragment>
            {
                (mostrarContenido === true)
                ?   <Fragment>
                        <h5>Organización del Empleado</h5>
                            { (!disabled.departamento) && infoClavePresupuestal }
                            <form id="OrganizacionEmpleado" onSubmit={elementSelect ? editar : guardar}>
                            {
                                (enviarEmpleado)
                                ?   <Fragment>
                                        <AutocompleteComponent
                                            id="cPresupuestal"
                                            title="Clave Presupuestal / Proyecto"
                                            options={cPresupuestales}
                                            optionListView="cpresupuestalnom"
                                            action={validarCPresupuestal}
                                            placeholder="Clave Presupuestal / Proyecto"
                                            tooltipText="Busque la Clave Presupuestal / Proyecto, por nombre o clave, en el campo de autocompletado"
                                            tooltipName="tooltipCPresupuestal"
                                            error={errorCPresupuestal}
                                            required
                                        />
                                        <AutocompleteComponent
                                            id="departamento"
                                            title="Departamento"
                                            options={departamentos}
                                            optionListView="areanom"
                                            action={validarDepartamento}
                                            disabled={disabled.departamento}
                                            placeholder="Departamento"
                                            tooltipText="Busque el Departamento, por nombre o clave, en el campo de autocompletado"
                                            tooltipName="tooltipDepartamento"
                                            error={errorDepartamento}
                                            required
                                        />
                                    </Fragment>
                                :   null
                            }
                            {
                                (elementSelect)
                                ?   (disabledFormOrgEdit.cPresupuestal === true)
                                    ?   <div className="row">
                                            <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                                <InputValidation title="Clave Presupuestal / Proyecto" type="none"
                                                    defaultValue={elementSelect ? elementSelect.cpresupuestalNom : ''} 
                                                    tooltipText="Busque la Clave Presupuestal / Proyecto, por nombre o clave, en el campo de autocompletado"
                                                    tooltipName="tooltipCPresupuestal" disabled required optional={true}/>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                                <button className="btn-autocompleteInput" onClick={e => enableCPresupuestal(e, false)}><EditIcon/></button>
                                            </div>
                                        </div>
                                    :   <div className="row">
                                            <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                                <AutocompleteComponent
                                                    id="cPresupuestal"
                                                    title="Clave Presupuestal / Proyecto"
                                                    options={cPresupuestales}
                                                    optionListView="cpresupuestalnom"
                                                    action={validarCPresupuestal}
                                                    placeholder="Clave Presupuestal / Proyecto"
                                                    tooltipText="Busque la Clave Presupuestal / Proyecto, por nombre o clave, en el campo de autocompletado"
                                                    tooltipName="tooltipCPresupuestal"
                                                    error={errorCPresupuestal}
                                                    required
                                                />
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                                <button className="btn-autocompletecancel" onClick={e => enableCPresupuestal(e, true)}><CancelIcon/></button>
                                            </div>
                                        </div>
                                :   null
                            }
                            {   
                                (elementSelect)
                                ?   (disabledFormOrgEdit.departamento === true)
                                    ?   <div className="row">
                                            <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                                <InputValidation title="Departamento" type="none"
                                                    defaultValue={elementSelect ? elementSelect.areaNom : ''} 
                                                    tooltipText="Busque el Departamento, por nombre o clave, en el campo de autocompletado"
                                                    tooltipName="tooltipDepartamento" disabled required optional={true}/>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                                <button className="btn-autocompleteInput" onClick={e => enableDepartamento(e, false)}><EditIcon/></button>
                                            </div>
                                        </div>
                                    :   <div className="row">
                                            <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                                <AutocompleteComponent
                                                    id="departamento"
                                                    title="Departamento"
                                                    options={departamentos}
                                                    optionListView="areanom"
                                                    action={validarDepartamento}
                                                    disabled={disabled.departamento}
                                                    placeholder="Departamento"
                                                    tooltipText="Busque el Departamento, por nombre o clave, en el campo de autocompletado"
                                                    tooltipName="tooltipDepartamento"
                                                    error={errorDepartamento}
                                                    required
                                                />
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                                <button className="btn-autocompletecancel" onClick={e => enableDepartamento(e, true)}><CancelIcon/></button>
                                            </div>
                                        </div>
                                :   null
                            }
                            {
                                (enviarEmpleado)
                                ?   <div className="formRelLab">
                                        <div className="form-left"> 
                                            <AutocompleteComponent
                                                id="plaza" title="Plaza" options={plazas} optionListView="plazaclave" action={validarPlaza}
                                                disabled={disabled.plaza} placeholder="Plaza" tooltipName="tooltipPlaza"
                                                tooltipText="Busque la Plaza, por clave, en el campo de autocompletado" error={errorPlaza} required />
                                        </div>
                                        <div className="form-right">
                                            <AutocompleteComponent
                                                id="puesto" title="Puesto" options={puestos} optionListView="puestoNom" action={validarPuesto}
                                                placeholder="Puesto" tooltipName="tooltipPuesto" error={errorPuesto}
                                                tooltipText="Busque el Puesto, por nombre o clave, en el campo de autocompletado" required/>
                                        </div>
                                    </div>
                                :   null
                            }
                            {
                                (elementSelect)
                                ?   <div className="row">
                                        <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                            {
                                                (disabledFormOrgEdit.plaza)
                                                ?   <InputValidation title="Plaza" type="none"
                                                        tooltipText="Busque la Plaza, por clave, en el campo de autocompletado" tooltipName="tooltipPlaza"
                                                        defaultValue={elementSelect.plazaClave} disabled required/>
                                                :   <AutocompleteComponent
                                                        id="plaza" title="Plaza" options={plazas} optionListView="plazaclave" action={validarPlaza}
                                                        disabled={disabled.plaza} placeholder="Plaza" tooltipName="tooltipPlaza"
                                                        tooltipText="Busque la Plaza, por clave, en el campo de autocompletado" error={errorPlaza} required />
                                            }
                                        </div>
                                        <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                            {
                                                (disabledFormOrgEdit.plaza)
                                                ?   <button className="btn-autocompleteInput" onClick={e => enablePlaza(e, false)}><EditIcon/></button>
                                                :   <button className="btn-autocompletecancel" onClick={e => enablePlaza(e, true)}><CancelIcon/></button>
                                            }
                                        </div>
                                        <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                            {
                                                (disabledFormOrgEdit.puesto)
                                                ?   <InputValidation title="Puesto" type="none"
                                                        tooltipText="Busque el Puesto, por nombre o clave, en el campo de autocompletado" tooltipName="tooltipPuesto"
                                                        defaultValue={elementSelect.puestoNom} disabled required/>
                                                :   <AutocompleteComponent
                                                        id="puesto" title="Puesto" options={puestos} optionListView="puestoNom" action={validarPuesto}
                                                        placeholder="Puesto" tooltipName="tooltipPuesto" error={errorPuesto}
                                                        tooltipText="Busque el Puesto, por nombre o clave, en el campo de autocompletado" required/>
                                            }
                                        </div>
                                        <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                            {
                                                (disabledFormOrgEdit.puesto)
                                                ?   <button className="btn-autocompleteInput" onClick={e => enablePuesto(e, false)}><EditIcon/></button>
                                                :   <button className="btn-autocompletecancel" onClick={e => enablePuesto(e, true)}><CancelIcon/></button>
                                            }
                                        </div>
                                    </div>
                                :   null
                            }
                            <Sueldo elementSelect={elementSelect} indicadores={indicadores} monto={monto} setMonto={setMonto}
                                setIndicadoresSelected={setIndicadoresSelected} indicadoresSelected={indicadoresSelected}
                                disabledPuesto={disabledFormOrgEdit.puesto} successTable={successTable} errorTable={errorTable}/>
                            {
                                (enviarEmpleado)
                                ?   <SelectValidation title="Zona de Pago" arrayOpciones={zonasPago}
                                        refValue={zonaPagoRef} defaultValue="" disabled={disabled.zonaPago} required/>
                                :   null
                            }
                            {
                                (elementSelect)
                                ?   (disabledFormOrgEdit.zonaPago)
                                    ?   <div className="row">
                                            <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                                <InputValidation title="Zona de Pago" type="none"
                                                    defaultValue={`${elementSelect.domciaCalle}${(elementSelect.domciaNumext) ? ` ${elementSelect.domciaNumext}` : ''}${(elementSelect.domciaNumint) ? ` ${elementSelect.domciaNumint}` : ''}`}
                                                    disabled required/>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                                <button className="btn-autocompleteInput" onClick={e => enableZonaPago(e, false)}><EditIcon/></button>
                                            </div>
                                        </div>
                                    :   <div className="row">
                                            <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                                <SelectValidation title="Zona de Pago" arrayOpciones={zonasPago}
                                                    refValue={zonaPagoRef} defaultValue="" required/>
                                            </div>
                                            <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                                <button className="btn-autocompletecancelInput" onClick={e => enableZonaPago(e, true)}><CancelIcon/></button>
                                            </div>
                                        </div>
                                :   null
                            }
                            {/* para validar este campo será necesario hacerlo por el arreglo de objetos en donde se guardarán todas las formas de pago del empleado */}
                            <div id="formasPago">
                                <div className="row">
                                    <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                                        <SelectValidation title="Forma de Pago" arrayOpciones={formasPago} keyOpcion="formapagoNom" onChangeExecute={verificaCheck}
                                            refValue={formaPagoRef} defaultValue="" disabled={editarElementoFormaPago ? true : false} required optional={true}/>
                                    </div>
                                    <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                        <button className="btn-autocompleteInput" onClick={(editarElementoFormaPago) ? editarFormaPago : guardarFormaPago}><SaveIcon/></button>
                                    </div>
                                </div>
                                { (muestraFormDatosBancarios || editarElementoFormaPago) && datosTransferencia }
                                { errorFormPago.active && <AlertForm message={errorFormPago.message}/> }
                                {
                                    (formasPagoSelected.length > 0)
                                    ?   <Fragment>
                                            {
                                                formasPagoSelected.map((forma, key) => (
                                                    <Fragment key={key}>
                                                        { key > 0 && <hr/> }
                                                        <div className="formRelLab">
                                                            {   
                                                                (elementSelect )
                                                                ?   <Fragment>
                                                                        {   (forma.formapago > 2 && forma.formapago < 10) &&
                                                                            <BtnEditItem id={forma.formapago} idArray="formapago" array={formasPagoSelected} action={muestraEditarFPago} remove/>
                                                                        }
                                                                        <BtnRemoveItem id={forma.formapago} idArray="formapago" array={formasPagoSelected} action={eliminaFormaPago} remove/>
                                                                    </Fragment>
                                                                :   <BtnRemoveItem id={forma.formapago} idArray="formapago" array={formasPagoSelected} action={setFormasPagoSelected}/>
                                                            }
                                                            {
                                                                (forma.banco)
                                                                ?   <Fragment>
                                                                        <div className="form-left">
                                                                            <div><strong>Forma de Pago: </strong>{ forma.formaPagoNom }</div>
                                                                            <div><strong>Número de Cuenta: </strong>{ forma.numCuenta }</div>
                                                                        </div>
                                                                        <div className="form-right">
                                                                            <div><strong>Banco: </strong>{ forma.banco }</div>
                                                                            <div><strong>CLABE Interbancaria: </strong>{ forma.cbeInterbancaria }</div>
                                                                        </div>
                                                                    </Fragment>
                                                                :   <div><strong>Forma de Pago: </strong>{ forma.formaPagoNom }</div>
                                                            }
                                                        </div>
                                                    </Fragment>
                                                ))
                                            }
                                            <br/>
                                        </Fragment>
                                    :   null
                                }
                            </div>
                            {
                                (enviarEmpleado)
                                ?   <div className="formRelLab">
                                        <div className="form-left"> 
                                            <SelectValidation title="Turno" arrayOpciones={turnos} keyOpcion="turnoNom"
                                                refValue={turnoRef} defaultValue="" required/>
                                        </div>
                                        <div className="form-right">
                                            <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nominaNombre"
                                                refValue={nominaRef} defaultValue="" required/>
                                        </div>
                                    </div>
                                :   null
                            }
                            {
                                (elementSelect)
                                ?   <div className="row">
                                        <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                            {
                                                (disabledFormOrgEdit.turno)
                                                ?   <InputValidation title="Turno" type="none" defaultValue={elementSelect.turnoNom} disabled required/>
                                                :   <SelectValidation title="Turno" arrayOpciones={turnos} keyOpcion="turnoNom"
                                                        refValue={turnoRef} defaultValue="" required/>
                                            }
                                        </div>
                                        <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                            {
                                                (disabledFormOrgEdit.turno)
                                                ?   <button className="btn-autocompleteInput" onClick={e => enabledTurno(e, false)}><EditIcon/></button>
                                                :   <button className="btn-autocompletecancelInput" onClick={e => enabledTurno(e, true)}><CancelIcon/></button>
                                            }
                                        </div>
                                        <div className="col-8 col-sm-9 col-md-10 col-lg-4 col-xl-5">
                                            {
                                                (disabledFormOrgEdit.nomina)
                                                ?   <InputValidation title="Nómina" type="none" refValue={nominaRef}
                                                        tooltipText="Seleccione la Nómina" tooltipName="tooltipNomina"
                                                        defaultValue={elementSelect.nominaNombre} disabled required/>
                                                :   <SelectValidation title="Nómina" arrayOpciones={nominas} keyOpcion="nominaNombre"
                                                        tooltipText="Seleccione la Nómina" tooltipName="tooltipNomina"
                                                        refValue={nominaRef} defaultValue={idNomina} required/>
                                            }
                                        </div>
                                        <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end mb-3">
                                            {
                                                (disabledFormOrgEdit.nomina)
                                                ?   <button className="btn-autocompleteInput" onClick={e => enableNomina(e, false)}><EditIcon/></button>
                                                :   <button className="btn-autocompletecancelInput" onClick={e => enableNomina(e, true)}><CancelIcon/></button>
                                            }
                                        </div>
                                    </div>
                                :   null
                            }
                            <div className="formRelLab">
                                {
                                    (elementSelect)
                                    ?   <Fragment>
                                            <div style={{ float: 'right', width: '30%' }}>
                                                <SelectValidation title="Contrato" arrayOpciones={contratos} keyOpcion="contratoSaoNom" onChangeExecute={validaContrato}
                                                    refValue={contratoRef} defaultValue={infoContrato.contrato.id} required/>
                                            </div>
                                            <div style={{ width: '30%', marginLeft: '5%', marginRight: '5%' }}>
                                                <InputDateValidation title="Fecha Inicial" refValue={fechaInicialContratoRef}
                                                    defaultValue={elementSelect ? longDateToInput(infoContrato.fechaini) : ''} required/>
                                            </div>
                                            <div style={{ float: 'left', width: '30%' }}>
                                                <InputDateValidation title="Fecha Final" refValue={fechaFinalContratoRef}
                                                    tooltipText="Se desbloquea dependiendo del Tipo de Contrato seleccionado" tooltipName="tooltipFFContrato"
                                                    defaultValue={elementSelect ? infoContrato.fechafin ? longDateToInput(infoContrato.fechafin) : '' : ''}
                                                    required={enabledFechaContrato} optional={!enabledFechaContrato} disabled={!enabledFechaContrato}/>
                                            </div>
                                        </Fragment>
                                    :   <Fragment>
                                            <div className="form-left">
                                                <SelectValidation title="Contrato" arrayOpciones={contratos} keyOpcion="contratoSaoNom" onChangeExecute={validaContrato}
                                                    refValue={contratoRef} defaultValue="" required/>
                                            </div>
                                            <div className="form-right">
                                                <InputDateValidation title="Fecha Final" refValue={fechaFinalContratoRef} defaultValue=""
                                                    tooltipText="Se desbloquea dependiendo del Tipo de Contrato seleccionado" tooltipName="tooltipFFContrato"
                                                    required={enabledFechaContrato} optional={!enabledFechaContrato} disabled={!enabledFechaContrato}/>
                                            </div>
                                        </Fragment>
                                }
                            </div>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <InputDateValidation title="Fecha de Nombramiento" refValue={fechaNombramientoRef}
                                        defaultValue={(elementSelect) ? (fechaNombramiento.fechaNombramiento !== '') ? longDateToInput(fechaNombramiento.fechaNombramiento) : '' : ''} optional={true}/>
                                </div>
                                <div className="form-right">
                                    <InputDateValidation title="Fecha de Aguinaldo" refValue={fechaAguinaldoRef}
                                        defaultValue={(elementSelect) ? (fechaAguinaldo.fechaAguinaldo !== '') ? longDateToInput(fechaAguinaldo.fechaAguinaldo) : '' : ''} optional={true}/>
                                </div>
                            </div>
                            <RegistroPatronal regPatronalRef={regPatronalRef} infoRegPatronal={(infoRegPatronal) ? infoRegPatronal : null} />
                            <Sindicato infoSindicato={(infoSindicato) ? infoSindicato : null} sindicatoRef={sindicatoRef}
                                sindicalizado={sindicalizado} setSindicalizado={setSindicalizado}
                                cuotaSindical={cuotaSindical} setCuotaSindical={setCuotaSindical}
                                sindicatoSeleccionado={sindicatoSeleccionado} setSindicatoSeleccionado={setSindicatoSeleccionado}/>
                            <BtnsInfoAdicional numForm={2} irInfo={irInfo}/>
                            <div className="formRelLab">
                                <div className="form-left">
                                    <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 1)}>Atrás</Button>
                                </div>
                                <div className="form-right">
                                    <Button variant="contained" className="btn-color" type="submit">{ (elementSelect) ? 'Actualizar' : 'Guardar' }</Button>
                                </div>
                            </div>
                        </form>
                        { elementSelect &&
                            <ModalOrganizacionEmpleadoEdit modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen} actualizar={actualizar} setDisabledFormOrgEdit={setDisabledFormOrgEdit}
                                disabledFormOrgEdit={disabledFormOrgEdit} elementSelect={elementSelect} selected={selected} idTurno={idTurno} turnos={turnos} turnoRef={turnoRef} nominas={nominas} nominaRef={nominaRef}
                                zonasPago={zonasPago} zonaPagoRef={zonaPagoRef}/>
                        }
                        <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text="No has agregado la Forma de Pago seleccionada. ¿Deseas continuar?" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={elementSelect ? abrirModal : enviar}/>
                    </Fragment>
                :   <SpinnerOval />
            }
        </Fragment>
    );
}
 
export default FormOrganizacionEmpleado;
import { AGREGAR_NAVEGADOR, QUITAR_NAVEGADOR, CAMBIAR_COMPONENTE_ACTIVO, LIMPIAR_PESTANAS } from '../actionTypes';
import { pushWarning } from '../../Services/Notifications/PushService';

export const agregarNavegador = ( navegadoresStore, modulo, componenteActivo, origen=null, text=null ) => (dispatch) => {
    
    if( !navegadoresStore.find( nav => nav.id === modulo.id )){
        if( navegadoresStore.length < 5 && text===null ){
            dispatch({
                type: AGREGAR_NAVEGADOR,
                modulo
            })
            dispatch({
                type: CAMBIAR_COMPONENTE_ACTIVO,
                activoID: modulo.id
            })
        }else{
            if(origen==='SearchComponent' && text!==null ){ 
                modulo.nombre="Búsqueda "+modulo.nombre;
                modulo.id=modulo.id+'-1';
                if( !navegadoresStore.find( nav => nav.id === modulo.id ) ){
                    if( navegadoresStore.length < 5 ){
                        dispatch({
                            type: AGREGAR_NAVEGADOR,
                            modulo
                        })
                        dispatch({
                            type: CAMBIAR_COMPONENTE_ACTIVO,
                            activoID: modulo.id
                        })
                    }else{
                        pushWarning({ title: 'Número máximo de pestañas 5', message: 'Cierre alguna para continuar' });
                    }
                }
            }else{
                pushWarning({ title: 'Número máximo de pestañas 5', message: 'Cierre alguna para continuar' });
            }
        }
    }else{
        
        if(origen==='SearchComponent' && (text!==null)){ 
            modulo.nombre="Búsqueda "+modulo.nombre;
            modulo.id=modulo.id+'-1';
            if( !navegadoresStore.find( nav => nav.id === modulo.id ) ){
                if( navegadoresStore.length < 5 ){
                    dispatch({
                        type: AGREGAR_NAVEGADOR,
                        modulo
                    })
                    dispatch({
                        type: CAMBIAR_COMPONENTE_ACTIVO,
                        activoID: modulo.id
                    })
                }
            }
        } 
        if( componenteActivo !== modulo.id ){
            dispatch({
                type: CAMBIAR_COMPONENTE_ACTIVO,
                activoID: modulo.id
            })
        }
    }
}

export const quitarNavegador = ( moduloID, componenteActivoID, position, navegadores ) => (dispatch) => {
    if( componenteActivoID === moduloID ){
        dispatch({
            type: QUITAR_NAVEGADOR,
            moduloID
        })
        if( position-1 < 0 ){
            dispatch({
                type: CAMBIAR_COMPONENTE_ACTIVO,
                activoID: 1
            })
        }else{
            dispatch({
                type: CAMBIAR_COMPONENTE_ACTIVO,
                activoID: navegadores[position-1].id
            })
        }
    }
    dispatch({
        type: QUITAR_NAVEGADOR,
        moduloID
    })
}


export const cambiarActivo = ( activoID, componenteActivo ) => (dispatch) => {
    if( componenteActivo !== activoID ){
        dispatch({
            type: CAMBIAR_COMPONENTE_ACTIVO,
            activoID
        })
    }
}

export const limpiarPestanas = () => (dispatch) => {
    dispatch({
        type: LIMPIAR_PESTANAS,
    })
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_catalogo, tipo_contrato } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import tipoContJson from '../../../../../Datos/tipoContrato.json';

const TipoContrato = ({ id, permisos }) => {
    const [ tiposContrato, setTiposContrato ] = useState( null );
    useEffect(() => {
        axiosTiposContrato();
    }, []);

    const axiosTiposContrato = () => {
        setTiposContrato(tipoContJson);
        /*axios.get( `${servicios_grupogack_catalogo}/contrato/`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setTiposContrato( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposContrato([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })*/
    }

    return (
        <div id={ id }>
            { tiposContrato ?
                <DataTableService id={id} title={ tipo_contrato } permisos={permisos}
                    columns={[
                        { field: "tipoContratoClave", title: "Clave" },
                        { field: "tipoContratoDesc", title: "Descripción" },
                    ]}
                    data={ tiposContrato } /> : <SpinnerOval />
            }
        </div>
    );
};

export default TipoContrato;
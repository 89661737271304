import React, { useState, useEffect } from 'react'
import TooltipComponent from '../Tooltip/TooltipComponent'
import { comprobar, trimValue } from './HelperValidation';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const InputValidation = ({ type, refValue, title, placeholder, maxLength, defaultValue, tooltipText, fieldType, required, action, disabled, size, optional, onChangeExecute, onBlurDecimal, onlyLetters, onlyNumbers, alfanumeric, isAmount }) => {
   
    const [valid, setValid] = useState(null);
    
    let mensaje = '';

    useEffect(() => {
        if(refValue) {
            if(refValue.current.value === '') setValid(null);
        }
    }, [refValue]);

    const comprobarValid = ( obj ) => {
        if( valid !== obj.status || mensaje !== obj.mensaje ){
            setValid(obj.status);
            mensaje = obj.mensaje;
        }
    };

    const validar = () => {
        if(refValue.current.value.trim() === '' ) {
            setValid(null);
            return;
        }

        if( optional === true && refValue.current.value.trim() === '' ){
            comprobarValid({ status: null, mensaje: '' });
        }else{
            comprobarValid( comprobar( refValue.current.value.trim(), type ) );
        }
        if( onChangeExecute ){
            onChangeExecute( refValue.current.value );
        }
    };

    const agregaDecimales = () => { 
        if(parseFloat(isAmount ? refValue.current.value.trim().replace(/,/g,'').replace(/\$/,'') : refValue.current.value )) {
            let valor = isAmount ? refValue.current.value.trim().replace(/,/g,'').replace(/\$/,'') : refValue.current.value.trim(); 
            let indice = valor.indexOf(".");
            let entero = '';
            let decimal = '';
            
            if(indice !== -1) {
                entero = valor.substring(0, indice); 
                if((valor.substring(indice)).length > onBlurDecimal) {
                    const decimalCopy = valor.substring(indice);
                    decimal = decimalCopy.substring(0, onBlurDecimal+1);
                } else {
                    decimal = valor.substring(indice);
                }

                valor = (`${entero}${decimal}`).trim();
            }

            if((maxLength-parseFloat(valor).toFixed(onBlurDecimal).length) >= 0) {  
                refValue.current.value = isAmount ? '$'+parseFloat(valor).toLocaleString('en-US', {minimumFractionDigits: onBlurDecimal}) : parseFloat(valor).toFixed(onBlurDecimal);
                comprobarValid(comprobar(refValue.current.value, type));
            } else {
                validar();
            }
        }
    };

    const keysAllowed = (e) =>{
        return e.keyCode!==8 && e.keyCode!==9 && e.keyCode!==46 && !(e.keyCode >= 37 && e.keyCode <= 40 ) && !((e.keyCode === 65 || e.keyCode === 67) && (e.ctrlKey || e.metaKey ));
    }

    const validarInput = e => {
        const number = /^[0-9Vv]$/;
        const letters= /^[A-Za-zñÑ]$/;
        const alfanumericos = /^[A-Za-zÑñ0-9]$/;
        if( onlyNumbers && (!number.test(e.key) && keysAllowed(e) && (e.key!==onlyNumbers || e.target.value.includes('.'))) ){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
 
        if( onlyLetters && (!letters.test(e.key) && keysAllowed(e) && onlyLetters!==e.key)){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        } 

        if( alfanumeric && ( !alfanumericos.test(e.key) && keysAllowed(e) ) ){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
    }

    const textActions = () => {
        if(type === 'rfc' || type === 'curp')
        refValue.current.value = refValue.current.value.toUpperCase()
    };

        let typeForm="form-group";
        if(fieldType==="textarea") typeForm="form-desc";
        return (
            <div className={`${typeForm} ${ optional === true && valid === null ? '' : 'validar' }`}>
                { 
                    title &&
                    <label>
                        { title }{ required && <strong className="requerido">*</strong> }
                        { tooltipText &&
                            <TooltipComponent tooltipText={tooltipText}>
                                <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                            </TooltipComponent>
                        }
                    </label>
                }
                {
                    (fieldType==="textarea" || fieldType==="textareaShort")
                    ?   <textarea type={type} placeholder={placeholder} maxLength={maxLength}  
                            className={ `${ valid === null ? '' : valid ? 'is-valid' : 'is-invalid'} ${ fieldType==="textarea" ? 'descripcion' : 'descripcionShort'}`}
                            ref={ refValue } defaultValue={defaultValue}
                            onChange={ () => validar() }
                            onBlur={ () => onBlurDecimal ? agregaDecimales() : trimValue(refValue) } disabled={(disabled) ? disabled : false}
                            style={{width: (size) ? size : '100%'}}
                            onKeyDown = { e => { validarInput(e) } }
                            onKeyUp={textActions}
                        />
                    :   
                        <input type={type} placeholder={placeholder} maxLength={maxLength} 
                            className={`form-control ${ valid === null ? '' : valid ? 'is-valid' : 'is-invalid'}`}
                            ref={ refValue } defaultValue={defaultValue} autoComplete={ type === 'password' ? 'new-password' : '' }
                            onChange={ (action) ? () => { validar(); action() } : () => {validar()} }
                            onBlur={ () => onBlurDecimal ? agregaDecimales() : trimValue(refValue) } disabled={(disabled) ? disabled : false}
                            style={{width: (size) ? size : '100%'}}
                            onKeyDown = { e => { validarInput(e) } }
                            onKeyUp={textActions}
                        />
                }

                <div className="invalid-feedback"> { mensaje } </div>     
            </div>
        )
}

export default InputValidation;
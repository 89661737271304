import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_actualizado, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { Tooltip } from 'reactstrap';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CheckIndicadoresSueldo from './CheckIndicadoresSueldo';
import  { floatToAmount } from '../../../../../../../Services/Validation/HelperValidation';

const Sueldo = ({ elementSelect, indicadores, monto, setMonto, setIndicadoresSelected, indicadoresSelected, disabledPuesto, successTable, errorTable }) => {

    const [muestraIndicadores, setMuestraIndicadores] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [mostrar, setMostrar] = useState('none');

    const toggle = () => setTooltipOpen(!tooltipOpen);

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupogack_rh}/vistaempleado/indicadores/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const idIndicadores = res.data.dataResponse.map(indicador => indicador.indicadorId);
                        const montoIndicadores = res.data.dataResponse.map(indicador => indicador.monto);
                        const totalMontoIndicadores = montoIndicadores.reduce((total, indicador) => total + parseFloat(indicador));
                        setMonto(totalMontoIndicadores);
                        setIndicadoresSelected(idIndicadores);
                        setMuestraIndicadores(true);
                        break;
                    default:
                        setIndicadoresSelected([]);
                        setMuestraIndicadores(true);
                        break;
                }
            })
            .catch(error => setIndicadoresSelected([]));
        } else {
            const idIndicadores = indicadores.map(indicador => indicador.indicadorid.indicadorId);
            setIndicadoresSelected(idIndicadores);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(!disabledPuesto) setMostrar('none');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disabledPuesto]);

    const mostrarCheck = e => {
        e.preventDefault();

        if(mostrar === 'none') {
            setMostrar('block');
        }

        if(mostrar === 'block') {
            setMostrar('none');
            if(elementSelect) {
                axios.post(`${servicios_grupogack_rh}/empleado/indicadores/`, { Empleado: [{ EmpleadoId: elementSelect.id, Indicadores: indicadoresSelected }] }, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            successTable(registro_actualizado);
                            break;
                        default:
                            errorTable(`Indicadores ${registro_no_guardado}`);
                            break;
                    }
                })
                .catch(error => errorTable(`Indicadores ${registro_no_guardado}`));
            }
        }
    };

    return (
        <Fragment>
            <div className="row">
                <div className="col-8 col-sm-9 col-md-10 col-lg-10 col-xl-11">
                    <label>Sueldo (MXN)<span id="tooltipSueldo" className="spanInfo"><InfoRoundedIcon color="disabled"/></span></label>
                    <input className="form-control" value={floatToAmount(monto, 2)} disabled/>
                    <Tooltip placement="right" isOpen={tooltipOpen} target="tooltipSueldo" toggle={toggle}>
                        Se registra automaticamente al seleccionar Puesto
                    </Tooltip>
                </div>
                <div className="col-4 col-sm-3 col-md-2 col-lg-2 col-xl-1 align-self-end">
                    <button className="btn-autocompleteSueldo" onClick={mostrarCheck}>{(mostrar === 'none') ? <EditIcon/> : <SaveIcon/>}</button>
                </div>
            </div>
            <div style={{ display: mostrar }}>
                <br/>
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-9 col-ls-9 col-xl-9">
                        <label>Indicadores Sueldo *</label>
                    </div>
                    <div className="col-12 col-sm-12 col-md-3 col-ls-3 col-xl-3">
                        <label>Monto (MXN) *</label>
                    </div>
                </div>
            {
                (!elementSelect)
                ?   (indicadores)
                    ?   indicadores.map((indicador, key) => (
                            <CheckIndicadoresSueldo key={key} elementSelect={elementSelect} monto={monto} setMonto={setMonto} indicador={indicador.indicadorid} montoIndicador={indicador.monto}
                                setIndicadoresSelected={setIndicadoresSelected} indicadoresSelected={indicadoresSelected} disabledPuesto={disabledPuesto}/>
                        ))
                    :   null
                :   (muestraIndicadores)
                    ?   indicadores.map((indicador, key) => (
                            <CheckIndicadoresSueldo key={key} elementSelect={elementSelect} monto={monto} setMonto={setMonto} indicador={indicador.indicadorid} montoIndicador={indicador.monto}
                                setIndicadoresSelected={setIndicadoresSelected} indicadoresSelected={indicadoresSelected} disabledPuesto={disabledPuesto}/>
                        ))
                    :   null
            }
            </div>
        </Fragment>
    );
}
 
export default Sueldo;
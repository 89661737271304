import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, registro_no_guardado } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { JsonMovimientosFijosEmpleado } from '../../../../Helpers/JsonToOneLevel';
import ModalService from '../../../../ModalService/ModalService';
import AlertForm from '../../../../../../../Services/AlertForm';
import FormularioFijos from './FormularioFijos';

const ModalAddFijos = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, idModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarFijo = ( fijo ) => {
        axios.post( `${servicios_grupogack_rh}/movimientos/fijo/`, fijo, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        agregar( JsonMovimientosFijosEmpleado( respuesta.data.dataResponse ) );
                        setOpenAdd( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Nuevo Movimiento Fijo" parentNode={idModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioFijos
                    error={error}
                    informacion={informacionAdicional}
                    enviarFijo={enviarFijo}
                />
        </ModalService>
    );
};

export default ModalAddFijos;
import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';
import AlertForm from '../../../../../../Services/AlertForm';

const FormBases = ({ error, setError, enviarBase, elementSelect, actualizarBase }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const claveRef = createRef('');
    const nombreRef = createRef('');
    const descripcionRef = createRef('');

    const comprobarCambios = () => {
        if(
            elementSelect.baseClave !== claveRef.current.value ||
            elementSelect.baseNom !== nombreRef.current.value ||
            elementSelect.baseDescrip !== descripcionRef.current.value
        ) {
            return true;
        } else {
            return false;
        }
    };

    const mostrarAlert = e => {
        e.preventDefault();
        if(comprobarCambios()) {
            setError(null);
            setOpenAlert(true);
        } else {
            setError(<AlertForm message="No se han realizado cambios en el formulario"/>);
        }
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('FormBase')) {
            const baseClave = claveRef.current.value;
            const baseNom = nombreRef.current.value;
            const baseDescrip = descripcionRef.current.value;
            enviarBase({ baseClave, baseNom, baseDescrip });
        }
    };

    const actualizar = () => {
        if(validarSubmit('FormBase')) {
            const baseClave = claveRef.current.value;
            const baseNom = nombreRef.current.value
            const baseDescrip = descripcionRef.current.value;
            setError(null);
            actualizarBase({ baseClave, baseNom, baseDescrip }, elementSelect.id);
        }
    };

    return ( 
        <Fragment>
            <form id="FormBase" onSubmit={elementSelect ? mostrarAlert : enviar}>
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Clave" type="claveBase" placeholder="Escriba la clave de la base"
                            refValue={claveRef} defaultValue={ elementSelect ? elementSelect.baseClave : '' } 
                            tooltipText="Máximo 16 números" tooltipName="tooltipCveBase" maxLength={16} onlyNumbers required/>
                        <InputValidation title="Nombre" type="nombreBase" placeholder="Escriba el nombre de la base"
                            refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.baseNom : '' } 
                            tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            tooltipName="tooltipNomBase" maxLength={64} required/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Descripción" type="descripcionBase" placeholder="Escriba la descripción de la base"
                            refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.baseDescrip : '' }
                            tooltipText={'Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            tooltipName="tooltipDesBase" maxLength={1024} fieldType="textarea" required/>
                    </div>  
                    { error }
                    <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                </div>
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment> 
    );
}
export default FormBases;
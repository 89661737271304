import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_catalogo, sindicatos_ } from '../../../../../../Constants/Constants';
import { ArrayJsonSindicatos } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Sindicatos = ({ id, permisos }) => {
    const [ sindicatos, setSindicatos ] = useState( null );
    useEffect(() => {
        axiosSindicatos();
    }, []);

    const axiosSindicatos = () => {
        axios.get( `${servicios_grupogack_catalogo}/sindicatos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setSindicatos( ArrayJsonSindicatos(respuesta.data.dataResponse) );
                        break;
                    case 404: //No hay registros para mostrar
                        setSindicatos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={ id }>
            { sindicatos ?
                <DataTableService id={id} title={ sindicatos_ } permisos={permisos}
                    columns={[
                        { field: "sindicatoAbrevia", title: "Abreviatura" },
                        { field: "sindicatoNombre", title: "Nombre" },
                        { field: "sindicatoDescrip", title: "Descripción" },
                        { field: "porcentajeCuota", title: "Porcentaje Cuota" },
                        { field: "importeCuota", title: "Importe Cuota (MXN)" },
                    ]}
                    data={ sindicatos } />
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default Sindicatos;
import React, { useState } from 'react';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './stylesInput.css';

/* <input type="password" className="form-control" autoComplete="current-password" ref={contraseñaRef} maxLength={lengthPassword} /> */
const PasswordInput = ({ placeholder, refValue, defaultValue, maxLength }) => {
    const [showPass, setShowPass] = useState(false);

    return (
        <div className="passIcon">
            <div className="iconPasswordLogin" onClick={ () => setShowPass(!showPass) }> { !showPass ? <Visibility/> : <VisibilityOff/> } </div>
            <input type={ !showPass ? 'password' : 'text' } className="form-control" autoComplete="current-password" maxLength={maxLength}
                placeholder={placeholder} ref={refValue} defaultValue={defaultValue}/>
        </div>
    );
};

export default PasswordInput;
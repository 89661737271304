import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_rh } from '../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import './styles.css';

import GraficaEstadisticas from './GraficaEstadisticas';

const getEstadisticas = ( dataResponse ) => {
    return({
        keys: Object.keys( dataResponse ),
        values: Object.values( dataResponse )
    });
}

const Estadisticas = ({ relacionLaboral }) => {
    const [ estadisticas, setEstadisticas ] = useState( null );
    useEffect(() => {
        axiosEstadisticas();
    }, [relacionLaboral]);

    const axiosEstadisticas = () => {/*
        setEstadisticas( null );
        axios.get( `${servicios_grupogack_rh}/empleado/grafica/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEstadisticas( getEstadisticas(respuesta.data.dataResponse) );
                        break;
                    case 401:
                        //setEstadisticas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
        */}

    return (/*
        
        <div className="estadisticas">
            <h2 className="pageTitle"> Estadísticas </h2>
            { estadisticas ?
                <GraficaEstadisticas estadisticas={estadisticas}/>
                :
                <SpinnerOval />
            }
        </div>
    */"");
};

export default Estadisticas;
import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadoresGenerales, ArrayJsonSalarioMinUMA, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormIndicadoresGenerales from './FormIndicadoresGenerales';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditIndicadoresGenerales = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);
    const [ numeroVista, setNumeroVista ]= useState(1);
    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null); 

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }
    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    alert('error');
                    break;
            } 
        })
        .catch(error => alert(error));

        Axios.get(`${servicios_grupogack_catalogo}/indicador/getall/relab`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse);
                    setIndicadoresGenerales(indicadores);
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
          // eslint-disable-next-line
    },[]);  

    const actualizarIndicadorGeneral = (indicadorGeneral, indicadorGeneralId, indicadorParametros, shouldUpdateIndParamet ) => {
        const info = indicadorGeneral;
        Axios.put( `${servicios_grupogack_catalogo}/indicador/${indicadorGeneralId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status){
                case 200:
                    const nuevoIndicador = JsonIndicadoresGenerales(res.data.dataResponse);
                    if(shouldUpdateIndParamet){
                        actualizarIndicadorParametros( nuevoIndicador.id, indicadorParametros );
                    }else{
                        if(indicadorParametros!==null)
                        enviarIndicadorParametros(nuevoIndicador.id, indicadorParametros);
                    }
                    editar(nuevoIndicador);
                    setOpenEdit( false );
                break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg} />);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => console.log(error));
    }

    const actualizarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {indicadorId: idIndicador, id:indicadorParametros.id, zonaId: indicadorParametros.zonaId, indicador: indicadorParametros.indicador, topVeces: indicadorParametros.topVeces };
        
        await Axios.put( `${servicios_grupogack_catalogo}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(cambios_no_guardados+" en Parametros Indicadores");
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    const enviarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {indicadorId: idIndicador, zonaId: indicadorParametros.zonaId, indicador: indicadorParametros.indicador, topVeces: indicadorParametros.topVeces };
    
        await Axios.post( `${servicios_grupogack_catalogo}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(registro_no_guardado+" en Parametros Indicadores");
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado));
    }

    const vista = (numero) =>{
        setNumeroVista(numero);
    }


    return (
        <ModalService title={ numeroVista===1 ? "Actualizar Concepto General" : "Configuración de Concepto" } parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormIndicadoresGenerales
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarIndicadorGeneral={actualizarIndicadorGeneral}
                        vista={vista}
                        idModulo={nombreModulo}
                        informacionAdicional={salarioMinimos}
                        indicadoresGeneral={indicadoresGenerales}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditIndicadoresGenerales;
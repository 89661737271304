import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioOrigenRecurso = ({ error, enviarOrigenRecurso, elementSelect, actualizarOrigenRecurso }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarOrigenRecurso' ) ){
            const origenClave = claveRef.current.value;
            const origenDesc = descripcionRef.current.value;
            enviarOrigenRecurso({ origenClave, origenDesc });
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarOrigenRecurso' ) ){
            const origenClave = claveRef.current.value;
            const origenDesc = descripcionRef.current.value;
            actualizarOrigenRecurso({ id: elementSelect.id, origenClave, origenDesc });
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarOrigenRecurso' : 'AgregarOrigenRecurso' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveOrigenRecurso" placeholder="Escriba la clave del Origen de Recurso"
                    tooltipText={'Máximo 2 letras mayúsculas'} tooltipName={'tooltipCveOrRec'} maxLength='2' onlyLetters
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.origenRecClave : '' } required/>
                <InputValidation title="Descripción" type="descripcionOrigenRecurso" placeholder="Escriba la descripción del Origen de Recurso" maxLength='36'
                    tooltipText={'Máximo 36 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipDescOrRec'}
                    refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.origenRecDesc : '' } required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioOrigenRecurso;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';

const RegistroPatronal = ({ regPatronalRef, infoRegPatronal }) => {

    const [regPatronales, setRegPatronales] = useState([]);

    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}/registropat/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const regPat = res.data.dataResponse.map(item => ({
                        id: item.id,
                        regPatronal: item.registroPatClave
                    }));
                    setRegPatronales(regPat);
                    break;
                default:
                    setRegPatronales([]);
                    break;
            }
        })
        .catch(error =>  setRegPatronales([]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(infoRegPatronal) {
            const regPatronal = regPatronales.find(regPatronal => regPatronal.id === infoRegPatronal.regPatronal.id);
            if(regPatronal) {
                regPatronalRef.current.value = regPatronal.id;
            };
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regPatronales]);

    return (
        <SelectValidation
            title="Registro Patronal"
            arrayOpciones={regPatronales}
            keyOpcion="regPatronal"
            refValue={regPatronalRef}
            defaultValue={infoRegPatronal ? infoRegPatronal.regPatronal.id : ''}
            optional={true}
        />
    )
}
 
export default RegistroPatronal;
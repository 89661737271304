import React from 'react';
import { Dashboard, //Tablero
    Build, //Generales
    AssignmentInd, ChromeReaderMode, OpenWith, AccountBalance, AssignmentReturned, SupervisorAccount, Streetview,
    AccountBalanceWallet, EventBusy, Timelapse, NotListedLocation, Toc, TurnedInNot, Wc, LocalHospital, PermMedia,
    LocalAtm, PostAdd, NaturePeople, FormatListBulleted, Description, SupervisedUserCircle,
    Web, //Administración
    TransferWithinAStation, Domain, Dehaze, ImageSearch,

    Grain, //Organización
    MoreVert, Work, ViewList, ScatterPlot, ViewModule, MonetizationOn, Schedule,

    AccessibilityNew, //Recursos Humanos
    People, PlaylistAdd, RepeatOne, FolderShared, FeaturedPlayList,

    InsertInvitation, //Nómina
    PermDataSetting, CalendarToday, DragIndicator, ListAlt, Repeat, FormatListNumbered,

    DynamicFeed, //Reporteador
    Assessment, RecentActors, Code, PictureAsPdf, VideoLibrary,

    HelpOutline, //Ayuda
    Healing, Backup, LocalActivity,
    MoneyOff, Gradient, GridOn, FlipCameraAndroid, WrapText, PauseCircleOutline,
    PermContactCalendar, Rowing, Apps, GroupWork, VerticalSplit, ViewWeek, Event,

    Search, //Buscador
    Cached,
    Replay,
    VpnKey,
} from '@material-ui/icons';

export const iconosDrawer = {
    dashboard: <Dashboard />,

    build: <Build />, //Generales
    notlistedlocation: <NotListedLocation />,
    openwith: <OpenWith />,
    account_balance_wallet: <AccountBalanceWallet />,
    streetview: <Streetview />,
    wc: <Wc />,
    turned_in_not: <TurnedInNot />,
    supervisor_account:<SupervisorAccount />,
    assignment_ind: <AssignmentInd />,
    localhospital: <LocalHospital />,
    chrome_reader_mode: <ChromeReaderMode />,
    account_balance: <AccountBalance />,
    toc: <Toc />,
    eventbusy: <EventBusy />,
    timelapse: <Timelapse />,
    assignment_returned: <AssignmentReturned />,
    permmedia: <PermMedia />,
    accountbalance: <AccountBalance />,
    localatm: <LocalAtm />,
    postadd: <PostAdd />,
    naturepeople: <NaturePeople />,
    formatlistbulleted: <FormatListBulleted />,
    description: <Description />,
    supervisedusercircle: <SupervisedUserCircle />,

    web: <Web />, //Administración
    transfer_within_a_station: <TransferWithinAStation />,
    domain: <Domain />,
    dehaze: <Dehaze />,
    imagesearch: <ImageSearch />,

    grain: <Grain />, //Organización
    more_vert: <MoreVert />,
    work: <Work />,
    "view-list": <ViewList />,
    scatter_plot: <ScatterPlot />,
    "view-module": <ViewModule />,
    monetization_on: <MonetizationOn />,
    schedule: <Schedule />,

    accessibility_new: <AccessibilityNew />, //Recursos Humanos
    people: <People />,
    playlist_add: <PlaylistAdd />, //Se emplea dos veces
    repeat_one: <RepeatOne />,
    folder_shered: <FolderShared />,
    featured_play_list: <FeaturedPlayList />,

    insert_invitation: <InsertInvitation />, //Nómina
    permdatasetting: <PermDataSetting />,
    calendar_today: <CalendarToday />,
    drag_indicator: <DragIndicator />,
    list_alt: <ListAlt />,
    formatlistnumbered: <FormatListNumbered />,
    repeat: <Repeat />,

    dynamic_feed: <DynamicFeed />, //Reporteador
    assessment: <Assessment />,
    recentactors: <RecentActors />,
    code: <Code />,
    monetizationon: <MonetizationOn />,
    pictureaspdf: <PictureAsPdf />,
    videolibrary: <VideoLibrary />,

    help_outline: <HelpOutline />,
    healing: <Healing />,
    backup: <Backup />,
    localactivity: <LocalActivity />,

    moneyoff: <MoneyOff />,
    gradient: <Gradient />,
    gridon: <GridOn />,
    flipcameraandroid: <FlipCameraAndroid />,
    wraptext: <WrapText />,
    pausecircleoutline: <PauseCircleOutline />,
    permcontactcalendar: <PermContactCalendar />,
    rowing: <Rowing />,
    apps: <Apps />,
    groupwork: <GroupWork />,
    verticalsplit: <VerticalSplit />,
    viewweek: <ViewWeek />,
    event: <Event />,

    vpnkey: <VpnKey/>,
    cached: <Cached/>,
    replay: <Replay/>,
    search: <Search />,
}
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_admon, servicios_grupogack_catalogo } from '../../../../../../../Constants/Constants';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

/* Elementos e Iconos para el listado de Domicilios */
import RoomIcon from '@material-ui/icons/Room';
import Button from '@material-ui/core/Button';
import Add from '@material-ui/icons/Add';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

/* Importación de modales para Insertar, Editar & Eliminar Domicilios */
import ModalAddDomicilio from './ModalAddDomicilio';
import ModalEditDomicilio from './ModalEditDomicilio';
import ModalDeleteDomicilio from './ModalDeleteDomicilio';
import './styles.css';

const DomiciliosDependencia = ({ dependenciaID, errorTable, successTable, idModulo }) => {
    const [ openAdd, setOpenAdd ] = useState( false );
    const [ openEdit, setOpenEdit ] = useState( false );
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} );
    /* --------------------- Axios para obtener los domicilios del elemento seleccionado -------------------- */
    const [ domicilios, setDomicilios ] = useState( null );
    const [ tiposDomicilio, setTiposDomicilios ] = useState( null );
    useEffect(() => {
        axiosDomicilios();
        axiosTiposDomicilio();
        // eslint-disable-next-line
    }, []);

    const axiosDomicilios = () => {
        axios.get( `${servicios_grupogack_admon}/compania/domicilios/${dependenciaID}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDomicilios( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setDomicilios([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosTiposDomicilio = () => {
        axios.get( `${servicios_grupogack_catalogo}s/tipos/domicilio/all`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposDomicilios( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposDomicilios([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------- Actualizaciones de estado (domicilios) ------------------------------- */
    const agregar = ( newElement ) => {
        setDomicilios( [ ...domicilios, newElement ] );
        successTable( 'Registro Agregado' );
    }
    const editar = ( newElement ) => {
        setDomicilios( domicilios.map( element => element.id === newElement.id ? element = newElement : element ) );
        successTable( 'Registro Actualizado' );
    }
    const eliminar = ( idElement ) => {
        setDomicilios( domicilios.filter( element => element.id !== idElement ) );
        successTable( 'Registro Eliminado' );
    }
    /* ------------------------------------------------------------------------------------------------------ */
    return (
        <React.Fragment>
            <div className="domiciliosFlex mb-2">
                <div className="domiciliosIzquerdo">
                    <h4>Domicilios</h4>
                </div>
                <div className="domiciliosDerecho">
                    <Button className="btn-third" endIcon={<Add/>} onClick={() => setOpenAdd( true )}> Agregar </Button>
                </div>
            </div>
            { domicilios && tiposDomicilio ?
                domicilios.map( domicilio => (
                    <div key={domicilio.id} className="domiciliosFlex">
                        <RoomIcon className="mt-1"/>
                        <div className="domiciliosIzquerdo marginDomicilio">
                            <b>{domicilio.tipoDom.tipodomNom}</b>
                            <address>
                                {`${domicilio.domCiaCalle}, ${domicilio.domCiaNumExt},
                                    ${domicilio.codPostal.codigopColonia}, ${domicilio.codPostal.ciudadId.ciudadNom},
                                    ${domicilio.codPostal.ciudadId.estadoId.estadoNom}, CP: ${domicilio.codPostal.codigopCp}`}
                            </address>
                        </div>

                        <div className="domiciliosDerecho">
                            <Button className="btn-color btn-auto" onClick={() => { setElementSelect( domicilio ); setOpenEdit( true ); }}> <Edit/> </Button>
                            <Button className="btn-outline btn-auto" onClick={() => { setElementSelect( domicilio ); setOpenDelete( true ); }}> <Delete/> </Button>
                        </div>
                    </div>
                ))
                :
                <SpinnerOval />
            }
            <ModalAddDomicilio openAdd={openAdd} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} informacion={{ tiposDomicilio, dependenciaID }} idModulo={idModulo} />
            <ModalEditDomicilio openEdit={openEdit} setOpenEdit={setOpenEdit} elementSelect={elementSelect} editar={editar} errorTable={errorTable} informacion={{ tiposDomicilio, dependenciaID }} idModulo={idModulo}/>
            <ModalDeleteDomicilio openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} errorTable={errorTable} informacion={{ dependenciaID }} idModulo={idModulo}/>
        </React.Fragment>
    );
};

export default DomiciliosDependencia;
import React from 'react';
import Tablero from '../Modulos/Tablero/Tablero';

//Generales
import TipoRelacionLaboral from '../Modulos/Generales/TipoRelacionLaboral/TipoRelacionLaboral';
import RegistroPatronal from '../Modulos/Generales/RegistroPatronal/RegistroPatronal';
import Giros from '../Modulos/Generales/Giros/Giros';
import TiposSecretariaDependencia from '../Modulos/Generales/TiposSecretariaDependencia/TiposSecretariaDependencia';
import MotivosBaja from '../Modulos/Generales/MotivosBaja/MotivosBaja';
import Parentesco from '../Modulos/Generales/Parentesco/Parentesco';
import Colonias from '../Modulos/Generales/Colonias/Colonias';
import Bancos from '../Modulos/Generales/Bancos/Bancos';
import TiposAusentismos from '../Modulos/Generales/TiposAusentismos/TiposAusentismos';
import TiposJornada from '../Modulos/Generales/TiposJornada/TiposJornada';
import TiposDomicilio from '../Modulos/Generales/TiposDomicilio/TiposDomicilio';
import IndicadoresGenerales from '../Modulos/Generales/IndicadoresGenerales/IndicadoresGenerales';
import NivelEscolaridad from '../Modulos/Generales/NivelEscolaridad/NivelEscolaridad';
import TipoEstadoCivil from '../Modulos/Generales/TipoEstadoCivil/TipoEstadoCivil';
import TipoSeguroSocial from '../Modulos/Generales/TipoSeguroSocial/TipoSeguroSocial';
import TipoDocumentos from '../Modulos/Generales/TipoDocumentos/TipoDocumentos';
import RegimenFiscal from '../Modulos/Generales/RegimenFiscal/RegimenFiscal';
import OrigenRecurso from '../Modulos/Generales/OrigenRecurso/OrigenRecurso';
import TipoContrato from '../Modulos/Generales/TipoContrato/TipoContrato';
import TipoRegimen from '../Modulos/Generales/TipoRegimen/TipoRegimen';
import TipoRiesgo from '../Modulos/Generales/TipoRiesgo/TipoRiesgo';
import TipoAcumulado from '../Modulos/Generales/TipoAcumulado/TipoAcumulado';
import ContratosRealacionLaboral from '../Modulos/Generales/ContratosRealacionLaboral/ContratosRealacionLaboral';
import SalarioMinimoUMA from '../Modulos/Generales/SalarioMinimoUMA/SalarioMinimoUMA';
import ImpuestoSobreNomina from '../Modulos/Generales/ImpuestoSobreNomina/ImpuestoSobreNomina';
import TablasImpuestos from '../Modulos/Generales/TablasImpuestos/TablasImpuestos';
import TablasSubsidio from '../Modulos/Generales/TablasSubsidio/TablasSubsidio';
import Bases from '../Modulos/Generales/Bases/Bases';
import TipoPasatiempo from '../Modulos/Generales/TipoPasatiempo/TipoPasatiempo';
import Sindicatos from '../Modulos/Generales/Sindicatos/Sindicatos';
import DepartamentosGenerales from '../Modulos/Generales/Departamentos/DepartamentosGenerales';

//Administración
import Usuarios from '../Modulos/Administracion/Usuarios/Usuarios';
import Dependencias from '../Modulos/Administracion/Dependencias/Dependencias';
import Proyectos from '../Modulos/Administracion/Proyectos/Proyectos';
import CargaLogo from '../Modulos/Administracion/CargaLogo/CargaLogo';

//Organizacion
import Niveles from '../Modulos/Organizacion/Niveles/Niveles';
import Puestos from '../Modulos/Organizacion/Puestos/Puestos';
import Tabuladores from '../Modulos/Organizacion/Tabuladores/Tabuladores';
import Departamentos from '../Modulos/Organizacion/Departamentos/Departamentos';
import NominasOrganizacion from '../Modulos/Organizacion/Nominas/Nominas';
import TabuladoresQuinquenios from '../Modulos/Organizacion/TabuladoresQuinquenios/TabuladoresQuinquenios';
import Turnos from '../Modulos/Organizacion/Turnos/Turnos';

//Recursos Humanos
import Empleados from '../Modulos/RecursosHumanos/Empleados/Empleados';
import Ausentismos from '../Modulos/RecursosHumanos/Ausentismos/Ausentismos';
import Reingresos from '../Modulos/RecursosHumanos/Reingresos/Reingresos';
import Expediente from '../Modulos/RecursosHumanos/Expediente/Expediente';
import Historico from '../Modulos/RecursosHumanos/Historico/Historico';
import Especiales from '../Modulos/RecursosHumanos/Especiales/Especiales';
import Plantillas from '../Modulos/RecursosHumanos/Plantillas/Plantillas';

//Nómina
import NominasNomina from '../Modulos/Nomina/Nominas/Nominas';
import PeriodosNomina from '../Modulos/Nomina/PeriodosNomina/PeriodosNomina';
import Indicadores from '../Modulos/Nomina/Indicadores/Indicadores';
import Politicas from '../Modulos/Nomina/Politicas/Politicas';
import Calculo from '../Modulos/Nomina/Calculo/Calculo';
import MovimientosVariables from '../Modulos/Nomina/Variables/MovimientosVariables';
import MovimientosFijos from '../Modulos/Nomina/Fijos/MovimientosFijos';
import MovimientosRelacionados from '../Modulos/Nomina/Relacionados/MovimientosRelacionados';
import MovimientosPendientes from '../Modulos/Nomina/Pendientes/MovimientosPendientes';
import Prestamos from '../Modulos/Nomina/Prestamos/Prestamos';
import IMSS from '../Modulos/Nomina/IMSS/IMSS';
import CargaMasivaDatos from '../Modulos/Nomina/CargaMasivaDatos/CargaMasivaDatos';
import Pensiones from '../Modulos/Nomina/Pensiones/Pensiones';
import Acumulados from '../Modulos/Nomina/Acumulados/Acumulados';

//import Dispersiones from '../Modulos/Reporteador/Dispersiones/Dispersiones';
import RecursosHumanos from '../Modulos/Reporteador/RecursosHumanos/RecursosHumanos';
import ReportesNominas from '../Modulos/Reporteador/Nominas/ReportesNominas';
import ReportesIsn from '../Modulos/Reporteador/Isn/ReportesNominas';
import ReportesImss from '../Modulos/Reporteador/Imss/ReportesNominas';
import ReportesPoliza from '../Modulos/Reporteador/Polizas/ReportesNominas';
import Reporteador from '../Modulos/Reporteador/Reporteador'; //Reporteador
import Auditoria from '../Modulos/Reporteador/Auditoria/Auditoria';
import LayoutCFDI from '../Modulos/Reporteador/LayoutCFDI/LayoutCFDI';

import Ayuda from '../Modulos/Ayuda/Ayuda'; //Ayuda
import Manual from '../Modulos/Ayuda/Manual';
import VideosTutoriales from '../Modulos/Ayuda/VideosTutoriales';
import Mensajes from '../Modulos/Mensajes/Mensajes'; //Mensajes


/* Funcion para determinar que componente corresponde a cada ID de navegacion */
export function mostrarFormulario( IDModulo, permisos ) {
    
    switch ( isNaN( IDModulo ) ? parseInt( IDModulo.split('-')[0]) : IDModulo ) {
        
        case 1: return <Tablero />;

        case 3: return <TipoRelacionLaboral id={IDModulo} permisos={permisos} />;
        case 4: return <RegistroPatronal id={IDModulo} permisos={permisos} />;
        case 5: return <Giros id={IDModulo} permisos={permisos} />;
        case 6: return <TiposSecretariaDependencia id={IDModulo} permisos={permisos} />;
        case 7: return <MotivosBaja id={IDModulo} permisos={permisos} />;
        case 8: return <Parentesco id={IDModulo} permisos={permisos} />;
        case 11: return <Colonias id={IDModulo} permisos={permisos} />;
        case 12: return <Bancos id={IDModulo} permisos={permisos} />;
        case 13: return <TiposAusentismos id={IDModulo} permisos={permisos} />;
        case 14: return <TiposJornada id={IDModulo} permisos={permisos} />;
        case 15: return <TiposDomicilio id={IDModulo} permisos={permisos} />;
        case 16: return <IndicadoresGenerales id={IDModulo} permisos={permisos} />;
        case 17: return <NivelEscolaridad id={IDModulo} permisos={permisos} />;
        case 44: return <TipoEstadoCivil id={IDModulo} permisos={permisos} />;
        case 45: return <TipoSeguroSocial id={IDModulo} permisos={permisos} />
        case 46: return <TipoDocumentos id={IDModulo} permisos={permisos} />;
        case 54: return <RegimenFiscal id={IDModulo} permisos={permisos} />;
        case 55: return <OrigenRecurso id={IDModulo} permisos={permisos} />;
        case 56: return <TipoContrato id={IDModulo} permisos={permisos} />;
        case 57: return <TipoRegimen id={IDModulo} permisos={permisos} />;
        case 58: return <TipoRiesgo id={IDModulo} permisos={permisos} />;
        case 60: return <TipoAcumulado id={IDModulo} permisos={permisos} />;
        case 61: return <ContratosRealacionLaboral id={IDModulo} permisos={permisos} />;
        case 65: return <SalarioMinimoUMA id={IDModulo} permisos={permisos} />;
        case 66: return <ImpuestoSobreNomina id={IDModulo} permisos={permisos} />;
        case 67: return <TablasImpuestos id={IDModulo} permisos={permisos} />;
        case 72: return <TablasSubsidio id={IDModulo} permisos={permisos} />;
        case 73: return <Bases id={IDModulo} permisos={permisos} />;
        case 74: return <TipoPasatiempo id={IDModulo} permisos={permisos} />;
        case 77: return <Sindicatos id={IDModulo} permisos={permisos} />;
        case 81: return <DepartamentosGenerales id={IDModulo} permisos={permisos} />;

        case 19: return <Usuarios id={IDModulo} permisos={permisos} />;
        /*case 20: return <Dependencias id={IDModulo} permisos={permisos} />;
        case 21: return <Proyectos id={IDModulo} permisos={permisos} />;*/
        case 47: return <CargaLogo id={IDModulo} permisos={permisos} />;

        case 23: return <Niveles id={IDModulo} permisos={permisos} />;
        case 24: return <Puestos id={IDModulo} permisos={permisos} />;
        case 25: return <Tabuladores id={IDModulo} permisos={permisos} />;
        case 26: return <Departamentos id={IDModulo} permisos={permisos} />;
        case 27: return <TabuladoresQuinquenios id={IDModulo} permisos={permisos} />;
        case 59: return <Turnos id={IDModulo} permisos={permisos} />
        case 28: return <NominasOrganizacion id={IDModulo} permisos={permisos} />;

        case 30: return <Empleados id={IDModulo} permisos={permisos} />;
        case 31: return <Ausentismos id={IDModulo} permisos={permisos} />;
        case 32: return <Reingresos id={IDModulo} permisos={permisos} />;
        case 33: return <Expediente id={IDModulo} permisos={permisos} />;
        case 34: return <Historico id={IDModulo} permisos={permisos} />;
        case 62: return <Especiales id={IDModulo} permisos={permisos} />;
        case 78: return <Plantillas id={IDModulo} permisos={permisos} />;

        case 36: return <NominasNomina id={IDModulo} permisos={permisos} />;
        case 37: return <PeriodosNomina id={IDModulo} permisos={permisos} />;
        case 38: return <Indicadores id={IDModulo} permisos={permisos} />;
        case 39: return <Politicas id={IDModulo} permisos={permisos} />;
        case 40: return <Calculo id={IDModulo} permisos={permisos} />;
        case 41: return <MovimientosVariables id={IDModulo} permisos={permisos} />;
        case 63: return <CargaMasivaDatos id={IDModulo} permisos={permisos} />;
        case 64: return <IMSS id={IDModulo} permisos={permisos} />;
        case 68: return <MovimientosFijos id={IDModulo} permisos={permisos} />;
        case 69: return <MovimientosRelacionados id={IDModulo} permisos={permisos} />;
        case 70: return <MovimientosPendientes id={IDModulo} permisos={permisos} />;
        case 71: return <Pensiones id={IDModulo} permisos={permisos} />;
        case 75: return <Prestamos id={IDModulo} permisos={permisos} />;
        case 76: return <Acumulados id={IDModulo} permisos={permisos} />;
        
        case 42: return <Reporteador id={IDModulo} permisos={permisos} />;
        case 48: return <ReportesNominas id={IDModulo} permisos={permisos} />;
        case 20: return <ReportesIsn id={IDModulo} permisos={permisos} />;
        case 21: return <ReportesImss id={IDModulo} permisos={permisos} />;
        case 22: return <ReportesPoliza id={IDModulo} permisos={permisos} />;
        
        case 49: return <RecursosHumanos id={IDModulo} permisos={permisos} />;
        case 50: return <LayoutCFDI id={IDModulo} permisos={permisos} />;
        //case 51: return <Dispersiones id={IDModulo} permisos={permisos} />;
        case 80: return <Auditoria id={IDModulo} permisos={permisos} />;
        
        case 43: return <Ayuda id={IDModulo} permisos={permisos} />;
        case 52: return <Manual id={IDModulo} permisos={permisos} />;
        case 53: return <VideosTutoriales id={IDModulo} permisos={permisos} />;
        
        case 1000: return <Mensajes id={IDModulo} permisos={permisos} />;
        
        default: return null ;
    }
}

/* Funcion que permite obtener modulos hijos nombre/ID/Ruta */
export function informacionModulos( modulosAPI ){
    const informacionModulos = [];
    modulosAPI.map( superModulo => {
        if( superModulo.children ){
            superModulo.children.map( mod => {
                const {id, nombre, icono, permisos} = mod;
                const modulo = { id, nombre, ruta: `${superModulo.nombre}/${mod.nombre}`, icono, permisos };
                informacionModulos.push( modulo );
                return '';
            });
        }
        return '';
    } );
    return informacionModulos ;
}
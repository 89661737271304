import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_rh, acumulados_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Acumulados = ({ id, permisos }) => {
    
    const [empleados, setEmpleados] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupogack_rh}/empleado/all/`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setEmpleados(res.data.dataResponse);
                    break;
                case 404:
                    setEmpleados([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]);

    return (
        <div id={id}>
            {   
                (empleados)
                ?   <DataTableService
                        id={id}
                        title={acumulados_}
                        columns={[
                            { field: "empleadoClave", title: "Núm. Empleado" },
                            { field: "nombreCompleto", title: "Nombre" },
                            { field: "empleadoRfc", title: "RFC" },
                            { field: "puestoNom", title: "Puesto" },
                            { field: "nominaNombre", title: "Nómina" },
                            { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                        ]}
                        data={empleados}
                        hideIconAdd hideIconDelete hideIconEdit
                        showIconApps
                        informacionAdicional={{ permisos }}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default Acumulados;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';

import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioSalarioMinUMA from './FormularioSalarioMinUMA'; 
import { JsonSalarioMinUMA } from '../../../Helpers/JsonToOneLevel';

const ModalEditSalarioMinUMA = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarSalarioMinUMA = ( salarioMin ) => {
        axios.put( `${servicios_grupogack_catalogo}s/salario/minimouma/${elementSelect.id}`, salarioMin, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200: 
                        editar(JsonSalarioMinUMA(respuesta.data.dataResponse));
                        setOpenEdit( false );
                        break;
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> ); 
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Salario Mínimo / UMA" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioSalarioMinUMA
                    error={error}
                    elementSelect={elementSelect}
                    actualizarSalarioMinUMA={actualizarSalarioMinUMA}
                />
        </ModalService>
    );
};

export default ModalEditSalarioMinUMA;
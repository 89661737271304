/* eslint-disable no-use-before-define */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { Tooltip } from 'reactstrap';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

export default function AutocompleteComponent({ id, title, options, optionListView, optionKey, action, disabled, size, placeholder, required, tooltipText, tooltipName, error, defaultValue, value }) {

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple')); 
  const [inputValue, setInputValue] = useState('');
  
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <Autocomplete
      id={id}
      style={{ margin: 0, width: (size) ? size : '100%' }}
      options={options}
      onChange={(e,v) => action(v)}
      noOptionsText="No existen coincidencias"
      size="small"
      clearonblur="true"
      clearText="Borrar"
      disabled={disabled ? disabled : false}
      defaultValue={defaultValue}
      value={value}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      getOptionLabel={option => option[`${optionListView}`]}
      renderInput={params => (
        <div className="form-group">
          <label style={{marginBottom: '0px'}}>{title}{(required) ? <strong className="requerido">* </strong> : null}{ tooltipText && tooltipName && <span id={tooltipName} className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span> }</label>
          <TextField {...params} variant="outlined" margin="normal" placeholder={placeholder} />
          <div className="invalid-feedback" style={error ? { display: 'block'} : { display: 'none'} }>Complete el campo requerido</div>
          { tooltipText && tooltipName && <Tooltip placement="right" isOpen={tooltipOpen} target={tooltipName} toggle={toggle}>{tooltipText}</Tooltip> }        
        </div>
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option[`${optionListView}`], inputValue);
        const parts = parse(option[`${optionListView}`], matches);

        return (
          <React.Fragment>
            { optionKey ?
              <span> {`${option[optionKey]} / ${option[optionListView]}`} </span>
              :
              <div>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </div>
            }
          </React.Fragment>
        );
      }}
    />
  );
}
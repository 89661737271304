import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_url, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import InformacionUsuario from './InformacionUsuario';

const ModalEditUsuarios = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, successTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    const actualizarUsuario = ( usuario, userID ) => {
        axios.put( `${servicios_grupogack_url}/info/${userID}`, usuario, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        editar( respuesta.data.dataResponse );
                        setOpenEdit( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                errorTable( cambios_no_guardados );
            })
    }

    return (
        <ModalService title="Actualizar Usuario" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <InformacionUsuario
                    error={error}
                    userID={elementSelect.id}
                    informacion={informacionAdicional}
                    actualizarUsuario={actualizarUsuario}
                    errorTable={errorTable}
                    successTable={successTable}
                    idModulo={nombreModulo}
                />
        </ModalService>
    );
};

export default ModalEditUsuarios;
import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { limpiarPestanas } from '../../../Redux/actions/NavActions';
/* -----------------------------------------------------------------------------------------------------  */
import axios from 'axios';
import { servicios_grupogack_url, servicios_grupogack, ocurrio_un_error } from '../../../Constants/Constants';
import { cerrarSession, headersAuth, obtenerUsuario, isAuthenticaded } from '../../../Services/AuthService';
import { resetAppStore } from '../../../Redux/actions/ResetActions';
import io from 'socket.io-client';
import Tour from './Tour/Tour';
import steps from './Tour/Steps.json';
import TerminosPrivacidad from './TerminosyCondiciones/TerminosPrivacidad';
import ModalTipoRelacionLaboral from './SeleccionTipoRelacionLaboral/ModalTipoRelacionLaboral';
/* ------------------------------- Deben de ser importados en este orden -------------------------------  */
import clsx from 'clsx';
import CssBaseline from '@material-ui/core/CssBaseline';
import HeaderAppBar from './MenuDrawer/Header/HeaderAppBar';
import MenuDrawer from './MenuDrawer/Drawer/MenuDrawer';
import useStylesMenu from './MenuDrawer/UseStylesMenu'; /* Los estilos son importados al ultimo */
/* -----------------------------------------------------------------------------------------------------  */
import Contenidos from './Modulos/Contenidos';
import TabsContainer from './MenuDrawer/TabsNavegacion/TabsContainer';
import Footer from '../../Footer/Footer';
import AlertForm from '../../../Services/AlertForm';
/* ------------------------------------------------------------------------------------------------------- */
import HOCAppUsuario from './MenuDrawer/HOCAppUsuario';
import AlertPassSession from '../../../Services/AlertPassSession';
import { NotificationContainer } from 'react-notifications';

const AppUsuario = ({ limpiarPestanas, resetAppStore, modulosApi, cleanIntervals, openContrasena, setModalContraseña, setTimeout, activarEventosInactividad, newToken }) => {
  const classes = useStylesMenu();
  //url del servidor de chat
  //const endpoint = 'wss://sao.grupogack.net';
  const endpoint = '';
  //abre y vierra el menú lateral
  const [openMenu, setOpenMenu] = useState(true);
  //guarda el socket del cliente
  const [socket, setSocket] = useState(null);
  //usuario logueado
  const [usuario, setUsuario] = useState( obtenerUsuario() );
  //variables de tour
  const [run, setRun] = useState(true);
  const [tour, setTour] = useState(usuario.tour);
  //terminos y condiciones
  const [tyc, setTyC] = useState(usuario.tyc);
  const [modal, setModal] = useState(true);
  //SeleecionRelacionLaboral
  const [companias, setcompanias] = useState([]);
  const [relacionLaboral, setCompania] = useState('');
  const [modalRelacionLaboral, setModalRelacionLaboral] = useState(false);
  const [error, setError] = useState(null);

  //funcion para modificar el estado del modal de Compania
  const toggleRelacionLaboral = () => setModalRelacionLaboral(!modalRelacionLaboral);

  const obtenerRelacioLaboralLS = () => {
    return JSON.parse(localStorage.getItem("IGCompania"));
  };
  
  /**----- Cuando se ha realizado el tour y el socket es nulo, se obtiene el socket para comunicarse con el servidor de chat ------*/
  /*
  useEffect(() => {/
    if(tour === "false") {
      if(socket === null) {
        const s = io(endpoint); 
        setSocket(s);
        s.emit('chat:login',  
          { usuario: usuario.usuario, token: usuario.token, nuevoUsuario: usuario.tour }
        );
      }
    }
    return;
  }, [socket, usuario, tour]);

  useEffect(() => {
    if(newToken) {
      socket.emit('chat:changeToken',
        { usuario: usuario.usuario, nuevoToken: newToken }
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newToken]);
*/
  const servicioSetRelLaboral = companiaSelect => {
    axios.post( `${servicios_grupogack_url}/asigna/empresa`, {empresa: companiaSelect.id}, headersAuth() )
    .then(res => {
        switch(res.data.status){
            case 200:
                limpiarPestanas(limpiarPestanas);
                localStorage.setItem( "IGCompania", JSON.stringify(companiaSelect));
                setCompania(companiaSelect);

                toggleRelacionLaboral();
                setError(null);
                break;
            default:
                setError( <AlertForm message={ ocurrio_un_error } /> );
                break;
        }
    })
    .catch( error => {
        setError( <AlertForm message={ ocurrio_un_error } /> )
    });
  };
 
  useEffect(() => {
    axios.get(`${servicios_grupogack}/empresa/`, headersAuth())
    .then(res => {
        switch(res.data.status) {
            case 200:
                //setcompanias(res.data.dataResponse);
                setcompanias(res.data.dataResponse);
                if(res.data.dataResponse.length === 1) servicioSetRelLaboral(res.data.dataResponse[0]);
                break;
            default:
                closeSession();
                break;
        }
    })
    .catch(error => closeSession());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  //al iniciar se busca en localstorage IGCompania
  useEffect(() => {
    const relacionLaboralLS = obtenerRelacioLaboralLS();
    if(relacionLaboralLS) {
      setCompania(relacionLaboralLS);
    } else {
      if(tour === "false") {
        if(companias.length === 1 & relacionLaboral === '') {
          setCompania(companias[0]);
        } else {
          setModalRelacionLaboral(true);
        }
      }
    }
    return;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tour]);

  /* ------------------ Metodo para cerrar sesión ------------------ */
  const cerrarSocket = () => {
    if(socket !== null) {
      //emit al servidor de socket para que borre el socket y actualice el arreglo de conectados
      socket.emit('chat:disconnectSocket', usuario.usuario);
      //cierra el socket del cliente
      socket.close();
    }
  }

  const closeSession = () => {
    axios.post( `${servicios_grupogack_url}/account/logout`, null, headersAuth() );
    try {
      //emit al servidor de socket para que borre el socket y actualice el arreglo de conectados
      socket.emit('chat:disconnect');
      //cierra el socket del cliente
      socket.close();

      //Termina el setInterval() creado para refrescar el token
      cleanIntervals();
      cerrarSession();
      resetAppStore();
    } catch(error) {
      cerrarSession();
      resetAppStore();
    }
  };

/* -----------------------Cierra socket cuando deja de existir la pestaña--------------------------- */
  window.addEventListener("beforeunload", () => cerrarSocket(), false);
/* --------------------------- Escuchar cambios en local Storage ---------------------------  */
  window.addEventListener('storage', e => {

    if(e.key === "IGSESSID") {
      const token = localStorage.getItem("IGSESSID");
      if(!token) {
        cerrarSession();
      } else {
        if(!isAuthenticaded()) {
          cerrarSession();
        }
      }
    }

    if(e.key === "IGCompania") {
      window.location.reload();
    }
  });

  return (
    <Fragment>
      <div className={classes.root}> 
        {  
          (tyc === "false")
          /* no ha aceptado tyc*/
          ? <TerminosPrivacidad modal={modal} setModal={setModal} setTyC={setTyC} closeSession={closeSession}/>
          : null
        }
        {  
          (companias.length > 1)
          /* solo tiene acceso a una compañia */
          ? <ModalTipoRelacionLaboral toggleRelacionLaboral={toggleRelacionLaboral} modal={modalRelacionLaboral} companias={companias} setCompania={setCompania} relacionLaboral={relacionLaboral} servicioSetRelLaboral={servicioSetRelLaboral} error={error} setError={setError}/>
          : null
        }
        {  
          (tour !== "false")
          /* no ha hecho el tour */
          ? <Tour setRun={setRun} setTour={setTour} steps={steps} />
          : <style>{`.MuiDrawer-paper { overflow-y: auto !important; }`}</style>
        }
        <CssBaseline />
        <HeaderAppBar steps={steps} run={run} openMenu={openMenu} setOpenMenu={setOpenMenu} socket={socket} usuario={usuario} modulosApi={modulosApi} tour={tour} relacionLaboral={relacionLaboral} toggleRelacionLaboral={toggleRelacionLaboral} companias={companias}/>
        <MenuDrawer steps={steps} run={run} classes={classes} openMenu={openMenu} modulosApi={modulosApi} socket={socket} closeSession={closeSession} usuario={usuario} setUsuario={setUsuario}/>
          <main className={clsx(classes.content, {[classes.contentShift]: openMenu, })}>
            <div className={classes.toolbar} />
            <TabsContainer />
            <Contenidos openMenu={openMenu} relacionLaboral={relacionLaboral} modulosApi={modulosApi}/>
          </main>
        <AlertPassSession openContrasena={openContrasena} setModalContraseña={setModalContraseña} setTimeout={setTimeout} activarEventosInactividad={activarEventosInactividad} closeSession={closeSession}/>
      </div>
      <Footer className="footer-components"/>
      <NotificationContainer/>
    </Fragment>
  );
}

export default connect(null, { resetAppStore, limpiarPestanas } )( HOCAppUsuario(AppUsuario) );
import React from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';

const ModalDeleteSeguroSocial = ({ alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {

    const axiosEliminar = () => {
        axios.delete( `${servicios_grupogack_catalogo}/tiposegsoc/${elementSelect.id}`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        eliminar( elementSelect.id );
                        break;
                    case 404:
                        errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_eliminado );
            })
    }

    return (
        <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
    );
};

export default ModalDeleteSeguroSocial;
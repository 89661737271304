//export const NAMEUSER_EXPR = /^[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{2,30}$|^[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}\s()[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}$|^[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}\s()[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}\s()[A-Za-zÑñÁÉÍÓÚáéíóúÜü]{1,30}$/;
export const NAMEUSER_EXPR = /^(([A-Za-zÑñÁÉÍÓÚáéíóúÜü][\s]?)*){1,60}$/;
export const SURNAMEUSER_EXPR = /^(([A-Za-zÑñÁÉÍÓÚáéíóúÜü][\s]?)*){1,30}$/;
export const CORREO_EXPR = /^([\w.\-0-9]{5,63})([@]{1})([a-z]{2,20})([.]{1})([a-z]{2,3}|([a-z]{3}[.]{1}[a-z]{2}))$/;
// Caracteres especiales que deberán considerarse posteriormente para creación de password !@#$&*%/()=?¡.,|°<>;+-_
//export const PASSWORD_EXPR = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z])\S{8,16}$/;
export const PASSWORD_EXPR = /^(?=.*[A-Z])(?=.*[!@#$&*%/()=?¡.,|°<>;+_])(?=.*[0-9])(?=.*[a-z])\S{8,16}$/;
export const RFC_EXPR = /^(([A-ZÑ]{1}[AEIOU]{1}[A-ZÑ]{1,2}))([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([0-9A-ZÑ]{3})\b/;
export const CURP_EXPR = /^(([A-ZÑ]{1}[AEIOU]{1}[A-ZÑ]{2}))([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]{1})(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)([B-DF-HJ-NP-TV-Z]{3})([0-9A-Z]{1}[0-9]{1})\b/;
export const COMPANIA_EXPR = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)]{2,64}$/;
export const CUENTAINDICADORES_3 = /^[A-Z0-9]{3}$/;
export const CUENTAINDICADORES_6 = /^[A-Z0-9]{6}$/;
export const CLAVE_3_NUM = /^[0-9]{3}$/;
export const CLAVE_6_NUM = /^[0-9]{6}$/;
export const CLAVE_9_NUM = /^[0-9]{9}$/;
export const CLAVE_8_NUM = /^[0-9]{8}$/;
export const CLAVE_1_16_NUM = /^[0-9]{1,16}$/;
export const CLAVE_2_ESP = /^[A-Za-z0-9=@#*<>]{2}$/;
export const CLAVE_2_20 = /^[A-Za-z0-9]{2,20}$/;
export const CLAVE_2_20_ESP = /^[A-Za-z0-9=@#*<>]{2,20}$/;
export const CLAVE_1_3 = /^[A-Za-z0-9]{1,3}$/;
export const CLAVE_1_5 = /^[A-Za-z0-9]{1,5}$/;
export const CLAVE_REG_PATRONAL = /^([A-Z]{1}[0-9]{10})$/;
export const CLAVE_1_8 = /^[A-Za-z0-9]{1,8}$/;
export const CLAVE_1_16 = /^[A-Za-z0-9]{1,16}$/;
export const CLAVE_1_3_NUMEROS = /^[0-9]{1,3}$/;
export const CLAVE_1_3_MAYUSCULAS = /^[A-Z]{1,3}$/;
export const CED_PROF = /^[A-Z0-9-]{1,13}$/;
export const NOMBRE_1_50 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s]{1,50}$/;
export const NOMBRE_1_230 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s]{1,230}$/;
export const TEXTO_1_20 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,20}$/;
export const TEXTO_1_64 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,64}$/;
export const TEXTO_100 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,100}$/;
export const TEXTO_1_120 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,120}$/;
export const TEXTO_1_124 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,124}$/;
export const TEXTO_1_128 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,128}$/;
export const TEXTO_1_200 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,200}$/;
export const TEXTO_1_256 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,256}$/;
export const TEXTO_1_512 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,512}$/;
export const TEXTO_1_1024 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,1024}$/;
export const TEXTO_1_2024 = /^[\wÑñÁáÉéÍíÓóÚúÜü/\s.,\-)("'#]{1,2024}$/;
export const AGNIO =/^(19|20)\d{2}$/;
export const MONTO =/^(\$)?(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/;
export const CANTIDAD_16 = /^[0-9]{1,16}$/;
export const GRADO_ESTUDIOS = /^[1-9]{1}$/;
export const DIAS_AGNIO = /^(0{0,2}[12]?[0-9]?[0-9]|3[0-5][0-9]|36[0-6])(\.\d+)?$/;
/* Expresiones regulares para modulo Dependencias/Domicilios */
export const CODIGOPOSTAL_EXPR = /^[0-9]{5}$/;
export const CALLE_EXPR = /^[A-Za-z0-9áéíóúÁÉÍÓÚñÑ\s.,\-&#]+$/;
export const NUM_INTERIOR_EXPR = /^[A-Za-z0-9\s#-]+$/;
export const TELEFONO_EXPR = /^[0-9]{10}$/;
export const EXT_TELEFONICA_EXPR = /^[0-9]{1,5}$/;
/* Expresiones regulares para modulo Usuarios */
export const USUARIO_CORREO_EXPR = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
export const USUARIO_NOMBRE_EXPR = /^[A-Za-záéíóúÁÉÍÓÚñÑ .,-]+$/;
/* Expresiones regulares para modulo /Organización/Nómina */
export const CLAVE_NOM_ORG = /^[A-Za-z0-9-]+$/;
export const TEXT_NOM_ORG = /^[A-Za-z0-9áéíóúÁÉÍÓÚñÑ /().,-]+$/;

export const TIPO_PARENTESCO = /^[A-Za-zÑñÁÉÍÓÚáéíóúÜü .,\-()]{2,64}/;
export const PROMEDIO_CALIFICACION = /^([0-9]{1}[.]{1}[0-9]{2}$)|10.00$/;
export const NSS = /^[0-9]{11}$/;
export const NUM_EMP = /^[0-9A-Z-]{1,10}$/;

export const CUENTA = /^[0-9]{10,11}$/;
export const CLABE = /^[0-9]{18}$/;
export const IMPORTE_DECIMAL = /^[0-9]{1,6}[.]{1}[0-9]{2}$/;
export const PORCENTAJE_DECIMAL = /^([0-9]{1,2}[.]{1}[0-9]{2}$)|100.00$/;
export const PORCENTAJE_3_DECIMALES = /^([0-9]{1,2}[.]{1}[0-9]{3}$)|100.000$/;
export const PRIORIDAD = /^[0-9]{1,254}$/;
export const DATE_SAFARI = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;

export const ENTERO_5_DECIMAL_2 = /^\$?((\d+|\d{1,2}(,\d{3}))[.]{1}[0-9]{2}$)$/; //1 entero de máximo 5 dígitos & 2 decimales
export const ENTERO_1_DECIMAL_6 = /^([0-9]{1}[.]{1}[0-9]{6}$)$/; //1 entero de máximo 1 dígito & 6 decimales

/* Funcion que retorna status y mensaje de validacion para el valor de un input */
export function comprobar( input, type ) {
    if(input !== ''){
        switch( type ){
            case 'name':
            case 'nombre':
                if( NAMEUSER_EXPR.test( input )){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'lastName':
            case 'pApellido':
            case 'sApellido':
                if( SURNAMEUSER_EXPR.test( input )){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Apellido no válido' };
                }
            case 'e-mail':
                if( CORREO_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Email no válido' };
                }
            case 'password':
            case 'pass':
                if( PASSWORD_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Contraseña no válida' };
                }
            case 'numEmpleado':
                if( NUM_EMP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Número de empleado no válido' };
                }
            case 'rfc':
                if( RFC_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'RFC no válido' };
                }
            case 'curp':
                if( CURP_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'CURP no válido' };
                }
            case 'gradoEstudios':
                if( GRADO_ESTUDIOS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'CURP no válido' };
                }
            case 'empresa':
            case 'compania':
                if( COMPANIA_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'claveCvePresupuestal':
                if( CLAVE_2_ESP.test( input ) ){
                return { status: true, mensaje: '' };
            }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveUEjecutora3':
            case 'clavePrograma3':
                if( CLAVE_3_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveUEjecutora6':
                if( CLAVE_6_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'clavePAccion':
                if( CLAVE_8_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'clavePrograma9':
                if( CLAVE_9_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'indicadorsat':
            case 'claveBanco':
            case 'claveParentesco':
            case 'claveSeguroSocial':
            case 'claveJornada':
            case 'claveMotBaja':
                if( CLAVE_1_3.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveIndGenerales':
            case 'claveUnidadResponsable':
            case 'claveNivel':
                if( CLAVE_1_5.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveCAdscripcion':
                if( CLAVE_2_20_ESP.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'clavePlaza':
                if( CLAVE_2_20.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'ClaveRegPatron':
                if( CLAVE_REG_PATRONAL.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveTipoRelLab':
            case 'claveTipAcu':
                if( CLAVE_1_16.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'folioPensionAlimenticia':
            case 'claveAusentismo':
                if( CLAVE_1_8.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Folio no válido' };
                }
            case 'claveRegimenFiscal':
            case 'claveTipoContrato':
            case 'claveTipoRegimen':
            case 'claveTipoRiesgo':
                if( CLAVE_1_3_NUMEROS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveOrigenRecurso':
                if( CLAVE_1_3_MAYUSCULAS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'claveBase':
                if( CLAVE_1_16_NUM.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'nombreDiscapacidad':
                if( TEXTO_1_20.test( input ) ){
                    return { status: true, mensaje: '' };
                 }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'ciudad':
            case 'estado':
            case 'nombreEdoCivil':
                if( NOMBRE_1_50.test( input ) ){
                    return { status: true, mensaje: '' };
                 }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombrePasatiempo':
            case 'carrera':
                if( NOMBRE_1_230.test( input ) ){
                    return { status: true, mensaje: '' };
                 }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreTipoRelLab':
            case 'nombreTipoAcu':
            case 'nombreRecibo':
            case 'nombreBase':
                if( TEXTO_1_64.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreTurno':
            case 'nombreContrato':
                if( TEXTO_100.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreTipoDomicilio':
                if( TEXTO_1_120.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreCAdscripcion':
                if( TEXTO_1_124.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreBanco':
            case 'nombreGiro':
            case 'nombreColonia':
            case 'nombreParentesco':
            case 'nombreSeguroSocial':
            case 'nombreAusentismo':
            case 'nombreJornada':
            case 'nombreDocumento':
            case 'nombreIndGenerales':
            case 'nombreTipoSecDep':
            case 'nombreNivelEsc':
            case 'nombreUEjecutora':
            case 'nombrePrograma':
            case 'nombrePAccion':
            case 'nombreCvePresupuestal':
            case 'nombreSindicato':
                if( TEXTO_1_128.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'nombreMotBaja':
            case 'nombreDepartamento':
                if( TEXTO_1_256.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'descripcionRegimenFiscal':
            case 'descripcionOrigenRecurso':
            case 'descripcionTipoContrato':
            case 'descripcionTipoRegimen':
            case 'descripcionTipoRiesgo':
                if( TEXTO_1_128.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionBanco':
            case 'descripcionSindicato':
                if( TEXTO_1_256.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionTipoRelLab':
            case 'descripcionDepartamento':
                if( TEXTO_1_512.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionCAdscripcion':
            case 'descripcionTipAcu':
            case 'descripcionDocumento':
            case 'descripcionUsuario':
            case 'descripcionBase':
                if( TEXTO_1_1024.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'descripcionPasatiempo':
            case 'descripcionBaja':
                if( TEXTO_1_2024.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'cuentaIndicadores3':
                if( CUENTAINDICADORES_3.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'cuentaIndicadores6':
                if( CUENTAINDICADORES_6.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            /* -- Casos Dependencias */
            case 'cp':
                if( CODIGOPOSTAL_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Código postal no válido' };
                }
            case 'calle':
                if( CALLE_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Dirección, calle no válida' };
                }
            case 'numIntExt':
                if( NUM_INTERIOR_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Número interior/exterior no válido' };
                }
            case 'parentesco':
                if( TIPO_PARENTESCO.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre de tipo de parentesco no válido' };
                }
            case 'telefono':
                if( TELEFONO_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Número de teléfono no válido' };
                }
            case 'extTelefonica':
                if( EXT_TELEFONICA_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Extensión telefónica no válida' };
                }
            /* -- Casos Usuario */
            case 'usuarioCorreo':
                if( USUARIO_CORREO_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cuenta de correo no válida' };
                }
            case 'usuarioNombre':
                if( USUARIO_NOMBRE_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'usuarioApellido':
                if( USUARIO_NOMBRE_EXPR.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Apellido no válido' };
                }
            /* -- Casos /Organización/Nóminas */
            case 'claveNominaOrg':
                if( CLAVE_NOM_ORG.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Clave no válida' };
                }
            case 'nombreNominaOrg':
            case 'nomCortoNominaOrg':
                if( TEXT_NOM_ORG.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Nombre no válido' };
                }
            case 'descNominaOrg':
                if( TEXT_NOM_ORG.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Descripción no válida' };
                }
            case 'promedio':
                if( PROMEDIO_CALIFICACION.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Promedio no válido' };
                }
            case 'nss':
                if( NSS.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'NSS no válido' };
                }
            case 'cedulaProfesional':
                if( CED_PROF.test( input ) ){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cédula no válida' };
                }
            case 'agnio':
                if(AGNIO.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Año no válido' };
                }
            case 'monto':
                if(MONTO.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Monto no válido' };
                }
            case 'cantidad':
                if(CANTIDAD_16.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'importe':
                if(IMPORTE_DECIMAL.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Importe no válido' };
                }
            case 'porcentaje':
                if(PORCENTAJE_DECIMAL.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Porcentaje no válido' };
                }
            case 'porcentaje3':
                if(PORCENTAJE_3_DECIMALES.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Porcentaje no válido' };
                }
            case 'cbeInterbancaria':
                if(CLABE.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Porcentaje no válido' };
                }
             case 'numCuenta':
                if(CUENTA.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Porcentaje no válido' };
                }
            case 'date':
                if(DATE_SAFARI.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Fecha no válida' };
                }
            case 'prioridad':
                if(PRIORIDAD.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Prioridad no válida' };
                }
            /* -- Casos IMSS */
            case 'cuotasIMSS':
                if(ENTERO_5_DECIMAL_2.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'factorSueldoIMSS':
                if(ENTERO_1_DECIMAL_6.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Cantidad no válida' };
                }
            case 'diasAgino':
                if(DIAS_AGNIO.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Días no válidos' };
                }
            case 'comentarios':
                if(TEXTO_1_1024.test(input)){
                    return { status: true, mensaje: '' };
                }else{
                    return { status: false, mensaje: 'Comentario no válido' };
                }
            /* -- Caso default */
            default:
                return { status: true, mensaje: '' };
                //return { status: false, mensaje: 'No encontrado' };
        }
    }else{
        return { status: false, mensaje: 'Complete el campo requerido' };
    }
}

export function validarSubmit( idForm ){
    const formulario = document.getElementById( idForm );
    const divs = formulario.getElementsByClassName( 'validar' );
    Array.from( divs ).map( div => {

        //VALIDACIÓN TIPO DATE PARA SAFARI
        if( div.childNodes[0].tagName === 'DIV' ){
            if(div.childNodes[0].children[0].tagName==='INPUT'){  
                const obj = comprobar( div.childNodes[0].children[0].value, 'date' ); 
                if( div.childNodes[0].children[0].value === '' || obj.status === false ){ 
                    div.className = 'MuiFormControl-root MuiTextField-root form-control validar is-invalid';
                    div.replaceChild( document.createElement('div'), div.lastChild );
                    div.lastChild.innerHTML = `Seleccione una fecha válida`;
                    div.lastChild.className = 'invalid-feedback';
                }else{
                    div.className = 'MuiFormControl-root MuiTextField-root form-control validar is-valid';
                }
                return '';
            }

        }

        if( div.childNodes[1].tagName === 'INPUT' ){
            if( div.childNodes[1].type === 'date' ){
                if( div.childNodes[1].value === '' ){
                    div.childNodes[1].className = 'form-control is-invalid';
                    div.replaceChild( document.createElement('div'), div.childNodes[2] );
                    div.childNodes[2].innerHTML = `Seleccione una fecha válida`;
                    div.childNodes[2].className = 'invalid-feedback';
                }else{
                    div.childNodes[1].className = 'form-control is-valid';
                }
                return '';
            }else{
                const obj = comprobar( div.childNodes[1].value, div.childNodes[1].attributes.type.value );
                if( obj.status === false ){
                    div.childNodes[1].className = 'form-control is-invalid';
                    div.replaceChild( document.createElement('div'), div.childNodes[2] );
                    div.childNodes[2].innerHTML = `${obj.mensaje}`;
                    div.childNodes[2].className = 'invalid-feedback';
                }else{
                    div.childNodes[1].className = 'form-control is-valid';
                }
                return '';
            }
        }
        /* valida claves de módulo de proyectos */
        if( div.childNodes[1].tagName === 'DIV' ){
            if( div.childNodes[1].className === "passIcon" ){ /* Valida si el div pertenece al componente PasswordValidation */
                const obj = comprobar( div.childNodes[1].childNodes[1].value, div.childNodes[1].childNodes[1].attributes.type.value );
                if( obj.status === false ){
                    div.childNodes[1].childNodes[1].className = 'form-control is-invalid';
                    //div.replaceChild( document.createElement('div'), div.childNodes[1].childNodes[2] );
                    div.childNodes[1].childNodes[2].innerHTML = `${obj.mensaje}`;
                    div.childNodes[1].childNodes[2].className = 'invalid-feedback';
                }else{
                    div.childNodes[1].childNodes[1].className = 'form-control is-valid';
                }
                return '';
            }else{
                const obj = comprobar( div.childNodes[0].value, div.childNodes[0].attributes.type.value );
                if( obj.status === false ){
                    div.childNodes[0].className = 'form-control is-invalid';
                    div.replaceChild( document.createElement('div'), div.childNodes[1] );
                    div.childNodes[1].innerHTML = `${obj.mensaje}`;
                    div.childNodes[1].className = 'invalid-feedback';
                }else{
                    div.childNodes[0].className = 'form-control is-valid';
                }
                return '';
            }
        }
        /* --------------------------------- */
        if( div.childNodes[1].tagName === 'TEXTAREA' ){
            const obj = comprobar( div.childNodes[1].value, div.childNodes[1].attributes.type.value );
            if( obj.status === false ){
                div.childNodes[1].className = 'descripcion is-invalid';
                div.replaceChild( document.createElement('div'), div.childNodes[2] );
                div.childNodes[2].innerHTML = `${obj.mensaje}`;
                div.childNodes[2].className = 'invalid-feedback';
            }else{
                div.childNodes[1].className = 'descripcion is-valid';
            }
            return '';
        }

        if( div.childNodes[1].tagName === 'SELECT' ){
            if( div.childNodes[1].value === '' ){
                div.childNodes[1].className = 'form-control is-invalid skip-image';
                div.replaceChild( document.createElement('div'), div.childNodes[2] );
                div.childNodes[2].innerHTML = `Seleccione una opción`;
                div.childNodes[2].className = 'invalid-feedback';
            }else{
                div.childNodes[1].className = 'form-control is-valid skip-image';
            }
            return '';
        }
        return '';
    });
    return comprobarSubmit( idForm );
}

export function comprobarSubmit( idForm ){
    const formulario = document.getElementById( idForm );
    const inputs = formulario.getElementsByClassName( 'is-invalid' );
    if( inputs.length === 0 ){
        return true;
    }
    inputs[0].focus();
    return false;
}

/* Funcion que limpia el valor de un ref - Debe colocarse en un evento onBlur() dentro deun input */
export function trimValue( ref ){
    ref.current.value = ref.current.value.trim();
}

/* Funcion para convertir inputDate a longDate(Milisegundos) formato requerido para almacenar en Base de datos */
export function inputDateToLong( inputDate ){
    if( inputDate === null || inputDate === '' ){
        return inputDate;
    }else{
        const splitDate= !inputDate.includes('/') ? inputDate.split( '-' ) : inputDate.split( '/' ).reverse();
        return new Date( parseInt( splitDate[0] ), parseInt( splitDate[1] )-1, parseInt( splitDate[2] ) ).getTime(); //Restar -1 a posicion[1] correspondiente al mes
    }
}

/* Funcion para convertir longDate(Milisegundos) a inputDate( Formato válido para <inputs type="date"/> ) */
const meses = { Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12' };
export function longDateToInput( longDate ){
    if( longDate === null ){
        return null;
    }else{
        const dateString = new Date( longDate ).toString().split( ' ', 4 );
        return `${dateString[3]}-${meses[dateString[1]]}-${dateString[2]}`;
    }
}

/* Funcion para eliminar campos opcionales de un objeto (elementos que sean === '') */
export function deleteOptionals( objectRequest ){
    var temporal = objectRequest;
    Object.keys( temporal ).forEach( key => {
        if( temporal[key] === '' ){
            delete temporal[key];
        }
    });
    return temporal;
}

export function amountToFloat( value ) {
    return parseFloat(value.replace(/,/g,'').replace('$',''));
}

export function floatToAmount( value, decimales ) {
    return '$'+value.toLocaleString('en-US', {minimumFractionDigits: decimales});
}
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioPensiones = ({ error, enviarPension, elementSelect, actualizarPension, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const fechainiRef = createRef('');
    const fechafinRef = createRef('');
    const pensionFpoRef = createRef('');
    const pensionFppRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarPension' ) ){
            const fechaini = inputDateToLong( fechainiRef.current.value );
            const fechafin = inputDateToLong( fechafinRef.current.value );
            const pensionFpo = parseFloat(pensionFpoRef.current.value);
            const pensionFpp = parseFloat(pensionFppRef.current.value);
            //console.log( deleteOptionals({ fechaini, fechafin, pensionFpo, pensionFpp }) );
            enviarPension( deleteOptionals({ fechaini, fechafin, pensionFpo, pensionFpp }) );
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarPension' ) ){
            const fechaini = inputDateToLong( fechainiRef.current.value );
            const fechafin = inputDateToLong( fechafinRef.current.value );
            const pensionFpo = parseFloat(pensionFpoRef.current.value);
            const pensionFpp = parseFloat(pensionFppRef.current.value);
            //console.log( deleteOptionals({ id: elementSelect.id, fechaini, fechafin, pensionFpo, pensionFpp }) );
            actualizarPension( deleteOptionals({ id: elementSelect.id, fechaini, fechafin, pensionFpo, pensionFpp }) );
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarPension' : 'AgregarPension' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechainiRef} required
                            defaultValue={ elementSelect ? elementSelect.fechaini : '' } />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechafinRef} optional
                            defaultValue={ elementSelect ? elementSelect.fechafin : '' } />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="% Fondo de Pensión Obrero" type="porcentaje" placeholder="Escriba el porcentaje de Pensión Obrero"
                            tooltipText={'Máximo 3 números y dos decimales del 0.00 al 100.00'} tooltipName={'tooltipPorcPatron'} maxLength='6'
                            refValue={pensionFpoRef} defaultValue={ elementSelect ? elementSelect.pensionFpo : '' } required
                            onlyNumbers="." onBlurDecimal={2}/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="% Fondo de Pensión Patronal" type="porcentaje" placeholder="Escriba el porcentaje de Pensión Patronal"
                            tooltipText={'Máximo 3 números y dos decimales del 0.00 al 100.00'} tooltipName={'tooltipPorcObrera'} maxLength='6'
                            refValue={pensionFppRef} defaultValue={ elementSelect ? elementSelect.pensionFpp : '' } required
                            onlyNumbers="." onBlurDecimal={2}/>
                    </div>
                </div>

                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioPensiones;
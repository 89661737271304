import React, { createRef } from 'react';
import {Button } from '@material-ui/core';
import { validarSubmit, longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation'; 

const FormPeriodoNomina = ({ error, setError, enviarPeriodoNomina, elementSelect }) => {
    
    const mesInicialRef = createRef('');
    const mesFinalRef = createRef('');
    const agnioInicialRef = createRef('');
    const agnioFinalRef = createRef('');
    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarPeriodoNomina')) {
            const mesInicial = parseInt(mesInicialRef.current.value);
            const mesFinal = parseInt(mesFinalRef.current.value);
            const anioInicial = parseInt(agnioInicialRef.current.value);
            const anioFinal = parseInt(agnioFinalRef.current.value);   
            enviarPeriodoNomina( { mesInicial, mesFinal, anioInicial, anioFinal } );
        }
    }; 

    const meses=[{id:1, nombre:'Enero'},
    {id:2, nombre:'Febrero'},
    {id:3, nombre:'Marzo'},
    {id:4, nombre:'Abril'},
    {id:5, nombre:'Mayo'},
    {id:6, nombre:'Junio'},
    {id:7, nombre:'Julio'},
    {id:8, nombre:'Agosto'},
    {id:9, nombre:'Septiembre'},
    {id:10, nombre:'Octubre'},
    {id:11, nombre:'Noviembre'},
    {id:12, nombre:'Diciembre'}
    ];

    const fechaFin=elementSelect ? longDateToInput(elementSelect.fechaFin).split('-'):''; 

    return (  
        <form id={ 'AgregarPeriodoNomina' } onSubmit={ enviar }> 
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <SelectValidation title="Mes Inicial" arrayOpciones={meses} keyOpcion="nombre" refValue={mesInicialRef} required
                        defaultValue={!isNaN(parseInt(fechaFin[1])) ? parseInt(fechaFin[1]) :''} disabled={!isNaN(parseInt(fechaFin[0])) } />
                </div>
                <div className="col-md-6 col-sm-12">
                    <SelectValidation title="Mes Final" arrayOpciones={meses} keyOpcion="nombre" refValue={mesFinalRef} required/>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6 col-sm-12"> 
                    <InputValidation title="Año Inicial" type="agnio" placeholder="Escriba el año inicial"
                        tooltipText={'4 caracteres numéricos'} tooltipName={'tooltipIniAgn'} maxLength='4'
                        refValue={agnioInicialRef} required defaultValue={!isNaN(parseInt(fechaFin[0]))  ? parseInt(fechaFin[0]) :''} 
                        disabled={!isNaN(parseInt(fechaFin[0])) }/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Año Final" type="agnio" placeholder="Escriba el año final"
                        tooltipText={'4 caracteres numéricos'} tooltipName={'tooltipFinAgn'} maxLength='4'
                        refValue={agnioFinalRef} required/>   
                </div>
            </div> 
            {error} 
            <Button variant="contained" className="btn-color" type="submit">{'Generar Periodos' }</Button>
            
        </form>   
    );
}
export default FormPeriodoNomina;

import React, { createRef } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, deleteOptionals } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';

const FormularioDependencia = ({ error, enviarDependencia, elementSelect, actualizarDependencia, informacion }) => {
    const rfcRef = createRef('');
    const nombreCortoRef = createRef('');
    const razonSocialRef = createRef('');
    const unidadResponsableRef = createRef('');
    const giroIdRef = createRef('');
    const tipoDependenciaIdRef = createRef('');
    const fechaConstitucionRef = createRef('');
    const ciaWebRef = createRef('');

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarDependencia' ) ){
            const rfc = rfcRef.current.value;
            const nombreCorto = nombreCortoRef.current.value;
            const razonSocial = razonSocialRef.current.value;
            const unidadResponsable = unidadResponsableRef.current.value;
            const giroId = giroIdRef.current.value;
            const tipoDependenciaId = tipoDependenciaIdRef.current.value;
            const fechaDeConstitucion = inputDateToLong( fechaConstitucionRef.current.value );
            const ciaWeb = ciaWebRef.current.value;
            //console.log({ rfc, nombreCorto, razonSocial, unidadResponsable, giroId, tipoDependenciaId, fechaDeConstitucion, ciaWeb });
            enviarDependencia( deleteOptionals({ rfc, nombreCorto, razonSocial, unidadResponsable, giroId, tipoDependenciaId, fechaDeConstitucion, ciaWeb }) );
        }
    }

    const actualizar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'EditarDependencia' ) ){
            const rfc = rfcRef.current.value;
            const nombreCorto = nombreCortoRef.current.value;
            const razonSocial = razonSocialRef.current.value;
            const unidadResponsable = unidadResponsableRef.current.value;
            const giroId = giroIdRef.current.value;
            const tipoDependenciaId = tipoDependenciaIdRef.current.value;
            const fechaDeConstitucion = inputDateToLong( fechaConstitucionRef.current.value );
            const ciaWeb = ciaWebRef.current.value;
            //console.log({ rfc, nombreCorto, razonSocial, unidadResponsable, giroId, tipoDependenciaId, fechaDeConstitucion, ciaWeb });
            actualizarDependencia( deleteOptionals({ rfc, nombreCorto, razonSocial, unidadResponsable, giroId, tipoDependenciaId, fechaDeConstitucion, ciaWeb }), elementSelect.id);
        }
    }

    return (
        <form id={ elementSelect ? 'EditarDependencia' : 'AgregarDependencia' } onSubmit={ elementSelect ? actualizar : enviar }>
            { elementSelect && <h4>Dependencia</h4> }
            <InputValidation title="Registro Federal de Contribuyentes (RFC)" type="rfc" placeholder="Escriba el RFC de la dependencia"
                tooltipName={'tooltipRfcDep'} tooltipText={`12/13 letras y números en formato de RFC`} maxLength='13' alfanumeric
                refValue={rfcRef} defaultValue={ elementSelect ? elementSelect.ciaRfc : '' } required/>

            <InputValidation title="Razón Social" type="razonSocial" placeholder="Escriba la razón social de la dependencia" maxLength='254'
                tooltipText={'Máximo 254 letras, números, espacios y/o caracteres especiales'} tooltipName={'tooltipRazSocDep'}
                refValue={razonSocialRef} defaultValue={ elementSelect ? elementSelect.ciaRazonSocial : '' } required/>

            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Unidad Responsable" type="claveUnidadResponsable" placeholder="Escriba las iniciales de la Unidad Responsable"
                        tooltipText={'Iniciales de la Unidad Responsable, máximo 5 letras y/o números'} tooltipName={'tooltipURDep'} maxLength='5' alfanumeric
                        refValue={unidadResponsableRef} defaultValue={ elementSelect ? elementSelect.ciaUr : '' } required/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputValidation title="Nombre Corto" type="nombreCorto" placeholder="Escriba el nombre corto de la dependencia" maxLength='30'
                        tooltipText={'Máximo 30 letras, números, espacios y/o caracteres especiales'} tooltipName={'tooltipNomDep'}
                        refValue={nombreCortoRef} defaultValue={ elementSelect ? elementSelect.ciaNomCorto : '' } required/>
                </div>
            </div>

            <SelectValidation title="Giro Comercial" arrayOpciones={informacion.giros} keyOpcion="giroNom"
                refValue={giroIdRef} defaultValue={ elementSelect ? elementSelect.giroId : '' } required/>

            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <SelectValidation title="Tipo de Dependencia" arrayOpciones={informacion.tiposDependencia} keyOpcion="nombre"
                        refValue={tipoDependenciaIdRef} defaultValue={ elementSelect ? elementSelect.tipoDependId : '' } required/>
                </div>
                <div className="col-md-6 col-sm-12">
                    <InputDateValidation title="Fecha de Constitución" refValue={fechaConstitucionRef}
                        defaultValue={ elementSelect ? elementSelect.ciaFecConst : '' }
                        optional />
                </div>
            </div>

            <InputValidation title="Sitio Web" type="sitioWeb" placeholder="Escriba el sitio web de la dependencia" maxLength='30'
                tooltipText={'Máximo 30 letras, números, espacios y/o caracteres especiales (Con la estructura de un sitio web)'} tooltipName={'tooltipSWebDep'}
                refValue={ciaWebRef} defaultValue={ elementSelect ? elementSelect.ciaWeb : '' }
                optional={true} />

            {error}
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
        </form>
    );
};

export default FormularioDependencia;
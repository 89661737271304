import React from 'react';
import axios from 'axios';
import { servicios_grupogack, registro_sera_eliminado } from '../../../../../Constants/Constants';
import { headersAuth } from '../../../../../Services/AuthService';
import Alertwarning from '../../../../../Services/Alerts/AlertWarning';

const ModalDeleteAcceso = ({ openDelete, setOpenDelete, elementSelect, eliminar }) => {

    const axiosEliminar = () => {
        axios.delete( `${servicios_grupogack}/dashboard/${elementSelect.id}`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        eliminar( elementSelect.id );
                        break;
                    case 400:
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        //errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_eliminado );
            })
    }

    return (
        <Alertwarning show={openDelete} SetopenALert={setOpenDelete} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosEliminar} />
    );
};

export default ModalDeleteAcceso;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack } from '../../Constants/Constants';

/* --- Componentes */
import LoginFormulario from './LoginFormulario';
import RecuperarPassFormulario from '../Recuperar/RecuperarPassFormulario';
import history from '../../Services/History';
import Footer from '../Footer/Footer';
/* Estilos */
import './styles.css';

const Login = () => {
    const [ logotipo, setLogotipo ] = useState( null );
    useEffect(() => {
        axiosLogotipo();
    }, []);

    /* Axios para obtener logotipo en base64 */
    const axiosLogotipo = () => {/*
        axios.get( `${servicios_grupogack}/logo/free/` )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setLogotipo( respuesta.data.dataResponse );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })*/
    }

    /* typeform -> MANEJO DE FORMULARIOS 
        1 = LOGIN
        2 = OLVIDE CONTRASEÑA
    */
    const [typeForm, setTypeForm] = useState(1)

    const handleChangeFormClick = value => {
        setTypeForm(value);
    };

    if(localStorage.getItem("IGSESSID")) {
        history.push( "/usuario" );
    }

    return (
        <div className = "background">
            <div className="container-fluid">
                <div className="row">
                    <div className="loginform">
                        { typeForm === 1 && <LoginFormulario onChangeFormClick ={(typeForm) => handleChangeFormClick(typeForm) } logotipo={logotipo}/> }
                        { typeForm === 2 && <RecuperarPassFormulario onChangeFormClick ={(typeForm) => handleChangeFormClick(typeForm)} logotipo={logotipo}/> }
                    </div>
                </div>
            </div>
            <Footer className="footer-login" />
        </div>
    );
}
export default Login;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_rh, servicios_grupogack_nomina, prestamos_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const Prestamos = ({ id }) => {
    const [ empleados, setEmpleados ] = useState( null );
    const [ nominas, setNominas ] = useState( null );
    useEffect(() => {
        axiosEmpleados();
        axiosNomninas();
    }, []);

    const axiosEmpleados = () => {
        axios.get( `${servicios_grupogack_rh}/empleado/activos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEmpleados( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setEmpleados( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener listado de Nóminas */
    const axiosNomninas = () => {
        axios.get( `${servicios_grupogack_nomina}/config/nomina`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setNominas( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setNominas([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={ id }>
            { empleados ?
                <DataTableService id={id} title={ prestamos_ }
                    columns={[
                        { field: "empleadoClave", title: "Núm. Empleado" },
                        { field: "empleadoNom", title: "Nombre" },
                        { field: "empleadoPat", title: "Primer Apellido" },
                        { field: "empleadoMat", title: "Segundo Apellido" },
                        { field: "empleadoRfc", title: "RFC" },
                        { field: "puestoNom", title: "Plaza / Puesto" },
                        { field: "nominaNombre", title: "Nómina" },
                        { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                    ]}
                    hideIconDelete hideIconEdit
                    showIconApps
                    data={ empleados }
                    informacionAdicional={{ empleados, nominas }} />
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default Prestamos;
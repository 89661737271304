import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, salario_minimo_uma } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonSalarioMinUMA } from '../../../Helpers/JsonToOneLevel';
import minimoumajson from '../../../../../Datos/minimoumajson.json';
const SalarioMinimoUMA = ({ id, permisos }) => {

    const [salarioMinimos, setSalarioMinimos] = useState(null);
    
    useEffect(() => {
        setSalarioMinimos(minimoumajson);
        /*
        Axios.get(`${servicios_grupogack_catalogo}s/salario/minimouma/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse));
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));*/
    },[]);

    return (
        <div id={ id }>
            {   
                salarioMinimos
                ?   <DataTableService id={id} title={ salario_minimo_uma }
                        permisos={permisos} 
                        columns={[
                            { field: "zonaNom", title: "Zona" },
                            { field: "monto", title: "Monto (MXN)", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}},
                            { field: "fechaIni", title: "Fecha Inicial" },
                            { field: "fechaFin", title: "Fecha Final" }
                        ]}
                        data={salarioMinimos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default SalarioMinimoUMA;

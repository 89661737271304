import React, { useState, useEffect } from 'react';

import Axios from 'axios';
import { servicios_grupogack_catalogo, turnos_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import DataTableService from '../../../DataTableService/DataTableService'; 
import { ArrayJsonTurnos } from '../../../Helpers/JsonToOneLevel';

const Turnos = ({ id, permisos  }) => { 
    const [turnos, setTurnos]= useState(null); 

    useEffect(()=>{
        Axios.get(`${servicios_grupogack_catalogo}/turno/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    const turno = ArrayJsonTurnos(res.data.dataResponse);                  
                    setTurnos(turno); 
                    break;
                case 404:
                    setTurnos([]);  
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error =>  alert(error));
    },[]);
    
   return (
       <div id={ id }> 
                {
                    turnos
                    ?
                    <DataTableService 
                        id={id}
                        title={ turnos_ }
                        permisos={permisos}
                        columns={[ 
                            { field: "turnoNom", title: "Nombre del Turno" },
                            { field: "jornadaNombre", title: "Jornada Laboral" },
                        ]}
                        data={turnos}
                        />
                    :<SpinnerOval/>
                }
       </div>

   );
}
 
export default Turnos;





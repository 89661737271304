import React, { useState, useEffect } from 'react';

import AlertForm from '../../../../../../Services/AlertForm';
import Radio from '@material-ui/core/Radio';
import Button from '@material-ui/core/Button';

/* ------------------------- Elementos necesarios para la creacion de MuiTheme -------------------------  */
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';
const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#1572E8' },
    },
});
/* -----------------------------------------------------------------------------------------------------  */

const ParametrosNominasOrg = ({ error, atras, elementSelect, action }) => {
    const [ errorParametros, setErrorParametros ] = useState( null );
    const [ paramQuinquenio, setParamQuinquenio ] = useState( null );
    const [ paramAjusteImpto, setParamAjusteImpto ] = useState( null );
    const [ paramPagSubUltPer, setParamPagSubUltPer ] = useState( null );
    const [ paramTimbrado, setParamTimbrado ] = useState( null );
    const [ paramRedondeo, setParamRedondeo ] = useState( null );

    useEffect(() => {
        if( elementSelect ){
            setParamQuinquenio( elementSelect.paramNom.paramQuinquenio );
            setParamAjusteImpto( elementSelect.paramNom.paramAjusteImpto );
            setParamPagSubUltPer( elementSelect.paramNom.paramPagSubUltPer );
            setParamTimbrado( elementSelect.paramNom.paramTimbrado );
            setParamRedondeo( elementSelect.paramNom.paramRedondeo );
        }
    // eslint-disable-next-line
    }, []);

    const comprobar = () => {
        if( paramQuinquenio === null || paramAjusteImpto === null || paramPagSubUltPer === null || paramTimbrado === null || paramRedondeo === null ){
            setErrorParametros( <AlertForm message="Seleccione una opción para cada elemento" /> );
        }else{
            setErrorParametros( null );
            action({ paramQuinquenio, paramAjusteImpto, paramPagSubUltPer, paramTimbrado, paramRedondeo });
        }
    }

    return (
        <MuiThemeProvider theme={theme}>
            <div className="parametrosFlex">
                <div className="parametrosIzquierdo">
                    <p className="parametrosText"> Parámetros de Nómina </p>
                    <p> ¿Se Paga Quinquenio? </p>
                    <p> ¿Se Realiza Ajuste de Impuesto? </p>
                    <p> ¿Se Paga Subsidio al Empleo? </p>
                    <p> ¿Se Timbra la Nómina? </p>
                    <p> ¿Se Realiza Redondeo? </p>
                </div>

                <div className="parametrosDerecho">
                    <p className="parametrosText"> <span> Sí </span> <span> No </span> </p>
                    <div>
                        <Radio className="radioParametros" checked={paramQuinquenio === true} onChange={ () => setParamQuinquenio(true) } />
                        <Radio className="radioParametros" checked={paramQuinquenio === false} onChange={ () => setParamQuinquenio(false) } />
                    </div>
                    <div>
                        <Radio className="radioParametros" checked={paramAjusteImpto === true} onChange={ () => setParamAjusteImpto(true) } />
                        <Radio className="radioParametros" checked={paramAjusteImpto === false} onChange={ () => setParamAjusteImpto(false) } />
                    </div>
                    <div>
                        <Radio className="radioParametros" checked={paramPagSubUltPer === true} onChange={ () => setParamPagSubUltPer(true) } />
                        <Radio className="radioParametros" checked={paramPagSubUltPer === false} onChange={ () => setParamPagSubUltPer(false) } />
                    </div>
                    <div>
                        <Radio className="radioParametros" checked={paramTimbrado === true} onChange={ () => setParamTimbrado(true) } />
                        <Radio className="radioParametros" checked={paramTimbrado === false} onChange={ () => setParamTimbrado(false) } />
                    </div>
                    <div>
                        <Radio className="radioParametros" checked={paramRedondeo === true} onChange={ () => setParamRedondeo(true) } />
                        <Radio className="radioParametros" checked={paramRedondeo === false} onChange={ () => setParamRedondeo(false) } />
                    </div>
                </div>
            </div>

            {errorParametros}
            {error}
            <div className="row">
                <div className="col-md-6 col-sm-12">
                    <Button variant="outlined" className="btn-outline" onClick={ () => atras() }> Atrás </Button>
                </div>
                <div className="col-md-6 col-sm-12">
                    <Button variant="contained" className="btn-color" onClick={ () => comprobar() }>{ elementSelect ? 'Actualizar' : 'Guardar' }</Button>
                </div>
            </div>
        </MuiThemeProvider>
    );
};

export default ParametrosNominasOrg;
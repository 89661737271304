const ConvertInfoEmp = data => {
    const newJson = {
        empleado: {
            id: data.id,
            empleadonom: data.empleadoNom,
            empleadoclave: data.empleadoClave,
            empleadopat: data.empleadoPat,
            empleadomat: data.empleadoMat,
            nombreCompleto: data.nombreCompleto,
            empleadofecnac: data.empleadoFecnac,
            empleadocurp: data.empleadoCurp,
            empleadorfc: data.empleadoRfc,
            empleadonss: data.empleadoNss,
            empleadoGenero: '',
            edoCivil: {
                id: '',
                edoCivilnom: ''
            },
            estado: {
                id: '',
                estadoNom: '',
                paisId: {
                    paisNom: '',
                    id: ''
                }
            }
        },
        antiguedad: {
            empantfecini: data.empantFecini,
            empantfecfin: '',
            id: data.empantId,
        },
        turno: {
            fecini: data.empturFecini,
            fecfin: data.empturFecfin,
            id: data.turnoId
        },
        nomina: {
            nomina: {
                nominaClave: data.nominaClave,
                nominaNombre: data.nominaNombre,
                id: data.nominaId
            }
        },
        plaza: {
            plazaid: {
                plazaclave: data.plazaClave,
                id: data.plazaId
            }
        },
        presupuestal: {
            presupuestal: {
                cpresupuestalnom: data.cpresupuestalNom,
                cpresupuestalclave: data.cpresupuestalClave,
                id: data.cpresupuestalId
            }
        },
        puesto: {
            puesto: {
                puestoCve: data.puestoCve,
                id: data.puestoId
            }
        },
        zonadepago: {
            domcia: {
                id: data.domciaId
            }
        },
        sueldo: {
            monto: '',
        }
    };

    return newJson
};

export default ConvertInfoEmp;
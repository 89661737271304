import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, tablas_impuestos } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonTablasImpuestos, ArrayJsonTablasImpuestosAdd } from '../../../Helpers/JsonToOneLevel'; 
import DinamicTableTablasImpuestos from './DinamicTableTablasImpuestos';
import TablaImpJson from '../../../../../Datos/tablaImp.json';


const TablasImpuestos = ({ id, permisos }) => {

    const [tablasImpuestos, setTablasImpuestos] = useState(null); 
    const [dinamicTable, setDinamicTable] =useState(''); 
    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ onlyRead, setOnlyRead ] = useState(true);

    useEffect(() => {
        setTablasImpuestos(ArrayJsonTablasImpuestos(TablaImpJson));
        /*
        Axios.get(`${servicios_grupogack_catalogo}s/impuesto/rango/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTablasImpuestos(ArrayJsonTablasImpuestos(res.data.dataResponse ));
                    break;
                case 404:
                    setTablasImpuestos([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));*/
    },[]);

    const showTable = async ( value, elemmentSelect={}, isNew=null, editElement=null ) => { 
        
        await setOnlyRead( !elemmentSelect.status );

        if(isNew){
            let newData=[elemmentSelect, ...tablasImpuestos];
            
            //setTablasImpuestos( tablasImpuestos.map( element => element.periodoImptoId === elemmentSelect.periodoImptoId && element.status ? element.status = false : element ) );
            setTablasImpuestos(ArrayJsonTablasImpuestosAdd(newData));
        } 
        if(editElement!==null){ 
            setTablasImpuestos( tablasImpuestos.map( element => element.id === editElement.id ? element = editElement : element ) ); 
        }
        
        await setElemmentSelect(elemmentSelect);
        setDinamicTable(value); 
          
        return dinamicTable;
    }

    return (
        <div id={ id }>
            {   
                dinamicTable
                ?
                    <DinamicTableTablasImpuestos idTab={elemmentSelect}  onShowTable={showTable} onlyRead={onlyRead}  idModulo={ id }/>
                :
                    <div>
                    {
                        tablasImpuestos
                        ?   <DataTableService id={id} title={ tablas_impuestos }
                                permisos={permisos} 
                                columns={[
                                    { field: "periodoImpto", title: "Tipo" },
                                    { field: "fechaIni", title: "Fecha Inicial" },
                                    { field: "fechaFin", title: "Fecha Final" }
                                ]}
                                data={tablasImpuestos}
                                onShowTable={showTable}
                            />
                        :   <SpinnerOval />
                    }
                    </div>
            }
        </div>
    );
}
 
export default TablasImpuestos;

import React, { useState } from 'react';
import FormFaltante from './FormFaltante'; 
import ModalService from '../../../ModalService/ModalService';

const ModalAddFaltante = ({ openAdd, setOpenAdd, info, agregar, errorTable, setOpenAddFirst, idModulo }) => {
    
    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenAdd(false);
        setError(null);
    }
    return (
        <ModalService title={((info.uResponsable) ? info.uResponsable.nombre : null)} parentNode={idModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormFaltante error={error} info={info} setOpenAdd={setOpenAdd} agregar={agregar} errorTable={errorTable} setOpenAddFirst={setOpenAddFirst}/>
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddFaltante;
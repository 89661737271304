import React from 'react';
import axios from 'axios';
import { servicios_grupogack_url, registro_sera_inactivado, registro_no_inactivado, registro_sera_activado, registro_no_activado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning';

const ModalDeleteUsuarios = ({ alertWarning, setAlertWarning, elementSelect, editar, errorTable }) => {

    /* Axios para inactivación de una cuenta de usuario subordinado */
    const axiosInactivar = () => {
        axios.put( `${servicios_grupogack_url}/inactive/account/${elementSelect.id}`, {}, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        elementSelect['status'] = { id: 2, statusNom: 'Inactivo' };
                        editar( elementSelect );
                        break;
                    case 403:
                        errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( registro_no_inactivado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_inactivado );
            })
    }

    /* Axios para activación de una cuenta de usuario subordinado */
    const axiosActivar = () => {
        axios.put( `${servicios_grupogack_url}/active/account/${elementSelect.id}`, {}, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        elementSelect['status'] = { id: 1, statusNom: 'Activo' };
                        editar( elementSelect );
                        break;
                    case 403:
                        errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( registro_no_activado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_activado );
            })
    }

    return (
        <React.Fragment>
        { Object.keys(elementSelect).length !== 0 && elementSelect.status.statusNom === "Activo" ?
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_inactivado}
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosInactivar} />
            :
            <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_activado}
                textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosActivar} />
        }
        </React.Fragment>
    );
};

export default ModalDeleteUsuarios;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, deleteOptionals } from '../../../../../../../Services/Validation/HelperValidation';
//import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import AlertForm from '../../../../../../../Services/AlertForm';
import IndicadorRelacionadoEdit from './IndicadorRelacionado';
import IndicadorRelacionadoAdd from './IndicadorRelacionadoAdd';

const FormularioRelacionados = ({ error, enviarRelacionado, elementSelect, actualizarRelacionado, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ nominaIndicador, setNominaIndicador ] = useState( null );
    const [ errorIndicador, setErrorIndicador ] = useState( null );
    const [ indicadoresAdd, setIndicadoresAdd ] = useState( [] );
    const acumuladoRef = createRef('');
    const fechaInicioRef = createRef('');
    const fechaFinalRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarRelacionado' ) ){
            if( nominaIndicador ){
                const nomina = nominaIndicador.id;
                const acumulado = parseInt( acumuladoRef.current.value );
                const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
                const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
                //console.log([ deleteOptionals({ empleado: informacion.empleado.id, nomina, acumulado, fechaInicio, fechaFinal }) ]);
                enviarRelacionado([ deleteOptionals({ empleado: informacion.empleado.id, nomina, acumulado, fechaInicio, fechaFinal }) ], indicadoresAdd);
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarRelacionado' ) ){
            const nomina = elementSelect.nomina.id;
            const acumulado = parseInt( acumuladoRef.current.value );
            const fechaInicio = inputDateToLong( fechaInicioRef.current.value );
            const fechaFinal = inputDateToLong( fechaFinalRef.current.value );
            //console.log([ deleteOptionals({ id: elementSelect.id, empleado: informacion.empleado.id, nomina, acumulado, fechaInicio, fechaFinal }) ]);
            actualizarRelacionado([ deleteOptionals({ id: elementSelect.id, empleado: informacion.empleado.id, nomina, acumulado, fechaInicio, fechaFinal }) ]);
        }
    };

    const validarIndicador = (valor) => {
        if(valor) {
            //console.log( valor );
            setErrorIndicador( null );
            setNominaIndicador( valor );
        }else{
            setErrorIndicador( true );
            setNominaIndicador( null );
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarRelacionado' : 'AgregarRelacionado' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                { elementSelect ?
                    <div> <b> Concepto de Nómina: </b> {elementSelect.nomina.nomIndicador} </div>
                    :
                    <AutocompleteComponent id="indicadorRelacionado" title="Concepto de Nómina" placeholder="Seleccione el Concepto de Nómina" size="100%"
                        tooltipName="tooltipIndicadorRelacionado" tooltipText={'Busque el Concepto, por nombre o clave, en el campo de autocompletado'} action={validarIndicador}
                        options={informacion.indicadoresNomina} optionListView={'nomIndicador'} optionKey="nomClave" error={errorIndicador} required/>
                }

                <SelectValidation title="Tipo de Acumulado" arrayOpciones={informacion.tiposAcumulado} keyOpcion="acumuladoNombre"
                    refValue={acumuladoRef} defaultValue={ elementSelect ? elementSelect.acumulado.id : '' } required/>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Inicial" refValue={fechaInicioRef} required
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaInicio) : '' } />
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha Final" refValue={fechaFinalRef} optional
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaFinal) : '' } />
                    </div>
                </div>
                {error}
                {errorIndicador}
                { elementSelect ?
                    <IndicadorRelacionadoEdit elementSelect={elementSelect} informacion={informacion}/>
                    : <IndicadorRelacionadoAdd informacion={informacion} indicadoresAdd={indicadoresAdd} setIndicadoresAdd={setIndicadoresAdd}/>
                }

                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioRelacionados;
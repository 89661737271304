import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormPlantillas = ({ error, setError, enviarDatosPlantilla, elementSelect, actualizarDatosPlantilla }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const claveRef = createRef('');
    const nombreRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('DatosPlantilla')) {
            const plantillaClave = claveRef.current.value;
            const plantillaNom = nombreRef.current.value
            const plantillaDesc = descripcionRef.current.value;
            const plantillaHtml = '';
            enviarDatosPlantilla({ plantillaClave, plantillaNom, plantillaDesc, plantillaHtml });
        }
    };

    const actualizar = () => {
        if(validarSubmit('DatosPlantilla')) {
            const plantillaClave = claveRef.current.value;
            const plantillaNom = nombreRef.current.value
            const plantillaDesc = descripcionRef.current.value;
            const plantillaHtml = elementSelect.plantillaHtml;
            setError(null);
            actualizarDatosPlantilla({ plantillaClave, plantillaNom, plantillaDesc, plantillaHtml }, elementSelect.id);
        }
    };

    return ( 
        <Fragment>
            <form id="DatosPlantilla" onSubmit={elementSelect ? mostrarAlert : enviar}>
                <InputValidation title="Clave" required type="claveBanco" placeholder="Escriba la clave de la plantilla"
                    refValue={claveRef} defaultValue={elementSelect ? elementSelect.plantillaClave : ''} 
                    tooltipText="Máximo 3 letras y/o números" tooltipName="tooltipCveBan" maxLength={3} alfanumeric/>
                <InputValidation title="Nombre" type="nombreBanco" placeholder="Escriba el nombre de la plantilla"
                    refValue={nombreRef} defaultValue={elementSelect ? elementSelect.plantillaNom : ''} 
                    tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                    tooltipName="tooltipNomBan" maxLength={128} required/>
                <InputValidation title="Descripción" type="descripcionBanco" placeholder="Escriba la descripción de la plantilla"
                    refValue={descripcionRef} defaultValue={elementSelect ? elementSelect.plantillaDesc : ''}
                    tooltipText={'Máximo 256 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                    tooltipName="tooltipDesBan" maxLength={256} fieldType="textarea" optional={true}/>
                { error }
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment> 
    );
}
export default FormPlantillas;
import React, { useState, createRef, Fragment, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { validarSubmit, inputDateToLong } from '../../../../../../Services/Validation/HelperValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import AlertForm from '../../../../../../Services/AlertForm';
import Tooltip from '@material-ui/core/Tooltip';
import AcumuladosSelecionados from './AcumuladosSeleccionados';
import ModalLoading from '../../../../../../Services/ModalLoading/ModalLoading';

const FormCalculo = ({ nombreModulo, elementSelect, tiposAcumulado, periodo, error, setError, errorTable, successTable, permisos, setOpenEdit }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const [openAlertWarning, setOpenAlertWarning] = useState(false);
    const [acumuladosSelected, setAcumuladosSelected] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [openAlertCerrar, setOpenAlertCerrar] = useState(false);
    const [openAlertEliminar, setOpenAlertEliminar] = useState(false);
    const [value, setValue] = useState(null);
    const [openLoading, setOpenLoading] = useState(false);

    const tipoAcumuladoRef = createRef('');
    const fechaPagoRef = createRef('');

    useEffect(() => {
        if(periodo) {
            axios.get(`${servicios_grupogack_nomina}/procesos/${periodo.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const acumulados = res.data.dataResponse.map(item => (
                            {
                                tipoAcumuladoId: item.acumulado.id,
                                tipoAcumuladoNombre: item.acumulado.acumuladoNombre,
                                fechaPago: item.fechaPago,
                                cerrado: (item.status.id === 1) ? false : true
                            }
                        ));
                        setAcumuladosSelected(acumulados);
                        break;
                    case 404:
                        setAcumuladosSelected([]);
                        break;
                    default:
                        errorTable("Error en el servicio. Vuelve a intentarlo");
                        break;
                }
            })
            .catch(error => errorTable("Error en el servicio. Vuelve a intentarlo"));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [periodo])

    const mostrarAlert = () => {
        setOpenAlert(true);
    };

    const mostrarAlertWarning = () => {
        setOpenAlertWarning(true);
    };

    const mostrarLoading = () => {
        setOpenLoading(true);
    };

    const ocultarLoading = () => {
        setOpenLoading(false);
    };

    const verificaAcumuladosCerrados = e => {
        e.preventDefault();
        const buscaAbiertos = acumuladosSelected.find(acumS => acumS.cerrado === false);
        
        if(buscaAbiertos) {
            mostrarAlert();
        } else {
            mostrarAlertWarning();
        }
    };

    const enviar = () => {
        if(permisos.find(permiso => permiso.id === 2)) {
            mostrarLoading();
            if(acumuladosSelected.length > 0) {
                ocultarLoading();
                setError(null);
                successTable('Se ha actualizado el periodo');
                setOpenEdit();
            } else {
                ocultarLoading();
                setError(<AlertForm message="No tienes algún Tipo de Acumulado agregados"/>);
            }
        } else {
            setError(<AlertForm message="No tienes permisos asignados para realizar esta acción"/>);
        }
    };

    const validaFecha = (fecha) => {
        if(periodo) {
            const { periodoFecini: fechaInicial, periodoFecfin: fechaFinal } = periodo;
            const fechaPago = fecha;

            if((fechaPago >= fechaInicial) & (fechaPago <= fechaFinal)) {
                return true;
            } else {
                return false;
            }

        } else {
            return false;
        }
    };

    const servicioProcesarAcumulado = (info, tipoAcumuladoInfo, tipo) => {
        if(validaFecha(info.fechaPago)) {
            mostrarLoading();
            axios.post(`${servicios_grupogack_nomina}/procesos/calcular`, info, headersAuth())
            .then(res => {
                ocultarLoading();
                switch(res.data.status) {
                    case 200:
                        if(tipo === 1) {
                            setAcumuladosSelected([ ...acumuladosSelected, { tipoAcumuladoId: info.acumulado, tipoAcumuladoNombre: tipoAcumuladoInfo.acumuladoNombre, fechaPago: info.fechaPago, cerrado: false }]);
                            successTable("El acumulado se procesó correctamente");
                        } else {
                            setAcumuladosSelected(tipoAcumuladoInfo);
                            successTable("El acumulado se reprocesó correctamente");
                        }
                        setError(null);
                        break;
                    case 400:
                        errorTable("La información enviada no cumple con lo requerido");
                        break;
                    case 401:
                        errorTable("Token inválido. Vuelve a iniciar sesión");
                        break;
                    default:
                        errorTable("Error en el servicio. Vuelve a intentarlo");
                        break;
                }
            })
            .catch(error => errorTable("Error en el servicio. Vuelve a intentarlo"));
        } else {
            setError(<AlertForm message="La fecha ingresada debe estár dentro del rango de Fecha Inicial y Fecha Final"/>)
        }
    };

    const procesarAcumulado = e => {
        e.preventDefault();
        if(validarSubmit('FormCalculoNomina')) {
            const tipoAcumuladoSelected = parseInt(tipoAcumuladoRef.current.value);
            const fechaPago = fechaPagoRef.current.value;

            const tipoAcumuladoInfo = tiposAcumulado.find(tipoA => tipoA.id === tipoAcumuladoSelected);

            const verificaSelected = () => {
                const encuentra = acumuladosSelected.find(acumS => acumS.tipoAcumuladoId === tipoAcumuladoSelected);
                if(encuentra) {
                    return false;
                } else {
                    return true;
                }
            };

            if(verificaSelected()) {
                if(permisos.find(permiso => permiso.id === 1)) {
                    servicioProcesarAcumulado({ 
                        nomina: elementSelect.id,
                        acumulado: parseInt(tipoAcumuladoSelected),
                        fechaPago: inputDateToLong(fechaPago),
                        periodo: periodo.id
                    }, tipoAcumuladoInfo, 1);
                } else {
                    setError(<AlertForm message="No tienes permisos asignados para realizar esta acción"/>);
                }
            } else {
                setError(<AlertForm message="Ya agregaste ese tipo de acumulado. Seleccione otro"/>);
            }

        }
    };

    const reprocesar = info => {
        let elementoCambio = acumuladosSelected.find(acumS => acumS.tipoAcumuladoId === info.id);
        elementoCambio.fechaPago = info.fecha;

        const nuevoArray = acumuladosSelected.filter(acumS => acumS.tipoAcumuladoId !== info.id);

        if(permisos.find(permiso => permiso.id === 2)) {
            servicioProcesarAcumulado({ 
                nomina: elementSelect.id,
                acumulado: info.id,
                fechaPago: info.fecha,
                periodo: periodo.id
            }, [ ...nuevoArray, elementoCambio ], 2);
            setModalIsOpen(false);
            setValue(null);
        } else {
            setError(<AlertForm message="No tienes permisos asignados para realizar esta acción"/>);
        }

    };

    const cerrarAcumulado = item => {
        mostrarLoading();
        const request = { acumulado: item.tipoAcumuladoId, periodo: periodo.id };
        axios.post(`${servicios_grupogack_nomina}/procesos/acumular`, request, headersAuth())
        .then(res => {
            ocultarLoading();
            switch(res.data.status) {
                case 200:
                    let elementoCerrado = acumuladosSelected.find(acumS => acumS.tipoAcumuladoId === item.tipoAcumuladoId);
                    elementoCerrado.cerrado = true;
                    const nuevoArray = acumuladosSelected.filter(acumS => acumS.tipoAcumuladoId !== item.tipoAcumuladoId);
                    
                    setAcumuladosSelected([ ...nuevoArray, elementoCerrado ]);
                    setError(null);
                    setValue(null);
                    successTable("El Acumulado se cerró correctamente");
                    break;
                default:
                    errorTable(res.data.msg);
                    break;
            }
        })
        .catch(error => { alert('error') });
    };

    const eliminarAcumulado = item => {
        if(permisos.find(permiso => permiso.id === 3)) {
            mostrarLoading();
            const request = { acumulado: item.tipoAcumuladoId, periodo: periodo.id };
            axios.post(`${servicios_grupogack_nomina}/procesos/eliminar`, request, headersAuth())
            .then(res => {
                ocultarLoading();
                switch(res.data.status) {
                    case 200:
                        const nuevoArray = acumuladosSelected.filter(acumS => acumS.tipoAcumuladoId !== item.tipoAcumuladoId);
                        successTable("El Acumulado procesado se eliminó correctamente");
                        setError(null);
                        setValue(null);
                        setAcumuladosSelected(nuevoArray);
                        break;
                    default:
                        errorTable(res.data.msg);
                        break;
                }
            })
            .catch(error => { alert('error') });
        } else {
            setError(<AlertForm message="No tienes permisos asignados para realizar esta acción"/>);
        }
    };

    return ( 
        <Fragment>
            <form id="FormCalculoNomina" onSubmit={verificaAcumuladosCerrados}>
                <div className="formRelLab">
                    <div style={{ float: 'right', width: '30%' }}>
                        <SelectValidation title="Tipo de Acumulado" arrayOpciones={tiposAcumulado} keyOpcion="acumuladoNombre"
                            refValue={tipoAcumuladoRef} defaultValue="" required/>
                    </div>
                    <div style={{ width: '30%', marginLeft: '5%', marginRight: '5%' }}>
                        <InputDateValidation title="Fecha de Pago" refValue={fechaPagoRef}
                            tooltipText="Seleccione una fecha del calendario" tooltipName="tooltipFPago"
                            defaultValue="" required/>
                    </div>
                    <div style={{ float: 'left', width: '30%', paddingTop: '30px' }}>
                        {   (permisos.find(permiso => permiso.id === 1)) &&
                            <Tooltip title="Procesar Acumulado" placement="top">
                                <Button variant="contained" className="btn-color" onClick={procesarAcumulado} disabled={openLoading}>
                                    <SyncIcon />
                                </Button>
                            </Tooltip>
                        }
                    </div>
                </div>
                <AcumuladosSelecionados nombreModulo={nombreModulo} acumuladosSelected={acumuladosSelected} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}
                    openAlertCerrar={openAlertCerrar} setOpenAlertCerrar={setOpenAlertCerrar}
                    openAlertEliminar={openAlertEliminar} setOpenAlertEliminar={setOpenAlertEliminar} value={value} setValue={setValue}
                    reprocesar={reprocesar} cerrarAcumulado={cerrarAcumulado} eliminarAcumulado={eliminarAcumulado}
                    permisos={permisos} openLoading={openLoading}/>
                { error }
                <br/>
                <Button variant="contained" className="btn-color" type="submit" disabled={(permisos.find(permiso => permiso.id === 2)) ? false : openLoading }>Actualizar Periodo</Button>
            </form>
            <AlertSyE show={openAlert} setOpenAlertSyE={setOpenAlert} title="Acumulados sin cerrar" text="Antes de actualizar periodo, debe cerrar todos los Acumulados" textbtnconfirm="Aceptar" type="error" />
            <AlertWarning show={openAlertWarning} SetopenALert={setOpenAlertWarning} text="¡Se abrirá un nuevo periodo!" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviar}/>
            <ModalLoading nombreModulo={nombreModulo} open={openLoading} setOpen={setOpenLoading}/>
        </Fragment> 
    );
}
export default FormCalculo;
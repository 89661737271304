import React, { PureComponent } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import image2base64 from 'image-to-base64';

/* Alerts */
import SweetAlert from 'react-bootstrap-sweetalert'
import { Button, Tooltip } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';

import axios from 'axios';
import { servicios_grupogack } from '../../../../Constants/Constants';
import { headersAuth } from '../../../../Services/AuthService';

class ModalCropReact extends PureComponent {
    state = {
        modal: false,
        sweetAlert: false,

        src: null,
        tipoSRC: null,
        nombreImagen: null,
        crop: {
            unit: "%",
            width: 100,
            aspect: 16 / 16
        }
    };

/* -------------------------------------------- Mostrar y ocultar el modal / alert -------------------------------------------- */
    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    onOpenfile = evt => {
        this.onSelectFile( evt );
    }

    /* Validacion */
    onConfirmAlert = () => {
        this.setState({ sweetAlert: !this.state.sweetAlert });
    }
/* -------------------------------------------------------------------------------------------------------------------- */
    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0 ) {
            const tipoImg = e.target.files[0].type;
            const nombreImg = e.target.files[0].name;
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                if( tipoImg === 'image/png' || tipoImg === 'image/jpeg' || tipoImg === 'image/gif' ) {
                    var image = new Image();
                    image.src = reader.result;
                    this.setState({ src: image.src, tipoSRC: tipoImg, nombreImagen: nombreImg });
                    this.toggle();
                }else{
                    this.onConfirmAlert();
                }
            });
            reader.readAsDataURL(e.target.files[0]);
        }
        document.getElementById( "file-input-profile" ).value = "";
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.png"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/png");
        });
    }
/* -------------------------------------------------------------------------------------------------------------------- */
    base64 = (URL) => {
        image2base64( URL )
            .then( response => {
                this.toggle();
                //const data = { imagen: response, nombre: this.state.nombreImagen, data: this.state.tipoSRC, id: this.props.usuario.claveUsuario };
                const { claveUsuario, usuario } = this.props.usuario;
                this.axiosImagen({ imagen: response, id: claveUsuario, usuario });
            })
            .catch( error => { console.log( error ) })
    }

    axiosImagen = ( imagen64 ) => {
        axios.post( `${servicios_grupogack}/img/`, imagen64, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        this.props.setImagenPerfil( respuesta.data.dataResponse );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
/* -------------------------------------------------------------------------------------------------------------------- */

    render() {
        const { modal, sweetAlert, crop, croppedImageUrl, src } = this.state;
        return (
            <div className="mt-3">
                <div className="image-upload">
                    <Tooltip title="Cambiar Imagen">
                        <label className="MuiButtonBase-root MuiIconButton-root" htmlFor="file-input-profile" type="button"> <PhotoCamera /> </label>
                    </Tooltip>
                    <input id="file-input-profile" type="file" className="btn btn-light col-md-12" onChange={this.onOpenfile} accept="image/png, image/jpeg"/>
                </div>
                
                <Modal id="ModalCrop" isOpen={modal} toggle={this.toggle} centered={true}>
                    <ModalHeader toggle={this.toggle}> Editar imagen de perfil </ModalHeader>

                    <ModalBody>
                        <div id="imagenCrop" className="col-md-12 img-fluid text-center">
                            {src && (
                                <ReactCrop
                                src={src}
                                crop={crop}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                                />
                            )}
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <Button variant="outlined" className="btn-outline" onClick={this.toggle}>Cancelar</Button>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <Button variant="contained" className="btn-color" onClick={() => this.base64( croppedImageUrl )}>Guardar</Button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>

                { /* ------------------------------ Notificacion de alerta ------------------------------ */ }
                <SweetAlert warning show={sweetAlert} title="Ingrese un formato de imagen" btnSize="sm" confirmBtnText="Aceptar" onConfirm={() => this.onConfirmAlert() } >
                    <span style={{ color: "#F1AC4A" }}> PNG / JPG </span>
                </SweetAlert>
            </div>
        );
    }
}

export default ModalCropReact;
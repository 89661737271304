import React from 'react';
import { connect } from 'react-redux';
import './style.css';

import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

/* -----------------------------------------------------------------------------------------------------  */
import { quitarNavegador, cambiarActivo } from '../../../../../Redux/actions/NavActions';
/* -----------------------------------------------------------------------------------------------------  */

function TabsContainer( {navegadoresStore, quitarNavegador, componenteActivoID, cambiarActivo} ){
    return(
            <div className="row" style={{ position: "relative", zIndex: "1299" }} >
                <div className="col-md-12" style={{top: "-80px", left:"20px"}}>
                    <div className="tabbable-panel">
                        <div className="tabbable-line">
                            <ul className="nav nav-tabs ">

                                <li style={ {paddingBottom: 12} } className={ componenteActivoID === 1 ? 'active' : '' }>
                                    <span onClick={() =>  cambiarActivo( 1, componenteActivoID )} >
                                        Tablero 
                                    </span>
                                </li>

                                { Object.keys(navegadoresStore).map(key => {
                                    return(
                                        <li key={key} className={ componenteActivoID === navegadoresStore[key].id ? 'active' : '' }>
                                            <span onClick={() =>  cambiarActivo( navegadoresStore[key].id, componenteActivoID )}>
                                                {navegadoresStore[key].nombre}
                                            </span>
                                            
                                            <IconButton aria-label="delete" size="small" 
                                                onClick={ () => quitarNavegador( navegadoresStore[key].id, componenteActivoID, key, navegadoresStore ) }>
                                                <ClearIcon fontSize="inherit" />
                                            </IconButton>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
    )
}

/* ------------------------------------------------------------------------------------------------------------------  */
const mapStateToProps = (state) => ({
    navegadoresStore : state.NavReducer.navegadores,
    componenteActivoID: state.NavReducer.componenteActivo
});
const mapDispatchToProps = { quitarNavegador, cambiarActivo };
/* ------------------------------------------------------------------------------------------------------------------  */
export default connect(mapStateToProps, mapDispatchToProps)(TabsContainer);

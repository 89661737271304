import React, { createRef, useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_catalogo } from '../../../../../../Constants/Constants';
import {Button, Switch, createMuiTheme, Tooltip, MuiThemeProvider} from '@material-ui/core';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import { headersAuth } from '../../../../../../Services/AuthService';
const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

const FormPuestos = ({ error, setError, enviarPuesto, elementSelect, actualizarPuesto }) => {
    
    const nivelesRef = createRef(''); 
    const relLaboralRef = createRef(''); 
    const puestoCveRef = createRef('');
    const puestoNomRef = createRef('');
    const puestoDescripRef = createRef('');
    const [toogle, setToogle] =useState(elementSelect ? elementSelect.status==='Activo': false);
    const [companias, setcompanias] = useState(null);

    const [niveles, setNiveles] = useState(null); 

    useEffect(()=>{
        axios.get(`${servicios_grupogack_catalogo}/nvlaboral/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    let response=[];
                    res.data.dataResponse.map((resp)=> resp.status.id === 1 ? response.push(resp) :null )
                    setNiveles(response);
                    break;
                case 404:
                    setNiveles([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]); 
    
    
    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}/relacionlaboral/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:   
                    setcompanias(res.data.dataResponse);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
      }, []);

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('AgregarPuesto')) {
            const nivlaboral = parseInt(nivelesRef.current.value); 
            const puestoCve = puestoCveRef.current.value;
            const puestoNom = puestoNomRef.current.value;
            const puestoDescrip = puestoDescripRef.current.value;
            const relabid = parseInt(relLaboralRef.current.value);
            enviarPuesto( { nivlaboral, puestoCve,  puestoNom, puestoDescrip, relabid} );
        }
    };

    const actualizar = e => {
        e.preventDefault();
        if(validarSubmit('EditarPuesto')) {
            const nivlaboral = parseInt(nivelesRef.current.value);
            const puestoCve = puestoCveRef.current.value;
            const puestoNom = puestoNomRef.current.value;
            const puestoDescrip = puestoDescripRef.current.value;
            const relabid = parseInt(relLaboralRef.current.value);
            const status = toogle ? 1 : 2 ;
            setError(null);
            actualizarPuesto( { nivlaboral, puestoCve,  puestoNom, puestoDescrip, status, relabid}, elementSelect.id );
        }
    };

    return (
        <form id={ elementSelect ? 'EditarPuesto' : 'AgregarPuesto' } onSubmit={ elementSelect ? actualizar : enviar }>
            
            {
                niveles
                ?   <SelectValidation title="Clave Nivel Laboral" arrayOpciones={niveles} keyOpcion="nivlaboralClave" 
                        refValue={nivelesRef} defaultValue={elementSelect ?  elementSelect.nivlaboralId :''} required/>
                :   null
            }
            {
                elementSelect
                ?
                <div className="row">
                    <div className="col-md-8">
                    <InputValidation title="Clave del Puesto" type="clavePuesto" placeholder="Escriba la clave del puesto"
                        refValue={puestoCveRef} defaultValue={ elementSelect ? elementSelect.puestoCve: '' }
                        tooltipText={'Máximo 20 letras y/o números'} tooltipName={'tooltipCvePuesto'} maxLength={20} required alfanumeric/>
                    </div>               
                    <div className="col-md-4">
                        <div className="form-group text-center">
                            <label>Estado</label>
                            </div>
                            <div className="text-center">
                            <MuiThemeProvider theme={theme}>
                                <Tooltip title="Modificar Status">
                                    <Switch
                                        checked={toogle}
                                        onChange={  () => setToogle(!toogle) }
                                        value="checkedA"
                                        size="small"
                                    />
                                </Tooltip>
                            </MuiThemeProvider>
                        </div>  
                    </div>
                </div>
                :
                <InputValidation title="Clave del Puesto" type="clavePuesto" placeholder="Escriba la clave del puesto"
                refValue={puestoCveRef} defaultValue={ elementSelect ? elementSelect.puestoCve: '' } 
                tooltipText={'Máximo 20 letras y/o números'} tooltipName={'tooltipCvePuesto'} maxLength={20} required alfanumeric/>
            }

            <InputValidation title="Nombre" type="nombrePuesto" placeholder="Escriba el nombre del puesto"
                refValue={puestoNomRef} defaultValue={ elementSelect ? elementSelect.puestoNom: '' } 
                tooltipText={`Máximo 256 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipNomPuesto'} maxLength={256} required/>

            <InputValidation title="Descripción" type="descPuesto" placeholder="Escriba la descripción del puesto" fieldType="textareaShort"
                refValue={puestoDescripRef} defaultValue={ elementSelect ? elementSelect.puestoDescrip : '' } 
                tooltipText={`Máximo 1024 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipDescPuesto'}  maxLength={1024} optional/>
            
            {   
                companias
                ?   <SelectValidation title="Tipo de Relación Laboral" arrayOpciones={companias} keyOpcion="nombre" 
                        refValue={relLaboralRef} defaultValue={elementSelect ?  elementSelect.relabId :''} required/>
                :   null
            }
            
            {error}
            <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            
        </form>   
    );
}
export default FormPuestos;

import React, { useState, createRef, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormTipoPasatiempos = ({ error, setError, enviarTipoPasatiempo, elementSelect, actualizarTipoPasatiempo }) => {

    const [openAlert, setOpenAlert] = useState(false);
    const nombreRef = createRef('');
    const descripcionRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const enviar = e => {
        e.preventDefault();
        if(validarSubmit('FormPasatiempo')) {
            const pasaNom = nombreRef.current.value
            const pasaDesc = descripcionRef.current.value;
            enviarTipoPasatiempo({ pasaNom, pasaDesc });
        }
    };

    const actualizar = () => {
        if(validarSubmit('FormPasatiempo')) {
            const pasaNom = nombreRef.current.value
            const pasaDesc = descripcionRef.current.value;
            setError(null);
            actualizarTipoPasatiempo({ pasaNom, pasaDesc }, elementSelect.id);
        }
    };

    return ( 
        <Fragment>
            <form id="FormPasatiempo" onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <div className="formRelLab">
                    <div className="form-left">
                        <InputValidation title="Nombre" type="nombrePasatiempo" placeholder="Escriba el nombre del pasatiempo"
                            refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.pasaNom : '' } 
                            tooltipText={'Máximo 230 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            tooltipName="tooltipNomPasa" maxLength={230} required/>
                    </div>
                    <div className="form-right">
                        <InputValidation title="Descripción" type="descripcionPasatiempo" placeholder="Escriba la descripción del pasatiempo"
                            refValue={descripcionRef} defaultValue={ elementSelect ? elementSelect.panDesc : '' }
                            tooltipText={'Máximo 2024 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'}
                            tooltipName="tooltipDesPasa" required maxLength={2024} fieldType="textareaShort"/>
                    </div>  
                { error }
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
                </div>
            </form>   
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </Fragment> 
    );
}
export default FormTipoPasatiempos;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
//import { JsonTipoDocumentos } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioNominasOrg from './FormularioNominasOrg';

const ModalAddNominasOrg = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    const [ error, setError ] = useState( null );
    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    }

    const enviarNomina = ( nomina ) => {
        axios.post( `${servicios_grupogack_nomina}/config/nomina`, nomina, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        //agregar( JsonTipoDocumentos(respuesta.data.dataResponse) );
                        agregar( respuesta.data.dataResponse );
                        setOpenAdd( false );
                        break;
                    case 400:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        break;
                    default:
                        errorTable( registro_no_guardado );
                        break;
                }
            })
            .catch( error => {
                errorTable( registro_no_guardado );
            })
    }

    return (
        <ModalService title="Nueva Nómina" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openAdd } onClose={ () => cerrarModal() } >
                <FormularioNominasOrg
                    error={error}
                    informacion={informacionAdicional}
                    enviarNomina={enviarNomina}
                />
        </ModalService>
    );
};

export default ModalAddNominasOrg;
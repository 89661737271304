import React from 'react';
//import axios from 'axios';
//import { servicios_grupogack_catalogo, registro_no_eliminado, registro_sera_eliminado } from '../../../../../../../Constants/Constants';
import { registro_sera_eliminado } from '../../../../../../../Constants/Constants';
//import { headersAuth } from '../../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';

const ModalDeleteAcumulados = ({ alertWarning, setAlertWarning, elementSelect, eliminar, errorTable }) => {
    
    const servicioEliminar = () => {
        /*axios.delete(`${servicios_grupogack_catalogo}/acumulado/${elementSelect.id}`, headersAuth())
        .then(res =>  {
            switch(res.data.status) {
                case 200:*/
                    eliminar(elementSelect.id);
                    /*break;
                case 404:
                    errorTable(res.data.msg);
                    break;
                default:
                    errorTable(registro_no_eliminado);
                    break;
            }
        })
        .catch(error => errorTable(registro_no_eliminado));*/
    }

    return(
        <Alertwarning show={alertWarning} SetopenALert={setAlertWarning} text={registro_sera_eliminado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={servicioEliminar} />
    );
}

export default ModalDeleteAcumulados;
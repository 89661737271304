import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormPuestos from './FormPuestos';
import { JsonPuestos } from '../../../Helpers/JsonToOneLevel';

const ModalEditPuestos = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    }

    const actualizarPuesto = (puesto, puestoId) => {
        const info = { nivlaboral: puesto.nivlaboral, puestoCve: puesto.puestoCve, puestoNom: puesto.puestoNom,
            puestoDescrip: puesto.puestoDescrip, status: puesto.status, relabid: puesto.relabid  };;
        Axios.put(`${servicios_grupogack}/org/puesto/${elementSelect.id}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    editar(JsonPuestos(res.data.dataResponse));
                    setOpenEdit(false);
                    break;
                case 400:
                case 404:
                    setError(<AlertForm message={res.data.msg} />);
                    break;

                default:
                    errorTable(cambios_no_guardados);
                    break;

            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Puesto" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormPuestos
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        actualizarPuesto={actualizarPuesto}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditPuestos;
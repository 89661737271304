import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_rh, servicios_grupogack_catalogo, movimientos_relacionados } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

import RelacionadosEmpleado from './RelacionadosEmpleado/RelacionadosEmpleado';
import './styles.css';

const MovimientosRelacionados = ({ id }) => {
    const [ elementSelect, setElementSelect ] = useState( null );
    /* ----------------------------- Axios para opbtener empleados activos ----------------------------- */
    const [ empleados, setEmpleados ] = useState( null );
    const [ tiposAcumulado, setTiposAcumulado ] = useState( null );
    useEffect(() => {
        axiosEmpleados();
        axiosTiposAcumulado();
    }, []);

    const axiosEmpleados = () => {
        axios.get( `${servicios_grupogack_rh}/empleado/activos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setEmpleados( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setEmpleados( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener tipos de acumulado */
    const axiosTiposAcumulado = () => {
        axios.get( `${servicios_grupogack_catalogo}s/tipoacumulado/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setTiposAcumulado( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setTiposAcumulado([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }
    /* ------------------------------------------------------------------------------------------------- */
    const onShowTable = ( elementSelect ) => {
        //console.log( elementSelect );
        setElementSelect( elementSelect );
    }

    return (
        <div id={ id }>
            { elementSelect && tiposAcumulado ?
                <RelacionadosEmpleado empleado={elementSelect} setEmpleado={setElementSelect} tiposAcumulado={tiposAcumulado} idModulo={ id }/>
                :
                <React.Fragment>
                    { empleados ?
                        <DataTableService id={id} title={movimientos_relacionados}
                            columns={[
                                { field: "empleadoClave", title: "Núm. Empleado" },
                                { field: "empleadoNom", title: "Nombre" },
                                { field: "empleadoPat", title: "Primer Apellido" },
                                { field: "empleadoMat", title: "Segundo Apellido" },
                                { field: "empleadoRfc", title: "RFC" },
                                { field: "puestoNom", title: "Plaza / Puesto" },
                                { field: "nominaNombre", title: "Nómina" },
                                { field: "cpresupuestalNom", title: "Clave Presupuestal / Proyecto" },
                            ]}
                            hideIconAdd hideIconEdit hideIconDelete
                            showIconDescription onShowTable={onShowTable}
                            data={ empleados } />
                        :
                        <SpinnerOval />
                    }
                </React.Fragment>
            }
        </div>
    );
};

export default MovimientosRelacionados;
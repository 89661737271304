import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_rh, servicios_grupogack_nomina, movimientos_ } from '../../../../../../../Constants/Constants';
import DataTableMovimientos from '../DataTableMovimientos/DataTableMovimientos';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';
import { ArrayJsonMovimientosEmpleado } from '../../../../Helpers/JsonToOneLevel';

const MovimientosEmpleado = ({ empleado, setEmpleado, tiposAcumulado, idModulo }) => {    
    const [ movimientos, setMovimientos ] = useState( null );
    const [ indicadoresNomina, setIndicadoresNomina ] = useState( null );
    useEffect(() => {
        axiosMovimientosEmpleado();
        axiosIndicadoresNomina();
        // eslint-disable-next-line
    }, []);

    const axiosMovimientosEmpleado = () => {
        axios.get( `${servicios_grupogack_rh}/movimientos/empleado/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setMovimientos( ArrayJsonMovimientosEmpleado ( respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        setMovimientos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener los indicadores por nomina (Nomina a la cual esta relacionada el empleado) */
    const axiosIndicadoresNomina = () => {
        axios.get( `${servicios_grupogack_nomina}/indicadores/getbynomina/${empleado.nominaId}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresNomina( respuesta.data.dataResponse );
                        break;
                    case 404: //No hay datos disponibles
                        setIndicadoresNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setIndicadoresNomina( [] );
            })
    }

    return (
        <React.Fragment>
            { movimientos && indicadoresNomina ?
                <DataTableMovimientos
                    idModulo={idModulo}
                    title={movimientos_}
                    empleado={empleado}
                    setEmpleado={setEmpleado}
                    columns={[
                        { field: "nomina.nomClave", title: "Clave Indicador" },
                        { field: "nomina.nomIndicador", title: "Nombre Indicador" },
                        { field: "cantidad", title: "Cantidad", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "monto", title: "Monto (MXN)", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "acumulado.acumuladoNombre", title: "Tipo de Acumulado" },
                        { field: "fechaAplicacionTab", title: "Fecha de Aplicación" },
                    ]}
                    data={ movimientos }
                    informacionAdicional={{ empleado, tiposAcumulado, indicadoresNomina }}/>
                :
                <SpinnerOval />
            }
        </React.Fragment>
    );
};

export default MovimientosEmpleado;
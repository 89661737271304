import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_url, servicios_grupogack_catalogo, usuarios_ } from '../../../../../../Constants/Constants';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import './styles.css';

const Usuarios = ({ id, permisos }) => {
    const [ usuarios, setUsuarios ] = useState( null );
    const [ departamentos, setDepartamentos ] = useState( null );
    useEffect(() => {
        axiosUsuarios();
        axiosDepartamentos();
    }, []);

    const axiosUsuarios = () => {
        axios.get( `${servicios_grupogack_url}/all/subordinates`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setUsuarios( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setUsuarios( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    const axiosDepartamentos = () => {
        axios.get( `${servicios_grupogack_catalogo}/departamentos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDepartamentos( respuesta.data.dataResponse );
                        break;
                    case 404: //No hay registros para mostrar
                        setDepartamentos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={ id }>
            { usuarios && departamentos ?
                <DataTableService id={id} title={ usuarios_ } permisos={permisos}
                    columns={[
                        { field: "datosUsuarioNom", title: "Nombre" },
                        { field: "datosUsuarioPat", title: "Primer Apellido" },
                        { field: "datosUsuarioMat", title: "Segundo Apellido" },
                        { field: "username", title: "Usuario" },
                    ]}
                    data={ usuarios }
                    informacionAdicional={{ usuarios, departamentos }}/>
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default Usuarios;
import React, { useState, useEffect, createRef, Fragment } from 'react';
/* -----------------------------------------------------------------------------------------------------  */
import { formatExcel, convertColumnasPDF, convertColumnasPrint, getStylesPDF } from './ActionsDatatable';
import { obtenerModalAdd, obtenerModalEdit, obtenerModalDelete, obtenerModalDeleteForm, obtenerModalInfo, obtenerModalForm } from './HerlperDialogs';
import { localization, tableIcons } from './ElementTable';
import { filtraJson } from '../Helpers/JsonToOneLevel';
import { eliminaNullPrint } from '../Helpers/FilterJsonNull';
import AlertSyE from '../../../../Services/Alerts/AlertSyE'; //Alertas
import AlertTable from './AlertTable';
import ModalPDF from './ModalPDF';
/* --------------------------------------- Librerias Adicionales ---------------------------------------  */
import jsPDF from 'jspdf';
import 'jspdf-autotable';
/* import csvDownload from 'json-to-csv-export' */
import printJS from 'print-js'
import copy from 'copy-to-clipboard';
/* -----------------------------------------------------------------------------------------------------  */
import { SaveAlt, PictureAsPdf, FileCopy, Print, Add, Edit, Delete, Description, Payment, Save, Person, Apps, MonetizationOn, AlarmOff, PostAdd, ChromeReaderMode } from '@material-ui/icons';
import Folder from '@material-ui/icons/Folder';
import CreateNewFolder from '@material-ui/icons/CreateNewFolder';
import { IconButton } from '@material-ui/core';
import MaterialTable, { MTableToolbar, MTableAction, MTableCell, MTableGroupRow  } from 'material-table';
import { MuiThemeProvider, createMuiTheme, Switch, Tooltip } from '@material-ui/core';
import './StyleDataTable.css';
import {searchComponentTex} from '../../Usuario/MenuDrawer/Header/SearchComponent';


const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#4C638E' },
    },
});

function DataTableService({ id, title, columns, data, informacionAdicional, onShowTable, hideIconAdd, hideIconEdit, hideIconDelete, showIconDescription, isReport, showIconPayment, showIconEmployee, showIconApps, showIconAux, showIconPostAdd, showIconChromeReaderMode, permisos, showIconPDF }) {
    
    const tableRef = createRef();
    const columnasPDF = convertColumnasPDF( columns ); //this.props.columns
    const estilosPDF = getStylesPDF( id );
    const columnasPrint = convertColumnasPrint( columns ); //this.props.columns
    let dataContent = data; //this.props.data
    const [ columnsTable, setColumnsTable ] = useState( isReport ? columns.map(element=>{const newElement=element; newElement.grouping = !data.every(item=>item[element.field]===data[0][element.field]); newElement.filtering=newElement.grouping; newElement.filterPlaceholder='Filtrar'; return newElement}) :  columns );
    /* if(tableRef.current && tableRef.current.dataManager.searchText==="" && searchComponentTex!=='' ){
        tableRef.current.onSearchChange(searchComponentTex)
    } */
    
    useEffect(() => {
        setDataTable(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    useEffect(() => {
        setColumnsTable(columnsTable); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columnsTable]);

    const [ textAlert, setTextAlert ] = useState(''); //this.props.data
    const [ dataTable, setDataTable ] = useState( data ); //this.props.data
    const [ openAdd, setOpenAdd ] = useState( false );
    const [ openEdit, setOpenEdit ] = useState( false );
    const [ openDeleteForm, setOpenDeleteForm ] = useState( false );
    const [ openModalForm, setOpenModalForm ] = useState( false );
    const [ openInfo, setOpenInfo ] = useState( false );
    const [ elemmentSelect, setElemmentSelect ] = useState( {} );

    const [ openPDF, setOpenPDF ] = useState( false );
    const [ pdfSelect, setPdfSelect ] = useState( null );
    const [ error, setError ] = useState( null );

    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );
    /* -----------------------------------------------------------------------------------------------------  */
    const detectarContenido = () => {
        if( dataContent.length > 0 ){
            setError( null );
            return true;
        }else{
            setError( <AlertTable mensaje="Asegúrate de tener contenido para exportar" closeAlert={ () => setError(null) } /> );
            return false;
        }
    }
    /* ---------------------------------------------------------------------------------------------- */
    const optionsTableReports={
        grouping       : true,
        filtering      : true,
        defaultExpanded: id!==48,
        doubleHorizontalScroll: true
    }

    const optionsTable = {
        columnsButton: true,
        pageSize: (dataTable.length <= 5) ? 5 : 10,
        headerStyle: { zIndex: "0", color: '#4C638E', fontweight: 'bold'},
        actionsColumnIndex:-1,
        searchFieldAlignment: "left",
        searchText:searchComponentTex.text ? searchComponentTex.text: isReport && informacionAdicional ? informacionAdicional.filtroGeneral:''
    }

    const dataVisible = (columns, data)=>{    
        return extractDataGroup(data, columnsVisibles(columns), groupByData(columns));
    }

    const groupByData = (columns) => {
        return columns.filter(columnDef => columnDef.tableData.groupOrder !== undefined);
    }

    const columnsVisibles = (columns) => { 
        return columns.filter(columnDef => (columnDef.hidden !== true  && columnDef.tableData.groupOrder === undefined)).sort((a, b)=> a.tableData.columnOrder > b.tableData.columnOrder ? 1 : a.tableData.columnOrder < b.tableData.columnOrder ? -1 :0 );
    }

    const extractDataGroup=(data, columnsVisibles, groupBy)=>{
        var result = [];
        for (const key in data) {  
            if (data[key].groups) { 
                const colName = groupBy.filter(element => element.tableData.groupOrder===data[key].path.length-1)[0];
                result.push({" ": `${colName.title}: ${data[key].value}`}); 
                if(data[key].groups.length>0){ 
                    const group = extractDataGroup(data[key].groups, columnsVisibles, groupBy);
                    for (const key in group) {
                        result.push( group[key]);
                    } 
                }else{
                    const dataVisble = data[key].data.map(rowData =>{ const newJson={};  Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = rowData[columnDef.field]; return element})); return newJson;});
                    dataVisble.map(element=>result.push(element)); 
                }
            }else{ 
                const newJson = {};
                Object.assign(newJson, ...columnsVisibles.map(columnDef => { const element={}; element[columnDef.title] = data[key][columnDef.field]; return element})); 
                result.push(newJson);
            }
        }
        return result;
    } 

    const csvDownload = (data, name) => {
        const items = data
        const filename = name || `export.csv`
      
        const header = Array.from(
          new Set(items.reduce((r, e) => [...r, ...Object.keys(e)], []))
        )
        let csv = items.map(row =>
          header.map(fieldName => 
            JSON.stringify(row[fieldName] || ''))
            .join(',')
        )
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')
      
        const blob = new Blob(["\uFEFF"+csv], {
          type: 'text/csv;charset=UTF-8',
        })
      
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(blob, filename)
          return
        }
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download = filename
        link.style.display = 'none'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const csvDownloadReport= ()=>{ 
        let csvContent =  "\ufeff"+formatExcel( dataVisible(tableRef.current.state.columns, tableRef.current.state.data), groupByData(tableRef.current.state.columns).length,  ',');
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", "data:text/csv;charset=utf-8," +encodedUri);
        link.setAttribute("download", `${title}.csv`);
        document.body.appendChild(link);
        link.click(); 
    }

    const actionsTable = [
        ...[ {
            icon: SaveAlt, tooltip: 'CSV',
            isFreeAction: true,
            onClick: (event) => {
                if( detectarContenido() ){
                    if(isReport){ 
                        csvDownloadReport();
                    }else{
                        const resultData = filtraJson(id, dataContent);
                        if(resultData) csvDownload( resultData, `${title}.csv` );
                        else alert("Falta que agregue el case de filtrado");
                    }
                }
            }
        }],
        {
            icon: PictureAsPdf, tooltip: 'PDF',
            isFreeAction: true,
            onClick: (event) => {
                if( detectarContenido() ){
                    const doc = new jsPDF( estilosPDF.orientation );
                    let titulo = isReport ? title : `Lista de ${title}`;
                    doc.autoTable(  isReport ? convertColumnasPDF(columnsVisibles(tableRef.current.state.columns), isReport, groupByData(tableRef.current.state.columns).length>0) 
                                    : columnasPDF ,
                                    isReport ? dataVisible(tableRef.current.state.columns, tableRef.current.state.data): 
                                      dataContent, {
                        margin: {top: 15},
                        headStyles: { fillColor: [76, 99, 142] }, //#4C638E
                        didDrawPage: function(data) {
                            doc.text( titulo, 14, 12);
                            (estilosPDF.orientation === 'landscape')
                            ?   doc.text(`Página ${data.pageNumber}`, 258, 200)
                            :   doc.text(`Página ${data.pageNumber}`, 170, 290)
                        },
                        styles: estilosPDF.styles,
                        columnStyles: estilosPDF.columnStyles
                    });
                    var string = doc.output('datauristring');
                    setPdfSelect( string );
                    setOpenPDF( true );
                }
            }
        },
        {
            icon: FileCopy, tooltip: 'Copiar',
            isFreeAction: true,
            onClick: (event) => {
                if( detectarContenido() ){
                    const resultData = isReport ? dataVisible(tableRef.current.state.columns, tableRef.current.state.data) : filtraJson(id, dataContent);
                    if(resultData){
                        const cadenaExcel = formatExcel( resultData, groupByData(tableRef.current.state.columns).length );
                        copy( cadenaExcel, {
                            format: 'text'
                        });
                    } else alert("Falta que agregue el case de filtrado");
                }
            }
        },
        {
            icon: Print, tooltip: 'Imprimir',
            isFreeAction: true,
            onClick: (event) => {
                if( detectarContenido() ){
                    const resultData = isReport ? dataVisible(tableRef.current.state.columns, tableRef.current.state.data) : eliminaNullPrint(id, dataContent); 
                    const lenghtGroupBy = groupByData(tableRef.current.state.columns).length
                    const resultVisible = resultData.map((element, index)=> { 
                        const key= Object.keys(element);  
                        if(key.length===1){ 
                            let result = element;
                            Object.keys(resultData[resultData.length-1]).forEach(elements => {
                                result[elements]=" ";
                            }); 
                            return result;
                        }else{
                            let result = element;
                            Object.keys(element).forEach(keys => {
                                if(result[keys]===null){
                                    result[keys]="";
                                }
                            }); 
                            if(lenghtGroupBy>0) result[" "]=" "; 
                            return result;
                        }
                    });  
                    printJS({
                        printable : lenghtGroupBy>0 ? resultVisible                                                                                                                 : resultData,
                        properties: isReport ? convertColumnasPrint(columnsVisibles(tableRef.current.state.columns), isReport, groupByData(tableRef.current.state.columns).length>0): columnasPrint,
                        type      : 'json'
                    })
                }
            }
        },

        ...( hideIconAdd ? [] :
            ( permisos && permisos.find(permiso => permiso.id === 1) ) || !permisos ? [ //Permiso 1: Registrar
                {
                    icon: (id === 41 || id === 68 || id === 75) ? CreateNewFolder : Add, tooltip: 'Agregar',
                    isFreeAction: true,
                    onClick: (event) => {
                        setOpenAdd( true );
                    }
                }
        ] : []),

        ...( hideIconEdit ? [] :
            ( permisos && permisos.find(permiso => permiso.id === 2) ) || !permisos ? [ //Permiso 2: Modificar
                {
                    icon: (id === 33) ? Folder : Edit, tooltip: 'Editar',
                    onClick: (event, rowData) => {
                        switch(id) {
                            case 25:
                            case 27:
                                onShowTable(true, rowData.id, null);
                                break;
                            case 67:
                            case 72:
                                onShowTable(true, rowData, null);
                                break;
                            default:
                                setElemmentSelect(rowData);
                                setOpenEdit(true);
                                break;
                        }
                    }
                }
        ] : []),
        ...( showIconAux ? [
            {
                icon: AlarmOff, tooltip: 'Finalizar',
                onClick: (event, rowData) => {
                    setElemmentSelect(rowData);
                    setOpenModalForm(true);
                }
            }
        ] : []),

        ...( showIconPayment
            ?   ( permisos && (permisos.find(permiso => permiso.id === 1 || permiso.id === 2 || permiso.id === 3)) ) || !permisos
                ?   [
                        {
                            icon: Payment, tooltip: 'Procesar Nómina',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                setOpenEdit(true);
                            }
                        }
                    ]
                : []
            : []),
        ...( showIconEmployee
            ?   ( permisos && (permisos.find(permiso => permiso.id === 1 || permiso.id === 2)) ) || !permisos
                ?   [
                        {
                            icon: Person, tooltip: 'Acceso al Portal del Empleado',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                setOpenModalForm(true);
                            }
                        }
                    ]
                : []
            : []),
        ...( showIconApps ? [
            {
                icon: (id === 75) ? MonetizationOn : Apps, tooltip: 'Consultar',
                onClick: (event, rowData) => {
                    setElemmentSelect(rowData);
                    setOpenModalForm(true);
                }
            }
        ] : []),
        ...( showIconPDF ? [
            {
                icon: PictureAsPdf, tooltip: 'Descargar',
                onClick: (event, rowData) => {
                    setElemmentSelect(rowData);
                    setOpenEdit(true);
                }
            }
        ] : []),
        ...(showIconPostAdd
            ?   (permisos && (permisos.find(permiso => permiso.id === 2)) ) || !permisos
                ?   [
                        {
                            icon: PostAdd, tooltip: 'Editar Plantilla',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                onShowTable(rowData, 'editar', dataTable);
                            }
                        }
                    ]
                : []
            : []),
        ...(showIconChromeReaderMode
            ?   (permisos && (permisos.find(permiso => permiso.id === 2)) ) || !permisos
                ?   [
                        {
                            icon: ChromeReaderMode, tooltip: 'Asignar Plantilla a Empleados',
                            onClick: (event, rowData) => {
                                setElemmentSelect(rowData);
                                onShowTable(rowData, 'asignar');
                            }
                        }
                    ]
                : []
            : []),
        ...( hideIconDelete ? [] :
            ( permisos && permisos.find(permiso => permiso.id === 3) ) || !permisos ? [ //Permiso 3: Eliminar
                {
                    icon: Delete, tooltip: (id !== 30) ? 'Eliminar' : 'Baja',
                    onClick: (event, rowData) => {
                        setElemmentSelect(rowData);
                        switch(id) {
                            case 30:
                                setOpenDeleteForm(true);
                            break;
                            default:
                                setAlertWarning(true);
                            break;
                        }
                    }
                }
        ] : []),

        ...( showIconDescription ? [
            {
                icon: Description, tooltip: 'Mostrar información',
                onClick: (event, rowData) => {
                    switch(id) {
                        case 31: //ausentismos
                            onShowTable(rowData);
                            break;
                        case 34: //historial
                            setElemmentSelect(rowData);
                            setOpenInfo(true);
                            break;
                        case 41: //Movimientos Variables
                        case 68: //Movimientos Fijos
                        case 69: //Movimientos Relacionados
                        case 70: //Movimientos Pendientes
                            onShowTable( rowData );
                        break;
                        case 80: //Auditorias
                            setElemmentSelect(rowData);
                            setOpenEdit(true);
                        break;
                        default:
                            onShowTable(true, rowData, null, null);
                            break;
                    }
                }
            }
        ]: []),/*
        ...( !isReport || informacionAdicional.isStatic ? [] : [
            {
                icon: Save, tooltip: 'Guardar Configuración de Reporte',
                isFreeAction: true,
                onClick: () => {
                    setElemmentSelect([...columns, {filtroGeneral:tableRef.current.state.searchText, infoReport: informacionAdicional.infoReport? informacionAdicional.infoReport: null}]);
                    setOpenEdit(true);
                }
            }
        ]),*/
    ]
    /* -------------------------------------- contenidoActual() ------------------------------------- */
    const contenidoActual = ( propsData ) => { dataContent = propsData };
    /* ------------------- Actualizaciones de estado(Contenido) Acciones API-State ------------------ */
    const agregar = ( newElement, info = null  ) => {
        switch(id) {
            case 25:
            case 27:
                onShowTable(true, null, info);
                break;
            case 67:
            case 72:
                onShowTable(true, info, true);
                break;
            default:
                setDataTable([ newElement, ...dataTable ]);
                setTextAlert('Registro Agregado');
                setAlertSuccess(true);
                break;
        }
    }

    const editar = ( newElement ) => {
        switch(id) {
            case 48:
            case 49:
                    setTextAlert('Configuración Guardada');
                    setAlertSuccess( true );
                    onShowTable(newElement);
                break;
            default:
                    setDataTable( dataTable.map( element => element.id === newElement.id ? element = newElement : element ) );
                    setTextAlert('Registro Actualizado');
                    setAlertSuccess( true );
                break;
        }
    }

    const eliminar = ( idElement ) => {
        if (id === 67 || id === 72){
            let editELement= dataTable.findIndex(element=> element.periodoImpto === idElement.periodoImpto); 
            if(editELement !==-1){ 
                let newElement=dataTable[editELement];
                newElement.status=true;                
                setDataTable( dataTable.map( element => element.id === newElement.id ? element = newElement : element ) );
            }
            setDataTable( dataTable.filter( element => element.id !== idElement.id ) );
            setTextAlert('Registro Eliminado');
            setAlertSuccess( true ); 
        }else{
            setDataTable( dataTable.filter( element => element.id !== idElement ) );
            setTextAlert('Registro Eliminado');
            setAlertSuccess( true );
        }
        
    }
    /* ---------------------------------------------------------------------------------------------- */
    const successTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }  
    
    /* ---------------------------------------------------------------------------------------------- */
    return (
        <div className={ isReport ? "ContenedorDataTable report" : 'ContenedorDataTable'}>
            { error }
            <MuiThemeProvider theme={theme}>
                <MaterialTable 
                    title={ '' }
                    columns={ columnsTable }
                    data={ dataTable }
                    tableRef={tableRef}
                    actions={ actionsTable }
                    options={ isReport ? Object.assign(optionsTable, optionsTableReports): optionsTable }
                    localization={ localization }
                    icons={ tableIcons }    
                    components={{
                        Toolbar: props => {
                            if( props.data.length !== 0 ){ contenidoActual( props.data ) }else{ contenidoActual( [] ) }
                            return( <div className="TableroDataTable"> <MTableToolbar {...props} /> </div> )
                        },
                        Action: props => {
                            if( props.action.tooltip === 'Eliminar' && id === 19 ){
                                return(
                                    <Tooltip title="Modificar Status">
                                        <Switch
                                            checked={ props.data.status.statusNom === 'Activo' ? true : false }
                                            onChange={ (event) => props.action.onClick(event, props.data) }
                                            value="checkedA"
                                            size="small"
                                        />
                                    </Tooltip>
                                )
                            }else if(id === 25 || id === 27){
                                
                                if(props.action.tooltip === 'Eliminar'){
                                    if(props.data.fechafin==='-' || props.data.fecFin==='-') return null;
                                    return(<IconButton onClick={()=>onShowTable(true, props.data.id, null)}>
                                                <Tooltip title="Mostrar información" placement="left-start">
                                                    <Description style={{ color: "#212121" }}/>
                                                </Tooltip>
                                            </IconButton>)
                                }else if(props.action.tooltip === 'Editar' && (props.data.fechafin!=='-' && props.data.fecFin!=='-' )){
                                    return null
                                }else{
                                    return( <MTableAction {...props} /> )
                                }
                            }else if(id === 67 ||  id === 72){ 
                                if(props.action.tooltip === 'Eliminar' && !props.data.status){ 
                                    return(<IconButton onClick={()=>onShowTable(true, props.data, null)}>
                                                <Tooltip title="Mostrar información" placement="left-start">
                                                    <Description style={{ color: "#212121" }}/>
                                                </Tooltip>
                                            </IconButton>)
                                }else if(props.action.tooltip === 'Editar' && !props.data.status){
                                    return null
                                }else{
                                    return( <MTableAction {...props} /> )
                                }
                            } else{
                                return( <MTableAction {...props} /> )
                            }
                        },
                        Cell: props => {
                            if( typeof( props.value ) === 'string' && props.value.length > 60 && !isReport ){
                                return(
                                    <Tooltip title={ props.value } placement="bottom-start" arrow>
                                        <td className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft">
                                            { `${props.value.substring(0,45)} ...` }
                                        </td>
                                    </Tooltip>
                                );
                            }else{ 
                                return( <MTableCell {...props} /> );
                            }
                        },
                        GroupRow: props => { 
                            const indiceCantidad = id===48 ? columnsTable.filter(element=> element.field==='cantidad') : null;
                            const indiceMonto = id===48 ? columnsTable.filter(element=> element.field==='monto') : null;
                            const columnsHidden = id===48 ? columnsTable.filter(element=> element.hidden) : null;
                            const columnsVisibles = id===48 ? columnsTable.filter(element=> !element.hidden) : null;
                            const indiceActualCantidad = id===48 ? columnsVisibles[(indiceCantidad[0].tableData.columnOrder-columnsHidden.length+1)-1].field==='cantidad' ? (indiceCantidad[0].tableData.columnOrder-columnsHidden.length+1) : (indiceCantidad[0].tableData.columnOrder-columnsHidden.length+1)+1 : null; 
                            const indiceActualMonto = id===48 ? columnsVisibles[(indiceMonto[0].tableData.columnOrder-columnsHidden.length+1)-1].field==='monto' ? (indiceMonto[0].tableData.columnOrder-columnsHidden.length+1) : (indiceMonto[0].tableData.columnOrder-columnsHidden.length+1)+1 : null;
                            
                            return( <Fragment>
                                        <MTableGroupRow {...props} />
                                        {
                                            props.groupData.isExpanded && id===48 && indiceCantidad[0].hidden===false &&
                                            <tr className="MuiTableRow-root MuiTableCell-root" style={{transition: "all 300ms ease 0s"}}>
                                                {
                                                    indiceActualCantidad < indiceActualMonto ?
                                                    <Fragment>
                                                        <td className="MuiTableCell-body" colSpan={ indiceActualCantidad } align={'right'} style={{paddingRight: "1rem"}} >
                                                            <strong>{`${
                                                                props.groupData.data.length>0
                                                                ? 
                                                                    parseFloat(props.groupData.data.map(element => parseFloat(element.cantidad)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)
                                                                :
                                                                    parseFloat(props.groupData.groups[props.groupData.groups.length-1].data.map(element => parseFloat(element.cantidad)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)   
                                                            }`}</strong>
                                                        </td> 
                                                        <td className="MuiTableCell-body" colSpan={ indiceActualMonto - indiceActualCantidad } align={'right'} style={{paddingRight: "1rem"}}>
                                                            <strong>{`${
                                                                props.groupData.data.length>0
                                                                ? 
                                                                    parseFloat(props.groupData.data.map(element => parseFloat(element.monto)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)
                                                                :
                                                                    parseFloat(props.groupData.groups[props.groupData.groups.length-1].data.map(element => parseFloat(element.monto)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)   
                                                            }`}</strong>
                                                        </td>  
                                                    </Fragment>
                                                    :
                                                    <Fragment>
                                                        
                                                        <td className="MuiTableCell-body" colSpan={ indiceActualMonto } align={'right'} style={{paddingRight: "1rem"}}>
                                                            <strong>{`${
                                                                props.groupData.data.length>0
                                                                ? 
                                                                    parseFloat(props.groupData.data.map(element => parseFloat(element.monto)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)
                                                                :
                                                                    parseFloat(props.groupData.groups[props.groupData.groups.length-1].data.map(element => parseFloat(element.monto)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)   
                                                            }`}</strong>
                                                        </td>  
                                                        <td className="MuiTableCell-body" colSpan={ indiceActualCantidad - indiceActualMonto+1} align={'right'} style={{paddingRight: "1rem"}} >
                                                            <strong>{`${
                                                                props.groupData.data.length>0
                                                                ? 
                                                                    parseFloat(props.groupData.data.map(element => parseFloat(element.cantidad)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)
                                                                :
                                                                    parseFloat(props.groupData.groups[props.groupData.groups.length-1].data.map(element => parseFloat(element.cantidad)).reduce((accumulator, currentValue) => accumulator + currentValue)).toFixed(2)   
                                                            }`}</strong>
                                                        </td> 
                                                    </Fragment>
                                                }
                                               
                                            </tr>
                                        }  
                                        
                                    </Fragment> 
                        ); 
                        }
                    }}
                />
            </MuiThemeProvider>
            { /* ----------------------------------------------------- Modals ----------------------------------------------------- */ }
            { obtenerModalAdd( id, openAdd, setOpenAdd, agregar, editar, errorTable, successTable, informacionAdicional ) }
            { obtenerModalEdit( id, openEdit, setOpenEdit, elemmentSelect, editar, errorTable, successTable, informacionAdicional ) }
            { obtenerModalDelete( id, alertWarning, setAlertWarning, elemmentSelect, editar, eliminar, errorTable, informacionAdicional ) }
            { obtenerModalDeleteForm( id, openDeleteForm, setOpenDeleteForm, elemmentSelect, eliminar, errorTable ) }
            { obtenerModalForm( id, openModalForm, setOpenModalForm, elemmentSelect, editar, successTable, errorTable, informacionAdicional ) }
            { obtenerModalInfo( id, openInfo, setOpenInfo, elemmentSelect) }
            { /* ---------------------------------------------------- Modal PDF --------------------------------------------------- */ }
            <ModalPDF idModulo={ id } title={ title } openPDF={ openPDF } setOpenPDF={ setOpenPDF } pdfSelect={ pdfSelect }/>
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            
        </div>
    );
}

export default DataTableService;

export function obtenerAccesos( accesos ) {
    //console.log( accesos.map( acceso => acceso.modulo.id ) );
    return accesos.map( acceso => acceso.modulo.id );
}

/* Funcion para agregar los permisos correspondientes a cada accesoDirecto del servicio de dashboard */
export function agregarPermisos( modulosApi, accesos ) {
    accesos.map( acceso => acceso.modulo.permisos = obtenerPermisosByIdModulo( modulosApi, acceso.modulo.superior, acceso.modulo.id ) );
    return accesos;
}

function obtenerPermisosByIdModulo( modulosApi, idSuperior, moduloId ) {
    var permisos = [];
    const moduloSuperior = modulosApi.find( moduloSuperior => moduloSuperior.id === idSuperior );
    if( moduloSuperior ){
        const moduloHijo = moduloSuperior.children.find( moduloHijo => moduloHijo.id === moduloId );
        if( moduloHijo ){
            permisos = moduloHijo.permisos;
        }
    }
    return permisos;
}
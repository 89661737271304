import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';

import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../Constants/Constants';

const FormularioTiposAusentismos = ({ error, enviarTipoAusentismo, elementSelect, actualizarTipoAusentismo }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const claveRef = createRef('');
    const nombreRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarTipoAusentismo' ) ){
            const ausentismoCve = claveRef.current.value;
            const ausentismoNom = nombreRef.current.value
            enviarTipoAusentismo( { ausentismoCve, ausentismoNom } );
        }
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarTipoAusentismo' ) ){
            const ausentismoCve = claveRef.current.value;
            const ausentismoNom = nombreRef.current.value
            actualizarTipoAusentismo({ ausentismoCve, ausentismoNom }, elementSelect.id);
        }
    }

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarTipoAusentismo' : 'AgregarTipoAusentismo' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                <InputValidation title="Clave" type="claveAusentismo" placeholder="Escriba la clave del tipo de Ausentismo"
                    tooltipText={'Máximo 8 letras y/o números'} tooltipName={'tooltipCveTipAus'} maxLength='8' alfanumeric
                    refValue={claveRef} defaultValue={ elementSelect ? elementSelect.ausentismoCve : '' } required/>
                <InputValidation title="Nombre" type="nombreAusentismo" placeholder="Escriba el nombre del tipo de Ausentismo" maxLength='128'
                    tooltipText={'Máximo 128 letras, números, espacios y/o signos de puntuación . , - ( ) " \' #'} tooltipName={'tooltipNomTipAus'}
                    refValue={nombreRef} defaultValue={ elementSelect ? elementSelect.ausentismoNom : '' } required/>
                {error}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioTiposAusentismos;
import React, { useState, useEffect, useRef } from 'react';

import Axios from 'axios';
import { servicios_grupogack, servicios_grupogack_catalogo, tabuladores_quinquenios } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import DinamicTableQuinquenios from './DinamicTableQuinquenios';
import DataTableService from '../../../DataTableService/DataTableService'; 
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation'; 
import ConvertDate from '../../../Helpers/ConvertDate';

const TabuladoresQuinquenios = ({ id, permisos }) => {
    const [dinamicTable, setDinamicTable] =useState('');

    const [tabuladoresBack, setTabuladoresBack]= useState(null);
    const [periodosTabuladoresQuin, setPeriodosTabuladoresQuin]= useState(null);
    const [idTab, setIdTab]=useState(null);
    const fechainiRef = useRef(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupogack}/org/quinquenios/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    fechainiRef.current=res.data.dataResponse[0].fecIni;                    
                    setTabuladoresBack(res.data.dataResponse);
                    var response = res.data.dataResponse;
                    response.map(( key, index)=>{
                        for (const prop in key) {
                            if(prop!=='id' && key[prop]!==null) {
                                response[index][prop] = ConvertDate(longDateToInput(key[prop]));
                            }
                            if( key[prop]===null) response[index][prop] ="-";
                        }
                        return null;
                    }); 
                    setPeriodosTabuladoresQuin(response);
                    break;
                case 404:
                    setPeriodosTabuladoresQuin([]);
                    setTabuladoresBack([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
    },[]); 
    
    const [niveles, setNiveles] = useState(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupogack_catalogo}/nvlaboral/byrelab/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setNiveles(res.data.dataResponse);
                    break;
                case 404:
                    setNiveles([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]);

    const showTable=(value, id, fechaIn, fechaSave=null)=>{
        setIdTab(id);
        if(fechaIn!==null){
            fechainiRef.current=fechaIn;
        } 
        setDinamicTable(value);

        if(fechaSave!==null){

            if(periodosTabuladoresQuin.length>=1){
                periodosTabuladoresQuin[0].fecFin=ConvertDate(longDateToInput(fechaSave-86400000)); 
            }
            var newData={id, fecIni: ConvertDate(longDateToInput(fechaSave)), fecFin: '-'};
            setTabuladoresBack([ newData, ...tabuladoresBack ]);
            setPeriodosTabuladoresQuin([ newData, ...periodosTabuladoresQuin ]);
             
        }
        return dinamicTable; 
    } 

   return (
       <div id={ id }> 
                {
                    dinamicTable
                    ?
                    <div>
                        {<DinamicTableQuinquenios permisos={permisos} niveles={niveles} tabuladoresBack={tabuladoresBack} idTab={idTab} fechainic={fechainiRef.current} onShowTable={showTable} idModulo={ id }/>}
                   </div>
                   : 
                   <div>
                   {
                        periodosTabuladoresQuin
                        ?
                        <DataTableService 
                            id={id}
                            title={ tabuladores_quinquenios }
                            columns={[
                                { field: "fecIni", title: "Fecha de Inicio" },
                                { field: "fecFin", title: "Fecha Final" },
                            ]}
                            data={periodosTabuladoresQuin}
                            onShowTable={showTable}
                            permisos={permisos}
                            informacionAdicional={{tabulador: periodosTabuladoresQuin.find(element=> element.fecFin==='-')}}
                            />
                        :<SpinnerOval/>
                    }
                    </div>
                }
       </div>

   );
}
 
export default TabuladoresQuinquenios;





import React from 'react';

const AvisoPrivacidad = () => {
    return (
        <div className="informacion-legal">
            <p>Versión 1.0 Fecha de última actualización 01/Julio/2013</p>
            <p>AVISO DE PRIVACIDAD RELACIONADO CON LOS DATOS PERSONALES, RECABADOS POR GACK MÉXICO, S. DE R.L. DE C.V., (EN ADELANTE DENOMINADA COMO "GACK")</p>
            <p> <b>[www.GRUPO GACK.net]</b> </p>

            <h4>GENERALES.</h4>
            <p>
                <b>1.1.</b> GACK ES UNA SOCIEDAD COMPROMETIDA Y RESPETUOSA DE LOS DERECHOS SOBRE LOS DATOS PERSONALES DE LAS PERSONAS FÍSICAS, RECONOCIDOS EN EL ARTÍCULO 16 FRACCIÓN II DE LA CONSTITUCIÓN POLÍTICA DE LOS ESTADOS UNIDOS MEXICANOS,
                ASÍ COMO DE LAS DISPOSICIONES DE LA LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES, EN ADELANTE "LA LEY" POR LO ANTERIOR, PONE A SU DISPOSICIÓN EL PRESENTE AVISO DE PRIVACIDAD, EN ARAS DE QUE EL TITULAR
                DE LOS DATOS PERSONALES, SE ENCUENTRE FACULTADO A EJERCITAR SU DERECHO A LA AUTODETERMINACIÓN INFORMATIVA.
                <br/>
                <br/>
                <b>1.2.</b> AL INGRESAR Y UTILIZAR ESTE PORTAL DE INTERNET, CUYO NOMBRE DE DOMINIO ES: WWW.GRUPO GACK.COM EN ADELANTE DENOMINADO COMO "EL SITIO", PROPIEDAD DE GACK, USTED (EL TITULAR) DECLARA QUE ESTÁ ACEPTANDO LOS TÉRMINOS
                Y LAS CONDICIONES CONTENIDOS EN ESTE AVISO Y DECLARA Y OTORGA EXPRESAMENTE SU ACEPTACIÓN Y CONSENTIMIENTO UTILIZANDO PARA TAL EFECTO MEDIOS ELECTRÓNICOS, EN TÉRMINOS DE LO DISPUESTO POR EL ARTÍCULO 1803 DEL CÓDIGO CIVIL FEDERAL.
                <br/>
                <br/>
                <b>1.3.</b> SI EL TITULAR NO ACEPTA EN FORMA ABSOLUTA Y COMPLETA LOS TÉRMINOS Y CONDICIONES DE ESTE AVISO, DEBERÁ ABSTENERSE DE COMPARTIR CUALQUIER TIPO DE INFORMACIÓN A GACK, POR CUALQUIER MEDIO INCLUYENDO "EL SITIO".
                <br/>
                <br/>
                <b>1.4.</b> PARA EL CASO QUE EL TITULAR CONTINÚE EN EL USO DE "EL SITIO" SEA EN FORMA TOTAL O PARCIAL, DICHA ACCIÓN SE CONSIDERARÁ COMO SU ABSOLUTA Y EXPRESA ACEPTACIÓN A LOS TÉRMINOS Y CONDICIONES AQUÍ ESTIPULADOS.
                <br/>
                <br/>
                <b>1.5.</b> LA SOLA UTILIZACIÓN DE LA PÁGINA DE INTERNET LE OTORGA AL PÚBLICO TITULAR (EN ADELANTE REFERIDO COMO EL "TITULAR" O LOS "TITULARES") E IMPLICA LA ACEPTACIÓN, PLENA E INCONDICIONAL, DE TODAS Y CADA UNA DE LAS CONDICIONES
                GENERALES Y PARTICULARES INCLUIDAS EN ESTE AVISO DE PRIVACIDAD EN LA VERSIÓN PUBLICADA POR GACK, EN EL MOMENTO MISMO EN QUE EL TITULAR ACCEDA A LA PÁGINA.
                <br/>
                <br/>
                LAS PARTES ACUERDAN QUE AL NO EXISTIR, ERROR, DOLO, MALA FE O CUALQUIER OTRO VICIO DE LA VOLUNTAD QUE PUDIERA NULIFICAR LA VALIDEZ DEL PRESENTE INSTRUMENTO, AMBAS ACUERDAN EN SUJETARSE AL TENOR DE LO ESTIPULADO EN LOS SIGUIENTES:
                <br/>
            </p>

            <h6>2. DEFINICIONES</h6>
            <p>
                <b>2.1.</b> TITULAR.- LA PERSONA FÍSICA (TITULAR) A QUIEN IDENTIFICAN O CORRESPONDEN LOS DATOS PERSONALES.
                <br/>
                <br/>
                <b>2.2.</b> DATOS PERSONALES SENSIBLES.- AQUELLOS DATOS PERSONALES QUE AFECTEN A LA ESFERA MÁS ÍNTIMA DE SU TITULAR, O CUYA UTILIZACIÓN INDEBIDA PUEDA DAR ORIGEN A DISCRIMINACIÓN O CONLLEVE UN RIESGO GRAVE PARA ÉSTE.
                <br/>
                <br/>
                <b>2.3.</b> RESPONSABLE. PERSONA FÍSICA O MORAL DE CARÁCTER PRIVADO QUE DECIDE SOBRE EL TRATAMIENTO DE LOS DATOS PERSONALES.
                <br/>
                <br/>
                <b>2.4.</b> ENCARGADO.- EL ENCARGADO ES LA PERSONA FÍSICA O MORAL, PÚBLICA O PRIVADA, AJENA A LA ORGANIZACIÓN DEL RESPONSABLE, QUE SOLA O CONJUNTAMENTE CON OTRAS, TRATA DATOS PERSONALES POR CUENTA DEL RESPONSABLE, COMO CONSECUENCIA
                DE LA EXISTENCIA DE UNA RELACIÓN JURÍDICA QUE LE VINCULA CON EL MISMO Y DELIMITA EL ÁMBITO DE SU ACTUACIÓN PARA LA PRESTACIÓN DE UN SERVICIO.
                <br/>
                <br/>
                <b>2.5.</b> TRATAMIENTO.- LA OBTENCIÓN, USO (QUE INCLUYE EL ACCESO, MANEJO, APROVECHAMIENTO, TRANSFERENCIA O DISPOSICIÓN DE DATOS PERSONALES), DIVULGACIÓN O ALMACENAMIENTO DE DATOS PERSONALES POR CUALQUIER MEDIO.
                <br/>
                <br/>
                <b>2.5.1.</b> TRANSFERENCIA.- TODA COMUNICACIÓN DE DATOS REALIZADA A PERSONA DISTINTA DEL RESPONSABLE O ENCARGADO DEL TRATAMIENTO.
                <br/>
                <br/>
                <b>2.6.</b> DERECHOS ARCO.- DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN Y OPOSICIÓN.
                <br/>
                <br/>
                <b>2.7.</b> CONSENTIMIENTO TÁCITO.- SE ENTENDERÁ QUE EL TITULAR HA CONSENTIDO EN EL TRATAMIENTO DE LOS DATOS, CUANDO HABIÉNDOSE PUESTO A SU DISPOSICIÓN EL AVISO DE PRIVACIDAD, NO MANIFIESTE SU OPOSICIÓN.
                <br/>
                <br/>
                <b>2.9.</b> FINALIDADES PRIMARIAS.- AQUELLAS FINALIDADES PARA LAS CUALES SE SOLICITAN PRINCIPALMENTE LOS DATOS PERSONALES Y POR LO QUE SE DA ORIGEN A LA RELACIÓN ENTRE GACK Y EL TITULAR.
                <br/>
                <br/>
                <b>2.10.</b> FINALIDADES SECUNDARIAS.- AQUELLAS FINALIDADES QUE NO SON IMPRESCINDIBLES PARA LA RELACIÓN ENTRE GACK Y EL TITULAR, PERO QUE CON SU TRATAMIENTO CONTRIBUYE AL CUMPLIMIENTO DEL OBJETO SOCIAL DE GACK.
                <br/>
            </p>

            <h6>3. IDENTIDAD Y DOMICILIOS DE LOS RESPONSABLES QUE RECABAN LOS DATOS PERSONALES.</h6>
            <p>
                EL RESPONSABLE DE LA OBTENCIÓN DE LOS DATOS PERSONALES ES GACK, S. DE R.L. DE C.V. (GACK) QUIEN SE COMPROMETE A RESPETAR LO ESTABLECIDO EN EL PRESENTE AVISO DE PRIVACIDAD (EN LO SUCESIVO EL " AVISO"), MISMO QUE ESTÁ PUESTO A
                SU DISPOSICIÓN EN CUMPLIMIENTO A LO ESTABLECIDO EN LA "LA LEY" Y, ES APLICABLE RESPECTO A LOS DATOS PERSONALES DE LAS PERSONAS FÍSICAS, QUE GACK OBTIENE CON MOTIVO DE LAS ACTIVIDADES QUE REALIZA CON LOS CLIENTES Y, USUARIOS DE
                "EL SITIO". EL DOMICILIO PARA LOS EFECTOS DEL PRESENTE AVISO, ESTABLECE GACK, ES EL UBICADO EN: GOBERNADOR AGUSTÍN VICENTE EGUIA 46 PISO 10, SAN MIGUEL CHAPULTEPEC I SECCIÓN, C.P. 11850, DELEGACIÓN MIGUEL HIDALGO, CIUDAD DE MÉXICO.
                <br/>
            </p>

            <h6>4. DATOS QUE SE RECABAN.</h6>
            <p>
                EL TITULAR RECONOCE Y ACEPTA QUE GACK, OBTENDRÁ DIRECTAMENTE LOS SIGUIENTES DATOS PERSONALES: GENERALES Y PATRIMONIALES. NOMBRE COMPLETO, FIRMA, CORREO ELECTRÓNICO, NOMBRES DE USUARIO EN REDES SOCIALES (SKY-PE), DOMICILIO,
                DOMICILIO FISCAL, TELÉFONO LOCAL Y/O MÓVIL, FAX, EDAD, REGISTRO FEDERAL DE CONTRIBUYENTES, USUARIO DE ACCESO A EL SITIO Y CURP. ASIMIMO, GACK PODRÁ SOLICITAR LOS SIGUIENTES DOCUMENTOS EN COPIA: IDENTIFICACIÓN OFICIAL VIGENTE,
                CÉDULA DE REGISTRO FEDERAL DE CONTRIBUYENTES, R1 Y R2 (ALTA ANTE SHCP), COMPROBANTE DE DOMICILIO Y COPIA DEL ESTADO DE CUENTA, COMPROBANTES DE ESTUDIOS Y CARTAS DE RECOMENDACIÓN.
                <br/>
                <br/>
                <b>4.1.</b> EL TITULAR EN ESTE ACTO, OTORGA SU CONSENTIMIENTO EXPRESO EN TÉRMINOS DEL ARTÍCULO 9 DE LA LFPDPPP, PARA QUE GACK, TRATE SUS DATOS PERSONALES, INCLUIDOS LOS DENOMINADOS FINANCIEROS Y/O PATRIMONIALES CONTENIDOS EN
                ÉSTA CLÁUSULA, PARA CUMPLIR CON LAS FINALIDADES QUE ESTABLECE EL PRESENTE AVISO DE PRIVACIDAD.
                <br/>
                <br/>
                <b>4.2.</b> GACK, MANIFIESTA QUE PODRÁ OBTENER LOS DATOS PERSONALES DE EL TITULAR MEDIANTE LAS DENOMINADAS FUENTES DE ACCESO PÚBLICO, A EFECTO DE VALIDAR Y ACTUALIZAR LOS DATOS DE EL TITULAR, RESPETANDO EN TODO MOMENTO LA EXPECTATIVA
                RAZONABLE DE PRIVACIDAD, A QUE SE REFIERE EL ARTÍCULO 7 DE LA LFPDPPP.
                <br/>
                <br/>
                <b>4.3.</b> GACK, USARÁ INFORMACIÓN IP (PROTOCOLO DE INTERNET, POR SUS SIGLAS EN INGLÉS INTERNET PROTOCOLE) PARA ANALIZAR CUALQUIER TIPO DE AMENAZAS A "EL SITIO", ASÍ COMO PARA RECABAR INFORMACIÓN DEMOGRÁFICA. SIN EMBARGO,
                LA INFORMACIÓN IP, EN NINGÚN CASO SERÁ UTILIZADA PARA IDENTIFICAR A LOS TITULARES, EXCEPTO CUANDO HAYA PROBABILIDADES DE ACTIVIDAD FRAUDULENTA.
                <br/>
            </p>

            <h6>5. USO DE "COOKIES" Y "WEB BEACONS".</h6>
            <p>
                <b>5.1.</b> GACK, RECONOCE QUE ES POSIBLE QUE "EL SITIO" HAGA USO DE "COOKIES" EN CONEXIÓN CON CIERTAS CARACTERÍSTICAS O FUNCIONES. LAS COOKIES SON TIPOS ESPECÍFICOS DE INFORMACIÓN QUE UN SITIO WEB TRANSMITE AL DISCO DURO DE LA
                COMPUTADORA DEL TITULAR CON EL FIN DE MANTENER LOS REGISTROS. LAS COOKIES PUEDEN SERVIR PARA FACILITAR EL USO DE UN SITIO WEB, AL GUARDAR CONTRASEÑAS Y PREFERENCIAS MIENTRAS EL TITULAR NAVEGA EN INTERNET.
                <br/>
                <br/>
                <b>5.2.</b> "EL SITIO" NO USA O GUARDAN COOKIES PARA OBTENER DATOS DE IDENTIFICACIÓN PERSONAL DE LA COMPUTADORA DEL TITULAR QUE NO SE HAYAN ENVIADO ORIGINALMENTE COMO PARTE DE LA COOKIE.
                <br/>
                <br/>
                <b>5.3.</b> POR SU PARTE, LAS "WEB BEACONS" SON IMÁGENES INSERTADAS EN UNA PÁGINA DE INTERNET O CORREO ELECTRÓNICO, QUE PUEDE SER UTILIZADO PARA MONITOREAR EL COMPORTAMIENTO DE UN VISITANTE, COMO ALMACENAR INFORMACIÓN SOBRE LA
                DIRECCIÓN IP DEL USUARIO, DURACIÓN DEL TIEMPO DE INTERACCIÓN EN DICHA PÁGINA Y EL TIPO DE NAVEGADOR UTILIZADO, ENTRE OTROS.
                <br/>
                <br/>
                <b>5.4.</b> AUNQUE LA MAYORÍA DE LOS NAVEGADORES ACEPTAN "COOKIES" Y "WEB BEACONS" AUTOMÁTICAMENTE, EL TITULAR PUEDE CONFIGURAR SU NAVEGADOR PARA QUE NO LOS ACEPTE.
                <br/>
                <br/>
                <b>5.5.</b> PARA DESACTIVAR LAS "COOKIES", DEBE SEGUIR LAS SIGUIENTES INSTRUCCIONES:
                <br/>
            </p>
                <ul className="ulclass">
                    <li type="square">INTERNET EXPLORER: http://support.microsoft.com/kb/283185</li>
                    <li type="square">FIREFOX: http://support.mozilla.org/es/kb/habilitar%20y%20deshabilitar%20cookies?s=deshabilitar+cookies</li>
                    <li type="square">OPERA: http://help.opera.com/windows/11.60/en/cookies.html;</li>
                    <li type="square">SAFARI IPAD: http://manuals.info.apple.com/en_us/ipad_ios4_user_guide.pdf;</li>
                    <li type="square">SAFARI IPHONE: http://manuals.info.apple.com/en_us/iphone_ios3.1_user_guide.pdf;</li>
                    <li type="square">CHROME: http://support.google.com/chrome/bin/answer.py?hl=es</li>
                </ul>
            

            <h6>6. FINALIDADES DEL TRATAMIENTO DE LOS DATOS PERSONALES.</h6>
            <p>
                <b>6.1.</b> GACK ACEPTA Y RECONOCE QUE PODRÁ TRATAR LOS DATOS PERSONALES DE EL TITULAR, DE CONFORMIDAD AL TIPO DE RELACIÓN QUE TIENE CON ÉSTE, PARA LAS SIGUIENTES FINALIDADES PRIMARIAS:
                <br/>
                <br/>
                <b>A) EL TITULAR -VISITANTE</b>
                <br/>1. IDENTIFICAR Y MONITOREAR MEDIANTE VIDEO Y/O AUDIO SIN GRABACIÓN A LAS PERSONAS QUE SE ENCUENTREN EN LAS INSTALACIONES DE GACK.
                <br/>2. LLEVAR A CABO DIRECTAMENTE O A TRAVÉS DEL ENCARGADO EL REGISTRO DE LOS INGRESOS Y SALIDAS DE LOS VISITANTES A SUS INSTALACIONES A TRAVÉS DE UN LIBRO DE REGISTRO FOLIADO.
                <br/>3. ALMACENAR EN UNA BASE DE DATOS LOS REGISTROS POR INGRESOS Y SALIDAS DE LAS INSTALACIONES DE GACK, HASTA POR UN PERIODO MÁXIMO DE 12-DOCE MESES A PARTIR DE QUE SE TERMINE EL ESPACIO EN EL LIBRO DE REGISTRO.
                <br/>
                <br/>
                <b>B) EL TITULAR -CLIENTE</b>
                <br/>1. CONTACTARLO VÍA TELEFÓNICA O POR CORREO ELECTRÓNICO, PARA ATENDER Y DAR SEGUIMIENTO LAS SOLICITUDES DE SERVICIOS Y/O PRODUCTOS QUE OFRECE GACK.
                <br/>2. ASIGNAR UN USUARIO PARA CONSULTA DE INFORMACIÓN EN LINEA A TRAVÉS DE EL SITIO.
                <br/>3. REGISTRAR LOS DATOS DE CONTACTO EN LOS SOFTWARES DE CARÁCTER CONTABLE, PARA EL ACCESO A LOS DATOS PERSONALES POR LAS PERSONAS AUTORIZADAS PARA ELLO.
                <br/>4. ELABORACIÓN DE FACTURACIÓN ELECTRÓNICA Y NOTAS DE CRÉDITO DERIVADAS DE LAS COMPRAS DE LOS PRODUCTOS QUE COMERCIALIZA GACK.
                <br/>5. REALIZAR TRANSACCIONES CON INSTITUCIONES DE CRÉDITO, QUE RESULTEN CON MOTIVO DE LAS OPERACIONES COMERCIALES.
                <br/>6. SOLICITAR REFERENCIAS EN EL BURÓ DE CRÉDITO.
                <br/>
                <br/>
                <b>C) EL TITULAR -PROVEEDOR O COLABORADORES</b>
                <br/>1. CONTACTARLO VÍA TELEFÓNICA O POR CORREO ELECTRÓNICO, PARA DAR SEGUIMIENTO A LAS SOLICITUDES DE SERVICIOS Y/O PRODUCTOS QUE REQUIERE GACK.
                <br/>2. REQUERIR COTIZACIONES SOBRE LOS PRODUCTOS O SERVICIOS QUE REQUIERE GACK.
                <br/>3. REGISTRARLOS EN EL SOFTWARE ADMINISTRATIVO UTILIZADO POR GACK, PARA EL ACCESO A LOS DATOS PERSONALES POR LAS PERSONAS AUTORIZADAS PARA ELLO.
                <br/>4. REALIZAR TRANSFERENCIAS BANCARIAS, CON MOTIVO DE LAS REQUISICIONES QUE LE HAGA GACK.
                <br/>5. GENERAR UN REGISTRO EN LA BASE DE DATOS DE "PROVEEDORES" O "COLABORADORES" DE GACK.
                <br/>6. LLEVAR UN EXPEDIENTE FÍSICO DE CADA UNO DE LOS TITULARES-PROVEEDORES DE GACK.
                <br/>
                <br/>
                <b>D) EL TITULAR – USUARIO DE "EL SITIO"</b>
                <br/>1. CONTACTAR VÍA TELEFÓNICA O POR CORREO ELECTRÓNICO A EL TITULAR-USUARIO, PARA ATENDER Y DAR SEGUIMIENTO A SUS COMENTARIOS O SOLICITUDES A TRAVÉS DE LOS MEDIOS DE CONTACTO DETERMINADOS EN "EL SITIO".
                <br/>2. PARA PERMITIRLE INICIAR SESIÓN Y DESCARGAR DOCUMENTOS DE ACUERDO A LOS PRIVILEGIOS DE CADA UNO DE LOS USUARIOS.
                <br/>3. PARA CREARLE UN PERFIL CON BASE EN LOS DATOS QUE HA PROPORCIONADO EN "EL SITIO".
                <br/>
                <br/>
                <b>6.2.</b> GACK PODRÁ TRATAR LOS DATOS PERSONALES DEL TITULAR PARA LAS SIGUIENTES FINALIDADES SECUNDARIAS:
                <br/>1. PARA EL CASO DEL TITULAR-CLIENTE, EVALUAR LA CALIDAD DE LOS SERVICIOS OFRECIDOS POR GACK, ASÍ COMO LA CALIDAD DE ATENCIÓN A SUS COMENTARIOS O SOLICITUDES.
                <br/>2. PARA ENVIARLES COMUNICACIONES CON FINES PUBLICITARIOS O MERCADOTÉCNICOS RESPECTO DE LOS PRODUCTOS O SERVICIOS QUE OFRECE GACK.
                <br/>3. REALIZAR ESTUDIOS INTERNOS SOBRE HÁBITOS DE CONSUMO.
                <br/>4. PARA PROPORCIONARLE COMUNICACIONES ADICIONALES, INFORMACIÓN Y PROMOCIONES, TALES COMO BOLETINES INFORMATIVOS E INVITACIONES A EVENTOS.
                <br/>5. INFORMAR VÍA CORREO ELECTRÓNICO SOBRE CAMBIOS O NUEVOS PRODUCTOS QUE ESTÉN RELACIONADOS CON EL SERVICIO SOLICITADO POR EL TITULAR.
                <br/>6. PARA CONTACTAR AL TITULAR-USUARIO A FIN DE INFORMARLE DE ACTUALIZACIONES DE "EL SITIO", MENSAJES INFORMATIVOS Y RELATIVOS A SERVICIOS, INCLUYENDO ACTUALIZACIONES DE SEGURIDAD IMPORTANTES.
                <br/>
                <br/>
                <b>6.3.</b> EN CASO DE QUE EL TITULAR NO DESEE QUE SUS DATOS PERSONALES SEAN UTILIZADOS PARA TODAS O ALGUNAS DE LAS FINALIDADES SECUNDARIAS QUE SE ESTABLECEN EN EL APARTADO 6.2., DEBERÁ ENVIAR UNA SOLICITUD DE ELIMINACIÓN DE SUS DATOS,
                ESPECIFICANDO LAS FINALIDADES PARA LAS QUE DESEA QUE NO SEAN TRATADOS SUS DATOS PERSONALES, A LOS SIGUIENTES CORREOS ELECTRÓNICOS:
                <br/>
                <br/>
                arios@riosabogados.com
                <br/>
                <br/>
                ASIMISMO, GACK INFORMA AL TITULAR QUE, SALVO LAS EXCEPCIONES QUE SE DESCRIBEN EN EL PUNTO 6.1 Y 6.2 DEL PRESENTE AVISO, TODOS LOS DATOS PERSONALES ALMACENADOS TANTO EN SOPORTES FÍSICOS Y/O ELECTRÓNICOS,
                SERÁN ALMACENADOS POR 10-DIEZ AÑOS, UNA VEZ QUE CONCLUYA LA FINALIDAD PARA LA QUE FUERON RECABADOS, PARA SU POSTERIOR DESTRUCCIÓN.
                <br/>
            </p>

            <h6>7. LIMITACIONES PARA EL ACCESO Y DIVULGACIÓN DE LOS DATOS PERSONALES.</h6>
            <p>
                GACK, SE COMPROMETE A REALIZAR SU MEJOR ESFUERZO PARA PROTEGER LA SEGURIDAD DE LOS DATOS PERSONALES QUE EL TITULAR LE ESTÁ ENTREGANDO, MEDIANTE LA CELEBRACIÓN DE ACTOS JURÍDICOS, EL USO DE TECNOLOGÍAS QUE CONTROLEN EL ACCESO,
                USO O DIVULGACIÓN SIN AUTORIZACIÓN DE LA INFORMACIÓN PERSONAL; PARA TAL EFECTO, SE ALMACENA LA INFORMACIÓN PERSONAL EN BASES DE DATOS CON ACCESO LIMITADO QUE SE ENCUENTRAN EN INSTALACIONES CONTROLADAS CON MECANISMOS DE SEGURIDAD;
                GACK, SE COMPROMETE A QUE LA INFORMACIÓN PROPORCIONADA POR EL TITULAR, SEA CONSIDERADA CON CARÁCTER CONFIDENCIAL, Y UTILIZADA BAJO PLENA PRIVACIDAD.
                <br/>
                <br/>
                EN ESTE TENOR, GACK SE OBLIGA A TOMAR LAS MEDIDAS NECESARIAS PARA GARANTIZAR QUE LOS ENCARGADOS QUE UTILICE CUMPLAN CON LO ESTABLECIDO EN EL PRESENTE AVISO DE PRIVACIDAD.
                <br/>
            </p>
            
            <h6>8. RESPONSABLE DE TRAMITAR LAS SOLICITUDES.</h6>
            <p>
                EN CASO DE QUE EL TITULAR NECESITE REVOCAR SU CONSENTIMIENTO, ASÍ COMO ACCEDER, RECTIFICAR, CANCELAR, OPONERSE AL TRATAMIENTO DE LOS DATOS PERSONALES QUE HA PROPORCIONADO, LO DEBERÁ HACER A TRAVÉS DE LA PERSONA DESIGNADA POR GACK,
                CUYOS DATOS SE DESCRIBEN A CONTINUACIÓN:
                <br/>
                <br/>RESPONSABLE: LIC. AGUSTÍN RÍOS AGUILAR
                <br/>CORREO ELECTRÓNICO: arios@riosabogados.com
                <br/>
            </p>

            <h6>9. MEDIOS PARA REVOCAR EL CONSENTIMIENTO.</h6>
            <p>
                EL TITULAR DE LOS DATOS PERSONALES PODRÁ REVOCAR EL CONSENTIMIENTO QUE SE OTORGA CON LA ACEPTACIÓN DEL PRESENTE. DICHA REVOCACIÓN DEL CONSENTIMIENTO QUE SE OTORGA POR MEDIOS ELECTRÓNICOS, SE DEBERÁ DE HACER OBSERVANDO EL SIGUIENTE
                PROCEDIMIENTO:
                <br/>
                <br/>
                <b>9.1.</b> ENVIAR UN CORREO ELECTRÓNICO EN ATENCIÓN AL RESPONSABLE, DESIGNADO EN EL PUNTO 8-OCHO DEL PRESENTE AVISO, MEDIANTE EL CUAL SERÁN ATENDIDAS DICHAS SOLICITUDES.
                <br/>
                <br/>
                <b>9.2.</b> ENVIAR UNA SOLICITUD O MENSAJE DE DATOS AL CORREO ELECTRÓNICO ANTES PRECISADO, EN EL QUE SEÑALE:
                <br/>
                <br/>
                <b>9.2.1.</b> EL NOMBRE COMPLETO DEL TITULAR, DOMICILIO Y CORREO ELECTRÓNICO PARA RECIBIR LA RESPUESTA QUE SE GENERE CON MOTIVO DE SU SOLICITUD;
                <br/>
                <br/>
                <b>9.2.2.</b> EL MOTIVO DE SU SOLICITUD;
                <br/>
                <br/>
                <b>9.2.3.</b> LOS ARGUMENTOS QUE SUSTENTEN SU SOLICITUD O PETICIÓN;
                <br/>
                <br/>
                <b>9.2.4.</b> DOCUMENTO OFICIAL QUE ACREDITE SU IDENTIDAD Y QUE DEMUESTRE QUE ES QUIEN DICE SER; Y
                <br/>
                <br/>
                <b>9.2.5.</b> FECHA A PARTIR DE LA CUAL, SE HACE EFECTIVA LA REVOCACIÓN DE SU CONSENTIMIENTO.
                <br/>
                <br/>
                <b>9.3.</b> GACK NOTIFICARÁ A EL TITULAR, EN UN PLAZO MÁXIMO DE 20 (VEINTE) DÍAS, CONTADOS DESDE LA FECHA EN QUE SE RECIBIÓ LA SOLICITUD SOBRE EL EJERCICIO DE LOS DERECHOS ARCO, LA RESOLUCIÓN ADOPTADA, A EFECTO DE QUE,
                SI RESULTA PROCEDENTE, SE HAGA EFECTIVA LA MISMA DENTRO DE LOS 15 (QUINCE) DÍAS SIGUIENTES A LA FECHA EN QUE SE COMUNICA LA RESPUESTA, MEDIANTE UN MENSAJE QUE CONTENGA QUE HA EJECUTADO DE TODOS LOS ACTOS TENDIENTES A NO TRATAR LOS DATOS
                PERSONALES DE EL TITULAR.
                <br/>
            </p>

            <h6>10. MEDIOS PARA EJERCER LOS DERECHOS ARCO.</h6>
            <p>
                EN CASO DE QUE EL TITULAR NECESITE ACCEDER, RECTIFICAR, CANCELAR U OPONERSE A LOS DATOS PERSONALES QUE HA PROPORCIONADO A GACK, EL TITULAR DEBERÁ SEGUIR EL SIGUIENTE PROCEDIMIENTO:
                <br/>
                <br/>
                <b>10.1.</b> ENVIAR UN CORREO ELECTRÓNICO EN ATENCIÓN AL RESPONSABLE, DESIGNADO EN EL PUNTO 8-OCHO DEL PRESENTE AVISO, MEDIANTE EL CUAL SERÁN ATENDIDAS DICHAS SOLICITUDES, SEÑALANDO LO SIGUIENTE:
                <br/>
                <br/>
                <b>10.2.1.</b> EL NOMBRE COMPLETO DEL TITULAR, DOMICILIO Y CORREO ELECTRÓNICO PARA RECIBIR LA RESPUESTA QUE SE GENERE CON MOTIVO DE SU SOLICITUD;
                <br/>
                <br/>
                <b>10.2.2.</b> EL MOTIVO DE SU SOLICITUD;
                <br/>
                <br/>
                <b>10.2.3.</b> LOS ARGUMENTOS QUE SUSTENTEN SU SOLICITUD O PETICIÓN;
                <br/>
                <br/>
                <b>10.2.4.</b> DOCUMENTO OFICIAL QUE ACREDITE SU IDENTIDAD Y QUE DEMUESTRE QUE ES QUIEN DICE SER;
                <br/>
                <br/>
                <b>10.2.5.</b> DESCRIPCIÓN CLARA Y PRECISA DE LOS DATOS PERSONALES RESPECTO DE LOS QUE SE BUSCA EJERCER ALGUNO DE LOS DERECHOS ARCO, Y CUALQUIER OTRO ELEMENTO O DOCUMENTO QUE FACILITE LA LOCALIZACIÓN DE LOS DATOS PERSONALES.
                <br/>
                <br/>
                <b>10.2.6.</b> TRATÁNDOSE DE SOLICITUDES DE RECTIFICACIÓN DE DATOS PERSONALES, EL TITULAR DEBERÁ INDICAR, ADEMÁS DE LO SEÑALADO, LAS MODIFICACIONES A REALIZARSE Y APORTAR LA DOCUMENTACIÓN QUE SUSTENTE SU PETICIÓN.
                <br/>
                <br/>
                <b>10.3.</b> GACK NOTIFICARÁ AL TITULAR, EN UN PLAZO MÁXIMO DE 20 (VEINTE) DÍAS CONTADOS DESDE LA FECHA EN QUE SE RECIBIÓ LA SOLICITUD DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN, LA RESOLUCIÓN ADOPTADA, A EFECTO DE QUE,
                SI RESULTA PROCEDENTE, SE HAGA EFECTIVA LA MISMA DENTRO DE LOS 15 (QUINCE) DÍAS SIGUIENTES A LA FECHA EN QUE SE COMUNICA LA RESPUESTA. TRATÁNDOSE DE SOLICITUDES DE ACCESO A DATOS PERSONALES, PROCEDERÁ LA ENTREGA PREVIA ACREDITACIÓN
                DE LA IDENTIDAD DEL SOLICITANTE O REPRESENTANTE LEGAL, SEGÚN CORRESPONDA.
                <br/>
            </p>

            <h6>11. TRANSFERENCIA DE DATOS PERSONALES.</h6>
            <p>
                GACK PODRÁ TRANSFERIRSE EN FORMA RECÍPROCA ENTRE LAS EMPRESAS RESPONSABLES LOS DATOS PERSONALES DEL TITULAR, ASIMISMO, PODRÁN REALIZAR TRANSFERENCIAS A FAVOR DE AUTORIDADES (SHCP) QUE RESULTEN NECESARIAS PARA EL CUMPLIMIENTO DE LAS
                FINALIDADES DESCRITAS EN EL PRESENTE AVISO.
                <br/>
                <br/>
                ADICIONALMENTE, GACK, SE OBLIGA A NO TRANSFERIR O COMPARTIR LOS DATOS A QUE SE REFIERE EL PRESENTE AVISO, A FAVOR DE TERCEROS, SALVO EN LOS CASOS QUE RESULTE NECESARIO PARA CUMPLIR CON LAS FINALIDADES DEL PRESENTE AVISO.
                <br/>
                <br/>
                NO OBSTANTE LO ANTERIOR Y, EN CASO DE QUE SE PRESENTEN VULNERACIONES DE SEGURIDAD OCURRIDAS EN CUALQUIER FASE DEL TRATAMIENTO, QUE AFECTEN DE FORMA SIGNIFICATIVA LOS DERECHOS PATRIMONIALES O MORALES DE LOS TITULARES, ÉSTOS SERÁN
                INFORMADOS POR CORREO ELECTRÓNICO, DE FORMA INMEDIATA, A FIN DE QUE ESTOS ÚLTIMOS PUEDAN TOMAR LAS MEDIDAS CORRESPONDIENTES A LA DEFENSA DE SUS DERECHOS, DESLINDANDO DE CUALQUIER RESPONSABILIDAD A GACK, SI LA VULNERACIÓN NO ES
                IMPUTABLE A ÉL.
                <br/>
            </p>
            
            <h6>12. MODIFICACIONES.</h6>
            <p>
                LAS PARTES ACUERDAN QUE EL AVISO DE PRIVACIDAD, PUEDE SER MODIFICADO EN EL TIEMPO Y FORMA QUE GACK LO DETERMINE, ATENDIENDO AL ESTUDIO Y LAS REGULACIONES EN MATERIA DE PROTECCIÓN DE DATOS PERSONALES SURJAN, POR LO QUE SE OBLIGA A
                MANTENER ACTUALIZADO EL PRESENTE AVISO, PARA SU CONSULTA EN "EL SITIO", A EFECTO DE QUE EN SU CASO, EL TITULAR SE ENCUENTRE EN POSIBILIDAD DE EJERCER SUS DERECHOS ARCO.
                <br/>
            </p>

            <h6>13. LEY APLICABLE Y JURISDICCIÓN</h6>
            <p>
                LAS PARTES EXPRESAN QUE EL PRESENTE AVISO, SE REGIRÁ POR LAS DISPOSICIONES LEGALES APLICABLES EN LA REPÚBLICA MEXICANA, EN ESPECIAL, POR LO DISPUESTO EN LA "LA LEY" Y SU REGLAMENTO. PARA EL CASO DE QUE EXISTA UNA DISPUTA O CONTROVERSIA,
                DERIVADA DE LA INTERPRETACIÓN, EJECUCIÓN O CUMPLIMIENTO DEL AVISO O DE CUALQUIERA DE LOS DOCUMENTOS QUE DEL MISMO SE DERIVEN, O QUE GUARDEN RELACIÓN CON ÉSTE , LAS PARTES AMIGABLEMENTE, BUSCARÁN LLEGAR A UN ACUERDO DENTRO DE UN PLAZO
                DE TREINTA (30) DÍAS NATURALES, CONTADOS A PARTIR DE LA FECHA EN QUE SURJA CUALQUIER DIFERENCIA Y SE NOTIFIQUE POR ESCRITO SOBRE DICHO EVENTO A LA CONTRAPARTE, DEDUCIENDO EL PROCESO DE MEDIACIÓN ANTE EL CENTRO DE JUSTICIA ALTERNATIVA
                DEL DISTRITO FEDERAL, LLEVÁNDOSE AL AMPARO DE LA LEY DE JUSTICIA ALTERNATIVA DEL TRIBUNAL SUPERIOR DE JUSTICIA DEL DISTRITO FEDERAL Y SU REGLAMENTO INTERNO, VIGENTE AL MOMENTO DE QUE SE PRESENTE LA CONTROVERSIA. EN CASO DE QUE LAS PARTES
                NO LLEGUEN A UN ACUERDO, CONVIENEN EN ESTE ACTO EN SOMETER TODAS LAS DESAVENENCIAS QUE DERIVEN DEL PRESENTE AVISO O DE CUALQUIERA DE LOS DOCUMENTOS QUE DEL MISMO SE DERIVEN, O QUE GUARDEN RELACIÓN CON ÉSTE O CON AQUÉLLOS,
                SERÁN RESUELTAS DE MANERA DEFINITIVA SE SOMETEN A LA COMPETENCIA Y LEYES DE LAS AUTORIDADES ADMINISTRATIVAS FEDERALES O TRIBUNALES DE LA CIUDAD DE MÉXICO, DISTRITO FEDERAL RENUNCIANDO EXPRESAMENTE A CUALQUIER FUERO DISTINTO QUE POR
                RAZONES DE SUS DOMICILIOS PRESENTES O FUTUROS PUDIEREN CORRESPONDERLES.
                <br/>
            </p>
        </div>
    );
};

export default AvisoPrivacidad;
import React, { useState, useEffect } from 'react';
import TooltipComponent from '../Tooltip/TooltipComponent';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

const SelectValidation = ({ title, label, arrayOpciones, keyOpcion, refValue, defaultValue, onChangeExecute, tooltipText, disabled, required, size, optional }) => {
    const [ valid, setValid ] = useState( null );
    const isSafari = (navigator.userAgent.toLowerCase().includes('safari') && navigator.vendor.toLowerCase().includes('apple'));    
    
    useEffect(() => {
        if(refValue) {
            if(refValue.current.value === '') setValid(null);
        }
        
    }, [refValue]);

    useEffect(() => {
        if(optional === true & refValue.current.value === '') {
            setValid(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refValue]);

    const validar = () => {
        if( optional === true && refValue.current.value === '' ){
            setValid(null);
        }else{
            setValid( true );
            if( onChangeExecute ){
                if(refValue.current.value === '') setValid(null);
                onChangeExecute( refValue.current.value );
            }
        }
    }

    return (
        <div className={`form-group ${ optional === true && valid === null ? '' : 'validar' }`}>
            {   title &&
                <label>
                    { title }{ (required) ? <strong className="requerido">* </strong> : (title === " ") ? <div className="requeridoWhitout"></div> : null }
                    { tooltipText &&
                        <TooltipComponent tooltipText={tooltipText}>
                            <span className={isSafari ? "spanInfoSelect" : "spanInfo"}><InfoRoundedIcon color="disabled"/></span>
                        </TooltipComponent>
                    }
                </label>
            }
            <select className={ `form-control ${ valid === null ? '' : valid ? 'is-valid skip-image' : 'is-invalid skip-image' }` } style={(size) ? {width: size} : {width: '100%'}}
                ref={refValue} defaultValue={defaultValue} onChange={() => validar()} disabled={disabled ? disabled : false}>
                <optgroup label={label ? label : title}>
                <option value="0">Seleccione </option>
                    { arrayOpciones.map( (opcion) => (
                        (title === 'Zona de Pago')
                        ?   <option key={opcion.id} value={opcion.id}> {`${opcion.domCiaCalle}${(opcion.domCiaNumExt & opcion.domCiaNumExt) ? ` ${opcion.domCiaNumExt}` : ''}${(opcion.domCiaNumInt) ? ` ${opcion.domCiaNumInt}` : ''}${(opcion.codPostal.codigopColonia) ? `, ${opcion.codPostal.codigopColonia}` : ''}${(opcion.codPostal.ciudadId.ciudadNom) ? `, ${opcion.codPostal.ciudadId.ciudadNom}` : ''}`} </option>
                        :   <option key={opcion.id} value={opcion.id}> {opcion[keyOpcion]} </option>
                    ))}
                </optgroup>
            </select>
            <div className="invalid-feedback"> Seleccione un elemento </div>
        </div>
    );
};

export default SelectValidation;
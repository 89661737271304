import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { cambios_no_guardados, servicios_grupogack_nomina, servicios_grupogack_catalogo, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonIndicadores, ArrayJsonSalarioMinUMA, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import AlertForm from '../../../../../../Services/AlertForm';
import FormularioIndicadores from './FormularioIndicadores';

const ModalEditIndicadores = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, nombreModulo, informacionAdicional}) => {
    const [ error, setError ] = useState( null );
    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null); 

    const cerrarModal = () => {
        setOpenEdit( false );
        setError( null );
    }

    useEffect(() => {
        axios.get(`${servicios_grupogack_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    alert('error');
                    break;
            } 
        })
        .catch(error => alert(error));

        axios.get(`${servicios_grupogack_catalogo}/indicador/getall/relab`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const indicadores = ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse);
                    setIndicadoresGenerales(indicadores);
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
          // eslint-disable-next-line
    },[]); 

    const actualizarIndicador = ( indicador, indicadorParametros, shouldUpdateIndParamet ) => {
        axios.put( `${servicios_grupogack_nomina}/indicadores/`, indicador, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        const nuevoIndicador = JsonIndicadores(respuesta.data.dataResponse)
                        if(shouldUpdateIndParamet){
                            actualizarIndicadorParametros( nuevoIndicador.id, indicadorParametros );
                        }else{
                            if(indicadorParametros!==null)
                            enviarIndicadorParametros(nuevoIndicador.id, indicadorParametros);
                        }
                        editar( nuevoIndicador );
                        setOpenEdit( false );
                        break;
                    case 404:
                        setError( <AlertForm message={ respuesta.data.msg } /> );
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        errorTable( cambios_no_guardados );
                        break;
                }
            })
            .catch( error => {
                //errorTable( cambios_no_guardados );
                console.log(error);
                
            })
    }

    const enviarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {nomindIndicador:indicadorParametros.nomindId , nomindId: idIndicador, zonaId: indicadorParametros.zonaId, topVeces: indicadorParametros.topVeces };
        
        await axios.post( `${servicios_grupogack_nomina}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(registro_no_guardado+" en Parametros Indicadores");
                    break;
            }
        })
        .catch(error => errorTable(registro_no_guardado)); 
    }

    const actualizarIndicadorParametros = async (idIndicador, indicadorParametros) => { 
        const newData = {id:indicadorParametros.id, nomindIndicador: idIndicador, zonaId: indicadorParametros.zonaId, topVeces: indicadorParametros.topVeces };
        
        await axios.put( `${servicios_grupogack_nomina}/indicadores/parametros/`, newData, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    break;
                default:
                    errorTable(cambios_no_guardados+" en Parametros Indicadores");
                    break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    }

    return (
        <ModalService title="Actualizar Concepto por Nómina" parentNode={nombreModulo} minWidth="40%"
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
                <FormularioIndicadores
                    error={error}
                    informacion={informacionAdicional}
                    elementSelect={elementSelect}
                    actualizarIndicador={actualizarIndicador}
                    informacionAdicional={salarioMinimos}
                    indicadoresGeneral={indicadoresGenerales}
                    idModulo={nombreModulo}
                />
        </ModalService>
    );
};

export default ModalEditIndicadores;
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const TooltipElement = withStyles((theme) => ({
    tooltip: {
      fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);

const TooltipComponent = ({ children, tooltipText }) => {
    return (
        <TooltipElement title={tooltipText}>
            { children }
        </TooltipElement>
    );
}
 
export default TooltipComponent;
import React, { useState, useEffect } from 'react'; 
import Avatar from '@material-ui/core/Avatar';
import profileIMG from '../../../../../../Media/Img/profile.jpg';
import ConvertDate from '../../../../Usuario/Helpers/ConvertDate';
import { longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import AlertForm from '../../../../../../Services/AlertForm';
const FormInfoAuditoria = ({ elementSelect }) => { 

    const [ detalleCRUD, setDetalleCRUD ] = useState(null);
    const regexFechaHora= /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])) (?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d).[0-9]/;
    const [ error, setError ] = useState(null);

    const flatten = function(data) {
        var result = {};
        function recurse (cur, prop) {
            if (Object(cur) !== cur) {
                result[prop] = cur;
            } else if (Array.isArray(cur)) {
                    for(var i=0, l=cur.length; i<l; i++)
                        recurse(cur[i], prop + "[" + i + "]");
                if (l === 0)
                    result[prop] = [];
            } else {
                var isEmpty = true;
                for (var p in cur) {
                    isEmpty = false;
                    recurse(cur[p], p);
                }
                if (isEmpty && prop)
                    result[prop] = {};
            }
        }
        recurse(data, "");
        return result;
        }
        
    const diccionarioNoShow=["attemptsLogin","id","password","icono","indicadorId","nominaid"];
    const diccionario = {
        nombremodulo     : "Nombre Modulo",
        operacion        : "Operación",
        date             : "Fecha",
        nivlaboralClave  : "Clave Nivel Laboral",
        baseDescrip      : "Descripción Base",
        baseNom          : "Nombre Base",
        baseClave        : "Clave de Base",
        clasificaNom     : "Nombre Clasificación",
        clave            : "Clave",
        indicadorClave   : "Clave Concepto",
        indicadorNom     : "Nombre Concepto",
        indicadorSat     : "Indicador SAT",
        recibo           : "Aparece en Recibo",
        rutinaDesc       : "Descripción Rutina",
        rutinaNom        : "Nombre Rutina",
        tipoNatuNom      : "Nombre Tipo de Naturaleza",
        nomClave         : "Nombre Clave",
        nomIndicador     : "Nombre Concepto",
        nomRecibo        : "Recibo",
        indicador        : "Concepto",
        zonaNom          : "Nombre Zona",
        zonaDesripcion   : "Descripción Zona",
        topeveces        : "Tope en Veces",
        nombre           : "Nombre",
        puestoCve        : "Clave Puesto",
        puestoNom        : "Nombre Puesto",
        puestoDescrip    : "Descripción Puesto",
        monto            : "Monto",
        fechaini         : "Fecha Inicial",
        fechafin         : "Fecha Final",
        contratoSaoNom   : "Nombre Contrato",
        tipoContratoClave: "Clave Tipo de Contrato",
        tipoContratoDesc : "Descripción Tipo de Contrato",
        contratoSaoFechas: "Fechas Contrato",
        subsidio_fecfin  : "Fecha Final",
        username         : "Usuario",
        superior         : "Superior",
        lastAccess       : "Ultimo Acceso",
        verificado       : "Verificado",
        secuencia        : "Secuencia",
        tour             : "Tour",
        periodoNombre    : "Nombre del Periodo",
        fechaIni         : "Fecha Inicial",
        fechaFin         : "Fecha Final",
        limiteInf        : "Límite Inferior",
        limiteSup        : "Límite Superior",
        cuotaFija        : "Cuota Fija",
        porcentaje       : "Porcentaje",
        fecIni           : "Fecha Inicial",
        fecFin           : "Fecha Final",
        quinAntAnios     : "Años de Antiguedad",
        tyc              : "Términos y Condiciones",
        prioridad        : "Prioridad",
        reporteNom       : "Nombre Reporte",
        reporteDescrip   : "Descripción Reporte",
        created          : "Creado",
        perdenomNombre   : "Nombre",
        perdenomDias     : "Días",
        nominaClave      : "Clave Nómina",
        nominaNomCorto   : "Nombre Corto Nómina",
        nominaNombre     : "Nombre Nómina",
        nominaDescrip    : "Descripción Nómina",
        nomina           : "Nómina",
        paramCalculo     : "Parametro de Cálculo",
        paramAjusteImpto : "Parametro Ajuste Impuesto",
        paramTimbrado    : "Parametro Timbrado",
        paramImptoPeriodo: "Parametro Impuesto Periodo",
        paramImptoMensual: "Parametro Impuesto Mensual",
        paramImptoAnual  : "Parametro Impuesto Anual",
        regFiscalClave   : "Clave Régimen Fiscal",
        regFiscalDesc    : "Descripción Régimen Fiscal",
        regimenClave     : "Clave Régimen",
        regimenDesc      : "Descripción Régimen",
        origenRecClave   : "Clave del Origen del Recurso",
        origenRecDesc    : "Descripción de Origen del Recurso",
        diaNombre        : "Día",
        turnoNom         : "Turno",
        entrada          : "Entrada",
        salcomida        : "Salida Comida",
        regcomida        : "Regreso Comida",
        salida           : "Salida",
        paramPagSubUltPer: "Pago de Subsidio",
        diasAguinaldoAnio: "Días Aguinaldo por Año",
        diasBonoAnio     : "Días Bono por Año",
        periodoPagoSem1  : "Periodo Pago 1er Semestre",
        periodoPagoSem2  : "Periodo Pago 2do Semestre",
        diasPrimaAnio    : "Dias Prima Vacacional por Año",
        diasAjuste       : "Días de Ajuste",
        periodoPago      : "Periodo Pago",
        diasPagoPeriodo  : "Días a Pagar en el Periodo",
        anioIni          : "De (Años)",
        anioFin          : "Hasta (Años)",
        diasSem1         : "Días 1er Semestre",
        diasSem2         : "Días 2do Semestre",
        plantillaClave   : "Clave Plantilla",
        plantillaNom     : "Nombre Plantilla",
        plantillaDesc    : "Descripción Plantilla",

      };
    
    const res = { data: elementSelect.datos._children[0] ? !elementSelect.datos._children[0]._children.value._value.includes('[') ?
                {[elementSelect.datos._children[0]._children.column._value] : `{${elementSelect.datos._children[0]._children.value._value.replace(/\[/g,'{ ').replace(/\]/g,'}')}}`}: 
                `{${elementSelect.datos._children[0]._children.value._value.replace(/\[/g,'{ ').replace(/\]/g,'}')}}`  : null };
    const isArray = { value : false };

    
    useEffect (()=>{
        if(res.data!==null){

            if(typeof res.data !== 'object'){ 
                //ELIMINAR CONFIGURACIÓN DE REPORTES RRHH 
                
                const regexLlaveReportesRRHH = /, reporteJson={.*"}}}}/;
                let searchedLlaveReportesRRHH= res.data.match(regexLlaveReportesRRHH);
                for (const key in searchedLlaveReportesRRHH) {    
                    res.data = res.data.replace(searchedLlaveReportesRRHH[key], '');    
                }
                
                //CREAR LLAVES PRINCIPALES
                const llavesDosPuntos = /[a-zA-Z]+:[^,}]+( |){/g;
                let searchedLlavesDosPuntos = res.data.match(llavesDosPuntos);
                for (const key in searchedLlavesDosPuntos) { 
                    let newKey = searchedLlavesDosPuntos[key];  
                    res.data = res.data.replace(searchedLlavesDosPuntos[key], newKey.replace(':','='));    
                } 

                const regexLlavePrimaria = /[A-Z-a-z]{1,100} \[|([A-Za-z]{1,100}=)[A-Za-z]{1,100} \[/g;
                let searched = res.data.match(regexLlavePrimaria);
                for (const key in searched) { 
                    let newKey = searched[key];
                    res.data = res.data.replace(searched[key], newKey.replace('=',''));
                    res.data = res.data.replace(searched[key], newKey.replace(' [','{'));
                }

                const regexLlaves = /[A-Za-z0-9]+=[A-Za-z0-9]+( |){/g
                let searchedLlaves = res.data.match(regexLlaves);
                for (const key in searchedLlaves) { 
                    let newKey =  '"' + searchedLlaves[key].replace('=','').replace('{','":{');
                    res.data = res.data.replace(searchedLlaves[key], newKey);    
                } 
                
                const regexLlavesComillas =/[A-Za-z]+ {/g
                let searchedLlavesComillas = res.data.match(regexLlavesComillas);  
                for (const key in searchedLlavesComillas) { 
                    let newKey = '"' + searchedLlavesComillas[key];
                    res.data = res.data.replace(searchedLlavesComillas[key], newKey.replace(' {','":{'));  
                }

                const regexLlavesFondo = /[a-zA-Z0-9]+=/g;
                let searchedLlavesFondo = res.data.match(regexLlavesFondo);
                for (const key in searchedLlavesFondo) {  
                    let newKey = '"' + searchedLlavesFondo[key];
                    res.data = res.data.replace(searchedLlavesFondo[key], newKey.replace('=','":"'));  
                }
                
                const comillasFondo = /:"[^,}]+(,|})/g;
                let searchedComillasFondo = res.data.match(comillasFondo);
                for (const key in searchedComillasFondo) { 
                    let newKey = searchedComillasFondo[key]; 
                    if(!/{ }/g.test(newKey)){
                        res.data = res.data.replace(searchedComillasFondo[key], newKey.replace('}','"}')); 
                        res.data = res.data.replace(searchedComillasFondo[key], newKey.replace(',','",'));  
                    }
                }
                
                const comillasDosPuntos = /[a-zA-Z]+:[^,}{]+/g;
                let searchedComillasDosPuntos = res.data.match(comillasDosPuntos);
                for (const key in searchedComillasDosPuntos) { 
                    let newKey = '"'+searchedComillasDosPuntos[key];  
                    res.data = res.data.replace(searchedComillasDosPuntos[key], newKey.replace(':','":"')+'"');    
                }

                if( res.data.charCodeAt(1) !== 34 ){ 
                    res.data = res.data.replace(/{/g,'{"');
                    res.data = res.data.substr(0,2)+res.data.substr(2,res.data.length-1).replace('{ ','":{');
                }
                res.data = res.data.replace(/"{ }/gi, '""');
                res.data = res.data.replace(/'/gi, '');
                res.data = res.data.replace(/, "plantillaHtml":"/gi, '');
                isArray.value = true;                
            } 

            try {
                const data = isArray.value ? JSON.parse(res.data) : null ; 
                const dataFlatten = isArray.value ? flatten(data) : null;
                const result = Object.keys(isArray.value ? dataFlatten : res.data ); 
                const dataResult = {};
                for (const key in result) { 
                    if( !diccionarioNoShow.includes(result[key]) ){  
                        const newKeyDiccionario = diccionario[result[key]];
                        dataResult[ newKeyDiccionario ? newKeyDiccionario : result[ key ] ] = isArray.value ? dataFlatten[ result[ key ] ] : res[ result[ key ] ]; 
                    }
                } 
                setDetalleCRUD(dataResult); 
            } catch (error) {
                setError( <AlertForm message="Por el momento no se puede mostrar información del módulo." /> );
            }
        }
        // eslint-disable-next-line
    },[]); 

    return (
        <React.Fragment>
            <form id={ 'infoAuditoria' } >
                <div className="row">
                    <div className="col-md-2 col-sm-12">
                        {
                            elementSelect.img !== ''
                            ? <Avatar alt="avatar" src={`data:image/jpeg;base64, ${elementSelect.img}`} style={{width:'5rem', height:'5rem' }}/>
                            : <Avatar alt="avatar" src={profileIMG} style={{width:'5rem', height:'5rem' }}/>
                        } 
                    </div>
                    <div className="col-md-10 col-sm-12"> 
                        <strong>Usuario: </strong><label>{ elementSelect.usuario }</label><br/>
                        <strong>Nombre: </strong><label>{ elementSelect.nombre }</label><br/>
                        <strong>Primer Apellido: </strong><label>{ elementSelect.apaterno }</label><br/>
                        <strong>Segundo Apellido: </strong><label>{  elementSelect.amaterno }</label><br/>
                        <strong>Módulo: </strong><label>{ elementSelect.moduleName}</label><br/>
                        <strong>Operación: </strong><label>{ elementSelect.crudOperation }</label><br/> 
                        <strong>Fecha: </strong><label>{`${elementSelect.fecha.split('T')[0].split('-').reverse().join('/')}` }</label><br/> 
                        <strong>Hora: </strong><label>{`${elementSelect.fecha.split('T')[1]}` }</label><br/> 
                    </div>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <strong>DATOS {elementSelect.crudOperation==='Agregar' ? 'AGREGADOS' : elementSelect.crudOperation ==="Actualizar" ? 'ACTUALIZADOS' : 'ELIMINADOS'} </strong><br/><br/>
                    </div>
                    <div className="col-md-12 col-sm-12">
                         {
                             detalleCRUD &&
                             Object.keys(detalleCRUD).map( element => {
                                 if(element !== 'plantilla_html')
                                 return <React.Fragment key={element}>
                                    <strong> { `${element}: `} </strong>
                                        <label>
                                            {   detalleCRUD[element]==='true' ? 'Sí' : detalleCRUD[element]==='false' ? 'No' : detalleCRUD[element]==='null' 
                                                ? '' : regexFechaHora.test(detalleCRUD[element]) ? ConvertDate(parseInt(detalleCRUD[element])) 
                                                : /[0-9]{13}/.test(detalleCRUD[element]) ? ConvertDate(longDateToInput(parseInt(detalleCRUD[element]))) 
                                                : detalleCRUD[element]}
                                        </label>
                                        <br/>
                                </React.Fragment>
                                return null;
                             })
                         }
                        
                    </div>
                </div>
                {error}
            </form>
        </React.Fragment>
    );
};
export default FormInfoAuditoria;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack_nomina, acumulados_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import { ArrayJsonConceptosEmp } from '../../../Helpers/JsonToOneLevel';

const TableAcumulados = ({ id, elementSelect, anioRef, periodo, acumulados, permisos, acumuladoRef }) => {
    
    const [conceptosEmp, setConceptosEmp] = useState(null);
    const [conceptos, setConceptos] = useState(null);

    useEffect(() => {
        axios.get(`${servicios_grupogack_nomina}/acumulados/${elementSelect.id}/${periodo.id}/${acumuladoRef.current.value}`, headersAuth()) 
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setConceptosEmp(ArrayJsonConceptosEmp(res.data.dataResponse));
                    break;
                case 404:
                    setConceptosEmp([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        axios.get(`${servicios_grupogack_nomina}/indicadores/getbynomina/${acumuladoRef.current.value}`, headersAuth() )
        .then(respuesta => {
            switch(respuesta.data.status) {
                case 200:
                    setConceptos(respuesta.data.dataResponse);
                    break;
                case 404:
                    setConceptos([]);
                    break;
                default:
                    break;
            }
        })
        .catch( error => { console.log( error ) })
    },[acumuladoRef]);

    return (
        <div id={id}>
            {   
                (conceptosEmp)
                ?   <DataTableService
                        id={`${id}secondary`}
                        title={acumulados_}
                        columns={[
                            { field: "nominaClave", title: "Clave" },
                            { field: "nominaIndicador", title: "Concepto de Nómina" },
                            { field: "indicadorSat", title: "Concepto SAT" },
                            { field: "cantidad", title: "Cantidad" },
                            { field: "monto", title: "Importe (MXN)", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'}},
                        ]}
                        data={conceptosEmp}
                        informacionAdicional={{
                            anio: (anioRef.current) ? anioRef.current.value : '',
                            periodo: periodo.periodo,
                            acumulados,
                            conceptos
                        }}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default TableAcumulados;
import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, cambios_no_guardados } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import { JsonRegistroPatronal } from '../../../Helpers/JsonToOneLevel';
import ModalService from '../../../ModalService/ModalService';
import FormRegistroPatronal from './FormRegistroPatronal';
import AlertForm from '../../../../../../Services/AlertForm';

const ModalEditRegistroPatronal = ({ openEdit, setOpenEdit, elementSelect, editar, errorTable, informacionAdicional, nombreModulo }) => {

    const [error, setError] = useState(null);

    const cerrarModal = () => {
        setOpenEdit(false);
        setError(null);
    };

    const actualizarRegistroPatronal = (registoPatronal, registoPatronalId) => {
        const info = registoPatronal;
        Axios.put( `${servicios_grupogack_catalogo}/registropat/${registoPatronalId}`, info, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                editar( JsonRegistroPatronal(res.data.dataResponse) );
                setOpenEdit(false);
                break;
            case 400:
            case 404:
                setError(<AlertForm message={res.data.msg} />);
                break;

            default:
                errorTable(cambios_no_guardados);
                break;
            }
        })
        .catch(error => errorTable(cambios_no_guardados));
    };

    return (
        <ModalService title="Actualizar Registro Patronal" parentNode={nombreModulo}
            isOpen={ openEdit } onClose={ () => cerrarModal() } >
            <div className="row">
                <div className="col-12">
                    <FormRegistroPatronal
                        setError={setError}
                        error={error}
                        elementSelect={elementSelect}
                        informacion={informacionAdicional}
                        actualizarRegistroPatronal={actualizarRegistroPatronal}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalEditRegistroPatronal;
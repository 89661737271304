import React, { useState } from 'react';
import Axios from 'axios';
import { servicios_grupogack, registro_no_guardado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import ModalService from './../../../ModalService/ModalService';
import FormPeriodoNomina from './FormPeriodoNomina'; 
import AlertForm from '../../../../../../Services/AlertForm';

const ModalAddPeriodoNomina = ({ openAdd, setOpenAdd, agregar, errorTable, informacionAdicional, nombreModulo }) => {
    
    const [ error, setError ] = useState( null );

    const cerrarModal = () => {
        setOpenAdd( false );
        setError( null );
    } 
    const enviarPeriodoNomina = periodo => {
         
        Axios.get(`${servicios_grupogack}/nomina/config/periodos/genera/${informacionAdicional.id}/${periodo.mesInicial}/${periodo.anioInicial}/${periodo.mesFinal}/${periodo.anioFinal}`, headersAuth() )
        .then( respuesta => {
            switch( respuesta.data.status ){
                case 200:
                    agregar(respuesta.data.dataResponse);
                    setOpenAdd( false );
                    break;
                case 400:
                    setError( <AlertForm message={ respuesta.data.msg } /> );
                    break;
                default:
                    errorTable( registro_no_guardado );
                    break;
            }
        })
        .catch( error => {
            errorTable( registro_no_guardado ); 
        })
    }

    return (
        <ModalService title="Nuevo Periodo de Nómina" parentNode={nombreModulo}
            isOpen={ openAdd } onClose={ () => cerrarModal() } minWidth="40%">
            <div className="row">
                <div className="col-12">
                    <FormPeriodoNomina
                        enviarPeriodoNomina={enviarPeriodoNomina}
                        error={error}
                        elementSelect={informacionAdicional}
                    />
                </div>
            </div>
        </ModalService>
    );
}

export default ModalAddPeriodoNomina;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../../Services/AuthService';
import { servicios_grupogack_rh, servicios_grupogack_nomina, prestamos_empleado } from '../../../../../../../Constants/Constants';
import { ArrayJsonPrestamos } from '../../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../../Services/Spinner/SpinnerOval';

const PrestamosEmpleado = ({ id, permisos, empleado }) => {
    const [ prestamos, setPrestamos ] = useState( null );
    const [ indicadoresNomina, setIndicadoresNomina ] = useState( null );
    useEffect(() => {
        axiosPrestamos();
        axiosIndicadoresNomina();
        // eslint-disable-next-line
    }, []);

    const axiosPrestamos = () => {
        axios.get( `${servicios_grupogack_rh}/empleado/prestamos/${empleado.id}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setPrestamos( ArrayJsonPrestamos(respuesta.data.dataResponse) );
                        break;
                    case 404: //No hay datos que mostrar
                        setPrestamos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para obtener los indicadores por nomina (Nomina a la cual esta relacionada el empleado) */
    const axiosIndicadoresNomina = () => {
        axios.get( `${servicios_grupogack_nomina}/indicadores/getbynomina/${empleado.nominaId}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setIndicadoresNomina( respuesta.data.dataResponse );
                        break;
                    case 404:
                        setIndicadoresNomina( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => {
                console.log( error );
                setIndicadoresNomina( [] );
            })
    }

    return (
        <div id={`${id}secondary`}>
            { prestamos && indicadoresNomina ?
                <DataTableService id={`${id}secondary`} title={ prestamos_empleado } permisos={permisos}
                    columns={[
                        { field: "folio", title: "Folio" },
                        { field: "indicadorNombre", title: "Concepto" },
                        { field: "numeroPagos", title: "Núm. Pagos", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "importe", title: "Importe Pagos (MXN)", cellStyle: rowData => ({ textAlign:'right'}), headerStyle: { textAlign:'right'} },
                        { field: "fechaIniTab", title: "Fecha Inicio Préstamo" },
                        { field: "fechaFinTab", title: "Fecha Fin Préstamo" },
                    ]}
                    showIconAux
                    data={ prestamos }
                    informacionAdicional={{ empleado, indicadoresNomina }} />
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default PrestamosEmpleado;
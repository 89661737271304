import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, tipo_estado_civil } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const TipoEstadoCivil = ({ id, permisos }) => {

    const [tipoEstadoCivil, setTipoEstadoCivil] = useState(null);

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}/tipoedocivil/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setTipoEstadoCivil(res.data.dataResponse);
                    break;
                case 404:
                    setTipoEstadoCivil([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]);

    return (
        <div id={ id }>
            {   
                tipoEstadoCivil
                ?   <DataTableService id={id} title={ tipo_estado_civil }
                        columns={[
                            { field: "edoCivilnom", title: "Nombre" }
                        ]}
                        data={tipoEstadoCivil}
                        permisos={permisos}
                    />
                :   <SpinnerOval />
            }
        </div>
    );
}
 
export default TipoEstadoCivil;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../Services/AuthService';
import { servicios_grupogack, tablero_ } from '../../../../../Constants/Constants';

import SpinnerOval from '../../../../../Services/Spinner/SpinnerOval';

import PageTitleBar from '../../Layouts/PageTitleBar';
import AccesoDirecto from './AccesoDirecto';
import AgregarAccesos from './AgregarAccesos/AgregarAccesos';
import ModalDeleteAcceso from './ModalDeleteAcceso';
import { obtenerAccesos, agregarPermisos } from './AgregarAccesos/HelperAccesos';

import Estadisticas from './Estadisticas/Estadisticas';
/********************json files */
import accesosdirectosjson from '../../../../Datos/accesosdirectos.json';

const Tablero = ({ relacionLaboral, modulosApi }) => {
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} );
    /* --------------------- Axios para obtener los accesos directos del usuario logueado --------------------- */
    const [ accesosDirectos, setAccesosDirectos ] = useState( null );
    useEffect(() => {
        axiosAccesos();
        // eslint-disable-next-line
    }, []);

    const axiosAccesos = () => {
        setAccesosDirectos( agregarPermisos( modulosApi, accesosdirectosjson ) );
      /*  axios.get( `${servicios_grupogack}/dashboard/`, headersAuth() )
            .then( respuesta => {
                //console.log( respuesta );
                switch( respuesta.data.status ){
                    case 200:
                        setAccesosDirectos( agregarPermisos( modulosApi, respuesta.data.dataResponse ) );
                        break;
                    case 404:
                        setAccesosDirectos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })*/
    }
    /* ------------------------------ Actualizaciones de estado (accesosDirectos) ----------------------------- */
    const eliminar = ( idElement ) => {
        setAccesosDirectos( accesosDirectos.filter( element => element.modulo.id !== idElement ) );
        //successTable( 'Registro Eliminado' );
    }
    /* -------------------------------------------------------------------------------------------------------- */
    return (
        <div id={tablero_}>
            <div className="my-six-step">
                <PageTitleBar title="Accesos Directos" path="Tablero"/>
                { accesosDirectos ?
                    <React.Fragment>
                        <div className="row">
                            { accesosDirectos.map( modulo => (
                                <div key={ modulo.id } className="col-md-2 col-sm-12 my-2">
                                    <AccesoDirecto modulo={modulo.modulo} setOpenDelete={setOpenDelete} setElementSelect={setElementSelect}/>
                                </div>
                            ))}

                            { /*accesosDirectos.length < 12 &&
                                <div className="col-md-3 col-sm-12 my-2">
                                    <AgregarAccesos accesosDirectos={obtenerAccesos( accesosDirectos )} axiosAccesos={axiosAccesos}/>
                                </div>
                            */}
                        </div>
                        <ModalDeleteAcceso openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar}/>
                    </React.Fragment> : <SpinnerOval />
                }
            </div>
            <Estadisticas relacionLaboral={relacionLaboral}/>
        </div>
    );
};

export default Tablero;
import React, { useState, useEffect } from 'react'
import TooltipComponent from '../Tooltip/TooltipComponent'
import { comprobar, trimValue } from './HelperValidation';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import './stylesInput.css';

const PasswordValidation = ({ type, refValue, title, placeholder, maxLength, defaultValue, tooltipText, required, action, disabled, size, optional }) => {
    
    const [valid, setValid] = useState(null);
    const [showPass, setShowPass] = useState(false);
    
    let mensaje = '';

    useEffect(() => {
        if(refValue) {
            if(refValue.current.value === '') setValid(null);
        }
    }, [refValue]);

    const comprobarValid = ( obj ) => {
        if( valid !== obj.status || mensaje !== obj.mensaje ){
            setValid(obj.status);
            mensaje = obj.mensaje;
        }
    };

    const validar = () => {
        if(refValue.current.value.trim() === '' ) {
            setValid(null);
            return;
        }

        if( optional === true && refValue.current.value.trim() === '' ){
            comprobarValid({ status: null, mensaje: '' });
        }else{
            comprobarValid( comprobar( refValue.current.value.trim(), type ) );
        }
    }

    return (
        <div className={`form-group ${ optional === true && valid === null ? '' : 'validar' }`}>
            { title &&
                <label>
                    { title }{ required && <strong className="requerido">*</strong> }
                    { tooltipText &&
                        <TooltipComponent tooltipText={tooltipText}>
                            <span className="spanInfo"><InfoRoundedIcon color="disabled"/></span>
                        </TooltipComponent>
                    }
                </label>
            }
            <div className="passIcon">
                <IconButton className="iconPassword" onClick={ () => setShowPass(!showPass) }> { !showPass ? <Visibility/> : <VisibilityOff/> } </IconButton>
                <input type={ !showPass ? type : 'pass' } placeholder={placeholder} maxLength={maxLength}
                    className={ `form-control ${ valid === null ? '' : valid ? 'is-valid' : 'is-invalid'}`}
                    ref={ refValue } defaultValue={defaultValue} autoComplete={ type === 'password' ? 'new-password' : '' }
                    onChange={ (action) ? () => {validar(); action() } : () => {validar()} }
                    onBlur={ () => trimValue(refValue) } disabled={(disabled) ? disabled : false}
                    style={{width: (size) ? size : '100%'}}
                />
                <div className="invalid-feedback"> { mensaje } </div>
            </div>
        </div>
    );
};

export default PasswordValidation;
import React, { useState } from 'react';
import axios from 'axios';
import { servicios_grupogack } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import ModalService from '../../../ModalService/ModalService'; 
import FormularioInfoReport from './FormularioInfoReport';

const ModalInfoReport = ({ openInfoReport, setOpenInfoReport, elementSelect, nombreModulo, title }) => {
    const [ error, setError ] = useState( null );

    const cerrarModal = () => {
        setOpenInfoReport( false );
        setError( null );
    }

    const enviarReportePermisos = ( permisos ) => {
        axios.post( `${servicios_grupogack}/configuracion/reportes/subordinados/`, permisos, headersAuth() )
            .then( respuesta => { 
                switch( respuesta.data.status ){
                    case 200:
                        //agregar( respuesta.data.dataResponse );
                        setOpenInfoReport( false );
                        break;
                    default: 
                        break;
                }
            })
            .catch( error => { 
            });
    }

    return (
        <ModalService title={title} parentNode={nombreModulo} minWidth="50%" maxWidth="50%"
            isOpen={ openInfoReport } onClose={ () => cerrarModal() } >
                <FormularioInfoReport
                    error={error}
                    enviarReportePermisos={enviarReportePermisos}
                    title={title}
                    elementSelect={elementSelect}
                />
        </ModalService>
    );
};

export default ModalInfoReport;
import React, { useState } from 'react';

import axios from 'axios';
import { servicios_grupogack_mensajes } from '../../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../../Services/AuthService';
import Alertwarning from '../../../../../../../Services/Alerts/AlertWarning';

import Button from '@material-ui/core/Button';
import Reply from '@material-ui/icons/Reply';
//import ReplyAll from '@material-ui/icons/ReplyAll';
import Forward from '@material-ui/icons/Forward';
import Delete from '@material-ui/icons/Delete';

const AccionesEntrada = ({ mensaje, usuario, eliminarEntrada }) => {
    const [ openPapelera, setOpenPapelera ] = useState( false );
    const axiosPapelera = () => {
        axios.delete( `${servicios_grupogack_mensajes}/eliminar/${mensaje.id}/${usuario}`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        eliminarEntrada( mensaje.id );
                        break;
                    case 400:
                        //errorTable( respuesta.data.msg );
                        break;
                    default:
                        //errorTable( registro_no_eliminado );
                        break;
                }
            })
            .catch( error => {
                //errorTable( registro_no_eliminado );
            })
    }

    return (
        <React.Fragment>
            <Button className="btn-incolor"> <Reply/> Responder </Button>
            <Button className="btn-incolor"> <Forward/> Reenviar </Button>
            <Button className="btn-incolor" onClick={ () => setOpenPapelera(true) }> <Delete/> Eliminar </Button>

            <Alertwarning show={openPapelera} SetopenALert={setOpenPapelera} text="Enviar elemento a papelera" textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={axiosPapelera} />
        </React.Fragment>
    );
};

export default AccionesEntrada;
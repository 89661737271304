import React, { useState, Fragment, useEffect } from 'react';
import axios from 'axios';
import { validarSubmit, inputDateToLong, longDateToInput } from '../../../../../../Services/Validation/HelperValidation';
import { servicios_grupogack_rh, registro_actualizado, cambios_no_guardados, registro_sera_actualizado } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import Button from '@material-ui/core/Button';
import BtnsInfoAdicional from './BtnsInfoAdicional';
import InfoPensionesAlimenticias from './InfoPensionesAlimenticias';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';
import InputDateValidation from '../../../../../../Services/Validation/InputDateValidation';
import BtnRemoveItem from './BtnRemoveItem';
import BtnEditItem from './BtnEditItem';
import AlertForm from '../../../../../../Services/AlertForm';
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';

const FormPensionesAlimenticias = ({ elementSelect, setError, agregarPensionAlimEdit, actualizarPensionAlim, enviar, irInfo, folioPensionAlimRef, nombrePensionAlimRef, pApellidoPensionAlimRef, sApellidoPensionAlimRef, tipoCuotaPensionAlimRef, montoPensionAlimRef, fechaInicioPensionAlimRef, fechaFinalPensionAlimRef, pensionesAlimSaved, setPensionesAlimSaved, setPensionesAlimSavedEdit, pensionesAlimSavedEdit, elementoSeleccionado, setElementoSeleccionado, mostrarFormAddEdit, setMostrarFormAddEdit, successTable, errorTable }) => {

    const [regexTipoCuota, setRegexTipoCuota] = useState('');
    const [openAlert, setOpenAlert] = useState(false);

    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };

    const guardar = e => {
        e.preventDefault();
        enviar();
    };

    const tipoCuota = (valor) => {
        montoPensionAlimRef.current.value = '';
        switch(valor) {
            case 'I':
                setRegexTipoCuota('importe');
            break;
            case 'P':
                setRegexTipoCuota('porcentaje');
            break;
            default:
                setRegexTipoCuota('');
            break;
        }
    };

    const abrirFormAddEdit = e => {
        e.preventDefault();
        setMostrarFormAddEdit(true);
    };

    useEffect(() => {
        if(elementSelect) {
            axios.get(`${servicios_grupogack_rh}/empleado/pension/${elementSelect.id}`, headersAuth())
            .then(res => {
                switch(res.data.status) {
                    case 200:
                        const info = res.data.dataResponse.map(res => (
                            {
                                id: res.id,
                                folio: res.folio,
                                nombre: res.nombre,
                                apellidoPat: res.apellidoPat,
                                apellidoMat: res.apellidoMat,
                                tipoCuota: res.tipoCuota,
                                tipoCuotaNom: (res.tipoCuota === 'I') ? 'IMPORTE' : 'PORCENTAJE',
                                montoPorc: parseFloat(res.montoPorc),
                                fechaIni: res.fechaIni,
                                fechaFin: res.fechaFin
                            }
                        ));
                        setPensionesAlimSaved(info);
                        break;
                    case 404:
                        setPensionesAlimSaved([]);
                        break;
                    default:
                        alert('No se puede obtener información de Pensión Alimenticia');
                        break;
                }
            })
            .catch(error => { alert('No se puede obtener información de Pensión Alimenticia'); console.log(error) });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const eliminarPensionAlim = item => {
        axios.delete(`${servicios_grupogack_rh}/empleado/pension/${item.id}`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    setPensionesAlimSaved(pensionesAlimSaved.filter(llEme => llEme.id !== item.id));
                    successTable(registro_actualizado);
                    break;
                default:
                    errorTable(cambios_no_guardados);
                    break;
            }
        })
        .catch(error => { errorTable(cambios_no_guardados); console.log(error) });
    };

    const guardaPension = (e, type) => {
        e.preventDefault();

        let lengthPensionAlim = 0;
        let lengthPensionAlimS = 0;

        if(pensionesAlimSaved.length) lengthPensionAlim = pensionesAlimSaved.length;
        if(setPensionesAlimSavedEdit.length) lengthPensionAlimS = setPensionesAlimSavedEdit.length;

        const total = lengthPensionAlim + lengthPensionAlimS;

        if(total < 10) {
            setError(null);
            if(validarSubmit("FormPensionesAlimenticias")) {
                let info = {
                    id: `${pensionesAlimSaved.length}${folioPensionAlimRef.current.value}`,
                    folio: folioPensionAlimRef.current.value,
                    nombre: nombrePensionAlimRef.current.value,
                    tipoCuota: tipoCuotaPensionAlimRef.current.value,
                    tipoCuotaNom: (tipoCuotaPensionAlimRef.current.value === 'I') ? 'IMPORTE' : 'PORCENTAJE',
                    montoPorc: parseFloat(montoPensionAlimRef.current.value),
                    fechaIni: inputDateToLong(fechaInicioPensionAlimRef.current.value),
                };
    
                if(pApellidoPensionAlimRef.current.value !== '') info = { ...info, apellidoPat: pApellidoPensionAlimRef.current.value };
                if(sApellidoPensionAlimRef.current.value !== '') info = { ...info, apellidoMat: sApellidoPensionAlimRef.current.value };
                if(fechaFinalPensionAlimRef.current.value !== '') info = { ...info, fechaFin: inputDateToLong(fechaFinalPensionAlimRef.current.value) };
    
                if(type === 'FormAgregar') setPensionesAlimSaved([ ...pensionesAlimSaved, info ]);
                if(type === 'FormEditar') {
                    info = { ...info, empleadoId: elementSelect.id }
                    setPensionesAlimSavedEdit([ ...pensionesAlimSavedEdit, info ]);
                }
    
                folioPensionAlimRef.current.value = '';
                nombrePensionAlimRef.current.value = '';
                pApellidoPensionAlimRef.current.value = '';
                sApellidoPensionAlimRef.current.value = '';
                tipoCuotaPensionAlimRef.current.value = '';
                montoPensionAlimRef.current.value = '';
                fechaInicioPensionAlimRef.current.value = '';
                fechaFinalPensionAlimRef.current.value = '';
            }
        } else {
            setError(<AlertForm message="Has llegado al límite de Pensiones Alimenticias"/>);
        }
    };
    
    const limpiaSelected = e => {
        e.preventDefault();
        if(pensionesAlimSavedEdit.length) setPensionesAlimSavedEdit([])
        else setElementoSeleccionado(null);
        setMostrarFormAddEdit(false);
    };

    const enviaTnfo = () => {
        setError(null);
        if(!pensionesAlimSavedEdit.length && !mostrarFormAddEdit) {
            actualizarPensionAlim();
        } else {
            agregarPensionAlimEdit();
        }
    };

    return (
        <Fragment>
            <h5>Pensiones Alimenticias</h5>
            {
                (!elementSelect || (elementoSeleccionado === null && pensionesAlimSaved.length > 0 && !mostrarFormAddEdit))
                ?   pensionesAlimSaved.map((pAlim, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            {
                                (elementSelect)
                                ?   <Fragment>
                                        <BtnEditItem id={pAlim.id} idArray="id" array={pensionesAlimSaved} action={setElementoSeleccionado} remove/>
                                        <BtnRemoveItem id={pAlim.id} idArray="id" array={pensionesAlimSaved} action={eliminarPensionAlim} remove/>
                                    </Fragment>
                                :   <BtnRemoveItem id={pAlim.id} idArray="id" array={pensionesAlimSaved} action={setPensionesAlimSaved}/>
                            }
                            <InfoPensionesAlimenticias pAlim={pAlim}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (pensionesAlimSavedEdit)
                ?   pensionesAlimSavedEdit.map((pAlim, key) => (
                        <Fragment key={key}>
                            { key > 0 && <hr/> }
                            <BtnRemoveItem id={pAlim.id} idArray="id" array={pensionesAlimSavedEdit} action={setPensionesAlimSavedEdit}/>
                            <InfoPensionesAlimenticias pAlim={pAlim}/>
                        </Fragment>
                    ))
                :   null
            }
            {
                (!elementSelect || elementoSeleccionado !== null || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit))
                ?   <form id="FormPensionesAlimenticias" onSubmit={(elementSelect) ? mostrarAlert : guardar}>
                        <InputValidation title="Número de Folio" type="folioPensionAlimenticia"
                            placeholder="Escriba el número de folio de la pensión alimenticia" alfanumeric
                            refValue={folioPensionAlimRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.folio : ''} 
                            tooltipText="Máximo 8 letras y/o números" tooltipName="tooltipNomPension" maxLength={8} required/>
                        <InputValidation title="Nombre" type="nombre" placeholder="Escribe el nombre"
                            refValue={nombrePensionAlimRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.nombre : ''} onlyLetters=" "
                            tooltipText="Máximo 60 letras y/o espacio" tooltipName="tooltipNomPensionAlim" maxLength={60} required/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputValidation title="Primer Apellido" type="pApellido" placeholder="Escriba el primer apellido" onlyLetters=" "
                                    refValue={pApellidoPensionAlimRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoPat) ? elementoSeleccionado.apellidoPat : '' : ''} 
                                    tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipPApellidoPensionAlim" maxLength={30} optional={true}/>
                            </div>
                            <div className="form-right">
                                <InputValidation title="Segundo Apellido" type="sApellido" placeholder="Escriba el segundo apellido" onlyLetters=" "
                                    refValue={sApellidoPensionAlimRef} defaultValue={elementoSeleccionado ? (elementoSeleccionado.apellidoMat) ? elementoSeleccionado.apellidoMat : '' : ''}
                                    tooltipText="Máximo 30 letras y/o espacio" tooltipName="tooltipSApellidoPensionAlim" maxLength={30} optional={true}/>
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <SelectValidation title="Tipo de Cuota" arrayOpciones={[{ id: 'I', cuota: 'Importe' }, { id: 'P', cuota: 'Porcentaje' }]} keyOpcion="cuota"
                                    refValue={tipoCuotaPensionAlimRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.tipoCuota : ''} onChangeExecute={tipoCuota} required/>
                            </div>
                            <div className="form-right">
                                <InputValidation title={`Monto${(regexTipoCuota === 'importe') ? ' (MXN)' : (regexTipoCuota === 'porcentaje') ? ' (%)' : ''}`}
                                    type={regexTipoCuota} placeholder="Escriba el monto de la pensión alimenticia"
                                    refValue={montoPensionAlimRef} defaultValue={elementoSeleccionado ? elementoSeleccionado.montoPorc : ''}
                                    tooltipText={regexTipoCuota === 'importe' ? 'Máximo 6 números y dos decimales' : regexTipoCuota === 'porcentaje' ? 'Máximo 2 números y dos decimales del 0.00 al 100.00' : 'Debes seleccionar el Tipo de Cuota'}
                                    tooltipName="tooltipMontoPensionAlim" maxLength={regexTipoCuota === 'importe' ? 9 : regexTipoCuota === 'porcentaje' ? 6 : 0}
                                    onBlurDecimal={2} onlyNumbers="." disabled={regexTipoCuota !== '' ? false : true} required/>
                            </div>
                        </div>
                        <div className="formRelLab">
                            <div className="form-left">
                                <InputDateValidation title="Fecha de Inicio" refValue={fechaInicioPensionAlimRef}
                                    defaultValue={elementoSeleccionado ? longDateToInput(elementoSeleccionado.fechaIni) : ''} required/>
                            </div>
                            <div className="form-right">
                                <InputDateValidation title="Fecha Final" refValue={fechaFinalPensionAlimRef}
                                    defaultValue={elementoSeleccionado ? (elementoSeleccionado.fechaFin) ? longDateToInput(elementoSeleccionado.fechaFin) : '' : ''} optional={true}/>
                            </div>
                        </div>
                        <BtnsInfoAdicional numForm={5} irInfo={irInfo}/>
                        {
                            (!elementSelect || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit))
                            ?   (!elementSelect || pensionesAlimSaved.length === 0)
                                ?   <div className="formRelLab">
                                        <div className="form-left">
                                            <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                                        </div>
                                        <div className="form-right">
                                            <Button variant="outlined" className="btn-outline" onClick={e => (!elementSelect) ? guardaPension(e, 'FormAgregar') : guardaPension(e, 'FormEditar')}>Agregar</Button>
                                        </div>
                                    </div>
                                :   <div className="formRelLab">
                                        <div className="form-left">
                                            <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                        </div>
                                        <div className="form-right">
                                            <Button variant="outlined" className="btn-outline" onClick={e => guardaPension(e, 'FormEditar')}>Agregar</Button>
                                        </div>
                                    </div>
                            :   null
                        }
                        {
                            (elementSelect && pensionesAlimSaved.length > 0 && !mostrarFormAddEdit)
                            ?   <div className="formRelLab">
                                    <div className="form-left">
                                        <Button variant="outlined" className="btn-outline" onClick={e => limpiaSelected(e)}>Cancelar</Button>
                                    </div>
                                    <div className="form-right">
                                        <Button variant="contained" className="btn-color" type="submit">{(!elementSelect || pensionesAlimSaved.length === 0 || (pensionesAlimSaved.length > 0 && mostrarFormAddEdit)) ? 'Guardar' : 'Actualizar' }</Button>
                                    </div>
                                </div>
                            :   <Button variant="contained" className="btn-color" type="submit">Guardar</Button>
                        }
                    </form>
                :   <Fragment>
                        <br />
                        <BtnsInfoAdicional numForm={5} irInfo={irInfo}/>
                        <div className="formRelLab">
                            <div className="form-left">
                                <Button variant="outlined" className="btn-outline" onClick={e => irInfo(e, 2)}>Atrás</Button>
                            </div>
                            <div className="form-right">
                                <Button variant="outlined" className="btn-outline" onClick={e => abrirFormAddEdit(e)}>Agregar</Button>
                            </div>
                        </div>
                    </Fragment>
            }
            {   elementSelect &&
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviaTnfo}/>
            }
        </Fragment>
    );
}
 
export default FormPensionesAlimenticias;
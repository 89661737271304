import React, { useState } from 'react';

import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Settings from '@material-ui/icons/Settings';
import VpnKey from '@material-ui/icons/VpnKey';

import FormularioInformacion from './FormularioInformacion';
import FormularioContrasena from './FormularioContrasena';

const ModificarInformacion = ({ usuario, setUsuario }) => {
    const [ openForm, setOpenForm ] = useState( false );
    const [ formActivo, setFormActivo ] = useState( '' );

    const seleccionarFormulario = ( nombreForm ) => {
        if( openForm ){
            if( formActivo !== nombreForm ){
                setFormActivo( nombreForm );
            }else{
                setOpenForm( false );
            }
        }else{
            setOpenForm( true );
            setFormActivo( nombreForm );
        }
    }
    return (
        <React.Fragment>
            <Tooltip title="Actualizar Contraseña" placement="left-start" style={{ float: "right" }} onClick={ () => seleccionarFormulario( 'Contraseña' ) } >
                <IconButton> <VpnKey/> </IconButton>
            </Tooltip>

            <Tooltip title="Modificar Información" placement="left" style={{ float: "right" }} onClick={ () => seleccionarFormulario( 'Informacion' ) } >
                <IconButton> <Settings/> </IconButton>
            </Tooltip>

            <h4> {`${usuario.nombre} ${usuario.apaterno} ${usuario.amaterno}`} </h4>
            <p> {usuario.usuario} </p>

            { openForm &&
                <React.Fragment>
                    <div style={{ display: formActivo === 'Informacion' ? 'block' : 'none' }}>
                        <FormularioInformacion usuario={usuario} setUsuario={setUsuario} setOpenForm={setOpenForm}/>
                    </div>

                    <div style={{ display: formActivo === 'Contraseña' ? 'block' : 'none' }}>
                        <FormularioContrasena setOpenForm={setOpenForm}/>
                    </div>
                </React.Fragment>
            }
        </React.Fragment>
    );
};

export default ModificarInformacion;
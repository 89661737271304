import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { servicios_grupogack_catalogo, niveles_ } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';


const Niveles = ({ id, permisos }) => {

    const [niveles, setNiveles] = useState(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupogack_catalogo}/nvlaboral/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    const arrayNiveles = res.data.dataResponse.map(item => ( { id: item.id, nivlaboralClave: item.nivlaboralClave, statusNom: item.status.statusNom } ))
                    setNiveles(arrayNiveles);
                    break;
                case 404:
                    setNiveles([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]); 

    return (
        
        <div id={ id }>
            {
                 niveles   
                ? <DataTableService id={id}
                    title={ niveles_ }
                    permisos={permisos}
                    columns={[
                        { field: "nivlaboralClave", title: "Clave Nivel Laboral" },
                        { field: "statusNom", title: "Estado" },
                    ]}
                    data={niveles}/>
                : <SpinnerOval/>
            }
        </div>

    );
}
 
export default Niveles;
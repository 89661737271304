import React, { createRef, useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit } from '../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../Services/Validation/SelectValidation';   
import { servicios_grupogack_catalogo,registro_guardado, registro_sera_actualizado, servicios_grupogack_nomina} from '../../../../../../Constants/Constants'; 
import Axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { ArrayJsonSalarioMinUMA, ArrayJsonIndicadoresGeneralesByRelLab } from '../../../Helpers/JsonToOneLevel'; 
import Checkbox from '@material-ui/core/Checkbox';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core';
import AutocompleteComponent from '../../../../../../Services/Autocomplete/AutocompleteComponent';
import AlertForm from '../../../../../../Services/AlertForm';
import { obtenerModalAdd, obtenerModalEdit } from '../../../DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE'; 
import AlertWarning from '../../../../../../Services/Alerts/AlertWarning';


const theme = createMuiTheme({
    palette: {
        primary: { main: '#4C638E' },
        secondary: { main: '#1572E8' },
    },
});
 

const FormularioIndicadores = ({ error, enviarIndicador, elementSelect, idModulo, actualizarIndicador, informacion, informacionAdicional, indicadoresGeneral }) => { 
    
    const [ errorAuto, setErrorAuto ] = useState( null );
    
    const nombreReciboRef = createRef(false); 
    const prioridadRef = createRef(''); 
    const rutinaCalcRef = createRef(''); 
    const calcSobreRef = createRef('');
    const importeRef = createRef(0);
    const topeVecesRef = createRef(0);  

    const [ apareceRecib , setApareceRecib ]= useState( elementSelect ? elementSelect.nomRecibo === 'Sí' : false );
    const [ dataConfigBase, setDataConfigBase ] = useState(null);
    const [ indicadorAuto, setIndicadorAuto ] = useState( null );
    const [ indicadorAutoExento, setIndicadorAutoExento ] = useState( null ); 
    const [ indicadoresGenerales, setIndicadoresGenerales ] = useState(null);
    const [ infoIndicador , setInfoIndicador ]= useState(elementSelect ? elementSelect : null );
    const [ rutinas , setRutinas ]= useState(null);
    const [ updateIndicadorParametros, setUpdateIndicadorParametros ] = useState(false);

    const [ exentoReq , setExentoReq ]= useState(false);
    const [salarioMinimos, setSalarioMinimos] = useState(null);
    const [ montoZona , setMontoZona ]= useState(1);

    const [ openAdd, setOpenAdd ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState(''); 
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ defValIndicador, setDefIndicador ] = useState(null);
    const [ idIndicadorParametro, setIdIndicadorParametro ] = useState(null);
    const [ defValTopVec, setDefValTopVec ] = useState(null);
    const [ defValCalcSob, setDefCalcSob ] = useState(null); 
    const [ defValMonto, setDefValMonto ] = useState(null);
    const [openAlert, setOpenAlert] = useState(false); 

    useEffect(() => {
        Axios.get(`${servicios_grupogack_catalogo}s/salario/minimouma/`, headersAuth())
        .then((res) => {
            switch(res.data.status) {
                case 200:
                    setSalarioMinimos(ArrayJsonSalarioMinUMA(res.data.dataResponse)); 
                    break;
                case 404:
                    setSalarioMinimos([]);
                    break;
                default:
                    alert('error');
                    break;
            } 
        })
        .catch(error => alert(error));
        
        Axios.get(`${servicios_grupogack_catalogo}/indicador/getall/relab`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    setIndicadoresGenerales(ArrayJsonIndicadoresGeneralesByRelLab(res.data.dataResponse));
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));

        Axios.get(`${servicios_grupogack_catalogo}/rutina/`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200: 
                    setRutinas( res.data.dataResponse );
                    break;
                case 404:
                    setRutinas([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error')); 

            if(elementSelect){ 
                Axios.get(`${servicios_grupogack_nomina}/indicadores/parametros/getall/${elementSelect.id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            const resultado = res.data.dataResponse[0]; 
                            let calcSobreNew= informacionAdicional.filter( element => element.id === parseInt(resultado.zona));
                            setDefValMonto(parseFloat(resultado.topVeces) * calcSobreNew[0].monto);
                            setIdIndicadorParametro( resultado.id );
                            setDefValTopVec( resultado.topVeces );
                            setDefCalcSob( parseInt(resultado.zona) ); 
                            let indicadorJson= indicadoresGeneral.filter( element => element.id === resultado.nomindid.indicador.indicadorId)[0];
                            setIndicadorAutoExento(indicadorJson);
                            setIndicadorAuto(indicadorJson); 
                            setDefIndicador(indicadorJson); 
                            setUpdateIndicadorParametros(true);
                            setExentoReq( true ); 
                            break;
                        case 404:
                            setUpdateIndicadorParametros(false);
                            break;
                        default:
                            setUpdateIndicadorParametros(false);
                            break;
                    }
                })
                .catch(error => setUpdateIndicadorParametros(false)); 
            }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]); 
    
    const getExentoRequ = (id)=>{
        Axios.get(`${servicios_grupogack_nomina}/indicadores/parametros/${id}`, headersAuth())
                .then(res => {
                    switch(res.data.status) {
                        case 200:
                            setUpdateIndicadorParametros(true);
                            let response= res.data.dataResponse;
                            let calcSobreNew= informacionAdicional.filter( element => element.zonaId === parseInt(response.zona));
                            setDefValMonto(parseFloat(response.topVeces) * calcSobreNew[0].monto);
                            setIdIndicadorParametro( response.nomindIndicador );
                            setDefValTopVec( response.topVeces );
                            setDefCalcSob( calcSobreNew[0].id );
                            let indicadorJson= indicadoresGeneral.filter( element => element.id === response.id)[0];
                            setIndicadorAutoExento(indicadorJson); 
                            setIndicadorAuto(indicadorJson.id); 
                            setDefIndicador(indicadorJson); 
                            setExentoReq( true );
                            break;
                        case 404:
                            setUpdateIndicadorParametros(false);
                            break;
                        default:
                            setUpdateIndicadorParametros(false);
                            break;
                    }
                })
                .catch(error => setUpdateIndicadorParametros(false));
    }

    const getInfoIndicador = ( ref ) => {
         
        if( ref !==null){
            let indicadorIndex = indicadoresGenerales.findIndex( (element) => element.id === parseInt(ref.id));
            if(indicadorIndex!==-1){ 
                nombreReciboRef.current.value=indicadoresGenerales[indicadorIndex].indicadorNom;
                rutinaCalcRef.current.value=indicadoresGenerales[indicadorIndex].rutinaId === null ? "" :parseInt(indicadoresGenerales[indicadorIndex].rutinaId.id);
                prioridadRef.current.value=indicadoresGenerales[indicadorIndex].prioridad;
                setApareceRecib(indicadoresGenerales[indicadorIndex].recibo); 
                getExentoRequ(ref.id);
                let info= {indicadorClave: indicadoresGenerales[indicadorIndex].indicadorClave,
                    indicadorNom: indicadoresGenerales[indicadorIndex].indicadorNom,
                    relacionLabNombre: indicadoresGenerales[indicadorIndex].relacionLabNombre,
                    tipoNatuNom: indicadoresGenerales[indicadorIndex].tipoNatuNom,
                    indicadorSat: indicadoresGenerales[indicadorIndex].indicadorSat,
                    clasificaNom: indicadoresGenerales[indicadorIndex].clasificaNom,
                }

                setIndicadorAuto(ref.id); 
                setInfoIndicador(info);
            }else{
                setInfoIndicador(null);
            } 
        }else{
            setInfoIndicador(null);
        } 
        
    };

    const deleteOptionals =( objectRequest )=>{
        var temporal = objectRequest;  
        Object.keys( temporal ).forEach( key => { 
            if( temporal[key] === " " ){
                delete temporal[key];
            }
        });
        return temporal;
    }
 
    const enviar = (evt) => {
        evt.preventDefault();
        if(indicadorAuto === null) {
            setErrorAuto(<AlertForm message="Complete los campos" />); 
        }else{
            if( validarSubmit( 'AgregarIndicador' ) ){ 
                
                const nominaId = informacion.id;
                const indicadorId = parseInt(indicadorAuto);
                const nominClave = infoIndicador.indicadorClave;
                const nominIndicador = nombreReciboRef.current.value;
                const rutinaId = parseInt(rutinaCalcRef.current.value);
                const prioridad = parseInt(prioridadRef.current.value);
                const nominaRecibo = apareceRecib;
                var indicadorParametros=null; 
                
                if(exentoReq){ 
                    const indicador = indicadorAutoExento.id;
                    const zonaId = parseInt(calcSobreRef.current.value);
                    const topVeces = parseInt( topeVecesRef.current.value );
                    const nomindId= informacion.id;
                    indicadorParametros={ nomindId, indicador, zonaId, topVeces }; 
                } 
                
                const dataConf=[];
                for (const key in dataConfigBase) {
                    dataConf.push(deleteOptionals(dataConfigBase[key]));
                } 

                enviarIndicador({ nominaId, indicadorId, nominClave, nominIndicador, prioridad, nominaRecibo, rutinaId }, dataConf.length>0 ? {basesIndicadores: [dataConf]} : null, indicadorParametros );
            }
        }
        
    }

    const actualizar = () => {
        if( validarSubmit( 'EditarIndicador' ) ){
 
            const id = elementSelect.id;
            const nominClave = elementSelect.nomClaveIndicador;
            const nominIndicador = nombreReciboRef.current.value;
            const rutinaId = parseInt(rutinaCalcRef.current.value);
            const prioridad = parseInt(prioridadRef.current.value);
            const nominaRecibo = apareceRecib; 

            var indicadorParametros=null;
            if(exentoReq){ 
                const indicador = indicadorAutoExento.id;
                const zonaId = parseInt(calcSobreRef.current.value);
                const topVeces = parseInt( topeVecesRef.current.value );
                indicadorParametros={ indicador, zonaId, topVeces };
                if(updateIndicadorParametros){
                    indicadorParametros.id = idIndicadorParametro;
                }
            } 

            actualizarIndicador( { id, nominClave, nominIndicador, prioridad, nominaRecibo, rutinaId }, indicadorParametros, updateIndicadorParametros );
        }
    } 
    
    const indicadorAutocomplete = valor => {
        setIndicadorAutoExento(valor);
    }
    const updateTopeVeces = ( value ) => {         
        importeRef.current.value = value!=='' ? parseFloat(value) * montoZona : 0 ;
    }

    const openConfig = (e) => {
        e.preventDefault();
        setOpenAdd(true);
    }
    const saveConfig = ( data ) =>{
        if(data===null){
            setTextAlert( 'Registro Actualizado' );
            setAlertSuccess( true );     
        }else{ 
            setTextAlert( registro_guardado );
            setAlertSuccess( true ); 
            setDataConfigBase( data );
        }

    } 
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }

    const successTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertSuccess( true );
    }

    const getMontoZona = ( zona  ) => {  
        var montoZon=0;  
        let calcSobreNew= salarioMinimos.filter( element => element.id === parseInt(zona)); 
        if(calcSobreNew.length>0){
            montoZon=calcSobreNew[0].monto;  
            setMontoZona(montoZon); 
        }else{
            setMontoZona(0);
        } 
        importeRef.current.value= topeVecesRef.current.value !=='' ? parseFloat(topeVecesRef.current.value) * montoZon : 0 ; 
    }
    const mostrarAlert = e => {
        e.preventDefault();
        setOpenAlert(true);
    };
    
    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarIndicador' : 'AgregarIndicador' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                
                {
                    !elementSelect
                        ?
                        <div className="row">
                            <div className="col-md-12 col-sm-12">
                                {
                                    indicadoresGenerales
                                    ?
                                    <AutocompleteComponent
                                    id="cPresupuestal"
                                    title="Concepto"
                                    options={indicadoresGenerales}
                                    action={getInfoIndicador}
                                    optionListView="indicadorNomClve"
                                    placeholder="Concepto"
                                    size="100%"
                                    tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                    tooltipName="tooltipAutoIndGral"
                                    required />
                                    :
                                    null
                                }
                                
                            </div>
                        </div>
                    :
                    null
                }
                
                {
                    infoIndicador || elementSelect
                    ? 
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <strong>Clave: </strong><label>{elementSelect ? elementSelect.nomClaveIndicador : infoIndicador.indicadorClave}</label><br/>
                            <strong>Nombre: </strong><label>{elementSelect ? elementSelect.nomIndicador : infoIndicador.indicadorNom}</label><br/>
                            <strong>Relación Laboral: </strong><label>{elementSelect ? elementSelect.relacionLaboral :  infoIndicador.relacionLabNombre}</label><br/>
                            <strong>Naturaleza: </strong><label>{ elementSelect ? elementSelect.nomNaturaleza :  infoIndicador.tipoNatuNom}</label><br/>
                            <strong>Indicador SAT: </strong><label>{elementSelect ? elementSelect.indicadorSat :  infoIndicador.indicadorSat}</label><br/>
                            <strong>Clasificación SAT: </strong><label>{elementSelect ? elementSelect.clasificaSat :  infoIndicador.clasificaNom}</label><br/> 
                        </div>
                    </div>
                    :null
                }
                <hr/>  
 
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Nombre Recibo" type="nombreRecibo" placeholder="Escriba el nombre del recibo"
                            tooltipText={`Máximo 64 letras, números, espacios y/o signos de puntuación . , - ( ) " ' #`} tooltipName={'tooltipNomRecInd'} maxLength={64}
                            refValue={nombreReciboRef} defaultValue={ elementSelect ? elementSelect.nomIndicador : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        {
                            rutinas
                            ?
                                <SelectValidation title="Rutina de Cálculo" arrayOpciones={rutinas} keyOpcion="rutinaNom" defaultValue={ elementSelect ? elementSelect.rutinaId : '' }
                                    refValue={rutinaCalcRef} required />
                            :
                            null
                        }
                    </div>
                    
                </div> 
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <InputValidation title="Prioridad" type="prioridad" placeholder="Escriba el valor de la prioridad"
                            tooltipText={`Máximo 99999 números`} tooltipName={'tooltipPriorInd'} maxLength={99999}
                            refValue={prioridadRef} defaultValue={ elementSelect ? elementSelect.prioridad : '' } required onlyNumbers/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <button onClick={e => openConfig(e) }  className="form-control btn-third" style={{marginTop: "37px"}}>Bases</button>
                    </div> 
                </div>
                <MuiThemeProvider theme={theme}> 
                <div className="row">
                    <div className="col-md-6 col-sm-12"> 
                        <Checkbox  
                            onChange={()=>{setApareceRecib(!apareceRecib)}}
                            color="secondary"
                            checked={apareceRecib}
                        /> 
                        <label> Aparece en Recibo </label>
                    </div>
                    <div className="col-md-6 col-sm-12"> 
                        <Checkbox 
                            onChange={()=>{setExentoReq(!exentoReq)}}
                            color="secondary"
                            checked={exentoReq}
                        />  
                        <label> Exento Requerido </label>
                    </div>
                </div>
                </MuiThemeProvider>
                {
                    exentoReq
                    ?
                    <div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            {
                                indicadoresGenerales
                                ?
                                <AutocompleteComponent
                                id="IndicadoresGeneralesInGral"
                                title="Concepto"
                                options={indicadoresGenerales}
                                action={indicadorAutocomplete}
                                optionListView="indicadorNomClve"
                                placeholder="Concepto"
                                size="100%"
                                tooltipText="Busque el Concepto, por nombre o clave, en el campo de autocompletado"
                                tooltipName="tooltipAutoIndGral"
                                required 
                                defaultValue={defValIndicador}
                                />
                                : null
                            }
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            {
                                salarioMinimos
                                ?
                                    <SelectValidation title="Cálculo Sobre" arrayOpciones={salarioMinimos} keyOpcion="zonaNom"
                                        refValue={calcSobreRef} required onChangeExecute={getMontoZona}
                                        defaultValue={ updateIndicadorParametros ? defValCalcSob : '' }/>
                                :
                                null
                            }
                        </div>
                        <div className="col-md-6 col-sm-12"> 
                            <InputValidation title="Tope en Veces" type="topeVeces" placeholder="Escriba el Tope en Veces"
                                tooltipText={'Máximo 999999 números'} tooltipName={'tooltipNomRecInd'} maxLength={999999}
                                refValue={topeVecesRef} defaultValue={ updateIndicadorParametros ? defValTopVec : '' } required onChangeExecute={updateTopeVeces}/>
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12">
                            <InputValidation title="Importe (MXN)"  type="monto"
                                tooltipText={'Importe'} tooltipName={'tooltipImpo'} refValue={ importeRef } defaultValue={ updateIndicadorParametros ? defValMonto : 0 } disabled optional/>
                        </div>
                    </div> 
                    </div>
                    : null 
                } 
                {error}
                {errorAuto}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            { !elementSelect ? obtenerModalAdd( idModulo+"config", openAdd, setOpenAdd, saveConfig, informacion, errorTable, successTable, dataConfigBase ) : null }
            { elementSelect ? obtenerModalEdit( idModulo+"config", openAdd, setOpenAdd, elementSelect, saveConfig, null, errorTable, successTable, dataConfigBase ) : null }
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
        </React.Fragment>
    );
};

export default FormularioIndicadores;
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './styles.css';

export function pushInfo( push ){
    if( comprobar( push.message ) ){
        NotificationManager.info( push.message, push.title ? push.title : 'Información', push.time && push.time, push.action && push.action );
    }
}

export function pushSuccess( push ){
    if( comprobar( push.message ) ){
        NotificationManager.success( push.message, push.title ? push.title : 'Exito', push.time && push.time, push.action && push.action );
    }
}

export function pushWarning( push ){
    if( comprobar( push.message ) ){
        NotificationManager.warning( push.message, push.title ? push.title : 'Alerta', push.time && push.time, push.action && push.action );
    }
}

export function pushError( push ){
    if( comprobar( push.message ) ){
        NotificationManager.error( push.message, push.title ? push.title : 'Error', push.time && push.time, push.action && push.action );
    }
}

/* Funcion para comprobar que no exista ningun elemento con el mismo mensaje */
function comprobar( mensaje ){
    const notificaciones = document.getElementsByClassName( "notification-message" );
    if( notificaciones.length === 0 ){
        return true;
    }else{
        var noExist = true;
        Array.from( notificaciones ).forEach( push => {
            if( push.children[1].textContent === mensaje ){
                noExist = false;
            }
        });
        return noExist;
    }
}
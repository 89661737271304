import React, { Fragment } from 'react';
import ConvertDate from '../../../../Helpers/ConvertDate';
import ModalService from '../../../../ModalService/ModalService';
import Formulario from '../components/Formulario';

const ModalCancelarTimbrados = ({ nombreModulo, open, setOpen, nombre, setOpenLoading, setData, setMostrarTabla }) => {

    const cerrarModal = () => {
        setOpen(false);
    };

    const showTable = () => {
        setOpenLoading(true);

        setTimeout(() => {
            setOpenLoading(false);
            setData({
                title:  <Fragment>
                            <strong>CVE- INTERNA</strong>{' '}<strong>Año:</strong>{' 2020 '}<strong>Periodo:</strong> {' 9 '}
                        </Fragment>,
                data: [
                    {
                        numRegistro: 1,
                        numEmpleado: 112245,
                        rfcEmisor: 'SCP020529L18',
                        nombre: 'Pedro Duana',
                        acumulado: 'Nómina',
                        uuid: '4A1B43E2-1183-4AD4-A3DE-C2DA787AE56A',
                        versionCFDI: 3.3,
                        fecha: ConvertDate(Date.now())
                    },
                    {
                        numRegistro: 2,
                        numEmpleado: 112246,
                        rfcEmisor: 'SCP020529L18',
                        nombre: 'Javier Solís',
                        acumulado: 'Nómina',
                        uuid: '5A1F43E2-1125-4RD4-A1HE-C2DA486AD85C',
                        versionCFDI: 3.3,
                        fecha: ConvertDate(Date.now())
                    },
                    {
                        numRegistro: 3,
                        numEmpleado: 112247,
                        rfcEmisor: 'SCP020529L18',
                        nombre: 'Roberto Andrade',
                        acumulado: 'Nómina',
                        uuid: '4C1F23E2-1835-2RF4-B6TE-D2DA436A8G5Q',
                        versionCFDI: 3.3,
                        fecha: ConvertDate(Date.now())
                    },
                    {
                        numRegistro: 4,
                        numEmpleado: 112248,
                        rfcEmisor: 'SCP020529L18',
                        nombre: 'Pablo Arteaga',
                        acumulado: 'Nómina',
                        uuid: '5B1F45K2-2463-2TR4-Y5TS-W1DA636N8J7W',
                        versionCFDI: 3.3,
                        fecha: ConvertDate(Date.now())
                    }
                ]
            });
            setMostrarTabla(true);
            cerrarModal();
        }, 3000);
    };

    return (
        <ModalService
            title="Cancelar Timbrados"
            parentNode={nombreModulo}
            isOpen={open}
            onClose={cerrarModal}
            maxWidth="55%"
            minWidth="55%"
        >
            <div className="row">
                <div className="col-12">
                    <Formulario
                        nombre={nombre}
                        action={showTable}
                    />
                </div>
            </div>
        </ModalService>
    );
}
 
export default ModalCancelarTimbrados;
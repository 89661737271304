//convierte una fecha en milisegundos a dd/mm/aaaa
export default function ConvertDate(miliseconsDate) {
    const date = new Date(miliseconsDate);
    
    const anio = date.getUTCFullYear();
    const mes = (date.getUTCMonth()+1 > 9) ? date.getUTCMonth()+1 : `0${date.getUTCMonth()+1}`;
    const dia = (date.getUTCDate() > 9) ? date.getUTCDate() : `0${date.getUTCDate()}`;

    return `${dia}/${mes}/${anio}`;

};
import React, { Component, Fragment } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { isAuthenticaded, cerrarSession } from '../../Services/AuthService';

class RouteProtected extends Component {
    render() {
        return (
            isAuthenticaded()
            ? <Route {...this.props} />
            :   <Fragment>
                    { cerrarSession }
                    <Redirect to="/acceso"/>
                </Fragment>
        )
    }
}

export default withRouter( RouteProtected );
import React, { useState, createRef } from 'react';
import ModalService from '../../../../ModalService/ModalService';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';
import { validarSubmit, inputDateToLong } from '../../../../../../../Services/Validation/HelperValidation';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';
import Button from '@material-ui/core/Button';
import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';

const ModalOrganizacionEmpleadoEdit = ({ modalIsOpen, setModalIsOpen, actualizar, setDisabledFormOrgEdit, disabledFormOrgEdit, elementSelect, selected, idTurno, turnos, turnoRef, nominas, nominaRef, zonasPago, zonaPagoRef }) => {

    const [openAlert, setOpenAlert] = useState(false);

    const cPresupuestalFechaRef = createRef('');
    const plazaFechaRef = createRef('');
    const zonaPagoFechaRef = createRef('');
    const puestoFechaRef = createRef('');
    const turnoFechaRef = createRef('');
    const nominaFechaRef = createRef('');

    const mostrarAlert = e => {
        e.preventDefault();
        if(validarSubmit('OrganizacionEmpleadoFechas')) {
            setOpenAlert(true);
        }
    };

    const cerrarModal = () => {
        setModalIsOpen(false);
    };

    const enviarActualizar = () => {

        let info = {};

        if(validarSubmit('OrganizacionEmpleadoFechas')) {
            if(!disabledFormOrgEdit.cPresupuestal) {
                elementSelect.cpresupuestalNom = selected.cPresupuestal.nombre;
                info = { ...info, cPresupuestal: { empleadoid: elementSelect.id, cpresupuestalid: selected.cPresupuestal.id, empcpresfecini: inputDateToLong(cPresupuestalFechaRef.current.value) } };
            }

            if(!disabledFormOrgEdit.departamento) {
                elementSelect.areaNom = selected.departamento.nombre;
            }

            if(!disabledFormOrgEdit.plaza) {
                elementSelect.plazaClave = selected.plaza.clave;
                info = { ...info, plaza: { empId: elementSelect.id, plazaId: selected.plaza.id, empFecIni: inputDateToLong(plazaFechaRef.current.value) } };
            }

            if(!disabledFormOrgEdit.puesto) {
                elementSelect.puestoNom = selected.puesto.nombre;
                info = { ...info, puesto: { empId: elementSelect.id, puestoId: selected.puesto.id, empPuestoFecIni: inputDateToLong(puestoFechaRef.current.value) } };
            }

            if(!disabledFormOrgEdit.zonaPago) {
                const zonapago = zonasPago.find( zpago => zpago.id === parseInt(zonaPagoRef.current.value));
                elementSelect.domciaCalle = zonapago.domCiaCalle;
                elementSelect.domciaNumext = zonapago.domCiaNumExt;
                elementSelect.domciaNumint = zonapago.domCiaNumInt;
                info = { ...info, zonaPago: { empId: elementSelect.id, domciaId: zonapago.id, empzpfecini: inputDateToLong(zonaPagoFechaRef.current.value) } };
            }
            
            if(!disabledFormOrgEdit.turno) {
                const turno = turnos.find( turno => turno.id === parseInt(turnoRef.current.value));
                elementSelect.turnoNom = turno.turnoNom;
                info = { ...info, turno: { id: idTurno, empId: elementSelect.id, turnoId: turno.id, fecini: inputDateToLong(turnoFechaRef.current.value) } };
            }

            if(!disabledFormOrgEdit.nomina) {
                const nomina = nominas.find( nomina => nomina.id === parseInt(nominaRef.current.value));
                elementSelect.nominaNombre = nomina.nominaNombre;
                info = { ...info, nomina: { empleadoid: elementSelect.id, nominaid: nomina.id, empnominafecini: inputDateToLong(nominaFechaRef.current.value) } };
            }

            cerrarModal();
            actualizar(info);
            setDisabledFormOrgEdit({ cPresupuestal: true, departamento: true, plaza: true, zonaPago: true, puesto: true, turno: true, nomina: true });
        }
    };

    return (
        <ModalService
            title="Actualizar Empleado"
            parentNode="organigrama"
            isOpen={modalIsOpen}
            onClose={cerrarModal}
            minWidth="55%"
            maxWidth="55%"
        >
            <div className="col-12">
                <form id="OrganizacionEmpleadoFechas" onSubmit={mostrarAlert}>
                    { !disabledFormOrgEdit.cPresupuestal &&
                        <InputDateValidation title="Clave Presupuestal / Proyecto" refValue={cPresupuestalFechaRef} required/>
                    }
                    { !disabledFormOrgEdit.plaza &&
                        <InputDateValidation title="Plaza" refValue={plazaFechaRef} required/>
                    }
                    { !disabledFormOrgEdit.zonaPago &&
                        <InputDateValidation title="Zona de Pago" refValue={zonaPagoFechaRef} required/>
                    }
                    { !disabledFormOrgEdit.puesto &&
                        <InputDateValidation title="Puesto" refValue={puestoFechaRef} required/>
                    }
                    { !disabledFormOrgEdit.turno &&
                        <InputDateValidation title="Turno" refValue={turnoFechaRef} required/>
                    }
                    { !disabledFormOrgEdit.nomina &&
                        <InputDateValidation title="Nómina" refValue={nominaFechaRef} required/>
                    }
                    <Button variant="contained" className="btn-color" type="submit">Actualizar</Button>
                </form>
                <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={enviarActualizar}/>
            </div>
        </ModalService>
    );
}
 
export default ModalOrganizacionEmpleadoEdit;
import React, { createRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import { validarSubmit, inputDateToLong, longDateToInput, amountToFloat } from '../../../../../../../Services/Validation/HelperValidation';
import InputValidation from '../../../../../../../Services/Validation/InputValidation';
import SelectValidation from '../../../../../../../Services/Validation/SelectValidation';
import AutocompleteComponent from '../../../../../../../Services/Autocomplete/AutocompleteComponent';
import InputDateValidation from '../../../../../../../Services/Validation/InputDateValidation';

import AlertWarning from '../../../../../../../Services/Alerts/AlertWarning';
import { registro_sera_actualizado } from '../../../../../../../Constants/Constants';

import AlertForm from '../../../../../../../Services/AlertForm';

const FormularioMovimientos = ({ error, enviarMovimiento, elementSelect, actualizarMovimiento, informacion }) => {
    const [ openAlert, setOpenAlert ] = useState( false );
    const [ nominaIndicador, setNominaIndicador ] = useState( null );
    const [ errorIndicador, setErrorIndicador ] = useState( null );
    const cantidadRef = createRef('');
    const montoRef = createRef('');
    const acumuladoRef = createRef('');
    const fechaAplicacionRef = createRef('');

    const mostrarAlert = (evt) => {
        evt.preventDefault();
        setOpenAlert(true);
    };

    const enviar = (evt) => {
        evt.preventDefault();
        if( validarSubmit( 'AgregarMovimiento' ) ){
            if( nominaIndicador ){
                const nomina = nominaIndicador.id;
                const cantidad = parseFloat(cantidadRef.current.value);
                const monto = amountToFloat(montoRef.current.value);
                const acumulado = parseInt( acumuladoRef.current.value );
                const fechaAplicacion = inputDateToLong( fechaAplicacionRef.current.value );
                //console.log({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaAplicacion });
                enviarMovimiento({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaAplicacion });
            }else{
                setErrorIndicador( <AlertForm message="Completa todos los campos" /> );
            }
        }
    };

    const actualizar = () => {
        if( validarSubmit( 'EditarMovimiento' ) ){
            const nomina = elementSelect.nomina.id;
            const cantidad = parseFloat(cantidadRef.current.value);
            const monto = amountToFloat(montoRef.current.value);
            const acumulado = parseInt( acumuladoRef.current.value );
            const fechaAplicacion = inputDateToLong( fechaAplicacionRef.current.value );
            //console.log({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaAplicacion });
            actualizarMovimiento({ empleado: informacion.empleado.id, nomina, cantidad, monto, acumulado, fechaAplicacion }, elementSelect.id);
        }
    };

    const validarIndicador = (valor) => {
        if(valor) {
            //console.log( valor );
            setErrorIndicador( null );
            setNominaIndicador( valor );
        }else{
            setErrorIndicador( true );
            setNominaIndicador( null );
        }
    };

    return (
        <React.Fragment>
            <form id={ elementSelect ? 'EditarMovimiento' : 'AgregarMovimiento' } onSubmit={ elementSelect ? mostrarAlert : enviar }>
                { elementSelect ?
                    <div> <b> Concepto de Nómina: </b> {elementSelect.nomina.nomIndicador} </div>
                    :
                    <AutocompleteComponent id="indicadorVariable" title="Concepto de Nómina" placeholder="Seleccione el Concepto de Nómina" size="100%"
                        tooltipName="tooltipIndicadorVariable" tooltipText={'Busque el Concepto, por nombre o clave, en el campo de autocompletado'} action={validarIndicador}
                        options={informacion.indicadoresNomina} optionListView={'nomIndicador'} optionKey="nomClave" error={errorIndicador} required/>
                }

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Cantidad" type="cantidad" placeholder="Digite la cantidad del movimiento variable"
                            tooltipText={'Máximo 16 números'} tooltipName={'tooltipCantMovi'} maxLength='16' onlyNumbers
                            refValue={cantidadRef} defaultValue={ elementSelect ? elementSelect.cantidad : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputValidation title="Monto (MXN)" type="monto" placeholder="Digite el monto del movimiento variable"
                            tooltipText={'Máximo 16 números y 2 decimales'} tooltipName={'tooltipMontMovi'} maxLength='19'
                            refValue={montoRef} defaultValue={ elementSelect ? elementSelect.monto : '' } required
                            onlyNumbers="." onBlurDecimal={2} isAmount/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6 col-sm-12">
                        <SelectValidation title="Tipo de Acumulado" arrayOpciones={informacion.tiposAcumulado} keyOpcion="acumuladoNombre"
                            refValue={acumuladoRef} defaultValue={ elementSelect ? elementSelect.acumulado.id : '' } required/>
                    </div>
                    <div className="col-md-6 col-sm-12">
                        <InputDateValidation title="Fecha de Aplicación" refValue={fechaAplicacionRef} required
                            defaultValue={ elementSelect ? longDateToInput(elementSelect.fechaAplicacion) : '' } />
                    </div>
                </div>

                {error}
                {errorIndicador}
                <Button variant="contained" className="btn-color" type="submit">{ elementSelect ? 'Actualizar' : 'Agregar' }</Button>
            </form>
            <AlertWarning show={openAlert} SetopenALert={setOpenAlert} text={registro_sera_actualizado} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={actualizar}/>
        </React.Fragment>
    );
};

export default FormularioMovimientos;
import React from 'react';

const Footer = ({className}) => {
    return (""/*
        <footer className={className}>
            <p>
                <span className="footer-line1">© 2013 - 2020 D.R. GRUPO GACK México S. de R.L. de C.V.</span><br/>
                <span className="footer-line2">Prohibida la reproducción total o parcial de esta obra sin la previa autorización de su titular.</span>
            </p>
            <div className="footer-logo"></div>
        </footer>*/
    );
    }
 
export default Footer; 
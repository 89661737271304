import React, { useState, useEffect } from 'react'; 

import Axios from 'axios';
import { servicios_grupogack_catalogo, servicios_grupogack, cambios_no_guardados, campos_vacios, registro_sera_eliminado, registro_guardado} from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService';
import {  Delete} from '@material-ui/icons';
import {IconButton} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import '../../../../../../Components/Usuarios/Usuario/DataTableService/StyleDataTable.css';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';
import Alertwarning from '../../../../../../Services/Alerts/AlertWarning'; 
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { obtenerModalDelete } from '../../../../../../Components/Usuarios/Usuario/DataTableService/HerlperDialogs';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';
import { ArrayJsonTabuladoresSueldo } from '../../../Helpers/JsonToOneLevel'; 
import { amountToFloat, floatToAmount, MONTO} from '../../../../../../Services/Validation/HelperValidation';

const useStyles = makeStyles(()=> ({
    root: {
      color:'#4C638E'
    }
}));

const DinamicTable = ({puestos, tabuladoresBack, idTab, fechainic, onShowTable, idModulo, permisos}) => {
    
    const permisosUsuario = permisos.map(element=> element.id);
    const classes = useStyles();
    const [columnHeaderShow, setColumnHeaderShow] =useState(["Nivel", "Código Funcional", "Puesto" ]);
    const columnHeader =["nivel","codigo", "puesto"];
    const [columnsTable, setColumnsTable] =  useState(columnHeader);
    const [indicadoresGenerales, setIndicadoresGenerales] = useState(null);
    const [indicadoresGeneralesBack, setIndicadoresGeneralesBack] = useState(null);
    const [indicador, setIndicador]= useState('');
    const [idIndicador, setIdIndicador]= useState('');   
    var indexTab=tabuladoresBack.findIndex(col=> col.id===idTab);

    const [ elemmentSelect, setElemmentSelect ] = useState( {} );
    const [ textAlert, setTextAlert ] = useState('');
    const [ alertSuccess, setAlertSuccess ] = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ alertWarning, setAlertWarning ] = useState( false );
    const [ alertWarningLocal, setAlertWarningLocal ] = useState( false );
    
    const [ deleteColumn, setDeleteColumn ] = useState( false );
    const [ showEmptyData, setShowEmptyData ] = useState( false );
    const [ shouldUpdate, setShouldUpdate ] = useState( false );
    const [ shouldInsert, setShouldInsert ] = useState( false );
    const [ loading, setLoading ] = useState( false );
    const [idTabTemp, setIdTabTemp ] = useState( null );

    
    
    const obtenerRelacioLaboralLS = () => {
        return JSON.parse(localStorage.getItem("IGCompania"));
    };
      
    var fechaini=fechainic;
    const [data, setData] =useState([]);
    const [headerTab, setHeaderTab]= useState(false);

    var col=columnsTable;
    var colName=columnHeaderShow;
    const [ dta, setDta]= useState(data);
    const [ dtaBack, setDtaBack]= useState(data);

    
    useEffect(() => {

        if(idTab){
            setHeaderTab(tabuladoresBack[indexTab].fechafin !=='-'); 
                Axios.get(`${servicios_grupogack}/org/tab/detalle/${idTab}`, headersAuth())
                .then((res) => {   
                    switch(res.data.status) {
                        case 200:                 
                            for(var j=0; j<res.data.dataResponse[0].indicadores.length; j++){  
                                col.push(res.data.dataResponse[0].indicadores[j].id);
                                colName.push(res.data.dataResponse[0].indicadores[j].indicadorNom+" (MXN)   "); 
                            }   
                            const newDataTabuladores=ArrayJsonTabuladoresSueldo(res.data.dataResponse);
                            setDta(newDataTabuladores);
                            setDtaBack(newDataTabuladores);
                            setColumnHeaderShow(colName);
                            setColumnsTable(col);
                            break;
                        case 404:
                            setData(puestos.filter(col => col!=='puestoId'));
                            setDta(puestos.filter(col => col!=='puestoId'));
                            setDtaBack(puestos.filter(col => col!=='puestoId'));
                            break;
                        default:
                            alert('error');
                            break;
                    }
                })
                .catch(error => alert(error)); 
        }else{
            setData(puestos.filter(col => col!=='puestoId'));
            setDta(puestos.filter(col => col!=='puestoId'));
            setDtaBack(puestos.filter(col => col!=='puestoId'));
            setShouldInsert(true);
        }
        Axios.get(`${servicios_grupogack_catalogo}/indicador`, headersAuth())
        .then(res => {
            switch(res.data.status) {
                case 200:
                    const relacionLaboralLS = obtenerRelacioLaboralLS();
                    var indicadoresRel=[];
                    res.data.dataResponse.map((key)=>{
                        if(key.relacionLab.id===relacionLaboralLS.id && key!==undefined){
                            indicadoresRel.push([key]);
                        }
                        return null;
                    });
                    var objInd= indicadoresRel.filter(col  => !columnsTable.includes(col[0].id))
                    setIndicadoresGenerales(objInd);
                    setIndicadoresGeneralesBack(indicadoresRel);                     
                    break;
                case 404:
                    setIndicadoresGenerales([]);
                    setIndicadoresGeneralesBack([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert(error));
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);
    
    const replicateData =()=>{
        var key=elemmentSelect;
        var obj=dta;
        var newValue=isNaN(parseFloat(obj[0][key])) ? obj[0][key] : floatToAmount(obj[0][key], 2) ; 
        if(MONTO.test(newValue) && !/\$0.00/g.test(newValue)){ 
            for(var i=0; i<obj.length; i++){
                obj[i][key]=newValue;
                var name=`${i}-${key}`;
                var input = document.getElementsByName(name);
                if(input[0]!==undefined){
                    input[0].value = newValue;
                    input[0].className="inpTable minIn";
                }
            }
        }
        setDta(obj);
        setColumnHeaderShow(colName);
        setColumnsTable(col);
        if(!shouldInsert){
            setShouldUpdate(true);
        }
    }

   const alertReplicateData=(key)=>{
        setElemmentSelect(key);
        setTextAlert("¡Se aplicará este importe a todos los campos!");
        setAlertWarningLocal(true);
    }

    const del=(n)=>{ 
            const index = columnsTable.indexOf(n)
            const newColumnShow = columnHeaderShow.filter(col  => col !== columnHeaderShow[index]);
            setColumnHeaderShow(newColumnShow);

            const newColumn = columnsTable.filter(col  => col !== n);
            setColumnsTable(newColumn);

            const newIndicadoresGenerales = Object.assign({}, indicadoresGeneralesBack.filter(ind  => ind[0].id === parseInt(n)));
             setIndicadoresGenerales( indicadoresGenerales => [newIndicadoresGenerales[0], ...indicadoresGenerales ]);
            for(var i=0; i<dta.length; i++){
                delete dta[i][n];
            }
            for (let j = 0; j < dtaBack.length; j++) {
                delete dtaBack[j][n];
            }
            setDta(dta);
            setDtaBack(dtaBack);
            if(idTab){
                setShouldUpdate(true);
            }
            if(!shouldInsert){
                setShouldUpdate(true);
            }
    }
    const errorTable = ( mensaje ) => {
        setTextAlert(mensaje);
        setAlertError( true );
    }



//FILTRAR POR NIVELES
    const [niveles, setNiveles] = useState(null);

    useEffect(()=>{
        Axios.get(`${servicios_grupogack_catalogo}/nvlaboral/byrelab/`, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setNiveles(res.data.dataResponse);
                    break;
                case 404:
                    setNiveles([]);
                    break;
                default:
                    alert('error');
                    break;
            }
        })
        .catch(error => alert('error'));
    },[]); 

     const filterData= e =>{
        if(e !== null) {
            var obj=dtaBack;
            var newData=obj.filter(element=> element.nivel===e.nivlaboralClave);
            if(newData.length>0){
                setShowEmptyData(false);
                if(!headerTab){
                    refreshData2(newData);
                }else{
                    setDta(newData);
                }            
            }else{
                setDta([]);
                setShowEmptyData(true);
            }
        }else{
            setDta(dtaBack);
            if(!headerTab){
                refreshData();
            }
        } 
    }


    async function updateDta2(newData){
        setDta(newData);
        return;
      };

    async function refreshData2(newData){
        await updateDta2(newData);
        refresh2(newData);
    };

    const refresh2=(newData)=>{
        var inputClass;
        newData.map((key, index)=>{
            const llave=Object.keys(key);
            for (const i in llave) {
                if(llave[i]!== columnHeader[0] && llave[i] !== columnHeader[1] && llave[i] !== columnHeader[2] && llave[i] !== 'puestoId'){
                    var name=`${index}-${llave[i]}`;
                    var input = document.getElementsByName(name);
                    if(input[0]!==undefined){
                        input[0].value= newData[index][llave[i]]==='' ? '': MONTO.test( newData[index][llave[i]] ) && isNaN(newData[index][llave[i]]) ? newData[index][llave[i]] : floatToAmount( newData[index][llave[i]], 2);
                        if(!MONTO.test(input[0].value) || /\$0.00/g.test(input[0].value)){ 
                            inputClass="inpTable minIn is-invalid";
                        }else{
                            inputClass="inpTable minIn";
                        }
                        input[0].className=inputClass;
                    }
                }
            } 
            return null;  
        })  
    } 

    async function updateDta(){
        setDta(dtaBack);
        return;
      };

    async function refreshData(){
        await updateDta();
        refresh();
    };

    const refresh=()=>{
        var newDataUp=dta;
        var newData=Object.assign(newDataUp, dtaBack);
        newData.map((key, index)=>{
            const llave=Object.keys(key);
            for (const i in llave) {
                if(llave[i]!== columnHeader[0] && llave[i] !== columnHeader[1] && llave[i] !== columnHeader[2] && llave[i] !== 'puestoId'){
                    var name=`${index}-${llave[i]}`;
                    var input = document.getElementsByName(name);
                    if(input[0]!==undefined){
                        var inputClass;
                        input[0].value= newData[index][llave[i]]==='' ? '': MONTO.test( newData[index][llave[i]] ) && isNaN(newData[index][llave[i]]) ? newData[index][llave[i]] : floatToAmount( newData[index][llave[i]], 2);
                        if(!MONTO.test(input[0].value) || /\$0.00/g.test(input[0].value)){ 
                            inputClass="inpTable minIn is-invalid";
                        }else{
                            inputClass="inpTable minIn";
                        }
                        input[0].className=inputClass;
                    }
                }
            }
            return null;
        }); 
    }


    const defaultProps = {
        options: niveles,
        getOptionLabel: (options) => options.nivlaboralClave
    };
    const filterByLevel=()=>{
        if(niveles)
        return  <Autocomplete

        className={classes.root}
        {...defaultProps}
        
        id="auto-complete"
        autoComplete
        includeInputInList
        options={niveles}
        onChange={(e,v) => filterData(v)}
        renderInput={(params) => 
                <TextField
                    className={classes.root}
                    {...params}
                    id="mui-theme-provider-outlined-input"
                    label="Nivel"
                    margin="normal"
                    style={{ width: 120}}
                />}
        />  
    };

     const generateHeader=() =>{
        
       return columnsTable.map((key, index)=>{
         
       if(index<3 || headerTab)  return <th key={index} className="MuiTableCell-root negritas">{index===0 ? filterByLevel(): columnHeaderShow[index]}</th>
         
        return <th key={index} id={`${key}`} className="MuiTableCell-root negritas">
                    {columnHeaderShow[index]} {permisosUsuario.includes(3) && <IconButton  onClick={e => alertDelete(key)}><Delete fontSize="small"/></IconButton>}</th>
        })
     }

     const updateData=(key, value, index)=>{
        dta[index][key]=value==='' ? 0 : amountToFloat(value);
        if(idTab){
            setShouldUpdate(true);
        }
        if(!shouldInsert){
            setShouldUpdate(true);
        }            
     }


     const validNumber=(index, value, e)=>{ 
        if(isNaN(amountToFloat(value)) || value<1 || /\$0.00/g.test(value)){
            e.target.className="inpTable minIn is-invalid"; 
            e.target.value= floatToAmount(0, 2);
        }else{
            e.target.className="inpTable minIn";
            e.target.value= floatToAmount(amountToFloat(value), 2);
        }
     }

    const keysAllowed = (e) =>{
        return e.keyCode!==8 && e.keyCode!==9 && e.keyCode!==46 && !(e.keyCode >= 37 && e.keyCode <= 40 ) && !((e.keyCode === 65 || e.keyCode === 67 || e.keyCode === 86) && (e.ctrlKey || e.metaKey ));
    }

     const validarInput=e=>{
        
        const number = /^[0-9]$/; 
        if( !number.test(e.key) && keysAllowed(e) && (e.key!=='.' || e.target.value.includes('.')) ){
            e.stopPropagation();
            e.preventDefault();  
            e.returnValue = false;
            e.cancelBubble = true;
            return false;
        }
    }

      const generateTableData=()=>{

         let res=[];
         for(let i =0; i < dta.length; i++){
             
            res.push(
               <tr key={`${columnsTable[i]}-${i}`} className="MuiTableRow-root">
                  {
                    columnsTable.map((key, index)=>{
                        if(index<3 || headerTab) return <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft" style={{textAlign: index<3 ? 'left':'right'}}>{dta[i][key]}</td>

                        if(index>=3 && i===0 )
                           return   <td key={`${key}${index}${i}`} className={`MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min`}>
                                         <input type="text" id={i} onBlur={e=> {validNumber(e.target.id, e.target.value, e);}} name={`${i}-${key}`}
                                            onKeyDown = { e => { validarInput(e) } } style={{textAlign:'right'}}
                                            className={`inpTable minIn`}  defaultValue={`${dta[i][key]}`} onChange={e =>{updateData(key, e.target.value,  e.target.id );
                                         }}/>
                                         <IconButton onClick={()=>alertReplicateData(key)}>
                                            <Tooltip title="Aplicar mismo monto" placement="left-start" >
                                                <ArrowDropDownIcon fontSize="small"/>
                                            </Tooltip>
                                        </IconButton>
                                         
                                    </td>
                        return  <td key={`${key}${index}${i}`} className="MuiTableCell-root MuiTableCell-body MuiTableCell-alignLeft min">
                                    <input type="text" name={`${i}-${key}`} onBlur={e=> {validNumber(e.target.id, e.target.value, e)}}  className={`inpTable minIn`}
                                            onKeyDown = { e => { validarInput(e) } } style={{textAlign:'right'}}
                                            defaultValue={dta[i][key]} id={i} onChange={e =>updateData(key, e.target.value, e.target.id )}/>
                                </td>
                    })
                  } 
               </tr>
            )
         }
         return res;
     }
     const alertDelete=(n)=>{

        setElemmentSelect(n)
        if(idTab){
            setAlertWarning( true );
        }else{
            setTextAlert(registro_sera_eliminado);
            setDeleteColumn(true);
            setAlertWarningLocal(true);
        }
     }

     const delColumn=()=> {
        setAlertSuccess(true);
        setTextAlert('Registro Eliminado');
        del(elemmentSelect);
        setDeleteColumn(false);
      }

     const addData = () =>{
         for(let i=0; i<dta.length; i++){
            var dat=dta;
            dat[i][idIndicador] = floatToAmount(0, 2);
            setDta(dat);
         }
         for(let j=0; j<dtaBack.length; j++){
            var dataBack=dtaBack;
            dataBack[j][idIndicador] = floatToAmount(0, 2);
            setDtaBack(dataBack);
         }
         
         setColumnsTable( columnsTable => [...columnsTable, idIndicador]);
         setColumnHeaderShow( columnHeaderShow => [...columnHeaderShow, indicador+" (MXN)"]);

         const newIndicadoresGenerales = indicadoresGenerales.filter(ind  => ind[0].id !== parseInt(idIndicador));
         setIndicadoresGenerales(newIndicadoresGenerales); 
         setIndicador('');
         setIdIndicador('');
         if(!shouldInsert){
            setShouldUpdate(true);
        }                 
     }
     const table=()=>{
         return  <div><table style={{overflowX: "scroll"}} className="MuiTable-root">
            <thead className="MuiTableHead-root">
                <tr className="MuiTableRow-root MuiTableRow-head">
                {generateHeader()}
                </tr>
            </thead>
            <tbody className="MuiTableBody-root">
                {generateTableData()}
                {dta.length<1 && !showEmptyData && puestos.length!==0  ? 
                    <tr style={{textAlign: "center", height: "245px"}}> 
                        <td colSpan={columnHeaderShow.length}>{<SpinnerOval/>}</td>
                    </tr>: null}
                    
                {puestos.length===0 ? 
                <tr style={{textAlign: "center", height: "245px"}}> 
                    <td colSpan={columnHeaderShow.length}>{'No Existen Puestos Registrados'}</td>
                </tr>: null}

                {showEmptyData && puestos.length>0  ?
                    <tr style={{textAlign: "center", height: "245px"}}>
                        <td colSpan={columnHeaderShow.length}>No existen registros</td>
                    </tr>
                :null}
            </tbody>
     </table></div> ;
     }
     
     const addIndicador=(e)=>{
        setIndicador(e.target.value); 
        setIdIndicador(e.target[e.target.selectedIndex].id);
     }

     const saveInfo= () =>{
        setLoading(true);
        var saveDta=dta;
        Object.assign(dta, dtaBack);
        const obj=[];
        var conceptos=[];
        var montos=true;
        var numIndicadores=0; 
        dta.map((key) => {
            const llave=Object.keys(key);
            for (const i in llave) {
                if(llave[i]!== columnHeader[0] && llave[i] !== columnHeader[1] && llave[i] !== columnHeader[2] && llave[i] !== 'puestoId' && conceptos.length<columnsTable.length-3){
                    if(!MONTO.test(key[llave[i]]) || /\$0.00/g.test(key[llave[i]]) || key[llave[i]]<1){ 
                        montos = false;
                    }
                    conceptos.push({indicadorId:parseInt(llave[i]), monto: isNaN(key[llave[i]]) ? amountToFloat(key[llave[i]]) : key[llave[i]] });
                    numIndicadores++;
                } 
            }
            if(idTab || shouldUpdate){
                obj.push({puestoId: key.puestoId, conceptos: conceptos});
            }else{
                obj.push({puestoId: key.puestoId, fechaini:fechaini, conceptos: conceptos});
            }
            conceptos=[];
            return null;
        }); 
        if( montos && numIndicadores>0){
            const request={tabuladores: obj};
            if(idTab && shouldUpdate){
                updateDataBack(request);
            }else{
                if(shouldInsert){
                    insertData(request);
                }else if(shouldUpdate){
                    updateDataBack(request);
                }else{
                    setTextAlert("¡No hay cambios por guardar!");
                    setAlertSuccess(true);
                    setLoading(false);
                }
            }
            refreshData2(saveDta);
        }else{
            if(numIndicadores<1){
                setLoading(false); 
                setTextAlert('Debe agregar al menos una percepción');
                setAlertError(true); 
            }

            if(!montos){
                setLoading(false);
                refreshData2(saveDta);
                setTextAlert(campos_vacios)
                setAlertError(true);  
            }
            
        }
     }

     
     const updateDataBack =(request)=>{
        Axios.put(`${servicios_grupogack}/org/tab/`,request, headersAuth())
        .then(res => {            
            switch(res.data.status) {
                case 200:
                    setLoading(false);
                    setTextAlert('Registro Actualizado');
                    setAlertSuccess(true); 
                    setShouldUpdate(false);
                    break;
                case 404:
                    setLoading(false);
                    setAlertError(true);
                    break;
                case 400:
                    setLoading(false);
                    setTextAlert(res.data.msg);
                    setAlertError(true);
                    break;
                default:
                    setLoading(false);
                    alert('error');
                    setTextAlert(cambios_no_guardados);
                    setAlertError(true);
                    break;
            }
        }).catch(error => {setLoading(false); alert(error)});
     }

     const insertData=(request)=>{

        Axios.post(`${servicios_grupogack}/org/tab/`,request, headersAuth())
            .then(res => {            
                switch(res.data.status) {
                    case 200:
                        setLoading(false);
                        setTextAlert(registro_guardado);
                        getPeriodos();
                        setAlertSuccess(true);
                        setShouldInsert(false);
                        break;
                    case 404:
                        setLoading(false);
                        setAlertError(true);
                        break;
                    case 400:
                        setLoading(false);
                        setTextAlert(res.data.msg);
                        setAlertError(true);
                        break;
                    default:
                        alert('error');
                        setLoading(false);
                        setTextAlert(cambios_no_guardados);
                        setAlertError(true);
                        break;
                }
            }).catch(error =>  {setLoading(false); alert(error)});
     }

    const getPeriodos = ()=>{
        Axios.get(`${servicios_grupogack}/org/tab/periodos`, headersAuth())
            .then(async  (res) => {            
                switch(res.data.status) {
                    case 200:
                        setIdTabTemp(res.data.dataResponse[0].id);
                        onShowTable(true, res.data.dataResponse[0].id, null, fechaini);
                        break;
                    case 404:
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert(error));
    }

    const verificarSalida = ()=>{
        if(idTab || idTabTemp){
            Axios.get(`${servicios_grupogack}/org/tab/detalle/${idTab ? idTab : idTabTemp}`, headersAuth())
            .then((respuesta) => {   
                switch(respuesta.data.status) {
                    case 200:                 
                            onShowTable(false);
                        break;
                    case 404:
                        setTextAlert('Debe agregar y guardar al menos una percepción');
                        setAlertError(true); 
                        break;
                    default:
                        alert('error');
                        break;
                }
            })
            .catch(error => alert(error));
        }else{
            onShowTable(false);
        }
    } 
    
   return (
       <div id={idModulo} className="back MuiPaper-root MuiPaper-elevation2 MuiPaper-rounded">
            {
                headerTab
                ?
                    <div className="row justify-content-between">
                        <div className="col-md-10 col-sm-12 ml-4" style={{padding:'1rem'}}>
                            <div><b>{`Fecha Inicio:`}</b> <span>{`${tabuladoresBack[indexTab].fechaini} - `}</span> <b>{`Fecha Final: `}</b> <span>{tabuladoresBack[indexTab].fechafin}</span></div>
                        </div>
                        <div className="col-1">
                            <button className="form-control" onClick={()=>onShowTable(false)}> 
                                <Tooltip title="Regresar a Tabuladores" placement="left-start">
                                    <ArrowBackIcon/>
                                </Tooltip>
                            </button>
                        </div>
                    </div>
                :
                <div className="row justify-content-between titleDinamicTable">
                    <div className="col-auto mr-auto ">
                        <label>Agregar Percepción</label>
                        <div className="form-inline" >
                            <select  className="form-control" value={ indicador } onChange={e=> addIndicador(e) }>
                                <optgroup label="Agregar Percepción">
                                        <option value="">- Seleccione -</option>
                                        {
                                            indicadoresGenerales
                                            ?
                                            indicadoresGenerales.map((indicador, index) => (     
                                                <option key={Math.random() * (500 - 1) + 1} value={indicador[0].indicadorNom} id={indicador[0].id}>
                                                    { indicador[0].indicadorNom }
                                                </option>
                                            ))
                                            :null
                                        }
                                </optgroup>
                            </select>
                            <button disabled={puestos.length===0} onClick={()=> indicador!=='' ? addData():null} className="form-control btn-third" style={{marginLeft: "10px"}}>Agregar</button> 
                        </div>
                    </div>
                    <div className="col-auto form-inline">
                        {   
                            loading
                            ? 
                                <SpinnerOval/>
                            :
                            <button disabled={puestos.length===0} className="form-control btn-color" onClick={()=>{saveInfo(); }}  style={{marginRight: "10px"}}> 
                                <DoneIcon/>
                            </button>
                        }
                        <button className="form-control btn-outline" onClick={()=>verificarSalida()}  > 
                                <CloseIcon/>
                        </button>
                    </div> 
                </div> 
            }         
           {
               <div style={{overflowX: "scroll"}} className="ContenedorDataTable">
                  {table()}
               </div>
           }
           {obtenerModalDelete( idModulo, alertWarning, setAlertWarning, elemmentSelect, null, delColumn, errorTable, null )}
           <Alertwarning show={alertWarningLocal} SetopenALert={setAlertWarningLocal} text={textAlert} textButtonAceptar="Aceptar" textButtonCancelar="Cancelar" action={deleteColumn ? delColumn : replicateData} />
           <AlertSyE show={alertSuccess} setOpenAlertSyE={setAlertSuccess} title="Petición exitosa" text={textAlert} textbtnconfirm="Aceptar" type="success"/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
       </div>

   );
}
 
export default DinamicTable;
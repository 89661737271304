import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import ReportesAccesoDirecto from '../RecursosHumanos/ReportesAccesoDirecto';
import ModalDeleteReport from '../RecursosHumanos/ModalDeleteReport';
import ModalInfoReport from '../RecursosHumanos/ModalInfoReport';
import NominaTablaReporte from './NominaTablaReporte';
import ModalNewReportNomina from './ModalNewReportNomina';
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

    /***********Json files */

import repnomjson from '../../../../../Datos/repnompoliza.json';

const ReportesNominas = ({ id, permisos}) => { 
    
    const [ reportesPersonalizados, setReportesPersonalizados ] = useState( null );
    const [ reportesSuperiores, setReportesSuperiores ] = useState( null );  
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ openInfoReport, setOpenInfoReport ]  = useState( false );
    const [ openNewReportNomina, setOpenNewReportNomina ]  = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} ); 
    const [ infoReport, setInfoReport ] = useState( null );
    const [ showReport, setShowReport ]  = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const permisoss = [1,2,3,4]//permisos.map(element=> element.id);
    const [ title, setTitle ] = useState('');
    const [ reportesDefinidos, setReportesDefinidos ] = useState( null );
    const [ downloadReportDefinido, setDownloadReportDefinido ] = useState(false);
    const titulo = "";



    useEffect(() => { 


        setReportesPersonalizados(  Array.isArray(repnomjson.reportes) ? repnomjson.reportes: [] );
                        //const subordinados =  Array.isArray(repnomjson.subordinados) ? repnomjson.subordinados : [] ;
                        const subordinados = [] ;
                        setReportesSuperiores( Array.isArray(repnomjson.superiores) ? repnomjson.superiores.concat(subordinados) : subordinados );
                        setReportesDefinidos( Array.isArray(repnomjson.estaticos) ? repnomjson.estaticos: [] );
/*
            axios.get( `${servicios_grupogack}/configuracion/reportes/2`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:  
                        setReportesPersonalizados(  Array.isArray(respuesta.data.dataResponse.reportes) ? respuesta.data.dataResponse.reportes: [] );
                        const subordinados =  Array.isArray(respuesta.data.dataResponse.subordinados) ? respuesta.data.dataResponse.subordinados : [] ;
                        setReportesSuperiores( Array.isArray(respuesta.data.dataResponse.superiores) ? respuesta.data.dataResponse.superiores.concat(subordinados) : subordinados );
                        setReportesDefinidos( Array.isArray(respuesta.data.dataResponse.estaticos) ? respuesta.data.dataResponse.estaticos: [] );
                        break;
                    case 404:
                        setReportesPersonalizados( [] );
                        setReportesSuperiores( [] ); 
                        setReportesDefinidos( [] );
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
        */  }, []);
    
    const openReport = (action) => {
        setShowReport(action);
    }

    const eliminar = ( idElement ) => { 
        setReportesPersonalizados( reportesPersonalizados.filter( element => { if(element.reportesId) return element.reportesId !== idElement; else  return element.reportesId !== idElement} ) ); 
    } 
         
    const onAdd = ( newElement ) => {  
        setReportesPersonalizados( [...reportesPersonalizados, newElement ]); 
    } 

    const error =  ( msjError ) => {
        setTextAlert( msjError ); 
        setAlertError( true ); 
    } 
    
    return (
        <div id={ id }>
            
            {
                showReport &&
                <NominaTablaReporte id = {id} permisos = {permisoss} openReport = {openReport} elementSelect = {elementSelect} onAdd={onAdd} infoReport={infoReport} setInfoReport={setInfoReport}/>
            }
            {
                !showReport && 
                <React.Fragment> 
                 <React.Fragment>  
                {   
                    reportesDefinidos
                    ?
                    <React.Fragment>
                        <div className="row">
                            { reportesDefinidos.map( reporte => (
                                <div key={ `define-${reporte.reportesId}` } className="col-md-2 col-sm-12 my-2">
                                    <ReportesAccesoDirecto reporte={reporte} openReport={openReport} icon={'RecentActors'} downloadReport setOpenNewReportNomina={setOpenNewReportNomina}
                                        setDownloadReportDefinido={setDownloadReportDefinido} setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} informacionAdicional={true}
                                        setInfoReport={setInfoReport}
                                        />
                                </div>
                            )
                            )
                            } 
                        </div> 
                    </React.Fragment>
                    :   
                    <SpinnerOval /> 
                }
                </React.Fragment>  
                
                <React.Fragment>            
                    <h2 className="pageTitle" style={{margin:'2rem 0 2rem'}}> {`Gestor de Reportes`} </h2>
                    {
                       reportesPersonalizados  
                       ?
                       <React.Fragment>
                           <div className="row">
                               {
                                   permisoss.includes(1) &&
                                    <div key={ 'customNew' } className="col-md-2 col-sm-12 my-2">
                                        <ReportesAccesoDirecto reporte={{reportesReporteNom:"Nuevo Gestor de Reporte"}} openReport={openReport} setOpenNewReportNomina={setOpenNewReportNomina}
                                            setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} icon={'Add'} permisos={permisoss} informacionAdicional={true}/>
                                    </div>
                               }  
                               { 
                               Array.isArray(reportesPersonalizados) &&
                               reportesPersonalizados.map( reporte => (
                                   <div key={ reporte.reportesId } className="col-md-2 col-sm-12 my-2">
                                       <ReportesAccesoDirecto reporte={reporte} openReport={openReport} permisos={permisoss}
                                            setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} setOpenInfoReport={setOpenInfoReport} informacionAdicional={reporte}/>
                                   </div>
                               ))} 
                           </div> 
                       </React.Fragment>
                       :   
                       <SpinnerOval /> 
                    }
                </React.Fragment> 
                <React.Fragment>            
                    <h2 className="pageTitle" style={{margin:'2rem 0 2rem'}}> {``} </h2>
                    {
                       Array.isArray(reportesSuperiores)
                       &&
                       <React.Fragment>
                           <div className="row"> 
                               {  
                               reportesSuperiores.map( reporte => (
                                   <div key={ `custom-${reporte.reportesId}`} className="col-md-2 col-sm-12 my-2">
                                       <ReportesAccesoDirecto reporte={reporte} openReport={openReport} permisos={permisoss}
                                        setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} setOpenInfoReport={setOpenInfoReport} informacionAdicional={reporte}/>
                                   </div>
                               ))} 
                           </div> 
                       </React.Fragment>
                    }
                </React.Fragment>
                </React.Fragment>
            }
            <ModalDeleteReport openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} error={error}/>
            <ModalInfoReport openInfoReport={openInfoReport} nombreModulo={id} setOpenInfoReport={setOpenInfoReport} elementSelect={elementSelect} title={title}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
            <ModalNewReportNomina openNewReportNomina={openNewReportNomina} nombreModulo={id} setOpenNewReportNomina={setOpenNewReportNomina} 
                                    setElementSelect={setElementSelect} setShowReport={setShowReport} title={'Nuevo Reporte'} setInfoReport={setInfoReport}
                                    downloadReportDefinido={downloadReportDefinido} setDownloadReportDefinido={setDownloadReportDefinido} infoReport={infoReport}   
            />
        </div>
    );
}
 
export default ReportesNominas;
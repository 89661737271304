import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { servicios_grupogack } from '../../../../../../Constants/Constants';
import { headersAuth } from '../../../../../../Services/AuthService'; 
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval'; 
import ReportesAccesoDirecto from './ReportesAccesoDirecto';
import ModalDeleteReport from './ModalDeleteReport';
import ModalInfoReport from './ModalInfoReport';
import RecursosHumanosTablaReporte from './RecursosHumanosTablaReporte'; 
import AlertSyE from '../../../../../../Services/Alerts/AlertSyE';

/********************json files ***********/

import reprhjson from '../../../../../Datos/reprh.json';

const RecursosHumanos = ({ id, permisos}) => { 
    
    const [ reportesPersonalizados, setReportesPersonalizados ] = useState( null );
    const [ reportesSuperiores, setReportesSuperiores ] = useState( null );  
    const [ openDelete, setOpenDelete ] = useState( false );
    const [ openInfoReport, setOpenInfoReport ]  = useState( false );
    const [ elementSelect, setElementSelect ] = useState( {} );
    const [ showReport, setShowReport ]  = useState( false );
    const [ alertError, setAlertError ] = useState( false );
    const [ textAlert, setTextAlert ] = useState('');
    const permisoss = [1,2,3,4]//permisos.map(element=> element.id);
    const [ title, setTitle ] = useState('');
    const [ reportesDefinidos, setReportesDefinidos ] = useState( null ); 
    useEffect(() => { 

            /*axios.get( `${servicios_grupogack}/configuracion/reportes/1`, headersAuth() )
            .then( respuesta => {*/
            /*switch( respuesta.data.status ){ 
                console.log(reprhjson);
                switch(reprhjson){
                    case 200:*/
                        setReportesPersonalizados(  Array.isArray(reprhjson.reportes) ? reprhjson.reportes: [] );
                        const subordinados =  Array.isArray(reprhjson.subordinados) ? reprhjson.subordinados : [] ;
                        setReportesSuperiores( Array.isArray(reprhjson.superiores) ? reprhjson.superiores.concat(subordinados) : subordinados );
                        setReportesDefinidos( Array.isArray(reprhjson.estaticos) ? reprhjson.estaticos: [] );
                        /*break;
                    case 404:
                        setReportesPersonalizados( [] );
                        setReportesSuperiores( [] ); 
                        setReportesDefinidos( [] );
                        break;
                    default:
                        break;
                }
           /* })
            .catch( error => { console.log( error ) })*/
    }, []);
    
    const openReport = (action) => {
        setShowReport(action);
    }

    const eliminar = ( idElement ) => { 
        setReportesPersonalizados( reportesPersonalizados.filter( element => { if(element.reportesId) return element.reportesId !== idElement; else  return element.reportesId !== idElement} ) ); 
    } 
         
    const onAdd = ( newElement ) => {  
        setReportesPersonalizados( [...reportesPersonalizados, newElement ]); 
    } 

    const error =  ( msjError ) => {
        setTextAlert( msjError ); 
        setAlertError( true ); 
    } 

    return (
        <div id={ id }>
            
            {
                showReport &&
                <RecursosHumanosTablaReporte id = {id} permisos = {permisoss} openReport = {openReport} elementSelect = {elementSelect} onAdd={onAdd} />
            }
            {
                !showReport && 
                <React.Fragment> 
                 <React.Fragment>  
                {   
                    reportesDefinidos
                    ?
                    <React.Fragment>
                        <div className="row">
                            { reportesDefinidos.map( reporte => (
                                <div key={ `define-${reporte.reportesId}` } className="col-md-2 col-sm-12 my-2">
                                    <ReportesAccesoDirecto reporte={reporte} openReport={openReport} icon={'RecentActors'} downloadReport
                                        setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} informacionAdicional={true}/>
                                </div>
                            ))} 
                        </div> 
                    </React.Fragment>
                    :   
                    <SpinnerOval /> 
                }
                </React.Fragment>  
                
                <React.Fragment>            
                    <h2 className="pageTitle" style={{margin:'2rem 0 2rem'}}> {`Gestor de Reportes`} </h2>
                    {
                       reportesPersonalizados  
                       ?
                       <React.Fragment>
                           <div className="row">
                               {
                                   permisoss.includes(1) &&
                                    <div key={ 'customNew' } className="col-md-2 col-sm-12 my-2">
                                        <ReportesAccesoDirecto reporte={{reportesReporteNom:"Nuevo Gestor de Reporte"}} openReport={openReport} 
                                            setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} icon={'Add'} permisos={permisoss} informacionAdicional={true}/>
                                    </div>
                               }  
                               { 
                               reportesPersonalizados !== "Sin reportes Aun" &&
                               reportesPersonalizados.map( reporte => (
                                   <div key={ reporte.reportesId } className="col-md-2 col-sm-12 my-2">
                                       <ReportesAccesoDirecto reporte={reporte} openReport={openReport} permisos={permisoss}
                                            setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} setOpenInfoReport={setOpenInfoReport}/>
                                   </div>
                               ))} 
                           </div> 
                       </React.Fragment>
                       :   
                       <SpinnerOval /> 
                    }
                </React.Fragment> 
                <React.Fragment>            
                    {/***Reportes de Otros Usuarios */}
                    <h2 className="pageTitle" style={{margin:'2rem 0 2rem'}}> {``} </h2>
                    {
                       reportesSuperiores && reportesSuperiores!== "Usted No Tiene Superiores"
                       &&
                       <React.Fragment>
                           <div className="row"> 
                               { 
                               reportesSuperiores &&
                               reportesSuperiores.map( reporte => (
                                   <div key={ `custom-${reporte.reportesId}`} className="col-md-2 col-sm-12 my-2">
                                       <ReportesAccesoDirecto reporte={reporte.reportes} openReport={openReport} permisos={permisoss}
                                        setOpenDelete={setOpenDelete} setElementSelect={setElementSelect} setTitle={setTitle} setOpenInfoReport={setOpenInfoReport} informacionAdicional={reporte}/>
                                   </div>
                               ))} 
                           </div> 
                       </React.Fragment>
                    }
                </React.Fragment>
                </React.Fragment>
            }
            <ModalDeleteReport openDelete={openDelete} setOpenDelete={setOpenDelete} elementSelect={elementSelect} eliminar={eliminar} error={error}/>
            <ModalInfoReport openInfoReport={openInfoReport} nombreModulo={id} setOpenInfoReport={setOpenInfoReport} elementSelect={elementSelect} title={title}/>
            <AlertSyE show={alertError} setOpenAlertSyE={setAlertError} title="Petición fallida" text={textAlert} textbtnconfirm="Aceptar" type="error"/>
        </div>
    );
}
 
export default RecursosHumanos;
import React, { Fragment } from 'react';
import DataTableService from '../../../../DataTableService/DataTableService';
import ButtonReturn from '../components/ButtonReturn';

const TableConsultar = ({ id, title, data, hidenTable }) => {
    return (
        <Fragment>
            <ButtonReturn action={hidenTable} type="consultar"/>
            <DataTableService id={id} title={title}
                columns={[
                    { field: "numRegistro", title: "N° Registro" },
                    { field: "status", title: "Status" },
                    { field: "numEmpleado", title: "Número de Empleado" },
                    { field: "nombre", title: "Nombre" },
                    { field: "periodo", title: "Periodo" },
                    { field: "anio", title: "Año" },
                    { field: "acumulado", title: "Tipo de Acumulado" },
                    { field: "fechaOperacion", title: "Fecha de Operación" },
                ]}
                data={data}
                hideIconAdd hideIconEdit hideIconDelete
            />
        </Fragment>
    );
}
 
export default TableConsultar;
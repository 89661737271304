import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { headersAuth } from '../../../../../../Services/AuthService';
import { servicios_grupogack_catalogo, servicios_grupogack_admon, departamentos_generales } from '../../../../../../Constants/Constants';
import { ArrayJsonDepartamentos } from '../../../Helpers/JsonToOneLevel';
import DataTableService from '../../../DataTableService/DataTableService';
import SpinnerOval from '../../../../../../Services/Spinner/SpinnerOval';

const DepartamentosGenerales = ({ id, permisos }) => {
    const [ departamentos, setDepartamentos ] = useState( null );
    const [ dependencias, setDependencias ] = useState( null );
    useEffect(() => {
        axiosDepartamentos();
        axiosDependencias();
    }, []);

    const axiosDepartamentos = () => {
        axios.get( `${servicios_grupogack_catalogo}/departamentos/`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDepartamentos( ArrayJsonDepartamentos(respuesta.data.dataResponse) );
                        break;
                    case 404: //No hay registros para mostrar
                        setDepartamentos([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    /* Axios para consumo de servicio Dependencias(Compañias) */
    const axiosDependencias = () => {
        axios.get( `${servicios_grupogack_admon}/compania`, headersAuth() )
            .then( respuesta => {
                switch( respuesta.data.status ){
                    case 200:
                        setDependencias( respuesta.data.dataResponse );
                        break;
                    case 404:
                        //setDependencias([]);
                        break;
                    default:
                        break;
                }
            })
            .catch( error => { console.log( error ) })
    }

    return (
        <div id={ id }>
            { departamentos && dependencias ?
                <DataTableService id={id} title={ departamentos_generales } permisos={permisos}
                    columns={[
                        { field: "deptoClave", title: "Clave" },
                        { field: "deptoNom", title: "Nombre Departamento" },
                        { field: "ciaRazonSocial", title: "Dependencia" },
                        { field: "deptoDescrip", title: "Descripción" },
                    ]}
                    data={ departamentos }
                    informacionAdicional={{ dependencias }} />
                :
                <SpinnerOval />
            }
        </div>
    );
};

export default DepartamentosGenerales;